var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "WeeklySession" }, [
    _c(
      "ul",
      { staticClass: "week-list" },
      _vm._l(_vm.sessionList, function(session, index) {
        return _c("li", { key: index }, [
          session
            ? _c("div", { staticClass: "week-session" }, [
                _c(
                  "button",
                  {
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        !session.batch_process_yn
                          ? _vm.detailPopup1(session.id)
                          : _vm.detailPopup2(
                              session.batch_session_id,
                              session.date
                            )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "page.patient.calendar.head.strokeCoachSession"
                          )
                        ) +
                        "\n          "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          "(" +
                            session.count +
                            " " +
                            _vm.$t("page.patient.calendar.head.trainings") +
                            ")"
                        ) +
                        "\n        "
                    )
                  ]
                )
              ])
            : _c("div")
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }