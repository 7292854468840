import Vue from "vue";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/scatter";
import "echarts/lib/chart/radar";
import "echarts/lib/chart/sankey";
import "echarts/lib/chart/gauge";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/title";
import "echarts/lib/component/graphic";
import "echarts/lib/component/grid";
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/legendScroll";

import shine from "../assets/echart-theme/shine.json";
import shine4k from "../assets/echart-theme/shine4k.json";

ECharts.registerTheme("shine", shine);
ECharts.registerTheme("shine4k", shine4k);

Vue.component("v-chart", ECharts);
