<template>
  <section class="Profile">
    <h1>{{ $t("page.profile.title") }}</h1>
    <form ref="updateForm" @submit.prevent>
      <div class="photo-part">
        <div class="photo">
          <span class="icon-ic_group_fill">
            <span class="path1" />
            <span class="path2" />
          </span>
          <span v-if="profileImage" class="user-avatar">
            <img :src="profileImage" :alt="`${name}'s photography'`">
          </span>
          <label for="photo" class="pic-btn">
            <span class="icon-ic_edit">
              <span class="path1" />
              <span class="path2" />
              <span class="path3" />
            </span>
          </label>
          <input
            id="photo"
            type="file"
            accept=".gif, .png, .jpg, .jpeg"
            @change="handlePhotoChange"
          >
        </div>
        <div class="name-with-email">
          <span class="name">{{ name }}</span>
          <a class="edit-btn" @click="handleEditName">{{
            $t("page.profile.editName.edit")
          }}</a>
          <span class="email">{{ email }}</span>
        </div>
      </div>
      <div class="country">
        <label for="country">{{ $t("page.profile.country") }}</label>
        <Select
          id="country"
          v-model="selectedCountry"
          label="countryName"
          placeholder="Select Country"
          :components="{ OpenIndicator, Deselect }"
          :options="countryList"
        >
          <div slot="no-options" class="vs__no-options">
            Sorry, no matching country.
          </div>
        </Select>
      </div>
      <div
        v-if="statesList && selectedCountry.countryValue === 'US'"
        class="states"
      >
        <div class="states-information">
          <label for="states">{{ $t("page.profile.states.title") }}</label>
          <span class="icon-ic_info_circle">
            <span class="path1" />
            <span class="path2" />
          </span>
          <div class="notice-information">
            {{ $t("page.profile.states.tooltip") }}
          </div>
        </div>
        <input
          v-model="showText"
          type="text"
          readonly
          class="inputClass"
          @focus="showPopup"
        >
        <LicenseStates
          v-show="licenseStatesPopup"
          ref="createLicenseStatesPopup"
          :all-states="statesList"
          :check-states="checkedStates"
          :license-states-info="createLicenseStatesPopup"
          :handle-create-license-states-popup-close="
            handleCreateLicenseStatesPopupClose
          "
          :set-checked-states="setCheckedStates"
          :set-selected-states="setSelectedStates"
        />
      </div>
      <div class="gender">
        <label for="Male">{{ $t("page.profile.gender.title") }}</label>
        <div class="gender-radio">
          <span v-for="(type, index) of genderList" :key="index">
            <input
              :id="type"
              v-model="selectedGender"
              type="radio"
              :value="type"
            >
            <label :for="type">{{ inputInfoGenderOption(type) }}</label>
          </span>
        </div>
      </div>
      <div class="mobile">
        <label for="mobile">{{ $t("page.profile.mobilePhone.title") }}</label>
        <input
          id="mobile"
          v-model="mobileNumber"
          placeholder="Mobile"
          type="text"
          @keydown="onlyNumber"
        >
      </div>
      <div class="therapist-type">
        <label for="OT">{{ $t("page.profile.providerType.title") }}</label>
        <div class="therapist-type-radio">
          <span v-for="(type, index) of therapistTypeList" :key="index">
            <input
              :id="type"
              v-model="selectedTherapistType"
              type="radio"
              :value="type"
            >
            <label :for="type">{{ inputInfoProviderTypeOption(type) }}</label>
          </span>
        </div>
      </div>
      <div class="work-place">
        <label for="OT">{{ $t("page.profile.workPlace.title") }}</label>
        <div class="work-place-radio">
          <span v-for="(type, index) of workplaceList" :key="index">
            <input
              :id="type"
              v-model="selectedWorkplace"
              type="radio"
              :value="type"
            >
            <label :for="type">{{ inputInfoWorkPlaceOption(type) }}</label>
          </span>
        </div>
        <!-- clinic code 입력 input 21.02.02 -->
        <input
          id="clinic"
          v-model="clinicCode"
          type="text"
          autocomplete="off"
          :placeholder="inputInfoWorkPlacePlaceholder"
          :disabled="selectedWorkplace === 'Neofect'"
        >
      </div>
      <div class="introduction">
        <label for="introduction">{{
          $t("page.profile.introduce.title")
        }}</label>
        <div class="introduction-length">
          <span class="length-check">({{ introduction.length }}/100)</span>
        </div>
        <textarea
          id="introduction"
          v-model="introduction"
          :placeholder="introducePlaceholder"
        />
      </div>
      <div class="password">
        <button @click="handleResetPassword">
          {{ $t("page.profile.resetPassword.button.reset") }}
        </button>
      </div>
      <div class="profile-submit-btns">
        <div class="submit-btns" :class="submitDisable ? 'disabled' : ''">
          <button type="button" @click="handleSubmit">
            {{ $t("page.profile.resetPassword.button.save") }}
          </button>
        </div>
        <div class="default-type-btns big-btns">
          <button type="button" @click="handleCancel">
            {{ $t("page.profile.resetPassword.button.cancel") }}
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import Select from "vue-select";
import ResetPassword from "./ResetPassword";
import Confirm from "@/components/Confirm";
import LicenseStates from "./components/LicenseStates";
import EditName from "./components/EditName";
import {
  getUSAStatesList,
  fetchGetTherapistInfo,
  fetchUpdateTherapistInfo,
  fetchUploadTherapistPhoto,
  getInfo
} from "@/api/user.js";
import {
  fetchGetClinicByClinicCode
} from "@/api/clinic.js";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
export default {
  name: "Profile",
  components: {
    Select,
    LicenseStates
  },
  mixins: [SelectMixin, ErrorMixin],
  data() {
    return {
      countryList: [
        { countryName: this.$t("common.nation.america"), countryValue: "US" },
        { countryName: this.$t("common.nation.korea"), countryValue: "KR" }
      ],
      statesList: [],
      genderList: ["Male", "Female"],
      therapistTypeList: ["OT", "PT", "Others"],
      workplaceList: ["Neofect", "Other Clinic"],
      passwordType: "password",
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      fullName: "",
      selectedGender: "",
      selectedCountry: "",
      selectedTherapistType: "",
      selectedWorkplace: "Neofect",
      selectedOldClinic: "",
      introduction: "",
      birthDay: this.$moment(new Date()).format("MM.DD.YYYY"),
      mobileNumber: "",
      profileImage: "",
      checkedStates: [],
      createLicenseStatesPopup: {},
      licenseStatesPopup: false,
      showText: "",
      id: "",
      clinicCode: "",
      nowClinicCode: ""
    };
  },
  computed: {
    inputInfoGenderOption() {
      return (value) => {
        return this.$t(`page.profile.gender.${value.toLowerCase()}`);
      };
    },
    inputInfoProviderTypeOption() {
      return (value) => {
        return this.$t(`page.profile.providerType.${value}`);
      };
    },
    inputInfoWorkPlaceOption() {
      return (value) => {
        return this.$t(`page.profile.workPlace.${value.replace(" ", "")}`);
      };
    },
    introducePlaceholder() {
      return this.$t(`page.profile.introduce.placeholder`);
    },
    submitDisable: function () {
      return (
        this.selectedGender.length < 1 ||
        this.selectedTherapistType.length < 1 ||
        this.birthDay.length < 1 ||
        this.mobileNumber.length < 1 ||
        !this.selectedCountry ||
        (this.selectedCountry.countryValue === "US" &&
          this.checkedStates.length < 1)
      );
    },
    clinicRequestSent: function () {
      return this.selectedClinic.request_sent;
    },
    inputInfoWorkPlacePlaceholder: function () {
      return this.$t("page.signUp.inputInfo.workPlace.placeholder");
    }
  },
  watch: {
    introduction: function (newVal) {
      const newLine = /\n/g;
      if ([...newVal.matchAll(newLine)].length > 2) {
        this.introduction = this.introduction.slice(0, -1);
      }

      // 100개문자까지 입력 가능함
      if (newVal.length > 100) {
        this.introduction = newVal.slice(0, 100);
      }
    },
    mobileNumber: function (newVal, oldVal) {
      const re = new RegExp("[^0-9\\-]", "g");
      if (re.test(newVal)) {
        this.mobileNumber = newVal.replace(re, "");
      }
    },
    selectedWorkplace: function (newVal, oldVal) {
      if (newVal === "Neofect") {
        this.selectedOldClinic = this.clinicCode;
        this.clinicCode = "";
      } else {
        if (oldVal === "Neofect" && newVal === "Other Clinic") {
          if (this.selectedOldClinic !== "") {
            this.clinicCode = this.selectedOldClinic;
          } else {
            this.clinicCode = this.clinicCode;
          }
        }
      }
    }
  },
  async created() {
    try {
      const statesList = await getUSAStatesList();
      statesList.forEach((target, index) => {
        const state = {
          abbr: target.abbr,
          state: target.state
        };
        this.statesList.push(state);
      });
    } catch (error) {
      this.handleError(error, "Profile > Get USA States List API");
    }
    try {
      const therapistInfo = await fetchGetTherapistInfo();
      const meInfo = await getInfo();
      console.log(meInfo);
      const profileInfo = {
        ...therapistInfo,
        clinic: meInfo.clinic
      };
      this.setProfileInfo(profileInfo);
    } catch (error) {
      this.handleError(error, "Profile > Get Therapist Info API");
    }
  },
  methods: {
    onlyNumber(event) {
      const re = new RegExp("[^0-9\\-]");
      if (event.keyCode === 8 || event.keyCode === 46) {
        return;
      } else {
        if (re.test(event.key)) {
          // event.stopPropagation();
          event.preventDefault();
          return;
        }
      }
    },
    handleClinicRequestCancel() {
      this.$modal.show(
        Confirm,
        {
          type: "cancel",
          confirm: {
            title: "Cancel Request",
            message: "Are you sure you want to cancel the request?"
          },
          submit: this.clinicRequestCancel
        },
        {
          height: "auto"
        }
      );
    },
    passwordHidden() {
      this.passwordType = "password";
    },
    passwordShow() {
      this.passwordType = "text";
    },
    handleCancel() {
      this.$router.go(-1);
    },
    async handleSubmit() {
      if (this.submitDisable) {
        return;
      }
      let clinicInfo;
      if (this.selectedWorkplace !== "Neofect") {
        try {
          clinicInfo = await fetchGetClinicByClinicCode(this.clinicCode);
        } catch (e) {
          this.handleError(e, "ClinicCode Error");
        }
      }
      const selectedClinic =
        this.selectedWorkplace === "Neofect" ? -1 : clinicInfo.id;
      const therapistInfo = {
        country: this.selectedCountry.countryValue,
        states: null,
        gender: this.selectedGender.toUpperCase(),
        mobile: this.mobileNumber,
        type: this.selectedTherapistType,
        password: this.password,
        introduction: this.introduction,
        zone_offset: -new Date().getTimezoneOffset(),
        clinic_req_id: selectedClinic
      };
      if (this.selectedCountry.countryValue === "US") {
        therapistInfo.states = this.checkedStates;
      }
      if (this.nowClinicCode === this.clinicCode) {
        // 클리닉을 변경하지 않음
        try {
          await fetchUpdateTherapistInfo(therapistInfo);
          this.$modal.show(
            Confirm,
            {
              type: "change_save",
              confirm: {
                title: this.$t("page.profile.saveModal.title"),
                message: this.$t("page.profile.saveModal.message")
              },
              submit: this.closeProfile
            },
            {
              height: "auto"
            }
          );
        } catch (error) {
          this.handleError(error, "Profile > Update Therapist Info API");
        }
      } else {
        // 클리닉을 변경
        console.log("클리닉 변경");
        try {
          await fetchUpdateTherapistInfo(therapistInfo);
          this.$router.push("/approvalStatus");
        } catch (error) {
          this.handleError(error, "Profile > Update Therapist Info API");
        }
      }
    },
    async handlePhotoChange(event) {
      const file = event.target.files[0];
      if (file.size > 1024 * 1000 * 1024 * 5) {
        alert("This file is huge size. We can allow until 5mb size.");
      } else {
        const formData = new FormData();
        formData.append("file", file);
        try {
          const result = await fetchUploadTherapistPhoto(formData);
          if (result) {
            const time = new Date().getTime();
            const profileImage = `${result}?forceRender=${time}`;
            this.profileImage = profileImage;
            debugger;
            await this.$store.dispatch("user/setAvatar", profileImage);
          }
        } catch (error) {
          this.handleError(error, "Profile > Change Photo API");
        }
      }
    },
    setProfileInfo(info) {
      console.log(info);
      const selectedCountry = this.countryList.filter((country) => {
        return country.countryValue === info.country;
      });
      this.id = info.id;
      this.name = info.name;
      this.email = info.email;
      this.selectedCountry = !selectedCountry ? "" : selectedCountry[0];
      this.checkedStates = info.states;
      this.selectedTherapistType = !info.type ? "" : info.type;
      const gender = info.gender.toLowerCase();
      this.selectedGender = gender.charAt(0).toUpperCase() + gender.slice(1);
      this.mobileNumber = !info.mobile ? "" : info.mobile;
      this.introduction = !info.introduction ? "" : info.introduction;
      const uuid = `${Math.floor(Math.random() * 10 + 10)}`; // 2자리의 uid 랜덤생성
      const profileImage = info.image
        ? `${info.image}?forceRender=${uuid}`
        : "";
      this.profileImage = profileImage;
      this.clinicCode = info.clinic.id === -1 ? "" : info.clinic.clinicCode;
      this.selectedWorkplace =
        info.clinic.id === -1 ? "Neofect" : "Other Clinic";
      this.nowClinicCode = info.clinic.id === -1 ? "" : info.clinic.clinicCode;
    },
    handleResetPassword() {
      this.$modal.show(ResetPassword, null, {
        width: "450px",
        height: "auto"
      });
    },
    closeProfile() {
      this.$router.go(-1);
    },
    handleCreateLicenseStatesPopupClose() {
      this.licenseStatesPopup = false;
    },
    showPopup() {
      this.handleCreateLicenseStatesPopupClose();
      this.licenseStatesPopup = true;
    },
    setCheckedStates(states) {
      this.checkedStates = states;
    },
    setSelectedStates(text) {
      this.showText = text;
    },
    handleEditName() {
      return this.$modal.show(
        EditName,
        {
          fullName: this.name,
          therapistId: this.id
        },
        {
          width: "450px",
          height: "294px"
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.Profile {
  min-width: 350px;
  width: 350px;
  margin: 130px auto auto;
  color: $fontColor1;
  h1 {
    font-weight: 800;
    font-size: $fontSize1;
    letter-spacing: -0.4px;
    line-height: 45px;
  }
  label {
    font-size: $fontSize7;
  }
  input[type="text"] {
    margin-top: 8px;
  }
  .notice {
    margin-top: 45px;
    font-size: $fontSize5;
    color: $fontColor2;
    line-height: 24px;
    button {
      text-decoration: underline;
      font-size: $fontSize5;
    }
  }
  .message {
    margin-top: 4px;
    display: none;
    color: $uiColor1;
    background: rgba(244, 67, 54, 0.25);
    padding: 4px 16px;
    font-size: $fontSize6;
  }
  .verification-email {
    margin-top: 56px;
    margin-bottom: -18px;
    font-size: $fontSize2;
    line-height: 34px;
    word-break: break-all;
  }
  .photo-part {
    position: relative;
    margin-top: 60px;
    display: flex;
    .photo {
      flex-shrink: 0;
      position: relative;
      .icon-ic_group_fill {
        font-size: 55px;
        color: rgba(255, 255, 255, 0.8);
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        background: $borderColor1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .user-avatar {
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: block;
        background: $borderColor1;
        overflow: hidden;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }
      }
      #photo {
        position: absolute;
        visibility: hidden;
      }
      .pic-btn {
        position: absolute;
        cursor: pointer;
        width: 32px;
        height: 32px;
        background: $fontColor3;
        top: 0;
        right: 0;
        transform: translate(0, 48px);
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        .icon-ic_edit {
          font-size: 16px;
          color: #ffffff;
        }
      }
    }
    .name-with-email {
      margin-top: 8px;
      margin-left: 32px;
      width: calc(100% - 32px - 80px);

      .name {
        display: inline-block;
        width: 170px;
        font-size: $fontSize2;
        line-height: 32px;
        color: $fontColor1;
        word-break: break-all;
      }

      .edit-btn {
        position: absolute;
        right: 0;
        // display: block;
        // float: right;
        cursor: pointer;
        font-weight: 600;
        font-size: $fontSize6;
        color: $fontColor2;
        padding: 7px 12px;
        border: 1px solid #d0d4dc;
        box-sizing: border-box;
        border-radius: 4px;
      }

      .email {
        margin-top: 4px;
        font-size: $fontSize5;
        color: $fontColor2;
        line-height: 21px;
        display: block;
      }
    }
  }
  .country {
    margin-top: 48px;
    .v-select {
      margin-top: 8px;
    }
  }
  .states {
    margin-top: 24px;
    position: relative;
    .states-information {
      display: flex;
      align-items: center;

      .icon-ic_info_circle {
        padding-left: 3px;
        color: $fontColor2;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          color: $fontColor1;

          & + .notice-information {
            display: block;
          }
        }
      }

      .notice-information {
        display: none;
        position: absolute;
        width: 236px;
        top: 2px;
        left: 71px;
        color: $fontColor2;
        background: #ffffff;
        padding: 12px 16px;
        font-size: $fontSize7;
        line-height: 21px;
        z-index: 2;
        box-sizing: border-box;
        border-radius: 2px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
        border: solid 1px #dcdfe6;
      }
    }
    .v-select {
      margin-top: 8px;
    }
  }
  .gender {
    margin-top: 24px;
    .gender-radio {
      display: flex;
      margin-top: 16px;
      span {
        margin-left: 32px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
  .mobile {
    margin-top: 24px;
  }
  .therapist-type {
    margin-top: 24px;
    .therapist-type-radio {
      display: flex;
      margin-top: 16px;
      span {
        margin-left: 32px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
  .work-place {
    margin-top: 24px;
    .work-place-radio {
      margin-top: 16px;
      display: flex;
      span {
        margin-left: 32px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
    ::v-deep .v-select {
      margin-top: 16px;
    }
    &.request-sent {
      ::v-deep .v-select {
        &.vs--disabled {
          .vs__dropdown-toggle,
          .vs__clear,
          .vs__search,
          .vs__selected,
          .vs__open-indicator,
          .vs__dropdown-toggle {
            border-color: #fdeae4;
            background: #fdeae4;
            color: $fontColor2;
          }
        }
      }
      .request-sent-message {
        margin-top: 16px;
        font-size: $fontSize6;
        color: $fontColor2;
        button {
          text-decoration: underline;
        }
      }
    }
  }
  .introduction {
    margin-top: 24px;
    position: relative;
    label {
      font-size: $fontSize7;
    }
    textarea {
      margin-top: 8px;
      resize: vertical;
      min-height: 120px;
    }

    .introduction-length {
      display: inline-block;
      margin-left: 4px;

      .length-check {
        color: $fontColor3;
        font-size: $fontSize7;
        font-weight: 700;
      }
    }
  }
  .password {
    margin-top: 32px;
    position: relative;
    button {
      border-bottom: 1px solid $borderColor1;
    }
  }
  .profile-submit-btns {
    display: flex;
    margin-top: 48px;
    .submit-btns {
      margin-top: 0;
      width: 100%;
    }
    .default-type-btns {
      width: 100%;
      margin-left: 14px;
      button {
        width: 100%;
      }
    }
  }
}
</style>
