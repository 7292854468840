<template>
  <div class="VideoCall">
    <div class="tokbox-sessions">
      <div
        class="Session"
        :class="medicalRecords ? 'menu-open' : ''"
        @error="errorHandler"
      >
        <div
          class="screen-wrap"
          :class="mode"
          @click="(e) => screenWrapAction(e)"
        >
          <div v-if="sessionDie" class="notice-message session-die">
            Video call has been disconnected.
            <br>Please turn off and restart the video call.
          </div>
          <!-- 환자 -->
          <div
            class="screen-item subscribers camera-screen video-call"
            :class="publisherMain ? 'publisher-main' : ''"
          >
            <div v-if="videoCall" class="sub-wrapper">
              <subscriber
                :stream="videoCall"
                :session="session"
                @error="errorHandler"
              />
            </div>
            <div class="push-again-btn">
              <div v-if="!videoDisconnected">
                <span class="notice-message">No Participants</span>
              </div>
              <div v-else>
                <span
                  v-if="videoDisconnected === 'CASE_1'"
                  class="notice-message"
                >Patient doesn't respond</span>
                <span
                  v-if="videoDisconnected === 'CASE_2'"
                  class="notice-message"
                >Video Call has been disconnected</span>
                <div class="primary-type-btns">
                  <button @click="handleConnectPush">Connect Again</button>
                </div>
              </div>
            </div>
          </div>
          <!-- 환자 화면공유 -->
          <div
            v-if="screenSharing.view"
            class="screen-item subscribers screen-sharing"
          >
            <div v-if="screenSharing.stream" class="sub-wrapper">
              <subscriber
                :stream="screenSharing.stream"
                :session="session"
                @error="errorHandler"
              />
            </div>
            <div class="push-again-btn">
              <div v-if="!screenDisconnected">
                <span class="notice-message">Connecting...</span>
              </div>
              <div v-else>
                <span
                  v-if="screenDisconnected === 'CASE_1'"
                  class="notice-message"
                >Patient doesn't respond</span>
                <span
                  v-else
                  class="notice-message"
                >Screen Sharing has been disconnected</span>
                <div class="primary-type-btns">
                  <button @click="handleHomePush">Connect Again</button>
                </div>
              </div>
            </div>
          </div>

          <!-- 치료사 -->
          <publisher
            id="publisher"
            :class="`screen-item publisher camera-screen ${
              publisherMain ? 'publisher-main' : ''
            }`"
            :session="session"
            :screen-sharing-on="screenSharing.view"
            :screen-action="screenAction"
            :change-mode="() => changeMode(modes[1])"
            :mode="mode"
            :user-id="userId"
          />
          <!-- 치료사 화면공유 -->
          <div
            v-show="publisherScreenSharing.view"
            class="screen-item publisher screen-sharing"
          >
            <div id="screen-preview" />
          </div>
          <!-- 녹화 stop watch -->
          <RecordClock :is-recording="isRecording" />
          <!-- 비디오콜 전체 시간 -->
          <VideoCallClock />
          <!-- 녹화 토스트 팝업 추가 21.01.27 -->
          <!-- TODO Translator -->
          <div id="toast-popup">
            <h1>Video Call Recorded</h1>
            <p>Video call record has been saved.</p>
          </div>
        </div>
        <div v-if="medicalRecords" class="medical-records">
          <MedicalRecords
            :submit="saveMedicalRecords"
            :patient="patient"
            :zone-offset="zoneOffset"
            :game-categories="gameCategories"
            :remote-request-game="remoteRequestGame"
          />
        </div>
      </div>
    </div>

    <div class="control-bar">
      <div class="start-wrapper">
        <button
          type="button"
          class="presentation_button"
          @click="togglePresentation"
        >
          <span class="icon">
            <img
              src="@/assets/svgs/ic_presentation.svg"
              alt="ic_presentation-image"
            >
          </span>
          <span v-if="!publisherScreenSharing.view"> Presentation </span>
          <span v-if="publisherScreenSharing.view"> End Presentation </span>
        </button>
      </div>
      <div class="button-wrapper">
        <button
          type="button"
          class="close-video-call"
          title="Close Video Call"
          @click="handleClose"
        >
          <span class="icon-ic_call">
            <span class="path1" />
          </span>
        </button>
        <button
          type="button"
          :disabled="guestYN"
          :class="`screen-share-btn ${screenSharing.view ? 'active' : ''}`"
          title="Screen Sharing"
          @click="toggleScreenSharing"
        >
          <span class="icon-ic_screen-sharing">
            <span class="path1" />
            <span class="path2" />
            <span class="path3" />
            <span class="path4" />
            <span class="path5" />
            <span class="path6" />
            <span class="path7" />
            <span class="path8" />
            <span class="path9" />
            <span class="path10" />
          </span>
        </button>
        <button
          type="button"
          :disabled="guestYN"
          :class="`text-memo-btn ${this.medicalRecords ? 'active' : ''}`"
          title="Write Medical Record"
          @click="toggleMedicalRecords"
        >
          <span class="icon-ic_text">
            <span class="path1" />
            <span class="path2" />
            <span class="path3" />
          </span>
        </button>
        <!-- 녹화 버튼 추가. 21.01.27 -->
        <button
          :disabled="guestYN"
          type="button"
          :class="`recording-btn ${this.isRecording ? 'stop' : ''}`"
          @click="isRecording ? handleRecordingEnd() : handleRecordingStart()"
        >
          <span class="icon-ic_recording" />
        </button>
      </div>
      <div class="end-wrapper">
        <button type="button" class="end_cal_button" @click="handleEnd">
          <span class="icon">
            <img
              src="@/assets/svgs/ic_termination.svg"
              alt="ic_termination-image"
            >
          </span>
          End Video Call
        </button>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import Confirm from "@/components/Confirm";
import OT from "@opentok/client";
import Publisher from "./components/Publisher";
import Subscriber from "./components/Subscriber";
import RecordClock from "./components/RecordClock";
import VideoCallClock from "./components/VideoCallClock";
import MedicalRecords from "./components/MedicalRecords";
import {
  fetchDetailEventInfo,
  fetchScreenSharingStartInSchedule,
  fetchVideoCallStartInSchedule,
  fetchVideoCallRecordStart,
  fetchVideoCallRecordEnd,
} from "@/api/calendar.js";
import { getGameCategories, getGameStart, getLevelList } from "@/api/game.js";
import { fetchCreateMemo } from "@/api/patient.js";
import { mapGetters } from "vuex";

export default {
  name: "VideoCall",
  components: {
    Publisher,
    Subscriber,
    MedicalRecords,
    RecordClock,
    VideoCallClock,
  },
  props: {
    sessionId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    apiKey: {
      type: String,
      required: true,
    },
    eventId: String,
  },
  data() {
    return {
      modes: ["main-mode", "secondary-mode", "third-mode", "fourth-mode"],
      mode: "main-mode",
      publisherMain: false,
      gameCategories: [],
      streams: [],
      videoCall: null,
      screenSharing: {
        view: false,
        stream: null,
      },
      publisherScreenSharing: {
        session: null,
        view: false,
        stream: null,
      },
      guestYN: false,
      session: null,
      patient: null,
      medicalRecords: false,
      connect_cancel: undefined,
      videoDisconnected: "",
      home_cancel: undefined,
      screenDisconnected: "",
      zoneOffset: -new Date().getTimezoneOffset(),
      sessionDie: false,
      clickDisconnectSession: false,
      // recording state
      isRecording: false,
      // isVideoCall: true,
    };
  },
  watch: {
    screenDisconnected: function (newVal, oldVal) {
      console.log("----------------------------------");
      console.log(newVal);
    },
    // videoCall(newVal, oldVal) {
    //   !newVal ? (this.isVideoCall = false) : (this.isVideoCall = true);
    // },
  },
  mounted() {},
  methods: {
    screenWrapAction(e) {
      if (e.target.tagName === "BUTTON") return;
      if (this.mode === this.modes[1]) {
        this.changeMode(this.modes[0]);
      }
    },
    screenAction(e) {
      if (e.target.tagName === "BUTTON") return;
      if (this.mode === this.modes[0]) {
        this.publisherMain = !this.publisherMain;
      }
    },
    changeMode(mode) {
      if (!mode) {
        let sharingCount = 0;
        if (this.screenSharing.view) sharingCount++;
        if (this.publisherScreenSharing.view) sharingCount++;
        this.changeMode(
          this.modes[sharingCount === 0 ? sharingCount : sharingCount + 1]
        );
      } else {
        this.mode = mode;
      }
    },
    closeMedicalRecords() {
      this.medicalRecords = false;
    },
    toggleMedicalRecords() {
      if (this.sessionDie) {
        return this.closeMedicalRecords();
      }
      this.medicalRecords = !this.medicalRecords;
    },
    saveMedicalRecords(memo) {
      fetchCreateMemo(memo)
        .then((result) => {
          if (result) {
            this.closeMedicalRecords();
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleHomePush() {
      fetchScreenSharingStartInSchedule(this.eventId)
        .then((result) => {
          // console.log(result);
          this.home_cancel = setTimeout(() => {
            this.screenDisconnected = "CASE_1";
          }, 20000);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleConnectPush() {
      fetchVideoCallStartInSchedule(this.eventId)
        .then((result) => {
          // console.log(result);
          this.connect_cancel = setTimeout(() => {
            this.videoDisconnected = "CASE_1";
          }, 20000);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    toggleScreenSharing() {
      if (this.sessionDie) {
        this.screenSharing.view = false;
        this.changeMode();
        return (this.screenSharing.view = false);
      }
      if (this.screenSharing.view) {
        this.screenSharing.view = false;
        this.changeMode();
      } else {
        this.screenSharing.view = true;
        this.changeMode();
        if (!this.screenSharing.stream) {
          fetchScreenSharingStartInSchedule(this.eventId)
            .then((result) => {
              this.screenSharing.view = true;
              this.changeMode();
              this.home_cancel = setTimeout(() => {
                this.screenDisconnected = "CASE_1";
              }, 20000);
            })
            .catch((error) => {
              this.handleError(error);
            });
        }
      }
    },
    errorHandler(error) {
      console.log("tokbox", error);
    },
    disconnectSession() {
      this.session.disconnect(this.session.connection, (error) => {
        if (error) {
          this.errorHandler(error);
        }
      });
    },
    async disconnect() {
      this.clickDisconnectSession = true;
      this.sendDisconnect();
      this.disconnectSession();
      try {
        await this.$store.dispatch("videocall/closeInSchedule");
      } catch (error) {
        this.handleError(error);
      }
    },
    async endconnect() {
      this.clickDisconnectSession = true;
      this.sendDisconnect();
      this.disconnectSession();
      try {
        await this.$store.dispatch("videocall/endInSchedule");
      } catch (error) {
        this.handleError(error);
      }
    },
    sendDisconnect() {
      if (this.streams.length > 0) {
        this.session.signal(
          {
            type: "disconnect_therapist",

            // signalName: 'disconnect_session',
            // data: {}
          },
          (error) => {
            if (error) {
              console.log("signal error", error);
            }
          }
        );
      }
    },
    handleClose() {
      if (this.sessionDie) {
        return this.$store.dispatch("videocall/closeView");
      }
      this.$modal.show(
        Confirm,
        {
          type: "close",
          submit: this.disconnect,
          confirm: {
            title: "Close Video Call",
            message: "Are you sure you want to close the Video Call?",
          },
        },
        {
          height: "auto",
        }
      );
    },
    async remoteRequestGame(game) {
      if (this.screenSharing.view === false) {
        this.toggleScreenSharing();
      }
      const target = game.levels.find((g) => game.level);
      const contentTrainingTypeId = target.contentTrainingTypeId;
      // const targetSuccessCount = await getLevelList(game.contentTrainingTypeId);
      // debugger;
      try {
        const startGame = await getGameStart({
          contentTrainingTypeId,
          motionTypeName: game.motionComplexTypeName,
          // TODO 반복횟수 어찌 구할지..?
          targetSuccessCount: 999,
          adaptiveAromControl: 0, // 고정
          level: game.level,
          aromRatio: (game.rangeOfMotion || 0) / 100,
          totalPlayTime: Number(game.playTime || 1) * 60,
          maintainTime: game.holddingTime,
          featureName: game.featureName,
          patientId: this.patient.id,
          apiKey: this.apiKey,
          sessionId: this.sessionId,
          token: this.token,
        });
        if (startGame) {
          alert(`"${game.title}" 게임을 요청 했습니다.`);
        } else {
          alert(`"${game.title}" 게임을 요청 실패했습니다.`);
        }
      } catch (error) {
        alert(`"${game.title}" 게임을 요청 실패했습니다.`);
      }
    },
    async togglePresentation() {
      const self = this;
      const $el = document.querySelectorAll(".publisher.screen-sharing")[0];
      const screenPublisherElement = document.createElement("div");
      screenPublisherElement.className = "publisher-screen-sharing-item";
      if (this.sessionDie) {
        return (this.publisherScreenSharing.view = false);
      }
      if (this.publisherScreenSharing.view) {
        // 발표하기 끊기
        this.session.unpublish(this.publisherScreenSharing.publisher);
      } else {
        // const stream = await navigator.mediaDevices.getDisplayMedia({
        //   video: true,
        //   audio: true,
        // });
        // const audioTrack = stream.getAudioTracks()[0];
        // const videoTrack = stream.getVideoTracks()[0];

        // console.log(audioTrack);
        $el.appendChild(screenPublisherElement);
        this.publisherScreenSharing.view = true;

        const publisher = OT.initPublisher(
          screenPublisherElement,
          {
            // name: "portal_screen",
            name: String(self.userId),
            videoSource: "screen",
            // videoSource: videoTrack,
            publishAudio: true,
            // audioSource: audioTrack,
          },
          (error) => {
            if (error) {
              self.$emit("error", error);
              self.publisherScreenSharing.view = false;
              self.changeMode();
            } else {
              self.$emit("publisherScreenCompleted");
            }
          }
        );
        this.$emit("publisherScreenCreated", publisher);
        const publish = () => {
          self.session.publish(publisher, (error) => {
            if (error) {
              self.$emit("error", error);
            } else {
              self.$emit("publisherScreenConnected", publisher);
            }
          });
        };
        if (this.session && this.session.isConnected()) {
          publish();
        }
        publisher.on("streamDestroyed", function (event) {
          self.publisherScreenSharing.view = false;
          self.changeMode();
        });
        this.publisherScreenSharing.publisher = publisher;
        self.changeMode();
      }
    },
    handleEnd() {
      if (this.sessionDie) {
        return this.$store.dispatch("videocall/closeView");
      }
      this.$modal.show(
        Confirm,
        {
          type: "close",
          submit: this.endconnect,
          confirm: {
            title: "End Video Call",
            message: "Are you sure you want to end the Video Call?",
          },
        },
        {
          height: "auto",
        }
      );
    },
    handleError(error) {
      console.error("VideoCall", error);
      if (error) {
        this.$modal.show(
          Confirm,
          {
            type: "error",
            confirm: {
              title: "Error occurred",
              message: "Something is wrong with the server. Please try again.",
            },
          },
          {
            height: "auto",
          }
        );
      }
    },
    // 녹화 시작 21.01.27
    async handleRecordingStart() {
      if (!this.videoCall) return;
      try {
        const recordStart = await fetchVideoCallRecordStart(this.eventId, {
          session_id: this.sessionId,
        });
        if (recordStart) {
          this.isRecording = true;
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    // 녹화 종료 21.02.10
    async handleRecordingEnd() {
      try {
        const recordEnd = await fetchVideoCallRecordEnd(this.eventId, {
          session_id: this.sessionId,
        });
        if (recordEnd) {
          const toast = document.getElementById("toast-popup");
          toast.classList.add("active");
          setTimeout(function () {
            toast.className = toast.className.replace("active", "");
          }, 3000);
          this.isRecording = false;
        }
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  created() {
    console.log(`------------------------`);
    fetchDetailEventInfo(this.eventId)
      .then((result) => {
        this.patient = result.patients[0];
        this.guestYN = result.guest_yn;
        getGameCategories(this.patient.id, this.lang).then((gameCategories) => {
          console.log(gameCategories);
          this.gameCategories = gameCategories;
        });
      })
      .catch((error) => {
        this.handleError(error);
      });
    this.session = OT.initSession(this.apiKey, this.sessionId);
    this.session.connect(this.token, (error) => {
      // console.log("Session is connected");
      if (error) {
        console.log("Failed to connect: ", error.message);
        return this.errorHandler(error);
      }
      this.connect_cancel = setTimeout(() => {
        this.videoDisconnected = "CASE_1";
      }, 20000);
    });
    this.session.on("streamCreated", (event) => {
      // console.log("stream", event);
      if (event.stream.videoType === "camera") {
        //video call
        const serviceName = event.stream.connection.data.indexOf("therapist");
        if (serviceName < 0) {
          this.videoCall = event.stream;
        }
        clearTimeout(this.connect_cancel);
      } else if (event.stream.videoType === "screen") {
        //screen sharing
        if (!this.screenSharing.view) {
          this.screenSharing.view = true;
          this.changeMode();
        }
        this.screenSharing.stream = event.stream;
        clearTimeout(this.home_cancel);
      }
      this.streams.push(event.stream);
    });
    this.session.on("streamDestroyed", (event) => {
      const idx = this.streams.indexOf(event.stream);
      if (idx > -1) {
        this.streams.splice(idx, 1);
      }
      if (event.stream.videoType === "camera") {
        this.videoCall = null;
        this.videoDisconnected = "CASE_2";
      } else if (event.stream.videoType === "screen") {
        this.screenSharing.stream = null;
        this.screenDisconnected = "CASE_2";
      }
    });
    this.session.on("sessionDisconnected", (event) => {
      if (!this.clickDisconnectSession) {
        this.sessionDie = true;
      }
      console.log("You were disconnected from the session.", event.reason);
    });
    this.session.on("signal", function (event) {
      console.log(event);
    });
  },
  computed: {
    ...mapGetters({
      lang: "lang/language",
      userId: "user/id",
    }),
  },
};
</script>

<style lang="scss">
.VideoCall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  min-height: 690px;
  background: rgba(0, 0, 0, 1);
  z-index: 100;
  display: flex;
  flex-direction: column;

  .tokbox-sessions {
    width: 100%;
    height: 100%;
    background: #000000;
    position: relative;
    padding-bottom: 76px;
    .Session {
      width: 100%;
      height: 100%;
      position: relative;
      box-sizing: border-box;
      &.menu-open {
        padding-right: 350px;
      }
      // 화면 분할 정의
      .screen-wrap {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;

        .screen-item {
          overflow: hidden;
          position: absolute;
          transition: all ease-in 0.2s;
          .publisher-screen-sharing-item {
            width: 100% !important;
            height: 100% !important;
          }
          &.view-off {
            display: none;
          }
        }
        .publisher.screen-sharing {
          .OT_bar,
          h1,
          button {
            display: none;
          }
        }
        &.main-mode {
          // 환자
          .subscribers {
            // 카메라
            &.camera-screen {
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              &.publisher-main {
                .notice-message {
                  font-size: 16px;
                }
                .push-again-btn {
                  padding: 60px 0;
                  width: 100%;
                  .primary-type-btns {
                    margin-top: 15px;
                  }
                }
                right: 0;
                left: auto;
                width: 241px;
                height: 194px;
                z-index: 10;
              }
            }
            // 공유화면
            &.screen-sharing {
              top: 246px;
              right: 24px;
              left: auto;
            }
          }
          // 치료사
          .publisher {
            z-index: 3;
            // 카메라
            &.camera-screen {
              top: 0;
              right: 0;
              left: auto;
              &.publisher-main {
                width: 100%;
                height: 100%;
                z-index: 1;
              }
            }
            // 공유화면
            &.screen-sharing {
              top: 194px;
              right: 0;
              left: auto;
            }
          }
        }
        &.secondary-mode {
          // 환자
          .subscribers {
            // 카메라
            &.camera-screen {
              left: 0;
              top: 0;
              width: 50%;
              height: 100%;
            }
            // 공유화면
            &.screen-sharing {
              top: 246px;
              right: 24px;
              left: auto;
            }
          }
          // 치료사
          .publisher {
            z-index: 3;
            // 카메라
            &.camera-screen {
              border-left: 3px solid #606266;
              right: 0;
              top: 0;
              width: 50%;
              height: 100%;
            }
            // 공유화면
            &.screen-sharing {
              top: 246px;
              right: 24px;
              left: auto;
            }
          }
        }

        &.third-mode {
          // 환자
          .subscribers {
            // 카메라
            &.camera-screen {
              left: 0;
              top: 0;
              width: 50%;
              height: 35%;
            }
            // 공유화면
            &.screen-sharing {
              border-top: 3px solid #606266;
              left: 0;
              top: 35%;
              width: 100%;
              height: 65%;
            }
          }
          // 치료사
          .publisher {
            z-index: 3;
            // 카메라
            &.camera-screen {
              left: 50%;
              top: 0;
              width: 50%;
              height: 35%;
              border-left: 3px solid #606266;
            }
            // 공유화면
            &.screen-sharing {
              border-top: 3px solid #606266;
              left: 0;
              top: 35%;
              width: 100%;
              height: 65%;
              .publisher-screen-sharing-item {
                width: 100% !important;
                height: 100% !important;
              }
            }
          }
        }
        &.fourth-mode {
          // 환자
          .subscribers {
            // 카메라
            &.camera-screen {
              left: 0;
              top: 0;
              width: 50%;
              height: 50%;
            }
            // 공유화면
            &.screen-sharing {
              border-top: 3px solid #606266;
              left: 0;
              top: 50%;
              width: 50%;
              height: 50%;
            }
          }
          // 치료사
          .publisher {
            z-index: 3;
            // 카메라
            &.camera-screen {
              border-left: 3px solid #606266;
              left: 50%;
              top: 0;
              width: 50%;
              height: 50%;
            }
            // 공유화면
            &.screen-sharing {
              border-left: 3px solid #606266;
              border-top: 3px solid #606266;
              left: 50%;
              top: 50%;
              width: 50%;
              height: 50%;
            }
          }
        }

        #toast-popup {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(-24px, 24px);
          width: 450px;
          height: auto;
          padding: 33px 24px;
          background-color: #fff;
          border: 1px solid #d0d4dc;
          box-sizing: border-box;
          box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
          border-radius: 12px;
          font-family: Manrope3;
          //
          visibility: hidden;
          z-index: 10;
          h1 {
            font-weight: bold;
            font-size: 24px;
            line-height: 33px;
          }

          p {
            margin-top: 26px;
            font-size: 16px;
            color: #606266;
            line-height: 26px;
          }

          &.active {
            visibility: visible;
            -webkit-animation: fadein 1s, fadeout 1s 2.5s;
            animation: fadein 1s, fadeout 1s 2.5s;
          }

          @-webkit-keyframes fadein {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          @keyframes fadein {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          @-webkit-keyframes fadeout {
            from {
              opacity: 1;
            }
            to {
              opacity: 0;
            }
          }

          @keyframes fadeout {
            from {
              opacity: 1;
            }
            to {
              opacity: 0;
            }
          }
        }
      }

      .medical-records {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        margin-left: auto;
        width: 350px;
        flex-shrink: 0;
        background: #ffffff;
      }
      .notice-message {
        font-size: $fontSize3;
        color: #ffffff;
        &.session-die {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 5;
          transform: translate(-50%, -50%);
          width: 400px;
          height: 150px;
          background: black;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 28px;
        }
      }
      .subscribers {
        &.video-call {
          .video-sub {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .sub-wrapper {
          width: 100%;
          height: 100%;
          position: relative;
          z-index: 2;
          & + .push-again-btn {
            display: none;
          }
        }
        .push-again-btn {
          background: #000000;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          z-index: 1;

          .primary-type-btns {
            margin-top: 48px;
          }
        }
      }
    }
    .OT_publisher {
      float: left;
      position: relative;
      z-index: 1;
    }
  }

  .control-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 14px 0;
    background: $fontColor1;
    z-index: 5;
    overflow: hidden;
    height: 76px;
    box-sizing: border-box;
    .button-wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 272px;
      overflow: hidden;
      button {
        display: block;
        float: left;
        margin: 0 10px;
        border: solid 1px #606266;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        text-align: center;
        position: relative;
        & > span {
          display: inline-block;
          vertical-align: middle;
        }

        &:disabled {
          opacity: 0.3;
          &:hover {
            background: none;
          }
        }
        &.close-video-call {
          .icon-ic_call {
            color: $uiColor1;
          }
        }
        &.active {
          background: rgba(255, 255, 255, 0.15);
        }
        &:hover {
          background: #ffffff;
        }
        &.screen-share-btn,
        &.text-memo-btn {
          // background: $fontColor2;

          &:not([disabled]):hover {
            // background: #ffffff;
            [class^="icon-"],
            [class*=" icon-"] {
              color: $fontColor1;
            }
          }
        }
        // 녹화버튼 css
        &.recording-btn {
          .icon-ic_recording {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24px;
            height: 24px;
            background-color: $uiColor1;
            border-radius: 50%;
          }

          &.stop {
            .icon-ic_recording {
              width: 16px;
              height: 16px;
              border-radius: 2px;
            }
          }
        }
        [class^="icon-"],
        [class*=" icon-"] {
          color: #ffffff;
        }

        .icon-ic_call {
          position: relative;
          transform: rotateZ(135deg);
        }
      }
    }
    .start-wrapper {
      float: left;
      padding-left: 24px;
      .presentation_button {
        color: white;
        .icon {
          display: block;
          margin-bottom: 5px;
          height: 24px;
        }
      }
    }
    .end-wrapper {
      padding-right: 24px;
      float: right;
      .end_cal_button {
        border: none;
        color: white;
        background-color: transparent;
        cursor: pointer;
        display: inline-block;
        .icon {
          display: block;
          margin-bottom: 5px;
          height: 24px;
        }
      }
    }
  }
}
</style>
