<template>
  <section class="report-section training-ratio">
    <h1>{{ $t("page.patient.neofectHome.data.trainingType") }}</h1>
    <div class="report-contents">
      <Loading v-if="loading" />
      <ul>
        <li class="report-list" v-for="(fr, index) in featureRatio" :key="index">
          <span class="label">{{ fr.name }}</span>
          <span class="value">{{ fr.ratio | numFormat("0.0") }}%</span>
          <div class="in-decrease" v-if="isRangeCompare" v-bind:class="{decrease: fr.genRatio < 0, increase: fr.genRatio > 0}">
            <span class="arrow" v-bind:class="{down: fr.genRatio < 0, up: fr.genRatio > 0}"></span>
            {{ removeNumberMinusSign(fr.genRatio) | numFormat("0.0") }}%
          </div>
        </li>
      </ul>
      <div v-if="isNoData" class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
      <div v-if="error" class="error">Error!</div>
    </div>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { getMotionFeatureRatio } from "@/api/report.js";
export default {
  name: "MotionFeatureRatio",
  data() {
    return {
      featureRatio: null,
      loading: false,
      isNoData: false,
      error: false
    };
  },
  components: {
    Loading
  },
  props: {
    device: {
      type: Object
    },
    dateRange: {
      type: Object
    },
    toDateRange: {
      type: Object
    },
    isRangeCompare: Boolean
  },
  computed: {
    patient: function() {
      return this.$store.getters.selectedPatient;
    }
  },
  watch: {
    device(newValue, oldValue) {
      this.featureRatio = null;
      this.error = false;
      this.isNoData = false;
      this.loadFeatureRatio();
    },
    dateRange(newValue, oldValue) {
      this.loadFeatureRatio();
    },
    toDateRange(newValue, oldVAlue) {
      this.loadFeatureRatio();
    },
    isRangeCompare(newValue, oldValue) {
      this.loadFeatureRatio();
    }
  },
  mounted() {
    this.loadFeatureRatio();
  },
  methods: {
    removeNumberMinusSign: function (number) {
      if (number < 0) {
        return number * -1;
      } else if (number === 0) {
        return '-';
      }
      return number;
    },
    async loadFeatureRatio() {
      if (!this.patient || !this.device) return;
      let featureRatio;
      let targetFeatureRatio;
      try {
        this.loading = true;
        featureRatio = await getMotionFeatureRatio(
          this.patient.id,
          this.device.id,
          this.$moment(this.dateRange.startDate).toISOString(),
          this.$moment(this.dateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString()
        );
        targetFeatureRatio = await getMotionFeatureRatio(
          this.patient.id,
          this.device.id,
          this.$moment(this.toDateRange.startDate).toISOString(),
          this.$moment(this.toDateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString()
        );
      } catch (error) {
        this.loading = false;
        this.error = true;
      }

      this.loading = false;
      this.featureRatio = featureRatio.map(item=>{
        const findItem = targetFeatureRatio.find(fItem=>fItem.name===item.name);
        item.genRatio = item.ratio - findItem.ratio;
        return item;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.training-ratio {
  .report-contents {
    box-sizing: border-box;
    height: 120px;
    padding: 17px 24px;
    position: relative;
    overflow: hidden;
    ul {
      overflow: hidden;
      li {
        width: 50%;
        color: $fontColor1;
        font-size: $fontSize6;
        line-height: 19px;
        margin: 7px 0;
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        .label {
          width: 70%;
          display: inline-block;
        }
      }
    }
    .no-data,
    .error {
      width: 100%;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      &.error {
        z-index: 2;
      }
    }
  }
}
</style>
