<template>
  <div class="CreateAdvice">
    <section>
      <div class="contents">
        <h1>{{title}}</h1>
        <textarea :placeholder="contentsPlaceholder" v-model="contents"></textarea>
      </div>

      <!-- 서밋버튼 -->
      <div class="popup-submit-btns">
        <button type="button" class="accept-btn" @click="handleSubmit">{{ $t("page.patient.advice.modal.RegisterButton") }}</button>
      </div>
      <div class="popup-btns">
        <!-- 팝업 컨트롤 버튼 -->
        <button type="button" class="close-btn" @click="handleClose">
          <span class="icon-ic_delete ic-icon">
            <span class="path1" />
            <span class="path2" />
          </span>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CreateAdvice",
  props: {
    title: String,
    patient: Object,
    submit: Function
  },
  data() {
    return {
      contents: ""
    };
  },
  computed: {
    contentsPlaceholder() {
      return this.$t("page.patient.advice.modal.contentsPlaceholer")
    }
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleSubmit() {
      this.submit(this.patient, this.contents);
      this.handleClose();
    }
  }
};
</script>

<style lang='scss' scoped>
.CreateAdvice {
  h1 {
    font-size: $fontSize2;
    font-weight: 500;
    padding: 50px 0 38px 0;
  }
  .contents {
    padding: 0 24px 40px 24px;
    textarea {
      height: 120px;
    }
  }
}
</style>
