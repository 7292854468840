<template>
  <section class="MappingPatient">
    <div class="mapping-top">
      <div class="mapping-search">
        <div class="search-select">
          <Select
            v-model="selectedSortingType"
            :options="sortingOption"
            label="label"
            placeholder
            :components="{ OpenIndicator, Deselect }"
          />
        </div>
        <div class="search">
          <span class="icon-ic_search" @click="searchTherapist">
            <span class="path1" />
            <span class="path2" />
          </span>
          <input v-model="searchText" type="text" @keypress.enter="searchTherapist">
        </div>
      </div>
    </div>
    <table>
      <colgroup>
        <col width="60px">
        <col width="160px">
        <col width="210px">
        <col width="80px">
        <col width="80px">
        <col width="150px">
        <col width="200px">
        <col width="100px">
      </colgroup>
      <thead>
        <tr>
          <th>No.</th>
          <th class="align-left">{{ $t("adminPage.management.mapping.patientProvider.list.header.name") }}</th>
          <th class="align-left">{{ $t("adminPage.management.mapping.patientProvider.list.header.email") }}</th>
          <th>{{ $t("adminPage.management.mapping.patientProvider.list.header.age") }}</th>
          <th>{{ $t("adminPage.management.mapping.patientProvider.list.header.gender") }}</th>
          <th>{{ $t("adminPage.management.mapping.patientProvider.list.header.created") }}</th>
          <th>{{ $t("adminPage.management.mapping.patientProvider.list.header.assignedTo") }}</th>
          <th />
        </tr>
      </thead>
    </table>
    <div class="custom-scrollbar table-scroll">
      <table>
        <colgroup>
          <col width="60px">
          <col width="160px">
          <col width="210px">
          <col width="80px">
          <col width="80px">
          <col width="150px">
          <col width="200px">
          <col width="100px">
        </colgroup>
        <tbody v-if="list.length > 0">
          <tr v-for="(item, index) of list" :key="item.index">
            <td>{{ item.index }}</td>
            <td class="align-left">
              {{
                !item.name ? "-" : item.name.includes("null") ? "-" : item.name
              }}
            </td>
            <td class="align-left">{{ item.email }}</td>
            <td>{{ item.age }}</td>
            <td>{{ item.gender ? item.gender[0] : "-" }}</td>
            <td>{{ $moment(item.created_time).format("YYYY-M-D H:mm") }}</td>
            <td class="therapist-select">
              <Select
                v-model="selectedTherapist[index]"
                :options="allTherapist"
                label="name"
                :placeholder="selectPlaceholder"
                :components="{ OpenIndicator, Deselect }"
              />
            </td>
            <td class="align-right default-type-btns small-btns">
              <button
                v-if="changedTherapist[index]"
                type="button"
                @click="
                  handleSingleMapping(
                    item.patient_id,
                    selectedTherapist[index],
                    index
                  )
                "
              >{{ $t("adminPage.management.mapping.patientProvider.button.save") }}</button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">{{ $t("adminPage.management.mapping.patientProvider.empty") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-pagination v-model="currentPage" :page-count="totalPage" />
  </section>
</template>

<script>
import vPagination from "vue-plain-pagination";
import Select from "vue-select";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import {
  fetchGetClinicPatient,
  fetchGetClinicTherapist,
  fetchMappingClinicPatientToTherapist
} from "@/api/clinic.js";
import { mapGetters } from "vuex";
export default {
  name: "MappingPatient",
  components: {
    vPagination,
    Select
  },
  mixins: [SelectMixin, ErrorMixin],
  data() {
    return {
      currentPage: 1,
      size: 15,
      totalCount: 1,
      totalPage: 1,
      searchText: "",
      sortingOption: [
        {
          label: this.$t("adminPage.management.mapping.patientProvider.selectOption.all"),
          value: "ALL"
        },
        {
          label: this.$t("adminPage.management.mapping.patientProvider.selectOption.name"),
          value: "NAME"
        },
        {
          label: this.$t("adminPage.management.mapping.patientProvider.selectOption.email"),
          value: "EMAIL"
        }
      ],
      selectedSortingType: {
        label: this.$t("adminPage.management.mapping.patientProvider.selectOption.all"),
        value: "ALL"
      },
      allTherapist: [],
      selectedTherapist: [],
      list: []
    };
  },
  computed: {
    ...mapGetters({
      clinic: "user/clinic"
    }),
    selectPlaceholder: function() {
      return this.$t("adminPage.management.mapping.patientProvider.selectPlaceholder");
    },
    changedTherapist: function() {
      const checkChanged = [];
      this.list.forEach((item, index) => {
        if (
          this.selectedTherapist[index] &&
          this.selectedTherapist[index].therapist_id !== item.therapist_id
        ) {
          checkChanged[index] = this.selectedTherapist[index];
        }
      });
      return checkChanged;
    }
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedTherapist = [];
        this.searchTherapist();
      }
    }
  },
  async created() {
    try {
      const clinicId = this.clinic.id;
      let getTherapist = await fetchGetClinicTherapist(clinicId, {
        size: 1000
      });
      if (getTherapist.list.length > 0) {
        getTherapist = getTherapist.list.map(item => {
          const newItem = {
            name: item.name,
            therapist_id: item.therapist_id
          };
          return newItem;
        });
      }
      this.allTherapist = getTherapist;
    } catch (error) {
      this.handleError(error, "MappingPatient > Get Clinic Therapist API");
    }
    this.searchTherapist();
  },
  methods: {
    async searchTherapist() {
      const search = {
        page: this.currentPage,
        size: this.size,
        keyword: this.searchText,
        search_type: this.selectedSortingType.value,
        sorts: [{ field: "time", order: "desc" }]
      };
      try {
        this.selectedTherapist = [];

        const result = await fetchGetClinicPatient(this.clinic.id, search);
        this.totalCount = result.total_count < 1 ? 1 : result.total_count;
        this.totalPage = result.total_page < 1 ? 1 : result.total_page;
        const addIndex = result.list.map((item, index) => {
          if (item.therapist_id) {
            this.selectedTherapist[index] = {
              name: item.therapist_name,
              therapist_id: item.therapist_id
            };
          }
          item.index =
            result.total_count - index - (this.currentPage - 1) * this.size;
          return item;
        });
        this.list = addIndex;
      } catch (error) {
        this.handleError(error, "MappingPatient > Get Clinic Patient API");
      }
    },
    async handleSingleMapping(patientId, therapist, index) {
      try {
        const clinicId = this.clinic.id;
        const result = await fetchMappingClinicPatientToTherapist(
          clinicId,
          patientId,
          therapist.therapist_id
        );
        if (result) {
          this.list[index].therapist_id = therapist.therapist_id;
        }
      } catch (error) {
        this.handleError(
          error,
          "MappingPatient > Clinic Patient to Therapist Mapping API"
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.MappingPatient {
  background: #ffffff;
  border: 1px solid $borderColor1;
  border-radius: 3px;
  .mapping-top {
    padding: 16px;
    display: flex;
    .mapping-search {
      display: flex;
      align-content: center;
      .search {
        padding: 0 16px;
        position: relative;
        .icon-ic_search {
          position: absolute;
          top: 50%;
          right: 32px;
          transform: translate(0, -50%);
          font-size: $fontSize2;
          color: $fontColor2;
        }
        input[type="text"] {
          padding-right: 55px;
          height: 35px;
          border-radius: 2px;
        }
      }
      .search-select {
        width: 110px;
        ::v-deep .v-select {
          background: #ffffff;
          .vs__dropdown-toggle {
            border-radius: 2px;
          }
          .vs__dropdown-menu {
            min-width: 110px;
          }
        }
      }
    }

    .control-btns {
      margin-left: auto;
      display: flex;
      justify-content: center;

      button {
        &:last-of-type {
          margin-left: 8px;
        }
      }
    }
  }
  .patient-count {
    button {
      text-decoration: underline;
      font-size: $fontSize7;
      color: $fontColor1;
    }
  }
  table {
    tbody {
      tr:first-of-type {
        th,
        td {
          border-top: 0 none;
        }
      }
    }
  }
  .therapist-select {
    ::v-deep .v-select {
      .vs__dropdown-toggle {
        height: 35px;
      }
      .vs__selected {
        color: $fontColor1;
      }
    }
  }
}
</style>
