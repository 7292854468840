<template>
  <section class="ForgotPassword">
    <div v-if="!send">
      <h1>{{ $t("page.forgotPassword.title") }}</h1>
      <div class="notice">{{ $t("page.forgotPassword.description") }}</div>
      <form ref="loginForm" @submit.prevent>
        <div ref="email" class="email">
          <label for="email">{{ $t("page.forgotPassword.email.title") }}</label>
          <input
            id="email"
            v-model="email"
            placeholder="Email"
            type="text"
            @keypress.enter="handleSubmit"
          >
          <span ref="emailErrorMessage" class="message">{{ $t("page.forgotPassword.email.error.invalidEmail") }}</span>
        </div>
        <div ref="submitBtn" class="submit-btns" :class="submitDisable ? 'disabled' : ''">
          <button type="button" @click="handleSubmit">{{ $t("page.forgotPassword.resetPasswordButton") }}</button>
        </div>
        <!--
          * 현재 email 찾기 기능은 비활성화 됨
          <div class="aside-btns">
            <router-link to="/account/email">Forgot ID (Email)</router-link>
          </div>
        -->
      </form>
    </div>
    <div v-else>
      <h1 v-if="!resend">{{ $t("page.forgotPassword.reset.sent.title") }}</h1>
      <h1 v-else>{{ $t("page.forgotPassword.reset.reSent.title") }}</h1>
      <div class="notice">
        <div v-if="!resend">
          <p v-for="item in $t('page.forgotPassword.reset.sent.description')" :key="item+$t('page.forgotPassword.reset.sent.description')">{{ item }}</p>
        </div>
        <div v-else>
          <p v-for="item in $t('page.forgotPassword.reset.reSent.description')" :key="item+$t('page.forgotPassword.reset.reSent.description')">{{ item }}</p>
        </div>
        <button
          type="button"
          @click="handleSubmit"
        >{{ $t("page.forgotPassword.reset.link") }}</button>.
      </div>
      <div class="primary-type-btns big-btns login-btn">
        <router-link to="/login">{{ $t("page.forgotPassword.reset.logIn") }}</router-link>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable vue/require-v-for-key */

import { fetchResetPassword } from "@/api/user.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
export default {
  name: "ForgotPassword",
  mixins: [ErrorMixin],
  data() {
    return {
      email: "",
      send: false,
      resend: false
    };
  },
  computed: {
    submitDisable: function() {
      const re = new RegExp(
        "^[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*\\.[a-zA-Z]{2,3}$",
        "i"
      );
      return !re.test(this.email);
    }
  },
  watch: {
    email: function(newVal, oldVal) {
      this.$refs.email.classList.remove("wrong");
      if (newVal.length < 1) {
        return;
      }
      const re = new RegExp(
        "^[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*\\.[a-zA-Z]{2,3}$",
        "i"
      );
      if (!re.test(newVal)) {
        const refs = this.$refs;
        refs.emailErrorMessage.innerText = this.$t("page.forgotPassword.email.error.invalidEmail");
        refs.email.classList.add("wrong");
        return refs.email.children[1].focus();
      }
    }
  },
  methods: {
    handleSubmit() {
      if (this.submitDisable) {
        return;
      }
      this.resetPassword();
    },
    resetPassword() {
      fetchResetPassword(this.email)
        .then(result => {
          console.log(result);
          if (result && !this.send) {
            this.send = true;
          } else if (result && this.send) {
            this.resend = true;
          }
        })
        .catch(error => {
          console.error(error.data);
          if (error.status === 404) {
            const refs = this.$refs;
            refs.emailErrorMessage.innerText = this.$t("page.forgotPassword.email.error.exist");
            refs.email.classList.add("wrong");
            refs.email.children[1].focus();
          } else {
            this.handleError(error, "ForgotPassword");
          }
        });
    }
  }
};
</script>

<style lang='scss' scoped>
.ForgotPassword {
  max-width: 350px;
  width: 100%;
  margin: auto;
  margin-top: 130px;
  color: $fontColor1;
  h1 {
    font-weight: 800;
    font-size: $fontSize1;
    letter-spacing: -0.4px;
    line-height: 45px;
  }
  .email {
    margin-top: 60px;
    label {
      font-size: $fontSize7;
    }
    input[type="text"] {
      margin-top: 8px;
    }
    &.wrong {
      input[type="password"],
      input[type="text"] {
        border-color: $uiColor1;
      }
      .message {
        display: inline-block;
      }
    }
  }
  .message {
    margin-top: 4px;
    display: none;
    color: $uiColor1;
    background: rgba(244, 67, 54, 0.25);
    padding: 4px 16px;
    font-size: $fontSize6;
  }
  .notice {
    margin-top: 45px;
    color: $fontColor2;
    font-size: $fontSize5;
    line-height: 24px;
    button {
      font-size: $fontSize5;
      text-decoration: underline;
    }
  }
  .login-btn {
    margin-top: 75px;
  }
  .aside-btns {
    margin-top: 40px;
    display: flex;
    a {
      text-decoration: none;
      color: $fontColor2;
      font-size: $fontSize6;
    }
  }
}
</style>
