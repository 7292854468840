/* eslint-disable no-case-declarations */
import moment from "moment";
import { forEach } from "lodash";

const repeatDateCalculator = (startDate, repeatData) => {
  let repeatDateList = [];

  if (repeatData.isNotRepeat) {
    return (repeatDateList = [moment(startDate).format("YYYY-MM-DD")]);
  }

  const {
    selectedRepeatType,
    daysRepeat,
    checkedDays,
    repeatCount
  } = repeatData;
  const start = moment(startDate);
  let repeat = repeatCount - 1; // startDate가 첫 날 혹은 첫 주이므로 -1
  let to;
  switch (selectedRepeatType) {
    // 일 반복
    case "Days":
      to = start.add(daysRepeat * repeat, "days"); // 반복 마지막 날

      for (
        let m = moment(startDate);
        m.diff(to, "days") <= 0;
        m.add(daysRepeat, "days")
      ) {
        repeatDateList.push(m.format("YYYY-MM-DD"));
      }
      break;
    // 주 반복
    case "Week":
      const daySorter = {
        mon: 1,
        tue: 2,
        wed: 3,
        thu: 4,
        fri: 5,
        sat: 6,
        sun: 7
      };
      const repeatDayofWeek = checkedDays.map(day => {
        return daySorter[day];
      });
      // 반복 주 계산
      const totalWeek = [];
      const seletedDay = start.day();
      // 이번주 남은 날에 선택한 요일이 없을 경우 반복 횟수를 마이너스 하지 않음
      repeatDayofWeek.map(repeatDay => {
        if (repeatDay < seletedDay) {
          repeat = repeatCount;
        } else {
          repeat = repeatCount - 1;
        }
      });

      to = start.add(7 * repeat, "days");
      for (
        let m = moment(startDate);
        m.diff(to, "days") <= 0;
        m.add(7, "days")
      ) {
        totalWeek.push(m.format("YYYY-MM-DD"));
      }
      // 반복 주 별 요일 계산
      totalWeek.map(date => {
        forEach(repeatDayofWeek, _date => {
          if (moment(startDate).diff(moment(date).day(_date), "days") <= 0) {
            repeatDateList.push(
              moment(date)
                .day(_date)
                .format("YYYY-MM-DD")
            );
          }
        });
      });

      break;
    default:
      break;
  }

  return repeatDateList;
};

export { repeatDateCalculator };
