var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShowChat
    ? _c("div", { staticClass: "TextChat" }, [
        _c("div", { staticClass: "popup-control" }, [
          _c("button", { attrs: { type: "button" } }, [
            _c(
              "span",
              { staticClass: "icon-ic_delete", on: { click: _vm.toggleChat } },
              [
                _c("span", { staticClass: "path1" }),
                _vm._v(" "),
                _c("span", { staticClass: "path2" })
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "chat-wrapper" },
          [
            _c("PatientList"),
            _vm._v(" "),
            _vm.selectedInfo !== undefined
              ? _c("ChatRoom", { key: _vm.selectedInfo.userId })
              : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }