var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "eventDetail",
      staticClass: "EventDetail",
      class: _vm.eventDetailInfo.element ? "" : "center",
      style: { top: _vm.coordinates.y + "px", left: _vm.coordinates.x + "px" }
    },
    [
      _c("div", { staticClass: "Event-contents" }, [
        _c("div", { class: "Event-title " + _vm.eventDetailInfo.className }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.eventDetailInfo.className === "group4"
                  ? _vm.$t("page.calendar.eventDetail.type.providerLive")
                  : _vm.typeFilter(_vm.eventDetailInfo.title)
              ) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "Event-date" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", {
            staticClass: "date-string",
            domProps: {
              innerHTML: _vm._s(
                _vm.convertTimeString(
                  _vm.eventDetailInfo.start,
                  _vm.eventDetailInfo.end
                )
              )
            }
          })
        ]),
        _vm._v(" "),
        _vm.eventDetailInfo.className === "group2"
          ? _c("div", { staticClass: "Event-game" }, [
              _vm._m(1),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.eventDetailInfo.game === undefined
                      ? "Nothing chosen"
                      : _vm.eventDetailInfo.game.game_title
                  ) +
                  "\n    "
              )
            ])
          : _vm.eventDetailInfo.className === "group4"
          ? _c("div", [
              _c("div", { staticClass: "Event-live-contents" }, [
                _c("div", { staticClass: "subject" }, [
                  _c("span", [_vm._v("Topic")]),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.eventDetailInfo.title) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "summary" }, [
                  _vm._v(_vm._s(_vm.eventDetailInfo.contents))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "Event-live-link" }, [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.eventDetailInfo.contents_link,
                      target: "_blank"
                    }
                  },
                  [_vm._v(_vm._s(_vm.eventDetailInfo.contents_link))]
                )
              ])
            ])
          : _vm.eventDetailInfo.className === "group5"
          ? _c("div", { staticClass: "Event-general-contents" }, [
              _vm._v(_vm._s(_vm.eventDetailInfo.contents))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.eventDetailInfo.className === "group1" ||
        _vm.eventDetailInfo.className === "group2" ||
        _vm.eventDetailInfo.className === "group3" ||
        _vm.eventDetailInfo.className === "group6"
          ? _c("div", { staticClass: "Event-patients" }, [
              _c("div", { staticClass: "patients-heading" }, [
                _vm._m(3),
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.attendeeFilter(_vm.eventDetailInfo.patients.length)
                    ) +
                    "\n      "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "patients-list" }, [
                _c(
                  "ul",
                  _vm._l(_vm.eventDetailInfo.patients, function(
                    patient,
                    index
                  ) {
                    return _c("li", { key: index }, [
                      _c("div", { staticClass: "patient-name" }, [
                        _vm._m(4, true),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(patient.name))])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "patient-time" }, [
                        _vm._v(
                          _vm._s(
                            _vm.convertTimeString(patient.start, patient.end)
                          )
                        )
                      ])
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "Event-videocall",
                  attrs: { if: "eventDetailInfo.className === 'group1'" }
                },
                [
                  _c("div", { staticClass: "Event-live-link" }, [
                    _vm._m(5),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard",
                            value: _vm.baseURL + "/" + _vm.eventDetailInfo.id,
                            expression: "`${baseURL}/${eventDetailInfo.id}`"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.copySucess,
                            expression: "copySucess",
                            arg: "success"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.copyError,
                            expression: "copyError",
                            arg: "error"
                          }
                        ],
                        staticClass: "copy-btn",
                        attrs: { type: "button" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("page.calendar.eventDetail.copyLink"))
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.eventDetailInfo.className === "group1"
        ? _c(
            "div",
            {
              staticClass: "popup-submit-btns",
              class: _vm.overTime ? "disabled" : ""
            },
            [
              _c(
                "button",
                {
                  staticClass: "accept-btn",
                  attrs: { type: "button" },
                  on: { click: _vm.handleVideoCall }
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("page.calendar.eventDetail.button.videoCall"))
                  )
                ]
              )
            ]
          )
        : _vm.eventDetailInfo.className === "group3"
        ? _c(
            "div",
            {
              staticClass: "popup-submit-btns",
              class: _vm.overTime ? "disabled" : ""
            },
            [
              _c(
                "button",
                {
                  staticClass: "accept-btn",
                  attrs: { type: "button" },
                  on: { click: _vm.handleStartScreenSharing }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("page.calendar.eventDetail.button.screenSharing")
                    )
                  )
                ]
              )
            ]
          )
        : _vm.eventDetailInfo.className === "group6"
        ? _c("div", { staticClass: "popup-submit-btns" }, [
            _c("div", { staticClass: "primary-type-btns" }, [
              _c(
                "button",
                {
                  staticClass: "accept-btn",
                  attrs: { type: "button" },
                  on: { click: _vm.confirmRequest }
                },
                [_vm._v("Confirm")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "secondary-type-btns decline" }, [
              _c(
                "button",
                { attrs: { type: "button" }, on: { click: _vm.denyRequest } },
                [_vm._v("Decline")]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "popup-btns" }, [
        _vm.eventDetailInfo.className !== "group6"
          ? _c(
              "button",
              {
                staticClass: "del-btn icon-btn",
                attrs: { type: "button" },
                on: { click: _vm.handleDeleteEvent }
              },
              [_vm._m(6)]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.eventDetailInfo.className !== "group1" &&
        _vm.eventDetailInfo.className !== "group6"
          ? _c(
              "button",
              {
                staticClass: "edit-btn icon-btn",
                attrs: { type: "button" },
                on: {
                  "~click": function($event) {
                    return _vm.handleEditEvent($event)
                  }
                }
              },
              [_vm._m(7)]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close-btn icon-btn",
            attrs: { type: "button" },
            on: { click: _vm.handleClose }
          },
          [_vm._m(8)]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_time" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_star" }, [
      _c("span", { staticClass: "path1" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_connecting" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_group" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" }),
      _vm._v(" "),
      _c("span", { staticClass: "path3" }),
      _vm._v(" "),
      _c("span", { staticClass: "path4" }),
      _vm._v(" "),
      _c("span", { staticClass: "path5" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticClass: "icon-ic_check_circle_fill accept status-icon" },
      [
        _c("span", { staticClass: "path1" }),
        _vm._v(" "),
        _c("span", { staticClass: "path2" })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_connecting" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_bin" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" }),
      _vm._v(" "),
      _c("span", { staticClass: "path3" }),
      _vm._v(" "),
      _c("span", { staticClass: "path4" }),
      _vm._v(" "),
      _c("span", { staticClass: "path5" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_edit" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" }),
      _vm._v(" "),
      _c("span", { staticClass: "path3" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }