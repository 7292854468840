<template>
  <section class="report-section area-arom">
    <h1>{{ $t("page.patient.neofectHome.data.areaAROM") }}</h1>
    <div class="report-contents">
      <Loading v-if="loading" />
      <div v-if="isValid" class="report-wrapper">
        <div class="report-info">
          <div class="report-item">
            <span class="value">{{ aromArea.last.ratio.toFixed(0) }}%</span>
            <div class="in-decrease" v-if="isRangeCompare" v-bind:class="{decrease: aromArea.last.ratio - targetAromAreaRatio < 0, increase: aromArea.last.ratio - targetAromAreaRatio > 0}">
              <span class="arrow" v-bind:class="{down: aromArea.last.ratio - targetAromAreaRatio < 0, up: aromArea.last.ratio - targetAromAreaRatio > 0}"></span>
              {{ removeNumberMinusSign(aromArea.last.ratio.toFixed(0) - targetAromAreaRatio.toFixed(0)) }}
            </div>
          </div>
          <span class="label">
            {{
            $moment(new Date(aromArea.last.date)).format("MMM DD,YYYY")
            }}
          </span>
          <span class="value up">
            {{ diff }}%
            <span :class="upOrDown">
              <span class="path1"></span>
              <span class="path2"></span>
            </span>
          </span>
          <span class="label">
            Compared to the first time
            <br />
            ({{ $moment(new Date(aromArea.first.date)).format("MMM DD,YYYY") }})
          </span>
        </div>
        <div class="graph">
          <v-stage
            v-if="dimensions"
            :height="isNaN(dimensions.stageHeight) ? 0 : dimensions.stageHeight"
            :width="isNaN(dimensions.stageWidth) ? 0 : dimensions.stageWidth"
          >
            <v-layer>
              <v-rect
                :x="0"
                :y="0"
                :width="isNaN(dimensions.stageWidth) ? 0 : dimensions.stageWidth"
                :height="isNaN(dimensions.stageHeight) ? 0 : dimensions.stageHeight"
                fill="rgba(90,173, 244,0.1)"
              />
            </v-layer>
            <v-layer>
              <v-line
                :points="dimensions.points"
                fill="rgba(90,173, 244,0.2)"
                stroke="#5aadf5"
                :strokeWidth="2"
                :closed="true"
              />
            </v-layer>
          </v-stage>
        </div>
      </div>
      <div v-if="isNoData" class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
      <div v-if="error" class="no-data">Error!</div>
    </div>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { getBoardAromArea } from "@/api/report.js";
const canvasHeight = 200;
export default {
  name: "BoardAromArea",
  components: {
    Loading
  },
  data() {
    return {
      aromArea: null,
      targetAromAreaRatio: 0,
      diff: null,
      upOrDown: null,
      dimensions: null,
      isNoData: false,
      loading: false,
      error: false
    };
  },
  props: {
    device: {
      type: Object
    },
    dateRange: {
      type: Object
    },
    toDateRange: {
      type: Object
    },
    isRangeCompare: Boolean
  },
  computed: {
    patient: function() {
      return this.$store.getters.selectedPatient;
    },
    isValid: function() {
      return (
        this.aromArea &&
        this.aromArea.first &&
        this.aromArea.first.ratio &&
        this.aromArea.last &&
        this.aromArea.last.ratio
      );
    }
  },
  watch: {
    device(newValue, oldValue) {
      this.aromArea = null;
      this.diff = null;
      this.upOrDown = null;
      this.dimensions = null;
      this.error = false;
      this.isNoData = false;
      this.drawChart();
    },
    dateRange(newValue, oldValue) {
      this.drawChart();
    },
    toDateRange(newValue, oldVAlue) {
      this.drawChart();
    },
    isRangeCompare(newValue, oldValue) {
      this.drawChart();
    }
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    removeNumberMinusSign: function (number) {
      if (number < 0) {
        return `${(number * -1).toFixed(0)}%`;
      } else if (number === 0) {
        return '-';
      }
      return `${number.toFixed(0)}%`;
    },
    async drawChart() {
      if (!this.patient || !this.device) return;
      let aromArea;
      let targetAromArea;
      try {
        this.loading = true;
        aromArea = await getBoardAromArea(
          this.patient.id,
          this.device.id,
          this.$moment(this.dateRange.startDate).toISOString(),
          this.$moment(this.dateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString());
        if (
          !aromArea.first ||
          !aromArea.last ||
          ( aromArea.first.ratio === 0 &&
            aromArea.last.ratio === 0 &&
            aromArea.first.values.length ===0 &&
            aromArea.last.values.length === 0) ) {
          this.isNoData = true;
          this.loading = false;
          return;
        }
        if (this.isRangeCompare) {
          targetAromArea = await getBoardAromArea(
            this.patient.id,
            this.device.id,
            this.$moment(this.toDateRange.startDate).toISOString(),
            this.$moment(this.toDateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString());
          if (targetAromArea.last) {
            this.targetAromAreaRatio = targetAromArea.last.ratio;
          }
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }

      this.aromArea = aromArea;
      let firstRatio = this.aromArea.first ? this.aromArea.first.ratio : 0;
      let lastRatio = this.aromArea.last ? this.aromArea.last.ratio : 0;
      this.diff = Math.abs(lastRatio - firstRatio).toFixed(0);
      if (lastRatio > firstRatio) {
        this.upOrDown = "icon-ic_ascending_sort";
      } else if (firstRatio > lastRatio) {
        this.upOrDown = "icon-ic_descending_sort";
      }
      this.dimensions = this.calculateDimensions(
        canvasHeight,
        this.aromArea.last ? this.aromArea.last.values : []
      );
      this.loading = false;
    },
    calculateDimensions(height, data) {
      let stageHeight = height * 0.6;
      let circleRadius = (stageHeight * (100.0 / 55.0)) / 2.0;

      let stageWidth = circleRadius * 2;
      stageHeight = circleRadius * 1.05;

      let angleDiff = 180.0 / (data.length - 1);

      let angle = 0.0;
      let points = [];
      for (let ratio of data) {
        let radius = ratio * circleRadius;
        let x = radius * Math.cos(angle) + circleRadius;
        let y = stageHeight - radius * Math.sin(angle);
        points.push(x);
        points.push(y);

        angle += (angleDiff * Math.PI) / 180;
      }

      return {
        stageHeight,
        stageWidth,
        points
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.area-arom {
  .report-contents {
    height: 260px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    .report-wrapper {
      display: flex;
    }
    .report-info {
      width: 185px;
      flex-shrink: 0;
      float: left;
      .report-item{
        display: flex;
        .in-decrease{
          text-align: center;
          width: 25%;
          font-size: 13px;
          align-self: center;
          &.increase{
            color: blue;
          }
          &.decrease{
            color: red;
          }
        }
      }
      .value {
        color: $fontColor1;
        font-weight: 500;
        font-size: $fontSize2;
        display: flex;
        align-items: center;
        &.up {
          margin-top: 24px;
        }
        .icon-ic_ascending_sort {
          display: inline-block;
          margin-left: 10px;
          color: #477eea;
        }
        .icon-ic_descending_sort {
          display: inline-block;
          margin-left: 10px;
          color: #e1414a;

        }
      }
      .label {
        display: block;
        margin-top: 12px;
        color: $fontColor1;
        font-size: $fontSize6;
        line-height: 19px;
      }
    }
    .graph {
      margin-left: 30px;
      width: 240px;
      height: 165px;
    }
    .no-data,
    .error {
      width: 100%;
      height: 260px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      &.error {
        z-index: 2;
      }
    }
  }
}
</style>
