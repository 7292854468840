var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "CalendarSorting",
      style: { height: _vm.windowHeight + "px" }
    },
    [
      _c("div", { staticClass: "sort-patient" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("page.calendar.sideBar.title")))]),
        _vm._v(" "),
        _c("div", { staticClass: "search" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchText,
                expression: "searchText"
              }
            ],
            attrs: { type: "text", placeholder: _vm.searchPlaceholder },
            domProps: { value: _vm.searchText },
            on: {
              keyup: _vm.handleSearch,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchText = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "check-all" }, [
          _c(
            "button",
            {
              class: _vm.checkAllPatients,
              on: { click: _vm.handleAllPatients }
            },
            [_vm._v(_vm._s(_vm.$t("page.calendar.sideBar.selectAll")))]
          )
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "check-list custom-scrollbar" },
          _vm._l(_vm.searchedPatients, function(patient, index) {
            return _c("li", { key: index }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.checkedPatients,
                    expression: "checkedPatients"
                  }
                ],
                attrs: { id: patient.id, type: "checkbox" },
                domProps: {
                  value: patient.id,
                  checked: Array.isArray(_vm.checkedPatients)
                    ? _vm._i(_vm.checkedPatients, patient.id) > -1
                    : _vm.checkedPatients
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.checkedPatients,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = patient.id,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.checkedPatients = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.checkedPatients = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.checkedPatients = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: patient.id } }, [
                _vm._v(_vm._s(patient.name ? patient.name : "-"))
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "sort-type" }, [
        _c("h2", { staticClass: "sort-heading" }, [
          _vm._v(_vm._s(_vm.$t("page.calendar.sideBar.typeOfEvent")))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "check-all" }, [
          _c(
            "button",
            { class: _vm.checkAllTypes, on: { click: _vm.handleAllTypes } },
            [_vm._v(_vm._s(_vm.$t("page.calendar.sideBar.selectAll")))]
          )
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "check-list custom-scrollbar" },
          _vm._l(_vm.eventTypes, function(type, index) {
            return _c("li", { key: index }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.checkedTypes,
                    expression: "checkedTypes"
                  }
                ],
                attrs: { id: type, type: "checkbox" },
                domProps: {
                  value: type,
                  checked: Array.isArray(_vm.checkedTypes)
                    ? _vm._i(_vm.checkedTypes, type) > -1
                    : _vm.checkedTypes
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.checkedTypes,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = type,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.checkedTypes = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.checkedTypes = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.checkedTypes = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { class: type, attrs: { for: type } }, [
                _vm._v(_vm._s(_vm.typeFilter(type)))
              ])
            ])
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_search" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }