var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "international" },
    [
      _c(
        "el-dropdown",
        { attrs: { trigger: "click" }, on: { command: _vm.handleSetLanguage } },
        [
          _c("img", {
            staticClass: "international-icon",
            attrs: { src: _vm.international, alt: "international" }
          }),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "el-dropdown-item",
                { attrs: { disabled: _vm.lang === "en", command: "en" } },
                [_vm._v("\n        English\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-item",
                { attrs: { disabled: _vm.lang === "ko", command: "ko" } },
                [_vm._v("\n        한국어\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }