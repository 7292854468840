<template>
  <section class="RoleAssign">
    <div class="mapping-top">
      <div class="mapping-search">
        <div class="search-select">
          <Select
            v-model="selectedSortingType"
            :options="sortingOption"
            label="label"
            placeholder
            :components="{ OpenIndicator, Deselect }"
          />
        </div>
        <div class="search">
          <span class="icon-ic_search" @click="searchTherapist">
            <span class="path1" />
            <span class="path2" />
          </span>
          <input v-model="searchText" type="text" @keypress.enter="searchTherapist">
        </div>
      </div>
    </div>
    <table>
      <colgroup>
        <col width="60px">
        <col width="180px">
        <col width="240px">
        <col width="80px">
        <col width="200px">
        <col width="100px">
      </colgroup>
      <thead>
        <tr>
          <th>No.</th>
          <th class="align-left">{{ $t("adminPage.setting.roleAssign.list.header.name") }}</th>
          <th class="align-left">{{ $t("adminPage.setting.roleAssign.list.header.email") }}</th>
          <th>{{ $t("adminPage.setting.roleAssign.list.header.type") }}</th>
          <th>{{ $t("adminPage.setting.roleAssign.list.header.role") }}</th>
          <th />
        </tr>
      </thead>
    </table>
    <div class="custom-scrollbar table-scroll">
      <table>
        <colgroup>
          <col width="60px">
          <col width="180px">
          <col width="240px">
          <col width="80px">
          <col width="200px">
          <col width="100px">
        </colgroup>
        <tbody v-if="list.length > 0">
          <tr v-for="(item, index) of list" :key="item.index">
            <td>{{ item.index }}</td>
            <td class="align-left">{{ item.name }}</td>
            <td class="align-left">{{ item.email }}</td>
            <td>{{ !item.therapist_type ? "-" : item.therapist_type }}</td>
            <td class="therapist-select">
              <Select
                v-if="item.role_type !== 'SYSOWNER'"
                v-model="selectedTherapist[index]"
                :options="levelType"
                label="label"
                placeholder="Select"
                :components="{ OpenIndicator, Deselect }"
              />
              <span v-else class="main-owner">{{ $t("adminPage.setting.roleAssign.mainOwner") }}</span>
            </td>
            <td class="align-right default-type-btns small-btns">
              <button
                v-if="changedTherapist[index]"
                type="button"
                @click="
                  handleSingleMapping(item, selectedTherapist[index], index)
                "
              >{{ $t("adminPage.setting.roleAssign.button.save") }}</button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">{{ $t("adminPage.setting.roleAssign.empty") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-pagination v-model="currentPage" :page-count="totalPage" />
  </section>
</template>

<script>
import vPagination from "vue-plain-pagination";
import Select from "vue-select";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import {
  fetchGetClinicTherapistList,
  fetchSingleMappingClinicTherapist
} from "@/api/clinic.js";
import { mapGetters } from "vuex";
export default {
  name: "RoleAssign",
  components: {
    vPagination,
    Select
  },
  mixins: [SelectMixin, ErrorMixin],
  data() {
    return {
      currentPage: 1,
      size: 15,
      totalCount: 1,
      totalPage: 1,
      searchText: "",
      sortingOption: [
        {
          label: this.$t("adminPage.setting.roleAssign.selectOption.all"),
          value: "ALL"
        },
        {
          label: this.$t("adminPage.setting.roleAssign.selectOption.name"),
          value: "NAME"
        },
        {
          label: this.$t("adminPage.setting.roleAssign.selectOption.email"),
          value: "EMAIL"
        }
      ],
      selectedSortingType: {
        label: this.$t("adminPage.setting.roleAssign.selectOption.all"),
        value: "ALL"
      },
      levelType: [
        {
          label: this.$t("adminPage.setting.roleAssign.roleOption.provider"),
          value: "NORMAL"
        },
        {
          label: this.$t("adminPage.setting.roleAssign.roleOption.admin"),
          value: "ADMIN"
        },
        {
          label: this.$t("adminPage.setting.roleAssign.roleOption.owner"),
          value: "OWNER"
        }
      ],
      selectedTherapist: [],
      list: []
    };
  },
  computed: {
    ...mapGetters({
      clinic: "user/clinic"
    }),
    changedTherapist: function() {
      const checkChanged = [];
      this.list.forEach((item, index) => {
        if (
          this.selectedTherapist[index] &&
          this.selectedTherapist[index].value !== item.role_type
        ) {
          checkChanged[index] = this.selectedTherapist[index];
        }
      });
      return checkChanged;
    }
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedTherapist = [];
        this.searchTherapist();
      }
    }
  },
  created() {
    this.searchTherapist();
  },
  methods: {
    async searchTherapist() {
      const search = {
        page: this.currentPage,
        size: this.size,
        keyword: this.searchText,
        search_type: this.selectedSortingType.value,
        sorts: [{ field: "time", order: "desc" }]
      };
      try {
        const clinicId = this.clinic.id;
        const result = await fetchGetClinicTherapistList(clinicId, search);
        this.totalCount = result.total_count < 1 ? 1 : result.total_count;
        this.totalPage = result.total_page < 1 ? 1 : result.total_page;
        const addIndex = result.list.map((item, index) => {
          const findLevel = this.levelType.findIndex(type => {
            return type.value === item.role_type;
          });
          this.selectedTherapist[index] =
            findLevel < 0 ? { value: "SYSOWNER" } : this.levelType[findLevel];
          item.index =
            result.total_count - index - (this.currentPage - 1) * this.size;
          return item;
        });
        this.list = addIndex;
      } catch (error) {
        this.handleError(error, "RoleAssign > Get Clinic Therapist List API");
      }
    },
    async handleSingleMapping(item, role, index) {
      try {
        const clinicId = this.clinic.id;
        const newLevel = {
          therapist_id: item.therapist_id,
          role_type: role.value,
          user_id: item.id
        };
        const result = await fetchSingleMappingClinicTherapist(
          clinicId,
          newLevel
        );
        if (result) {
          this.list[index].role_type = role.value;
        }
      } catch (error) {
        this.handleError(
          error,
          "RoleAssign > Single Clinic Therapist Mapping API"
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.RoleAssign {
  background: #ffffff;
  border: 1px solid $borderColor1;
  border-radius: 3px;
  .mapping-top {
    padding: 16px;
    display: flex;
    .mapping-search {
      display: flex;
      align-content: center;
      .search {
        padding: 0 16px;
        position: relative;
        .icon-ic_search {
          position: absolute;
          top: 50%;
          right: 32px;
          transform: translate(0, -50%);
          font-size: $fontSize2;
          color: $fontColor2;
        }
        input[type="text"] {
          padding-right: 55px;
          height: 35px;
          border-radius: 2px;
        }
      }
      .search-select {
        width: 110px;
        ::v-deep .v-select {
          background: #ffffff;
          .vs__dropdown-toggle {
            border-radius: 2px;
          }
          .vs__dropdown-menu {
            min-width: 110px;
          }
        }
      }
    }

    .control-btns {
      margin-left: auto;
      display: flex;
      justify-content: center;

      button {
        &:last-of-type {
          margin-left: 8px;
        }
      }
    }
  }
  .patient-count {
    button {
      text-decoration: underline;
      font-size: $fontSize7;
      color: $fontColor1;
    }
  }
  table {
    tbody {
      tr:first-of-type {
        th,
        td {
          border-top: 0 none;
        }
      }
    }
  }
  .main-owner {
    padding-left: 17px;
  }
  .therapist-select {
    font-size: $fontSize6;
    text-align: left;
    ::v-deep .v-select {
      .vs__dropdown-toggle {
        height: 35px;
      }
      .vs__selected {
        color: $fontColor1;
      }
    }
  }
}
</style>
