var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "RequestPatient" },
    [
      _c("div", { staticClass: "request-top" }, [
        _c("div", { staticClass: "request-search" }, [
          _c(
            "div",
            { staticClass: "search-select" },
            [
              _c("Select", {
                attrs: {
                  options: _vm.sortingOption,
                  label: "label",
                  placeholder: "",
                  components: {
                    OpenIndicator: _vm.OpenIndicator,
                    Deselect: _vm.Deselect
                  }
                },
                model: {
                  value: _vm.selectedSortingType,
                  callback: function($$v) {
                    _vm.selectedSortingType = $$v
                  },
                  expression: "selectedSortingType"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "search" }, [
            _c(
              "span",
              {
                staticClass: "icon-ic_search",
                on: { click: _vm.searchPatients }
              },
              [
                _c("span", { staticClass: "path1" }),
                _vm._v(" "),
                _c("span", { staticClass: "path2" })
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchText,
                  expression: "searchText"
                }
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.searchText },
              on: {
                keypress: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.searchPatients($event)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchText = $event.target.value
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "control-btns default-type-btns small-btns",
            class: _vm.checkedList.length < 1 ? "disabled" : ""
          },
          [
            _c(
              "button",
              {
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.multipleManipulation(1)
                  }
                }
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("adminPage.request.patient.button.approve"))
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.multipleManipulation(2)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("adminPage.request.patient.button.deny")))]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("table", [
        _vm._m(0),
        _vm._v(" "),
        _c("thead", [
          _c("tr", [
            _c("th", [
              _c("div", { staticClass: "check-all" }, [
                _c("button", {
                  class: _vm.checkAll,
                  on: { click: _vm.handleAllCheck }
                })
              ])
            ]),
            _vm._v(" "),
            _c("th", [_vm._v("No.")]),
            _vm._v(" "),
            _c("th", { staticClass: "align-left" }, [
              _vm._v(
                _vm._s(_vm.$t("adminPage.request.patient.list.header.name"))
              )
            ]),
            _vm._v(" "),
            _c("th", { staticClass: "align-left" }, [
              _vm._v(
                _vm._s(_vm.$t("adminPage.request.patient.list.header.email"))
              )
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                _vm._s(_vm.$t("adminPage.request.patient.list.header.age"))
              )
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                _vm._s(_vm.$t("adminPage.request.patient.list.header.gender"))
              )
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                _vm._s(
                  _vm.$t("adminPage.request.patient.list.header.requestedTime")
                )
              )
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(
                _vm._s(_vm.$t("adminPage.request.patient.list.header.status"))
              )
            ]),
            _vm._v(" "),
            _c("th")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "custom-scrollbar table-scroll" }, [
        _c("table", [
          _vm._m(1),
          _vm._v(" "),
          _vm.list.length > 0
            ? _c(
                "tbody",
                _vm._l(_vm.list, function(item) {
                  return _c(
                    "tr",
                    {
                      key: item.index,
                      class:
                        _vm.checkedList.indexOf(item.index) > -1
                          ? "checked"
                          : ""
                    },
                    [
                      _c("th", [
                        item.status < 1
                          ? _c("span", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.checkedList,
                                    expression: "checkedList"
                                  }
                                ],
                                attrs: { id: item.index, type: "checkbox" },
                                domProps: {
                                  value: item.index,
                                  checked: Array.isArray(_vm.checkedList)
                                    ? _vm._i(_vm.checkedList, item.index) > -1
                                    : _vm.checkedList
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.checkedList,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = item.index,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.checkedList = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.checkedList = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.checkedList = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: item.index } })
                            ])
                          : _c("span", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.checkedList,
                                    expression: "checkedList"
                                  }
                                ],
                                attrs: {
                                  id: item.id,
                                  type: "checkbox",
                                  disabled: ""
                                },
                                domProps: {
                                  value: item.id,
                                  checked: Array.isArray(_vm.checkedList)
                                    ? _vm._i(_vm.checkedList, item.id) > -1
                                    : _vm.checkedList
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.checkedList,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = item.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.checkedList = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.checkedList = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.checkedList = $$c
                                    }
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: item.id } })
                            ])
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.index))]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "align-left",
                          class: item.name ? "" : ""
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                !item.name
                                  ? "-"
                                  : item.name.includes("null")
                                  ? "-"
                                  : item.name
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-left" }, [
                        _vm._v(_vm._s(item.email))
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.age))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(item.gender ? item.gender[0] : "-"))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm
                              .$moment(item.requested_time)
                              .format("YYYY-MM-DD HH:mm")
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          class:
                            item.status === 2
                              ? "denied"
                              : item.status === 1
                              ? "approved"
                              : ""
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                item.status === 0
                                  ? _vm.$t(
                                      "adminPage.request.patient.list.statusType.requested"
                                    )
                                  : item.status === 1
                                  ? _vm.$t(
                                      "adminPage.request.patient.list.statusType.approved"
                                    )
                                  : item.status === 2
                                  ? _vm.$t(
                                      "adminPage.request.patient.list.statusType.denied"
                                    )
                                  : item.status === 3
                                  ? _vm.$t(
                                      "adminPage.request.patient.list.statusType.cancel"
                                    )
                                  : ""
                              ) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "align-right" }, [
                        item.status < 1
                          ? _c(
                              "span",
                              { staticClass: "default-type-btns small-btns" },
                              [
                                _c(
                                  "button",
                                  {
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.singleManipulation(item, 1)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "adminPage.request.patient.list.button.approve"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.singleManipulation(item, 2)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "adminPage.request.patient.list.button.deny"
                                        )
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          : _c("span", { staticClass: "delete-btn icon-btn" }, [
                              _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDelete(item)
                                    }
                                  }
                                },
                                [_vm._m(2, true)]
                              )
                            ])
                      ])
                    ]
                  )
                }),
                0
              )
            : _c("tbody", [
                _c("tr", [
                  _c("td", { attrs: { colspan: "9" } }, [
                    _vm._v(
                      _vm._s(_vm.$t("adminPage.request.patient.list.empty"))
                    )
                  ])
                ])
              ])
        ])
      ]),
      _vm._v(" "),
      _c("v-pagination", {
        attrs: { "page-count": _vm.totalPage },
        model: {
          value: _vm.currentPage,
          callback: function($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage"
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { attrs: { width: "50px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "60px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "140px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "240px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "60px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "60px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "160px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "130px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "200px" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { attrs: { width: "50px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "60px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "140px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "240px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "60px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "60px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "160px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "130px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "200px" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_bin" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" }),
      _vm._v(" "),
      _c("span", { staticClass: "path3" }),
      _vm._v(" "),
      _c("span", { staticClass: "path4" }),
      _vm._v(" "),
      _c("span", { staticClass: "path5" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }