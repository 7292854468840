<template>
  <section v-if="patientInfo" class="Profile">
    <div class="patient-avatar">
      <div class="avatar">
        <span v-if="!patientInfo.image" class="icon-ic_group_fill">
          <span class="path1" />
          <span class="path2" />
        </span>
        <span v-else class="patient-image">
          <img :src="patientInfo.image" :alt="`${patientInfo.name}'s profile image'`">
        </span>
      </div>
    </div>
    <div class="patient-info">
      <section class="info-section">
        <h1>{{ $t("page.patient.profile.basicInformation.title") }}</h1>
        <ul>
          <li>
            <span class="label">{{ $t("page.patient.profile.basicInformation.age") }}</span>
            <span class="value">{{ patientInfo.age ? patientInfo.age : '-' }}</span>
          </li>
          <li>
            <span class="label">{{ $t("page.patient.profile.basicInformation.dateOfBirth") }}</span>
            <span
              class="value"
            >{{ patientInfo.birthday ? $moment(patientInfo.birthday).format("MMMM D, YYYY") : '-' }}</span>
          </li>
          <li>
            <span class="label">{{ $t("page.patient.profile.basicInformation.gender") }}</span>
            <span class="value capitalize">{{ patientInfo.gender ? patientInfo.gender.toLowerCase() : '' }}</span>
          </li>
          <li v-if="patientInfo.state_name">
            <span class="label">{{ $t("page.patient.profile.basicInformation.state") }}</span>
            <span class="value">{{ patientInfo.state_name }}</span>
          </li>
          <li v-if="patientInfo.email">
            <span class="label">{{ $t("page.patient.profile.basicInformation.email") }}</span>
            <span class="value">{{ patientInfo.email }}</span>
          </li>
          <li v-if="patientInfo.subscription">
            <span class="label">{{ $t("page.patient.profile.basicInformation.subscriptionPlan") }}</span>
            <span
              class="value"
            >{{ patientInfo.subscription.planName ? patientInfo.subscription.planName : '-' }}</span>
          </li>
          <li v-if="patientInfo.subscription">
            <span class="label">{{ $t("page.patient.profile.basicInformation.videoCall") }}</span>
            <span class="value">
              {{
                patientInfo.subscription.video ?
                  `Used ${patientInfo.subscription.video.done} times (${patientInfo.subscription.video.remain} left)`
                  : '-'
              }}
            </span>
          </li>
        </ul>
      </section>
      <section v-if="patientInfo.disease" class="info-section">
        <h1>{{ $t("page.patient.profile.diagnosis.title") }}</h1>
        <ul>
          <li>
            <span class="label">{{ $t("page.patient.profile.diagnosis.nameOfDisease") }}</span>
            <span class="value">{{ patientInfo.disease.disease? patientInfo.disease.disease : '-' }}</span>
          </li>
          <li>
            <span class="label">{{ $t("page.patient.profile.diagnosis.affectedSide") }}</span>
            <span
              class="value capitalize"
            >{{ patientInfo.disease.strokeSide ? patientInfo.disease.strokeSide.toLowerCase() : '-' }}</span>
          </li>
          <li>
            <span class="label">{{ $t("page.patient.profile.diagnosis.dateOfOccurance") }}</span>
            <span class="value">
              {{
                patientInfo.disease.onsetDate ?
                  $moment(patientInfo.disease.onsetDate).format("ll") : '-'
              }}
            </span>
          </li>
        </ul>
      </section>
      <section v-if="patientInfo.severity" class="info-section">
        <h1>{{ $t("page.patient.profile.upperExtremityLevel.title") }}</h1>
        <ul>
          <li v-for="(serverity, index) of patientInfo.severity" :key="index">
            <span class="label">{{ serverity.date ? $moment(serverity.date).format("ll") : '-' }}</span>
            <span class="value">{{ serverity.level ? `Level ${serverity.level}` : '-' }}</span>
          </li>
        </ul>
      </section>
    </div>
  </section>
  <div v-else class="loading">No Result</div>
</template>

<script>
/* eslint-disable */
import { fetchGetPatientInformation } from "@/api/patient";
import { mapGetters } from "vuex";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
export default {
  name: "Profile",
  mixins: [ErrorMixin],
  data() {
    return {
      patientInfo: null
    };
  },
  methods: {
    async getPatientInfo(patientId) {
      try {
        const result = await fetchGetPatientInformation(patientId);
        if (result) {
          this.patientInfo = result;
        }
      } catch (error) {
        this.handleError(error, "Patient Profile");
      }
    },
    initPatientInfo() {
      this.patientInfo = null;
    }
  },
  watch: {
    selectedPatient: function(newVal, oldVal) {
      if (newVal) {
        if (newVal && oldVal && newVal.id === oldVal.id) {
          return;
        }
        this.initPatientInfo();
        this.getPatientInfo(newVal.id);
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedPatient: "patient/selectedPatient"
    })
  },
  created() {
    if (this.selectedPatient) {
      this.getPatientInfo(this.selectedPatient.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.Profile {
  padding: 46px 48px;
  height: calc(100% - 12px) !important;
  display: flex;
  .patient-avatar {
    flex-shrink: 0;
    .avatar {
      background: $fontColor5;
      border-radius: 50%;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .patient-image {
        width: 80px;

        img {
          width: 100%;
        }
      }
      .icon-ic_group_fill {
        font-size: 56px;
        color: $fontColor3;
      }
    }
  }
  .patient-info {
    margin-left: 40px;
    width: 100%;
    .info-section {
      width: 660px;
      padding-bottom: 30px;
      border-bottom: 1px solid $borderColor1;
      margin-bottom: 30px;
      &:last-of-type {
        border-bottom: 0 none;
        margin-bottom: 0;
        padding-bottom: 150px;
      }
      h1 {
        font-size: $fontSize4;
        line-height: 25px;
        font-weight: 500;
        color: $fontColor1;
      }
      ul {
        margin-top: 24px;
        li {
          font-size: $fontSize6;
          line-height: 19px;
          & + li {
            margin-top: 13px;
          }
          .label {
            color: $fontColor3;
            width: 170px;
            display: inline-block;
          }
          .value {
            color: $fontColor1;
            &.capitalize {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
