<template>
  <section class="report-section movement-ratio">
    <h1>{{ $t("page.patient.neofectHome.data.movementTargeted") }}</h1>
    <div class="report-contents">
      <Loading v-if="loading" />
      <ul v-if="!isNoData">
        <li>
          <span class="label">{{ motionRatio[0].name }}</span>
          <span class="value">{{ motionRatio[0].ratio | numFormat("0.0") }}%</span>
          <div class="in-decrease" v-if="isRangeCompare" v-bind:class="{decrease: motionRatio[0].ratio - targetMotionRatio[0].ratio < 0, increase: motionRatio[0].ratio - targetMotionRatio[0].ratio > 0}">
            <span class="arrow" v-bind:class="{down: motionRatio[0].ratio - targetMotionRatio[0].ratio < 0, up: motionRatio[0].ratio - targetMotionRatio[0].ratio > 0}"></span>
            {{ removeNumberMinusSign(motionRatio[0].ratio - targetMotionRatio[0].ratio) | numFormat("0.0") }}%
          </div>
        </li>
        <li>
          <span class="label">{{ motionRatio[1].name }}</span>
          <span class="value">{{ motionRatio[1].ratio | numFormat("0.0") }}%</span>
          <div class="in-decrease" v-if="isRangeCompare" v-bind:class="{decrease: motionRatio[1].ratio - targetMotionRatio[1].ratio < 0, increase: motionRatio[1].ratio - targetMotionRatio[1].ratio > 0}">
            <span class="arrow" v-bind:class="{down: motionRatio[1].ratio - targetMotionRatio[1].ratio < 0, up: motionRatio[1].ratio - targetMotionRatio[1].ratio > 0}"></span>
            {{ removeNumberMinusSign(motionRatio[1].ratio - targetMotionRatio[1].ratio) | numFormat("0.0") }}%
          </div>
        </li>
        <li>
          <span class="label">{{ motionRatio[2].name }}</span>
          <span class="value">{{ motionRatio[2].ratio | numFormat("0.0") }}%</span>
          <div class="in-decrease" v-if="isRangeCompare" v-bind:class="{decrease: motionRatio[2].ratio - targetMotionRatio[2].ratio < 0, increase: motionRatio[2].ratio - targetMotionRatio[2].ratio > 0}">
            <span class="arrow" v-bind:class="{down: motionRatio[2].ratio - targetMotionRatio[2].ratio < 0, up: motionRatio[2].ratio - targetMotionRatio[2].ratio > 0}"></span>
            {{ removeNumberMinusSign(motionRatio[2].ratio - targetMotionRatio[2].ratio) | numFormat("0.0") }}%
          </div>
        </li>
        <li>
          <span class="label">{{ egToGe(motionRatio[3].name)}}</span>
          <span class="value">{{ motionRatio[3].ratio | numFormat("0.0") }}%</span>
          <div class="in-decrease" v-if="isRangeCompare" v-bind:class="{decrease: motionRatio[3].ratio - targetMotionRatio[3].ratio < 0, increase: motionRatio[3].ratio - targetMotionRatio[3].ratio > 0}">
            <span class="arrow" v-bind:class="{down: motionRatio[3].ratio - targetMotionRatio[3].ratio < 0, up: motionRatio[3].ratio - targetMotionRatio[3].ratio > 0}"></span>
            {{ removeNumberMinusSign(motionRatio[3].ratio - targetMotionRatio[3].ratio) | numFormat("0.0") }}%
          </div>
        </li>
        <li>
          <span class="label">{{ egToGe(motionRatio[4].name) }}</span>
          <span class="value">{{ motionRatio[4].ratio | numFormat("0.0") }}%</span>
          <div class="in-decrease" v-if="isRangeCompare" v-bind:class="{decrease: motionRatio[4].ratio - targetMotionRatio[4].ratio < 0, increase: motionRatio[4].ratio - targetMotionRatio[4].ratio > 0}">
            <span class="arrow" v-bind:class="{down: motionRatio[4].ratio - targetMotionRatio[4].ratio < 0, up: motionRatio[4].ratio - targetMotionRatio[4].ratio > 0}"></span>
            {{ removeNumberMinusSign(motionRatio[4].ratio - targetMotionRatio[4].ratio) | numFormat("0.0") }}%
          </div>
        </li>
        <li v-if="!isKids">
          <span class="label">{{ motionRatio[5].name }}</span>
          <span class="value">{{ motionRatio[5].ratio | numFormat("0.0") }}%</span>
          <div class="in-decrease" v-if="isRangeCompare" v-bind:class="{decrease: motionRatio[5].ratio - targetMotionRatio[5].ratio < 0, increase: motionRatio[5].ratio - targetMotionRatio[5].ratio > 0}">
            <span class="arrow" v-bind:class="{down: motionRatio[5].ratio - targetMotionRatio[5].ratio < 0, up: motionRatio[5].ratio - targetMotionRatio[5].ratio > 0}"></span>
            {{ removeNumberMinusSign(motionRatio[5].ratio - targetMotionRatio[5].ratio) | numFormat("0.0") }}%
          </div>
        </li>
      </ul>
      <div v-if="isNoData" class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
      <div v-if="error" class="error">Error!</div>
    </div>
  </section>
</template>

<script>
import { getGloveMotionTypeRatio } from "@/api/report.js";
import Loading from "@/components/Loading";
export default {
  name: "GloveMotionTypeRatio",
  data() {
    return {
      motionRatio: [{}, {}, {}, {}, {}, {}].fill({
        name: "",
        playedTime: "",
        ratio: ""
      }),
      targetMotionRatio: [{}, {}, {}, {}, {}, {}].fill({
        name: "",
        playedTime: "",
        ratio: ""
      }),
      loading: false,
      isNoData: false,
      error: false
    };
  },
  components: {
    Loading
  },
  props: {
    device: {
      type: Object
    },
    dateRange: {
      type: Object
    },
    toDateRange: {
      type: Object
    },
    isRangeCompare: Boolean,
    isKids: Boolean
  },
  computed: {
    patient: function() {
      return this.$store.getters.selectedPatient;
    }
  },
  watch: {
    device(newValue, oldValue) {
      this.isNoData = false;
      this.error = false;
      this.motionRatio = [{}, {}, {}, {}, {}, {}].fill({
        name: "",
        playedTime: "",
        ratio: ""
      });
      this.loadMotionRatio();
    },
    dateRange(newValue, oldValue) {
      this.loadMotionRatio();
    },
    toDateRange(newValue, oldVAlue) {
      this.loadMotionRatio();
    },
    isRangeCompare(newValue, oldValue) {
      this.loadMotionRatio();
    }
  },
  mounted() {
    this.loadMotionRatio();
  },
  methods: {
    egToGe: function (eg) {
      return eg.replace("E.G.","G.E.");
    },
    removeNumberMinusSign: function (number) {
      if (number < 0) {
        return number * -1;
      } else if (number === 0) {
        return '-';
      }
      return number;
    },
    async loadMotionRatio() {
      if (!this.patient || !this.device) return;
      let motionRatio;
      let targetMotionRatio;
      try {
        this.loading = true;
        motionRatio = await getGloveMotionTypeRatio(
          this.patient.id,
          this.device.id,
          this.$moment(this.dateRange.startDate).toISOString(),
          this.$moment(this.dateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString()
        );
        if (this.isRangeCompare) {
          targetMotionRatio = await getGloveMotionTypeRatio(
            this.patient.id,
            this.device.id,
            this.$moment(this.toDateRange.startDate).toISOString(),
            this.$moment(this.toDateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString()
          );
        }
        this.loading = false;
        this.isNoData = motionRatio < 1;
        this.motionRatio = motionRatio;
        this.targetMotionRatio = targetMotionRatio;
      } catch (error) {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.movement-ratio {
  .report-contents {
    position: relative;
    overflow: hidden;

    .no-data,
    .error {
      width: 100%;
      height: 135px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      &.error {
        z-index: 2;
      }
    }
  }
}
</style>
