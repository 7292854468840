var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "ClinicManagement" },
    [
      _vm.target === "patient"
        ? _c("ManagementPatient")
        : _c("ManagementTherapist")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }