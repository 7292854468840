var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "WeeklyHomework" }, [
    _c(
      "ul",
      { staticClass: "week-list" },
      _vm._l(_vm.homeworkList, function(homework, index) {
        return _c("li", { key: index }, [
          homework
            ? _c("div", { staticClass: "week-homework" }, [
                _c(
                  "button",
                  {
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.detailPopup(homework.id)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("page.patient.calendar.head.exerciseHomework")
                        ) +
                        "\n          "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          "(" +
                            homework.missions.length +
                            " " +
                            _vm.$t("page.patient.calendar.head.exercises") +
                            ")"
                        ) +
                        "\n        "
                    )
                  ]
                )
              ])
            : _c("div")
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }