<template>
  <div class="patients-list">
    <div class="search">
      <span class="icon-ic_search">
        <span class="path1" />
        <span class="path2" />
      </span>
      <input v-model="keyword" type="text" :placeholder="searchPatientPlaceholder">
    </div>
    <div class="list">
      <ul class="custom-scrollbar">
        <!-- 채팅방 활성화는 클래스 on -->
        <li
          v-for="item in filteredPatients"
          :key="item.user_id"
          :class="item.user_id === (!!selectedInfo && selectedInfo.patientId) ? 'on' : ''"
          @click=" enterGroupChannel({ patientId: item.user_id, name: item.name, image: item.image }) "
        >
          <button type="button">
            <div class="patient-image">
              <span class="icon-ic_group_fill">
                <span class="path1" />
                <span class="path2" />
              </span>
              <img v-if="item.image !== null" :src="item.image">
            </div>
            <div class="message-info">
              <span class="patient-name">
                <span class="name">{{ item.name }}</span>
                <span class="time">{{ item.lastMessageCreatedAt | dateFormat }}</span>
              </span>
              <span class="message">{{ item.lastMessage }}</span>
            </div>
            <div v-if="item.unreadMessageCount > 0" class="unread">{{ item.unreadMessageCount }}</div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  name: "Chat",
  data() {
    return {
      keyword: ""
    };
  },
  methods: {
    ...mapActions({
      enterGroupChannel: "chat/enterGroupChannel",
      exitGroupChannel: "chat/exitGroupChannel"
    })
  },
  computed: {
    ...mapGetters({
      token: "token",
      patients: "chat/patients",
      selectedInfo: "chat/selectedInfo"
    }),
    searchPatientPlaceholder() {
      return this.$t("common.chat.searchPatientPlaceholder");
    },
    filteredPatients() {
      return this.patients.filter(x =>
        x.name ? x.name.toLowerCase().search(this.keyword.toLowerCase()) != -1 : false
      );
    }
  },
  filters: {
    dateFormat: function(timestamp) {
      const today = moment().startOf("day");
      const target = moment(timestamp);
      if (today.isBefore(target)) {
        return target.format("HH:mm");
      }
      return target.format("MMM DD");
    }
  }
};
</script>

<style lang="scss" scoped>
/* 왼쪽 영역 */
.patients-list {
  width: 270px;
  background: #836855;
  box-sizing: border-box;
  .search {
    position: relative;
    margin: 24px 16px 0 16px;
    .icon-ic_search {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translate(0, -50%);
      font-size: $fontSize2;
      color: #ffffff;
    }
    input[type="text"] {
      padding-left: 55px;
      line-height: 24px;
      border-radius: 24.5px;
      background: rgba(0, 0, 0, 0.15);
      border: 0 none;
      color: #ffffff;
      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
  .list {
    margin-top: 38px;
    margin-bottom: 16px;

    ul {
      height: 410px;
      overflow-y: auto;
      &.custom-scrollbar {
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background-color: rgba(255, 255, 255, 0.4);
          border-radius: 4px;
        }
      }
      li {
        width: 100%;
        overflow: hidden;

        &.on {
          button {
            background-color: rgba(255, 255, 255, 0.15);
            .patient-image {
              background: rgba(131, 104, 85, 0.5);
            }
          }
        }
        button {
          padding: 14px 16px;
          text-align: left;
          width: 100%;
          display: flex;
          align-items: center;
          border-radius: 0;
          box-sizing: border-box;
          position: relative;
          .patient-image {
            width: 34px;
            height: 34px;
            display: inline-block;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            position: relative;
            flex-shrink: 0;
            overflow: hidden;
            .icon-ic_group_fill {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: rgba(255, 255, 255, 0.4);
            }
            img {
              width: 34px;
              height: 34px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          .message-info {
            padding: 0 0 0 16px;
            width: 100%;
            box-sizing: border-box;
            .patient-name {
              display: flex;
              width: 100%;
              color: $fontColor1;
              font-size: $fontSize6;
              line-height: 19px;
              .name {
                width: 100px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: normal;
                box-sizing: border-box;
                color: #ffffff;
              }
              .time {
                margin-left: auto;
                font-size: 11px;
                color: rgba(255, 255, 255, 0.6);
              }
            }
            .message {
              margin-top: 5px;
              line-height: 16px;
              font-size: $fontSize7;
              color: rgba(255, 255, 255, 0.6);
              width: 155px;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              word-wrap: normal;
              box-sizing: border-box;
            }
          }
          .unread {
            padding: 3px 5px;
            font-size: 11px;
            line-height: 12px;
            background: $uiColor5;
            color: $fontColor2;
            border-radius: 48%;
            position: absolute;
            right: 16px;
            bottom: 11px;
            min-width: 7px;
            display: inline-block;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
