<template>
  <div class="slider-wrap">
    <vue-slider
      v-model="options.obj[key]"
      direction="btt"
      height="230px"
      :marks="false"
      :max="max"
      :min="min"
      :tooltip="'none'"
      :data-value="'value'"
      :data-label="'label'"
      :data="options.data"
    />
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  name: "Counter",
  components: { VueSlider },
  props: {
    options: Object
  },
  data() {
    return {
      ...this.options
    };
  },
  methods: {}
};
</script>
<style lang="scss">
.slider-wrap {
  padding: 10px;
  .vue-slider-rail {
    width: 8px;
    height: 226px;
    border-radius: 50px;
    border: solid 1px #d0d4dc;
    background-color: #ffffff;
  }
  .vue-slider-mark {
    .vue-slider-mark-step {
      opacity: 0;
    }
    .vue-slider-mark-label {
      line-height: 16px;
      font-size: 12px;
      font-weight: 600;
      color: #606266;
      margin-left: 16px;
      letter-spacing: 1px;
    }
  }
  .vue-slider-process {
    background: #f0734d !important;
  }
  .vue-slider-dot-handle {
    border: 2px solid #f0734d !important;
  }
}
</style>
