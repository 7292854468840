<template>
  <section class="report-section shapre-similarity">
    <h1>{{ $t("page.patient.neofectHome.data.shapeSimilarity") }}</h1>
    <div class="report-contents">
      <Loading v-if="loading" />
      <ul v-if="diff">
        <li v-if="similarity.last">
          <div class="report-item">
            <span class="value">{{ similarity.last.value.toFixed(0) }}%</span>
            <div class="in-decrease" v-if="isRangeCompare" v-bind:class="{decrease: similarity.last.value.toFixed(0) - targetSimilarityLastValue.toFixed(0) < 0, increase: similarity.last.value.toFixed(0) - targetSimilarityLastValue.toFixed(0) > 0}">
              <span class="arrow" v-bind:class="{down: similarity.last.value.toFixed(0) - targetSimilarityLastValue.toFixed(0) < 0, up: similarity.last.value.toFixed(0) - targetSimilarityLastValue.toFixed(0) > 0}"></span>
              {{ removeNumberMinusSign(similarity.last.value.toFixed(0) - targetSimilarityLastValue.toFixed(0)) }}
            </div>
          </div>
          <span class="label">
            {{
            $moment(similarity.last.date, "YYYY-MM-DD").format("MMM DD,YYYY")
            }}
          </span>
        </li>
        <li>
          <span class="value up">
            {{ diff }}%
            <span :class="upOrDown">
              <span class="path1"></span>
              <span class="path2"></span>
            </span>
          </span>
          <span class="label" v-if="similarity.first">
            Compared to the first time
            <br />
            ({{ $moment(similarity.first.date, "YYYY-MM-DD").format("MMM DD,YYYY") }})
          </span>
        </li>
      </ul>
      <div v-if="isNoData" class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
      <div v-if="error" class="no-data">Error!</div>
    </div>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { getBoardAromSimilarity } from "@/api/report.js";
export default {
  name: "BoardSimilarity",
  components: {
    Loading
  },
  data() {
    return {
      similarity: null,
      targetSimilarityLastValue: 0,
      upOrDown: null,
      diff: null,
      isNoData: false,
      loading: false,
      error: false
    };
  },
  props: {
    device: {
      type: Object
    },
    dateRange: {
      type: Object
    },
    toDateRange: {
      type: Object
    },
    isRangeCompare: Boolean
  },
  computed: {
    patient: function() {
      return this.$store.getters.selectedPatient;
    }
  },
  watch: {
    device(newValue, oldValue) {
      this.similarity = null;
      this.upOrDown = null;
      this.diff = null;
      this.error = false;
      this.isNoData = false;
      this.drawChart();
    },
    dateRange(newValue, oldValue) {
      this.drawChart();
    },
    toDateRange(newValue, oldVAlue) {
      this.drawChart();
    },
    isRangeCompare(newValue, oldValue) {
      this.drawChart();
    }
  },
  mounted() {
    this.drawChart();
  },
  beforeDestroy() {},
  methods: {
    removeNumberMinusSign: function (number) {
      if (number < 0) {
        return `${(number * -1).toFixed(0)}%`;
      } else if (number === 0) {
        return '-';
      }
      return `${number.toFixed(0)}%`;
    },
    async drawChart() {
      if (!this.patient || !this.device) return;
      let similarity;
      let targetSimilarity;
      try {
        this.loading = true;
        similarity = await getBoardAromSimilarity(
          this.patient.id,
          this.device.id,
          this.$moment(this.dateRange.startDate).toISOString(),
          this.$moment(this.dateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString()
        );
        if (this.isRangeCompare) {
          targetSimilarity = await getBoardAromSimilarity(
            this.patient.id,
            this.device.id,
            this.$moment(this.toDateRange.startDate).toISOString(),
            this.$moment(this.toDateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString()
          );
          this.targetSimilarityLastValue = targetSimilarity.last ? targetSimilarity.last.value : 0.0;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }

      this.similarity = similarity;

      this.isNoData =
        this.similarity.first === null && this.similarity.last === null;

      if (this.isNoData) {
        this.loading = false;
        return;
      }

      let firstValue = this.similarity.first
        ? this.similarity.first.value
        : 0.0;
      let lastValue = this.similarity.last ? this.similarity.last.value : 0.0;
      this.diff = Math.abs(lastValue - firstValue).toFixed(0);
      if (lastValue > firstValue) {
        this.upOrDown = "icon-ic_ascending_sort";
      } else if (firstValue > lastValue) {
        this.upOrDown = "icon-ic_descending_sort";
      }

      this.loading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.shapre-similarity {
  .report-contents {
    position: relative;
    overflow: hidden;
    height: 125px;
    box-sizing: border-box;
    .report-item{
      display: flex;
      .in-decrease{
        text-align: center;
        width: 25%;
        font-size: 13px;
        align-self: center;
        &.increase{
          color: blue;
        }
        &.decrease{
          color: red;
        }
      }
    }
    .no-data,
    .error {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      &.error {
        z-index: 2;
      }
    }
  }
}
</style>
