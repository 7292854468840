<template>
  <div class="Memo" @scroll="handleScroll">
    <div class="contents" v-if="memoList.length > 0">
      <div class="memo-new-btn secondary-type-btns">
        <button type="button" @click="handleCreateMemo">
          {{ $t("page.patient.documentation.newDocumentationButton") }}
        </button>
      </div>
      <div class="memo-list">
        <ul>
          <li
            v-for="(memo, index) of memoList"
            :key="index"
            @click="handleMemoDetail(memo.id, $event)"
          >
            <div>
              <div class="memo-type-date">
                <span class="type">{{ printType(memo.division) }}</span>
                <span class="date">{{
                  $moment(memo.created).format("MMMM D, YYYY h:mm A")
                }}</span>
              </div>
              <div class="memo-title">{{ memo.title }}</div>
            </div>
            <!-- Video Download 추가. 21.02.02 -->
            <a
              v-if="memo.division === 'VIDEOCALLRECORD'"
              class="video-download"
              @click="handleClickVideoDownload(memo,selectedPatient)"
            >
              {{
                `${selectedPatient.name}_videocall_record_${$moment(memo.created).format("YYMMDD")}.mp4`
              }}
              <div class="download-icon">
                <span class="download-1" />
                <span class="download-2" />
                <span class="download-3" />
                <span class="download-4" />
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="contents no-written" v-else>
      {{ $t("page.patient.documentation.noDocumentationYet") }}
      <div class="memo-new-btn secondary-type-btns">
        <button type="button" @click="handleCreateMemo">
          {{ $t("page.patient.documentation.newDocumentationButton") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  fetchCreateMemo,
  fetchGetMemo,
  fetchDeleteMemo,
  fetchGetMemoDetail,
  fetchEditMemo,
  fetchGetVideoCallDownload,
  getVideoCallDownload,
} from "@/api/patient.js";
import CreateMemo from "./components/CreateMemo";
import MemoDetail from "./components/MemoDetail";
// import Confirm from "@/components/Confirm";
import { mapGetters } from "vuex";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
// import { download } from "@/utils/download.js";
export default {
  name: "Memo",
  mixins: [ErrorMixin],
  data() {
    return {
      totalCount: 0,
      size: 20,
      page: 1,
      totalPage: 1,
      createMemoPopup: {
        view: false,
      },
      memoList: [
        // 비디오콜 예시
        // {
        //   created: "2021-02-02T08:04:47.864Z",
        //   division: "VIDEOCALLRECORD",
        //   id: "6019079f1e852b0001dd86be",
        //   organization_id: -1,
        //   title: "test",
        // },
      ],
    };
  },
  methods: {
    printType(type) {
      return type === "MEMO"
        ? this.$t("page.patient.documentation.list.type.memo")
        : type === "VIDEOSESSION"
        ? this.$t("page.patient.documentation.list.type.videoCall")
        : type === "VIDEOCALLRECORD" // 비디오콜 레코드
        ? this.$t("page.patient.documentation.list.type.videoCallRecord")
        : this.$t("page.patient.documentation.list.type.coachingSession");
    },
    async handleMemoDetail(memoId, event) {
      // 비디오 다운로드 버튼 클릭시 디테일 모달이 뜨지 않도록 return
      if (event.target.className.includes("download")) return;
      try {
        const result = await fetchGetMemoDetail(memoId);
        if (result) {
          this.$modal.show(
            MemoDetail,
            {
              memo: result,
              printType: this.printType,
              deleteMemo: this.deleteMemo,
              handleEditPopup: this.handleEditPopup,
            },
            {
              height: "auto",
              clickToClose: false,
            }
          );
        }
      } catch (error) {
        this.handleError(error, "Memo > getMemoDetail API");
      }
    },
    handleCreateMemo() {
      return this.$modal.show(
        CreateMemo,
        {
          patient: this.selectedPatient,
          submit: this.createMemo,
        },
        {
          height: "auto",
          clickToClose: false,
        }
      );
    },
    handleEditPopup(memo) {
      return this.$modal.show(
        CreateMemo,
        {
          patient: this.selectedPatient,
          submit: this.editMemo,
          memo: memo,
          edit: true,
        },
        {
          height: "auto",
          clickToClose: false,
        }
      );
    },
    async createMemo(memo) {
      try {
        const result = await fetchCreateMemo(memo);
        if (result) {
          let newMemo = [result, ...this.memoList];
          // console.log(newMemo);
          if (newMemo.length > this.page * this.size) {
            if (this.totalPage === 1) {
              this.totalPage += 1;
            }
            newMemo.pop();
          }
          this.memoList = newMemo;
          this.totalCount += 1;
        }
      } catch (error) {
        this.handleError(error, "Memo > createMemo API");
      }
    },
    async editMemo(memo) {
      try {
        const result = await fetchEditMemo(memo.id, memo);
        if (result) {
          const editMemo = this.memoList.map((memo) => {
            if (memo.id === result.id) {
              memo = result;
            }
            return memo;
          });
          // console.log(newMemo);
          this.memoList = editMemo;
        }
      } catch (error) {
        this.handleError(error, "Memo > editMemo API");
      }
    },
    deleteMemo(memoId) {
      return new Promise(
        function (resolve, reject) {
          fetchDeleteMemo(memoId)
            .then((result) => {
              this.memoList = this.memoList.filter((memo) => {
                if (memo.id !== memoId) return memo;
              });
              resolve(result);
            })
            .then(() => {
              // 메모 삭제 후 리스트의 제일 마지막 메모 가져오기
              fetchGetMemo(
                this.selectedPatient.id,
                this.size,
                this.page,
                this.clinic.id
              ).then((result) => {
                if (result && result.total_count !== this.memoList.length) {
                  this.memoList.push(result.datas[result.datas.length - 1]);
                }
              });
            })
            .catch((error) => {
              this.handleError(error, "Memo > deleteMemo API");
            });
        }.bind(this)
      );
    },
    async getMemoList() {
      // memo list 가져오기
      try {
        const result = await fetchGetMemo(
          this.selectedPatient.id,
          this.size,
          this.page,
          this.clinic.id
        );

        // console.log(result);

        if (result) {
          this.memoList = this.memoList.concat(result.datas);
          this.totalCount = result.total_count;
          this.totalPage = result.total_page;
        }
        // console.log(result);
      } catch (error) {
        this.handleError(error, "Memo > Get Memo List API");
      }
    },
    handleScroll(e) {
      const {
        target: { scrollTop, clientHeight, scrollHeight },
      } = e;
      if (scrollTop + clientHeight >= scrollHeight) {
        // lazyload, scrollbottom 발생시 memo list가져오기
        // console.log("bottom", this.page);
        if (this.page === this.totalPage) return;
        this.page += 1;
        this.getMemoList();
      }
    },
    initMemoList() {
      this.memoList = [];
      this.page = 1;
      this.totalCount = 0;
      this.totalPage = 1;
    },
    async handleClickVideoDownload(memo,selectedPatient) {
      // 비디오콜 다운로드
      try {
        const result = await getVideoCallDownload(memo.id);
        if (result) {
          const blob = new Blob([result], {
            type: "video/mp4",
          });
          const blobURL = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobURL;
          a.style.display = "none";
          a.download = `${selectedPatient.name}_videocall_record_${this.$moment(memo.created).format(
            "YYMMDD"
          )}.mp4`;
          document.body.appendChild(a);
          a.click();
          // URL.revokeObjectURL(blob);
        }
      } catch (error) {
        this.handleError(error, "Memo > GetVideoCallDownload API");
      }
    },
  },
  watch: {
    selectedPatient: function (newVal, oldVal) {
      this.initMemoList();
      if (this.selectedPatient) {
        this.getMemoList();
      }
    },
  },
  computed: {
    ...mapGetters({
      selectedPatient: "patient/selectedPatient",
      clinic: "user/clinic",
    }),
  },
  created() {
    if (this.selectedPatient) {
      this.getMemoList();
    }
  },
};
</script>

<style lang='scss' scoped>
.Memo {
  padding: 40px 24px;
  font-size: $fontSize6;
  .contents {
    &.no-written {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $fontColor3;
      text-align: center;
      .memo-new-btn {
        margin-top: 24px;
      }
    }
    .memo-list {
      margin-top: 24px;
      width: 100%;
      max-width: 620px;
      li {
        position: relative;
        border: 1px solid $borderColor1;
        border-radius: 4px;
        padding: 14px 24px 32px;
        cursor: pointer;
        & + li {
          margin-top: 8px;
        }

        .memo-type-date {
          display: flex;
          .type {
            color: $mainColor1;
            font-size: $fontSize7;
          }
          .date {
            margin-left: auto;
            color: $fontColor3;
            font-size: $fontSize7;
          }
        }
        .memo-title {
          margin-top: 8px;
          color: $fontColor1;
          font-size: $fontSize6;
          line-height: 19px;
        }

        .video-download {
          position: absolute;
          bottom: 0;
          right: 0;
          transform: translate(-24px, -14px);
          display: inline-block;
          font-family: Manrope3;
          font-size: 12px;
          line-height: 16px;
          color: #606266;
          padding: 10px 12px 10px 16px;
          background-color: #f4f5f7;
          border-radius: 4px;
          .download-icon {
            display: block;
            width: 16px;
            height: 16px;
            float: right;
            margin-left: 12px;
            position: relative;
            .download-1 {
              display: block;
              position: absolute;
              width: 12px;
              height: 2px;
              top: 5px;
              left: 2px;
              background-color: #606266;
              border-radius: 4px;
              transform: rotate(90deg);
            }
            .download-2 {
              display: block;
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 2px;
              background-color: #606266;
              border-radius: 4px;
            }
            .download-3 {
              display: block;
              position: absolute;
              top: 8px;
              left: 1px;
              width: 9px;
              height: 2px;
              background-color: #606266;
              border-radius: 4px;
              transform: rotate(45deg);
            }
            .download-4 {
              display: block;
              position: absolute;
              top: 8px;
              left: 6px;
              width: 9px;
              height: 2px;
              background-color: #606266;
              border-radius: 4px;
              transform: rotate(135deg);
            }
          }
        }
      }
    }
  }
}
</style>
