var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "ChangeClinic" }, [
    _c("h1", [_vm._v(_vm._s(_vm.$t("page.changeClinic.title")))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "work-place", class: _vm.clinicCodeCheck ? "" : "wrong" },
      [
        _c("label", [_vm._v(_vm._s(_vm.$t("page.changeClinic.description")))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "work-place-radio" },
          _vm._l(_vm.workplaceList, function(type, index) {
            return _c("span", { key: index }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedWorkplace,
                    expression: "selectedWorkplace"
                  }
                ],
                attrs: { id: type, type: "radio" },
                domProps: {
                  value: type,
                  checked: _vm._q(_vm.selectedWorkplace, type)
                },
                on: {
                  change: function($event) {
                    _vm.selectedWorkplace = type
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: type } }, [
                _vm._v(_vm._s(_vm.inputInfoWorkPlaceOption(type)))
              ])
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.clinicCode,
              expression: "clinicCode"
            }
          ],
          attrs: {
            id: "clinic",
            type: "text",
            autocomplete: "off",
            placeholder: _vm.inputInfoWorkPlacePlaceholder,
            disabled: _vm.selectedWorkplace === "Neofect"
          },
          domProps: { value: _vm.clinicCode },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.clinicCode = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "message" }, [
          _vm._v("\n      " + _vm._s(_vm.clinicCodeWrongMessage) + "\n    ")
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "submit-btns",
        class: _vm.submitDisable ? "disabled" : ""
      },
      [
        _c(
          "button",
          { attrs: { type: "button" }, on: { click: _vm.handleSubmit } },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("page.changeClinic.button")) + "\n    "
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }