var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-room" }, [
    _c("div", { staticClass: "room-title" }, [
      _vm._v(_vm._s(_vm.selectedInfo.name))
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "message-log custom-scrollbar",
        attrs: { id: "messageLogScroll" },
        on: { scroll: _vm.onScrollByThrottling }
      },
      _vm._l(_vm.selectedGroupChannel.messages, function(item, index) {
        return _c("div", { key: index }, [
          item.userId == _vm.userId
            ? _c("div", { staticClass: "me" }, [
                _c("div", { staticClass: "message-wrapper" }, [
                  _c("div", { staticClass: "message" }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.newlineToBr(item.message)
                        )
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm._f("dateFormat")(item.createdAt)))
                    ]),
                    _vm._v(" "),
                    item.createdAt == _vm.selectedGroupChannel.readMessageAt
                      ? _c("div", { staticClass: "read" }, [_vm._v("Read")])
                      : _vm._e()
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          item.userId != _vm.userId
            ? _c("div", { staticClass: "other" }, [
                _c("div", { staticClass: "message-wrapper" }, [
                  _c("div", { staticClass: "image" }, [
                    _vm._m(0, true),
                    _vm._v(" "),
                    _vm.selectedInfo.image !== null
                      ? _c("img", { attrs: { src: _vm.selectedInfo.image } })
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.selectedInfo.name))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "message" }, [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.newlineToBr(item.message)
                        )
                      }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm._f("dateFormat")(item.createdAt)))
                    ])
                  ])
                ])
              ])
            : _vm._e()
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "chat-input",
        class: _vm.isShowInputWarning ? "warning" : ""
      },
      [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.message,
              expression: "message"
            }
          ],
          ref: "message",
          attrs: { id: "chatInput" },
          domProps: { value: _vm.message },
          on: {
            focus: _vm.startTyping,
            blur: _vm.endTyping,
            keypress: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.updateMessage($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.message = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "send-btn primary-type-btns",
            class: _vm.message.length < 1 ? "disabled" : ""
          },
          [
            _c(
              "button",
              {
                ref: "sendBtn",
                attrs: { type: "button" },
                on: { click: _vm.send }
              },
              [_vm._v(_vm._s(_vm.$t("common.chat.send")))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "message" }, [
              _c("span", [_vm._v(_vm._s(_vm.chatInfo))])
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_group_fill" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }