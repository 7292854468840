var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "report-section vertical-horizontal-arom" },
    [
      _c("h1", [
        _vm._v(
          _vm._s(_vm.$t("page.patient.neofectHome.data.verticalHorizontalAROM"))
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "report-contents" },
        [
          _vm.loading ? _c("Loading") : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "report-btns" }, [
            _c(
              "button",
              {
                class: { on: _vm.show === "vertical" },
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.show = "vertical"
                  }
                }
              },
              [_vm._v("Vertical")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                class: { on: _vm.show === "horizontal" },
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.show = "horizontal"
                  }
                }
              },
              [_vm._v("Horizontal")]
            )
          ]),
          _vm._v(" "),
          _vm.aromRange
            ? _c("div", { staticClass: "report-wrapper" }, [
                _vm.vertical && _vm.horizontal
                  ? _c("div", { staticClass: "report-info" }, [
                      _c("div", { staticClass: "report-value" }, [
                        _c("span", { staticClass: "value" }, [
                          _vm._v(
                            _vm._s(_vm.selected.lastValueRange.toFixed(0)) +
                              "cm"
                          )
                        ]),
                        _vm._v(" "),
                        _vm.isRangeCompare && _vm.show === "horizontal"
                          ? _c(
                              "div",
                              {
                                staticClass: "in-decrease",
                                class: {
                                  decrease:
                                    _vm.selected.lastValueRange.toFixed(0) -
                                      _vm.targetHorizontalRange <
                                    0,
                                  increase:
                                    _vm.selected.lastValueRange.toFixed(0) -
                                      _vm.targetHorizontalRange >
                                    0
                                }
                              },
                              [
                                _c("span", {
                                  staticClass: "arrow",
                                  class: {
                                    down:
                                      _vm.selected.lastValueRange.toFixed(0) -
                                        _vm.targetHorizontalRange <
                                      0,
                                    up:
                                      _vm.selected.lastValueRange.toFixed(0) -
                                        _vm.targetHorizontalRange >
                                      0
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "arrow-text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.removeNumberMinusSign(
                                        _vm.selected.lastValueRange.toFixed(0) -
                                          _vm.targetHorizontalRange.toFixed(0)
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isRangeCompare && _vm.show === "vertical"
                          ? _c(
                              "div",
                              {
                                staticClass: "in-decrease",
                                class: {
                                  decrease:
                                    _vm.selected.lastValueRange.toFixed(0) -
                                      _vm.targetVerticalRange <
                                    0,
                                  increase:
                                    _vm.selected.lastValueRange.toFixed(0) -
                                      _vm.targetVerticalRange >
                                    0
                                }
                              },
                              [
                                _c("span", {
                                  staticClass: "arrow",
                                  class: {
                                    down:
                                      _vm.selected.lastValueRange.toFixed(0) -
                                        _vm.targetVerticalRange <
                                      0,
                                    up:
                                      _vm.selected.lastValueRange.toFixed(0) -
                                        _vm.targetVerticalRange >
                                      0
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "arrow-text" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.removeNumberMinusSign(
                                        _vm.selected.lastValueRange.toFixed(0) -
                                          _vm.targetVerticalRange.toFixed(0)
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "label" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm
                                .$moment(
                                  _vm.selected.rangeData.last.date,
                                  "YYYY-MM-DD"
                                )
                                .format("MMM DD,YYYY")
                            ) +
                            "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "value up" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.selected.diff) +
                            "\n          "
                        ),
                        _c("span", { class: _vm.selected.upOrDown }, [
                          _c("span", { staticClass: "path1" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "path2" })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "label" }, [
                        _vm._v(
                          "\n          Compared to the first time\n          "
                        ),
                        _c("br"),
                        _vm._v(
                          "\n          (" +
                            _vm._s(
                              _vm
                                .$moment(
                                  _vm.selected.rangeData.first.date,
                                  "YYYY-MM-DD"
                                )
                                .format("MMM DD,YYYY")
                            ) +
                            ")\n        "
                        )
                      ])
                    ])
                  : _c("div", { staticClass: "no-data" }, [
                      _vm._v(
                        _vm._s(_vm.$t("page.patient.neofectHome.data.empty"))
                      )
                    ]),
                _vm._v(" "),
                _vm.horizontal
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.show === "horizontal",
                            expression: "show === 'horizontal'"
                          }
                        ],
                        staticClass: "graph"
                      },
                      [
                        _c(
                          "v-stage",
                          {
                            attrs: {
                              height: _vm.horizontal.dimensions.stageHeight,
                              width: _vm.horizontal.dimensions.stageWidth
                            }
                          },
                          [
                            _c(
                              "v-layer",
                              [
                                _c("v-rect", {
                                  attrs: {
                                    x: isNaN(_vm.horizontal.dimensions.range.x)
                                      ? 0
                                      : _vm.horizontal.dimensions.range.x,
                                    y: isNaN(_vm.horizontal.dimensions.range.y)
                                      ? 0
                                      : _vm.horizontal.dimensions.range.y,
                                    width: isNaN(
                                      _vm.horizontal.dimensions.range.width
                                    )
                                      ? 0
                                      : _vm.horizontal.dimensions.range.width,
                                    height: isNaN(
                                      _vm.horizontal.dimensions.range.height
                                    )
                                      ? 0
                                      : _vm.horizontal.dimensions.range.height,
                                    fill: "rgba(90,173, 244,0.7)"
                                  }
                                }),
                                _vm._v(" "),
                                _c("v-text", {
                                  attrs: {
                                    text: _vm.horizontal.valueRange[0].toFixed(
                                      0
                                    ),
                                    x: _vm.horizontal.dimensions.labels.start.x,
                                    y: _vm.horizontal.dimensions.labels.start.y
                                  }
                                }),
                                _vm._v(" "),
                                _c("v-text", {
                                  attrs: {
                                    text: _vm.horizontal.valueRange[1] + " cm",
                                    x: _vm.horizontal.dimensions.labels.end.x,
                                    y: _vm.horizontal.dimensions.labels.end.y
                                  }
                                }),
                                _vm._v(" "),
                                _c("v-rect", {
                                  attrs: {
                                    x: _vm.horizontal.dimensions.background.x,
                                    y: _vm.horizontal.dimensions.background.y,
                                    width:
                                      _vm.horizontal.dimensions.background
                                        .width,
                                    height:
                                      _vm.horizontal.dimensions.background
                                        .height,
                                    fill: "rgba(90,173, 244,0.1)",
                                    stroke: "#5aadf5",
                                    strokeWidth: 1
                                  }
                                }),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.horizontal.dimensions.ticks,
                                  function(tick, index) {
                                    return _c("v-line", {
                                      key: index,
                                      attrs: {
                                        points: tick,
                                        stroke: "#5aadf5",
                                        strokeWidth: 1,
                                        closed: "false"
                                      }
                                    })
                                  }
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.vertical
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.show === "vertical",
                            expression: "show === 'vertical'"
                          }
                        ],
                        staticClass: "graph"
                      },
                      [
                        _c(
                          "v-stage",
                          {
                            attrs: {
                              height: _vm.vertical.dimensions.stageHeight,
                              width: _vm.vertical.dimensions.stageWidth
                            }
                          },
                          [
                            _c(
                              "v-layer",
                              [
                                _c("v-rect", {
                                  attrs: {
                                    x: isNaN(_vm.vertical.dimensions.range.x)
                                      ? 0
                                      : _vm.vertical.dimensions.range.x,
                                    y: isNaN(_vm.vertical.dimensions.range.y)
                                      ? 0
                                      : _vm.vertical.dimensions.range.y,
                                    width: isNaN(
                                      _vm.vertical.dimensions.range.width
                                    )
                                      ? 0
                                      : _vm.vertical.dimensions.range.width,
                                    height: isNaN(
                                      _vm.vertical.dimensions.range.height
                                    )
                                      ? 0
                                      : _vm.vertical.dimensions.range.height,
                                    fill: "rgba(90,173, 244,0.7)"
                                  }
                                }),
                                _vm._v(" "),
                                _c("v-text", {
                                  attrs: {
                                    text: _vm.vertical.valueRange[0].toFixed(0),
                                    x: _vm.vertical.dimensions.labels.start.x,
                                    y: _vm.vertical.dimensions.labels.start.y
                                  }
                                }),
                                _vm._v(" "),
                                _c("v-text", {
                                  attrs: {
                                    text: _vm.vertical.valueRange[1] + " cm",
                                    x: _vm.vertical.dimensions.labels.end.x,
                                    y: _vm.vertical.dimensions.labels.end.y
                                  }
                                }),
                                _vm._v(" "),
                                _c("v-rect", {
                                  attrs: {
                                    x: _vm.vertical.dimensions.background.x,
                                    y: _vm.vertical.dimensions.background.y,
                                    width:
                                      _vm.vertical.dimensions.background.width,
                                    height:
                                      _vm.vertical.dimensions.background.height,
                                    fill: "rgba(90,173, 244,0.1)",
                                    stroke: "#5aadf5",
                                    strokeWidth: 1
                                  }
                                }),
                                _vm._v(" "),
                                _vm._l(_vm.vertical.dimensions.ticks, function(
                                  tick,
                                  index
                                ) {
                                  return _c("v-line", {
                                    key: index,
                                    attrs: {
                                      points: tick,
                                      stroke: "#5aadf5",
                                      strokeWidth: 1,
                                      closed: "false"
                                    }
                                  })
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isNoData
                  ? _c("div", { staticClass: "no-data" }, [
                      _vm._v(
                        _vm._s(_vm.$t("page.patient.neofectHome.data.empty"))
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.error
                  ? _c("div", { staticClass: "no-data" }, [_vm._v("Error!")])
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }