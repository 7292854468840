var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "report-section movement-ratio" }, [
    _c("h1", [
      _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.movementTargeted")))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "report-contents" },
      [
        _vm.loading ? _c("Loading") : _vm._e(),
        _vm._v(" "),
        !_vm.isNoData
          ? _c("ul", [
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.motionRatio[0].name))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("numFormat")(_vm.motionRatio[0].ratio, "0.0")
                    ) + "%"
                  )
                ]),
                _vm._v(" "),
                _vm.isRangeCompare
                  ? _c(
                      "div",
                      {
                        staticClass: "in-decrease",
                        class: {
                          decrease:
                            _vm.motionRatio[0].ratio -
                              _vm.targetMotionRatio[0].ratio <
                            0,
                          increase:
                            _vm.motionRatio[0].ratio -
                              _vm.targetMotionRatio[0].ratio >
                            0
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "arrow",
                          class: {
                            down:
                              _vm.motionRatio[0].ratio -
                                _vm.targetMotionRatio[0].ratio <
                              0,
                            up:
                              _vm.motionRatio[0].ratio -
                                _vm.targetMotionRatio[0].ratio >
                              0
                          }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("numFormat")(
                                _vm.removeNumberMinusSign(
                                  _vm.motionRatio[0].ratio -
                                    _vm.targetMotionRatio[0].ratio
                                ),
                                "0.0"
                              )
                            ) +
                            "%\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.motionRatio[1].name))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("numFormat")(_vm.motionRatio[1].ratio, "0.0")
                    ) + "%"
                  )
                ]),
                _vm._v(" "),
                _vm.isRangeCompare
                  ? _c(
                      "div",
                      {
                        staticClass: "in-decrease",
                        class: {
                          decrease:
                            _vm.motionRatio[1].ratio -
                              _vm.targetMotionRatio[1].ratio <
                            0,
                          increase:
                            _vm.motionRatio[1].ratio -
                              _vm.targetMotionRatio[1].ratio >
                            0
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "arrow",
                          class: {
                            down:
                              _vm.motionRatio[1].ratio -
                                _vm.targetMotionRatio[1].ratio <
                              0,
                            up:
                              _vm.motionRatio[1].ratio -
                                _vm.targetMotionRatio[1].ratio >
                              0
                          }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("numFormat")(
                                _vm.removeNumberMinusSign(
                                  _vm.motionRatio[1].ratio -
                                    _vm.targetMotionRatio[1].ratio
                                ),
                                "0.0"
                              )
                            ) +
                            "%\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.motionRatio[2].name))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("numFormat")(_vm.motionRatio[2].ratio, "0.0")
                    ) + "%"
                  )
                ]),
                _vm._v(" "),
                _vm.isRangeCompare
                  ? _c(
                      "div",
                      {
                        staticClass: "in-decrease",
                        class: {
                          decrease:
                            _vm.motionRatio[2].ratio -
                              _vm.targetMotionRatio[2].ratio <
                            0,
                          increase:
                            _vm.motionRatio[2].ratio -
                              _vm.targetMotionRatio[2].ratio >
                            0
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "arrow",
                          class: {
                            down:
                              _vm.motionRatio[2].ratio -
                                _vm.targetMotionRatio[2].ratio <
                              0,
                            up:
                              _vm.motionRatio[2].ratio -
                                _vm.targetMotionRatio[2].ratio >
                              0
                          }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("numFormat")(
                                _vm.removeNumberMinusSign(
                                  _vm.motionRatio[2].ratio -
                                    _vm.targetMotionRatio[2].ratio
                                ),
                                "0.0"
                              )
                            ) +
                            "%\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.egToGe(_vm.motionRatio[3].name)))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("numFormat")(_vm.motionRatio[3].ratio, "0.0")
                    ) + "%"
                  )
                ]),
                _vm._v(" "),
                _vm.isRangeCompare
                  ? _c(
                      "div",
                      {
                        staticClass: "in-decrease",
                        class: {
                          decrease:
                            _vm.motionRatio[3].ratio -
                              _vm.targetMotionRatio[3].ratio <
                            0,
                          increase:
                            _vm.motionRatio[3].ratio -
                              _vm.targetMotionRatio[3].ratio >
                            0
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "arrow",
                          class: {
                            down:
                              _vm.motionRatio[3].ratio -
                                _vm.targetMotionRatio[3].ratio <
                              0,
                            up:
                              _vm.motionRatio[3].ratio -
                                _vm.targetMotionRatio[3].ratio >
                              0
                          }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("numFormat")(
                                _vm.removeNumberMinusSign(
                                  _vm.motionRatio[3].ratio -
                                    _vm.targetMotionRatio[3].ratio
                                ),
                                "0.0"
                              )
                            ) +
                            "%\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.egToGe(_vm.motionRatio[4].name)))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("numFormat")(_vm.motionRatio[4].ratio, "0.0")
                    ) + "%"
                  )
                ]),
                _vm._v(" "),
                _vm.isRangeCompare
                  ? _c(
                      "div",
                      {
                        staticClass: "in-decrease",
                        class: {
                          decrease:
                            _vm.motionRatio[4].ratio -
                              _vm.targetMotionRatio[4].ratio <
                            0,
                          increase:
                            _vm.motionRatio[4].ratio -
                              _vm.targetMotionRatio[4].ratio >
                            0
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "arrow",
                          class: {
                            down:
                              _vm.motionRatio[4].ratio -
                                _vm.targetMotionRatio[4].ratio <
                              0,
                            up:
                              _vm.motionRatio[4].ratio -
                                _vm.targetMotionRatio[4].ratio >
                              0
                          }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("numFormat")(
                                _vm.removeNumberMinusSign(
                                  _vm.motionRatio[4].ratio -
                                    _vm.targetMotionRatio[4].ratio
                                ),
                                "0.0"
                              )
                            ) +
                            "%\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              !_vm.isKids
                ? _c("li", [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.motionRatio[5].name))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("numFormat")(_vm.motionRatio[5].ratio, "0.0")
                        ) + "%"
                      )
                    ]),
                    _vm._v(" "),
                    _vm.isRangeCompare
                      ? _c(
                          "div",
                          {
                            staticClass: "in-decrease",
                            class: {
                              decrease:
                                _vm.motionRatio[5].ratio -
                                  _vm.targetMotionRatio[5].ratio <
                                0,
                              increase:
                                _vm.motionRatio[5].ratio -
                                  _vm.targetMotionRatio[5].ratio >
                                0
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "arrow",
                              class: {
                                down:
                                  _vm.motionRatio[5].ratio -
                                    _vm.targetMotionRatio[5].ratio <
                                  0,
                                up:
                                  _vm.motionRatio[5].ratio -
                                    _vm.targetMotionRatio[5].ratio >
                                  0
                              }
                            }),
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("numFormat")(
                                    _vm.removeNumberMinusSign(
                                      _vm.motionRatio[5].ratio -
                                        _vm.targetMotionRatio[5].ratio
                                    ),
                                    "0.0"
                                  )
                                ) +
                                "%\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isNoData
          ? _c("div", { staticClass: "no-data" }, [
              _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.empty")))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "error" }, [_vm._v("Error!")])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }