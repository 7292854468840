<template>
  <section class="MappingTherapist">
    <div class="mapping-top">
      <div class="mapping-search">
        <div class="search-select">
          <Select
            v-model="selectedSortingType"
            :options="sortingOption"
            label="label"
            placeholder
            :components="{ OpenIndicator, Deselect }"
          />
        </div>
        <div class="search">
          <span class="icon-ic_search" @click="searchTherapist">
            <span class="path1" />
            <span class="path2" />
          </span>
          <input v-model="searchText" type="text" @keypress.enter="searchTherapist">
        </div>
      </div>
    </div>
    <table>
      <colgroup>
        <col width="60px">
        <col width="180px">
        <col width="240px">
        <col width="80px">
        <col width="120px">
        <col width="200px">
      </colgroup>
      <thead>
        <tr>
          <th>No.</th>
          <th class="align-left">{{ $t("adminPage.management.mapping.providerPatient.list.header.name") }}</th>
          <th class="align-left">{{ $t("adminPage.management.mapping.providerPatient.list.header.email") }}</th>
          <th>{{ $t("adminPage.management.mapping.providerPatient.list.header.type") }}</th>
          <th>{{ $t("adminPage.management.mapping.providerPatient.list.header.patients") }}</th>
          <th />
        </tr>
      </thead>
    </table>
    <div class="custom-scrollbar table-scroll">
      <table>
        <colgroup>
          <col width="60px">
          <col width="180px">
          <col width="240px">
          <col width="80px">
          <col width="120px">
          <col width="200px">
        </colgroup>
        <tbody v-if="list.length > 0">
          <tr v-for="(item) of list" :key="item.index">
            <td>{{ item.index }}</td>
            <td class="align-left">{{ item.name }}</td>
            <td class="align-left">{{ item.email }}</td>
            <td>{{ item.therapist_type ? item.therapist_type : "-" }}</td>
            <td class="patient-count">{{ `${item.patients ? item.patients : 0} ${$t("adminPage.management.mapping.providerPatient.patients")}` }}</td>
            <td class="align-right default-type-btns small-btns">
              <button
                type="button"
                @click="handleMapping(item.therapist_id, item.name)"
              >{{ $t("adminPage.management.mapping.providerPatient.button.patientMapping") }}</button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">{{ $t("adminPage.management.mapping.providerPatient.empty") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-pagination v-model="currentPage" :page-count="totalPage" />
  </section>
</template>

<script>
import vPagination from "vue-plain-pagination";
import Select from "vue-select";
import PatientMapping from "./PatientMapping";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import {
  fetchGetClinicTherapist
} from "@/api/clinic.js";
import { mapGetters } from "vuex";
export default {
  name: "MappingTherapist",
  components: {
    vPagination,
    Select
  },
  mixins: [SelectMixin, ErrorMixin],
  data() {
    return {
      currentPage: 1,
      size: 15,
      totalCount: 1,
      totalPage: 1,
      searchText: "",
      sortingOption: [
        {
          label: this.$t("adminPage.management.mapping.providerPatient.selectOption.all"),
          value: "ALL"
        },
        {
          label: this.$t("adminPage.management.mapping.providerPatient.selectOption.name"),
          value: "NAME"
        },
        {
          label: this.$t("adminPage.management.mapping.providerPatient.selectOption.email"),
          value: "EMAIL"
        }
      ],
      selectedSortingType: {
        label: this.$t("adminPage.management.mapping.providerPatient.selectOption.all"),
        value: "ALL"
      },
      list: []
    };
  },
  computed: {
    ...mapGetters({
      clinic: "user/clinic"
    })
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchTherapist();
      }
    }
  },
  created() {
    this.searchTherapist();
  },
  methods: {
    async searchTherapist() {
      const search = {
        page: this.currentPage,
        size: this.size,
        keyword: this.searchText,
        search_type: this.selectedSortingType.value,
        sorts: [{ field: "time", order: "desc" }]
      };
      try {
        const result = await fetchGetClinicTherapist(this.clinic.id, search);
        this.totalCount = result.total_count < 1 ? 1 : result.total_count;
        this.totalPage = result.total_page < 1 ? 1 : result.total_page;
        const addIndex = result.list.map((item, index) => {
          item.index =
            result.total_count - index - (this.currentPage - 1) * this.size;
          return item;
        });
        this.list = addIndex;
      } catch (error) {
        this.handleError(error, "MappingTherapist > Get Clinic Therapist API");
      }
    },
    handleMapping(therapistId, therapistName) {
      const clinicId = this.clinic.id;
      this.$modal.show(
        PatientMapping,
        {
          clinicId,
          therapistName,
          therapistId,
          searchTherapist: this.searchTherapist
        },
        {
          height: "auto",
          width: 960,
          clickToClose: false
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.MappingTherapist {
  background: #ffffff;
  border: 1px solid $borderColor1;
  border-radius: 3px;
  .mapping-top {
    padding: 16px;
    display: flex;
    .mapping-search {
      display: flex;
      align-content: center;
      .search {
        padding: 0 16px;
        position: relative;
        .icon-ic_search {
          position: absolute;
          top: 50%;
          right: 32px;
          transform: translate(0, -50%);
          font-size: $fontSize2;
          color: $fontColor2;
        }
        input[type="text"] {
          padding-right: 55px;
          height: 35px;
          border-radius: 2px;
        }
      }
      .search-select {
        width: 110px;
        ::v-deep .v-select {
          background: #ffffff;
          .vs__dropdown-toggle {
            border-radius: 2px;
          }
          .vs__dropdown-menu {
            min-width: 110px;
          }
        }
      }
    }

    .control-btns {
      margin-left: auto;
      display: flex;
      justify-content: center;

      button {
        &:last-of-type {
          margin-left: 8px;
        }
      }
    }
  }
  .patient-count {
    button {
      text-decoration: underline;
      font-size: $fontSize7;
      color: $fontColor1;
    }
  }
  table {
    tbody {
      tr:first-of-type {
        th,
        td {
          border-top: 0 none;
        }
      }
    }
  }
}
</style>
