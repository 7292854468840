/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-redeclare */
import { getToken } from "@/utils/auth";
import SendBird from "sendbird";
import { sendBirdAppId } from "@/settings";
import { fetchGetPatientsList } from "@/api/user";
import { fetchGetSendbirdToken } from "@/api/chat";
import { getMessage, getPersonalChannelName } from "@/utils/sendbird";
import Vue from "vue";
import store from "@/store";

const sendbird = new SendBird({ appId: sendBirdAppId });

const SingletonSendBird = (function() {
  let instanceSendbird;

  async function init() {
    const token = getToken();
    const therapistId = `${token.user_id}`;
    const apiResult = await fetchGetSendbirdToken();
    const sendBirdAccessToken = apiResult.access_token;
    if (!sendBirdAccessToken) {
      // alert("chating server has something wrong...");
      console.log("chatting server crashed");
      return;
    }
    return new Promise(resolve => {
      sendbird.connect(therapistId, sendBirdAccessToken, function(user, error) {
        if (error) {
          console.error(
            "request failed that connecting sendbird.",
            user,
            error
          );
          throw error;
        }
        sendbird.updateCurrentUserInfo(token.name, null, (response, error) => {
          if (error) console.error("fail to sendbird profile.", error);
        });
        fetchGetPatientsList()
          .then(async data => {
            if (data.status) {
              console.error("api error", data);
              return;
            }
            const list = data;
            const userList = [];
            const filterLimit = 200;

            let max = parseInt(list.length / filterLimit);
            max += parseInt(list.length % filterLimit) > 0 ? 1 : 0;
            for (let i = 0; i < max; ++i) {
              const filter = list.slice(i * filterLimit, (i + 1) * filterLimit);
              const applicationUserListQueryByIds = sendbird.createApplicationUserListQuery();
              // limit 의 기본값은 20. 최대 100.
              applicationUserListQueryByIds.limit = 100;
              // userIdsFilter 의 리스트 값 최대치는 250. 이 이상으로 값을 넣어서 필터링할 경우 Sendbird 에서 414 에러를 반환할 수 있다.
              // https://sendbird.com/docs/chat/v3/javascript/guides/application#2-retrieve-a-list-of-users
              // "-1"을 넣은 이유는 Sendbird 는 empty array 를 인자로 주면 전체 유저 리스트를 반환한다.
              applicationUserListQueryByIds.userIdsFilter = filter.length > 0 ? filter.map(x => x.id) : ["-1"];

              // 환자 목록을 100개단위(applicationUserListQueryByIds.limit 에 설정된 값) 만큼 받아오고 계속 값이 있으면
              // 반복해서 가져와서 모든 데이터를 가져올 수 있도록 함.
              while (applicationUserListQueryByIds.hasNext) {
                await new Promise(resolve => {
                  applicationUserListQueryByIds.next((users, error) => {
                    if (error) {
                      throw error;
                    }

                    resolve(users);
                  });
                })
                  .catch(err => {
                    console.error("Sendbird Error", err);
                  })
                  .then(result => {
                    userList.push(...result);
                  });
              }
            }

            const patientsInfo = list
              .map(x => ({ id: x.id, image: x.image, name: x.name }))
              .reduce((map, obj) => {
                map[obj.id] = { image: obj.image, name: obj.name };
                return map;
              }, {});

            const channelInfos = userList.map(x => {
              return {
                name: getPersonalChannelName(x.userId),
                patient: {
                  user_id: x.userId,
                  name: patientsInfo[x.userId].name,
                  image: patientsInfo[x.userId].image
                }
              };
            });

            store.commit("chat/INIT_CHANNELS", channelInfos);
            if (error) {
              console.error(
                "error sendbird: createApplicationUserListQuery",
                error
              );
              return;
            }
            sendbird.getTotalUnreadChannelCount(async function(count, error) {
              if (error) {
                return;
              }
              const channelListQuery = sendbird.GroupChannel.createMyGroupChannelListQuery();
              channelListQuery.includeEmpty = true;
              channelListQuery.limit = 100; // The value of pagination limit could be set up to 100.

              const channelList = [];

              while (channelListQuery.hasNext) {
                await new Promise(resolve => {
                  channelListQuery.next(function (channelList, error) {
                    if (error) {
                      return;
                    }
                    resolve(channelList);
                  });
                })
                  .catch(err => {
                    console.error("Sendbird Error : ", err);
                  })
                  .then(result => {
                    channelList.push(...result.map(x => ({
                      name: getPersonalChannelName(
                        x.members
                          .filter(x => x.userId !== therapistId)
                          .map(x => x.userId)
                      ),
                      unreadMessageCount: x.unreadMessageCount,
                      lastMessage: x.lastMessage ? x.lastMessage.message : "",
                      lastMessageCreatedAt: x.lastMessage
                        ? x.lastMessage.createdAt
                        : undefined
                    })));
                  });
              }
              store.commit("chat/INIT_GROUP_CHANNEL", channelList);
            });
          })
          .catch(e => {
            // TODO 시각적으로 보여주는 로직 추가.
            console.error("catch", e);
          });
        // connection handler
        const ConnectionHandler = new sendbird.ConnectionHandler();
        ConnectionHandler.onReconnectStarted = function() {
          console.log("reconnect start");
        };
        ConnectionHandler.onReconnectSucceeded = function() {
          console.log("reconnect success");
        };
        ConnectionHandler.onReconnectFailed = function() {
          console.log("reconnect failed");
          disconnectLoop = setTimeout(() => {
            console.log("Retry Connection");
            // sendbird 커넥션이 failed 일 경우 1초 간격으로 커넥션 연결시도
            sendbird.reconnect();
          }, 1000);
        };
        sendbird.addConnectionHandler(
          "sendbird_connection_handler",
          ConnectionHandler
        );
        /*
        // message handler
        */
        const ChannelHandler = new sendbird.ChannelHandler();
        ChannelHandler.onMessageReceived = function(channel, message) {
          const messageVo = getMessage(message);
          store.commit("chat/NEW_MESSAGE", {
            channelName: channel.name,
            message: messageVo
          });
          if (
            store.getters["chat/selectedInfo"] === undefined ||
            store.getters["chat/selectedInfo"].channelName !== channel.name
          ) {
            const chatMessageUser = store.getters["patients"].find(item => item.email === messageVo.nickname);
            Vue.$toast.open(
              `New Message: You have a new text chat message from ${
                chatMessageUser === undefined ? "" : chatMessageUser.name
              }.`
            );
          } else {
            channel.markAsRead();
          }
        };
        sendbird.addChannelHandler("sendbird_channel_handler", ChannelHandler);
        /*
        //  Typing indicators
        */
        var handler = new sendbird.ChannelHandler();
        handler.onTypingStatusUpdated = function(groupChannel) {
          var members = groupChannel.getTypingMembers();
          if (members.length > 0) {
            store.dispatch(
              "chat/setChatInfo",
              `${members[0].nickname} is typing...`
            );
            // store.commit(
            //   "chat/SET_CHAT_INFO",
            //   `${members[0].nickname} is typing...`
            // );
          } else {
            store.dispatch("chat/setChatInfo", ``);
            // store.commit("chat/SET_CHAT_INFO", "");
          }
        };
        sendbird.addChannelHandler("TYPING_HANDLER", handler);
        /*
          Retrieve number of members who have not read a message
        */
        var handler = new sendbird.ChannelHandler();
        handler.onReadReceiptUpdated = function(groupChannel) {
          store.commit("chat/SET_GROUPCHANNEL_PATIENT_UNREAD_TO_ZERO", {
            channelName: groupChannel.name,
            readMessageAtArr: groupChannel.cachedReadReceiptStatus
          });
        };
        sendbird.addChannelHandler("READ_RECIPT_HANDLER", handler);
        resolve(sendbird);
      });
    });
  }

  return {
    getInstance: function() {
      if (!instanceSendbird) {
        instanceSendbird = init();
      }
      return instanceSendbird;
    },
    getForceInstance: function () {
      instanceSendbird = undefined;
      return instanceSendbird = init();
    }
  };
})();

export default SingletonSendBird;
