<template>
  <div ref="createEvent" class="CreateEvent">
    <div class="wrapper">
      <div class="Event-contents">
        <!-- 이벤트 타입 라디오 -->
        <div class="radio-section">
          <span v-for="(type, index) of eventTypes" :key="index">
            <input
              :id="type.value"
              v-model="pickedType"
              type="radio"
              :value="type.value"
              :disabled="
                createEventInfo.method === 'EDIT' ||
                  type.value === 'SCREENSHARE' ||
                  type.value === 'THERAPISTLIVE'
              "
            >
            <label :for="type.value">{{ typeFilter(type.type) }}</label>
          </span>
        </div>
        <div class="time-section">
          <span class="icon-ic_time ic-icon">
            <span class="path1" />
            <span class="path2" />
          </span>
          <!-- 개인일정 옵션 체크박스 -->
          <div v-if="pickedType === 'GENERAL'" class="checkbox-section">
            <span>
              <input
                id="allDay"
                v-model="checkedOptions"
                type="checkbox"
                value="allDay"
              >
              <label for="allDay">{{
                $t("page.calendar.createEvent.type.general.allDay")
              }}</label>
            </span>
            <span>
              <input
                id="daily"
                v-model="checkedOptions"
                type="checkbox"
                value="daily"
              >
              <label for="daily">{{
                $t("page.calendar.createEvent.type.general.daily")
              }}</label>
            </span>
          </div>
          <!-- 시간대 설정 -->
          <div v-if="!isAllDay" class="time-range">
            <Select
              v-model="startTime"
              :options="startTimes"
              label="label"
              placeholder="H:mm A"
              :components="{ OpenIndicator, Deselect }"
            >
              <div slot="no-options" class="vs__no-options">
                Sorry, no matching time.
              </div>
            </Select>
            <!-- <Timepicker
              v-model="startTime"
              format="hh:mm a"
              :minute-interval="5"
              close-on-complete
              :blur-delay="3000"
            />-->
            <span class="center-hyphen">-</span>
            <Select
              v-model="endTime"
              :options="endTimes"
              label="label"
              placeholder="H:mm A"
              :components="{ OpenIndicator, Deselect }"
            >
              <div slot="no-options" class="vs__no-options">
                Sorry, no matching time.
              </div>
            </Select>
            <!-- <Timepicker
              v-model="endTime"
              format="hh:mm a"
              :minute-interval="5"
              close-on-complete
              :blur-delay="3000"
            />-->
          </div>
          <!-- 날짜 설정 -->
          <div v-if="pickedType === 'GENERAL' && isDaily" class="date-range">
            <span class="icon-ic_date ic-icon">
              <span class="path1" />
              <span class="path2" />
              <span class="path3" />
              <span class="path4" />
              <span class="path5" />
              <span class="path6" />
              <span class="path7" />
              <span class="path8" />
              <span class="path9" />
              <span class="path10" />
            </span>
            <Datepicker
              v-model="startDate"
              format="MMM dd, yyyy"
              :monday-first="true"
            />
            <span class="center-hyphen">-</span>
            <Datepicker
              v-model="endDate"
              format="MMM dd, yyyy"
              :monday-first="true"
            />
          </div>
        </div>
        <!-- 게임설정 -->
        <div v-if="pickedType === 'COOPGAME'" class="game-section">
          <span class="icon-ic_star ic-icon">
            <span class="path1" />
          </span>
          <Select
            v-model="selectedGame"
            :options="games"
            label="print_title"
            placeholder="Select Game"
            :components="{ OpenIndicator, Deselect }"
          >
            <div slot="no-options" class="vs__no-options">
              Sorry, no matching game.
            </div>
          </Select>
        </div>
        <!-- 환자목록 -->
        <div
          v-if="
            pickedType === 'COOPGAME' ||
              pickedType === 'SCREENSHARE' ||
              pickedType === 'VIDEOSESSION'
          "
          class="patients-section"
        >
          <span class="icon-ic_group ic-icon">
            <span class="path1" />
            <span class="path2" />
            <span class="path3" />
            <span class="path4" />
            <span class="path5" />
          </span>
          <span v-if="pickedType === 'COOPGAME'" class="icon-ic_info_circle">
            <span class="path1" />
            <span class="path2" />
          </span>
          <div class="notice-information">
            The max number of attendees differs for each game.
          </div>
          <!-- 여러환자 선택 -->
          <div v-if="pickedType === 'COOPGAME'">
            <Select
              v-model="selectedPatients"
              :options="createEventInfo.allPatients"
              label="name"
              :placeholder="patientPlaceholder"
              :components="{ OpenIndicator, Deselect }"
              :multiple="true"
              :disabled="selectedGame.game_limit <= selectedPatients.length"
            >
              <div slot="no-options" class="vs__no-options">
                {{ $t("page.calendar.createEvent.validation.noPatient") }}
              </div>
            </Select>
            <!-- 선택된 환자 출력 -->
            <div
              v-if="selectedPatients.length !== 0"
              class="checked-patient-list"
            >
              <ul>
                <li v-for="(patient, index) of selectedPatients" :key="index">
                  <div class="patient-name">
                    <span class="icon-ic_check_circle_fill">
                      <span class="path1" />
                      <span class="path2" />
                    </span>
                    <span>{{ patient.name }}</span>
                  </div>
                  <div class="patient-time">
                    {{ printPatientTime(patient.zone_offset) }}
                  </div>
                  <button
                    type="button"
                    class="del-btn"
                    @click.once="deletePatient(patient)"
                  >
                    <span class="icon-ic_delete_circle_fill">
                      <span class="path1" />
                      <span class="path2" />
                      <span class="path3" />
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <!-- 이메일 초대 -->
          <div v-else-if="inviteByEmail">
            <button
              class="invite-email-cancel-button"
              @click="() => setInviteByEmail(null)"
            >
              {{ inviteByEmail }} <small>초대취소</small>
            </button>
          </div>
          <!-- 단일 환자 선택 -->
          <div v-else>
            <Select
              v-model="selectedPatients"
              :options="createEventInfo.allPatients"
              :placeholder="patientPlaceholder"
              :components="{ OpenIndicator, Deselect }"
              :get-option-label="getPatientLabel"
              class="paint-search-input"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching && !isEmail(search)">
                  {{ noPaintText }}
                </template>
                <template v-else-if="searching && isEmail(search)">
                  {{ inviteByEmailText }}
                  <button
                    class="invite-email-button"
                    @click="() => setInviteByEmail(search)"
                  >
                    초대하기
                  </button>
                </template>
                <em v-else>{{ noPaintText }}</em>
              </template>

              <!-- <div slot="no-options" class="vs__no-options">
                {{ noPaintText }}
              </div> -->
            </Select>
          </div>
        </div>
        <!-- 텍스트컨텐츠 입력부 -->
        <div v-if="pickedType === 'THERAPISTLIVE'" class="subject-text">
          Topic
        </div>
        <div class="text-section">
          <span class="icon-ic_text ic-icon">
            <span class="path1" />
            <span class="path2" />
            <span class="path3" />
          </span>
          <div
            v-if="
              pickedType === 'COOPGAME' ||
                pickedType === 'SCREENSHARE' ||
                pickedType === 'VIDEOSESSION'
            "
          >
            <textarea
              v-model="contents"
              :placeholder="messagePlaceholder"
              class="only-text"
            />
          </div>
          <div v-else-if="pickedType === 'THERAPISTLIVE'">
            <input
              v-model="title"
              type="text"
              :placeholder="topicPlaceholder"
            >
            <textarea
              v-model="contents"
              :placeholder="descriptionPlaceholder"
            />
          </div>
          <div v-else-if="pickedType === 'GENERAL'">
            <input
              v-model="title"
              type="text"
              :placeholder="titlePlaceholder"
            >
            <textarea v-model="contents" :placeholder="memoPlaceholder" />
          </div>
        </div>
        <div v-if="pickedType === 'THERAPISTLIVE'" class="link-section">
          <span class="icon-ic_connecting ic-icon">
            <span class="path1" />
            <span class="path2" />
          </span>
          <input
            v-model="contents_link"
            type="text"
            placeholder="URL address of Live"
          >
        </div>
      </div>
      <!-- 서밋버튼 -->
      <div class="popup-submit-btns">
        <button type="button" class="accept-btn" @click="handleSubmit">
          {{ $t("page.calendar.createEvent.saveButton") }}
        </button>
      </div>
      <div class="popup-btns">
        <!-- 팝업 컨트롤 버튼 -->
        <button type="button" class="close-btn icon-btn" @click="handleClose">
          <span class="icon-ic_delete">
            <span class="path1" />
            <span class="path2" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Timepicker from "vue2-timepicker";
import Select from "vue-select";
import Datepicker from "vuejs-datepicker";
import Confirm from "@/components/Confirm";
import { fetchCreateEvent, fetchEditEvent } from "@/api/calendar.js";
import { fetchGetTherapistInfo } from "@/api/user.js";
import times from "@/utils/timeList.js";
import { isEmail } from "@/utils/validate.js";
import { mapGetters } from "vuex";
import { SelectMixin } from "@/mixins/SelectMixin.js";
export default {
  name: "CreateEvent",
  mixins: [SelectMixin],
  components: {
    Timepicker,
    Select,
    Datepicker,
  },
  props: {
    createEventInfo: Object,
    changeEventSources: Function,
    handleCreateEventPopupClose: Function,
    handleError: Function,
  },
  data() {
    return {
      inviteByEmail: null,
      eventTypes: [
        {
          type: "Video Call",
          value: "VIDEOSESSION",
        },
        {
          type: "Screen Sharing",
          value: "SCREENSHARE",
        },
        {
          type: "Stroke coach Live",
          value: "THERAPISTLIVE",
        },
        {
          type: "General",
          value: "GENERAL",
        },
      ],
      pickedType: "",
      therapist: {},
      selectedGame: null,
      selectedPatients: [],
      games: [],
      checkedOptions: [],
      isAllDay: false,
      isDaily: false,
      contents: "",
      contents_link: "",
      title: "",
      startTimes: JSON.parse(JSON.stringify(times)),
      endTimes: JSON.parse(JSON.stringify(times)),
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      validationMessage: this.$t(
        "page.calendar.createEvent.validation.requiredFieldsLong"
      ),
    };
  },
  watch: {
    pickedType: function (newVal, oldVal) {
      if (newVal !== oldVal && this.createEventInfo.method !== "EDIT") {
        this.checkedOptions = [];
        this.selectedPatients = [];
        this.title = "";
        this.contents = "";
        this.endDate = this.$moment(this.startDate).format("YYYY-MM-DD");
      }
    },
    checkedOptions: function (newVal, oldVal) {
      this.isAllDay = false;
      this.isDaily = false;
      if (newVal.length === 2) {
        this.isAllDay = true;
        this.isDaily = true;
        this.endDate = this.$moment(this.startDate)
          .add(1, "months")
          .format("YYYY-MM-DD");
      } else {
        newVal.forEach((option) => {
          if (option === "allDay") {
            this.isAllDay = true;
            this.endDate = this.$moment(this.startDate).format("YYYY-MM-DD");
          } else if (option === "daily") {
            this.isDaily = true;
            this.endDate = this.$moment(this.startDate)
              .add(1, "months")
              .format("YYYY-MM-DD");
          }
        });
      }
    },
    selectedGame: function (newVal, oldVal) {
      if (newVal != null && newVal.game_limit < this.selectedPatients.length) {
        const newSelectedPatients = this.selectedPatients.filter(
          (patient, index) => {
            return index < newVal.game_limit;
          }
        );
        this.selectedPatients = newSelectedPatients;
      }
    },
    selectedPatients: function (newVal, oldVal) {
      newVal.zone_offset;
    },
    createEventInfo: function (newVal, oldVal) {
      this.initSettings();
    },
    startDate: function (newVal, oldVal) {
      if (typeof newVal !== "string") {
        this.startDate = this.$moment(newVal).format("YYYY-MM-DD");
      }
    },
    endDate: function (newVal, oldVal) {
      if (typeof newVal !== "string") {
        this.endDate = this.$moment(newVal).format("YYYY-MM-DD");
      }
    },
    startTime: function (newVal, oldVal) {
      let idx = this.startTimes.findIndex((t) => t.value === newVal.value);
      this.endTimes = times.filter(
        (endTime) => this.startTime.value < endTime.value
      );
      this.endTime = this.endTimes[0];
    },
  },
  computed: {
    ...mapGetters({
      clinic: "user/clinic",
    }),
    patientPlaceholder() {
      return this.$t("page.calendar.createEvent.placeholder.patient");
    },
    messagePlaceholder() {
      return this.$t("page.calendar.createEvent.placeholder.message");
    },
    descriptionPlaceholder() {
      return this.$t("page.calendar.createEvent.placeholder.description");
    },
    memoPlaceholder() {
      return this.$t("page.calendar.createEvent.placeholder.memo");
    },
    topicPlaceholder() {
      return this.$t("page.calendar.createEvent.placeholder.topic");
    },
    titlePlaceholder() {
      return this.$t("page.calendar.createEvent.placeholder.title");
    },
    noPaintText() {
      return this.$t("page.calendar.createEvent.validation.noPatient");
    },
    inviteByEmailText() {
      return this.$t("page.calendar.createEvent.validation.inviteByEmail");
    },
  },
  methods: {
    isEmail,
    setInviteByEmail(email) {
      this.inviteByEmail = email;
    },
    typeFilter(value) {
      if (value === "Video Call") {
        return this.$t("page.calendar.createEvent.type.videoCall");
      } else if (value === "Screen Sharing") {
        return this.$t("page.calendar.createEvent.type.screenSharing");
      } else if (value === "Stroke coach Live") {
        return this.$t("page.calendar.createEvent.type.providerLive");
      } else {
        return this.$t("page.calendar.createEvent.type.general.title");
      }

      //     type: "Video Call",
      // type: "Screen Sharing",
      // type: "Stroke coach Live",
      // type: "General",
    },
    handleClose() {
      /*
      //// 팝업 닫기 버튼 ////
      */
      if (this.isChangedValues()) {
        this.$modal.show(
          Confirm,
          {
            type: "close",
            submit: this.confirm_close_ok,
            confirm: {
              title: this.$t("page.calendar.createEvent.closeModal.title"),
              message: this.$t(
                "page.calendar.createEvent.closeModal.description"
              ),
            },
          },
          {
            height: "auto",
          }
        );
      } else {
        // 값의 변화가 없을경우 컨펌하지 않는다.
        this.confirm_close_ok();
      }
    },
    confirm_close_ok() {
      /*
      //// 팝업 닫기 버튼 컨펌 okay ////
      */
      this.handleCreateEventPopupClose();
    },
    deletePatient(target) {
      /*
      //// 환자리스트에서 환자삭제 ////
      */
      this.selectedPatients = this.selectedPatients.filter((patient) => {
        return patient !== target;
      });
    },
    getPatientLabel(option) {
      const patientOffset = option.zone_offset ? option.zone_offset : 0;

      const startDatePatient = this.$moment(
        `${this.startDate} ${this.startTime.value}`,
        "YYYY-MM-DD hh:mm A"
      ).utcOffset(patientOffset);
      const endDatePatient = this.$moment(
        `${this.endDate} ${this.endTime.value}`,
        "YYYY-MM-DD hh:mm A"
      ).utcOffset(patientOffset);

      const time = ` ${startDatePatient.format(
        "h:mm A"
      )} - ${endDatePatient.format("h:mm A")}`;
      return (
        option.name +
        "( " +
        (option.state ? `\t${option.state}` : "") +
        time +
        " )"
      );
    },
    printPatientTime(zone_offset) {
      /*
      //// 환자리스트에서 환자시간대 표시(시차계산) ////
      */
      const startDate = this.$moment(this.createEventInfo.start);
      const endDate = this.$moment(this.createEventInfo.end);

      const startOffsetDate = startDate.clone().utcOffset(zone_offset);
      const endOffsetDate = endDate.clone().utcOffset(zone_offset);

      return `${startDate.format("dddd, MMMM D, A h:mm")} - ${endDate.format(
        "A h:mm"
      )} : ${startOffsetDate.format(
        "dddd, MMMM D, A h:mm"
      )} - ${endOffsetDate.format("A h:mm")}`;
    },
    validationValues() {
      /*
      //// 이벤트 등록 컨펌 이전에 값 확인 ////
      */
      if (this.pickedType === "COOPGAME") {
        if (this.selectedPatients.length < this.selectedGame.game_limit) {
          this.validationMessage = this.$t(
            "page.calendar.createEvent.validation.requiredFieldsLong"
          );
          return true;
        } else {
          return false;
        }
      } else if (this.pickedType === "SCREENSHARE") {
        if (!this.selectedPatients || this.selectedPatients.length === 0) {
          this.validationMessage = this.$t(
            "page.calendar.createEvent.validation.choosePatient"
          );
          return true;
        } else {
          return false;
        }
      } else if (this.pickedType === "THERAPISTLIVE") {
        if (!this.contents || !this.contents_link || !this.title) {
          this.validationMessage = this.$t(
            "page.calendar.createEvent.validation.requiredFieldsLong"
          );
          return true;
        } else {
          return false;
        }
      } else if (this.pickedType === "VIDEOSESSION") {
        if (
          (!this.selectedPatients || this.selectedPatients.length === 0) &&
          !this.inviteByEmail
        ) {
          this.validationMessage = this.$t(
            "page.calendar.createEvent.validation.choosePatient"
          );
          return true;
        } else {
          return false;
        }
      } else if (this.pickedType === "GENERAL") {
        if (!this.title) {
          this.validationMessage = this.$t(
            "page.calendar.createEvent.validation.requiredFieldsLong"
          );
          return true;
        } else {
          return false;
        }
      }
    },
    isChangedValues() {
      /*
      //// 팝업을 닫을경우 컨펌창을 위한 값 체크 ////
      */

      // 팝업창을 닫으려고 할때 값이 변했는지 체크
      // 현재는 값이 넣어져있는지 아닌지로만 체크함
      if (this.pickedType === "COOPGAME") {
        if (this.selectedPatients || this.contents) {
          return true;
        } else {
          return false;
        }
      } else if (
        this.pickedType === "SCREENSHARE" ||
        this.pickedType === "VIDEOSESSION"
      ) {
        if (this.selectedPatients || this.contents) {
          return true;
        } else {
          return false;
        }
      } else if (this.pickedType === "THERAPISTLIVE") {
        if (this.contents || this.contents_link || this.title) {
          return true;
        } else {
          return false;
        }
      } else if (this.pickedType === "GENERAL") {
        if (this.contents || this.title || this.checkedOptions) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    handleSubmit() {
      /*
      //// 이벤트 등록, 수정 서밋 ////
      */
      const startDate = this.$moment(
        `${this.startDate} ${this.startTime.value}`,
        "YYYY-MM-DD hh:mm A"
      );
      const endDate = this.$moment(
        `${this.endDate} ${this.endTime.value}`,
        "YYYY-MM-DD hh:mm A"
      );
      const title =
        this.pickedType === "COOPGAME"
          ? "Co-op Game"
          : this.pickedType === "SCREENSHARE"
          ? "Screen Sharing"
          : this.pickedType === "VIDEOSESSION"
          ? "Video Call"
          : this.title;

      const putSchedule = {
        type: this.pickedType,
        title,
        guest_yn: false,
        videocall_division: "TOKBOX",
        division: this.pickedType,
        state: "CONFIRM",
        start: startDate.toDate(),
        end: endDate.toDate(),
        zone_offset: this.createEventInfo.zone_offset,
        patients: this.selectedPatients,
        contents: this.contents,
        contents_link: this.contents_link,
        allday_yn: this.isAllDay,
        repeat_division: this.isDaily ? "DAILY" : "NONE",
      };
      if (this.pickedType === "COOPGAME") {
        // 코옵 게임일경우
        putSchedule.game = this.selectedGame;
      }
      // 이메일 초대일 경우
      if (this.inviteByEmail) {
        putSchedule.guest_yn = true;
        putSchedule.patients = [
          {
            id: "-1",
            attendance_state: "WAIT",
            name: this.inviteByEmail,
            email: this.inviteByEmail,
          },
        ];
      } else if (
        (this.pickedType === "SCREENSHARE" &&
          !Array.isArray(this.selectedPatients)) ||
        (this.pickedType === "VIDEOSESSION" &&
          !Array.isArray(this.selectedPatients))
      ) {
        // 다수의 환자를 넣느냐 마느냐의 문제
        putSchedule.patients = [this.selectedPatients];
      } else {
        putSchedule.patients = this.selectedPatients;
      }
      if (this.validationValues()) {
        return this.$modal.show(
          Confirm,
          {
            type: "validate",
            confirm: {
              title: this.$t(
                "page.calendar.createEvent.validation.requiredFields"
              ),
              message: this.validationMessage,
            },
          },
          {
            height: "auto",
          }
        );
      }
      if (this.pickedType === "GENERAL") {
        // General 일정으로 서밋할 경우 컨펌을 하지 않는다.
        this.confirm_ok(putSchedule);
      } else {
        return this.$modal.show(
          Confirm,
          {
            type: this.pickedType,
            eventInfo: putSchedule,
            submit: this.confirm_ok,
          },
          {
            height: "auto",
          }
        );
      }
    },
    confirm_ok(schedule) {
      /*
      //// 이벤트 등록, 수정 서밋 컨펌 okay ////
      */
      let newSchedule = JSON.parse(JSON.stringify(schedule));
      newSchedule.start_date = this.$moment(newSchedule.start).format(
        "YYYY-MM-DD"
      );
      newSchedule.start_time = this.$moment(newSchedule.start).format("HH:mm");
      newSchedule.end_date = this.$moment(newSchedule.end).format("YYYY-MM-DD");
      newSchedule.end_time = this.$moment(newSchedule.end).format("HH:mm");
      delete newSchedule.start;
      delete newSchedule.end;

      if (this.createEventInfo.method === "ADD") {
        //clinic id추가
        newSchedule.organization_id = this.clinic.id;
        fetchCreateEvent(newSchedule)
          .then((result) => {
            // console.log(result);
            const className =
              this.pickedType === "VIDEOSESSION"
                ? "group1"
                : this.pickedType === "COOPGAME"
                ? "group2"
                : this.pickedType === "SCREENSHARE"
                ? "group3"
                : this.pickedType === "THERAPISTLIVE"
                ? "group4"
                : "group5";
            const newSources = {
              className,
              events: result,
            };
            this.changeEventSources(this.createEventInfo.method, newSources);
            this.confirm_close_ok();
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else if (this.createEventInfo.method === "EDIT") {
        fetchEditEvent(this.createEventInfo.id, newSchedule)
          .then((result) => {
            // console.log(result);
            const className =
              this.pickedType === "VIDEOSESSION"
                ? "group1"
                : this.pickedType === "COOPGAME"
                ? "group2"
                : this.pickedType === "SCREENSHARE"
                ? "group3"
                : this.pickedType === "THERAPISTLIVE"
                ? "group4"
                : "group5";
            const newSources = {
              className,
              events: result,
            };
            this.changeEventSources(
              this.createEventInfo.method,
              newSources,
              this.createEventInfo.id
            );
            this.confirm_close_ok();
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    initSettings() {
      this.startTimes.pop();
      this.endTimes.shift();

      // ADD상태일때 필요한 인풋값 셋팅
      let startDate = new Date(this.createEventInfo.start);
      let endDate = new Date(this.createEventInfo.end);
      if (this.createEventInfo.method === "EDIT") {
        // EDIT상태일때 필요한 인풋값 셋팅
        this.isAllDay = this.createEventInfo.allday_yn;
        this.isDaily = this.createEventInfo.repeat_division === "DAILY";
      } else {
        startDate.setHours(9);
        endDate.setHours(10);
        this.isAllDay = false;
        this.isDaily = false;
      }
      this.startDate = startDate;
      this.endDate = endDate;
      // console.log(startDate, endDate);
      const startTime = this.$moment(startDate).format("HH:mm");
      const endTime = this.$moment(endDate).format("HH:mm");

      this.startTime = this.startTimes.filter((time) => {
        if (time.value === startTime) {
          return time;
        }
      })[0];
      this.endTime = this.endTimes.filter((time) => {
        if (time.value === endTime) {
          return time;
        }
      })[0];
      this.pickedType =
        this.createEventInfo.className === "group1"
          ? "VIDEOSESSION"
          : this.createEventInfo.className === "group2"
          ? "COOPGAME"
          : this.createEventInfo.className === "group3"
          ? "SCREENSHARE"
          : this.createEventInfo.className === "group4"
          ? "THERAPISTLIVE"
          : "GENERAL";
      this.title = this.createEventInfo.title;
      this.contents = this.createEventInfo.contents;
      this.contents_link = this.createEventInfo.contents_link;
      this.selectedGame = this.createEventInfo.game;
      this.selectedPatients = this.createEventInfo.patients;
      let getOptions = [];
      if (this.isAllDay) getOptions.push("allDay");
      if (this.isDaily) getOptions.push("daily");
      this.checkedOptions = getOptions;
    },
  },
  created() {
    this.initSettings();
  },
};
</script>

<style lang="scss" scoped>
.CreateEvent {
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(255, 255, 255, 0);
  }
  .wrapper {
    z-index: 2;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
    width: $modalWidth2;
    border-radius: 0.5rem;
    background: #ffffff;
    box-sizing: border-box;
    position: fixed;
    padding: 50px 0 0 0;
    color: $fontColor1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .Event-contents {
    padding: 0 64px 32px 64px;
    font-size: $fontSize6;

    .radio-section {
      padding-bottom: 20px;
      overflow: hidden;
      span {
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
        overflow-wrap: break-spaces;
        /*&:nth-of-type(3n) {*/
        /*  margin-right: 0;*/
        /*}*/
      }
    }
    .ic-icon {
      position: absolute;
      top: 5px;
      left: -40px;
      color: $fontColor2;
    }

    .time-section {
      .checkbox-section {
        padding-top: 5px;
        overflow: hidden;
        span {
          float: left;
          margin-right: 32px;
        }
        & + .time-range {
          margin-top: 20px;
        }
      }
      position: relative;
      .time-range {
        clear: both;
        display: flex;
        align-items: center;
      }
      .date-range {
        clear: both;
        margin-top: 16px;
        position: relative;
        display: flex;
        align-items: center;
      }
    }
    .game-section {
      margin-top: 16px;
      position: relative;
    }
    .patients-section {
      margin-top: 16px;
      position: relative;
      &::v-deep input {
        &::-webkit-input-placeholder {
          /* Edge */
          color: $fontColor3;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: $fontColor3;
        }

        &::placeholder {
          color: $fontColor3;
        }
      }
      .icon-ic_info_circle {
        position: absolute;
        top: 5px;
        right: -40px;
        color: $fontColor2;
        cursor: pointer;
        &:hover {
          color: $fontColor1;
          & + .notice-information {
            display: block;
          }
        }
      }
      .notice-information {
        display: none;
        position: absolute;
        width: 300px;
        top: 37px;
        right: -316px;
        color: $fontColor2;
        line-height: 32px;
        background: #ffffff;
        padding: 12px 16px;
        font-size: $fontSize6;
        z-index: 2;
        box-sizing: border-box;
        border-radius: 2px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
        border: solid 1px #dcdfe6;
      }
      .checked-patient-list {
        margin-top: 16px;
        padding: 4px 16px 4px 16px;
        font-size: $fontSize6;
        color: $fontColor1;
        max-height: 140px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          box-shadow: none;
          width: 8px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $fontColor4;
          border-radius: 4px;
        }
        li {
          position: relative;
          & + li {
            margin-top: 16px;
          }
        }
        div {
          display: flex;
          align-items: center;
        }
        .icon-ic_check_circle_fill {
          font-size: $fontSize5;
          color: $uiColor4;
          margin-right: 10px;
        }
        .patient-time {
          font-size: $fontSize7;
          padding-top: 8px;
          color: $fontColor3;
          padding-left: 26px;
        }
        .del-btn {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
        }
      }
    }
    .subject-text {
      margin-top: 24px;
      font-size: $fontSize7;
      color: $fontColor2;
      font-weight: 500;
      margin-bottom: -8px;
    }
    .text-section {
      position: relative;
      margin-top: 16px;

      textarea {
        margin-top: 16px;
        &.only-text {
          margin-top: 0;
        }
      }
    }
    .link-section {
      margin-top: 16px;
      position: relative;
    }
  }
}
.v--modal-overlay {
  background: transparent;
}
.invite-email-button {
  margin-left: 10px;
  padding: 5px 16px;
  border-radius: 4px;
  background: #f0734d;
  color: #ffffff;
}
.invite-email-cancel-button {
  line-height: 35px;
  padding: 0 16px;
  small {
    margin-left: 10px;
    opacity: 0.5;
  }
}
</style>
