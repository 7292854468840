<template>
  <div class="Home">
    <div class="report-wrapper" v-if="selectedDevice">
      <ul class="device-list">
        <li v-for="(device, index) in devices" :key="index">
          <button
            type="button"
            :class="device.id === selectedDevice.id ? 'on' : ''"
            @click="selectDevice(device)"
          >
            {{ device.printName }}
          </button>
        </li>
      </ul>
      <div class="sub-menu-list">
        <div
          class="separators-symbol"
          :class="selectedReport === selectReportType.TRAINING ? 'on' : ''"
        />
        <span
          class="sub-menu-list-item"
          :class="selectedReport === selectReportType.TRAINING ? 'on' : ''"
          @click="selectReport(selectReportType.TRAINING)"
        >
          {{ $t("page.patient.neofectHome.layout.trainingReport") }}
        </span>
        <div
          class="separators-symbol second"
          :class="selectedReport === selectReportType.EVALUATION ? 'on' : ''"
        />
        <span
          class="sub-menu-list-item"
          :class="selectedReport === selectReportType.EVALUATION ? 'on' : ''"
          @click="selectReport(selectReportType.EVALUATION)"
        >
          {{ $t("page.patient.neofectHome.layout.evaluation") }}
        </span>
      </div>
      <div
        class="search-bar"
        v-show="selectedReport === selectReportType.TRAINING"
      >
        <button class="all-period" @click="handleClickAllPeriod">
          {{ $t("page.patient.neofectHome.layout.allPeriod") }}
        </button>
        <div class="search-row">
          <ToggleButton
            class="search-row-item"
            @toggle="toggleIsRangeCompare"
            :checked="isRangeCompare"
          />
          <span class="search-row-item">{{
            $t("page.patient.neofectHome.layout.compare")
          }}</span>
          <date-range-picker
            ref="picker"
            :close-on-esc="true"
            :append-to-body="true"
            :auto-apply="isDateAutoApply"
            :ranges="false"
            v-model="dateRange"
            :max-date="yesterday"
            :show-dropdowns="true"
            @change-month="onChangeMonth"
            @toggle="calendarOpen"
            style="margin-right: 20px; text-align: center; width: 210px"
          >
            <template v-slot:input="picker">
              {{ dateRange.startDate | moment("YYYY.MM.DD") }} ~
              {{ dateRange.endDate | moment("YYYY.MM.DD") }}
            </template>
          </date-range-picker>
        </div>
        <transition name="fade">
          <div class="search-row" v-if="isRangeCompare">
            <button
              class="search-row-item search-button"
              @click="updateRangeDate"
            >
              {{ $t("page.patient.neofectHome.layout.previousPeriodButton") }}
            </button>
            <span class="search-row-item">{{
              $t("page.patient.neofectHome.layout.target")
            }}</span>
            <date-range-picker
              ref="picker2"
              :close-on-esc="true"
              :append-to-body="true"
              :auto-apply="false"
              :ranges="false"
              :max-date="yesterday"
              :date-format="dateFormat"
              v-model="toDateRange"
              @update="updateAutoRangeDate"
              style="margin-right: 20px; text-align: center; width: 210px"
            >
              <template v-slot:input="picker2">
                {{ toDateRange.startDate | moment("YYYY.MM.DD") }} ~
                {{ toDateRange.endDate | moment("YYYY.MM.DD") }}
              </template>
            </date-range-picker>
          </div>
        </transition>
      </div>
      <div>
        <div v-if="isBoard && selectedReport === selectReportType.TRAINING">
          <BoardTrainingReport
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
          />
          <TrainingTime
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
          />
          <!-- MissionLogs > Session Log -->
          <MissionLogs
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
          />
          <!-- BoardMovedDistance > Total Distance Moved -->
          <BoardMovedDistance
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
          />
          <!-- MotionFeatureRatio > Training Ratio -->
          <MotionFeatureRatio
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
          />
          <!-- BoardAromArea > Area AROM -->
          <BoardAromArea
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
          />
          <!-- BoardArom > Vertical / Horizontal AROM -->
          <BoardArom
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
          />
          <!-- BoardSmoothness > Smoothness of Reach -->
          <BoardSmoothness
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
          />
          <!-- BoardSimilarity > Shapre Similarity -->
          <BoardSimilarity
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
          />
        </div>
        <div v-if="isBoard && selectedReport === selectReportType.EVALUATION">
          <!-- BoardEvaluation > Evaluation Information -->
          <BoardEvaluation :device="selectedDevice"></BoardEvaluation>
        </div>
        <div v-if="isGloveOrKids && selectedReport === selectReportType.TRAINING">
          <div class="grid-section">
            <GloveTrainingReport
              :device="selectedDevice"
              :isRangeCompare="isRangeCompare"
              :dateRange="dateRange"
              :toDateRange="toDateRange"
            />
            <GloveSensorReport
              :device="selectedDevice"
              :isRangeCompare="isRangeCompare"
              :dateRange="dateRange"
              :toDateRange="toDateRange"
            />
          </div>
          <TrainingTime
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
          />
          <!-- MissionLogs > Session Log -->
          <MissionLogs
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
          />
          <!-- GloveMotionTypeRatio > Movement Ratio -->
          <GloveMotionTypeRatio
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
            :isKids="isKids"
          />
          <!-- MotionFeatureRatio > Training Ratio -->
          <MotionFeatureRatio
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
          />
          <!-- GloveAromTrend > Range of Motion -->
          <GloveAromTrend
            :device="selectedDevice"
            :isRangeCompare="isRangeCompare"
            :dateRange="dateRange"
            :toDateRange="toDateRange"
            :isKids="isKids"
          />
        </div>
        <div
          v-if="isGloveOrKids && selectedReport === selectReportType.EVALUATION"
        >
          <!-- GloveEvaluation > Evaluation Score -->
          <GloveEvaluation :device="selectedDevice"></GloveEvaluation>
          <!-- GloveAromTrend > Range of Motion -->
        </div>
      </div>
    </div>
    <div v-else class="no-device">No Device</div>
  </div>
</template>

<script>
import Select from "vue-select";
import TrainingTime from "./components/TrainingTime.vue";
import MissionLogs from "./components/MissionLogs.vue";
import MotionFeatureRatio from "./components/MotionFeatureRatio.vue";
import GloveMotionTypeRatio from "./components/GloveMotionTypeRatio.vue";
import GloveAromTrend from "./components/GloveAromTrend.vue";
import GloveEvaluation from "./components/GloveEvaluation.vue";
import BoardMovedDistance from "./components/BoardMovedDistance.vue";
import BoardArom from "./components/BoardArom.vue";
import BoardAromArea from "./components/BoardAromArea.vue";
import BoardSmoothness from "./components/BoardSmoothness.vue";
import BoardSimilarity from "./components/BoardSimilarity.vue";
import BoardEvaluation from "./components/BoardEvaluation.vue";
import ToggleButton from "../../../../components/ToggleSliderButton.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import BoardTrainingReport from "./components/BoardTrainingReport";
import GloveTrainingReport from "./components/GloveTrainingReport";
import GloveSensorReport from "./components/GloveSensorReport";
// 전체기간 api 추가.
import { getPatientTrainedWholeDates } from "@/api/report.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import {
  // findLast,
  isEmpty,
  forEach,
} from "lodash";

export default {
  name: "Home",
  mixins: [ErrorMixin],
  components: {
    Select,
    TrainingTime, //훈련시간
    MissionLogs, // 세션 수행 기록
    GloveMotionTypeRatio, //글로브  훈련 동작 비율
    MotionFeatureRatio, // 훈련 유형 비율
    GloveAromTrend, // 글로브 동작별 ROM 추이
    GloveEvaluation, // 글로브 평가 정보
    BoardMovedDistance, //보드 움직임 거리
    BoardAromArea, //면적 AROM
    BoardArom, //가로/세로 AROM
    BoardSmoothness, //부드러움
    BoardSimilarity, //궤적 유사도
    BoardEvaluation, // 글로브 평가 정보
    ToggleButton,
    DateRangePicker,
    BoardTrainingReport,
    GloveTrainingReport,
    GloveSensorReport,
  },
  data() {
    return {
      selectedDevice: null,
      selectedReport: "Training",
      selectReportType: Object.freeze({
        TRAINING: "Training",
        EVALUATION: "Evaluation",
      }),
      isRangeCompare: false,
      dateRange: {
        startDate: this.$moment().subtract(7, "d").format("YYYY.MM.DD"),
        endDate: this.$moment().subtract(1, "d").format("YYYY.MM.DD"),
      },
      toDateRange: {
        startDate: this.$moment().subtract(14, "d").format("YYYY.MM.DD"),
        endDate: this.$moment().subtract(8, "d").format("YYYY.MM.DD"),
      },
      oldToDateRange: {
        startDate: this.$moment().subtract(14, "d").format("YYYY.MM.DD"),
        endDate: this.$moment().subtract(8, "d").format("YYYY.MM.DD"),
      },
      isDateAutoApply: false,
      // 전체 훈련 기간 날짜
      trainedDays: [],
      calendar: {
        leftYear: null,
        leftMonth: null,
        rightYear: null,
        rightMonth: null,
      }, // 캘린더의 월
    };
  },
  filters: {
    dateFilter(val) {
      return this.$moment(val).format("YYYY.MM.DD");
    },
  },
  methods: {
    findTrainedCalendarPage(picker) {
      const trainedDayList = this.trainedDays.map((date) => ({
        year: new Date(date).getFullYear(),
        month: new Date(date).getMonth() + 1,
        day: new Date(date).getDate(),
      }));
      const leftCalendar = picker.$children[0].$el.getElementsByTagName("td");
      const rightCalendar = picker.$children[1].$el.getElementsByTagName("td");
      // left calendar
      forEach(leftCalendar, (left) => {
        trainedDayList.map((date) => {
          if (
            this.calendar.leftYear === date.year &&
            this.calendar.leftMonth === date.month
          ) {
            if (
              !left.className.includes("off") &&
              String(left.innerText) === String(date.day)
            ) {
              // left.classList.add("trained");
              left.innerHTML = `<span></span>${left.innerText}`;
            }
          }
        });
      });
      // right calendar
      forEach(rightCalendar, (right) => {
        trainedDayList.map((date) => {
          if (
            this.calendar.rightYear === date.year &&
            this.calendar.rightMonth === date.month
          ) {
            if (
              !right.className.includes("off") &&
              String(right.innerText) === String(date.day)
            ) {
              // right.classList.add("trained");
              right.innerHTML = `<span></span>${right.innerText}`;
            }
          }
        });
      });
    },
    // 캘린더 오픈
    calendarOpen(open) {
      if (!open) return;
      const picker = this.$refs.picker;

      this.$nextTick(() => {
        // 캘린더 오픈시 year, month 세팅.
        this.calendar = {
          leftYear: picker.$children[0].year,
          leftMonth: picker.$children[0].month,
          rightYear: picker.$children[1].year,
          rightMonth: picker.$children[1].month,
        };

        // 캘린더 오픈시 표시된 Left Calendar의  Month
        this.findTrainedCalendarPage(picker);
      });
    },
    // 캘린더 월 변경
    onChangeMonth() {
      const picker = this.$refs.picker;
      const leftCalendar = picker.$children[0].$el.getElementsByTagName("td");
      const rightCalendar = picker.$children[1].$el.getElementsByTagName("td");

      // 월 변경시 캘린더 훈련날짜 class remove
      forEach(leftCalendar, (left) => {
        if (!isEmpty(left.children)) left.removeChild(left.firstChild);
      });
      forEach(rightCalendar, (right) => {
        // right.classList.remove("trained");
        if (!isEmpty(right.children)) right.removeChild(right.firstChild);
      });

      // 월 변경시 year, month 세팅.
      this.$nextTick(() => {
        this.calendar = {
          leftYear: picker.$children[0].year,
          leftMonth: picker.$children[0].month,
          rightYear: picker.$children[1].year,
          rightMonth: picker.$children[1].month,
        };
        this.findTrainedCalendarPage(picker);
      });
    },
    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled =
          date >= this.$moment(this.dateRange.startDate) &&
          date < this.$moment(this.dateRange.endDate).add(1, "d");
      }
      return classes;
    },
    updateAutoRangeDate(newVal) {
      const toPeriod = this.$moment(this.toDateRange.endDate).diff(
        this.$moment(this.toDateRange.startDate),
        "days"
      );
      const period = this.$moment(this.dateRange.endDate).diff(
        this.$moment(this.dateRange.startDate),
        "days"
      );

      if (period !== toPeriod) {
        this.toDateRange.startDate = this.oldToDateRange.startDate;
        this.toDateRange.endDate = this.oldToDateRange.endDate;
        alert("out of range!");
      }
    },
    updateRangeDate() {
      const period = this.$moment(this.dateRange.endDate).diff(
        this.$moment(this.dateRange.startDate),
        "days"
      );

      this.toDateRange.endDate = this.$moment(
        this.dateRange.startDate
      ).subtract(1, "d");
      this.toDateRange.startDate = this.$moment(
        this.toDateRange.endDate
      ).subtract(period, "d");
    },
    toggleIsRangeCompare(isCheck) {
      this.isRangeCompare = isCheck;
    },
    selectDevice(device) {
      this.selectedDevice = device;
      this.selectedReport = this.selectReportType.TRAINING;
    },
    selectReport(report) {
      this.selectedReport = report;
    },
    // 전체기간 버튼 액션
    async handleClickAllPeriod() {
      console.log("all period");
      if (!this.patient || !this.selectedDevice) return;

      if (isEmpty(this.trainedDays)) {
        alert("There are no days of training.");
        return;
      }
      // 전체 기간. range 변경 21.02.10
      this.dateRange = {
        startDate: this.$moment(this.trainedDays[0]),
        // endDate: this.$moment(findLast(this.trainedDays))
        endDate: this.$moment(new Date()),
        // .add(23, "hours")
        // .add(59, "minutes")
        // .add(59, "seconds"),
      };

      this.updateRangeDate();
    },
  },
  watch: {
    devices(newVal, oldVal) {
      this.selectedDevice = newVal[0];
    },
    dateRange(newVal, oldVal) {
      const period = this.$moment(newVal.endDate).diff(
        this.$moment(newVal.startDate),
        "days"
      );

      this.toDateRange.endDate = this.$moment(newVal.startDate).subtract(
        1,
        "d"
      );
      this.toDateRange.startDate = this.$moment(
        this.toDateRange.endDate
      ).subtract(period, "d");
    },
    toDateRange(newVal, oldVal) {
      this.oldToDateRange.startDate = oldVal.startDate;
      this.oldToDateRange.endDate = oldVal.endDate;
      // const newPeriod = this.$moment(newVal.endDate).diff(this.$moment(newVal.startDate),'days');
      // const oldPeriod = this.$moment(oldVal.endDate).diff(this.$moment(oldVal.startDate),'days');
      //
      // if( newPeriod !== oldPeriod) {
      //   // this.toDateRange = oldVal;
      //   alert('out of range!');
      // }
    },
    selectedDevice(newVal) {
      if (newVal) {
        getPatientTrainedWholeDates(this.patient.id, newVal.id)
          .then((res) => {
            this.trainedDays = res.dates;
          })
          .catch((err) => {
            this.handleError(
              err,
              "Report > Get Patient Trained Whole Dates API"
            );
          });
      }
    },
  },
  computed: {
    isBoard: function () {
      return this.selectedDevice.isBoard;
    },
    isKids: function () {
      return this.selectedDevice.isKids;
    },
    isGloveOrKids: function () {
      return this.selectedDevice.isGlove || this.selectedDevice.isKids;
    },
    patient: function () {
      return this.$store.getters.selectedPatient;
    },
    devices: function () {
      return this.$store.getters.selectedPatientDevice;
    },
    yesterday: function () {
      return this.$moment().subtract(1, "d").format("YYYY-MM-DD");
    },
  },
  created() {
    if (this.devices) {
      this.selectedDevice = this.devices[0];
    }
  },
};
</script>

<style lang="scss" scoped>
.Home {
  padding: 40px 24px;
  /* device 리스트 선택 버튼 */
  .report-wrapper {
    width: 100%;
    max-width: 1000px;
    &::v-deep .device-list {
      width: 100%;
      display: flex;
      &::after {
        content: "";
        clear: both;
        display: block;
      }
      li {
        &:first-of-type {
          button {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
        }
        &:last-of-type {
          button {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
        & + li {
          button {
            border-left: 0 none;
          }
        }
        button {
          padding: 10px 22px;
          border: 1px solid $borderColor1;
          box-sizing: border-box;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: normal;
          &.on {
            color: #ffffff;
            background: $mainColor1;
            border-color: $mainColor1;
          }
        }
      }
    }
    /* report sections */
    &::v-deep .sub-menu-list {
      display: flex;
      padding-top: 40px;
      .sub-menu-list-item {
        padding-left: 8px;
        color: #606266;
        cursor: pointer;
      }
      .sub-menu-list-item.on {
        color: black;
      }
      .separators-symbol {
        border-left: 3px solid;
        color: #ebecf0;
      }
      .separators-symbol.on {
        border-color: #f0734d;
      }
      .second {
        margin-left: 17px;
      }
    }
    &::v-deep .search-bar {
      display: grid;
      justify-items: flex-end;
      .fade-leave-active {
        transition: opacity 0.3s;
      }
      .fade-enter-active {
        transition: opacity 0.5s;
      }
      .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
      }

      .all-period {
        padding: 6px 8px;
        border: 1px solid #d0d4dc;
        box-sizing: border-box;
        border-radius: 4px;
        color: $fontColor2;
        font-size: $fontSize6;
      }

      .search-row {
        display: flex;
        align-items: center;
        padding: 8px 0 5px;

        .search-row-item {
          margin-right: 10px;
        }
        .search-button {
          border: 1px solid gray;
          color: gray;
          padding: 3px 14px;
          text-align: center;
          text-decoration: none;
          border-radius: 3px;
          display: inline-block;
          font-size: 14px;
        }
        .search-button:hover {
          background-color: #f0734d;
          border: 1px solid white;
          color: white;
        }
      }
    }
    &::v-deep .grid-section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
    }
    &::v-deep .report-section {
      margin-top: 32px;
      width: 100%;
      background: #ffffff;
      h1 {
        color: $fontColor1;
        font-size: $fontSize5;
        line-height: 21px;
        font-weight: 500;
      }
      .report-contents {
        margin-top: 16px;
        border: 1px solid $borderColor1;
        border-radius: 8px;
        padding: 24px;
        .arrow {
          display: inline-block;
          vertical-align: middle;
          &.up {
            border: 4px solid blue;
            border-color: transparent transparent blue transparent;
            margin-top: -6px;
          }
          &.down {
            border: 4px solid red;
            border-color: red transparent transparent;
            margin-top: 4px;
          }
        }
      }
      /* board training report */
      &.board-training-report {
      }
      /* glove training report */
      &.glove-training-report {
      }
      /* glove sensor report */
      &.glove-sensor-report {
      }

      /* session log */
      &.session-log {
        $decoColor1: #d0d4dc;
        width: calc(50% - 9px);
        float: left;

        .report-contents {
          padding-left: 46px;
          position: relative;
          height: 266px;
          overflow-y: auto;
          h2 {
            color: $fontColor3;
            font-size: $fontSize7;
            line-height: 16px;
            position: relative;
            &::before {
              content: "";
              display: block;
              width: 9px;
              height: 9px;
              background: $decoColor1;
              border-radius: 50%;
              position: absolute;
              top: 3px;
              left: -26px;
            }
          }
          ul {
            padding: 16px 0;
            position: relative;
            &::before {
              content: "";
              width: 1px;
              height: calc(100% + 8px);
              display: block;
              background: $decoColor1;
              position: absolute;
              top: -4px;
              left: -22px;
            }
            li {
              display: flex;
              align-items: center;
              & + li {
                margin-top: 16px;
              }
            }
            .title {
              font-size: $fontSize6;
              color: $fontColor1;
            }
            .title.target {
              color: $fontColor3;
            }
            .score {
              margin-left: auto;
              .icon-ic_star_fill {
                font-size: 14px;
                color: $fontColor5;
                display: inline-block;
                &.active {
                  color: #faac00;
                }
              }
            }
          }
        }
      }
      /* movement ratio */
      &.movement-ratio {
        margin-left: 18px;
        width: calc(50% - 9px);
        float: left;

        .report-contents {
          padding: 17px 12px;
          ul {
            overflow: hidden;
            li {
              display: flex;
              float: left;
              width: 50%;
              color: $fontColor1;
              font-size: $fontSize6;
              line-height: 19px;
              margin: 7px 0;
              .label {
                width: 63%;
                display: inline-block;
              }
              .value {
                width: 10%;
                font-size: 13px;
                padding-right: 8px;
                display: inline-block;
              }
              .in-decrease {
                text-align: center;
                width: 25%;
                font-size: 10px;
                &.increase {
                  color: blue;
                }
                &.decrease {
                  color: red;
                }
              }
            }
          }
        }
      }
      /* range of motion */
      &.training-ratio {
        margin-left: 18px;
        margin-top: 24px;
        margin-bottom: 24px;
        width: calc(50% - 9px);
        float: left;
        .report-list {
          display: flex;
          .value {
            width: 10%;
            font-size: 13px;
            padding-right: 15px;
            display: inline-block;
          }

          .in-decrease {
            text-align: center;
            width: 25%;
            font-size: 10px;

            &.increase {
              color: blue;
            }

            &.decrease {
              color: red;
            }
          }
        }
      }
      /* total distance moved */
      &.total-distance-moved {
        margin-left: 18px;
        width: calc(50% - 9px);
        float: left;
        .report-contents {
          display: flex;
          .report-info {
            width: 280px;
            flex-shrink: 0;

            .label {
              display: block;
              margin-top: 12px;
              color: $fontColor1;
              font-size: $fontSize6;
              line-height: 19px;
            }
            .value {
              color: $fontColor1;
              font-weight: 500;
              font-size: $fontSize2;
            }
            .report-value {
              display: flex;
              .in-decrease {
                text-align: center;
                width: 25%;
                font-size: 10px;
                align-self: flex-end;

                &.increase {
                  color: blue;
                }

                &.decrease {
                  color: red;
                }
              }
            }
          }
          .graph {
            width: 136px;
            height: 85px;
          }
        }
      }
      &.range-of-motion {
        clear: both;
      }
      /* evaluation score */
      &.evaluation-score {
        .report-contents {
          overflow: hidden;
          .select {
            display: flex;
            .select-1 {
              width: 220px;
              position: relative;
              &::before {
                content: "";
                width: 23px;
                height: 6px;
                border-radius: 3px;
                background: #477eea;
                display: block;
                position: absolute;
                top: 15px;
                left: 16px;
              }
            }
            .select-2 {
              margin-left: 16px;
              width: 220px;
              position: relative;
              &::before {
                content: "";
                width: 23px;
                height: 6px;
                border-radius: 3px;
                background: #e1414a;
                display: block;
                position: absolute;
                top: 15px;
                left: 16px;
              }
            }
          }
          .contents {
            margin-top: 32px;
            display: flex;
            .graph {
              width: 100%;
              height: 300px;
              float: left;
            }
            .table {
              width: 450px;
              flex-shrink: 0;
              float: left;
              padding: 32px 0 0 24px;
              table {
                border-collapse: collapse;
                th,
                td {
                  border-top: 1px solid $borderColor1;
                  border-bottom: 1px solid $borderColor1;
                  padding: 9px;
                  font-size: $fontSize6;
                  color: $fontColor1;
                  min-width: 70px;
                  text-align: left;
                }
              }
            }
          }
        }
      }
      /* area arom */
      &.area-arom {
        width: calc(50% - 9px);
        margin-top: 0;
        float: left;
      }
      /* vertical horizontal arom */
      &.vertical-horizontal-arom {
        margin-left: 18px;
        width: calc(50% - 9px);
        float: left;
        margin-top: 0;
        margin-bottom: 24px;
        .report-contents {
          height: 520px;
          box-sizing: border-box;

          .report-wrapper {
            display: flex;
            width: 100%;
          }
          .report-btns {
            margin-bottom: 24px;
            letter-spacing: -4px;
            button {
              font-size: $fontSize6;
              padding: 6px 16px;
              color: $fontColor2;
              border: 1px solid $borderColor1;
              &:first-of-type {
                border-radius: 4px 0 0 4px;
              }
              &:last-of-type {
                border-radius: 0 4px 4px 0;
              }
              &.on {
                background: #477eea;
                color: #ffffff;
                border-color: #477eea;
              }
            }
          }
          .report-info {
            width: 185px;
            flex-shrink: 0;
            .report-value {
              display: flex;

              .in-decrease {
                text-align: center;
                width: 25%;
                font-size: 10px;
                align-self: center;
                padding: 0px 5px;
                display: flex;
                &.increase {
                  color: blue;
                }

                &.decrease {
                  color: red;
                }
                .arrow-text {
                }
              }
            }
            .value {
              color: $fontColor1;
              font-weight: 500;
              font-size: $fontSize2;
              display: flex;
              align-items: center;
              &.up {
                margin-top: 24px;
              }
              .icon-ic_ascending_sort {
                display: inline-block;
                margin-left: 10px;
                color: #477eea;
              }
              .icon-ic_descending_sort {
                display: inline-block;
                margin-left: 10px;
                color: #e1414a;
              }
            }
            .label {
              display: block;
              margin-top: 12px;
              color: $fontColor1;
              font-size: $fontSize6;
              line-height: 19px;
            }
          }
          .graph {
            margin-left: 40px;
            width: 240px;
            height: 140px;
          }
        }
      }
      /* smoothness of reach */
      &.smoothness-of-reach {
        width: calc(50% - 9px);
        margin-top: 0;
        float: left;
        .report-contents {
          ul {
            display: flex;
            li {
              &:first-of-type {
                flex-shrink: 0;
                width: 160px;
              }
              .value {
                color: $fontColor1;
                font-weight: 500;
                font-size: $fontSize2;
              }
              .icon-ic_ascending_sort {
                color: #477eea;
              }
              .icon-ic_descending_sort {
                color: #e1414a;
              }
              .label {
                display: block;
                margin-top: 12px;
                color: $fontColor1;
                font-size: $fontSize6;
                line-height: 19px;
              }
            }
          }
        }
      }

      /* shapre similarity */
      &.shapre-similarity {
        margin-left: 18px;
        width: calc(50% - 9px);
        float: left;
        margin-top: 0;
        margin-bottom: 24px;
        .report-contents {
          ul {
            display: flex;
            li {
              &:first-of-type {
                flex-shrink: 0;
                width: 160px;
              }
              .value {
                color: $fontColor1;
                font-weight: 500;
                font-size: $fontSize2;
              }
              .icon-ic_ascending_sort {
                color: #477eea;
              }
              .icon-ic_descending_sort {
                color: #e1414a;
              }
              .label {
                display: block;
                margin-top: 12px;
                color: $fontColor1;
                font-size: $fontSize6;
                line-height: 19px;
              }
            }
          }
        }
      }
      /* evaluation information */
      &.evaluation-information {
        clear: both;
        .select {
          margin-top: 16px;
          width: 190px;
          &:focus {
            outline: none;
          }
          select:focus {
            outline: none;
          }
        }
      }
      /* free exploration */
      &.free-exploration {
        width: calc(50% - 9px);
        float: left;
        .report-contents {
          display: flex;
          height: 356px;
          box-sizing: border-box;
          .report-info {
            width: 105px;
            flex-shrink: 0;
            .value {
              color: $fontColor1;
              font-weight: 500;
              font-size: $fontSize2;
            }
            .label {
              display: block;
              margin-top: 12px;
              color: $fontColor1;
              font-size: $fontSize6;
              line-height: 19px;
            }
          }
          .table {
            width: 400px;
            margin-left: 0px;
            table {
              table-layout: fixed;
              width: 100%;
              margin-top: 0px;
              border-collapse: collapse;
              text-align: left;
              thead {
                th {
                  border-top: 0 none;
                  font-weight: 500;
                }
              }
              th {
                border-top: 1px solid $borderColor1;
                border-bottom: 1px solid $borderColor1;
                padding: 9px;
                font-size: $fontSize6;
                color: $fontColor1;
                min-width: 50px;
              }
              td {
                border-top: 1px solid $borderColor1;
                border-bottom: 1px solid $borderColor1;
                padding: 9px;
                font-size: $fontSize6;
                color: $fontColor1;
                min-width: 60px;
              }
            }
          }
        }
      }
      /* point to point reaching */
      &.point-to-point-reaching {
        width: calc(50% - 9px);
        margin-left: 18px;
        margin-bottom: 24px;
        float: left;
        .report-contents {
          display: flex;
          height: 356px;
          box-sizing: border-box;
          .report-info {
            width: 105px;
            flex-shrink: 0;
            .value {
              color: $fontColor1;
              font-weight: 500;
              font-size: $fontSize2;
            }
            .label {
              display: block;
              margin-top: 12px;
              color: $fontColor1;
              font-size: $fontSize6;
              line-height: 19px;
            }
          }
          .table {
            width: 300px;
            margin-left: 36px;
            .graph {
              height: 150px;
              border-radius: 5px;
            }
            table {
              width: 100%;
              margin-top: 24px;
              border-collapse: collapse;
              text-align: left;
              thead {
                th {
                  border-top: 0 none;
                  font-weight: 500;
                }
              }
              th,
              td {
                border-top: 1px solid $borderColor1;
                border-bottom: 1px solid $borderColor1;
                padding: 9px;
                font-size: $fontSize6;
                color: $fontColor1;
              }
            }
          }
        }
      }
      /* shape drawing */
      &.shape-drawing {
        width: calc(50% - 9px);
        margin-left: 18px;
        margin-bottom: 24px;
        float: left;
        .report-contents {
          display: flex;
          .report-info {
            width: 105px;
            flex-shrink: 0;
            margin-right: 197px;
            .value {
              color: $fontColor1;
              font-weight: 500;
              font-size: $fontSize2;
            }
            .label {
              display: block;
              margin-top: 12px;
              color: $fontColor1;
              font-size: $fontSize6;
              line-height: 19px;
            }
          }
        }
        .table {
          width: 300px;
          &:last-of-type {
            margin-left: 48px;
          }
          table {
            width: 100%;
            margin-top: 24px;
            border-collapse: collapse;
            text-align: left;
            thead {
              th {
                border-top: 0 none;
                font-weight: 500;
              }
            }
            th,
            td {
              border-top: 1px solid $borderColor1;
              border-bottom: 1px solid $borderColor1;
              padding: 9px;
              font-size: $fontSize6;
              color: $fontColor1;
            }
          }
        }
      }
    }
  }
}
.no-device {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
