<template>
  <section class="Session">
    <h1>{{ title }}</h1>
    <div class="device-select">
      <label for="#deviceSelect">{{ $t("page.patient.session.detail.selectDevice")  }}</label>
      <Select
        v-model="selectedDevice"
        :options="selectedPatientDevice"
        label="printName"
        :components="{ OpenIndicator, Deselect }"
        :disabled="sessionPopup.edit"
        id="deviceSelect"
      />
    </div>
    <div class="contents">
      <div class="game-list">
        <Select
          v-model="selectedMotionType"
          :options="motionTypes"
          label="name"
          :components="{ OpenIndicator, Deselect }"
          id="deviceSelect"
        />
        <ul class="custom-scrollbar">
          <li v-for="(game, index) of sortedGames" :key="index">
            <div class="game-image">
              <img :src="game.thumbnail" :alt="game.title" />
            </div>
            <div class="game-info">
              <div>
                <span class="game-feature">
                  <span v-for="(feature, index) of game.features" :key="index">{{ feature }}</span>
                </span>
                <span class="game-title">{{ game.title }}</span>
                <span v-if="selectedDevice.name !== 'SMART_BOARD'" class="game-motion">{{ displayMotionFunc(game.motions) }}</span>
              </div>
            </div>
            <div
              class="game-select secondary-type-btns small-btns"
              :class="selectedGames.length > 7 ? 'disabled' : ''"
            >
              <button type="button" @click="selectGame(game)">{{ $t("page.patient.session.detail.select")  }}</button>
            </div>
          </li>
        </ul>
      </div>
      <div class="selected-games-list custom-scrollbar">
        <div class="auto-select primary-type-btns small-btns">
          <button type="button" @click="autoCreateGames">{{ $t("page.patient.session.detail.autoCreateButton")  }}</button>
        </div>
        <div class="game-list-wrapper">
          <ul class="selected-games">
            <li v-for="(game, index) of selectedGames" :key="index">
              <div class="game-info">
                <span class="game-image">
                  <img :src="game.thumbnail" :alt="game.title" />
                </span>
                <span class="game-title">{{ game.title }}</span>
                <span class="game-delete">
                  <button type="button">
                    <span class="icon-ic_delete_circle" @click="deleteGame(game)">
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </span>
                  </button>
                </span>
              </div>
              <div class="game-level">
                <button
                  type="button"
                  @click="minusLevel(index, game.min_level)"
                  :class="game.level === game.min_level ? 'disabled' : ''"
                >
                  <span class="icon-ic_minus ic-icon">
                    <span class="path1"></span>
                  </span>
                </button>
                {{ `Level ${game.level}` }}
                <button
                  type="button"
                  @click="plusLevel(index, game.max_level)"
                  :class="game.level === game.max_level ? 'disabled' : ''"
                >
                  <span class="icon-ic_add ic-icon">
                    <span class="path1" />
                    <span class="path2" />
                  </span>
                </button>
              </div>
            </li>
          </ul>
          <ul class="empty-list">
            <li v-for="(empty, index) of emptyList" :key="index">
              <span class="icon-ic_add">
                <span class="path1"></span>
                <span class="path2"></span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 서밋버튼 -->
    <div class="popup-submit-btns" :class="selectedGames.length < 1 ? 'disabled' : 'selectedGames'">
      <button type="button" class="accept-btn" @click.once="handleSubmit">{{ $t("page.patient.session.saveButton") }}</button>
      <button type="button" @click="handleClose">{{ $t("page.patient.session.cancelButton") }}</button>
    </div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button type="button" class="close-btn icon-btn" @click="handleClose">
        <span class="icon-ic_delete">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import Select from "vue-select";
import { fetchGetGames, fetchGetMotionTypes } from "@/api/patient";
import { fetchCreateGamesListByAlgorithm } from "@/api/home";
import { mapGetters } from "vuex";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";

const displayMotion = Object.freeze({
  // forearm
  FOREARM : "Forearm",
  FOREARM_PRO : "Forearm",
  FOREARM_SUP : "Forearm",
  FOREARM_SUP_PRONATION : "Forearm",

  // finger
  FINGER : "Finger",
  FINGER_EXT : "Finger",
  FINGER_FLEX_EXT : "Finger",

  // wristA
  WRIST_FLEX : "Wrist Flex./Ext.",
  WRIST_FLEX_AG : "Wrist Flex./Ext.",
  WRIST_FLEX_EG : "Wrist Flex./Ext.",
  WRIST_FLEX_EXT : "Wrist Flex./Ext.",
  WRIST_FLEX_EXT_AG : "Wrist Flex./Ext.",
  WRIST_FLEX_EXT_EG : "Wrist Flex./Ext.",

  // wristB
  WRIST_DEVIATION : "Wrist R.D./U.D.",
  WRIST_DEVIATION_EG : "Wrist R.D./U.D.",
  WRIST_DEVIATION_AG : "Wrist R.D./U.D.",
  WRIST_RADIAL : "Wrist R.D./U.D.",
  WRIST_RADIAL_AG : "Wrist R.D./U.D.",
  WRIST_RADIAL_EG : "Wrist R.D./U.D."

})

export default {
  name: "Session",
  components: {
    Select
  },
  mixins: [SelectMixin, ErrorMixin],
  props: {
    sessionPopup: {
      selectedDate: Date,
      // 수정시 받아오는 값
      edit: Boolean,
      division: String,
      side: String,
      games: Array,
      title: String
    },
    closeSessionPopup: Function,
    addSession: Function,
    editSession: Function
  },
  data() {
    return {
      selectedDevice: "",
      selectedMotionType: { name: this.$t("page.patient.session.detail.defaultMotion"), value: "ALL_MOVEMENT" },
      selectedGames: [], // 선택된 게임
      gameList: [], // 받아온 게임리스트
      emptyList: ["", "", "", "", "", "", "", ""], // emptylist 출력용
      motionTypes: []
    };
  },
  computed: {
    ...mapGetters({
      selectedPatientDevice: "patient/selectedPatientDevice",
      selectedPatient: "patient/selectedPatient",
      lang: "lang/language"
    }),
    title() {
      if (this.lang==='ko') {
        return `${this.$moment(this.sessionPopup.selectedDate).format("MMMM D, YYYY")} ${this.$t("page.patient.session.detail.title")} `;
      }
      return `${this.$t("page.patient.session.detail.title")} ${this.$moment(this.sessionPopup.selectedDate).format("MMMM D, YYYY")}`;
    },
    sortedGames() {
      return this.gameList.filter(game => {
        if (this.selectedMotionType.value === "ALL_MOVEMENT") {
          return game;
        } else if (this.selectedMotionType.value === game.motionTypes[0]) {
          return game;
        }
      });
    }
  },
  watch: {
    lang: function(newVal) {
      this.motionTypes[0].name = this.$t("page.patient.session.detail.defaultMotion");
      if (this.selectedMotionType.value==='ALL_MOVEMENT') {
        this.selectedMotionType.name = this.$t("page.patient.session.detail.defaultMotion");
      }
    },
    selectedDevice: async function(newVal, oldVal) {
      try {
        const device = newVal.name;
        const deviceSide = newVal.side;
        const result = await fetchGetGames(device, deviceSide);
        if (result) {
          this.gameList = result;
        }
      } catch (error) {
        this.handleError(error, "CreateSession > Get Games API");
      }
      try {
        const result = await fetchGetMotionTypes(
          this.selectedPatientDevice[0].name
        );
        if (result) {
          this.motionTypes = [
            { name: this.$t("page.patient.session.detail.defaultMotion"), value: "ALL_MOVEMENT" },
            ...result
          ];
        }
      } catch (error) {
        this.handleError(error, "CreateSession > Get Motion Types API");
      }
      if (oldVal) {
        this.selectedGames = [];
      }
    }
  },
  methods: {
    displayMotionFunc(motions) {
      let resMotion = "";
      let isComma = false;

      motions.forEach(item => {
        if (isComma) {
          resMotion = resMotion.concat(", ");
        }
        resMotion = resMotion.concat(displayMotion[item]);
        isComma = true;
      })

      return resMotion;
    },
    handleSubmit() {
      if (this.selectedGames.length < 1) {
        return;
      }
      const newSession = {
        division: this.selectedDevice.name,
        side: this.selectedDevice.side,
        date: this.$moment(this.sessionPopup.selectedDate).format("YYYY-MM-DD"),
        games: this.selectedGames,
        // list 출력용 title
        title: `${this.selectedDevice.printName} - ${this.selectedGames.length} ${this.$t("page.patient.session.rightList.smartRehab.description")}`
      };
      if (this.sessionPopup.edit) {
        this.editSession(newSession);
      } else {
        this.addSession(newSession);
      }
      this.handleClose();
    },
    handleClose() {
      this.closeSessionPopup();
    },
    async autoCreateGames() {
      try {
        const device = this.selectedDevice.name;
        const side = this.selectedDevice.side;
        const patientId = this.selectedPatient.id;
        const games = this.selectedGames.map(game => {
          game.contentId = game.id;
          return game;
        });
        const result = await fetchCreateGamesListByAlgorithm(
          device,
          side,
          patientId,
          games
        );
        if (result) {
          console.log(result);
          const getGames = result.map(game => {
            const newSet = {
              // api통신을 위한 데이터형식을 맞춰줌
              id: game.contentId,
              level: game.level,
              title: game.title,
              min_level: game.minLevel,
              max_level: game.maxLevel,
              thumbnail: game.thumbnail
            };
            return newSet;
          });
          this.selectedGames = getGames;
        }
      } catch (error) {
        this.handleError(error, "CreateSession > Games List By Algorithm API");
      }
    },
    selectGame(game) {
      // 선택한 게임을 리스트에 추가
      if (this.selectedGames.length > 7) {
        return;
      }
      const newSet = {
        // api통신을 위한 데이터형식을 맞춰줌
        id: game.contentId,
        level: 1,
        title: game.title,
        min_level: game.minLevel,
        max_level: game.maxLevel,
        thumbnail: game.thumbnail
      };
      this.selectedGames.push(newSet);
    },
    deleteGame(game) {
      // 선택한 게임을 리스트에서 삭제
      this.selectedGames = this.selectedGames.filter(selected => {
        if (selected !== game) {
          return selected;
        }
      });
    },
    plusLevel(index, maxLevel) {
      // 레벨 올리기
      if (this.selectedGames[index].level === maxLevel) {
        return;
      }
      this.selectedGames[index].level += 1;
    },
    minusLevel(index, minLevel) {
      // 레벨 낮추기
      if (this.selectedGames[index].level === minLevel) {
        return;
      }
      this.selectedGames[index].level -= 1;
    }
  },
  created() {
    // 초기화면의 디바이스 세팅
    if (this.sessionPopup.edit) {
      this.selectedDevice = {
        name: this.sessionPopup.division,
        side: this.sessionPopup.side,
        printName: this.sessionPopup.title.split(" - ")[0]
      };
      this.selectedGames = [...this.sessionPopup.games];
    } else {
      this.selectedDevice = this.selectedPatientDevice[0];
    }
  }
};
</script>

<style lang="scss" scoped>
.Session {
  position: fixed;
  width: 100%;
  max-width: 960px;
  background: #ffffff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $fontColor1;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  overflow: hidden;
  z-index: 1;
  h1 {
    padding: 50px 32px;
    font-size: $fontSize2;
    font-weight: 500;
    border-bottom: 1px solid $borderColor1;
  }
  .device-select {
    width: 240px;
    position: absolute;
    top: 45px;
    right: 23px;
    label {
      color: $fontColor2;
      font-size: $fontSize7;
    }
    .v-select {
      margin-top: 8px;
    }
  }
  .contents {
    display: flex;
    height: 390px;
    .game-list {
      flex-shrink: 0;
      width: 400px;
      padding: 24px 32px;
      box-sizing: border-box;
      border-right: 1px solid $borderColor1;
      .vdp-datepicker__calendar {
        margin-top: 23px;
        border: 0 none;
        padding: 0;
        .cell {
          &.day {
            height: 30px;
            width: 30px;
            line-height: 30px;
            margin: 8.9px;
          }
          &.selected {
            &:hover {
              background: none;
            }
            &::before {
              background: none;
            }
          }
          &.today {
            border-radius: 50%;
            color: #ffffff;
            background: $mainColor1;
            &::before {
              background: none;
            }
          }
        }
      }
      ul {
        margin-top: 32px;
        width: calc(100% + 32px);
        padding-right: 25px;
        height: calc(100% - 32px - 35px);
        box-sizing: border-box;
        overflow-y: auto;
        li {
          display: flex;
          align-items: center;
          padding: 15px 0;
          &:first-of-type {
            padding-top: 0;
          }
          & + li {
            border-top: 1px solid $borderColor1;
          }
          .game-image {
            width: 76px;
            height: 49px;
            flex-shrink: 0;
            overflow: hidden;
            border-radius: 4px;
            position: relative;
            img {
              width: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .game-info {
            margin-left: 16px;
            padding-right: 16px;
            width: 145px;
            position: relative;
            span {
              width: 100%;
              &.game-title {
                display: block;
                color: $fontColor1;
                font-size: $fontSize6;
                box-sizing: border-box;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: normal;
              }
              &.game-feature {
                display: block;
                margin-top: 4px;
                color: $fontColor6;
                font-size: $fontSize7;
                box-sizing: border-box;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: normal;
                span {
                  & + span {
                    &::before {
                      content: "/";
                      padding: 0 4px;
                    }
                  }
                }
              }
              &.game-motion {
                display: block;
                margin-top: 4px;
                color: $fontColor2;
                font-size: $fontSize8;
                box-sizing: border-box;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: normal;
                -webkit-text-size-adjust: none;
                span {
                  & + span {
                    &::before {
                      content: "/";
                      padding: 0 4px;
                    }
                  }
                }
              }
            }
          }
          .game-select {
            margin-left: auto;
          }
        }
      }
    }
    .selected-games-list {
      padding: 24px 32px;
      width: 560px;
      height: 100%;
      position: relative;
      overflow-y: auto;
      box-sizing: border-box;
      .auto-select {
        display: flex;
        button {
          margin-left: auto;
        }
      }
      .game-list-wrapper {
        position: relative;
        margin-top: 16px;
        .empty-list {
          li {
            width: calc((100% - 32px) / 3);
            height: 166px;
            float: left;
            border: 1px dashed $borderColor1;
            margin-right: 16px;
            position: relative;
            box-sizing: border-box;
            border-radius: 4px;
            &:nth-of-type(3n) {
              margin-right: 0;
            }
            &:nth-child(n + 4) {
              margin-top: 16px;
            }
            .icon-ic_add {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: $fontColor3;
            }
          }
        }
        .selected-games {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          li {
            width: calc((100% - 32px) / 3);
            height: 166px;
            float: left;
            margin-right: 16px;
            position: relative;
            box-sizing: border-box;
            position: relative;
            z-index: 1;
            border: 1px solid $borderColor1;
            border-radius: 4px;
            overflow: hidden;
            &:nth-of-type(3n) {
              margin-right: 0;
            }
            &:nth-child(n + 4) {
              margin-top: 16px;
            }
            .game-info {
              width: 100%;
              height: 110px;
              position: relative;
              overflow: hidden;
              .game-image {
                display: block;
                height: 100%;
                img {
                  width: 100%;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
                &::after {
                  content: "";
                  width: 100%;
                  height: 100%;
                  display: block;
                  box-shadow: 0 -50px 30px rgba(0, 0, 0, 0.7) inset;
                  position: relative;
                }
              }
              .game-title {
                color: #ffffff;
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 12px 8px;
                box-sizing: border-box;
                font-size: $fontSize6;
              }
              .game-delete {
                position: absolute;
                top: 6px;
                right: 6px;
                .icon-ic_delete_circle {
                  color: #ffffff;
                  .path2,
                  .path3 {
                    color: $fontColor1;
                  }
                }
              }
            }
            .game-level {
              padding: 16px 12px;
              display: flex;
              align-items: center;
              font-size: $fontSize6;
              color: $fontColor1;
              justify-content: space-between;
              button {
                padding: 3px;
                border: 1px solid $borderColor1;
                color: $fontColor3;
                border-radius: 3px;
                .ic-icon {
                  font-size: $fontSize5;
                }
                &.disabled {
                  background: $fontColor5;
                  color: $disabledColor;
                  border-color: rgba(255, 255, 255, 0);
                  cursor: default;
                  &:focus,
                  &:hover,
                  &:active {
                    outline: none;
                    background: $fontColor5;
                    border-color: rgba(255, 255, 255, 0);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
