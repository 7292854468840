var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "App" } },
    [
      _c("router-view", { ref: "appView" }),
      _vm._v(" "),
      _vm.screen_view
        ? _c("ScreenSharing", {
            attrs: {
              "session-id": _vm.screen_sessionId,
              "api-key": _vm.screen_apiKey,
              token: _vm.screen_token,
              "event-id": _vm.screen_eventId
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.video_view
        ? _c("VideoCall", {
            attrs: {
              "session-id": _vm.video_sessionId,
              "api-key": _vm.video_apiKey,
              token: _vm.video_token,
              "event-id": _vm.video_eventId
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("modals-container")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }