<template>
  <section class="ManagementPatient">
    <div class="management-top">
      <div class="management-search">
        <div class="search-select">
          <Select
            v-model="selectedSortingType"
            :options="sortingOption"
            label="label"
            placeholder
            :components="{ OpenIndicator, Deselect }"
          />
        </div>
        <div class="search">
          <span class="icon-ic_search" @click="searchPatients">
            <span class="path1" />
            <span class="path2" />
          </span>
          <input
            v-model="searchText"
            type="text"
            @keypress.enter="searchPatients"
          >
        </div>
      </div>
    </div>
    <table>
      <colgroup>
        <col width="60px">
        <col width="180px">
        <col width="240px">
        <col width="80px">
        <col width="80px">
        <col width="110px">
        <col width="120px">
        <col width="180px">
      </colgroup>
      <thead>
        <tr>
          <th>No.</th>
          <th class="align-left">
            {{ $t("adminPage.management.patientList.list.header.name") }}
          </th>
          <th class="align-left">
            {{ $t("adminPage.management.patientList.list.header.email") }}
          </th>
          <th>{{ $t("adminPage.management.patientList.list.header.age") }}</th>
          <th>
            {{ $t("adminPage.management.patientList.list.header.gender") }}
          </th>
          <th>
            {{ $t("adminPage.management.patientList.list.header.assignedTo") }}
          </th>
          <th>
            {{
              $t("adminPage.management.patientList.list.header.subscription")
            }}
          </th>
          <th>
            {{
              $t("adminPage.management.patientList.list.header.created")
            }}
          </th>
          <th />
        </tr>
      </thead>
    </table>
    <div class="custom-scrollbar table-scroll">
      <table>
        <colgroup>
          <col width="60px">
          <col width="180px">
          <col width="240px">
          <col width="80px">
          <col width="80px">
          <col width="110px">
          <col width="120px">
          <col width="180px">
        </colgroup>
        <tbody v-if="list.length > 0">
          <tr
            v-for="(item, index) of list"
            :key="index"
            :class="checkedList.indexOf(item.id) > -1 ? 'checked' : ''"
          >
            <td>{{ item.index }}</td>
            <td class="align-left">
              {{
                !item.name ? "-" : item.name.includes("null") ? "-" : item.name
              }}
            </td>
            <td class="align-left">{{ item.email }}</td>
            <td>{{ item.age }}</td>
            <td>{{ item.gender ? item.gender[0] : "-" }}</td>
            <td>
              {{
                !item.therapist_name
                  ? "-"
                  : item.therapist_name.includes("null")
                    ? "-"
                    : item.therapist_name
              }}
            </td>
            <td>
              <button
                v-if="item.subscription.marketPlace != null"
                @click="handleShowSubscription(item)"
              >
                {{ item.subscription.planName }}
              </button>
              <template v-else>{{ item.subscription.planName }}</template>
            </td>
            <td>{{ $moment(item.created_time).format("YYYY-MM-DD HH:mm:ss") }} </td>
            <td>
              <button class="edit-btn" @click="handleEditClick(item)">
                {{
                  $t("adminPage.management.patientList.basicInformation.edit")
                }}
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="6">Nothing to display</td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-pagination v-model="currentPage" :page-count="totalPage" />
  </section>
</template>

<script>
import vPagination from "vue-plain-pagination";
import Select from "vue-select";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import { fetchGetClinicPatient } from "@/api/clinic.js";
import { mapGetters } from "vuex";
import Subscription from "./Subscription";
import PatientInfo from "./PatientInfo";
export default {
  name: "ManagementPatient",
  components: {
    vPagination,
    Select
  },
  mixins: [SelectMixin, ErrorMixin],
  data() {
    return {
      currentPage: 1,
      size: 15,
      totalCount: 1,
      totalPage: 1,
      checkedList: [],
      searchText: "",
      sortingOption: [
        {
          label: this.$t("adminPage.management.patientList.selectOption.all"),
          value: "ALL"
        },
        {
          label: this.$t("adminPage.management.patientList.selectOption.name"),
          value: "NAME"
        },
        {
          label: this.$t("adminPage.management.patientList.selectOption.email"),
          value: "EMAIL"
        }
      ],
      selectedSortingType: {
        label: this.$t("adminPage.management.patientList.selectOption.all"),
        value: "ALL"
      },
      list: []
    };
  },
  computed: {
    availableCheckList: function () {
      return this.list.reduce((acc, cur) => {
        if (cur.status < 1) {
          acc.push(cur);
        }
        return acc;
      }, []);
    },
    checkAll: function () {
      const allCounts = this.availableCheckList.length;
      const checkedCounts = this.checkedList.length;
      return checkedCounts === allCounts
        ? "all all-checkbox"
        : checkedCounts > 0
          ? "checked all-checkbox"
          : "all-checkbox";
    },
    ...mapGetters({
      clinic: "user/clinic"
    })
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchPatients();
      }
    }
  },
  created() {
    this.searchPatients();
  },
  methods: {
    async searchPatients() {
      const search = {
        page: this.currentPage,
        size: this.size,
        keyword: this.searchText,
        search_type: this.selectedSortingType.value,
        sorts: [{ field: "time", order: "desc" }]
      };
      try {
        const result = await fetchGetClinicPatient(this.clinic.id, search);
        this.totalCount = result.total_count < 1 ? 1 : result.total_count;
        this.totalPage = result.total_page < 1 ? 1 : result.total_page;
        const addIndex = result.list.map((item, index) => {
          item.index =
            result.total_count - index - (this.currentPage - 1) * this.size;
          return item;
        });
        this.list = addIndex;
      } catch (error) {
        this.handleError(error, "ManagementPatient > Get Clinic Patient API");
      }
    },
    handleAllCheck() {
      const newChecked = [];
      if (
        this.checkedList.length === 0 ||
        this.checkedList.length !== this.availableCheckList.length
      ) {
        this.availableCheckList.forEach((item, index) => {
          newChecked.push(item.id);
        });
      }
      this.checkedList = newChecked;
    },
    handleShowSubscription(patient) {
      return this.$modal.show(
        Subscription,
        {
          patient: patient,
          title: `Subscription Info`
        },
        {
          height: "auto",
          clickToClose: false
        }
      );
    },
    handleEditClick(item) {
      this.$modal.show(
        PatientInfo,
        {
          patientInfo: item,
          searchPatients: this.searchPatients
        },
        {
          width: "450px",
          height: "auto"
        }
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.ManagementPatient {
  background: #ffffff;
  border: 1px solid $borderColor1;
  border-radius: 3px;
  .management-top {
    padding: 16px;
    display: flex;
    .management-search {
      display: flex;
      align-content: center;
      .search {
        padding: 0 16px;
        position: relative;
        .icon-ic_search {
          position: absolute;
          top: 50%;
          right: 32px;
          transform: translate(0, -50%);
          font-size: $fontSize2;
          color: $fontColor2;
        }
        input[type="text"] {
          padding-right: 55px;
          height: 35px;
          border-radius: 2px;
        }
      }
      .search-select {
        width: 110px;
        ::v-deep .v-select {
          background: #ffffff;
          .vs__dropdown-toggle {
            border-radius: 2px;
          }
          .vs__dropdown-menu {
            min-width: 110px;
          }
        }
      }
    }

    .control-btns {
      margin-left: auto;
      display: flex;
      justify-content: center;

      button {
        &:last-of-type {
          margin-left: 8px;
        }
      }
    }
  }
  .patient-count {
    button {
      text-decoration: underline;
      font-size: $fontSize7;
      color: $fontColor1;
    }
  }
  table {
    tbody {
      tr:first-of-type {
        th,
        td {
          border-top: 0 none;
        }
      }

      tr {
        td {
          .edit-btn {
            padding: 7px 12px;
            border: 1px solid #d0d4dc;
            box-sizing: border-box;
            border-radius: 4px;
            font-family: Manrope3;
            font-weight: 600;
            font-size: $fontSize7;
            line-height: 18px;
            width: 58px;
            height: 32px;
          }
        }
      }
    }
  }
}
</style>
