var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "account" } },
    [
      _c("div", { staticClass: "top" }, [
        _c("a", { staticClass: "nav-home", on: { click: _vm.routerLink } }, [
          _c("img", { attrs: { src: _vm.logo, alt: "neofect logo" } })
        ])
      ]),
      _vm._v(" "),
      _c("router-view", { on: { status: _vm.loginStatus } }),
      _vm._v(" "),
      _c("footer", { staticClass: "footer" }, [
        _vm._v("NEOFECT. ALL RIGHTS RESERVED")
      ]),
      _vm._v(" "),
      _c("modals-container")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }