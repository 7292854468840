<template>
  <div class="WeeklySession">
    <ul class="week-list">
      <li v-for="(session,index) of sessionList" :key="index">
        <div v-if="session" class="week-session">
          <button
            type="button"
            @click="!session.batch_process_yn ? detailPopup1(session.id) : detailPopup2(session.batch_session_id, session.date)"
          >
            {{ $t("page.patient.calendar.head.strokeCoachSession") }}
            <br />
            {{`(${session.count} ${$t("page.patient.calendar.head.trainings")})`}}
          </button>
        </div>
        <div v-else></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "WeeklySession",
  props: {
    sessionList: Array,
    handleSessionType1Click: Function,
    handleSessionType2Click: Function
  },
  data() {
    return {};
  },
  methods: {
    detailPopup1(sessionId) {
      // 배치가 돌지 않은 세션
      this.handleSessionType1Click(sessionId);
    },
    detailPopup2(sessionId, date) {
      // 배치 이후의 세션
      this.handleSessionType2Click(sessionId, date);
    }
  },
  created() {
    // console.log(this.sessionList);
  }
};
</script>

<style lang='scss' scoped>
.WeeklySession {
  .week-list {
    display: table;
    table-layout: fixed;
    padding-left: 65px;
    width: 100%;
    box-sizing: border-box;
    li {
      display: table-cell;
      border: 1px solid rgba(255, 255, 255, 0);
      border-right: none;
    }

    .week-session {
      width: calc(100% - 8px);
      margin: 4px;
      box-sizing: border-box;
      border-radius: 4px;
      height: 56px;
      padding: 8px;
      background: #214252;
      position: relative;
      button {
        color: #ffffff;
        font-size: $fontSize7;
        line-height: 18px;
        text-align: left;
        padding: 0 8px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        span {
          display: block;
        }
      }
    }
  }
}
</style>
