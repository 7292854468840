<template>
  <div ref="clock" class="clock">
    <span class="record" />
    <span class="time">{{ recordingTime }}</span>
  </div>
</template>
<script>
/* eslint-disable prefer-const */

export default {
  props: {
    isRecording: Boolean
  },
  data() {
    return {
      recordingTime: "00:00.00",
      startTime: null,
      started: null
    };
  },
  watch: {
    isRecording(newVal, oldVal) {
      newVal ? this.start() : this.reset();
    }
  },
  methods: {
    start() {
      const clock = this.$refs.clock;
      clock.classList.add("active");
      this.startTime = new Date();
      this.started = setInterval(this.clockRunning, 10);
    },
    reset() {
      clearInterval(this.started);
      this.recordingTime = "00:00.00";
      const clock = this.$refs.clock;
      setTimeout(() => {
        clock.classList.remove("active");
      }, 500);
    },
    clockRunning() {
      const currentTime = new Date();
      const timeElapsed = new Date(currentTime - this.startTime);
      const hour = timeElapsed.getUTCHours();
      const min = timeElapsed.getUTCMinutes();
      const sec = timeElapsed.getUTCSeconds();
      const ms = timeElapsed.getUTCMilliseconds();
      // 시간당 60분씩 분과 덧셈
      let calmin = "0" + (hour * 60 + min);

      this.recordingTime =
        calmin.slice(
          -(calmin.length <= 2 ? calmin.length : calmin.length - 1)
        ) +
        ":" +
        this.zeroPrefix(sec, 2) +
        "." +
        this.zeroPrefix(ms, 2);
    },
    zeroPrefix(num, digit) {
      let zero = "";
      for (let i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    }
  }
};
</script>
<style lang="scss" scoped>
.clock {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  transform: translate(24px, 26px);
  width: 104px;
  height: auto;
  padding: 7px 0;
  background-color: #606266;
  border-radius: 8px;
  overflow: hidden;
  z-index: 10;

  &.active {
    display: block;
  }

  .record {
    display: block;
    float: left;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #f44336;
    margin: 3px 0 0 10px;
  }

  .time {
    display: block;
    float: left;
    font-size: $fontSize5;
    margin-left: 10px;
    color: #fff;
  }
}
</style>
