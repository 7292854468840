<template>
  <section class="Session">
    <h1>{{ title }}</h1>
    <div class="contents">
      <div class="game-list">
        <Select
          v-model="selectedLevelType"
          :options="levelTypes"
          label="name"
          :components="{ OpenIndicator, Deselect }"
          id="deviceSelect"
        />
        <!-- Add Tag Box 21.01.21 -->
        <div class="tag-box custom-scrollbar">
          <a
            v-for="(tag, index) of tagList"
            :key="index"
            class="tag"
            :class="selectedTagList.includes(tag) ? 'active' : ''"
            @click="handleOnClickTag(tag)"
            >{{ tag }}</a
          >
        </div>
        <ul class="custom-scrollbar">
          <li v-for="(mission, index) of missionList" :key="index">
            <div class="game-image">
              <img :src="showMissionImage(mission)" :alt="mission.title" />
            </div>
            <div class="game-info">
              <span class="game-title">{{ mission.title }}</span>
              <div
                class="game-select secondary-type-btns small-btns"
                :class="selectedHomework.length > 7 ? 'disabled' : ''"
              >
                <button type="button" @click="addMission(mission)">
                  {{ $t("page.patient.session.detail.select") }}
                </button>
                <button
                  type="button"
                  class="detail-btn"
                  @click="viewMissionDetail(mission)"
                >
                  {{ $t("page.patient.session.exerciseDetail.detail") }}
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="selected-games-list custom-scrollbar">
        <div class="repeat-wrap">
          <span>{{
            $t("page.patient.session.exerciseDetail.recurrence")
          }}</span>
          <input type="text" v-model="homeworkRepeat.message" readonly />
          <button
            class="btn"
            :class="repeatDisabled ? 'disabled' : ''"
            @click="handleRepeatSettingOpen"
          >
            {{ $t("page.patient.session.exerciseDetail.setting") }}
          </button>
        </div>
        <div class="homework-message">
          <div class="homework-message-title">
            {{ $t("page.patient.session.exerciseDetail.message") }}
          </div>
          <textarea
            :class="{ 'homework-message-text': true, first: messageStyleFirst }"
            @click="handleMessageStyleToggle"
            v-model="homeworkMessage"
          />
          <span class="homework-message-description">{{
            $t("page.patient.session.exerciseDetail.messageDescription")
          }}</span>
        </div>
        <div class="game-list-wrapper">
          <ul class="selected-games">
            <li v-for="(mission, index) of selectedHomework" :key="index">
              <div class="game-info">
                <span class="game-image">
                  <img :src="showMissionImage(mission)" :alt="mission.title" />
                </span>
                <span class="game-title">{{ mission.title }}</span>
                <span class="game-delete">
                  <button type="button">
                    <span
                      class="icon-ic_delete_circle"
                      @click="deleteMission(mission)"
                    >
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </span>
                  </button>
                </span>
                <div class="game-set-info">
                  <div class="set-label">
                    {{ $t("page.patient.session.exerciseDetail.complete") }}
                  </div>
                  <div class="set-input-wrapper">
                    <input
                      type="number"
                      v-model="mission.setsCount"
                      @input="updateValue(mission, $event)"
                      @keydown="onlyNumber"
                      @blur="isCheckEmpty(mission, $event)"
                    />
                    <span>{{
                      $t("page.patient.session.exerciseDetail.sets")
                    }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul class="empty-list">
            <li v-for="(empty, index) of emptyList" :key="index">
              <span class="icon-ic_add">
                <span class="path1"></span>
                <span class="path2"></span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 서밋버튼 -->
    <div
      class="popup-submit-btns"
      :class="selectedHomework.length < 1 ? 'disabled' : 'selectedGames'"
    >
      <button type="button" class="accept-btn" @click.once="handleSubmit">
        {{ $t("page.patient.session.saveButton") }}
      </button>
      <button type="button" @click="handleClose">
        {{ $t("page.patient.session.cancelButton") }}
      </button>
    </div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button type="button" class="close-btn icon-btn" @click="handleClose">
        <span class="icon-ic_delete">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
    <MissionDetail
      v-if="missionDetailPopup.view"
      :mission="missionDetailPopup.mission"
      :closePopup="closeDetailPopup"
      :sets="initSets"
      :selectedHomework="selectedHomework"
    />
  </section>
</template>

<script>
/* eslint-disable */
import Select from "vue-select";
import { fetchGetHomeworkMissionListV2 } from "@/api/connect";
import { mapGetters } from "vuex";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import MissionDetail from "./MissionDetail";
import RepeatSetting from "./RepeatSetting";
import { isEmpty } from "lodash";
import { repeatDateCalculator } from "@/utils/repeat-date-calculator";
import { get, uniqBy } from "lodash";

const missionType = Object.freeze({
  ACTIVITY: "ACTIVITY",
  BASICS: "BASICS",
  VIDEO: "VIDEO",
  READING: "READING",
  QUIZ: "QUIZ",
});
export default {
  name: "HomeworkSession",
  components: {
    Select,
    MissionDetail,
  },
  mixins: [SelectMixin, ErrorMixin],
  props: {
    sessionPopup: {
      selectedDate: Date,
      // 수정시 받아오는 값
      edit: Boolean,
      missions: Array,
      message: String,
    },
    closeSessionPopup: Function,
    addSession: Function,
    editSession: Function,
    repeatDisabled: Boolean,
  },
  data() {
    return {
      selectedLevelType: {
        name: this.$t("page.patient.session.exerciseDetail.defaultLevel"),
        value: "ALL",
      },
      initMissionList: [],
      selectedHomework: [], // 선택된 미션
      missionList: [], // 받아온 미션리스트
      emptyList: ["", "", "", "", "", "", "", ""], // emptylist 출력용
      levelTypes: [
        {
          name: this.$t("page.patient.session.exerciseDetail.defaultLevel"),
          value: "ALL",
        },
        { name: "Level 1", value: "STABILIZER" },
        { name: "Level 2", value: "ASSIST" },
        { name: "Level 3", value: "FUNCTIONAL" },
      ],
      homeworkMessage: this.$t(
        "page.patient.session.exerciseDetail.messageDefault"
      ),
      messageStyleFirst: true,
      // Add Publishing Tag Data
      tagList: [], // api 에서 받아오는 태그 리스트
      selectedTagList: [], // 선택된 태그 리스트
      initSets: 3,
      missionDetailPopup: {
        view: false,
      },
      // 홈워크 반복 정보 객체
      homeworkRepeat: {
        message: this.$t("page.patient.session.exerciseDetail.doesnotrepeat"),
        isNotRepeat: true,
        selectedRepeatType: "Days",
        daysRepeat: "",
        checkedDays: [],
        repeatCount: "",
      },
      repeatDates: [],
      selectedDate: "",
    };
  },
  computed: {
    ...mapGetters({
      selectedPatient: "patient/selectedPatient",
      lang: "lang/language",
    }),
    title() {
      if (this.lang === "ko") {
        return `${this.$moment(this.sessionPopup.selectedDate).format(
          "MMMM D, YYYY"
        )} ${this.$t("page.patient.session.exerciseDetail.title")} `;
      }
      return `${this.$t(
        "page.patient.session.exerciseDetail.title"
      )} ${this.$moment(this.sessionPopup.selectedDate).format(
        "MMMM D, YYYY"
      )}`;
    },
  },
  watch: {
    lang: function (newVal) {
      this.levelTypes[0].name = this.$t(
        "page.patient.session.exerciseDetail.defaultLevel"
      );
      if (this.selectedLevelType.value === "ALL") {
        this.selectedLevelType.name = this.$t(
          "page.patient.session.exerciseDetail.defaultLevel"
        );
      }
    },
    selectedLevelType: async function (newVal) {
      let levelString = null;
      this.selectedTagList = [];
      if (newVal.value !== "ALL") {
        levelString = newVal.value;
      }

      await fetchGetHomeworkMissionListV2(missionType.BASICS, levelString).then(
        (res) => {
          this.tagList = res.missionTags;
          this.initMissionList = uniqBy(res.missions, "title");
        }
      );

      this.missionList = this.initMissionList;
    },
    // 선택된 태그 리스트
    selectedTagList: async function (newVal) {
      if (isEmpty(newVal)) {
        this.missionList = this.initMissionList;
      } else {
        // let list = [];
        // forEach(newVal, (selectedTag) => {
        //   this.initMissionList.map((mission) => {
        //     if (mission.tags.includes(selectedTag)) {
        //       list.push(mission);
        //     }
        //   });
        // });
        let list = [];
        this.initMissionList.map((mission) => {
          const op = newVal.every((tag) => mission.tags.indexOf(tag) > -1);

          if (op) return list.push(mission);
        });

        this.missionList = list;
      }
    },
  },
  methods: {
    showMissionImage: function (mission) {
      if (
        mission.image !== "" &&
        mission.image !== null &&
        mission.image !== undefined
      )
        return mission.image;

      if (
        typeof get(mission, "links") !== "undefined" &&
        get(mission, "links[0]") !== "" &&
        get(mission, "links[0]") !== null &&
        get(mission, "links[0]") !== undefined
      ) {
        const youtubeUrl = get(mission, "links[0]");
        let youtubeId = youtubeUrl.split("/")[3];
        if (youtubeId.includes("watch?v=")) {
          youtubeId = youtubeId.slice(8);
        }
        return `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`;
      }

      if (
        typeof get(mission, "meta.links") !== "undefined" &&
        get(mission, "meta.links[0]") !== "" &&
        get(mission, "meta.links[0]") !== null &&
        get(mission, "meta.links[0]") !== undefined
      ) {
        const youtubeUrl = get(mission, "meta.links[0]");
        let youtubeId = youtubeUrl.split("/")[3];
        if (youtubeId.includes("watch?v=")) {
          youtubeId = youtubeId.slice(8);
        }
        return `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`;
      }
      return require(`@/assets/imgs/mission_empty_img.png`);
    },
    handleMessageStyleToggle() {
      if (this.messageStyleFirst === false) return;
      this.messageStyleFirst = false;
    },
    handleSubmit() {
      if (this.selectedHomework.length < 1) {
        return;
      }

      // 홈워크 반복 추가. 21.02.08
      const newSession = {
        date: !isEmpty(this.repeatDates)
          ? this.repeatDates
          : [this.selectedDate],
        missions: this.selectedHomework,
        // list 출력용 title
        title: `${this.selectedHomework.length} ${this.$t(
          "page.patient.session.rightList.exercise.description"
        )}`,
        message: this.homeworkMessage,
        selectedDate: this.selectedDate,
        homeworkRepeat: this.homeworkRepeat,
      };

      if (this.sessionPopup.edit) {
        this.editSession(newSession, this.sessionPopup.id);
      } else {
        this.addSession(newSession);
      }
      this.handleClose();
    },
    handleClose() {
      this.closeSessionPopup();
    },
    addMission(mission) {
      // 선택한 미션을 리스트에 추가
      if (this.selectedHomework.length > 7) {
        return;
      }
      const newSet = {
        id: mission.id,
        title: mission.title,
        image: mission.image,
        links: mission.links,
        setsCount: this.initSets,
      };
      this.selectedHomework.push(newSet);
    },
    deleteMission(mission) {
      // 선택한 미션을 리스트에서 삭제
      this.selectedHomework = this.selectedHomework.filter((selected) => {
        if (selected !== mission) {
          return selected;
        }
      });
    },
    // Add Tag Click event 21.01.21
    handleOnClickTag(tag) {
      if (this.selectedTagList.includes(tag)) {
        this.selectedTagList = this.selectedTagList.filter((t) => t !== tag);
      } else {
        this.selectedTagList.push(tag);
      }
    },
    // Add View Mission Detail 21.01.21
    viewMissionDetail(mission) {
      this.missionDetailPopup.view = true;
      this.missionDetailPopup = { ...this.missionDetailPopup, mission };
    },
    closeDetailPopup() {
      this.missionDetailPopup.view = false;
    },
    handleRepeatSettingOpen() {
      if (this.repeatDisabled) return;

      this.$modal.show(
        RepeatSetting,
        {
          lang: this.lang,
          addHomeworkRepeat: this.addHomeworkRepeat,
          homeworkRepeat: this.homeworkRepeat,
        },
        {
          width: "450px",
          height: "auto",
        }
      );
    },
    updateValue(mission, e) {
      const value = Number(e.target.value);
      if (value < 1) mission.setsCount = "";
      if (value > 99) mission.setsCount = 99;
      // this.$forceUpdate();
    },
    isCheckEmpty(mission, e) {
      if (isEmpty(e.target.value)) mission.setsCount = 3;
    },
    onlyNumber(event) {
      let re = new RegExp("[^0-9\\-]");
      if (event.keyCode === 8 || event.keyCode === 46) {
        return;
      } else {
        if (re.test(event.key)) {
          // event.stopPropagation();
          event.preventDefault();
          return;
        }
      }
    },
    // 반복 세팅된 data를 계산해야함
    addHomeworkRepeat(repeatData) {
      this.repeatDates = repeatDateCalculator(
        this.sessionPopup.selectedDate,
        repeatData
      );

      this.homeworkRepeat = {
        ...this.homeworkRepeat,
        ...repeatData,
      };
    },
  },
  async created() {
    // 21.01.25 api change
    await fetchGetHomeworkMissionListV2(missionType.BASICS).then((res) => {
      this.tagList = res.missionTags;
      this.initMissionList = uniqBy(res.missions, "title");
    });

    this.missionList = this.initMissionList;
    // 반복데이트 배열 초기 세팅
    this.repeatDates = [
      this.$moment(this.sessionPopup.selectedDate).format("YYYY-MM-DD"),
    ];

    // 초기화면의 디바이스 세팅
    if (this.sessionPopup.edit) {
      this.selectedHomework = [...this.sessionPopup.missions];
      this.homeworkMessage = this.sessionPopup.messages;
      this.messageStyleFirst = false;
      this.repeatDates = this.sessionPopup.date;
      this.homeworkRepeat =
        this.sessionPopup.homeworkRepeat || this.homeworkRepeat;
    }

    this.selectedDate = this.sessionPopup.selectedDate;
  },
};
</script>

<style lang="scss" scoped>
.Session {
  position: fixed;
  width: 960px;
  height: 728px;
  min-width: 960px;
  background: #ffffff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $fontColor1;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  overflow: hidden;
  z-index: 2;
  h1 {
    padding: 40px 32px 28px;
    font-size: $fontSize2;
    font-weight: 500;
    line-height: 33px;
    border-bottom: 1px solid $borderColor1;
    white-space: nowrap;
    overflow: hidden;
  }
  .device-select {
    width: 240px;
    position: absolute;
    top: 45px;
    right: 23px;
    label {
      color: $fontColor2;
      font-size: $fontSize7;
    }
    .v-select {
      margin-top: 8px;
    }
  }
  .contents {
    display: flex;
    height: 539px;
    .game-list {
      flex-shrink: 0;
      width: 400px;
      padding: 24px 32px;
      box-sizing: border-box;
      border-right: 1px solid $borderColor1;
      .vdp-datepicker__calendar {
        margin-top: 23px;
        border: 0 none;
        padding: 0;
        .cell {
          &.day {
            height: 30px;
            width: 30px;
            line-height: 30px;
            margin: 8.9px;
          }
          &.selected {
            &:hover {
              background: none;
            }
            &::before {
              background: none;
            }
          }
          &.today {
            border-radius: 50%;
            color: #ffffff;
            background: $mainColor1;
            &::before {
              background: none;
            }
          }
        }
      }
      ul {
        margin-top: 32px;
        width: calc(100% + 32px);
        padding-right: 25px;
        height: calc(100% - 32px - 35px);
        box-sizing: border-box;
        overflow-y: auto;
        li {
          display: flex;
          // align-items: center;
          padding: 16px 0;
          box-sizing: border-box;
          &:first-of-type {
            padding-top: 0;
          }
          & + li {
            border-top: 1px solid $borderColor1;
          }
          .game-image {
            width: 100px;
            height: 80px;
            flex-shrink: 0;
            overflow: hidden;
            border-radius: 4px;
            position: relative;
            img {
              width: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .game-info {
            margin-left: 16px;
            // padding-right: 16px;
            width: calc(100% - 100px - 16px);
            position: relative;
            span {
              display: block;
              width: 100%;
              height: 40px;

              &.game-title {
                color: $fontColor1;
                font-size: $fontSize6;
                box-sizing: border-box;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                word-wrap: normal;
                line-height: 20px;
              }
              &.game-feature {
                display: block;
                margin-top: 4px;
                color: $fontColor6;
                font-size: $fontSize7;
                box-sizing: border-box;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: normal;
                span {
                  & + span {
                    &::before {
                      content: "/";
                      padding: 0 4px;
                    }
                  }
                }
              }
              &.game-motion {
                display: block;
                margin-top: 4px;
                color: $fontColor2;
                font-size: $fontSize8;
                box-sizing: border-box;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                word-wrap: normal;
                -webkit-text-size-adjust: none;
                span {
                  & + span {
                    &::before {
                      content: "/";
                      padding: 0 4px;
                    }
                  }
                }
              }
            }

            .game-select {
              position: absolute;
              bottom: 0;

              button {
                margin-right: 8px;
              }

              .detail-btn {
                color: #606266;
                border: 1px solid #d0d4dc;
                background-color: #fff;
                cursor: pointer;

                &:hover {
                  color: #fff;
                  background-color: #d0d4dc;
                }
              }
            }
          }
        }
      }

      .tag-box.custom-scrollbar {
        width: calc(100% + 32px);
        padding-right: 25px;
        box-sizing: border-box;
        margin-top: 20px;
        height: 64px;
        overflow-y: auto;
        .tag {
          font-family: Manrope;
          cursor: pointer;
          display: inline-block;
          margin-bottom: 8px;
          font-size: $fontSize7;
          margin-right: 6px;
          padding: 5px 12px;
          border: 1px solid #d0d4dc;
          box-sizing: border-box;
          border-radius: 100px;

          &.active {
            background-color: #606266;
            color: #fff;
            border: 1px solid #606266;
          }
        }
      }

      .custom-scrollbar {
        margin-top: 12px;
        height: calc(100% - 64px - 35px - 12px);
      }
    }
    .selected-games-list {
      padding: 24px;
      width: 560px;
      height: 100%;
      position: relative;
      overflow-y: auto;
      box-sizing: border-box;
      .repeat-wrap {
        display: flex;
        align-items: center;
        font-size: $fontSize6;
        color: $fontColor2;
        font-family: Manrope;

        span {
          font-weight: bold;
          font-size: $fontSize7;
          line-height: 16px;
        }

        input {
          margin-left: 16px;
          width: 320px;
          height: 36px;
          font-weight: 500;
          color: $fontColor1;
          background: #f4f5f7;
          border-radius: 2px;
        }

        .btn {
          cursor: pointer;
          margin-left: 12px;
          width: 70px;
          height: 32px;
          padding: 6px;
          font-weight: 600;
          border: 1px solid #d0d4dc;
          box-sizing: border-box;
          line-height: 14px;
          border-radius: 4px;

          &.disabled {
            background: #f4f5f7;
          }
        }
      }

      .auto-select {
        display: flex;
        button {
          margin-left: auto;
        }
      }
      .homework-message {
        margin-top: 20px;

        .homework-message-title {
          color: #62676f;
          font-weight: bold;
          font-size: 13px;
        }
        .homework-message-text {
          margin-top: 10px;
          color: black;
          font-family: Manrope3;
          &.first {
            color: #a7b0bb;
          }
        }
        .homework-message-description {
          padding-top: 5px;
          font-size: 11px;
          color: #a7b0bb;
        }
      }
      .game-list-wrapper {
        position: relative;
        margin-top: 16px;
        .empty-list {
          li {
            width: calc((100% - 32px) / 3);
            height: 192px;
            float: left;
            border: 1px dashed $borderColor1;
            margin-right: 16px;
            position: relative;
            box-sizing: border-box;
            border-radius: 4px;
            &:nth-of-type(3n) {
              margin-right: 0;
            }
            &:nth-child(n + 4) {
              margin-top: 16px;
            }
            .icon-ic_add {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: $fontColor3;
            }
          }
        }
        .selected-games {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          li {
            width: calc((100% - 32px) / 3);
            height: 192px;
            float: left;
            margin-right: 16px;
            position: relative;
            box-sizing: border-box;
            position: relative;
            z-index: 1;
            border: 1px solid $borderColor1;
            border-radius: 4px;
            overflow: hidden;
            &:nth-of-type(3n) {
              margin-right: 0;
            }
            &:nth-child(n + 4) {
              margin-top: 16px;
            }
            .game-info {
              width: 100%;
              height: 192px;
              position: relative;
              // overflow: hidden;
              .game-image {
                display: block;
                height: 112px;
                img {
                  width: 100%;
                  height: 112px;
                  position: absolute;
                  top: 0;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
                &::after {
                  content: "";
                  width: 100%;
                  height: 112px;
                  display: block;
                  background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 35.94%,
                    rgba(0, 0, 0, 0.7) 100%
                  );
                  position: relative;
                }
              }
              .game-title {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #ffffff;
                position: absolute;
                top: 112px;
                left: 0;
                transform: translateY(calc(-100% - 12px));
                padding: 0 8px 0 8px;
                box-sizing: border-box;
                font-size: $fontSize6;
                line-height: 18px;
              }
              .game-delete {
                position: absolute;
                top: 6px;
                right: 6px;
                .icon-ic_delete_circle {
                  color: #ffffff;
                  .path2,
                  .path3 {
                    color: $fontColor1;
                  }
                }
              }
              .game-set-info {
                padding: 8px 12px;

                .set-label {
                  font-family: Manrope;
                  font-weight: 700;
                  font-size: $fontSize7;
                  line-height: 16px;
                  margin-left: 2px;
                }
                .set-input-wrapper {
                  margin-top: 8px;

                  input {
                    border: 1px solid #d0d4dc;
                    box-sizing: border-box;
                    border-radius: 2px;
                    padding: 8px 16px;
                    width: 96px;
                    height: 36px;
                    font-size: $fontSize6;
                  }

                  span {
                    display: inline-block;
                    font-family: Manrope;
                    font-weight: 500;
                    margin-left: 8px;
                    font-size: $fontSize7;
                    line-height: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .popup-submit-btns {
    height: 88px;
  }
}
</style>
