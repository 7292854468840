<template>
  <div ref="createLicenseStatesPopup" class="LicenseStates">
    <div class="wrapper">
      <div class="Event-contents">
        <div class="checkbox-section">
          <div class="check-all">
            <button :class="checkAllStates" @click="handleAllStates">Select All</button>
          </div>
          <ul class="check-list custom-scrollbar">
            <li v-for="(state,index) of allStates" :key="index">
              <input :id="state.abbr" v-model="checkedStates" type="checkbox" :value="state">
              <label :for="state.abbr">{{ state.state }}</label>
            </li>
          </ul>
        </div>
      </div>
      <div class="popup-submit-btns">
        <button type="button" class="accept-btn" @click="handleClose">
          Save
        </button>
      </div>
      <div class="popup-btns">
        <!-- 팝업 컨트롤 버튼 -->
        <button type="button" class="close-btn icon-btn" @click="handleClose">
          <span class="icon-ic_delete">
            <span class="path1" />
            <span class="path2" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: "LicenseStates",
  props: {
    allStates: Array,
    checkStates: Array,
    setCheckedStates: Function,
    setSelectedStates: Function,
    handleCreateLicenseStatesPopupClose: Function
  },
  data() {
    return {
      checkedStates: []
    };
  },
  methods: {
    handleAllStates: function() {
      let newChecked = [];
      if (this.checkedStates.length === 0 || this.checkedStates.length !== this.allStates.length) {
        this.allStates.forEach((state, index) => {
          newChecked.push(state);
        });
      }
      this.checkedStates = newChecked;
    },
    handleSetSelectedStates: function() {
      let checkedStatesLength = this.checkedStates.length;
      let text =
        checkedStatesLength == 1
          ? this.checkedStates[0].state
          : checkedStatesLength > 1
            ? this.checkedStates[0].state + " and " + (checkedStatesLength - 1) + " other states"
            : this.$t("common.license.input");
      this.setSelectedStates(text);
    },
    handleClose() {
      this.handleCreateLicenseStatesPopupClose();
    }
  },
  watch: {
    checkedStates: function() {
      let newChecked = [];
      this.checkedStates.forEach((state, index) => {
         let checkedState = {
          abbr: state.abbr,
          state: state.state
        }
        newChecked.push(checkedState);
      });
      this.setCheckedStates(newChecked);
      this.handleSetSelectedStates();
    }
  },
  computed: {
    checkAllStates: function() {
      const allCounts = this.allStates.length;
      const checkedCounts = this.checkedStates.length;
      return checkedCounts === allCounts
        ? "all all-checkbox"
        : checkedCounts > 0
          ? "checked all-checkbox"
          : "all-checkbox";
    },
  },
  created() {
    if(this.checkStates != null) {
      this.checkedStates = this.checkStates;
    }
  }
};
</script>
<style lang="scss" scoped>
  .LicenseStates {
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background: rgba(255, 255, 255, 0);
    }

    .wrapper {
      z-index: 2;
      box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
      width: 700px;
      border-radius: 0.5rem;
      background: #ffffff;
      box-sizing: border-box;
      position: fixed;
      padding: 50px 0 0 0;
      color: $fontColor1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .Event-contents {
      padding: 0 64px 32px 64px;
      font-size: $fontSize6;

      .ic-icon {
        position: absolute;
        top: 5px;
        left: -40px;
        color: $fontColor2;
      }
      .checkbox-section {
        padding-top: 5px;
        overflow: hidden;

        span {
          float: left;
          margin-right: 32px;
        }

        .check-all {
          border-bottom: 1px solid $borderColor1;
          padding-bottom: 10px;
          margin: 0 8px;
        }

        .check-list {
          padding-top: 16px;
          margin: 0 8px;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          width: 100%;

          li {
            padding-top: 5px;
          }
        }

        .all-checkbox {
          margin-top: 32px;
          font-weight: 600;
          display: flex;
          align-items: center;
          font-size: $fontSize6;

          &:focus {
            outline: none;
            border: 0 none;
          }
          &::before {
            border-color: #dcdfe6;
            background: none;
            color: rgba(255, 255, 255, 0);
          }
          &.all {
            &::before {
              content: "\e933";
              border-color: $mainColor1;
              background: $mainColor1;
              color: #ffffff;
            }
          }
          &.checked {
            &::before {
              content: "\e965";
              border-color: $mainColor1;
              background: $mainColor1;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
</style>
