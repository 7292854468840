import request from "@/utils/request";
import settings from "@/settings";

export function login(data) {
  const authParam = {
    client_id: settings.secretClientId,
    response_type: settings.secretResponseType,
    grant_type: settings.secretGrantType,
    scope: settings.secretScope
  };

  const req = { ...authParam };

  const formData = new FormData();
  formData.append("username", data.username);
  formData.append("password", data.password);

  return request({
    url:
      "/uaa/oauth/token?" +
      Object.keys(req)
        .map(key => key + "=" + req[key])
        .join("&"),
    data: formData,
    method: "post"
  });
}

// therapist 치료사의 환자목록 가져오기
export function fetchGetPatientsList() {
  return request({
    url: `/user/api/v1/patients/therapist`,
    method: "get"
  });
}

// user 신규가입
export function fetchSignup(user) {
  return request({
    url: "/user/api/v1/users/registTherapist",
    method: "post",
    data: user
  });
}

// user 가입된 email주소인지 확인
export function fetchCheckEmail(email) {
  return request({
    url: `/user/api/v1/users/email/exist/${email}`,
    method: "get"
  });
}

// email로 인증코드 보내기
export function fetchVerifyEmail(email, uuid, from) {
  return request({
    url: `/user/api/v1/users/email/verify/${email}?uuid=${uuid}&from=${from}`,
    method: "get"
  });
}

// reset password
export function fetchResetPassword(email) {
  return request({
    url: `/user/api/v1/users/resetPassword/${email}`,
    method: "post"
  });
}

// 미국의 states정보 가져오기
export function getUSAStatesList() {
  return request({
    url: "/user/api/v1/address/states",
    method: "get"
  });
}

export function getInfo() {
  return request({
    url: "/user/api/v1/users/me",
    method: "get"
  });
}

// Init client with fcm token
export function clientInit(client) {
  return request({
    url: `/user/api/v1/client/init`,
    method: "post",
    data: client
  });
}

// Email주소로 user정보 가져오기
export function fetchGetUserInfoByEmail(email) {
  return request({
    url: `/user/api/v1/users/email/${email}`,
    method: "get"
  });
}

// Therapist 정보 가져오기
export function fetchGetTherapistInfo() {
  return request({
    url: `/user/api/v1/therapist`,
    method: "get"
  });
}

// Therapist 이름 변경
export function fetchUpdateTherapistName(therapistName) {
  return request({
    url: `/user/api/v1/users/registTherapist/name`,
    method: "put",
    data: therapistName
  });
}

// Therapist 정보 업데이트
export function fetchUpdateTherapistInfo(therapist) {
  return request({
    url: `/user/api/v1/users/registTherapist`,
    method: "put",
    data: therapist
  });
}

// Therapist photo파일 업로드
export function fetchUploadTherapistPhoto(formData) {
  return request({
    url: `/user/api/v1/users/image`,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    method: "post",
    data: formData
  });
}

// Therapist 비밀번호 업데이트
export function fetchUpdateTherapistPassword(password) {
  return request({
    url: `/user/api/v1/users/updatePassword`,
    method: "post",
    params: password
  });
}

// Therapist clinic 조직정보
export function fetchGetClinicList() {
  return request({
    url: `/user/api/v1/clinic/list`,
    method: "get"
  });
}

// Therapist clinic 변경신청 취소
export function fetchCancelChangeClinic(clinic_req_id) {
  return request({
    url: `/user/api/v1/clinic/therapist/req/${clinic_req_id}`,
    method: "delete"
  });
}

// Therapist management 치료사목록 가져오기
export function fetchGetClinicTherapist(id, data) {
  return request({
    url: `/user/api/v1/clinic/${id}/therapist/list`,
    method: "post",
    data
  });
}

// Therapist request 치료사목록 가져오기
export function fetchGetTherapistRequest(id, data) {
  return request({
    url: `/user/api/v1/clinic/${id}/therapist/req/list`,
    method: "post",
    data
  });
}

// Therapist management 환자목록 가져오기
export function fetchGetClinicPatient(id, data) {
  return request({
    url: `/user/api/v1/clinic/${id}/patient/list`,
    method: "post",
    data
  });
}

// Therapist management 치료사별 환자목록 가져오기
export function fetchGetClinicTherapistPatientList(id, therapistId) {
  return request({
    url: `/user/api/v1/clinic/${id}/therapist/patients`,
    method: "get",
    params: {
      therapist_id: therapistId
    }
  });
}

// Therapist request 환자목록 가져오기
export function fetchGetPatientRequest(id, data) {
  return request({
    url: `/user/api/v1/clinic/${id}/patient/req/list`,
    method: "post",
    data
  });
}
