var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ScreenSharing" }, [
    _c(
      "div",
      { staticClass: "Session", on: { error: _vm.errorHandler } },
      [
        _vm.sessionDie
          ? _c("div", { staticClass: "notice-message session-die" }, [
              _vm._v("\n      Video call has been disconnected.\n      "),
              _c("br"),
              _vm._v("Please turn off and restart the video call.\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "push-again-btn" }, [
          !_vm.disconnected
            ? _c("div", [
                _c("span", { staticClass: "notice-message" }, [
                  _vm._v("Connecting...")
                ])
              ])
            : _c("div", [
                _vm.disconnected === "CASE_1"
                  ? _c("span", { staticClass: "notice-message" }, [
                      _vm._v("Patient doesn't respond")
                    ])
                  : _c("span", { staticClass: "notice-message" }, [
                      _vm._v("Screen Sharing has been disconnected")
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "primary-type-btns" }, [
                  _c("button", { on: { click: _vm.handleConnectPush } }, [
                    _vm._v("Connect Again")
                  ])
                ])
              ])
        ]),
        _vm._v(" "),
        _vm._l(_vm.streams, function(stream) {
          return _c(
            "div",
            { key: stream.streamId, attrs: { id: "subscribers" } },
            [
              stream
                ? _c("subscriber", {
                    attrs: { stream: stream, session: _vm.session },
                    on: { error: _vm.errorHandler }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        _vm._v(" "),
        _c("div", { staticClass: "control-bar" }, [
          _c(
            "button",
            { attrs: { type: "button" }, on: { click: _vm.handleClose } },
            [_vm._m(0)]
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }