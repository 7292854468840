/* eslint-disable no-unused-vars */
import Vue from "vue";
import "./registerServiceWorker";
import moment from "moment-timezone";
import VueMoment from "vue-moment";
import VModal from "vue-js-modal";
import VueKonva from "vue-konva";
import ElementUI from "element-ui";
import "./plugins/chart.js";

import numeral from "numeral";
import numFormat from "vue-filter-number-format";
import UUID from "vue-uuid";

import VueToast from "vue-toast-notification";

import App from "./App";
import store from "./store";
import router from "./router";

import i18n from "@/lang";

import "@/permission"; // permission control

import "@/styles/element-variables.scss";

// style 관련
import "reset-css";
import "@/styles/font.css";
import "vue-toast-notification/dist/index.css";

import Clipboard from "v-clipboard";

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;

Vue.use(VModal, {
  dialog: true,
  dynamic: true
});

Vue.use(VueKonva);
Vue.use(UUID);
Vue.use(VueToast);
Vue.use(Clipboard);

Vue.filter("numFormat", numFormat(numeral));
Vue.use(VueMoment, {
  moment
});

Vue.use(ElementUI, {
  size: "medium",
  i18n: (key, value) => i18n.t(key, value)
});

const vue = new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
