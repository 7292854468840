<template>
  <div class="WeeklyHomework">
    <ul class="week-list">
      <li v-for="(homework,index) of homeworkList" :key="index">
        <div v-if="homework" class="week-homework">
          <button
            type="button"
            @click="detailPopup(homework.id)"
          >
            {{ $t("page.patient.calendar.head.exerciseHomework") }}
            <br />
            {{`(${homework.missions.length} ${$t("page.patient.calendar.head.exercises")})`}}
          </button>
        </div>
        <div v-else></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "WeeklySession",
  props: {
    homeworkList: Array,
    handleHomeworkDetail: Function
  },
  data() {
    return {};
  },
  methods: {
    detailPopup(homeworkId) {
      this.handleHomeworkDetail(homeworkId);
    }
  },
  created() {
    // console.log(this.homeworkList);
  }
};
</script>

<style lang='scss' scoped>
.WeeklyHomework {
  .week-list {
    display: table;
    table-layout: fixed;
    padding-left: 65px;
    width: 100%;
    box-sizing: border-box;
    li {
      display: table-cell;
      border: 1px solid rgba(255, 255, 255, 0);
      border-right: none;
    }

    .week-homework {
      width: calc(100% - 8px);
      margin: 4px;
      box-sizing: border-box;
      border-radius: 4px;
      height: 56px;
      padding: 8px;
      background: #59886b;
      position: relative;
      button {
        color: #ffffff;
        font-size: $fontSize7;
        line-height: 18px;
        text-align: left;
        padding: 0 8px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        span {
          display: block;
        }
      }
    }
  }
}
</style>
