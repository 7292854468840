var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TopMenu" },
    [
      _c("lang-select"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "user-profile",
          attrs: { type: "button" },
          on: { click: _vm.linkProfile }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.avatar
            ? _c("span", { staticClass: "user-image" }, [
                _c("img", {
                  attrs: { src: _vm.avatar, alt: _vm.name + "'s photo" }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span"),
          _vm._v(" "),
          _c("span", { staticClass: "user-name" }, [
            _vm._v("\n      " + _vm._s(_vm.name) + "\n      "),
            _c("span", { staticClass: "user-grade" }, [
              _vm._v(_vm._s(_vm.i18nLevel(_vm.level)))
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "user-menu",
          attrs: { type: "button" },
          on: { click: _vm.toggleAccountMenu }
        },
        [
          !_vm.accountMenu
            ? _c("span", { staticClass: "icon-ic_chevron_down" }, [
                _c("span", { staticClass: "path1" })
              ])
            : _c("span", { staticClass: "icon-ic_chevron_up" }, [
                _c("span", { staticClass: "path1" })
              ])
        ]
      ),
      _vm._v(" "),
      _vm.accountMenu
        ? _c(
            "div",
            { staticClass: "account-menu", on: { click: _vm.toPortal } },
            [
              _c(
                "router-link",
                { staticClass: "to-clinic", attrs: { to: "/" } },
                [
                  _c("span", { staticClass: "icon-ic_change" }, [
                    _c("span", { staticClass: "path1" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "path2" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "path3" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "path4" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "path5" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "path6" })
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("components.clinicTopMenu.moveToPortal"))
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                { attrs: { type: "button" }, on: { click: _vm.handleLogout } },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("components.clinicTopMenu.logOut")))
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_group_fill" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_logout" }, [
      _c("span", { staticClass: "path1" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }