var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Home" }, [
    _vm.selectedDevice
      ? _c("div", { staticClass: "report-wrapper" }, [
          _c(
            "ul",
            { staticClass: "device-list" },
            _vm._l(_vm.devices, function(device, index) {
              return _c("li", { key: index }, [
                _c(
                  "button",
                  {
                    class: device.id === _vm.selectedDevice.id ? "on" : "",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.selectDevice(device)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(device.printName) + "\n        "
                    )
                  ]
                )
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticClass: "sub-menu-list" }, [
            _c("div", {
              staticClass: "separators-symbol",
              class:
                _vm.selectedReport === _vm.selectReportType.TRAINING ? "on" : ""
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "sub-menu-list-item",
                class:
                  _vm.selectedReport === _vm.selectReportType.TRAINING
                    ? "on"
                    : "",
                on: {
                  click: function($event) {
                    return _vm.selectReport(_vm.selectReportType.TRAINING)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("page.patient.neofectHome.layout.trainingReport")
                    ) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", {
              staticClass: "separators-symbol second",
              class:
                _vm.selectedReport === _vm.selectReportType.EVALUATION
                  ? "on"
                  : ""
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "sub-menu-list-item",
                class:
                  _vm.selectedReport === _vm.selectReportType.EVALUATION
                    ? "on"
                    : "",
                on: {
                  click: function($event) {
                    return _vm.selectReport(_vm.selectReportType.EVALUATION)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("page.patient.neofectHome.layout.evaluation")
                    ) +
                    "\n      "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectedReport === _vm.selectReportType.TRAINING,
                  expression: "selectedReport === selectReportType.TRAINING"
                }
              ],
              staticClass: "search-bar"
            },
            [
              _c(
                "button",
                {
                  staticClass: "all-period",
                  on: { click: _vm.handleClickAllPeriod }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("page.patient.neofectHome.layout.allPeriod")
                      ) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "search-row" },
                [
                  _c("ToggleButton", {
                    staticClass: "search-row-item",
                    attrs: { checked: _vm.isRangeCompare },
                    on: { toggle: _vm.toggleIsRangeCompare }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "search-row-item" }, [
                    _vm._v(
                      _vm._s(_vm.$t("page.patient.neofectHome.layout.compare"))
                    )
                  ]),
                  _vm._v(" "),
                  _c("date-range-picker", {
                    ref: "picker",
                    staticStyle: {
                      "margin-right": "20px",
                      "text-align": "center",
                      width: "210px"
                    },
                    attrs: {
                      "close-on-esc": true,
                      "append-to-body": true,
                      "auto-apply": _vm.isDateAutoApply,
                      ranges: false,
                      "max-date": _vm.yesterday,
                      "show-dropdowns": true
                    },
                    on: {
                      "change-month": _vm.onChangeMonth,
                      toggle: _vm.calendarOpen
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "input",
                          fn: function(picker) {
                            return [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      _vm.dateRange.startDate,
                                      "YYYY.MM.DD"
                                    )
                                  ) +
                                  " ~\n            " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      _vm.dateRange.endDate,
                                      "YYYY.MM.DD"
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      4283985570
                    ),
                    model: {
                      value: _vm.dateRange,
                      callback: function($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.isRangeCompare
                  ? _c(
                      "div",
                      { staticClass: "search-row" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "search-row-item search-button",
                            on: { click: _vm.updateRangeDate }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "page.patient.neofectHome.layout.previousPeriodButton"
                                  )
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "search-row-item" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("page.patient.neofectHome.layout.target")
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("date-range-picker", {
                          ref: "picker2",
                          staticStyle: {
                            "margin-right": "20px",
                            "text-align": "center",
                            width: "210px"
                          },
                          attrs: {
                            "close-on-esc": true,
                            "append-to-body": true,
                            "auto-apply": false,
                            ranges: false,
                            "max-date": _vm.yesterday,
                            "date-format": _vm.dateFormat
                          },
                          on: { update: _vm.updateAutoRangeDate },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "input",
                                fn: function(picker2) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            _vm.toDateRange.startDate,
                                            "YYYY.MM.DD"
                                          )
                                        ) +
                                        " ~\n              " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            _vm.toDateRange.endDate,
                                            "YYYY.MM.DD"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3754655120
                          ),
                          model: {
                            value: _vm.toDateRange,
                            callback: function($$v) {
                              _vm.toDateRange = $$v
                            },
                            expression: "toDateRange"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _vm.isBoard && _vm.selectedReport === _vm.selectReportType.TRAINING
              ? _c(
                  "div",
                  [
                    _c("BoardTrainingReport", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange
                      }
                    }),
                    _vm._v(" "),
                    _c("TrainingTime", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange
                      }
                    }),
                    _vm._v(" "),
                    _c("MissionLogs", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange
                      }
                    }),
                    _vm._v(" "),
                    _c("BoardMovedDistance", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange
                      }
                    }),
                    _vm._v(" "),
                    _c("MotionFeatureRatio", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange
                      }
                    }),
                    _vm._v(" "),
                    _c("BoardAromArea", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange
                      }
                    }),
                    _vm._v(" "),
                    _c("BoardArom", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange
                      }
                    }),
                    _vm._v(" "),
                    _c("BoardSmoothness", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange
                      }
                    }),
                    _vm._v(" "),
                    _c("BoardSimilarity", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isBoard &&
            _vm.selectedReport === _vm.selectReportType.EVALUATION
              ? _c(
                  "div",
                  [
                    _c("BoardEvaluation", {
                      attrs: { device: _vm.selectedDevice }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isGloveOrKids &&
            _vm.selectedReport === _vm.selectReportType.TRAINING
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "grid-section" },
                      [
                        _c("GloveTrainingReport", {
                          attrs: {
                            device: _vm.selectedDevice,
                            isRangeCompare: _vm.isRangeCompare,
                            dateRange: _vm.dateRange,
                            toDateRange: _vm.toDateRange
                          }
                        }),
                        _vm._v(" "),
                        _c("GloveSensorReport", {
                          attrs: {
                            device: _vm.selectedDevice,
                            isRangeCompare: _vm.isRangeCompare,
                            dateRange: _vm.dateRange,
                            toDateRange: _vm.toDateRange
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("TrainingTime", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange
                      }
                    }),
                    _vm._v(" "),
                    _c("MissionLogs", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange
                      }
                    }),
                    _vm._v(" "),
                    _c("GloveMotionTypeRatio", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange,
                        isKids: _vm.isKids
                      }
                    }),
                    _vm._v(" "),
                    _c("MotionFeatureRatio", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange
                      }
                    }),
                    _vm._v(" "),
                    _c("GloveAromTrend", {
                      attrs: {
                        device: _vm.selectedDevice,
                        isRangeCompare: _vm.isRangeCompare,
                        dateRange: _vm.dateRange,
                        toDateRange: _vm.toDateRange,
                        isKids: _vm.isKids
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isGloveOrKids &&
            _vm.selectedReport === _vm.selectReportType.EVALUATION
              ? _c(
                  "div",
                  [
                    _c("GloveEvaluation", {
                      attrs: { device: _vm.selectedDevice }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      : _c("div", { staticClass: "no-device" }, [_vm._v("No Device")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }