import { clientInit } from "@/api/user";
import {
  getFcmToken,
  getUuid,
  removeFcmToken
} from "@/utils/firebase";

import moment from "moment";

const state = {
  fcmToken: getFcmToken()
};

const mutations = {
  SET_FCM_TOKEN: (state, token) => {
    state.fcmToken = token;
  }
};

const actions = {
  initFcmToken({ commit }, fcmToken) {
    return clientInit({
      uuid: getUuid(),
      fcm_token: fcmToken,
      service_type: "therapist",
      gmt_offset: moment().utcOffset()
    })
      .then(token => {
        commit("SET_FCM_TOKEN", fcmToken);
      })
      .catch(error => {
        throw error.data;
      });
  },
  // remove fcm token
  resetFcmToken({ commit }) {
    return new Promise(resolve => {
      commit("SET_FCM_TOKEN", "");
      removeFcmToken();
      resolve();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
