export default {
  common: {
    modal: {
      button: {
        close: "닫기",
        cancel: "취소",
        delete: "삭제",
        remove: "삭제",
        register: "등록"
      }
    },
    role: {
      provider: "의료인",
      admin: "관리자",
      owner: "오너",
      mainOwner: "메인오너"
    },
    nation: {
      america: "미국",
      korea: "한국"
    },
    license: {
      input: "자격증을 취득한 주(state)를 선택해주세요."
    },
    chat: {
      searchPatientPlaceholder: "검색",
      send: "보내기",
      sending: "전송중..."
    },
    eventModal: {
      title: "이 일정을 등록하시겠습니까?",
      message:
        "아래 내용으로 환자에게 알림이 발송됩니다. 일정을 등록하기 전에 입력한 정보를 다시 한 번 확인해주세요.",
      boxLabel: {
        typeOfEvent: "일정 유형",
        time: "시간",
        attendees: "참석자",
        message: "메세지"
      },
      type: {
        videoCall: "비디오 콜",
        screenSharing: "화면공유",
        providerLive: "라이브방송"
      }
    },
    validation: {
      clinicCodeRequired: "클리닉 코드는 필수입니다.",
      clinicCodeWrong: "클리닉 코드는 대문자와 숫자 조합입니다.",
      clinicCodeLength: "클리닉 코드는 6자리 입니다."
    }
  },
  page: {
    login: {
      logIn: "로그인",
      email: "Email",
      password: "비밀번호",
      logInButton: "로그인",
      forgotPassword: "비밀번호를 잊었어요",
      willAccount: "아직 계정이 없으신가요?"
    },
    signUp: {
      title: "회원가입",
      description:
        "이메일 주소 인증이 필요합니다. 오직 의료인만 포탈에 가입할 수 있습니다.",
      emailLabel: "Email",
      emailError: {
        format: "유효하지 않은 이메일 주소입니다",
        already: "이미 사용중인 이메일 주소입니다"
      },
      sendButton: "인증 메일 전송",
      logInLabel: "로그인",
      email: {
        title: "인증 메일 전송 완료",
        description: [
          "고객님이 입력한 이메일 주소로 인증 메일을 전송했습니다.",
          "이메일 주소를 인증하기 위해 인증 메일의 링크를 클릭해주세요.",
          "만약 인증 메일을 받지 못하셨다면 스팸함을 확인하거나 "
        ],
        homeButton: "홈으로",
        reSend: "인증 메일을 재전송 하세요"
      },
      reEmail: {
        title: "인증 메일 재전송 완료",
        description: [
          "인증 메일이 재전송 되었습니다.",
          "이메일 주소를 인증하기 위해 인증 메일의 링크를 클릭해주세요.",
          "만약 인증 메일을 받지 못하셨다면 스팸함을 확인하거나 "
        ],
        reSend: "인증 메일을 재전송 하세요"
      },
      inputInfo: {
        title: "회원가입",
        name: {
          title: "이름",
          placeholder: "이름을 입력하세요"
        },
        country: {
          title: "국적",
          placeholder: "국적을 선택하세요",
          errorMessage: "일치하는 국가가 없습니다."
        },
        states: {
          title: "주 (State)",
          tooltip: "자격증을 취득한 주(state)를 선택해주세요."
        },
        gender: {
          title: "성별",
          male: "남성",
          female: "여성"
        },
        birth: {
          title: "생년월일"
        },
        mobilePhone: {
          title: "휴대폰번호",
          placeholder: "숫자만 입력 가능"
        },
        providerType: {
          title: "의료인 유형",
          OT: "OT",
          PT: "PT",
          Others: "기타"
        },
        workPlace: {
          title: "직장",
          Neofect: "네오펙트",
          OtherClinic: "그 외",
          placeholder: "클리닉 코드를 입력하세요"
        },
        email: {
          title: "Email"
        },
        password: {
          title: "비밀번호",
          placeholder: "비밀번호를 입력하세요",
          error: {
            length: "비밀번호는 10-20자로 설정해야 합니다.",
            continuous: "연속된 문자 또는 숫자는 포함할 수 없습니다.",
            twoTypes:
              "영어대문자, 소문자, 숫자, 특수문자 중 2가지 이상을 포함해야 합니다."
          }
        },
        rePassword: {
          title: "비밀번호 확인",
          placeholder: "비밀번호를 다시 한 번 입력하세요",
          error: {
            notMatch: "비밀번호가 일치하지 않습니다"
          }
        },
        signUpButton: "회원가입"
      },
      signUpDone: {
        title: "안녕하세요",
        description: "네오펙트 포탈 가입을 환영합니다.",
        logIn: "로그인"
      }
    },
    forgotPassword: {
      title: "비밀번호 찾기",
      description: "가입시 사용한 ID (Email)를 입력해주세요.",
      email: {
        title: "Email",
        error: {
          exist: "계정 정보가 존재하지 않습니다",
          invalidEmail: "유효하지 않은 이메일 주소입니다"
        }
      },
      resetPasswordButton: "비밀번호 재설정",
      reset: {
        sent: {
          title: "비밀번호 재설정 링크 발송 완료",
          description: [
            "비밀번호 재설정 링크가 고객님의 이메일로 발송되었습니다.",
            "비밀번호를 재설정 하기 위해 메일에 포함된 링크를 클릭해주세요.",
            "",
            "만약 메일을 받지 못하셨다면 스팸함을 확인하거나 "
          ]
        },
        reSent: {
          title: "비밀번호 재설정 링크 발송 완료",
          description: [
            "비밀번호 재설정 링크를 다시 발송했습니다.",
            "비밀번호를 재설정 하기 위해 메일에 포함된 링크를 클릭해주세요.",
            "",
            "만약 메일을 받지 못하셨다면 스팸함을 확인하거나 "
          ]
        },
        link: "메일을 재전송하세요",
        logIn: "로그인"
      }
    },
    calendar: {
      setting: {
        title: "업무시간설정",
        workingHours: "업무시간",
        lunchBreak: "점심시간",
        save: "저장"
      },
      notifications: {
        title: "알림",
        readAllButton: "모두읽기"
      },
      topMenu: {
        moveToAdmin: "관리자페이지",
        logOut: "로그아웃"
      },
      createEvent: {
        type: {
          videoCall: "비디오 콜",
          screenSharing: "화면공유",
          providerLive: "라이브방송",
          general: {
            title: "일반",
            allDay: "하루 종일",
            daily: "매일"
          }
        },
        placeholder: {
          patient: "참석자 추가",
          message: "메세지 입력 (선택)",
          description: "설명 입력",
          memo: "메모 입력 (선택)",
          topic: "주제 입력",
          title: "제목 입력"
        },
        saveButton: "저장",
        validation: {
          requiredFields: "필수 항목을 모두 입력하세요.",
          requiredFieldsLong:
            "이 일정을 등록하기 위해서 모든 필수 항목을 입력해주세요.",
          choosePatient: "환자를 선택해주세요.",
          noPatient: "담당 환자가 없습니다.",
          inviteByEmail: "이메일로 초대하시겠습니까?"
        },
        closeModal: {
          title: "창 닫기",
          description:
            "정말 창을 닫으시겠습니까? 입력 내용이 저장되지 않습니다."
        }
      },
      eventDetail: {
        type: {
          videoCall: "비디오 콜",
          screenSharing: "화면공유",
          providerLive: "라이브방송"
        },
        attendee: "참석자",
        copyLink: "링크 복사",
        button: {
          videoCall: "비디오 콜 시작",
          screenSharing: "화면공유 시작"
        },
        modal: {
          title: "일정 삭제",
          description: "정말 이 일정을 삭제하시겠습니까?"
        }
      },
      sideBar: {
        title: "담당 환자",
        search: "검색",
        selectAll: "모두 선택",
        typeOfEvent: "일정 유형",
        type: {
          videoCall: "비디오 콜",
          videoCallRequest: "비디오 콜 요청",
          screenSharing: "화면공유",
          providerLive: "라이브방송",
          general: "일반"
        }
      },
      index: {
        type: {
          videoCall: "비디오 콜",
          videoCallRequest: "비디오 콜 요청",
          screenSharing: "화면공유",
          providerLive: "라이브방송"
        }
      }
    },
    patient: {
      layout: {
        searchPlaceholder: "검색",
        gender: {
          male: "남자",
          female: "여자"
        },
        button: {
          createSession: "숙제 생성",
          textChat: "채팅",
          screenSharing: "화면공유"
        },
        menu: {
          profile: "프로필",
          neofectHome: "홈 훈련기록",
          homework: "숙제 기록",
          calandar: "환자 달력",
          advice: "조언",
          Documentation: "문서"
        }
      },
      profile: {
        basicInformation: {
          title: "기본 정보",
          age: "나이",
          dateOfBirth: "생년월일",
          gender: "성별",
          state: "거주지",
          email: "Email",
          subscriptionPlan: "구독 상품",
          videoCall: "비디오 콜"
        },
        diagnosis: {
          title: "진단명",
          nameOfDisease: "병명",
          affectedSide: "환측",
          dateOfOccurance: "발병일"
        },
        upperExtremityLevel: {
          title: "상지 평가 레벨"
        }
      },
      neofectHome: {
        layout: {
          trainingReport: "훈련기록",
          evaluation: "평가",
          compare: "비교하기",
          previousPeriodButton: "이전 기간",
          target: "비교 기간",
          allPeriod: "모든 기간"
        },
        summary: {
          assignedSessions: "주어진 세션",
          completedSessions: "완료 세션",
          daysTrained: "훈련 일수",
          averageTraining: "세션당 평균 훈련시간",
          totalTrainingTime: "전체 훈련 시간",
          activityTolerance: "훈련 지속시간"
        },
        data: {
          trainingTime: "훈련시간",
          sessionLog: "세션로그",
          movementTargeted: "훈련 동작",
          trainingType: "훈련 유형",
          rangeOfMotion: "가동범위 (ROM)",
          evaluationRecord: "평가 기록",
          totalDistanceMoved: "총 움직임 거리",
          areaAROM: "면적 AROM",
          verticalHorizontalAROM: "수직 / 수평 AROM",
          smoothnessOfReach: "부드러움",
          shapeSimilarity: "궤적 유사도",
          empty: "기록이 없습니다."
        }
      },
      advice: {
        newAdviceButton: "새로운 조언",
        noAdviceYet: "아직 입력한 조언이 없습니다.",
        list: {
          title: "조언"
        },
        modal: {
          title: "조언",
          contentsPlaceholer: "메세지 입력",
          RegisterButton: "등록"
        }
      },
      documentation: {
        newDocumentationButton: "새로운 문서",
        noDocumentationYet: "아직 작성한 문서가 없습니다.",
        modal: {
          type: {
            memo: "메모",
            videoCall: "비디오 콜",
            coachingSession: "코칭 세션"
          },
          placeholder: {
            title: "제목입력",
            memo: "메모 입력"
          },
          registerButton: "등록",
          saveButton: "저장"
        },
        list: {
          type: {
            memo: "메모",
            videoCall: "비디오 콜",
            coachingSession: "코칭 세션",
            videoCallRecord: "비디오 콜 녹화기록"
          }
        },
        deleteModal: {
          title: "문서 삭제",
          description: "정말 이 문서를 삭제하시겠습니까?"
        }
      },
      calendar: {
        head: {
          dailyMission: "오늘의 미션",
          strokeCoachSession: "스마트 재활 숙제",
          trainings: "훈련",
          exerciseHomework: "운동 숙제",
          exercises: "운동",
          detail: {
            type: {
              dailyMission: {
                title: "오늘의 미션"
              },
              strokeCoachSession: {
                title: "스마트 재활 숙제",
                deleteModal: {
                  title: "훈련 삭제",
                  message: "이 스마트 재활 숙제를 삭제하시겠습니까?"
                }
              },
              homework: {
                title: "운동 숙제",
                deleteModal: {
                  title: "숙제 삭제",
                  message: "이 운동 숙제를 삭제하시겠습니까?"
                }
              }
            }
          }
        }
      },
      session: {
        create: {
          title: "의 숙제",
          selectDate: "날짜 선택",
          setSession: "훈련 만들기",
          noSession: [
            "생성된 훈련이 없습니다.",
            "훈련을 생성하기 위해 날짜를 선택해주세요."
          ],
          setExerciseHomework: "운동 숙제 만들기",
          noExerciseHomework: [
            "아직 생성된 숙제가 없습니다.",
            "숙제를 만드려면 날짜를 선택해주세요."
          ]
        },
        saveButton: "저장",
        cancelButton: "취소",
        saveModal: {
          title: "숙제가 생성되었습니다."
        },
        tab: {
          smartRehab: {
            title: "스마트 재활"
          },
          exercise: {
            title: "운동"
          }
        },
        detail: {
          title: "의 스마트 재활 숙제",
          selectDevice: "기기 선택",
          select: "추가",
          autoCreateButton: "자동 생성",
          defaultMotion: "모든 동작"
        },
        exerciseDetail: {
          title: "의 운동 숙제",
          defaultLevel: "모든 레벨",
          message: "메시지",
          messageDefault:
            "이 운동들은 당신의 재활에 큰 도움이 될거에요. 숙제를 모두 수행할 수 있도록 최선을 다해봐요!",
          messageDescription: "세션에 대한 메세지나 설명을 입력해주세요.",
          select: "선택",
          detail: "상세정보",
          recurrence: "반복",
          doesnotrepeat: "반복 안 함",
          setting: "설정",
          complete: "수행 횟수",
          sets: "세트",
          cancel: "취소"
        },
        rightList: {
          smartRehab: {
            title: "의 세션",
            description: "훈련"
          },
          exercise: {
            title: "의 운동 숙제",
            description: "운동"
          }
        },
        recurrence: {
          homeworkRecurrence: "숙제 반복 설정",
          doesNotRepeat: "반복 안 함",
          repeat: "반복",
          every: "매",
          days: "일 마다",
          ends: "반복 종료",
          after: "반복",
          occurences: "회 후 종료",
          save: "저장",
          week: "주",
          repeatOn: "다음 요일 마다",
          mon: "월",
          tue: "화",
          wed: "수",
          thu: "목",
          fri: "금",
          sat: "토",
          sun: "일"
        }
      },
      homework: {
        title: "운동",
        selectOption: {
          asc: "오래된 날짜 순",
          desc: "최근 날짜 순"
        },
        table: {
          header: {
            date: "날짜",
            exercises: "운동",
            completion: "수행여부",
            time: "수행시간"
          },
          contents: {
            empty: "기록이 없습니다"
          }
        }
      }
    },
    profile: {
      title: "프로필",
      editName: {
        edit: "수정",
        editName: "이름 수정하기",
        name: "이름",
        save: "저장",
        cancel: "취소",
        placeholder: "이름을 입력하세요"
      },
      country: "국적",
      states: {
        title: "주 (State)",
        tooltip: "자격증을 취득한 주(state)를 선택해주세요."
      },
      gender: {
        title: "성별",
        male: "남성",
        female: "여성"
      },
      birth: {
        title: "생년월일"
      },
      mobilePhone: {
        title: "휴대폰번호",
        placeholder: "숫자만 입력 가능"
      },
      providerType: {
        title: "의료인 유형",
        OT: "OT",
        PT: "PT",
        Others: "기타"
      },
      workPlace: {
        title: "직장",
        Neofect: "네오펙트",
        OtherClinic: "그 외",
        placeholder: "클리닉 코드를 입력하세요"
      },
      introduce: {
        title: "자기소개",
        placeholder: "자기소개를 입력하세요(문자열 100자 이내, 3줄 이내)"
      },
      resetPassword: {
        title: "비밀번호 변경",
        codeTitle: "새로운 비밀번호를 입력해주세요",
        current: "현재 비밀번호",
        new: "새로운 비밀번호",
        confirm: "비밀번호 확인",
        placeholder: {
          current: "비밀번호 입력",
          new: "새로운 비밀번호를 입력하세요",
          confirm: "비밀번호를 다시 한 번 입력하세요"
        },
        button: {
          reset: "비밀번호 변경",
          cancel: "취소",
          save: "저장"
        },
        error: {
          incorrect: "올바르지 않은 비밀번호입니다",
          notMatch: "비밀번호가 일치하지 않습니다",
          length: "비밀번호는 10-20자로 설정해야 합니다.",
          continuous: "연속된 문자 또는 숫자는 포함할 수 없습니다.",
          twoTypes:
            "영어대문자, 소문자, 숫자, 특수문자 중 2가지 이상을 포함해야 합니다."
        },
        saveModal: {
          title: "비밀번호가 변경 완료",
          message:
            "비밀번호가 변경되었습니다. 새로운 비밀번호로 로그인 할 수 있습니다."
        },
        link: {
          logIn: "로그인"
        }
      },
      saveModal: {
        title: "변경 내용 저장완료",
        message: "개인정보 변경 내용이 저장되었습니다."
      }
    },
    changeClinic: {
      title: "클리닉 선택",
      description: "소속 클리닉을 선택해주세요.",
      button: "가입요청"
    },
    videoCall: {
      memo: {
        sessionrecords: "세션 기록",
        videosession: "비디오 세션",
        noteforthepatient: "환자를 위한 조언"
      },
      remoteTraining: {
        rangeofmotion: "가동범위",
        playtime: "게임시간",
        holdtime: "동작 유지시간"
      }
    }
  },
  adminPage: {
    request: {
      patient: {
        button: {
          approve: "선택 승인",
          deny: "선택 거절"
        },
        selectOption: {
          all: "전체",
          name: "이름",
          email: "Email"
        },
        list: {
          header: {
            name: "이름",
            email: "Email",
            age: "나이",
            gender: "성별",
            requestedTime: "요청 일시",
            status: "상태"
          },
          statusType: {
            requested: "요청됨",
            approved: "승인됨",
            denied: "거절됨",
            cancel: "요청취소"
          },
          button: {
            approve: "승인",
            deny: "거절"
          },
          empty: "표시할 항목이 없습니다"
        },
        deleteModal: {
          title: "요청 삭제",
          message: "정말 이 요청을 삭제하시겠습니까?"
        }
      },
      provider: {
        button: {
          approve: "선택 승인",
          deny: "선택 거절"
        },
        selectOption: {
          all: "전체",
          name: "이름",
          email: "Email"
        },
        list: {
          header: {
            name: "이름",
            email: "Email",
            age: "나이",
            type: "유형",
            requestedTime: "요청 일시",
            status: "상태"
          },
          statusType: {
            requested: "요청됨",
            approved: "승인됨",
            denied: "거절됨",
            cancel: "요청취소"
          },
          button: {
            approve: "승인",
            deny: "거절"
          },
          empty: "표시할 항목이 없습니다."
        },
        deleteModal: {
          title: "요청 삭제",
          message: "정말 이 요청을 삭제하시겠습니까?"
        }
      }
    },
    management: {
      patientList: {
        selectOption: {
          all: "전체",
          name: "이름",
          email: "Email"
        },
        list: {
          header: {
            name: "이름",
            email: "Email",
            age: "나이",
            gender: "성별",
            assignedTo: "담당의료인",
            subscription: "구독상품",
            created: "생성일시"
          }
        },
        basicInformation: {
          edit: "수정",
          basicInformation: "기본 정보",
          fullName: "닉네임",
          firstName: "이름",
          lastName: "성",
          dateofBirth: "생년월일",
          gender: "성별",
          state: "거주지",
          mobile: "휴대폰",
          diagnosis: "병명",
          dateofOccurence: "발병일",
          affectedSide: "환측",
          left: "왼쪽",
          right: "오른쪽",
          save: "저장",
          cancel: "취소"
        }
      },
      providerList: {
        selectOption: {
          all: "전체",
          name: "이름",
          email: "Email"
        },
        button: {
          remove: "선택항목삭제"
        },
        removeModal: {
          title: "리스트에서 삭제",
          message: "정말 이 의료인을 명단에서 삭제하시겠습니까?"
        },
        empty: "표시할 항목이 없습니다",
        list: {
          header: {
            name: "이름",
            email: "Email",
            age: "나이",
            type: "유형",
            patients: "담당환자"
          },
          patients: "명",
          patientList: {
            title: "의 환자",
            list: {
              header: {
                name: "이름",
                email: "Email",
                age: "나이",
                gender: "성별"
              }
            }
          }
        }
      },
      mapping: {
        tabTitle: {
          providerPatient: "의료인 - 환자",
          patientProvider: "환자 - 의료인"
        },
        providerPatient: {
          selectOption: {
            all: "전체",
            name: "이름",
            email: "Email"
          },
          empty: "표시할 항목이 없습니다",
          button: {
            patientMapping: "환자 매핑"
          },
          list: {
            header: {
              name: "이름",
              email: "Email",
              age: "나이",
              type: "유형",
              patients: "담당환자"
            }
          },
          patients: "명"
        },
        patientProvider: {
          selectOption: {
            all: "전체",
            name: "이름",
            email: "Email"
          },
          empty: "표시할 항목이 없습니다",
          selectPlaceholder: "의료인 선택",
          button: {
            save: "저장"
          },
          list: {
            header: {
              name: "이름",
              email: "Email",
              age: "나이",
              gender: "성별",
              created: "생성일시",
              assignedTo: "담당의료인"
            }
          }
        },
        patientMapping: {
          title: "환자 매핑",
          patientList: {
            title: "환자 리스트",
            empty: "표시할 항목이 없습니다",
            selectOption: {
              all: "전체",
              name: "이름",
              range: "기간"
            },
            list: {
              header: {
                name: "이름",
                age: "나이",
                gender: "성별",
                create: "생성일시"
              }
            },
            button: {
              add: "추가"
            }
          },
          patientOf: {
            title: "의 환자",
            empty: "표시할 항목이 없습니다",
            selectOption: {
              all: "전체",
              name: "이름"
            },
            list: {
              header: {
                name: "이름",
                age: "나이",
                gender: "성별"
              }
            }
          },
          button: {
            save: "저장",
            cancel: "취소"
          }
        }
      }
    },
    setting: {
      roleAssign: {
        mainOwner: "메인오너",
        empty: "표시할 항목이 없습니다",
        selectOption: {
          all: "전체",
          name: "이름",
          email: "Email"
        },
        roleOption: {
          admin: "관리자",
          owner: "오너",
          provider: "의료인"
        },
        list: {
          header: {
            name: "이름",
            email: "Email",
            age: "나이",
            type: "유형",
            role: "권한"
          }
        },
        button: {
          save: "저장"
        }
      }
    }
  },
  components: {
    clinicMenu: {
      request: "가입요청",
      patient: "환자",
      provider: "의료인",
      management: "관리",
      patientList: "환자 리스트",
      providerList: "의료인 리스트",
      mapping: "매핑",
      setting: "설정",
      roleAssign: "권한 설정"
    },
    clinicTopMenu: {
      moveToPortal: "포탈로 이동",
      logOut: "로그아웃"
    },
    mappingPatientList: {
      title: "의 환자",
      list: {
        header: {
          name: "이름",
          email: "Email",
          age: "나이",
          gender: "성별"
        }
      }
    },
    subscriptionInfo: {
      title: "구독정보",
      name: "이름",
      email: "Email",
      plan: "구독상품",
      start: "시작일시",
      expiration: "만료일시",
      paymentMethod: "결제방법",
      paymentHistory: "결제내역"
    },
    approveStatus: {
      reviewing: "가입 요청 검토중",
      reviewingText:
        "가입 요청을 검토중입니다.<br/><span class='clinic-name'></span>의 승인을 기다려주세요.<br/><br/>클리닉을 변경하려면 <a href='#' class='clickable'>여기</a>를 클릭하세요.",
      denied: "가입 요청 거절됨",
      deniedText:
        "<span class='clinic-name'></span>으로부터 가입 요청이 거절되었습니다.<br/>올바른 클리닉을 선택했는지 확인 후<br/><a href='#' class='clickable'>다시 요청해주세요.</a><br/><br/>클리닉을 변경하려면 <a href='#' class='clickable'>여기</a>를 클릭하세요.",
      logout: "로그아웃"
    }
  }
};
