<template>
  <section class="ChangeClinic">
    <h1>{{ $t("page.changeClinic.title") }}</h1>
    <div class="work-place" :class="clinicCodeCheck ? '' : 'wrong'">
      <label>{{ $t("page.changeClinic.description") }}</label>
      <div class="work-place-radio">
        <span v-for="(type, index) of workplaceList" :key="index">
          <input
            :id="type"
            v-model="selectedWorkplace"
            type="radio"
            :value="type"
          >
          <label :for="type">{{ inputInfoWorkPlaceOption(type) }}</label>
        </span>
      </div>
      <input
        id="clinic"
        v-model="clinicCode"
        type="text"
        autocomplete="off"
        :placeholder="inputInfoWorkPlacePlaceholder"
        :disabled="selectedWorkplace === 'Neofect'"
      >
      <span class="message">
        {{ clinicCodeWrongMessage }}
      </span>
    </div>
    <div class="submit-btns" :class="submitDisable ? 'disabled' : ''">
      <button type="button" @click="handleSubmit">
        {{ $t("page.changeClinic.button") }}
      </button>
    </div>
  </section>
</template>
<script>
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import {
  fetchGetClinicByClinicCode,
  fetchAddMyClinicTherapistRequest
} from "@/api/clinic.js";

export default {
  name: "ChangeClinic",
  components: {
  },
  mixins: [SelectMixin, ErrorMixin],
  props: {},
  data() {
    return {
      workplaceList: ["Neofect", "Other Clinic"],
      selectedWorkplace: "Other Clinic",
      clinicCode: "",
      clinicCodeWrongMessage: ""
    };
  },
  computed: {
    inputInfoWorkPlacePlaceholder: function () {
      return this.$t("page.signUp.inputInfo.workPlace.placeholder");
    },
    inputInfoWorkPlaceOption() {
      return (value) => {
        return this.$t(
          `page.signUp.inputInfo.workPlace.${value.replace(" ", "")}`
        );
      };
    },
    submitDisable: function () {
      return !this.validateClinicCode(this.clinicCode) || !this.clinicCode;
    },
    clinicCodeCheck: function () {
      return this.validateClinicCode(this.clinicCode);
    }
  },
  watch: {
    selectedWorkplace: function (newVal, oldVal) {
      if (newVal === "Neofect") {
        this.clinicCode = "";
      }
    }
  },
  created() {
    this.$emit("status", this.$route.params.status);
  },
  methods: {
    async handleSubmit() {
      if (this.submitDisable) return;

      try {
        const codeInfo = await fetchGetClinicByClinicCode(this.clinicCode);
        if (codeInfo) {
          const req = await fetchAddMyClinicTherapistRequest(codeInfo.id);

          if (req) {
            // this.$store.dispatch("user/logout").then((result) => {
            //   location.href = "/";
            // });
            this.$router.push("/approvalStatus");
          }
        }
      } catch (error) {
        this.handleError(error, "Change Clinic By ClinicCode");
      }
    },
    validateClinicCode(code) {
      if (!code) return true;
      const regExpIsClincCode = /^(?=.*[A-Z])(?=.*\d)[A-Z\d]/;
      let resBoolean = true;

      if (!code) {
        this.clinicCodeWrongMessage = this.$t(
          "common.validation.clinicCodeRequired"
        );
        resBoolean = false;
      } else if (!regExpIsClincCode.test(code)) {
        this.clinicCodeWrongMessage = this.$t(
          "common.validation.clinicCodeWrong"
        );
        resBoolean = false;
      } else if (code.length !== 6) {
        this.clinicCodeWrongMessage = this.$t(
          "common.validation.clinicCodeLength"
        );
        resBoolean = false;
      }

      return resBoolean;
    }
  }
};
</script>
<style lang="scss" scoped>
.ChangeClinic {
  max-width: 350px;
  width: 100%;
  margin: auto;
  margin-top: 130px;
  margin-bottom: 144px;
  color: $fontColor1;
  h1 {
    font-weight: 800;
    font-size: $fontSize1;
    letter-spacing: -0.4px;
  }

  .work-place {
    &.wrong {
      input[type="text"] {
        border-color: $uiColor1;
      }
      .message {
        display: inline-block;
      }
    }

    margin-top: 60px;
    label {
      font-size: $fontSize7;
    }

    .work-place-radio {
      margin-top: 16px;
      display: flex;

      span {
        margin-left: 32px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
    input[type="text"] {
      margin-top: 16px;
    }
    .message {
      margin-top: 4px;
      display: none;
      color: $uiColor1;
      background: rgba(244, 67, 54, 0.25);
      padding: 4px 16px;
      font-size: $fontSize6;
    }
  }

  .submit-btns {
    margin-top: 88px;
  }
}
</style>
