<template>
  <section class="NoticeConfirm">
    <div class="Confirm-contents">
      <h1>
        <span>{{ confirm.title }}</span>
      </h1>
      <div class="confirm-message">
        <span>{{ confirm.message }}</span>
      </div>
    </div>
    <div v-if="confirm.type ==='VIDEOCALL'" class="popup-submit-btns">
      <button type="button" class="accept-btn" @click="handleSubmit">Start Video Call</button>
      <button type="button" @click="$emit('close')">Close</button>
    </div>
    <div v-else-if="confirm.type ==='SCREENSHARING'" class="popup-submit-btns">
      <button type="button" class="accept-btn" @click="handleSubmit">Start Screen Sharing</button>
      <button type="button" @click="$emit('close')">Close</button>
    </div>
    <div v-else class="popup-submit-btns">
      <button type="button" class="accept-btn" @click="$emit('close')">Close</button>
    </div>
  </section>
</template>

<script>
/* eslint-disable vue/require-default-prop */

import { fetchDetailEventInfo } from "@/api/calendar.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
export default {
  name: "NoticeConfirm",
  mixins: [ErrorMixin],
  props: {
    confirm: Object,
    eventId: String
  },
  data() {
    return {
      patient: {}
    };
  },
  async created() {
    try {
      const result = await fetchDetailEventInfo(this.eventId);
      if (result) {
        this.patient = result.patients[0];
      }
    } catch (error) {
      this.handleError(error, "NoticeConfirm > Get DetailEventInfo API");
    }
  },
  methods: {
    handleSubmit() {
      if (this.confirm.type === "VIDEOCALL") {
        this.$store.dispatch("videocall/startInSchedule", this.eventId);
      } else if (this.confirm.type === "SCREENSHARING") {
        this.$store.dispatch("screensharing/startInSchedule", this.eventId);
      }
      this.$emit("close");
    }
  }
};
</script>
<style lang='scss' scoped>
.NoticeConfirm {
  color: $fontColor1;
  font-size: $fontSize5;
  .Confirm-contents {
    padding: 50px 24px 40px 24px;
    h1 {
      font-size: $fontSize2;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    .confirm-message {
      margin-top: 26px;
      color: $fontColor2;
      line-height: 24px;
    }
  }
}
</style>
