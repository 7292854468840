<template>
  <section class="report-section smoothness-of-reach">
    <h1>{{ $t("page.patient.neofectHome.data.smoothnessOfReach") }}</h1>
    <div class="report-contents">
      <Loading v-if="loading" />
      <ul v-if="diff">
        <li v-if="smoothness.last">
          <div class="report-item">
            <span class="value">{{ smoothness.last.value.toFixed(0) }} pt.</span>
            <div class="in-decrease" v-if="isRangeCompare" v-bind:class="{decrease: smoothness.last.value.toFixed(0) - targetSmoothnessLastValue.toFixed(0) < 0, increase: smoothness.last.value.toFixed(0) - targetSmoothnessLastValue.toFixed(0) > 0}">
              <span class="arrow" v-bind:class="{down: smoothness.last.value.toFixed(0) - targetSmoothnessLastValue.toFixed(0) < 0, up: smoothness.last.value.toFixed(0) - targetSmoothnessLastValue.toFixed(0) > 0}"></span>
              {{ removeNumberMinusSign(smoothness.last.value.toFixed(0) - targetSmoothnessLastValue.toFixed(0)) }}
            </div>
          </div>
          <span class="label">
            {{
            $moment(smoothness.last.date, "YYYY-MM-DD").format("MMM DD,YYYY")
            }}
          </span>
        </li>
        <li>
          <span class="value up">
            {{ diff }} pt.
            <span :class="upOrDown">
              <span class="path1"></span>
              <span class="path2"></span>
            </span>
          </span>
          <span class="label" v-if="smoothness.first">
            Compared to the first time
            <br />
            ({{ $moment(smoothness.first.date, "YYYY-MM-DD").format("MMM DD,YYYY") }})
          </span>
        </li>
      </ul>
      <div v-if="isNoData" class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
      <div v-if="error" class="no-data">Error!</div>
    </div>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { getBoardAromSmoothness } from "@/api/report.js";
export default {
  name: "BoardSmoothness",
  components: {
    Loading
  },
  data() {
    return {
      smoothness: null,
      targetSmoothnessLastValue: 0,
      upOrDown: null,
      diff: null,
      isNoData: false,
      loading: false,
      error: false
    };
  },
  props: {
    device: {
      type: Object
    },
    dateRange: {
      type: Object
    },
    toDateRange: {
      type: Object
    },
    isRangeCompare: Boolean
  },
  computed: {
    patient: function() {
      return this.$store.getters.selectedPatient;
    }
  },
  watch: {
    device(newValue, oldValue) {
      this.smoothness = null;
      this.upOrDown = null;
      this.diff = null;
      this.error = false;
      this.isNoData = false;
      this.drawChart();
    },
    dateRange(newValue, oldValue) {
      this.drawChart();
    },
    toDateRange(newValue, oldVAlue) {
      this.drawChart();
    },
    isRangeCompare(newValue, oldValue) {
      this.drawChart();
    }
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    removeNumberMinusSign: function (number) {
      if (number < 0) {
        return `${(number * -1).toFixed(0)}pt`;
      } else if (number === 0) {
        return '-';
      }
      return `${number.toFixed(0)}pt`;
    },
    async drawChart() {
      if (!this.patient || !this.device) return;
      let smoothness;
      let targetSmoothness;
      try {
        this.loading = true;
        smoothness = await getBoardAromSmoothness(
          this.patient.id,
          this.device.id,
          this.$moment(this.dateRange.startDate).toISOString(),
          this.$moment(this.dateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString()
        );
        if (this.isRangeCompare) {
          targetSmoothness = await getBoardAromSmoothness(
            this.patient.id,
            this.device.id,
            this.$moment(this.toDateRange.startDate).toISOString(),
            this.$moment(this.toDateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString()
          );
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
      this.smoothness = smoothness;

      this.isNoData = this.smoothness.first === null &&
                      this.smoothness.last === null;

      if (this.isNoData) {
        this.loading = false;
        return;
      }

      if (this.isRangeCompare) {
        this.targetSmoothnessLastValue = targetSmoothness.last ? targetSmoothness.last.value : 0;
      }

      let firstValue = this.smoothness.first
        ? this.smoothness.first.value * 100.0
        : 0.0;
      let lastValue = this.smoothness.last
        ? this.smoothness.last.value * 100.0
        : 0.0;

      this.diff = Math.abs(lastValue - firstValue).toFixed(0);

      if (lastValue > firstValue) {
        this.upOrDown = "icon-ic_ascending_sort";
      } else if (firstValue > lastValue) {
        this.upOrDown = "icon-ic_descending_sort";
      }

      this.loading = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.smoothness-of-reach {
  .report-contents {
    position: relative;
    overflow: hidden;
    height: 125px;
    box-sizing: border-box;
    .report-item{
      display: flex;
      .in-decrease{
        text-align: center;
        font-size: 13px;
        align-self: center;
        &.increase{
          color: blue;
        }
        &.decrease{
          color: red;
        }
      }
    }
    .no-data,
    .error {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      &.error {
        z-index: 2;
      }
    }
  }
}
</style>
