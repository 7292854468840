var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "Confirm" }, [
    !_vm.confirm
      ? _c("div", { staticClass: "Confirm-contents" }, [
          _c("h1", [
            _vm._m(0),
            _vm._v(
              "\n      " + _vm._s(_vm.$t("common.eventModal.title")) + "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sub-guidance" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("common.eventModal.message")) +
                "\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "event-detail" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("common.eventModal.boxLabel.typeOfEvent")) +
                  ": " +
                  _vm._s(_vm.printType(_vm.type))
              )
            ]),
            _vm._v(" "),
            _vm.eventInfo.type === "THERAPISTLIVE"
              ? _c("span", [_vm._v("Subject: " + _vm._s(_vm.eventInfo.title))])
              : _vm._e(),
            _vm._v(" "),
            _vm.eventInfo.type === "THERAPISTLIVE"
              ? _c("span", [
                  _vm._v("Description: " + _vm._s(_vm.eventInfo.contents))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "detail-time" }, [
              _vm._v(_vm._s(_vm.$t("common.eventModal.boxLabel.time")) + ": ")
            ]),
            _vm._v(" "),
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.printTime(_vm.eventInfo.start, _vm.eventInfo.end)
                )
              }
            }),
            _vm._v(" "),
            _vm.eventInfo.type === "COOPGAME"
              ? _c("span", { staticClass: "detail-game" }, [
                  _vm._v("Game: " + _vm._s(_vm.eventInfo.game.print_title))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.eventInfo.type === "COOPGAME" ||
            _vm.eventInfo.type === "SCREENSHARE" ||
            _vm.eventInfo.type === "VIDEOSESSION"
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("common.eventModal.boxLabel.attendees")) +
                      ": " +
                      _vm._s(_vm.printPatients(_vm.eventInfo.patients))
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.eventInfo.type !== "THERAPISTLIVE"
              ? _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("common.eventModal.boxLabel.message")) +
                      ": " +
                      _vm._s(_vm.eventInfo.contents)
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.eventInfo.type === "THERAPISTLIVE"
              ? _c("span", [
                  _vm._v("URL address: " + _vm._s(_vm.eventInfo.contents_link))
                ])
              : _vm._e()
          ])
        ])
      : _vm.confirm
      ? _c(
          "div",
          {
            staticClass: "Confirm-contents",
            class: _vm.confirm ? "warning" : ""
          },
          [
            _vm.type !== "change_save"
              ? _c("h1", [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.confirm.title))])
                ])
              : _c("h1", [_c("span", [_vm._v(_vm._s(_vm.confirm.title))])]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "confirm-message custom-scrollbar",
                class: _vm.type === "change_save" ? "just-message" : ""
              },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.confirm.message) }
                })
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.confirm
      ? _c("div", { staticClass: "popup-submit-btns" }, [
          _c(
            "button",
            {
              staticClass: "accept-btn",
              attrs: { type: "button" },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v(_vm._s(_vm.$t("common.modal.button.register")))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("common.modal.button.cancel")))]
          )
        ])
      : _vm.confirm
      ? _c(
          "div",
          {
            staticClass: "popup-submit-btns",
            class: _vm.confirm ? "warning" : ""
          },
          [
            _vm.type === "delete"
              ? _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "accept-btn error",
                      attrs: { type: "button" },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.modal.button.delete")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.modal.button.cancel")))]
                  )
                ])
              : _vm.type === "close"
              ? _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "accept-btn error",
                      attrs: { type: "button" },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.modal.button.close")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.modal.button.cancel")))]
                  )
                ])
              : _vm.type === "not-yet" ||
                _vm.type === "validate" ||
                _vm.type === "error"
              ? _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "accept-btn error",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.modal.button.close")))]
                  )
                ])
              : _vm.type === "change_save"
              ? _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "accept-btn",
                      attrs: { type: "button" },
                      on: { click: _vm.handleClose }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.modal.button.close")))]
                  )
                ])
              : _vm.type === "cancel"
              ? _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "accept-btn error",
                      attrs: { type: "button" },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v("Yes")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v("No")]
                  )
                ])
              : _vm.type === "remove"
              ? _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "accept-btn error",
                      attrs: { type: "button" },
                      on: { click: _vm.handleSubmit }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.modal.button.remove")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.modal.button.cancel")))]
                  )
                ])
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_check_circle" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_info_circle" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }