var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "report-section smoothness-of-reach" }, [
    _c("h1", [
      _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.smoothnessOfReach")))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "report-contents" },
      [
        _vm.loading ? _c("Loading") : _vm._e(),
        _vm._v(" "),
        _vm.diff
          ? _c("ul", [
              _vm.smoothness.last
                ? _c("li", [
                    _c("div", { staticClass: "report-item" }, [
                      _c("span", { staticClass: "value" }, [
                        _vm._v(
                          _vm._s(_vm.smoothness.last.value.toFixed(0)) + " pt."
                        )
                      ]),
                      _vm._v(" "),
                      _vm.isRangeCompare
                        ? _c(
                            "div",
                            {
                              staticClass: "in-decrease",
                              class: {
                                decrease:
                                  _vm.smoothness.last.value.toFixed(0) -
                                    _vm.targetSmoothnessLastValue.toFixed(0) <
                                  0,
                                increase:
                                  _vm.smoothness.last.value.toFixed(0) -
                                    _vm.targetSmoothnessLastValue.toFixed(0) >
                                  0
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "arrow",
                                class: {
                                  down:
                                    _vm.smoothness.last.value.toFixed(0) -
                                      _vm.targetSmoothnessLastValue.toFixed(0) <
                                    0,
                                  up:
                                    _vm.smoothness.last.value.toFixed(0) -
                                      _vm.targetSmoothnessLastValue.toFixed(0) >
                                    0
                                }
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.removeNumberMinusSign(
                                      _vm.smoothness.last.value.toFixed(0) -
                                        _vm.targetSmoothnessLastValue.toFixed(0)
                                    )
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm
                              .$moment(_vm.smoothness.last.date, "YYYY-MM-DD")
                              .format("MMM DD,YYYY")
                          ) +
                          "\n        "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "value up" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.diff) + " pt.\n          "
                  ),
                  _c("span", { class: _vm.upOrDown }, [
                    _c("span", { staticClass: "path1" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "path2" })
                  ])
                ]),
                _vm._v(" "),
                _vm.smoothness.first
                  ? _c("span", { staticClass: "label" }, [
                      _vm._v(
                        "\n          Compared to the first time\n          "
                      ),
                      _c("br"),
                      _vm._v(
                        "\n          (" +
                          _vm._s(
                            _vm
                              .$moment(_vm.smoothness.first.date, "YYYY-MM-DD")
                              .format("MMM DD,YYYY")
                          ) +
                          ")\n        "
                      )
                    ])
                  : _vm._e()
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isNoData
          ? _c("div", { staticClass: "no-data" }, [
              _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.empty")))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "no-data" }, [_vm._v("Error!")])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }