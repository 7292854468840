import { render, staticRenderFns } from "./MappingTherapist.vue?vue&type=template&id=0b765a48&scoped=true&"
import script from "./MappingTherapist.vue?vue&type=script&lang=js&"
export * from "./MappingTherapist.vue?vue&type=script&lang=js&"
import style0 from "./MappingTherapist.vue?vue&type=style&index=0&id=0b765a48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b765a48",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0b765a48')) {
      api.createRecord('0b765a48', component.options)
    } else {
      api.reload('0b765a48', component.options)
    }
    module.hot.accept("./MappingTherapist.vue?vue&type=template&id=0b765a48&scoped=true&", function () {
      api.rerender('0b765a48', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/clinic/components/Mapping/components/MappingTherapist.vue"
export default component.exports