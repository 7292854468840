var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "report-section free-exploration",
      staticStyle: { "margin-left": "18px" }
    },
    [
      _c("h1", [_vm._v("Shape Drawing(Counter Clockwise)")]),
      _vm._v(" "),
      _c("div", { staticClass: "report-contents" }, [
        _vm.left && _vm.right
          ? _c("div", { staticClass: "table" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { width: "127" } }),
                    _vm._v(" "),
                    _c("th", { attrs: { width: "40" } }),
                    _vm._v(" "),
                    _c("th", { attrs: { width: "55" } }, [
                      _vm._v("[" + _vm._s(_vm.leftId) + "]")
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { width: "55" } }, [
                      _vm._v("[" + _vm._s(_vm.rightId) + "]")
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { width: "45" } })
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Shape Similarity")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Inner")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.leftData.innerDataCCW
                              ? _vm.leftData.innerDataCCW.pathFollowIndex
                              : 0
                          ) +
                          " %\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.rightData.innerDataCCW
                              ? _vm.rightData.innerDataCCW.pathFollowIndex
                              : 0
                          ) +
                          " %\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.leftData.innerDataCCW
                                ? _vm.leftData.innerDataCCW.pathFollowIndex
                                : 0,
                              _vm.rightData.innerDataCCW
                                ? _vm.rightData.innerDataCCW.pathFollowIndex
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.leftData.innerDataCCW
                                ? _vm.leftData.innerDataCCW.pathFollowIndex
                                : 0,
                              _vm.rightData.innerDataCCW
                                ? _vm.rightData.innerDataCCW.pathFollowIndex
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th"),
                    _vm._v(" "),
                    _c("th", [_vm._v("Outer")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.leftData.outerDataCCW
                              ? _vm.leftData.outerDataCCW.pathFollowIndex
                              : 0
                          ) +
                          " %\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.rightData.outerDataCCW
                              ? _vm.rightData.outerDataCCW.pathFollowIndex
                              : 0
                          ) +
                          " %\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.leftData.outerDataCCW
                                ? _vm.leftData.outerDataCCW.pathFollowIndex
                                : 0,
                              _vm.rightData.outerDataCCW
                                ? _vm.rightData.outerDataCCW.pathFollowIndex
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.leftData.outerDataCCW
                                ? _vm.leftData.outerDataCCW.pathFollowIndex
                                : 0,
                              _vm.rightData.outerDataCCW
                                ? _vm.rightData.outerDataCCW.pathFollowIndex
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Circular Symmetry")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Inner")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.leftData.innerDataCCW
                              ? _vm.leftData.innerDataCCW.circleIndex
                              : 0
                          ) +
                          " %\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.rightData.innerDataCCW
                              ? _vm.rightData.innerDataCCW.circleIndex
                              : 0
                          ) +
                          " %\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.leftData.innerDataCCW
                                ? _vm.leftData.innerDataCCW.circleIndex
                                : 0,
                              _vm.rightData.innerDataCCW
                                ? _vm.rightData.innerDataCCW.circleIndex
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.leftData.innerDataCCW
                                ? _vm.leftData.innerDataCCW.circleIndex
                                : 0,
                              _vm.rightData.innerDataCCW
                                ? _vm.rightData.innerDataCCW.circleIndex
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th"),
                    _vm._v(" "),
                    _c("th", [_vm._v("Outer")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.leftData.outerDataCCW
                              ? _vm.leftData.outerDataCCW.circleIndex
                              : 0
                          ) +
                          " %\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.rightData.outerDataCCW
                              ? _vm.rightData.outerDataCCW.circleIndex
                              : 0
                          ) +
                          " %\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.leftData.outerDataCCW
                                ? _vm.leftData.outerDataCCW.circleIndex
                                : 0,
                              _vm.rightData.outerDataCCW
                                ? _vm.rightData.outerDataCCW.circleIndex
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.leftData.outerDataCCW
                                ? _vm.leftData.outerDataCCW.circleIndex
                                : 0,
                              _vm.rightData.outerDataCCW
                                ? _vm.rightData.outerDataCCW.circleIndex
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Duration")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Inner")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.leftData.innerDataCCW
                              ? _vm.leftData.innerDataCCW.time
                              : 0
                          ) +
                          " secs\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.rightData.innerDataCCW
                              ? _vm.rightData.innerDataCCW.time
                              : 0
                          ) +
                          " secs\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.leftData.innerDataCCW
                                ? _vm.leftData.innerDataCCW.time
                                : 0,
                              _vm.rightData.innerDataCCW
                                ? _vm.rightData.innerDataCCW.time
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.leftData.innerDataCCW
                                ? _vm.leftData.innerDataCCW.time
                                : 0,
                              _vm.rightData.innerDataCCW
                                ? _vm.rightData.innerDataCCW.time
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th"),
                    _vm._v(" "),
                    _c("th", [_vm._v("Outer")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.leftData.outerDataCCW
                              ? _vm.leftData.outerDataCCW.time
                              : 0
                          ) +
                          " secs\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.rightData.outerDataCCW
                              ? _vm.rightData.outerDataCCW.time
                              : 0
                          ) +
                          " secs\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.leftData.outerDataCCW
                                ? _vm.leftData.outerDataCCW.time
                                : 0,
                              _vm.rightData.outerDataCCW
                                ? _vm.rightData.outerDataCCW.time
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.leftData.outerDataCCW
                                ? _vm.leftData.outerDataCCW.time
                                : 0,
                              _vm.rightData.outerDataCCW
                                ? _vm.rightData.outerDataCCW.time
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }