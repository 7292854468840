var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "report-section total-distance-moved" }, [
    _c("h1", [
      _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.totalDistanceMoved")))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "report-contents" },
      [
        _vm.loading ? _c("Loading") : _vm._e(),
        _vm._v(" "),
        _vm.totalDistance && _vm.dateFrom
          ? _c("div", { staticClass: "report-info" }, [
              _c("div", { staticClass: "report-value" }, [
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s((_vm.totalDistance / 100.0).toFixed(2)) + " m")
                ]),
                _vm._v(" "),
                _vm.isRangeCompare
                  ? _c(
                      "div",
                      {
                        staticClass: "in-decrease",
                        class: {
                          decrease:
                            _vm.totalDistance - _vm.targetTotalDistance < 0,
                          increase:
                            _vm.totalDistance - _vm.targetTotalDistance > 0
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "arrow",
                          class: {
                            down:
                              _vm.totalDistance - _vm.targetTotalDistance < 0,
                            up: _vm.totalDistance - _vm.targetTotalDistance > 0
                          }
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.removeNumberMinusSign(
                                (_vm.totalDistance / 100.0).toFixed(2) -
                                  (_vm.targetTotalDistance / 100.0).toFixed(2)
                              )
                            ) +
                            "\n        "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$moment(new Date(_vm.dateFrom)).format("MMM DD,YYYY")
                    ) +
                    " ~\n        " +
                    _vm._s(
                      _vm.$moment(new Date(_vm.dateTo)).format("MMM DD,YYYY")
                    ) +
                    "\n      "
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "graph" },
          [
            !_vm.isNoData
              ? _c("v-chart", {
                  ref: "chartRef",
                  staticStyle: { width: "136px", height: "85px" },
                  attrs: { options: _vm.chartOptions, "auto-resize": true }
                })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _vm.isNoData
          ? _c("div", { staticClass: "no-data" }, [
              _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.empty")))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "no-data" }, [_vm._v("Error!")])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }