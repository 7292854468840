/* eslint-disable no-undef */
import Vue from "vue";
import {
  getPersonalChannelName,
  getMessage,
  getEnvProfile
} from "@/utils/sendbird";
import { getToken } from "@/utils/auth";
import SingletonSendBird from "@/sendbird";

/**
  sendbird가 동작하려면 login 이후에 sendbird가 초기화되어야하는데
  chat vuex는 vue instance가 만들어질때 같이 초기화 되므로 일부 꼬이는 것이 있다.
  초기화하는 시점이 애매할 수 있으니 일단은 sendbird variable은 let으로 assign 가능하게 처리
  ++++++
  sendbird는 현재 컴포넌트생성될때마다 이니셜라이즈되고 있음.
 */
let sendbird;
const sendbirdGroupChannels = {};
const prevMessageListQuerys = {};
const state = {
  isShowChat: false,
  selectedInfo: undefined,
  groupChannels: {},
  chatInfo: "",
  isShowInputWarning: false
};
const mutations = {
  INIT_CHANNELS: (state, channelInfo) => {
    state.groupChannels = [];
    channelInfo.forEach(x => {
      Vue.set(state.groupChannels, x.name, {
        selected: false,
        hasMore: true,
        messages: [],
        unreadMessageCount: 0,
        patient: x.patient,
        didPrev: false,
        lastMessage: "",
        lastMessageCreatedAt: undefined,
        readMessageAt: 0,
        url: null
      });
    });
  },
  INIT_GROUP_CHANNEL: (state, groupChannels) => {
    groupChannels.forEach(x => {
      if (state.groupChannels[x.name]) {
        state.groupChannels[x.name].unreadMessageCount = x.unreadMessageCount;
        state.groupChannels[x.name].lastMessage = x.lastMessage;
        state.groupChannels[x.name].lastMessageCreatedAt =
          x.lastMessageCreatedAt;
        state.groupChannels[x.name].readMessageAt = 0;
      }
    });
  },
  SEND_FAILED_MESSAGE: state => {
    state.chatInfo = "Sending Failed";
    state.isShowInputWarning = true;
  },
  RESET_FAILED_MESSAGE: state => {
    state.chatInfo = "";
    state.isShowInputWarning = false;
  },
  SELECT_CHANNEL: (state, channelName) => {
    Object.values(state.groupChannels)
      .filter(x => x.selected)
      .forEach(x => (x.selected = false));
    state.groupChannels[channelName].selected = true;
    state.groupChannels[channelName].unreadMessageCount = 0;
  },
  NEW_MESSAGE: (state, { channelName, message }) => {
    if (!state.groupChannels[channelName]) {
      return;
    }

    if (!state.selectedInfo || state.selectedInfo.channelName !== channelName) {
      state.groupChannels[channelName].unreadMessageCount =
        state.groupChannels[channelName].unreadMessageCount + 1;
    } else if (state.groupChannels[channelName].didPrev) {
      // prev를 한번이라고 한 경우 push
      state.groupChannels[channelName].messages.push(message);
      Vue.nextTick(function() {
        const scrollDiv = document.querySelector(
          ".chat-room .message-log.custom-scrollbar"
        );
        scrollDiv.scrollTop = scrollDiv.scrollHeight;
      });
    }
    // 사용자가 새로운 메시지 방에 없고 다른 방에 있을 경우 메시지를 받으면 해당 방의 스토어에 추가 시켜준다. 단 기존에 해당 메시지가 없을때
    if (state.groupChannels[channelName].messages.find(item => item.id === message.id) === undefined &&
      state.groupChannels[channelName].didPrev
    ) {
      state.groupChannels[channelName].messages.push(...[message]);
    }
    state.groupChannels[channelName].lastMessage = message.message;
    state.groupChannels[channelName].lastMessageCreatedAt = message.createdAt;
  },
  PREV_MESSAGES: (state, { channelName, messages }) => {
    state.groupChannels[channelName].messages.unshift(...messages);
  },
  OPEN_CHAT: state => {
    state.isShowChat = true;
  },
  CLOSE_CHAT: state => {
    state.isShowChat = false;
    state.selectedInfo = undefined;
  },
  START_TYPING: state => {
    sendbirdGroupChannels[state.selectedInfo.channelName].startTyping();
  },
  END_TYPING: state => {
    sendbirdGroupChannels[state.selectedInfo.channelName].endTyping();
  },
  SET_CHAT_INFO: (state, chatInfo) => {
    state.chatInfo = chatInfo;
  },
  SET_GROUPCHANNEL_PATIENT_UNREAD_TO_ZERO: (
    state,
    { channelName, readMessageAtArr }
  ) => {
    if (state.groupChannels[channelName]) {
      const patientId = state.groupChannels[channelName].patient.user_id;
      state.groupChannels[channelName].readMessageAt =
        readMessageAtArr[patientId];
    }
  }
};

const actions = {
  async initSendBird() {
    // var first = await SingletonSendBird.getInstance();
    // var second = await SingletonSendBird.getInstance();
    // console.log(first === second);
    sendbird = await SingletonSendBird.getInstance();
  },
  async initForceSendBird() {
    sendbird = await SingletonSendBird.getForceInstance();
  },
  toggleTextChat({ commit }) {
    if (state.isShowChat) {
      commit("CLOSE_CHAT");
    } else {
      commit("OPEN_CHAT");
    }
  },
  setChatInfo({ commit }, text) {
    commit("SET_CHAT_INFO", text);
  },
  resetWarning({ commit }) {
    commit("RESET_FAILED_MESSAGE");
  },
  closeTextChat({ commit }) {
    commit("CLOSE_CHAT");
  },
  startTyping({ commit }) {
    commit("START_TYPING");
  },
  endTyping({ commit }) {
    commit("END_TYPING");
  },
  enterGroupChannel({ commit }, { patientId, name, image }) {
    if (!state.isShowChat) {
      commit("OPEN_CHAT");
    }
    const userId = getToken().user_id;
    const channelName = getPersonalChannelName(patientId);
    if (!state.groupChannels[channelName]) {
      // 환자가 채팅목록에 없는 경우 예외처리
      return;
    }
    state.selectedInfo = {
      patientId: patientId,
      channelName: channelName,
      name: name,
      image: image
    };
    state.selectedInfo.userId = `${patientId}`;
    commit("SELECT_CHANNEL", channelName);
    if (sendbirdGroupChannels[channelName] === undefined && patientId !== 0) {
      const userIds = [`${userId}`, `${patientId}`].sort();
      var params = new sendbird.GroupChannelParams();
      params.isPublic = false;
      params.isDistinct = true;
      params.addUserIds(userIds);
      params.name = channelName;
      params.customType = getEnvProfile();
      sendbird.GroupChannel.createChannel(params, function(
        groupChannel,
        error
      ) {
        if (error) {
          console.error("fail createChannel", e);
          return;
        }

        state.groupChannels[channelName].readMessageAt =
          groupChannel.cachedReadReceiptStatus[patientId];
        sendbirdGroupChannels[channelName] = groupChannel;
        state.groupChannels[channelName].url = groupChannel.url;
        // groupChannel.markAsRead();

        // prev messages
        const prevMessageListQuery = sendbirdGroupChannels[
          channelName
        ].createPreviousMessageListQuery();
        prevMessageListQuery.limit = 30;
        prevMessageListQuery.reverse = false;

        let isFirst = true;
        // sendbird prevMessageListQuery sdk는 load이후에 반복으로 호출하면 자동으로 페이징을 한다.
        const f = function() {
          NProgress.configure({
            showSpinner: true,
            parent: ".chat-room .message-log.custom-scrollbar"
          });

          NProgress.start();
          const scrollDiv = document.querySelector(
            ".chat-room .message-log.custom-scrollbar"
          );
          const beforeHeight = scrollDiv.scrollHeight;
          prevMessageListQuery.load((list, error) => {
            if (error) {
              console.error("prev messages retrieve fail.", error);
              return;
            }
            const messages = list.map(x => getMessage(x));
            if (list.length > 0) { commit("PREV_MESSAGES", { channelName, messages }); } else state.groupChannels[channelName].hasMore = false;
            state.groupChannels[channelName].didPrev = true;
            NProgress.done();
            Vue.nextTick(function() {
              if (isFirst) {
                scrollDiv.scrollTop = scrollDiv.scrollHeight;
                isFirst = false;
              } else {
                scrollDiv.scrollTop = scrollDiv.scrollHeight - beforeHeight;
              }
              NProgress.configure({ showSpinner: false, parent: "body" });
            });
          });
        };
        prevMessageListQuerys[channelName] = f;
        f();
        groupChannel.markAsRead();
      });
    } else {
      sendbird.GroupChannel.getChannel(state.groupChannels[channelName].url, function (groupChannel, error) {
        if (error) {
          console.error("fail getChannel", e);
          return;
        }
        groupChannel.markAsRead();
      });
      Vue.nextTick(function() {
        const scrollDiv = document.querySelector(
          ".chat-room .message-log.custom-scrollbar"
        );
        scrollDiv.scrollTop = scrollDiv.scrollHeight;
      });
    }
  },
  sendMessage({ commit }, message) {
    // state.chatInfo = "Sending...";
    const params = new sendbird.UserMessageParams();
    params.message = message;
    params.mentionType = "channel";
    params.pushNotificationDeliveryOption = "default";
    params.data = "|from_therapist|";
    const channelName = state.selectedInfo.channelName;
    if (!navigator.onLine) {
      commit("SEND_FAILED_MESSAGE");
      return new Promise((resolve, reject) => {
        resolve(false);
      });
    }
    return new Promise((resolve, reject) => {
      sendbirdGroupChannels[channelName].sendUserMessage(
        params,
        (message, error) => {
          if (error) {
            console.error("sending failed", error);
            commit("SEND_FAILED_MESSAGE");
            return resolve(false);
          }
          commit("NEW_MESSAGE", {
            channelName: channelName,
            message: getMessage(message)
          });
          Vue.nextTick(function() {
            const scrollDiv = document.querySelector(
              ".chat-room .message-log.custom-scrollbar"
            );
            scrollDiv.scrollTop = scrollDiv.scrollHeight;
          });
          setTimeout(() => {
            state.chatInfo = "";
          }, 300);
          resolve(true);
        }
      );
    });
  },
  prevMessages() {
    const channelName = state.selectedInfo.channelName;
    prevMessageListQuerys[channelName]();
  }
};

const getters = {
  patients: state =>
    Object.values(state.groupChannels)
      .map(x => ({
        name: x.patient.name,
        user_id: x.patient.user_id,
        image: x.patient.image,
        unreadMessageCount: x.unreadMessageCount,
        lastMessage: x.lastMessage,
        lastMessageCreatedAt: x.lastMessageCreatedAt || 0
      }))
      .sort((a, b) => b.lastMessageCreatedAt - a.lastMessageCreatedAt),
  selectedGroupChannel: state =>
    state.groupChannels[state.selectedInfo.channelName],
  unreadMessageCount: state =>
    Object.values(state.groupChannels)
      .map(x => x.unreadMessageCount)
      .reduce((a, b) => a + b, 0),
  selectedInfo: state => state.selectedInfo,
  isShowChat: state => state.isShowChat,
  chatInfo: state => state.chatInfo,
  isShowInputWarning: state => state.isShowInputWarning
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
