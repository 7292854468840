import { setLanguage } from "../../utils/cookies";
import { getLocale } from "@/lang";

const state = {
  language: getLocale()
};

const mutations = {
  SET_LANGUAGE: (state, lang) => {
    state.language = lang;
  }
};

const actions = {
  setLanguage({ commit }, lang) {
    commit("SET_LANGUAGE", lang);
    setLanguage(lang);
  }
};

const getters = {
  language: state => {
    return state.language;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
