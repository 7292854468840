var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "report-section evaluation-score" }, [
    _c("div", { staticClass: "evaluation" }, [
      _c("h1", [
        _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.evaluationRecord")))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "report-contents" },
        [
          _vm.loading ? _c("Loading") : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "select" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.leftSelected,
                    expression: "leftSelected"
                  }
                ],
                staticClass: "select-1 select-type-2 left",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.leftSelected = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.evalList, function(ev) {
                return _c("option", { key: ev.id, domProps: { value: ev } }, [
                  _vm._v(
                    "\n            [" +
                      _vm._s(ev.id) +
                      "] " +
                      _vm._s(ev.date) +
                      "\n          "
                  )
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.rightSelected,
                    expression: "rightSelected"
                  }
                ],
                staticClass: "select-2 select-type-2 left",
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.rightSelected = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.evalList, function(ev) {
                return _c("option", { key: ev.id, domProps: { value: ev } }, [
                  _vm._v(
                    "\n            [" +
                      _vm._s(ev.id) +
                      "] " +
                      _vm._s(ev.date) +
                      "\n          "
                  )
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "contents" }, [
            _vm.leftDetail && _vm.rightDetail
              ? _c("div", { staticClass: "table" }, [
                  _c("table", [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { attrs: { colspan: "2" } }),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v("[" + _vm._s(_vm.leftSelected.id) + "]")
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v("[" + _vm._s(_vm.rightSelected.id) + "]")
                        ]),
                        _vm._v(" "),
                        _c("th")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      [
                        _c("tr", [
                          _c("th", [_vm._v("Forearm")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("Pron.")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  (
                                    -1.0 *
                                    _vm.leftDetail.arom.FOREARM_SUP_PRONATION
                                      .min
                                  ).toFixed(1)
                                ) +
                                "°\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  (
                                    -1.0 *
                                    _vm.rightDetail.arom.FOREARM_SUP_PRONATION
                                      .min
                                  ).toFixed(1)
                                ) +
                                "°\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.diffLeftRight(
                                    (
                                      -1.0 *
                                      _vm.leftDetail.arom.FOREARM_SUP_PRONATION
                                        .min
                                    ).toFixed(1),
                                    (
                                      -1.0 *
                                      _vm.rightDetail.arom.FOREARM_SUP_PRONATION
                                        .min
                                    ).toFixed(1)
                                  )
                                ) +
                                "\n                "
                            ),
                            _c("img", {
                              attrs: {
                                src:
                                  "/img/icons/" +
                                  _vm.upDownIcon(
                                    (
                                      -1.0 *
                                      _vm.leftDetail.arom.FOREARM_SUP_PRONATION
                                        .min
                                    ).toFixed(1),
                                    (
                                      -1.0 *
                                      _vm.rightDetail.arom.FOREARM_SUP_PRONATION
                                        .min
                                    ).toFixed(1)
                                  ),
                                width: "10",
                                height: "10"
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th"),
                          _vm._v(" "),
                          _c("th", [_vm._v("Sup.")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.leftDetail.arom.FOREARM_SUP_PRONATION.max.toFixed(
                                    1
                                  )
                                ) +
                                "°\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.rightDetail.arom.FOREARM_SUP_PRONATION.max.toFixed(
                                    1
                                  )
                                ) +
                                "°\n              "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.diffLeftRight(
                                    _vm.leftDetail.arom.FOREARM_SUP_PRONATION.max.toFixed(
                                      1
                                    ),
                                    _vm.rightDetail.arom.FOREARM_SUP_PRONATION.max.toFixed(
                                      1
                                    )
                                  )
                                ) +
                                "\n                "
                            ),
                            _c("img", {
                              attrs: {
                                src:
                                  "/img/icons/" +
                                  _vm.upDownIcon(
                                    _vm.leftDetail.arom.FOREARM_SUP_PRONATION.max.toFixed(
                                      1
                                    ),
                                    _vm.rightDetail.arom.FOREARM_SUP_PRONATION.max.toFixed(
                                      1
                                    )
                                  ),
                                width: "10",
                                height: "10"
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _vm.leftDetail.arom.FINGER_THUMB_FLEX_EXT
                          ? [
                              _c("tr", [
                                _c("th", [_vm._v("Finger")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("All Digit")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          ((_vm.leftDetail.arom
                                            .FINGER_THUMB_FLEX_EXT.max -
                                            _vm.leftDetail.arom
                                              .FINGER_THUMB_FLEX_EXT.min +
                                            _vm.leftDetail.arom
                                              .FINGER_INDEX_FLEX_EXT.max -
                                            _vm.leftDetail.arom
                                              .FINGER_INDEX_FLEX_EXT.min +
                                            _vm.leftDetail.arom
                                              .FINGER_MIDDLE_FLEX_EXT.max -
                                            _vm.leftDetail.arom
                                              .FINGER_MIDDLE_FLEX_EXT.min +
                                            _vm.leftDetail.arom
                                              .FINGER_RING_FLEX_EXT.max -
                                            _vm.leftDetail.arom
                                              .FINGER_RING_FLEX_EXT.min +
                                            _vm.leftDetail.arom
                                              .FINGER_LITTLE_FLEX_EXT.max -
                                            _vm.leftDetail.arom
                                              .FINGER_LITTLE_FLEX_EXT.min) *
                                            100.0) /
                                          5.0
                                        ).toFixed(1)
                                      ) +
                                      "%\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          ((_vm.rightDetail.arom
                                            .FINGER_THUMB_FLEX_EXT.max -
                                            _vm.rightDetail.arom
                                              .FINGER_THUMB_FLEX_EXT.min +
                                            _vm.rightDetail.arom
                                              .FINGER_INDEX_FLEX_EXT.max -
                                            _vm.rightDetail.arom
                                              .FINGER_INDEX_FLEX_EXT.min +
                                            _vm.rightDetail.arom
                                              .FINGER_MIDDLE_FLEX_EXT.max -
                                            _vm.rightDetail.arom
                                              .FINGER_MIDDLE_FLEX_EXT.min +
                                            _vm.rightDetail.arom
                                              .FINGER_RING_FLEX_EXT.max -
                                            _vm.rightDetail.arom
                                              .FINGER_RING_FLEX_EXT.min +
                                            _vm.rightDetail.arom
                                              .FINGER_LITTLE_FLEX_EXT.max -
                                            _vm.rightDetail.arom
                                              .FINGER_LITTLE_FLEX_EXT.min) *
                                            100.0) /
                                          5.0
                                        ).toFixed(1)
                                      ) +
                                      "%\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.diffLeftRight(
                                          (
                                            ((_vm.leftDetail.arom
                                              .FINGER_THUMB_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_THUMB_FLEX_EXT.min +
                                              _vm.leftDetail.arom
                                                .FINGER_INDEX_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_INDEX_FLEX_EXT.min +
                                              _vm.leftDetail.arom
                                                .FINGER_MIDDLE_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_MIDDLE_FLEX_EXT.min +
                                              _vm.leftDetail.arom
                                                .FINGER_RING_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_RING_FLEX_EXT.min +
                                              _vm.leftDetail.arom
                                                .FINGER_LITTLE_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_LITTLE_FLEX_EXT.min) *
                                              100.0) /
                                            5.0
                                          ).toFixed(1),
                                          (
                                            ((_vm.rightDetail.arom
                                              .FINGER_THUMB_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_THUMB_FLEX_EXT.min +
                                              _vm.rightDetail.arom
                                                .FINGER_INDEX_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_INDEX_FLEX_EXT.min +
                                              _vm.rightDetail.arom
                                                .FINGER_MIDDLE_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_MIDDLE_FLEX_EXT.min +
                                              _vm.rightDetail.arom
                                                .FINGER_RING_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_RING_FLEX_EXT.min +
                                              _vm.rightDetail.arom
                                                .FINGER_LITTLE_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_LITTLE_FLEX_EXT.min) *
                                              100.0) /
                                            5.0
                                          ).toFixed(1)
                                        )
                                      ) +
                                      "\n                  "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/img/icons/" +
                                        _vm.upDownIcon(
                                          (
                                            ((_vm.leftDetail.arom
                                              .FINGER_THUMB_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_THUMB_FLEX_EXT.min +
                                              _vm.leftDetail.arom
                                                .FINGER_INDEX_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_INDEX_FLEX_EXT.min +
                                              _vm.leftDetail.arom
                                                .FINGER_MIDDLE_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_MIDDLE_FLEX_EXT.min +
                                              _vm.leftDetail.arom
                                                .FINGER_RING_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_RING_FLEX_EXT.min +
                                              _vm.leftDetail.arom
                                                .FINGER_LITTLE_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_LITTLE_FLEX_EXT.min) *
                                              100.0) /
                                            5.0
                                          ).toFixed(1),
                                          (
                                            ((_vm.rightDetail.arom
                                              .FINGER_THUMB_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_THUMB_FLEX_EXT.min +
                                              _vm.rightDetail.arom
                                                .FINGER_INDEX_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_INDEX_FLEX_EXT.min +
                                              _vm.rightDetail.arom
                                                .FINGER_MIDDLE_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_MIDDLE_FLEX_EXT.min +
                                              _vm.rightDetail.arom
                                                .FINGER_RING_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_RING_FLEX_EXT.min +
                                              _vm.rightDetail.arom
                                                .FINGER_LITTLE_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_LITTLE_FLEX_EXT.min) *
                                              100.0) /
                                            5.0
                                          ).toFixed(1)
                                        ),
                                      width: "10",
                                      height: "10"
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th"),
                                _vm._v(" "),
                                _c("th", [_vm._v("Thumb")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          (_vm.leftDetail.arom
                                            .FINGER_THUMB_FLEX_EXT.max -
                                            _vm.leftDetail.arom
                                              .FINGER_THUMB_FLEX_EXT.min) *
                                          100.0
                                        ).toFixed(1)
                                      ) +
                                      "%\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          (_vm.rightDetail.arom
                                            .FINGER_THUMB_FLEX_EXT.max -
                                            _vm.rightDetail.arom
                                              .FINGER_THUMB_FLEX_EXT.min) *
                                          100.0
                                        ).toFixed(1)
                                      ) +
                                      "%\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.diffLeftRight(
                                          (
                                            (_vm.leftDetail.arom
                                              .FINGER_THUMB_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_THUMB_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1),
                                          (
                                            (_vm.rightDetail.arom
                                              .FINGER_THUMB_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_THUMB_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1)
                                        )
                                      ) +
                                      "\n                  "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/img/icons/" +
                                        _vm.upDownIcon(
                                          (
                                            (_vm.leftDetail.arom
                                              .FINGER_THUMB_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_THUMB_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1),
                                          (
                                            (_vm.rightDetail.arom
                                              .FINGER_THUMB_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_THUMB_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1)
                                        ),
                                      width: "10",
                                      height: "10"
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th"),
                                _vm._v(" "),
                                _c("th", [_vm._v("Index")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          (_vm.leftDetail.arom
                                            .FINGER_INDEX_FLEX_EXT.max -
                                            _vm.leftDetail.arom
                                              .FINGER_INDEX_FLEX_EXT.min) *
                                          100.0
                                        ).toFixed(1)
                                      ) +
                                      "%\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          (_vm.rightDetail.arom
                                            .FINGER_INDEX_FLEX_EXT.max -
                                            _vm.rightDetail.arom
                                              .FINGER_INDEX_FLEX_EXT.min) *
                                          100.0
                                        ).toFixed(1)
                                      ) +
                                      "%\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.diffLeftRight(
                                          (
                                            (_vm.leftDetail.arom
                                              .FINGER_INDEX_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_INDEX_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1),
                                          (
                                            (_vm.rightDetail.arom
                                              .FINGER_INDEX_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_INDEX_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1)
                                        )
                                      ) +
                                      "\n                  "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/img/icons/" +
                                        _vm.upDownIcon(
                                          (
                                            (_vm.leftDetail.arom
                                              .FINGER_INDEX_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_INDEX_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1),
                                          (
                                            (_vm.rightDetail.arom
                                              .FINGER_INDEX_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_INDEX_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1)
                                        ),
                                      width: "10",
                                      height: "10"
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th"),
                                _vm._v(" "),
                                _c("th", [_vm._v("Middle")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          (_vm.leftDetail.arom
                                            .FINGER_MIDDLE_FLEX_EXT.max -
                                            _vm.leftDetail.arom
                                              .FINGER_MIDDLE_FLEX_EXT.min) *
                                          100.0
                                        ).toFixed(1)
                                      ) +
                                      "%\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          (_vm.rightDetail.arom
                                            .FINGER_MIDDLE_FLEX_EXT.max -
                                            _vm.rightDetail.arom
                                              .FINGER_MIDDLE_FLEX_EXT.min) *
                                          100.0
                                        ).toFixed(1)
                                      ) +
                                      "%\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.diffLeftRight(
                                          (
                                            (_vm.leftDetail.arom
                                              .FINGER_MIDDLE_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_MIDDLE_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1),
                                          (
                                            (_vm.rightDetail.arom
                                              .FINGER_MIDDLE_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_MIDDLE_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1)
                                        )
                                      ) +
                                      "\n                  "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/img/icons/" +
                                        _vm.upDownIcon(
                                          (
                                            (_vm.leftDetail.arom
                                              .FINGER_MIDDLE_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_MIDDLE_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1),
                                          (
                                            (_vm.rightDetail.arom
                                              .FINGER_MIDDLE_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_MIDDLE_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1)
                                        ),
                                      width: "10",
                                      height: "10"
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th"),
                                _vm._v(" "),
                                _c("th", [_vm._v("Ring")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          (_vm.leftDetail.arom
                                            .FINGER_RING_FLEX_EXT.max -
                                            _vm.leftDetail.arom
                                              .FINGER_RING_FLEX_EXT.min) *
                                          100.0
                                        ).toFixed(1)
                                      ) +
                                      "%\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          (_vm.rightDetail.arom
                                            .FINGER_RING_FLEX_EXT.max -
                                            _vm.rightDetail.arom
                                              .FINGER_RING_FLEX_EXT.min) *
                                          100.0
                                        ).toFixed(1)
                                      ) +
                                      "%\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.diffLeftRight(
                                          (
                                            (_vm.leftDetail.arom
                                              .FINGER_RING_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_RING_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1),
                                          (
                                            (_vm.rightDetail.arom
                                              .FINGER_RING_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_RING_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1)
                                        )
                                      ) +
                                      "\n                  "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/img/icons/" +
                                        _vm.upDownIcon(
                                          (
                                            (_vm.leftDetail.arom
                                              .FINGER_RING_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_RING_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1),
                                          (
                                            (_vm.rightDetail.arom
                                              .FINGER_RING_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_RING_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1)
                                        ),
                                      width: "10",
                                      height: "10"
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th"),
                                _vm._v(" "),
                                _c("th", [_vm._v("Little")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          (_vm.leftDetail.arom
                                            .FINGER_LITTLE_FLEX_EXT.max -
                                            _vm.leftDetail.arom
                                              .FINGER_LITTLE_FLEX_EXT.min) *
                                          100.0
                                        ).toFixed(1)
                                      ) +
                                      "%\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          (_vm.rightDetail.arom
                                            .FINGER_LITTLE_FLEX_EXT.max -
                                            _vm.rightDetail.arom
                                              .FINGER_LITTLE_FLEX_EXT.min) *
                                          100.0
                                        ).toFixed(1)
                                      ) +
                                      "%\n                "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.diffLeftRight(
                                          (
                                            (_vm.leftDetail.arom
                                              .FINGER_LITTLE_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_LITTLE_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1),
                                          (
                                            (_vm.rightDetail.arom
                                              .FINGER_LITTLE_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_LITTLE_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1)
                                        )
                                      ) +
                                      "\n                  "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/img/icons/" +
                                        _vm.upDownIcon(
                                          (
                                            (_vm.leftDetail.arom
                                              .FINGER_LITTLE_FLEX_EXT.max -
                                              _vm.leftDetail.arom
                                                .FINGER_LITTLE_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1),
                                          (
                                            (_vm.rightDetail.arom
                                              .FINGER_LITTLE_FLEX_EXT.max -
                                              _vm.rightDetail.arom
                                                .FINGER_LITTLE_FLEX_EXT.min) *
                                            100.0
                                          ).toFixed(1)
                                        ),
                                      width: "10",
                                      height: "10"
                                    }
                                  })
                                ])
                              ])
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.leftDetail && _vm.rightDetail
              ? _c("div", { staticClass: "table" }, [
                  _c("table", [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { attrs: { colspan: "2" } }),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v("[" + _vm._s(_vm.leftSelected.id) + "]")
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _vm._v("[" + _vm._s(_vm.rightSelected.id) + "]")
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("Wrist")]),
                        _vm._v(" "),
                        _c("td", [_vm._v("R.D.")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.leftDetail.arom.WRIST_DEVIATION_AG.max.toFixed(
                                  1
                                )
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.rightDetail.arom.WRIST_DEVIATION_AG.max.toFixed(
                                  1
                                )
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.diffLeftRight(
                                  _vm.leftDetail.arom.WRIST_DEVIATION_AG.max.toFixed(
                                    1
                                  ),
                                  _vm.rightDetail.arom.WRIST_DEVIATION_AG.max.toFixed(
                                    1
                                  )
                                )
                              ) +
                              "\n                "
                          ),
                          _c("img", {
                            attrs: {
                              src:
                                "/img/icons/" +
                                _vm.upDownIcon(
                                  _vm.leftDetail.arom.WRIST_DEVIATION_AG.max.toFixed(
                                    1
                                  ),
                                  _vm.rightDetail.arom.WRIST_DEVIATION_AG.max.toFixed(
                                    1
                                  )
                                ),
                              width: "10",
                              height: "10"
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td"),
                        _vm._v(" "),
                        _c("td", [_vm._v("U.D.")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                (
                                  -1.0 *
                                  _vm.leftDetail.arom.WRIST_DEVIATION_AG.min
                                ).toFixed(1)
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                (
                                  -1.0 *
                                  _vm.rightDetail.arom.WRIST_DEVIATION_AG.min
                                ).toFixed(1)
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.diffLeftRight(
                                  (
                                    -1.0 *
                                    _vm.leftDetail.arom.WRIST_DEVIATION_AG.min
                                  ).toFixed(1),
                                  (
                                    -1.0 *
                                    _vm.rightDetail.arom.WRIST_DEVIATION_AG.min
                                  ).toFixed(1)
                                )
                              ) +
                              "\n                "
                          ),
                          _c("img", {
                            attrs: {
                              src:
                                "/img/icons/" +
                                _vm.upDownIcon(
                                  (
                                    -1.0 *
                                    _vm.leftDetail.arom.WRIST_DEVIATION_AG.min
                                  ).toFixed(1),
                                  (
                                    -1.0 *
                                    _vm.rightDetail.arom.WRIST_DEVIATION_AG.min
                                  ).toFixed(1)
                                ),
                              width: "10",
                              height: "10"
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td"),
                        _vm._v(" "),
                        _c("td", [_vm._v("R.D.(G.E.)")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.leftDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(
                                  1
                                )
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.rightDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(
                                  1
                                )
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.diffLeftRight(
                                  _vm.leftDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(
                                    1
                                  ),
                                  _vm.rightDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(
                                    1
                                  )
                                )
                              ) +
                              "\n                "
                          ),
                          _c("img", {
                            attrs: {
                              src:
                                "/img/icons/" +
                                _vm.upDownIcon(
                                  _vm.leftDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(
                                    1
                                  ),
                                  _vm.rightDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(
                                    1
                                  )
                                ),
                              width: "10",
                              height: "10"
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td"),
                        _vm._v(" "),
                        _c("td", [_vm._v("U.D.(G.E.)")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                (
                                  -1.0 *
                                  _vm.leftDetail.arom.WRIST_FLEX_EXT_EG.min
                                ).toFixed(1)
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                (
                                  -1.0 *
                                  _vm.rightDetail.arom.WRIST_FLEX_EXT_EG.min
                                ).toFixed(1)
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.diffLeftRight(
                                  (
                                    -1.0 *
                                    _vm.leftDetail.arom.WRIST_FLEX_EXT_EG.min
                                  ).toFixed(1),
                                  (
                                    -1.0 *
                                    _vm.rightDetail.arom.WRIST_FLEX_EXT_EG.min
                                  ).toFixed(1)
                                )
                              ) +
                              "\n                "
                          ),
                          _c("img", {
                            attrs: {
                              src:
                                "/img/icons/" +
                                _vm.upDownIcon(
                                  (
                                    -1.0 *
                                    _vm.leftDetail.arom.WRIST_FLEX_EXT_EG.min
                                  ).toFixed(1),
                                  (
                                    -1.0 *
                                    _vm.rightDetail.arom.WRIST_FLEX_EXT_EG.min
                                  ).toFixed(1)
                                ),
                              width: "10",
                              height: "10"
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td"),
                        _vm._v(" "),
                        _c("td", [_vm._v("Flex.")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.leftDetail.arom.WRIST_FLEX_EXT_AG.max.toFixed(
                                  1
                                )
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.rightDetail.arom.WRIST_FLEX_EXT_AG.max.toFixed(
                                  1
                                )
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.diffLeftRight(
                                  _vm.leftDetail.arom.WRIST_FLEX_EXT_AG.max.toFixed(
                                    1
                                  ),
                                  _vm.rightDetail.arom.WRIST_FLEX_EXT_AG.max.toFixed(
                                    1
                                  )
                                )
                              ) +
                              "\n                "
                          ),
                          _c("img", {
                            attrs: {
                              src:
                                "/img/icons/" +
                                _vm.upDownIcon(
                                  _vm.leftDetail.arom.WRIST_FLEX_EXT_AG.max.toFixed(
                                    1
                                  ),
                                  _vm.rightDetail.arom.WRIST_FLEX_EXT_AG.max.toFixed(
                                    1
                                  )
                                ),
                              width: "10",
                              height: "10"
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td"),
                        _vm._v(" "),
                        _c("td", [_vm._v("Ext.")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                (
                                  -1.0 *
                                  _vm.leftDetail.arom.WRIST_FLEX_EXT_AG.min
                                ).toFixed(1)
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                (
                                  -1.0 *
                                  _vm.rightDetail.arom.WRIST_FLEX_EXT_AG.min
                                ).toFixed(1)
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.diffLeftRight(
                                  (
                                    -1.0 *
                                    _vm.leftDetail.arom.WRIST_FLEX_EXT_AG.min
                                  ).toFixed(1),
                                  (
                                    -1.0 *
                                    _vm.rightDetail.arom.WRIST_FLEX_EXT_AG.min
                                  ).toFixed(1)
                                )
                              ) +
                              "\n                "
                          ),
                          _c("img", {
                            attrs: {
                              src:
                                "/img/icons/" +
                                _vm.upDownIcon(
                                  (
                                    -1.0 *
                                    _vm.leftDetail.arom.WRIST_FLEX_EXT_AG.min
                                  ).toFixed(1),
                                  (
                                    -1.0 *
                                    _vm.rightDetail.arom.WRIST_FLEX_EXT_AG.min
                                  ).toFixed(1)
                                ),
                              width: "10",
                              height: "10"
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td"),
                        _vm._v(" "),
                        _c("td", [_vm._v("Flex.(G.E.)")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.leftDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(
                                  1
                                )
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.rightDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(
                                  1
                                )
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.diffLeftRight(
                                  _vm.leftDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(
                                    1
                                  ),
                                  _vm.rightDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(
                                    1
                                  )
                                )
                              ) +
                              "\n                "
                          ),
                          _c("img", {
                            attrs: {
                              src:
                                "/img/icons/" +
                                _vm.upDownIcon(
                                  _vm.leftDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(
                                    1
                                  ),
                                  _vm.rightDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(
                                    1
                                  )
                                ),
                              width: "10",
                              height: "10"
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td"),
                        _vm._v(" "),
                        _c("td", [_vm._v("Ext.(G.E.)")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                (
                                  -1.0 *
                                  _vm.leftDetail.arom.WRIST_FLEX_EXT_EG.min
                                ).toFixed(1)
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                (
                                  -1.0 *
                                  _vm.rightDetail.arom.WRIST_FLEX_EXT_EG.min
                                ).toFixed(1)
                              ) +
                              "°\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.diffLeftRight(
                                  (
                                    -1.0 *
                                    _vm.leftDetail.arom.WRIST_FLEX_EXT_EG.min
                                  ).toFixed(1),
                                  (
                                    -1.0 *
                                    _vm.rightDetail.arom.WRIST_FLEX_EXT_EG.min
                                  ).toFixed(1)
                                )
                              ) +
                              "\n                "
                          ),
                          _c("img", {
                            attrs: {
                              src:
                                "/img/icons/" +
                                _vm.upDownIcon(
                                  (
                                    -1.0 *
                                    _vm.leftDetail.arom.WRIST_FLEX_EXT_EG.min
                                  ).toFixed(1),
                                  (
                                    -1.0 *
                                    _vm.rightDetail.arom.WRIST_FLEX_EXT_EG.min
                                  ).toFixed(1)
                                ),
                              width: "10",
                              height: "10"
                            }
                          })
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.isNoData
            ? _c("div", { staticClass: "no-data" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("page.patient.neofectHome.data.empty")) +
                    "\n      "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.error
            ? _c("div", { staticClass: "error" }, [_vm._v("Error!")])
            : _vm._e()
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "range-of-motion" }, [
      _c("h1", [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("page.patient.neofectHome.data.rangeOfMotion")) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "report-contents" }, [
        _c(
          "div",
          { staticClass: "graph" },
          [
            _vm.loading ? _c("Loading") : _vm._e(),
            _vm._v(" "),
            !_vm.isNoData
              ? _c("v-chart", {
                  ref: "chartRef",
                  staticStyle: { width: "100%", height: "500px" },
                  attrs: { options: _vm.chartOptions, "auto-resize": true }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isNoData
              ? _c("div", { staticClass: "no-data" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("page.patient.neofectHome.data.empty")) +
                      "\n        "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.error
              ? _c("div", { staticClass: "error" }, [_vm._v("Error!")])
              : _vm._e()
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }