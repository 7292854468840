var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "EditName" }, [
    _c("section", [
      _c("div", { staticClass: "contents" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("page.profile.editName.editName")))]),
        _vm._v(" "),
        _c("div", { staticClass: "name" }, [
          _c("label", { attrs: { for: "name" } }, [
            _vm._v(_vm._s(_vm.$t("page.profile.editName.name")))
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            attrs: {
              id: "name",
              placeholder: _vm.inputPlaceholder,
              type: "text"
            },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          this.name
            ? _c("a", {
                staticClass: "delete-icon",
                on: { click: _vm.handleRemoveName }
              })
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "popup-submit-btns",
          class: _vm.submitDisable ? "disabled" : ""
        },
        [
          _c(
            "button",
            {
              staticClass: "accept-btn",
              attrs: { type: "button" },
              on: { click: _vm.handleSubmit }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("page.profile.editName.save")) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "cancel-btn",
              attrs: { type: "button" },
              on: { click: _vm.handleClose }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("page.profile.editName.cancel")) +
                  "\n      "
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "popup-btns" }, [
        _c(
          "button",
          {
            staticClass: "close-btn icon-btn",
            attrs: { type: "button" },
            on: { click: _vm.handleClose }
          },
          [_vm._m(0)]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }