<template>
  <section class="report-section free-exploration" style="margin-left: 18px;">
    <h1>Shape Drawing(Counter Clockwise)</h1>
    <div class="report-contents">
      <div class="table"  v-if="left && right">
        <table>
          <thead>
          <tr>
            <th width="110"></th>
            <th width="70"></th>
            <th width="60">[{{ leftId }}]</th>
            <th width="60">[{{ rightId }}]</th>
            <th width="50"></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>Error reached</th>
            <th>Inner</th>
            <td>
              {{left.targetAchieveResultInner ? left.targetAchieveResultInner.errorScoreCenti.toFixed(1) : 0}} cm
            </td>
            <td>
              {{right.targetAchieveResultInner ? right.targetAchieveResultInner.errorScoreCenti.toFixed(1) : 0}} cm
            </td>
            <td>
              {{diffLeftRight(
                left.targetAchieveResultInner ? left.targetAchieveResultInner.errorScoreCenti.toFixed(1) : 0,
                right.targetAchieveResultInner ? right.targetAchieveResultInner.errorScoreCenti.toFixed(1) : 0
              )}}
              <img :src="'/img/icons/' + upDownIcon(
                left.targetAchieveResultInner ? left.targetAchieveResultInner.errorScoreCenti.toFixed(1) : 0,
                right.targetAchieveResultInner ? right.targetAchieveResultInner.errorScoreCenti.toFixed(1) : 0
              )" width="10" height="10"/>
            </td>
          </tr>
          <tr>
            <th></th>
            <th>Outer</th>
            <td>
              {{left.targetAchieveResultOuter ? left.targetAchieveResultOuter.errorScoreCenti.toFixed(1) : 0}} cm
            </td>
            <td>
              {{right.targetAchieveResultOuter ? right.targetAchieveResultOuter.errorScoreCenti.toFixed(1) : 0}} cm
            </td>
            <td>
              {{diffLeftRight(
              left.targetAchieveResultOuter ? left.targetAchieveResultOuter.errorScoreCenti.toFixed(1) : 0,
              right.targetAchieveResultOuter ? right.targetAchieveResultOuter.errorScoreCenti.toFixed(1) : 0
              )}}
              <img :src="'/img/icons/' + upDownIcon(
                left.targetAchieveResultOuter ? left.targetAchieveResultOuter.errorScoreCenti.toFixed(1) : 0,
                right.targetAchieveResultOuter ? right.targetAchieveResultOuter.errorScoreCenti.toFixed(1) : 0
              )" width="10" height="10"/>
            </td>
          </tr>

          <tr>
            <th>Duration</th>
            <th>Inner</th>
            <td>
              {{left.targetAchieveResultInner ? left.targetAchieveResultInner.time.toFixed(1) : 0}} cm
            </td>
            <td>
              {{right.targetAchieveResultInner ? right.targetAchieveResultInner.time.toFixed(1) : 0}} cm
            </td>
            <td>
              {{diffLeftRight(
              left.targetAchieveResultInner ? left.targetAchieveResultInner.time.toFixed(1) : 0,
              right.targetAchieveResultInner ? right.targetAchieveResultInner.time.toFixed(1) : 0
              )}}
              <img :src="'/img/icons/' + upDownIcon(
                left.targetAchieveResultInner ? left.targetAchieveResultInner.time.toFixed(1) : 0,
                right.targetAchieveResultInner ? right.targetAchieveResultInner.time.toFixed(1) : 0
              )" width="10" height="10"/>
            </td>
          </tr>
          <tr>
            <th></th>
            <th>Outer</th>
            <td>
              {{left.targetAchieveResultOuter ? left.targetAchieveResultOuter.time.toFixed(1) : 0}} cm
            </td>
            <td>
              {{right.targetAchieveResultOuter ? right.targetAchieveResultOuter.time.toFixed(1) : 0}} cm
            </td>
            <td>
              {{diffLeftRight(
              left.targetAchieveResultOuter ? left.targetAchieveResultOuter.time.toFixed(1) : 0,
              right.targetAchieveResultOuter ? right.targetAchieveResultOuter.time.toFixed(1) : 0
              )}}
              <img :src="'/img/icons/' + upDownIcon(
                left.targetAchieveResultOuter ? left.targetAchieveResultOuter.time.toFixed(1) : 0,
                right.targetAchieveResultOuter ? right.targetAchieveResultOuter.time.toFixed(1) : 0
              )" width="10" height="10"/>
            </td>
          </tr>



          <tr>
            <th>Smoothness</th>
            <th>Inner</th>
            <td>
              {{left.targetAchieveResultInner ? left.targetAchieveResultInner.smoothnessScorePercentage.toFixed(1) : 0}} cm
            </td>
            <td>
              {{right.targetAchieveResultInner ? right.targetAchieveResultInner.smoothnessScorePercentage.toFixed(1) : 0}} cm
            </td>
            <td>
              {{diffLeftRight(
              left.targetAchieveResultInner ? left.targetAchieveResultInner.smoothnessScorePercentage.toFixed(1) : 0,
              right.targetAchieveResultInner ? right.targetAchieveResultInner.smoothnessScorePercentage.toFixed(1) : 0
              )}}
              <img :src="'/img/icons/' + upDownIcon(
                left.targetAchieveResultInner ? left.targetAchieveResultInner.smoothnessScorePercentage.toFixed(1) : 0,
                right.targetAchieveResultInner ? right.targetAchieveResultInner.smoothnessScorePercentage.toFixed(1) : 0
              )" width="10" height="10"/>
            </td>
          </tr>
          <tr>
            <th></th>
            <th>Outer</th>
            <td>
              {{left.targetAchieveResultOuter ? left.targetAchieveResultOuter.smoothnessScorePercentage.toFixed(1) : 0}} cm
            </td>
            <td>
              {{right.targetAchieveResultOuter ? right.targetAchieveResultOuter.smoothnessScorePercentage.toFixed(1) : 0}} cm
            </td>
            <td>
              {{diffLeftRight(
              left.targetAchieveResultOuter ? left.targetAchieveResultOuter.smoothnessScorePercentage.toFixed(1) : 0,
              right.targetAchieveResultOuter ? right.targetAchieveResultOuter.smoothnessScorePercentage.toFixed(1) : 0
              )}}
              <img :src="'/img/icons/' + upDownIcon(
                left.targetAchieveResultOuter ? left.targetAchieveResultOuter.smoothnessScorePercentage.toFixed(1) : 0,
                right.targetAchieveResultOuter ? right.targetAchieveResultOuter.smoothnessScorePercentage.toFixed(1) : 0
              )" width="10" height="10"/>
            </td>
          </tr>
          <tr>
            <th>Total Score</th>
            <th></th>
            <td>
              {{left.standardScoreAvg ? left.standardScoreAvg.toFixed(1) : 0}} pt.
            </td>
            <td>
              {{right.standardScoreAvg ? right.standardScoreAvg.toFixed(1) : 0}} pt.
            </td>
            <td>
              {{diffLeftRight(
                left.standardScoreAvg ? left.standardScoreAvg.toFixed(1) : 0,
                right.standardScoreAvg ? right.standardScoreAvg.toFixed(1) : 0
              )}}
              <img :src="'/img/icons/' + upDownIcon(
                left.standardScoreAvg ? left.standardScoreAvg.toFixed(1) : 0,
                right.standardScoreAvg ? right.standardScoreAvg.toFixed(1) : 0
              )" width="10" height="10"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
  import jsonQuery from "json-query";
  export default {
    name: "EvaluationPointToPoint",
    props: {
      leftId: {
        type: Number
      },
      rightId: {
        type: Number
      },
      left: {
        type: Object
      },
      right: {
        type: Object
      }
    },
    mounted() {
      this.drawChart();
    },
    methods: {
      diffLeftRight(left, right) {
        if(left === right)
        {
          return '-'
        } else
        {
          return  Math.abs(right - left).toFixed(1)
        }
      },
      upDownIcon(left, right) {
        left = parseFloat(left);
        right = parseFloat(right);
        if(left === right)
        {
          return 'blank.png'
        } else if(left > right) {
          return 'arrow_red.png'
        } else {
          return 'arrow_blue.png'
        }
      },
      async drawChart() {
      },
    }
  };
</script>
