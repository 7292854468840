import request from "@/utils/request";
import { isEmpty, each, cloneDeep } from "lodash";

export const _middleCategories = [];
export const _subClasses = [];

// 게임 메인 카테고리
export const getGameMainCategory = (patientId) => {
  return request({
    url: `/home/api/v1/menu/mainCategory/${patientId}`,
    method: "get"
  });
};
// 게임 미들 카테고리
export const getGameMiddleCategory = () => {
  return request({
    url: `/home/api/v1/menu/middleCategory`,
    method: "get"
  });
};

// 게임 소분류
export const getGameSubclass = (lang) => {
  return request({
    url: `/home/api/v1/menu/subclass/${lang}`,
    method: "get"
  });
};

export const getGameCategories = async (patientId, lang) => {
  return new Promise(async (resolve, reject) => {
    const middleCategories = _middleCategories;
    const subClasses = _subClasses;
    if (isEmpty(subClasses)) {
      let tempSubClasses = await getGameSubclass(lang);
      tempSubClasses = tempSubClasses.filter(s => s.sideId === 1);
      each(tempSubClasses, (category) => {
        if (category.level === 1) {
          const game = cloneDeep(category);
          game.levels = tempSubClasses.filter(s => {
            return s.trainingTypeName === category.trainingTypeName && s.deviceName === category.deviceName && s.contentId === category.contentId;
          });
          _subClasses.push(game);
        }
      });
    }
    if (isEmpty(middleCategories)) {
      let tempMiddleCategories = await getGameMiddleCategory();
      tempMiddleCategories = tempMiddleCategories.filter(s => s.sideId === 1);
      each(tempMiddleCategories, (category) => {
        category.subClasses = subClasses.filter(s => {
          return s.motionComplexTypeName === category.motionType && s.deviceName === category.deviceName;
        });
        // debugger
        category.isOpen = false;
        _middleCategories.push(category);
      });
    }
    // const mainCagegories = await getGameMainCategory(1)
    const mainCagegories = await getGameMainCategory(patientId);
    each(mainCagegories, (mainCate) => {
      mainCate.isOpen = false;
      mainCate.middleCategories = middleCategories.filter(m => m.deviceName === mainCate.deviceName);
    });
    resolve(mainCagegories);
  });
};

// 게임상세 정보 호출
export const getGameInfo = ({ contentId, deviceName }, lang) => {
  return request({
    url: `/home/api/v1/menu/info/${contentId}/${deviceName}/${lang}`,
    method: "get"
  });
};

// 게임실행 호출
export const getGameStart = (data) => {
  return request({
    url: `/home/api/v1/games/gameExecution`,
    method: "post",
    data
  });
};

// 레벨리스트 확인
export const getLevelList = (contentId) => {
  return request({
    url: `/rapaelhome/api/user/getLevelList?contentId=${contentId}`,
    method: "get"
  });
};

