var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "report-section free-exploration" }, [
    _c("h1", [_vm._v("Free Exploration")]),
    _vm._v(" "),
    _c("div", { staticClass: "report-contents" }, [
      _vm.left && _vm.right
        ? _c("div", { staticClass: "table" }, [
            _c("table", [
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { width: "170" } }),
                  _vm._v(" "),
                  _c("th", [_vm._v("[" + _vm._s(_vm.leftId) + "]")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("[" + _vm._s(_vm.rightId) + "]")]),
                  _vm._v(" "),
                  _c("th", { attrs: { width: "70" } })
                ])
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v("Ratio of Area")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.left.areaRatioPercentage
                            ? _vm.left.areaRatioPercentage.toFixed(0)
                            : 0
                        ) +
                        " %\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.right.areaRatioPercentage
                            ? _vm.right.areaRatioPercentage.toFixed(0)
                            : 0
                        ) +
                        " %\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.diffLeftRight(
                            _vm.left.areaRatioPercentage
                              ? _vm.left.areaRatioPercentage.toFixed(0)
                              : 0,
                            _vm.right.areaRatioPercentage
                              ? _vm.right.areaRatioPercentage.toFixed(0)
                              : 0
                          )
                        ) +
                        "\n            "
                    ),
                    _c("img", {
                      attrs: {
                        src:
                          "/img/icons/" +
                          _vm.upDownIcon(
                            _vm.left.areaRatioPercentage
                              ? _vm.left.areaRatioPercentage.toFixed(0)
                              : 0,
                            _vm.right.areaRatioPercentage
                              ? _vm.right.areaRatioPercentage.toFixed(0)
                              : 0
                          ),
                        width: "10",
                        height: "10"
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [_vm._v("Total Distance")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.left.meanRadialDistanceCenti
                            ? _vm.left.meanRadialDistanceCenti.toFixed(0)
                            : 0
                        ) +
                        " cm\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.right.meanRadialDistanceCenti
                            ? _vm.right.meanRadialDistanceCenti.toFixed(0)
                            : 0
                        ) +
                        " cm\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.diffLeftRight(
                            _vm.left.meanRadialDistanceCenti
                              ? _vm.left.meanRadialDistanceCenti.toFixed(0)
                              : 0,
                            _vm.right.meanRadialDistanceCenti
                              ? _vm.right.meanRadialDistanceCenti.toFixed(0)
                              : 0
                          )
                        ) +
                        "\n            "
                    ),
                    _c("img", {
                      attrs: {
                        src:
                          "/img/icons/" +
                          _vm.upDownIcon(
                            _vm.left.meanRadialDistanceCenti
                              ? _vm.left.meanRadialDistanceCenti.toFixed(0)
                              : 0,
                            _vm.right.meanRadialDistanceCenti
                              ? _vm.right.meanRadialDistanceCenti.toFixed(0)
                              : 0
                          ),
                        width: "10",
                        height: "10"
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [_vm._v("Total Time")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.left.time ? _vm.left.time : 0) +
                        " Secs\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.right.time ? _vm.right.time : 0) +
                        " Secs\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.diffLeftRight(
                            _vm.left.time ? _vm.left.time : 0,
                            _vm.right.time ? _vm.right.time : 0
                          )
                        ) +
                        "\n            "
                    ),
                    _c("img", {
                      attrs: {
                        src:
                          "/img/icons/" +
                          _vm.upDownIcon(
                            _vm.left.time ? _vm.left.time : 0,
                            _vm.right.time ? _vm.right.time : 0
                          ),
                        width: "10",
                        height: "10"
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("th", [_vm._v("Total Score")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.left.standardScore ? _vm.left.standardScore : 0
                        ) +
                        " pt.\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.right.standardScore ? _vm.right.standardScore : 0
                        ) +
                        " pt.\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.diffLeftRight(
                            _vm.left.standardScore ? _vm.left.standardScore : 0,
                            _vm.right.standardScore
                              ? _vm.right.standardScore
                              : 0
                          )
                        ) +
                        "\n            "
                    ),
                    _c("img", {
                      attrs: {
                        src:
                          "/img/icons/" +
                          _vm.upDownIcon(
                            _vm.left.standardScore ? _vm.left.standardScore : 0,
                            _vm.right.standardScore
                              ? _vm.right.standardScore
                              : 0
                          ),
                        width: "10",
                        height: "10"
                      }
                    })
                  ])
                ])
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }