<template>
  <div class="WeeklyMission">
    <ul class="week-list">
      <li v-for="(mission, index) of missionList" :key="index">
        <div v-if="mission" class="week-mission">
          <button
            type="button"
            @click="handleMissionClick(mission.date, mission.missions)"
          >
            {{ $t("page.patient.calendar.head.dailyMission") }}
            {{ `(${mission.completedCount}/${mission.missionCount})` }}
          </button>
        </div>
        <div v-else></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "WeeklyMission",
  props: {
    missionList: Array,
    handleMissionDetailPopup: Function
  },
  data() {
    return {};
  },
  methods: {
    handleMissionClick(date, missions) {
      // 배치가 돌지 않은 세션
      this.handleMissionDetailPopup(date, missions);
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.WeeklyMission {
  .week-list {
    display: table;
    table-layout: fixed;
    padding-left: 65px;
    width: 100%;
    box-sizing: border-box;
    li {
      display: table-cell;
      border: 1px solid rgba(255, 255, 255, 0);
      border-right: none;
    }

    .week-mission {
      width: calc(100% - 8px);
      margin: 4px;
      box-sizing: border-box;
      border-radius: 4px;
      height: 40px;
      padding: 8px;
      background: #2d9cdb;
      position: relative;
      button {
        color: #ffffff;
        font-size: $fontSize7;
        line-height: 18px;
        text-align: left;
        padding: 0 8px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        span {
          display: block;
        }
      }
    }
  }
}
</style>
