var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "report-section free-exploration",
      staticStyle: { "margin-left": "18px" }
    },
    [
      _c("h1", [_vm._v("Shape Drawing(Counter Clockwise)")]),
      _vm._v(" "),
      _c("div", { staticClass: "report-contents" }, [
        _vm.left && _vm.right
          ? _c("div", { staticClass: "table" }, [
              _c("table", [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { width: "110" } }),
                    _vm._v(" "),
                    _c("th", { attrs: { width: "70" } }),
                    _vm._v(" "),
                    _c("th", { attrs: { width: "60" } }, [
                      _vm._v("[" + _vm._s(_vm.leftId) + "]")
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { width: "60" } }, [
                      _vm._v("[" + _vm._s(_vm.rightId) + "]")
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { width: "50" } })
                  ])
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Error reached")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Inner")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.left.targetAchieveResultInner
                              ? _vm.left.targetAchieveResultInner.errorScoreCenti.toFixed(
                                  1
                                )
                              : 0
                          ) +
                          " cm\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.right.targetAchieveResultInner
                              ? _vm.right.targetAchieveResultInner.errorScoreCenti.toFixed(
                                  1
                                )
                              : 0
                          ) +
                          " cm\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.left.targetAchieveResultInner
                                ? _vm.left.targetAchieveResultInner.errorScoreCenti.toFixed(
                                    1
                                  )
                                : 0,
                              _vm.right.targetAchieveResultInner
                                ? _vm.right.targetAchieveResultInner.errorScoreCenti.toFixed(
                                    1
                                  )
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.left.targetAchieveResultInner
                                ? _vm.left.targetAchieveResultInner.errorScoreCenti.toFixed(
                                    1
                                  )
                                : 0,
                              _vm.right.targetAchieveResultInner
                                ? _vm.right.targetAchieveResultInner.errorScoreCenti.toFixed(
                                    1
                                  )
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th"),
                    _vm._v(" "),
                    _c("th", [_vm._v("Outer")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.left.targetAchieveResultOuter
                              ? _vm.left.targetAchieveResultOuter.errorScoreCenti.toFixed(
                                  1
                                )
                              : 0
                          ) +
                          " cm\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.right.targetAchieveResultOuter
                              ? _vm.right.targetAchieveResultOuter.errorScoreCenti.toFixed(
                                  1
                                )
                              : 0
                          ) +
                          " cm\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.left.targetAchieveResultOuter
                                ? _vm.left.targetAchieveResultOuter.errorScoreCenti.toFixed(
                                    1
                                  )
                                : 0,
                              _vm.right.targetAchieveResultOuter
                                ? _vm.right.targetAchieveResultOuter.errorScoreCenti.toFixed(
                                    1
                                  )
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.left.targetAchieveResultOuter
                                ? _vm.left.targetAchieveResultOuter.errorScoreCenti.toFixed(
                                    1
                                  )
                                : 0,
                              _vm.right.targetAchieveResultOuter
                                ? _vm.right.targetAchieveResultOuter.errorScoreCenti.toFixed(
                                    1
                                  )
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Duration")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Inner")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.left.targetAchieveResultInner
                              ? _vm.left.targetAchieveResultInner.time.toFixed(
                                  1
                                )
                              : 0
                          ) +
                          " cm\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.right.targetAchieveResultInner
                              ? _vm.right.targetAchieveResultInner.time.toFixed(
                                  1
                                )
                              : 0
                          ) +
                          " cm\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.left.targetAchieveResultInner
                                ? _vm.left.targetAchieveResultInner.time.toFixed(
                                    1
                                  )
                                : 0,
                              _vm.right.targetAchieveResultInner
                                ? _vm.right.targetAchieveResultInner.time.toFixed(
                                    1
                                  )
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.left.targetAchieveResultInner
                                ? _vm.left.targetAchieveResultInner.time.toFixed(
                                    1
                                  )
                                : 0,
                              _vm.right.targetAchieveResultInner
                                ? _vm.right.targetAchieveResultInner.time.toFixed(
                                    1
                                  )
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th"),
                    _vm._v(" "),
                    _c("th", [_vm._v("Outer")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.left.targetAchieveResultOuter
                              ? _vm.left.targetAchieveResultOuter.time.toFixed(
                                  1
                                )
                              : 0
                          ) +
                          " cm\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.right.targetAchieveResultOuter
                              ? _vm.right.targetAchieveResultOuter.time.toFixed(
                                  1
                                )
                              : 0
                          ) +
                          " cm\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.left.targetAchieveResultOuter
                                ? _vm.left.targetAchieveResultOuter.time.toFixed(
                                    1
                                  )
                                : 0,
                              _vm.right.targetAchieveResultOuter
                                ? _vm.right.targetAchieveResultOuter.time.toFixed(
                                    1
                                  )
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.left.targetAchieveResultOuter
                                ? _vm.left.targetAchieveResultOuter.time.toFixed(
                                    1
                                  )
                                : 0,
                              _vm.right.targetAchieveResultOuter
                                ? _vm.right.targetAchieveResultOuter.time.toFixed(
                                    1
                                  )
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Smoothness")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Inner")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.left.targetAchieveResultInner
                              ? _vm.left.targetAchieveResultInner.smoothnessScorePercentage.toFixed(
                                  1
                                )
                              : 0
                          ) +
                          " cm\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.right.targetAchieveResultInner
                              ? _vm.right.targetAchieveResultInner.smoothnessScorePercentage.toFixed(
                                  1
                                )
                              : 0
                          ) +
                          " cm\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.left.targetAchieveResultInner
                                ? _vm.left.targetAchieveResultInner.smoothnessScorePercentage.toFixed(
                                    1
                                  )
                                : 0,
                              _vm.right.targetAchieveResultInner
                                ? _vm.right.targetAchieveResultInner.smoothnessScorePercentage.toFixed(
                                    1
                                  )
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.left.targetAchieveResultInner
                                ? _vm.left.targetAchieveResultInner.smoothnessScorePercentage.toFixed(
                                    1
                                  )
                                : 0,
                              _vm.right.targetAchieveResultInner
                                ? _vm.right.targetAchieveResultInner.smoothnessScorePercentage.toFixed(
                                    1
                                  )
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th"),
                    _vm._v(" "),
                    _c("th", [_vm._v("Outer")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.left.targetAchieveResultOuter
                              ? _vm.left.targetAchieveResultOuter.smoothnessScorePercentage.toFixed(
                                  1
                                )
                              : 0
                          ) +
                          " cm\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.right.targetAchieveResultOuter
                              ? _vm.right.targetAchieveResultOuter.smoothnessScorePercentage.toFixed(
                                  1
                                )
                              : 0
                          ) +
                          " cm\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.left.targetAchieveResultOuter
                                ? _vm.left.targetAchieveResultOuter.smoothnessScorePercentage.toFixed(
                                    1
                                  )
                                : 0,
                              _vm.right.targetAchieveResultOuter
                                ? _vm.right.targetAchieveResultOuter.smoothnessScorePercentage.toFixed(
                                    1
                                  )
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.left.targetAchieveResultOuter
                                ? _vm.left.targetAchieveResultOuter.smoothnessScorePercentage.toFixed(
                                    1
                                  )
                                : 0,
                              _vm.right.targetAchieveResultOuter
                                ? _vm.right.targetAchieveResultOuter.smoothnessScorePercentage.toFixed(
                                    1
                                  )
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Total Score")]),
                    _vm._v(" "),
                    _c("th"),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.left.standardScoreAvg
                              ? _vm.left.standardScoreAvg.toFixed(1)
                              : 0
                          ) +
                          " pt.\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.right.standardScoreAvg
                              ? _vm.right.standardScoreAvg.toFixed(1)
                              : 0
                          ) +
                          " pt.\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.diffLeftRight(
                              _vm.left.standardScoreAvg
                                ? _vm.left.standardScoreAvg.toFixed(1)
                                : 0,
                              _vm.right.standardScoreAvg
                                ? _vm.right.standardScoreAvg.toFixed(1)
                                : 0
                            )
                          ) +
                          "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src:
                            "/img/icons/" +
                            _vm.upDownIcon(
                              _vm.left.standardScoreAvg
                                ? _vm.left.standardScoreAvg.toFixed(1)
                                : 0,
                              _vm.right.standardScoreAvg
                                ? _vm.right.standardScoreAvg.toFixed(1)
                                : 0
                            ),
                          width: "10",
                          height: "10"
                        }
                      })
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }