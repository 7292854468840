var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "Session" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "contents" }, [
        _c(
          "div",
          { staticClass: "game-list" },
          [
            _c("Select", {
              attrs: {
                options: _vm.levelTypes,
                label: "name",
                components: {
                  OpenIndicator: _vm.OpenIndicator,
                  Deselect: _vm.Deselect
                },
                id: "deviceSelect"
              },
              model: {
                value: _vm.selectedLevelType,
                callback: function($$v) {
                  _vm.selectedLevelType = $$v
                },
                expression: "selectedLevelType"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tag-box custom-scrollbar" },
              _vm._l(_vm.tagList, function(tag, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    staticClass: "tag",
                    class: _vm.selectedTagList.includes(tag) ? "active" : "",
                    on: {
                      click: function($event) {
                        return _vm.handleOnClickTag(tag)
                      }
                    }
                  },
                  [_vm._v(_vm._s(tag))]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "custom-scrollbar" },
              _vm._l(_vm.missionList, function(mission, index) {
                return _c("li", { key: index }, [
                  _c("div", { staticClass: "game-image" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.showMissionImage(mission),
                        alt: mission.title
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "game-info" }, [
                    _c("span", { staticClass: "game-title" }, [
                      _vm._v(_vm._s(mission.title))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "game-select secondary-type-btns small-btns",
                        class: _vm.selectedHomework.length > 7 ? "disabled" : ""
                      },
                      [
                        _c(
                          "button",
                          {
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.addMission(mission)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("page.patient.session.detail.select")
                                ) +
                                "\n              "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "detail-btn",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.viewMissionDetail(mission)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(
                                    "page.patient.session.exerciseDetail.detail"
                                  )
                                ) +
                                "\n              "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ])
              }),
              0
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "selected-games-list custom-scrollbar" }, [
          _c("div", { staticClass: "repeat-wrap" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("page.patient.session.exerciseDetail.recurrence"))
              )
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.homeworkRepeat.message,
                  expression: "homeworkRepeat.message"
                }
              ],
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.homeworkRepeat.message },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.homeworkRepeat, "message", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn",
                class: _vm.repeatDisabled ? "disabled" : "",
                on: { click: _vm.handleRepeatSettingOpen }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t("page.patient.session.exerciseDetail.setting")
                    ) +
                    "\n        "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "homework-message" }, [
            _c("div", { staticClass: "homework-message-title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.$t("page.patient.session.exerciseDetail.message")
                  ) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.homeworkMessage,
                  expression: "homeworkMessage"
                }
              ],
              class: {
                "homework-message-text": true,
                first: _vm.messageStyleFirst
              },
              domProps: { value: _vm.homeworkMessage },
              on: {
                click: _vm.handleMessageStyleToggle,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.homeworkMessage = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "homework-message-description" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "page.patient.session.exerciseDetail.messageDescription"
                  )
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "game-list-wrapper" }, [
            _c(
              "ul",
              { staticClass: "selected-games" },
              _vm._l(_vm.selectedHomework, function(mission, index) {
                return _c("li", { key: index }, [
                  _c("div", { staticClass: "game-info" }, [
                    _c("span", { staticClass: "game-image" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.showMissionImage(mission),
                          alt: mission.title
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "game-title" }, [
                      _vm._v(_vm._s(mission.title))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "game-delete" }, [
                      _c("button", { attrs: { type: "button" } }, [
                        _c(
                          "span",
                          {
                            staticClass: "icon-ic_delete_circle",
                            on: {
                              click: function($event) {
                                return _vm.deleteMission(mission)
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "path1" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "path2" }),
                            _vm._v(" "),
                            _c("span", { staticClass: "path3" })
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "game-set-info" }, [
                      _c("div", { staticClass: "set-label" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm.$t(
                                "page.patient.session.exerciseDetail.complete"
                              )
                            ) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "set-input-wrapper" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: mission.setsCount,
                              expression: "mission.setsCount"
                            }
                          ],
                          attrs: { type: "number" },
                          domProps: { value: mission.setsCount },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  mission,
                                  "setsCount",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.updateValue(mission, $event)
                              }
                            ],
                            keydown: _vm.onlyNumber,
                            blur: function($event) {
                              return _vm.isCheckEmpty(mission, $event)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("page.patient.session.exerciseDetail.sets")
                            )
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "empty-list" },
              _vm._l(_vm.emptyList, function(empty, index) {
                return _c("li", { key: index }, [_vm._m(0, true)])
              }),
              0
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "popup-submit-btns",
          class: _vm.selectedHomework.length < 1 ? "disabled" : "selectedGames"
        },
        [
          _c(
            "button",
            {
              staticClass: "accept-btn",
              attrs: { type: "button" },
              on: {
                "~click": function($event) {
                  return _vm.handleSubmit($event)
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("page.patient.session.saveButton")) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            { attrs: { type: "button" }, on: { click: _vm.handleClose } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("page.patient.session.cancelButton")) +
                  "\n    "
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "popup-btns" }, [
        _c(
          "button",
          {
            staticClass: "close-btn icon-btn",
            attrs: { type: "button" },
            on: { click: _vm.handleClose }
          },
          [_vm._m(1)]
        )
      ]),
      _vm._v(" "),
      _vm.missionDetailPopup.view
        ? _c("MissionDetail", {
            attrs: {
              mission: _vm.missionDetailPopup.mission,
              closePopup: _vm.closeDetailPopup,
              sets: _vm.initSets,
              selectedHomework: _vm.selectedHomework
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_add" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }