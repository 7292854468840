<template>
  <div
    class="Publisher"
    :class="[ScreenSharingOn ? 'ScringSharingOn' : 'ScringSharingOff']"
    @click="screenAction"
  >
    <div v-if="mode === 'main-mode'" class="controller-button-wrap">
      <!-- <button>
        <span class="icon">
          <img src="@/assets/svgs/ic_full.svg" alt="ic_full-image" />
        </span>
      </button> -->
      <button v-if="changeMode" @click.stop="changeMode">
        <span class="icon">
          <img src="@/assets/svgs/ic_division.svg" alt="ic_division-image">
        </span>
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */

import OT from "@opentok/client";
export default {
  name: "Publisher",
  props: {
    session: {
      type: OT.Session,
      required: false
    },
    screenSharingOn: {
      type: Boolean,
      required: true
    },
    mode: {
      type: String
    },
    changeMode: {
      type: Function
    },
    screenAction: {
      type: Function
    },
    userId: Number
  },
  data() {
    return {
      options: {
        width: "100%",
        height: "100%",
        insertMode: "append",
        name: "portal_screen"
      }
    };
  },
  mounted() {
    const publisher = OT.initPublisher(this.$el, this.options, (error) => {
      if (error) {
        this.$emit("error", error);
      } else {
        this.$emit("publisherCompleted");
      }
    });
    this.$emit("publisherCreated", publisher);
    const publish = () => {
      this.session.publish(publisher, (error) => {
        if (error) {
          this.$emit("error", error);
        } else {
          this.$emit("publisherConnected", publisher);
        }
      });
    };
    if (this.session && this.session.isConnected()) {
      publish();
    }
    if (this.session) {
      this.session.on("sessionConnected", publish);
    }
  },
  created() {
    console.log(String(this.userId));
    this.options.name = String(this.userId);
  }
};
</script>
<style lang="scss" scoped>
.Publisher {
  position: relative;
  overflow: hidden;
  &:hover {
    .controller-button-wrap {
      bottom: 0;
      opacity: 1;
    }
  }
  .controller-button-wrap {
    position: absolute;
    right: 0;
    bottom: -38px;
    z-index: 10;
    transition: all ease-in 0.5s;
    opacity: 0;
    button {
      cursor: pointer;
      display: inline-block;
      width: 38px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-color: rgba(48, 49, 51, 0.6);
      border-radius: 50%;
      .icon {
        position: relative;
        top: 6px;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.6);
      }
    }
  }
}
.ScringSharingOn {
  // width: 120px;
  // height: 97px;
  width: 241px;
  height: 194px;
}
.ScringSharingOff {
  width: 241px;
  height: 194px;
  // width: 432px;
  // height: 273px;
}
</style>
