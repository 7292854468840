var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "Notification" }, [
    _c("div", { staticClass: "contents" }, [
      _c("div", { staticClass: "notification-header" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("page.calendar.notifications.title")))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "secondary-type-btns small-btns read-all-btn" },
          [
            _c(
              "button",
              { attrs: { type: "button" }, on: { click: _vm.handleReadAll } },
              [
                _vm._v(
                  _vm._s(_vm.$t("page.calendar.notifications.readAllButton"))
                )
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "notice-list" }, [
        _vm.noticeList
          ? _c(
              "ul",
              {
                staticClass: "custom-scrollbar",
                on: { scroll: _vm.handleScroll }
              },
              _vm._l(_vm.noticeList, function(notice, index) {
                return _c(
                  "li",
                  { key: index, class: !notice.read ? "unread" : "" },
                  [
                    _c(
                      "button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.handleRead(notice.id, notice.eventId)
                          }
                        }
                      },
                      [
                        _vm._m(0, true),
                        _vm._v(" "),
                        _c("div", { staticClass: "notice-info" }, [
                          _c("span", { staticClass: "title" }, [
                            _vm._v(_vm._s(notice.title))
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "time" }, [
                            _vm._v(_vm._s(_vm.handleFromNow(notice.date)))
                          ])
                        ])
                      ]
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "popup-btns" }, [
      _c(
        "button",
        {
          staticClass: "close-btn",
          attrs: { type: "button" },
          on: { click: _vm.closeNotification }
        },
        [_vm._m(1)]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_bell" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" }),
      _vm._v(" "),
      _c("span", { staticClass: "path3" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete ic-icon" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }