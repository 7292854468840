<template>
  <div class="PatientInfo">
    <section>
      <div class="contents">
        <h1>
          {{
            $t(
              "adminPage.management.patientList.basicInformation.basicInformation"
            )
          }}
        </h1>
        <div class="content">
          <div
            v-for="(formData, index) in this.formDataList"
            :key="index"
            class="form"
          >
            <label
              v-if="formData.type !== 'input'"
            >{{ formData.label }}</label>

            <label
              v-if="formData.type === 'input'"
              v-show="patientDetailInfo[formData.key].visible"
            >{{ formData.label }}</label>
            <input
              v-if="formData.type === 'input'"
              v-show="patientDetailInfo[formData.key].visible"
              v-model="patientDetailInfo[formData.key].name"
              type="text"
              @change="validation"
            >
            <input
              v-if="formData.type === 'number'"
              v-model="patientDetailInfo[formData.key]"
              type="number"
              @change="validation"
              @keydown="onlyNumber"
            >
            <input
              v-if="formData.type === 'mobile'"
              v-model="patientDetailInfo[formData.key]"
              type="number"
              @change="validation"
              @keydown="onlyNumber"
            >
            <span v-if="formData.type === 'text'">{{
              get(patientDetailInfo, formData.key)
            }}</span>
            <span v-if="formData.type === 'date'">{{
              formatDate(get(patientDetailInfo, formData.key))
            }}</span>
          </div>
          <div class="form">
            <label>{{
              $t("adminPage.management.patientList.basicInformation.diagnosis")
            }}</label>
            <input v-model="patientDetailInfo.disease.disease" type="text">
          </div>
          <div class="form">
            <label>{{
              $t(
                "adminPage.management.patientList.basicInformation.dateofOccurence"
              )
            }}</label>
            <datepicker
              v-model="patientDetailInfo.disease.onsetDate"
              name="onsetDate"
              format="yyyy.MM.dd"
              class="datepicker"
              calendar-class="datepicker-calendar"
            />
          </div>
          <div class="form">
            <label>{{
              $t(
                "adminPage.management.patientList.basicInformation.affectedSide"
              )
            }}</label>
            <div class="switch-field">
              <input
                id="radio-one"
                v-model="patientDetailInfo.disease.strokeSide"
                type="radio"
                name="switch-radio"
                value="LEFT"
              >
              <label for="radio-one">{{
                $t("adminPage.management.patientList.basicInformation.left")
              }}</label>
              <input
                id="radio-two"
                v-model="patientDetailInfo.disease.strokeSide"
                type="radio"
                name="switch-radio"
                value="RIGHT"
              >
              <label for="radio-two">{{
                $t("adminPage.management.patientList.basicInformation.right")
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-submit-btns">
        <button type="button" class="accept-btn" @click="handleSubmit">
          {{ $t("adminPage.management.patientList.basicInformation.save") }}
        </button>
        <button type="button" class="cancel-btn" @click="handleClose">
          {{ $t("adminPage.management.patientList.basicInformation.cancel") }}
        </button>
      </div>
      <div class="popup-btns">
        <!-- 팝업 컨트롤 버튼 -->
        <button type="button" class="close-btn icon-btn" @click="handleClose">
          <span class="icon-ic_delete">
            <span class="path1" />
            <span class="path2" />
          </span>
        </button>
      </div>
    </section>
  </div>
</template>
<script>
/* eslint-disable vue/require-default-prop */

import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import { get } from "lodash";
import {
  fetchGetPatientInformation,
  fetchPutPatientInformation
} from "@/api/patient";
import Datepicker from "vuejs-datepicker";

export default {
  name: "PatientInfo",
  components: {
    Datepicker
  },
  mixins: [ErrorMixin],
  props: {
    patientInfo: Object,
    searchPatients: Function
  },
  data() {
    return {
      patientDetailInfo: {
        disease: {}
      },
      formDataList: [
        {
          label: this.$t(
            "adminPage.management.patientList.basicInformation.fullName"
          ),
          key: `name`,
          type: "input"
        },
        {
          label: this.$t(
            "adminPage.management.patientList.basicInformation.firstName"
          ),
          key: "first_name",
          type: "input"
        },
        {
          label: this.$t(
            "adminPage.management.patientList.basicInformation.lastName"
          ),
          key: "last_name",
          type: "input"
        },
        {
          label: this.$t(
            "adminPage.management.patientList.basicInformation.dateofBirth"
          ),
          key: "birthday",
          type: "date"
        },
        {
          label: this.$t(
            "adminPage.management.patientList.basicInformation.gender"
          ),
          key: "gender",
          type: "text"
        },
        {
          label: this.$t(
            "adminPage.management.patientList.basicInformation.state"
          ),
          key: "state_name",
          type: "text"
        },
        {
          label: this.$t(
            "adminPage.management.patientList.basicInformation.mobile"
          ),
          key: "mobile",
          type: "number"
        }
      ]
    };
  },
  async created() {
    try {
      this.patientDetailInfo = await fetchGetPatientInformation(
        this.patientInfo.id
      );
      const onlyNickaname = (this.patientDetailInfo.first_name === null && this.patientDetailInfo.last_name === null);
      this.patientDetailInfo.first_name = {
        name: this.patientDetailInfo.first_name,
        visible: !onlyNickaname
      };
      this.patientDetailInfo.last_name = {
        name: this.patientDetailInfo.last_name,
        visible: !onlyNickaname
      };
      this.patientDetailInfo.name = {
        name: this.patientDetailInfo.name,
        visible: onlyNickaname
      };
    } catch (err) {
      this.handleError(err, "ManagementPatient > Get Clinic Patient API");
    }
  },
  methods: {
    onlyNumber(event) {
      const re = new RegExp("[^0-9\\-]");
      if (event.keyCode === 8 || event.keyCode === 46) {
        return;
      } else {
        if (re.test(event.key)) {
          event.preventDefault();
          return;
        }
      }
    },
    handleClose() {
      this.$emit("close");
    },
    async handleSubmit() {
      const payload = {
        firstName: this.patientDetailInfo.first_name,
        lastName: this.patientDetailInfo.last_name,
        mobile: this.patientDetailInfo.mobile,
        disease: {
          disease: this.patientDetailInfo.disease.disease,
          strokeSide: this.patientDetailInfo.disease.strokeSide,
          onsetDate: this.patientDetailInfo.disease.onsetDate == null || this.patientDetailInfo.disease.onsetDate === "" ? null
            : this.$moment(this.patientDetailInfo.disease.onsetDate).format("YYYY-MM-DD")
        }
      };

      try {
        const result = await fetchPutPatientInformation(
          this.patientInfo.id,
          payload
        );
        if (result) {
          this.handleClose();
          this.searchPatients();
        }
      } catch (err) {
        this.handleError(err, "ManagementPatient > PUT Clinic Patient API");
      }
    },
    formatDate(date) {
      return this.$moment(date).format("YYYY.MM.DD");
    },
    get,
    validation() {
      const mobile = this.patientDetailInfo.mobile;
      const newMobile = mobile.replace(/[^0-9]/g, "");
      if (newMobile === mobile) return;
      this.patientDetailInfo.mobile = newMobile;
    }
  }
};
</script>
<style lang="scss" scoped>
.PatientInfo {
  .contents {
    padding: 50px 24px 40px;

    h1 {
      font-weight: bold;
      color: $fontColor1;
      font-size: $fontSize2;
      line-height: 32px;
    }

    .content {
      margin-top: 24px;

      .form {
        display: flex;
        align-items: center;
        font-family: Manrope;
        font-size: $fontSize7;
        margin-top: 12px;

        .datepicker {
          width: 200px;
          height: 36px;

          & >>> input {
            font-size: $fontSize7;
          }

          & >>> .datepicker-calendar {
            transform: translate(0, -100%);
          }
        }

        label {
          width: 162px;
          font-weight: 600;
          line-height: 22px;
          color: $fontColor3;
        }

        input {
          width: 200px;
          height: 36px;
          border: 1px solid #d0d4dc;
          box-sizing: border-box;
          border-radius: 2px;
          padding: 8px 16px;
          font-size: $fontSize7;
        }

        span {
          display: inline-block;
          padding-left: 16px;
        }

        .switch-field {
          display: flex;
          align-items: center;
          width: 120px;
          height: 27px;

          input {
            position: absolute !important;
            height: 1px;
            width: 1px;
            border: 0;
            overflow: hidden;
          }

          label {
            display: inline-block;
            text-align: center;
            background-color: #fff;
            color: #606266;
            font-size: 12px;
            line-height: 18px;
            width: 60px;
            height: 100%;
            padding-top: 4px;
            margin-right: -1px;
            border: 1px solid #d0d4dc;
            box-sizing: border-box;
          }

          label:before {
            display: none;
          }

          label:after {
            display: none;
          }

          label:hover {
            cursor: pointer;
          }

          input:checked + label {
            background-color: #f0734d;
            border: 1px solid #f0734d;
            color: #fff;
          }

          label:first-of-type {
            border-radius: 4px 0 0 4px;
          }

          label:last-of-type {
            border-radius: 0 4px 4px 0;
          }
        }
      }
    }
  }

  .popup-submit-btns {
    height: 88px;

    .accept-btn {
      width: 84px;
      height: 40px;
    }
  }
}
</style>
