import request from "@/utils/request";

// 환자별 주별 미션목록 가져오기 (환자 스케쥴에서 사용됨)
export function fetchGetWeeklyMissions(patientId, start, end) {
  return request({
    url: `/connect/api/v2/missions`,
    method: "get",
    params: {
      user_id: patientId,
      start,
      end
    }
  });
}

// 미션 가져오기 (홈웍 만들때 사용됨)
export function fetchGetHomeworkMissionListV1(
  missionType = null,
  levelString = null
) {
  const params = {};
  if (missionType !== null && levelString !== null) {
    params.type = missionType;
    params.handArmFunctionType = levelString;
  } else if (missionType !== null && levelString === null) {
    params.type = missionType;
  } else if (missionType === null && levelString !== null) {
    params.handArmFunctionType = levelString;
  }

  return request({
    url: `/connect/api/v1/homework/missionList`,
    method: "get",
    params
  });
}

// 미션리스트 + 태그 조회(v1의 리턴 리스트가 missions에 들어감.)
export function fetchGetHomeworkMissionListV2(
  missionType = null,
  levelString = null
) {
  const params = {};
  if (missionType !== null && levelString !== null) {
    params.type = missionType;
    params.handArmFunctionType = levelString;
  } else if (missionType !== null && levelString === null) {
    params.type = missionType;
  } else if (missionType === null && levelString !== null) {
    params.handArmFunctionType = levelString;
  }

  return request({
    url: `/connect/api/v2/homework/missionList`,
    method: "get",
    params
  });
}

// 홈웍 만들때 사용
export function fetchCreateHomework(requestData) {
  return request({
    url: `/connect/api/v1/homework`,
    method: "post",
    data: requestData
  });
}

// 홈워크 만들 때 사용
export function fetchCreateHomeworkV2(requestData) {
  return request({
    url: `/connect/api/v2/homework`,
    method: "post",
    data: requestData
  });
}

// 홈웍 디테일 가져올 때 사용
export function fetchGetHomework(patientUserId) {
  return request({
    url: `/connect/api/v1/homework/${patientUserId}`,
    method: "get"
  });
}

// 홈웍 삭제 할 때 사용
export function fetchDeleteHomework(patientUserId) {
  return request({
    url: `/connect/api/v1/homework/${patientUserId}`,
    method: "delete"
  });
}

// 홈웍 수정 할 때 사용
export function fetchUpdateHomework(patientUserId, requestData) {
  return request({
    url: `/connect/api/v1/homework/${patientUserId}`,
    method: "put",
    data: requestData
  });
}

// 홈웍 수정 할 때 사용 V2
export function fetchUpdateHomeworkV2(patientUserId, requestData) {
  return request({
    url: `/connect/api/v2/homework/${patientUserId}`,
    method: "put",
    data: requestData
  });
}

// 홈웍 월별 날짜 리스트 가져오기.
export function fetchGetMonthlyHomeworkAssingDate(year, month, patientUserId) {
  return request({
    url: `/connect/api/v1/homework/monthlyHomeworkAssingDate/${year}/${month}`,
    method: "get",
    params: {
      userId: patientUserId
    }
  });
}

// 주별 홈웍 리스트 가져오기.
export function fetchGetWeeklyHomeworkAssingInfo(
  patientUserId,
  startDate,
  endDate
) {
  return request({
    url: `/connect/api/v1/homework/weeklyHomeworkAssignInfo/${patientUserId}`,
    method: "get",
    params: {
      startDate,
      endDate
    }
  });
}

// 홈웍 히스토리 리스트 가져오기.
export function fetchGetHistoryHomeworkList(
  patientUserId,
  sort,
  startDate,
  endDate
) {
  return request({
    url: `/connect/api/v1/homework/history/${patientUserId}`,
    method: "get",
    params: {
      sort,
      startDate,
      endDate
    }
  });
}
