<template>
  <div class="GlobalMenu">
    <router-link to="/" class="nav-home">
      <img :src="symbol" alt="neofect symbol">
    </router-link>&nbsp;
    <router-link ref="calendar" to="/calendar" class="nav-calendar nav-btn">
      <span class="icon-ic_date">
        <span class="path1" />
        <span class="path2" />
        <span class="path3" />
        <span class="path4" />
        <span class="path5" />
        <span class="path6" />
        <span class="path7" />
        <span class="path8" />
        <span class="path9" />
        <span class="path10" />
      </span>
    </router-link>
    <router-link ref="patient" to="/patient" class="nav-patients nav-btn">
      <span class="icon-ic_group">
        <span class="path1" />
        <span class="path2" />
        <span class="path3" />
        <span class="path4" />
      </span>
    </router-link>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: "GlobalMenu",
  computed: {
    symbol() {
      return require("@/assets/svgs/symbol-neofect-colored.svg");
    }
  }
};
</script>

<style lang='scss' scoped>
.GlobalMenu {
  width: 70px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid $borderColor1;
  background: #836855;
  z-index: 1;
  .nav-home {
    display: block;
    margin-top: 24px;
    width: 40px;
    text-align: center;
  }
  .nav-btn {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    text-decoration: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    &.router-link-active {
      background: rgba(0, 0, 0, 0.2);
      opacity: 1;
    }
    &.nav-calendar {
      margin-top: 40px;
    }
    &.nav-patients {
      margin-top: 30px;
    }
  }
}
</style>