const chartDataCalculator = options => {
  const lineColor = ["#5aadf5", "#f0734d"];
  let indicatorData = options.indicator
  let seriesData = [];

  seriesData.push({
    connectNulls: true,
    stack: false,
    type: "radar",
    data: options.data,
    labelLine: {
      show: true,
      length: 30,
    },
    label: {
      show: true,
      backgroundColor: '#F6F8FC',
      borderColor: '#8C8D8E',
      borderWidth: 1,
      borderRadius: 4,
      padding: 4,
      fontSize: 14,
    }
  });

  return {
    color: lineColor,
    radar: {
      indicator: indicatorData,
      center: ['50%', '55%'],
      axisLabel: {
        show: true,
        inside: false,
        fontSize: 16
      },
      splitArea: false,
      radius: "80%"
    },
    legend: {
      data: [options.data[0].name, options.data[1].name]
    },
    series: seriesData
  };
};

export { chartDataCalculator };
