<template>
  <section
    class="SessionDetail"
    :style="{ top: `${coordinates.y}px`, left: `${coordinates.x}px` }"
  >
    <h1>{{ $t("page.patient.calendar.head.detail.type.strokeCoachSession.title") }}</h1>
    <div class="session-detail-date">
      {{ $moment(sessionDetailInfo.date).format("MMMM D, YYYY") }}
    </div>
    <div class="session-games" v-if="!sessionDetailInfo.batch">
      <ul>
        <li v-for="(game, index) of sessionDetailInfo.games" :key="index">
          <span>{{ game.title }}</span>
          <span>{{ `(Level${game.level})` }}</span>
        </li>
      </ul>
    </div>
    <div class="session-games" v-else>
      <ul>
        <li v-for="(game, index) of sessionDetailInfo.games" :key="index">
          <span v-if="game.lastTrainedDate"
            >{{ $moment(game.lastTrainedDate).format("h:mm A") }}&nbsp;</span
          >
          <span>{{ `S${game.sessionNumber}` }}&nbsp;</span>
          <span>{{ `M${index + 1}` }}&nbsp;</span>
          <span>{{ game.title }}&nbsp;</span>
          <span>{{ `(${game.successCount}/${game.targetCount})` }}</span>
          <span class="game-star">
            <span
              class="icon-ic_star_fill"
              v-for="(star, index) of game.starCount"
              :key="index"
              :class="star ? 'active' : ''"
            >
              <span class="path1"></span>
            </span>
          </span>
        </li>
      </ul>
    </div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button type="button" class="del-btn icon-btn" @click="handleDelete()">
        <span class="icon-ic_bin" v-if="!sessionDetailInfo.batch && !overTime">
          <span class="path1" />
          <span class="path2" />
          <span class="path3" />
          <span class="path4" />
          <span class="path5" />
        </span>
      </button>
      <button
        type="button"
        class="edit-btn icon-btn"
        @click="handleEdit(sessionDetailInfo)"
        v-if="!sessionDetailInfo.batch && !overTime"
      >
        <span class="icon-ic_edit">
          <span class="path1" />
          <span class="path2" />
          <span class="path3" />
        </span>
      </button>
      <button type="button" class="close-btn icon-btn" @click="handleClose">
        <span class="icon-ic_delete">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
  </section>
</template>

<script>
import Confirm from "@/components/Confirm";
export default {
  name: "SessionDetail",
  props: {
    sessionDetailInfo: Object,
    detailPopupClose: Function,
    deleteSession: Function,
    editSession: Function
  },
  data() {
    return {
      coordinates: {
        x: 0,
        y: 0
      },
      overTime: false
    };
  },
  methods: {
    calculatePosition() {
      let calendarSize = this.sessionDetailInfo.calendarSize;
      let thisPopupSize = {
        width: this.$el.offsetWidth,
        height: this.$el.offsetHeight
      };
      let calculateX =
        this.sessionDetailInfo.coordinates.x +
          thisPopupSize.width +
          this.sessionDetailInfo.coordinates.flexible +
          4 <
        calendarSize.width
          ? this.sessionDetailInfo.coordinates.x +
            this.sessionDetailInfo.coordinates.flexible +
            4
          : this.sessionDetailInfo.coordinates.x - thisPopupSize.width - 4;
      let calculateY = this.sessionDetailInfo.coordinates.y;
      this.coordinates = {
        x: calculateX,
        y: calculateY
      };
    },
    handleClose() {
      this.detailPopupClose();
    },
    handleEdit(session) {
      this.editSession(session);
    },
    handleDelete(sessionId) {
      this.$modal.show(
        Confirm,
        {
          type: "delete",
          submit: this.confirm_delete_ok,
          confirm: {
            title: this.$t("page.patient.calendar.head.detail.type.strokeCoachSession.deleteModal.title"),
            message: this.$t("page.patient.calendar.head.detail.type.strokeCoachSession.deleteModal.message")
          }
        },
        {
          height: "auto"
        }
      );
    },
    confirm_delete_ok() {
      this.deleteSession(this.sessionDetailInfo.id);
    }
  },
  created() {
    // console.log(this.sessionDetailInfo);
    const now = new Date();
    const eventStart = new Date(this.sessionDetailInfo.date);
    const startDisable = (eventStart - now) / 1000 / 60 <= 0;
    if (startDisable) {
      this.overTime = true;
    }
  },
  mounted() {
    this.calculatePosition();
  }
};
</script>

<style lang="scss" scoped>
.SessionDetail {
  width: $modalWidth1;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #d0d4dc;
  padding: 50px 24px 40px 24px;
  box-sizing: border-box;
  h1 {
    font-size: $fontSize2;
    color: $fontColor1;
    font-weight: 500;
  }
  .session-detail-date {
    margin-top: 18px;
    font-size: $fontSize6;
    color: $fontColor2;
  }
  .session-games {
    margin-top: 32px;
    font-size: $fontSize6;
    color: $fontColor2;
    line-height: 24px;
    li {
      display: flex;
      align-items: center;
      & + li {
        margin-top: 4px;
      }
      .game-star {
        position: relative;
        margin-left: 20px;
        color: $fontColor5;
        .icon-ic_star_fill {
          font-size: 16px;
          &.active {
            color: #faac00;
          }
        }
      }
    }
  }
}
</style>
