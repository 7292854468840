<template>
  <section class="ClinicRequest">
    <RequestPatient v-if="target === 'patient'" />
    <RequestTherapist v-else />
  </section>
</template>

<script>
import RequestPatient from "./components/RequestPatient";
import RequestTherapist from "./components/RequestTherapist";

export default {
  name: "ClinicRequest",
  components: {
    RequestPatient,
    RequestTherapist
  },
  computed: {
    target: function() {
      return this.$route.params.target;
    }
  },
  watch: {
    target: function(newVal, oldVal) {}
  }
};
</script>
