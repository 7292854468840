<template>
  <section class="ManagementTherapist">
    <div class="management-top">
      <div class="management-search">
        <div class="search-select">
          <Select
            v-model="selectedSortingType"
            :options="sortingOption"
            label="label"
            placeholder
            :components="{ OpenIndicator, Deselect }"
          />
        </div>
        <div class="search">
          <span class="icon-ic_search" @click="searchTherapist">
            <span class="path1" />
            <span class="path2" />
          </span>
          <input v-model="searchText" type="text" @keypress.enter="searchTherapist">
        </div>
      </div>
      <div
        class="control-btns default-type-btns small-btns"
        :class="checkedList.length < 1 ? 'disabled' : ''"
      >
        <button type="button" @click="handleDelete">{{ $t("adminPage.management.providerList.button.remove") }}</button>
      </div>
    </div>
    <table>
      <colgroup>
        <col width="50px">
        <col width="60px">
        <col width="180px">
        <col width="200px">
        <col width="200px">
        <col width="240px">
      </colgroup>
      <thead>
        <tr>
          <th>
            <div class="check-all">
              <button :class="checkAll" @click="handleAllCheck" />
            </div>
          </th>
          <th>No.</th>
          <th class="align-left">{{ $t("adminPage.management.providerList.list.header.name") }}</th>
          <th class="align-left">{{ $t("adminPage.management.providerList.list.header.email") }}</th>
          <th>{{ $t("adminPage.management.providerList.list.header.type") }}</th>
          <th>{{ $t("adminPage.management.providerList.list.header.patients") }}</th>
          <th />
        </tr>
      </thead>
    </table>
    <div class="custom-scrollbar table-scroll">
      <table>
        <colgroup>
          <col width="50px">
          <col width="60px">
          <col width="180px">
          <col width="200px">
          <col width="200px">
          <col width="240px">
        </colgroup>
        <tbody v-if="list.length > 0">
          <tr
            v-for="(item) of list"
            :key="item.index"
            :class="checkedList.indexOf(item.index) > -1 ? 'checked' : ''"
          >
            <th>
              <span>
                <input :id="item.index" v-model="checkedList" type="checkbox" :value="item.index">
                <label :for="item.index" />
              </span>
            </th>
            <td>{{ item.index }}</td>
            <td class="align-left">{{ item.name }}</td>
            <td class="align-left">{{ item.email }}</td>
            <td>{{ item.therapist_type ? item.therapist_type : "-" }}</td>
            <td class="patient-count">
              <button
                type="button"
                @click="handleGetPatients(item.therapist_id, item.name)"
              >{{ `${item.patients ? item.patients : 0} ${$t("adminPage.management.providerList.list.patients")}` }}</button>
            </td>
            <td class="align-right">
              <span class="delete-btn icon-btn">
                <button type="button" @click="handleDelete(item)">
                  <span class="icon-ic_bin">
                    <span class="path1" />
                    <span class="path2" />
                    <span class="path3" />
                    <span class="path4" />
                    <span class="path5" />
                  </span>
                </button>
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="8">{{ $t("adminPage.management.providerList.empty") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-pagination v-model="currentPage" :page-count="totalPage" />
  </section>
</template>

<script>
import Confirm from "@/components/Confirm";
import vPagination from "vue-plain-pagination";
import Select from "vue-select";
import MappingPatientList from "./../../MappingPatientList";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import {
  fetchGetClinicTherapist,
  fetchDeleteClinicTherapistList
} from "@/api/clinic.js";
import { mapGetters } from "vuex";
export default {
  name: "ManagementTherapist",
  components: {
    vPagination,
    Select
  },
  mixins: [SelectMixin, ErrorMixin],
  data() {
    return {
      currentPage: 1,
      size: 15,
      totalCount: 1,
      totalPage: 1,
      checkedList: [],
      searchText: "",
      sortingOption: [
        {
          label: this.$t("adminPage.management.providerList.selectOption.all"),
          value: "ALL"
        },
        {
          label: this.$t("adminPage.management.providerList.selectOption.name"),
          value: "NAME"
        },
        {
          label: this.$t("adminPage.management.providerList.selectOption.email"),
          value: "EMAIL"
        }
      ],
      selectedSortingType: {
        label: this.$t("adminPage.management.providerList.selectOption.all"),
        value: "ALL"
      },
      list: [],
      deleteTarget: ""
    };
  },
  computed: {
    checkAll: function() {
      const allCounts = this.list.length;
      const checkedCounts = this.checkedList.length;
      return checkedCounts === allCounts
        ? "all all-checkbox"
        : checkedCounts > 0
          ? "checked all-checkbox"
          : "all-checkbox";
    },
    ...mapGetters({
      clinic: "user/clinic"
    })
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchTherapist();
      }
    }
  },
  created() {
    this.searchTherapist();
  },
  methods: {
    async searchTherapist() {
      const search = {
        page: this.currentPage,
        size: this.size,
        keyword: this.searchText,
        search_type: this.selectedSortingType.value,
        sorts: [{ field: "time", order: "desc" }]
      };
      try {
        const result = await fetchGetClinicTherapist(this.clinic.id, search);
        this.totalCount = result.total_count < 1 ? 1 : result.total_count;
        this.totalPage = result.total_page < 1 ? 1 : result.total_page;
        const addIndex = result.list.map((item, index) => {
          item.index =
            result.total_count - index - (this.currentPage - 1) * this.size;
          return item;
        });
        this.list = addIndex;
      } catch (error) {
        this.handleError(
          error,
          "ManagementTherapist > Get Clinic Therapist API"
        );
      }
    },
    handleDelete(target) {
      if (target.id) {
        this.deleteTarget = target;
      } else if (!target.id && this.checkedList.length < 1) {
        console.log(this.checkedList);
        return;
      }
      this.$modal.show(
        Confirm,
        {
          type: "remove",
          submit: this.deleteSubmit,
          confirm: {
            title: this.$t("adminPage.management.providerList.removeModal.title"),
            message:
              this.$t("adminPage.management.providerList.removeModal.message")
          }
        },
        {
          height: "auto"
        }
      );
    },
    async deleteSubmit() {
      const clinicId = this.clinic.id;
      let target = [];
      if (this.checkedList.length < 1) {
        target = [
          {
            user_id: this.deleteTarget.id,
            target_id: this.deleteTarget.therapist_id
          }
        ];
      } else {
        this.list.forEach(item => {
          if (this.checkedList.includes(item.index)) {
            target.push({
              user_id: item.id,
              target_id: item.therapist_id
            });
          }
        });
      }
      try {
        const result = await fetchDeleteClinicTherapistList(clinicId, target);
        if (result) {
          this.checkedList = [];
          this.searchTherapist();
        }
      } catch (error) {
        this.handleError(
          error,
          "ManagementTherapist > Delete Clinic Therapist"
        );
      }
    },
    handleGetPatients(therapistId, therapistName) {
      this.$modal.show(
        MappingPatientList,
        {
          clinicId: this.clinic.id,
          therapistName,
          therapistId
        },
        {
          height: "auto",
          clickToClose: false
        }
      );
    },
    handleAllCheck() {
      const newChecked = [];
      if (
        this.checkedList.length === 0 ||
        this.checkedList.length !== this.list.length
      ) {
        this.list.forEach((item, index) => {
          newChecked.push(item.index);
        });
      }
      this.checkedList = newChecked;
    }
  }
};
</script>

<style lang="scss" scoped>
.ManagementTherapist {
  background: #ffffff;
  border: 1px solid $borderColor1;
  border-radius: 3px;
  .management-top {
    padding: 16px;
    display: flex;
    .management-search {
      display: flex;
      align-content: center;
      .search {
        padding: 0 16px;
        position: relative;
        .icon-ic_search {
          position: absolute;
          top: 50%;
          right: 32px;
          transform: translate(0, -50%);
          font-size: $fontSize2;
          color: $fontColor2;
        }
        input[type="text"] {
          padding-right: 55px;
          height: 35px;
          border-radius: 2px;
        }
      }
      .search-select {
        width: 110px;
        ::v-deep .v-select {
          background: #ffffff;
          .vs__dropdown-toggle {
            border-radius: 2px;
          }
          .vs__dropdown-menu {
            min-width: 110px;
          }
        }
      }
    }

    .control-btns {
      margin-left: auto;
      display: flex;
      justify-content: center;

      button {
        &:last-of-type {
          margin-left: 8px;
        }
      }
    }
  }
  .patient-count {
    button {
      text-decoration: underline;
      font-size: $fontSize7;
      color: $fontColor1;
    }
  }
  table {
    tbody {
      tr:first-of-type {
        th,
        td {
          border-top: 0 none;
        }
      }
    }
  }
}
</style>
