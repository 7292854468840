var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "therapist" } },
    [
      _c("GlobalMenu"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-view" },
        [
          _c("TopMenu", {
            attrs: {
              "handle-toggle-notification": _vm.handleToggleNotification,
              "handle-toggle-setting-time": _vm.handleToggleSettingTime
            }
          }),
          _vm._v(" "),
          _c("router-view", { ref: "therapistCalendar", staticClass: "view" })
        ],
        1
      ),
      _vm._v(" "),
      _vm.settingTime.view
        ? _c("SettingTime", {
            attrs: { "handle-close-setting-time": _vm.handleCloseSettingTime }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.notification.view
        ? _c("Notification", {
            attrs: { "handle-close-notification": _vm.handleCloseNotification }
          })
        : _vm._e(),
      _vm._v(" "),
      this.userClinic.name.toLowerCase() === "neofect"
        ? _c("div", { staticClass: "textchat-btn" }, [
            _c(
              "button",
              { attrs: { type: "button" }, on: { click: _vm.toggleChat } },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm.unreadMessageCount > 0
                  ? _c("span", { staticClass: "unread" }, [
                      _vm._v(_vm._s(_vm.unreadMessageCount))
                    ])
                  : _vm._e()
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      this.userClinic.name.toLowerCase() === "neofect"
        ? _c("TextChat")
        : _vm._e(),
      _vm._v(" "),
      _c("modals-container")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_message" }, [
      _c("span", { staticClass: "path1" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }