<template>
  <section class="ApprovalStatus">
    <div v-if="status === 0" class="contents">
      <h1>{{ $t("components.approveStatus.reviewing") }}</h1>
      <div id="requested-discription" class="discription">
        <div v-if="lang === 'ko'">
          가입 요청을 검토중입니다.<br>
          <span class="clinic-name">{{ clinic.name }}</span>의 승인을 기다려주세요.<br><br>
          클리닉을 변경하려면
          <a class="clickable" @click="handleClickHere">여기</a>를 클릭하세요.
        </div>
        <div v-if="lang === 'en'">
          Your sign up request is under review.<br>
          Please wait for the approval from
          <span class="clinic-name">{{ clinic.name }}</span><br><br>
          if you want to change your clinic, click
          <a class="clickable" @click="handleClickHere">here.</a>
        </div>
      </div>
    </div>
    <div v-if="status === 2" class="contents">
      <h1>{{ $t("components.approveStatus.denied") }}</h1>
      <div id="denied-discription" class="discription">
        <div v-if="lang === 'ko'">
          <span class="clinic-name">{{ clinic.name }}</span>
          으로부터 가입 요청이 거절되었습니다.<br>
          올바른 클리닉을 선택했는지 확인 후<br>
          <a
            class="clickable"
            @click="handleRequestReSent"
          >다시 요청해주세요.</a><br><br>
          클리닉을 변경하려면
          <a class="clickable" @click="handleClickHere">여기</a>를 클릭하세요.
        </div>
        <div v-if="lang === 'en'">
          Your sign up request has been denied by
          <span class="clinic-name">{{ clinic.name }}</span><br>
          Please check if you have chosen right clinic and
          <a
            class="clickable"
            @click="handleRequestReSent"
          >send a request again.</a>
          <br><br>
          if you want to change your clinic, click
          <a class="clickable" @click="handleClickHere">here.</a>
        </div>
      </div>
    </div>
    <div class="submit-btns">
      <button type="button" @click="handleClickLogout">
        {{ $t("components.approveStatus.logout") }}
      </button>
    </div>
  </section>
</template>
<script>
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import { fetchGetClinicReqStatus } from "@/api/clinic.js";
import { fetchGetTherapistInfo } from "@/api/user.js";
import { mapGetters } from "vuex";
import { fetchAddMyClinicTherapistRequest } from "@/api/clinic";
export default {
  name: "ApprovalStatus",
  mixins: [ErrorMixin],
  data() {
    return {
      status: null,
      clinic: {}
    };
  },
  methods: {
    handleClickLogout() {
      this.$store.dispatch("user/logout").then(() => {
        location.href = "/";
      });
    },
    async handleRequestReSent() {
      try {
        const req = await fetchAddMyClinicTherapistRequest(this.clinic.id);
        if (req) {
          this.handleClickLogout();
        }
      } catch (e) {
        this.handleError(e, "Send a request again");
      }
    },
    handleClickHere() {
      this.$router.push({
        name: "ChangeClinic",
        params: { clinic: this.clinic, status: this.status }
      });
    }
  },
  computed: {
    ...mapGetters({
      lang: "lang/language"
    })
  },
  async created() {
    try {
      const therapistInfo = await fetchGetTherapistInfo();
      if (therapistInfo.clinic_req) {
        this.status = therapistInfo.clinic_req.status;
        this.clinic = therapistInfo.clinic_req.clinic;
        this.$emit("status", therapistInfo.clinic_req.status);
        // 모달에서 페이지로 수정.
        // status는 (0 : 요청, 1 : 승인, 2 : 거절, 3: 취소) 입니다.
        if (therapistInfo.clinic_req.status === 1) {
          this.$router.push("/");
        }
      } else {
        const clinicReq = await fetchGetClinicReqStatus();
        this.status = clinicReq.status;
        this.clinic = clinicReq.clinic;
        this.$emit("status", clinicReq.status);
        // 모달에서 페이지로 수정.
        // status는 (0 : 요청, 1 : 승인, 2 : 거절, 3: 취소) 입니다.
        if (clinicReq.status === 1) {
          this.$router.push("/");
        }
      }
    } catch (e) {
      this.handleError(e, "Clinic Approval Status");
    }
  }
};
</script>
<style lang="scss" scoped>
.ApprovalStatus {
  max-width: 350px;
  width: 100%;
  margin: auto;
  margin-top: 130px;
  margin-bottom: 144px;
  color: $fontColor1;
  font-family: Manrope3;

  h1 {
    font-weight: 800;
    font-size: $fontSize1;
    letter-spacing: -0.4px;
  }

  .discription {
    margin-top: 45px;
    font-size: $fontSize5;
    line-height: 26px;
    color: $fontColor2;
  }
}
</style>
