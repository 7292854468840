export const SelectMixin = {
  data() {
    return {
      Deselect: {
        render: createElement =>
          createElement("span", { class: { del_btn: true }})
      },
      OpenIndicator: {
        render: createElement => createElement("span")
      }
    };
  }
};
