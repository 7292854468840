<template>
  <div class="CalendarSorting" :style="{height:`${windowHeight}px`}">
    <div class="sort-patient">
      <h2>{{ $t("page.calendar.sideBar.title") }}</h2>
      <div class="search">
        <span class="icon-ic_search">
          <span class="path1" />
          <span class="path2" />
        </span>
        <input v-model="searchText" type="text" :placeholder="searchPlaceholder" @keyup="handleSearch">
      </div>
      <div class="check-all">
        <button :class="checkAllPatients" @click="handleAllPatients">{{ $t("page.calendar.sideBar.selectAll") }}</button>
      </div>
      <ul class="check-list custom-scrollbar">
        <li v-for="(patient,index) of searchedPatients" :key="index">
          <input :id="patient.id" v-model="checkedPatients" type="checkbox" :value="patient.id">
          <label :for="patient.id">{{ patient.name ? patient.name : '-' }}</label>
        </li>
      </ul>
    </div>
    <div class="sort-type">
      <h2 class="sort-heading">{{ $t("page.calendar.sideBar.typeOfEvent") }}</h2>
      <div class="check-all">
        <button :class="checkAllTypes" @click="handleAllTypes">{{ $t("page.calendar.sideBar.selectAll") }}</button>
      </div>
      <ul class="check-list custom-scrollbar">
        <li v-for="(type,index) of eventTypes" :key="index">
          <input :id="type" v-model="checkedTypes" type="checkbox" :value="type">
          <label :for="type" :class="type">{{ typeFilter(type) }}</label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "CalendarSorting",
  props: {
    allPatients: Array,
    eventTypes: Array,
    windowHeight: Number,
    handleDetailPopupClose: Function,
    sortEventSources: Function,
    setEventSources: Function
  },
  data() {
    return {
      checkedPatients: [],
      checkedTypes: [],
      searchText: "",
      searchedPatients: []
    };
  },
  methods: {
    typeFilter: function(value) {
      let res = "";
      switch (value) {
        case "group1":
          res = this.$t("page.calendar.sideBar.type.videoCall");
          break;
        // case "group2": // "Co-op Game", 현재 코옵게임 옵션은 제거됨
        //   break;
        case "group3":
          res = this.$t("page.calendar.sideBar.type.screenSharing");
          break;
        case "group4":
          res = this.$t("page.calendar.sideBar.type.providerLive");
          break;
        case "group5":
          res = this.$t("page.calendar.sideBar.type.general");
          break;
        case "group6":
          res = this.$t("page.calendar.sideBar.type.videoCallRequest");
          break;
      }
      return res;
    },
    handleAllTypes: function() {
      let newChecked = [];
      if (
        this.checkedTypes.length === 0 ||
        this.checkedTypes.length !== this.eventTypes.length
      ) {
        this.eventTypes.forEach((type, index) => {
          newChecked.push(type);
        });
      }
      this.checkedTypes = newChecked;
    },
    handleAllPatients: function() {
      let newChecked = [];
      if (
        this.checkedPatients.length === 0 ||
        this.checkedPatients.length !== this.searchedPatients.length
      ) {
        this.searchedPatients.forEach((patient, index) => {
          newChecked.push(patient.id);
        });
      }
      this.checkedPatients = newChecked;
    },
    handleSearch: function(e) {
      const text = this.searchText;
      if (text.length < 1) {
        this.searchedPatients = this.allPatients;
        return;
      }
      const re = new RegExp(`${text}`, "i");
      let SearchedList = [];
      this.allPatients.forEach((patient, index) => {
        if (re.test(patient.name)) {
          SearchedList.push(patient);
        }
      });
      if (
        JSON.stringify(this.searchedPatients) === JSON.stringify(SearchedList)
      ) {
        return;
      }
      this.searchedPatients = SearchedList;
    }
  },

  watch: {
    checkedPatients: function(newVal, oldVal) {
      this.handleDetailPopupClose();
      const sortEventSources = this.sortEventSources(this.checkedTypes, newVal);
      this.setEventSources(sortEventSources);
    },
    checkedTypes: function(newVal, oldVal) {
      this.handleDetailPopupClose();
      const sortEventSources = this.sortEventSources(
        newVal,
        this.checkedPatients
      );
      this.setEventSources(sortEventSources);
    },
    searchedPatients: function(newVal, oldVal) {
      // 환자검색이 단순히 seach가 아니라 검색된것만 보여줘야하는경우
      // let newChecked = [];
      // newVal.forEach((patient, index) => {
      //   newChecked.push(patient.id);
      // });
      // this.checkedPatients = newChecked;
    },
    allPatients: function(newVal, oldVal) {
      // 새로고침시 created를 거치지 않고 값이 셋팅되어야하는 checkedPatient값을 init함
      this.searchedPatients = newVal;
      const allChecked = [];
      this.searchedPatients.forEach((patient, index) => {
        allChecked.push(patient.id);
      });
      this.checkedPatients = allChecked;
    },
    eventTypes: function(newVal, oldVal) {
      // 새로고침시 created를 거치지 않고 값이 셋팅되어야하는 checkedType값을 init함
      this.checkedTypes = newVal;
    }
  },
  computed: {
    searchPlaceholder: function() {
      return this.$t("page.calendar.sideBar.search")
    },
    checkAllTypes: function() {
      const allCounts = this.eventTypes.length;
      const checkedCounts = this.checkedTypes.length;
      return checkedCounts === allCounts
        ? "all all-checkbox"
        : checkedCounts > 0
        ? "checked all-checkbox"
        : "all-checkbox";
    },
    checkAllPatients: function() {
      const allCounts = this.searchedPatients.length;
      const checkedCounts = this.checkedPatients.length;
      return checkedCounts === allCounts
        ? "all all-checkbox"
        : checkedCounts > 0
        ? "checked all-checkbox"
        : "all-checkbox";
    }
  },
  created() {
    // console.log("CalendarSorting created");
    this.searchedPatients = this.allPatients;
    const allChecked = [];
    this.searchedPatients.forEach((patient, index) => {
      allChecked.push(patient.id);
    });
    this.checkedPatients = allChecked;
    this.checkedTypes = this.eventTypes;
  },
  mounted() {
    // console.log("CalendarSorting mounted");
  }
};
</script>

<style lang="scss" scoped>
.CalendarSorting {
  min-height: 790px;
  width: 250px;
  flex-shrink: 0;
  padding: 16px 16px 0 16px;
  margin-top: -60px;
  box-sizing: border-box;
  border-right: 1px solid $borderColor1;
  color: $fontColor1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  background: #f9f7f6;
  h2 {
    margin: 16px 8px 0 8px;
    font-weight: 500;
    &.sort-heading {
      margin-top: 32px;
    }
  }
  .search {
    input[type="text"] {
      line-height: 24px;
      border-radius: 24.5px;
    }
  }
  li {
    & + li {
      margin-top: 16px;
    }
  }
  .check-all {
    border-bottom: 1px solid $borderColor1;
    padding-bottom: 16px;
    margin: 0 8px;
  }
  .check-list {
    padding-top: 16px;
    margin: 0 8px;
  }
  .all-checkbox {
    margin-top: 32px;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: $fontSize6;

    &:focus {
      outline: none;
      border: 0 none;
    }
    &::before {
      border-color: #dcdfe6;
      background: none;
      color: rgba(255, 255, 255, 0);
    }
    &.all {
      &::before {
        content: "\e933";
        border-color: $mainColor1;
        background: $mainColor1;
        color: #ffffff;
      }
    }
    &.checked {
      &::before {
        content: "\e965";
        border-color: $mainColor1;
        background: $mainColor1;
        color: #ffffff;
      }
    }
  }
  input[type="checkbox"]:checked {
    & + label {
      &.group1 {
        &::before {
          border-color: $groupColor1;
          background: $groupColor1;
        }
      }
      &.group2 {
        &::before {
          border-color: $groupColor2;
          background: $groupColor2;
        }
      }
      &.group3 {
        &::before {
          border-color: $groupColor3;
          background: $groupColor3;
        }
      }
      &.group4 {
        &::before {
          border-color: $groupColor4;
          background: $groupColor4;
        }
      }
      &.group5 {
        &::before {
          border-color: $groupColor5;
          background: $groupColor5;
        }
      }
      &.group6 {
        &::before {
          border-color: $groupColor6;
          background: $groupColor6;
        }
      }
    }
  }
  label {
    display: block;
    font-size: $fontSize6;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: normal;
    box-sizing: border-box;
    &.group1 {
      &::before {
        border-color: $groupColor1;
      }
    }
    &.group2 {
      &::before {
        border-color: $groupColor2;
      }
    }
    &.group3 {
      &::before {
        border-color: $groupColor3;
      }
    }
    &.group4 {
      &::before {
        border-color: $groupColor4;
      }
    }
    &.group5 {
      &::before {
        border-color: $groupColor5;
      }
    }
    &.group6 {
      &::before {
        border-color: $groupColor6;
      }
    }
  }
  .sort-patient {
    height: calc(100% - 330px);
    .search {
      margin-top: 24px;
      position: relative;
      .icon-ic_search {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);
        font-size: $fontSize2;
        color: $fontColor2;
      }
      input[type="text"] {
        padding-left: 55px;
      }
    }
    .check-list {
      width: calc(100% + 8px);
      margin-top: 16px;
      padding-top: 0;
      height: calc(100% - 200px);
      box-sizing: border-box;
      overflow-y: auto;
    }
  }
  .sort-type {
    padding-bottom: 35px;
    margin-top: auto;
  }
}
</style>
