var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "Subscription" }, [
    _vm.patient
      ? _c("div", { staticClass: "patient-info" }, [
          _c("section", { staticClass: "info-section" }, [
            _c("h1", [
              _vm._v(_vm._s(_vm.$t("components.subscriptionInfo.title")))
            ]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("components.subscriptionInfo.name")))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        !_vm.patient.name
                          ? "-"
                          : _vm.patient.name.includes("null")
                          ? "-"
                          : _vm.patient.name
                      ) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("components.subscriptionInfo.email")))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.patient.email) + " ")
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("components.subscriptionInfo.plan")))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.patient.subscription.planName) + " ")
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.$t("components.subscriptionInfo.start")))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.customeDateTime(_vm.patient.subscription.startDate)
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("components.subscriptionInfo.expiration"))
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.customeDateTime(_vm.patient.subscription.expireDate)
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("components.subscriptionInfo.paymentMethod"))
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.marketPlace))
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("components.subscriptionInfo.paymentHistory"))
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.paymentList,
                        expression: "paymentList"
                      }
                    ],
                    attrs: { readonly: "" },
                    domProps: { value: _vm.paymentList },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.paymentList = $event.target.value
                      }
                    }
                  })
                ])
              ])
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "popup-btns" }, [
      _c(
        "button",
        {
          staticClass: "close-btn",
          attrs: { type: "button" },
          on: { click: _vm.handleClose }
        },
        [_vm._m(0)]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete ic-icon" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }