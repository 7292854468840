<template>
  <section class="report-section range-of-motion">
    <h1>{{ $t("page.patient.neofectHome.data.rangeOfMotion") }}</h1>
    <div class="report-contents">
      <div v-if="!isNoData" class="motion-btn-group">
        <div
          v-for="item in motionNameList"
          class="motion-btn"
          :class="{ on: selectedMotion === item }"
          @click="selectMotion(item)"
        >
          {{ item }}
        </div>
      </div>
      <div class="graph">
        <Loading v-if="loading" />
        <v-chart
          v-if="!isNoData"
          ref="chartRef"
          :options="chartOptions"
          style="width: 100%; height: 200px"
          :auto-resize="true"
        />
        <div v-if="isNoData" class="no-data">
          {{ $t("page.patient.neofectHome.data.empty") }}
        </div>
        <div v-if="error" class="error">Error!</div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { getGloveTrainedAroms } from "@/api/report.js";
import option from "./GloveAromTrend.js";
export default {
  name: "GloveAromTrend",
  components: {
    Loading
  },
  props: {
    device: {
      type: Object
    },
    dateRange: {
      type: Object
    },
    toDateRange: {
      type: Object
    },
    isRangeCompare: Boolean,
    isKids: Boolean
  },
  data() {
    return {
      chartOptions: null,
      isNoData: false,
      loading: false,
      error: false,
      motionNameList: [],
      motionAromObjectList: [],
      targetMotionAromObjectList: [],
      selectedMotion: null
    };
  },
  computed: {
    patient: function () {
      return this.$store.getters.selectedPatient;
    }
  },
  watch: {
    device(newValue, oldValue) {
      this.chartOptions = null;
      this.error = false;
      this.isNoData = false;
      this.drawChart();
    },
    dateRange(newValue, oldValue) {
      this.drawChart();
    },
    toDateRange(newValue, oldVAlue) {
      this.drawChart();
    },
    isRangeCompare(newValue, oldValue) {
      this.drawChart();
    },
    selectedMotion(newValue) {
      this.drawChartDetail();
    }
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    getFullDateArray(startDate, endDate, format = "YYYY-MM-DD") {
      let tempDate = this.$moment(startDate).format(format);
      endDate = this.$moment(endDate).format(format);

      const resDateArray = [];
      while (this.$moment(tempDate) <= this.$moment(endDate)) {
        resDateArray.push(tempDate);
        tempDate = this.$moment(tempDate).add(1, "d").format(format);
      }
      return resDateArray;
    },
    selectMotion(motion) {
      this.selectedMotion = motion;
    },
    drawChartDetail() {
      if (this.isNoData) return;

      const newObj = JSON.parse(JSON.stringify(this.motionAromObjectList));
      const isStandardListEmpty = this.motionAromObjectList.dates.length < 1;
      let isTargetListEmpty = false;

      // this.motionAromObjectList.motionMap
      const fingerAvgs = [];
      for (let i = 0; i < this.motionAromObjectList.motionMap.FINGER_INDEX_FLEX_EXT.length; ++i) {
        const avg = (this.motionAromObjectList.motionMap.FINGER_THUMB_FLEX_EXT[i] +
          this.motionAromObjectList.motionMap.FINGER_INDEX_FLEX_EXT[i] +
          this.motionAromObjectList.motionMap.FINGER_MIDDLE_FLEX_EXT[i] +
          this.motionAromObjectList.motionMap.FINGER_RING_FLEX_EXT[i] +
          this.motionAromObjectList.motionMap.FINGER_LITTLE_FLEX_EXT[i]) / 5;
        fingerAvgs.push(avg);
      }

      if (this.isRangeCompare) {
        isTargetListEmpty = this.targetMotionAromObjectList.dates.length < 1;
        // 각 날짜 별로 x를 만든다.
        // 총 3개의 경우의 수가 있다.
        // 1. 기준, 타겟 모두 있는 경우
        // 2. 기준만 있는 경우
        // 3. 타겟만 있는 경우
        if (!isStandardListEmpty && !isTargetListEmpty) {
          // x축 기준 date
          const standardFullDateArray = this.getFullDateArray(
            this.dateRange.startDate,
            this.dateRange.endDate
          );
          const targetFullDateArray = this.getFullDateArray(
            this.toDateRange.startDate,
            this.toDateRange.endDate
          );

          // 기준 dateList를 기준으로 targetDateList와 인덱스 매핑해서 타켓을 기준 날짜로 만들어준다.
          const targetIndexObjectList = this.targetMotionAromObjectList.dates.map(
            (item) => {
              const dateIndex = targetFullDateArray.findIndex(
                (fItem) => fItem === item
              );
              return standardFullDateArray[dateIndex];
            }
          );

          // 기준 date들과 타켓의 date를 합쳐서 소팅한다.
          let realDateList = this.motionAromObjectList.dates
            .concat(targetIndexObjectList)
            .map((item) => item)
            .sort(function (a, b) {
              a = new Date(a);
              b = new Date(b);
              return a - b;
            });

          realDateList = realDateList.filter(
            (item, index) => realDateList.indexOf(item) === index
          );

          // realDateList는 값이 있는 모든 범위이기 때문에 기준 데이터에 데이터가 없었으면 0으로 넣어준다.
          let originDataListCount = -1;
          const originDataList = realDateList.map((date) => {
            const item = this.motionAromObjectList.dates.find(
              (item) => item === date
            );
            if (item === undefined || item === null) return 0;
            originDataListCount++;
            if (
              this.motionAromObjectList.motionMap[this.selectedMotion][
                originDataListCount
              ] === null
            ) {
              return 0;
            }
            return this.motionAromObjectList.motionMap[this.selectedMotion][
              originDataListCount
            ];
          });

          // realDateList는 값이 있는 모든 범위이기 때문에 타겟 데이터에 데이터가 없었으면 0으로 넣어준다.
          let targetDataListCount = -1;
          const targetDataList = realDateList.map((date) => {
            const item = targetIndexObjectList.find((item) => item === date);
            if (item === undefined) return 0;
            targetDataListCount++;
            if (
              this.targetMotionAromObjectList.motionMap[this.selectedMotion][
                targetDataListCount
              ] === null
            ) {
              return 0;
            }
            return this.targetMotionAromObjectList.motionMap[
              this.selectedMotion
            ][targetDataListCount];
          });

          newObj.dates = realDateList;
          newObj.motionMap = {
            [this.selectedMotion]: originDataList,
            [`${this.selectedMotion}_TARGET`]: targetDataList
          };
        } else if (!isStandardListEmpty) {
          newObj.dates = this.motionAromObjectList.dates;
          newObj.motionMap = {
            [this.selectedMotion]: this.motionAromObjectList.motionMap[
              this.selectedMotion
            ]
          };
        } else {
          newObj.dates = this.targetMotionAromObjectList.dates;
          newObj.motionMap = {
            [this.selectedMotion]: this.targetMotionAromObjectList.motionMap[
              this.selectedMotion
            ]
          };
        }
      } else {
        newObj.dates = this.motionAromObjectList.dates;
        newObj.motionMap = {
          [this.selectedMotion]: this.motionAromObjectList.motionMap[
            this.selectedMotion
          ]
        };
      }

      if (this.isRangeCompare && isStandardListEmpty && !isTargetListEmpty) {
        newObj.lineColorMode = "singleTarget";
      } else {
        newObj.lineColorMode = "origin";
      }

      const fingerReg = /finger/i;
      const forearmReg = /forearm/i;
      const wristFlexExtReg = /wrist_flex_ext/i;

      if (fingerReg.test(this.selectedMotion)) {
        newObj.dataSymbol = "(per, %)";
        newObj.yRangeMax = 100;

        newObj.motionMap = { "Finger Flex./Ext.": fingerAvgs };
        newObj.motionMap[this.selectedMotion] = newObj.motionMap[this.selectedMotion].map(item => item * 100);
      } else if (forearmReg.test(this.selectedMotion)) {
        newObj.yRangeMax = 180;
      } else if (wristFlexExtReg.test(this.selectedMotion)) {
        newObj.yRangeMax = 160;
      } else {
        newObj.yRangeMax = 80;
      }

      this.chartOptions = option(newObj);
    },
    async drawChart() {
      if (!this.patient || !this.device) return;
      try {
        this.loading = true;
        this.isNoData = true;

        this.motionAromObjectList = await getGloveTrainedAroms(
          this.patient.id,
          this.device.id,
          this.$moment(this.dateRange.startDate).toISOString(),
          this.$moment(this.dateRange.endDate)
            .add(23, "hours")
            .add(59, "minutes")
            .add(59, "seconds")
            .toISOString()
        );

        if (this.isRangeCompare) {
          this.targetMotionAromObjectList = await getGloveTrainedAroms(
            this.patient.id,
            this.device.id,
            this.$moment(this.toDateRange.startDate).toISOString(),
            this.$moment(this.toDateRange.endDate)
              .add(23, "hours")
              .add(59, "minutes")
              .add(59, "seconds")
              .toISOString()
          );
        }

        const isStandardListEmpty = this.motionAromObjectList.dates.length < 1;
        let isTargetListEmpty = true;

        if (this.isRangeCompare) {
          isTargetListEmpty = this.targetMotionAromObjectList.dates.length < 1;
          this.isNoData = isStandardListEmpty && isTargetListEmpty;
        } else {
          this.isNoData = isStandardListEmpty;
        }
        if (this.isNoData) {
          this.loading = false;
          return;
        }

        // 내려온 모션 key중 _EG가 있으면 _GE로 바꿔준다.
        const forearmSupPro = /FOREARM_SUP_PRONATION/;
        const wristDev = /WRIST_DEVIATION_AG/;
        const wristFlexExt = /WRIST_FLEX_EXT_AG/;
        const wristDevGE = /WRIST_DEVIATION_EG/;
        const wristFlexExtGE = /WRIST_FLEX_EXT_EG/;

        if (this.isRangeCompare && !isTargetListEmpty) {
          Object.keys(this.targetMotionAromObjectList.motionMap).map((item) => {
            if (forearmSupPro.test(item)) {
              this.targetMotionAromObjectList.motionMap[
                item.replace("FOREARM_SUP_PRONATION", "Forearm Pron./Sup.")
              ] = this.targetMotionAromObjectList.motionMap[item];
              delete this.targetMotionAromObjectList.motionMap[item];
            }
          });
          Object.keys(this.targetMotionAromObjectList.motionMap).map((item) => {
            if (wristDev.test(item)) {
              this.targetMotionAromObjectList.motionMap[
                item.replace("WRIST_DEVIATION_AG", "Wrist U.D./R.D.")
              ] = this.targetMotionAromObjectList.motionMap[item];
              delete this.targetMotionAromObjectList.motionMap[item];
            }
          });
          Object.keys(this.targetMotionAromObjectList.motionMap).map((item) => {
            if (wristFlexExt.test(item)) {
              this.targetMotionAromObjectList.motionMap[
                item.replace("WRIST_FLEX_EXT_AG", "Wrist Flex./Ext.")
              ] = this.targetMotionAromObjectList.motionMap[item];
              delete this.targetMotionAromObjectList.motionMap[item];
            }
          });
          Object.keys(this.targetMotionAromObjectList.motionMap).map((item) => {
            if (wristDevGE.test(item)) {
              this.targetMotionAromObjectList.motionMap[
                item.replace("WRIST_DEVIATION_EG", "Wrist U.D./R.D. - G.E.")
              ] = this.targetMotionAromObjectList.motionMap[item];
              delete this.targetMotionAromObjectList.motionMap[item];
            }
          });
          Object.keys(this.targetMotionAromObjectList.motionMap).map((item) => {
            if (wristFlexExtGE.test(item)) {
              this.targetMotionAromObjectList.motionMap[
                item.replace("WRIST_FLEX_EXT_EG", "Wrist Flex./Ext. - G.E.")
              ] = this.targetMotionAromObjectList.motionMap[item];
              delete this.targetMotionAromObjectList.motionMap[item];
            }
          });
        }
        if (!isStandardListEmpty) {
          Object.keys(this.motionAromObjectList.motionMap).map((item) => {
            if (forearmSupPro.test(item)) {
              this.motionAromObjectList.motionMap[
                item.replace("FOREARM_SUP_PRONATION", "Forearm Pron./Sup.")
              ] = this.motionAromObjectList.motionMap[item];
              delete this.motionAromObjectList.motionMap[item];
            }
          });
          Object.keys(this.motionAromObjectList.motionMap).map((item) => {
            if (wristDev.test(item)) {
              this.motionAromObjectList.motionMap[
                item.replace("WRIST_DEVIATION_AG", "Wrist U.D./R.D.")
              ] = this.motionAromObjectList.motionMap[item];
              delete this.motionAromObjectList.motionMap[item];
            }
          });
          Object.keys(this.motionAromObjectList.motionMap).map((item) => {
            if (wristFlexExt.test(item)) {
              this.motionAromObjectList.motionMap[
                item.replace("WRIST_FLEX_EXT_AG", "Wrist Flex./Ext.")
              ] = this.motionAromObjectList.motionMap[item];
              delete this.motionAromObjectList.motionMap[item];
            }
          });
          Object.keys(this.motionAromObjectList.motionMap).map((item) => {
            if (wristDevGE.test(item)) {
              this.motionAromObjectList.motionMap[
                item.replace("WRIST_DEVIATION_EG", "Wrist U.D./R.D. - G.E.")
              ] = this.motionAromObjectList.motionMap[item];
              delete this.motionAromObjectList.motionMap[item];
            }
          });
          Object.keys(this.motionAromObjectList.motionMap).map((item) => {
            if (wristFlexExtGE.test(item)) {
              this.motionAromObjectList.motionMap[
                item.replace("WRIST_FLEX_EXT_EG", "Wrist Flex./Ext. - G.E.")
              ] = this.motionAromObjectList.motionMap[item];
              delete this.motionAromObjectList.motionMap[item];
            }
          });
        }

        const fingerReg = /finger/i;

        // 버튼 생성
        if (this.isRangeCompare && isStandardListEmpty) {
          if (this.isKids) {
            this.motionNameList = Object.keys(
              this.targetMotionAromObjectList.motionMap
            ).filter((item) => !fingerReg.test(item));
          } else {
            this.motionNameList = Object.keys(
              this.targetMotionAromObjectList.motionMap
            ).filter((item) => !fingerReg.test(item));
            this.motionNameList.push("Finger Flex./Ext.");
          }
        } else {
          if (this.isKids) {
            this.motionNameList = Object.keys(
              this.motionAromObjectList.motionMap
            ).filter((item) => !fingerReg.test(item));
          } else {
            this.motionNameList = Object.keys(
              this.motionAromObjectList.motionMap
            ).filter((item) => !fingerReg.test(item));
            this.motionNameList.push("Finger Flex./Ext.");
          }
        }

        // 최신정렬로 내려온다... 리버싱해준다.
        if (!isTargetListEmpty) {
          this.targetMotionAromObjectList.dates = this.targetMotionAromObjectList.dates.reverse();
          this.motionNameList.map((item) => {
            if (this.targetMotionAromObjectList.motionMap[item] !== undefined) {
              this.targetMotionAromObjectList.motionMap[
                item
              ] = this.targetMotionAromObjectList.motionMap[item]
                .reverse()
                .map((zeroItem) => {
                  return zeroItem === null ? 0 : zeroItem;
                });
            }
          });
        }
        if (!isStandardListEmpty) {
          this.motionAromObjectList.dates = this.motionAromObjectList.dates.reverse();
          this.motionNameList.map((item) => {
            if (this.motionAromObjectList.motionMap[item] !== undefined) {
              this.motionAromObjectList.motionMap[
                item
              ] = this.motionAromObjectList.motionMap[item]
                .reverse()
                .map((zeroItem) => {
                  return zeroItem === null ? 0 : zeroItem;
                });
            }
          });
        }

        this.selectedMotion = this.motionNameList[0];

        this.drawChartDetail();
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.range-of-motion {
  .motion-btn-group {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 10px;
    .motion-btn {
      font-size: 14px;
      border: 1px solid #d0d4dc;
      padding: 10px 5px;
      text-align: center;
      background-color: white;
      color: gray;
      cursor: pointer;
      &.on {
        background-color: #f0734d;
        color: white;
      }
    }
  }
  .graph {
    position: relative;
    height: calc(250px - 48px);
    .no-data,
    .error {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      &.error {
        z-index: 2;
      }
    }
  }
}
</style>
