<template>
  <div id="therapist">
    <GlobalMenu />
    <div class="main-view">
      <TopMenu
        :handle-toggle-notification="handleToggleNotification"
        :handle-toggle-setting-time="handleToggleSettingTime"
      />
      <router-view ref="therapistCalendar" class="view" />
    </div>
    <SettingTime v-if="settingTime.view" :handle-close-setting-time="handleCloseSettingTime" />
    <Notification v-if="notification.view" :handle-close-notification="handleCloseNotification" />
    <!-- TextChat -->
    <div v-if="this.userClinic.name.toLowerCase() === 'neofect'" class="textchat-btn">
      <button type="button" @click="toggleChat">
        <span class="icon-ic_message">
          <span class="path1" />
        </span>
        <span v-if="unreadMessageCount > 0" class="unread">{{ unreadMessageCount }}</span>
      </button>
    </div>
    <TextChat v-if="this.userClinic.name.toLowerCase() === 'neofect'" />
    <modals-container />
  </div>
</template>
<script>
/* eslint-disable */
import SettingTime from "@/components/SettingTime";
import GlobalMenu from "@/components/GlobalMenu";
import TopMenu from "@/components/TopMenu";
import Timepicker from "vue2-timepicker";
import Select from "vue-select";
import Datepicker from "vuejs-datepicker";
import Notification from "@/components/Notification";
import TextChat from "@/views/chat/components/Chat";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    SettingTime,
    GlobalMenu,
    TopMenu,
    Timepicker,
    Select,
    Datepicker,
    Notification,
    TextChat
  },

  data() {
    return {
      settingTime: {
        view: false
      },
      notification: {
        view: false
      }
    };
  },
  computed: {
    ...mapGetters({
      unreadMessageCount: "chat/unreadMessageCount",
      userClinic: "user/clinic"
    })
  },
  methods: {
    handleToggleSettingTime() {
      this.handleCloseNotification();
      this.settingTime.view = !this.settingTime.view;
    },
    handleCloseSettingTime() {
      this.settingTime.view = false;
    },
    handleCloseNotification() {
      this.notification.view = false;
    },
    handleToggleNotification() {
      this.handleCloseSettingTime();
      this.notification.view = !this.notification.view;
    },
    ...mapActions({
      toggleChat: "chat/toggleTextChat"
    })
  }
};
</script>
<style lang="scss">
#therapist {

  display: flex;
  font-family: "manrope3";
  min-width: 1000px;
  min-height: 690px;
  position: relative;
  .main-view {
    width: 100%;
  }
  .textchat-btn {
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 50;
    button {
      width: 48px;
      height: 48px;
      background: $mainColor1;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
      position: relative;
      .icon-ic_message {
        color: #ffffff;
        font-size: 28px;
      }
      .unread {
        position: absolute;
        top: -8px;
        right: -10px;
        color: $fontColor2;
        font-size: $fontSize6;
        line-height: $fontSize6;
        padding: 6px 8px;
        background: $uiColor5;
        border-radius: 50%;
        min-width: 10px;
        display: inline-block;
        text-align: center;
      }
    }
  }
}
</style>
