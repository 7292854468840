<template>
  <div class="medical-options">
    <section class="MedicalRecords">
      <h1>{{ $t("page.videoCall.memo.sessionrecords") }}</h1>
      <!-- 메모 타입 라디오 -->
      <div class="contents">
        <div class="radio-section">
          <span v-for="(type, index) of memoTypes" :key="index">
            <input
              :id="type.value"
              v-model="pickedType"
              type="radio"
              :value="type.value"
            >
            <label :for="type.value">{{ type.type }}</label>
          </span>
        </div>
        <div class="memo-title">
          <input v-model="title" type="text" placeholder="Enter the title">
        </div>
        <div class="memo-contents">
          <textarea v-model="contents" placeholder="Enter Memo" />
        </div>
        <!-- 서밋버튼 -->
        <div
          class="secondary-type-btns"
          :class="submitDisable ? 'disabled' : ''"
        >
          <button
            type="button"
            class="accept-btn"
            :disabled="submitDisable"
            @click="handleSubmit"
          >
            Save
          </button>
        </div>
      </div>
    </section>

    <section class="MedicalRemote">
      <!-- 리모트 요청 -->
      <!-- <div class="tabs">
        <button class="tab active">Smart rehab</button>
      </div> -->
      <div class="line" />
      <h1>Smart rehab</h1>
      <div class="contents">
        <div class="start-rehab-content">
          <p v-if="!gameCategories[0]">실행 가능한 게임이 없습니다.</p>
          <ul v-if="gameCategories[0]" class="tree-view">
            <li
              v-for="(category, idx) in gameCategories"
              :key="idx"
              class="depth1"
            >
              <div class="depth-box">
                <a
                  class="depth1-button"
                  @click="category.isOpen = !category.isOpen"
                >{{ category.deviceName }}</a>
              </div>
              <ul
                v-if="category.isOpen && !isEmpty(category.middleCategories)"
                class="sub-depth"
              >
                <template
                  v-for="(middelCategory, idx) in category.middleCategories"
                >
                  <li
                    v-if="!isEmpty(middelCategory.subClasses)"
                    :key="idx"
                    class="depth2"
                  >
                    <div class="depth-box">
                      <a
                        class="depth2-button"
                        @click="middelCategory.isOpen = !middelCategory.isOpen"
                      >{{ middelCategory.motionType }}</a>
                    </div>
                    <ul v-if="middelCategory.isOpen" class="sub-depth">
                      <li
                        v-for="(subClass, idx) in middelCategory.subClasses"
                        :key="idx"
                        class="depth3"
                      >
                        <div class="depth-box">
                          <a
                            class="depth3-button"
                            @click="() => setGame(subClass)"
                          >{{ subClass.title }}</a>
                        </div>
                      </li>
                    </ul>
                  </li>
                </template>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <div v-if="selectedGame" class="game-remote-modal-wrap">
      <div class="game-remote-modal">
        <div class="modal-options">
          <a class="close-button" @click="selectedGame = null">
            <img src="@/assets/svgs/ic_delete.svg" alt="ic_delete-image">
          </a>
        </div>
        <div class="modal-header">
          <h1>{{ selectedGame.title || "Game Setting" }}</h1>
          <div class="game-info">
            <div class="game-thumbnail">
              <span v-if="!selectedGame.thumbnailUrl" class="no-image">
                <svg
                  width="78"
                  height="58"
                  viewBox="0 0 78 58"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="78" height="58" fill="#EBECF0" />
                  <g opacity="0.55">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M30 36.2821C30 41.2527 34.0295 45.2822 39.0001 45.2822C43.9705 45.2822 48 41.2527 48 36.2821C48 31.3118 43.9705 27.2822 39.0001 27.2822C34.0295 27.2822 30 31.3118 30 36.2821Z"
                      fill="url(#paint0_linear)"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M39.0001 37.4795C43.9705 37.4795 48 33.4503 48 28.4796C48 23.509 43.9705 19.4795 39.0001 19.4795C34.0295 19.4795 30 23.509 30 28.4796C30 33.4503 34.0295 37.4795 39.0001 37.4795Z"
                      fill="url(#paint1_linear)"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M38.9999 30.0003C34.0295 30.0003 30 25.9708 30 21.0001C30 16.0295 34.0295 12 38.9999 12C43.9705 12 48 16.0295 48 21.0001C48 25.9708 43.9705 30.0003 38.9999 30.0003Z"
                      fill="#909399"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="47.849"
                      y1="45.193"
                      x2="47.849"
                      y2="27.4951"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#909399" stop-opacity="0.5" />
                      <stop
                        offset="1"
                        stop-color="#909399"
                        stop-opacity="0.3"
                      />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear"
                      x1="30"
                      y1="19.4797"
                      x2="30"
                      y2="37.4797"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#909399" stop-opacity="0.5" />
                      <stop
                        offset="1"
                        stop-color="#909399"
                        stop-opacity="0.7"
                      />
                    </linearGradient>
                  </defs></svg></span>
              <img
                v-if="selectedGame.thumbnailUrl"
                :src="selectedGame.thumbnailUrl"
                :alt="selectedGame.title"
              >
            </div>
            <div class="game-descriptions">
              {{ selectedGame.contentDescription }}
            </div>
          </div>
        </div>
        <div class="modal-contents">
          <div class="options-wrap">
            <div class="options-box">
              <div v-if="selectedGame.levels.length > 1" class="level-box">
                <h4>Difficulty</h4>

                <Slider
                  :options="{
                    min: 1,
                    max: selectedGame.levels.length,
                    obj: selectedGame,
                    key: 'level',
                    data: selectedGame.levels.map((item, idx) => ({
                      label: `LEVEL ${idx + 1}`,
                      value: idx + 1,
                    })),
                  }"
                />
              </div>
              <div class="detail-box">
                <div class="detail-item">
                  <Counter
                    :options="{
                      title: rangeOfMotionTitle,
                      min: 10,
                      max: 90,
                      step: 10,
                      range: ' 10% ~ 90% ',
                      obj: selectedGame,
                      key: 'rangeOfMotion',
                    }"
                  />
                </div>
                <div class="detail-item">
                  <Counter
                    :options="{
                      title: playTime,
                      min: 1,
                      max: 7,
                      step: 1,
                      range: ' 1min ~ 7min ',
                      obj: selectedGame,
                      key: 'playTime',
                    }"
                  />
                </div>
                <div class="detail-item">
                  <Counter
                    :options="{
                      title: holdTime,
                      min: 1,
                      max: 10,
                      step: 1,
                      range: ' 1sec ~ 10sec ',
                      obj: selectedGame,
                      key: 'holddingTime',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="modal-button play-game-button"
            @click="
              () => {
                // selectedGame 정보 필요
                remoteRequestGame(selectedGame);
                selectedGame = null;
              }
            "
          >
            Play game
          </button>
          <button class="modal-button" @click="selectedGame = null">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
/* eslint-disable vue/html-self-closing */

import Counter from "@/components/Counter";
import Slider from "@/components/Slider";
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";
import { getGameInfo } from "@/api/game.js";

export default {
  components: { Counter, Slider },
  name: "MedicalRecords",
  props: {
    patient: Object,
    zoneOffset: Number,
    submit: Function,
    remoteRequestGame: Function,
    gameCategories: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rangeOfMotion: 10,
      selectedGame: null,
      memoTypes: [
        {
          type: this.$t("page.videoCall.memo.noteforthepatient"),
          value: "COACHINGSESSION",
        },
        {
          type: this.$t("page.videoCall.memo.videosession"),
          value: "VIDEOSESSION",
        },
      ],
      pickedType: "COACHINGSESSION",
      title: "",
      contents: "",
    };
  },
  methods: {
    isEmpty,

    minus(value, step, min) {
      if (value > min) {
        value -= step;
      }
      return value;
    },
    plus(value, step, max) {
      if (value < max) {
        value += step;
      }
      return value;
    },
    handleSubmit() {
      const newMemo = {
        division: this.pickedType,
        title: this.title,
        content: this.contents,
        patient: this.patient,
        zone_offset: this.zoneOffset,
        id: this.memo ? this.memo.id : "",
        organization_id: this.clinic.id,
      };
      // console.log(newMemo);
      this.submit(newMemo);
    },
    async setGame(subClass) {
      subClass.rangeOfMotion = 10;

      subClass.playTime = 1;
      if (subClass.contentId === "RHYTHM_WORLD") {
        subClass.playTime = 3;
        subClass.playTime_disabled = true;
      }
      subClass.holddingTime = 1;
      subClass.level = 1;
      this.selectedGame = subClass;
      const info = await getGameInfo(subClass, this.lang);
      this.selectedGame = { ...subClass, ...info[0] };
      console.log(this.selectedGame);
    },
  },
  computed: {
    ...mapGetters({
      clinic: "user/clinic",
    }),
    ...mapGetters({
      lang: "lang/language",
    }),
    submitDisable: function () {
      return this.title.length < 1 || this.contents.length < 1;
    },
    rangeOfMotionTitle() {
      return this.$t("page.videoCall.remoteTraining.rangeofmotion");
    },
    playTime() {
      return this.$t("page.videoCall.remoteTraining.playtime");
    },
    holdTime() {
      return this.$t("page.videoCall.remoteTraining.holdtime");
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-top: 24px;
  padding-left: 20px;
  line-height: 20px;
  font-size: 17px;
  font-weight: bold;
  color: #303133;
}
.medical-options {
  height: 100%;
}

.MedicalRecords {
  position: relative;
  padding: 24px;
  height: 50%;
  box-sizing: border-box;
  h1 {
    margin-top: 0;
    padding-left: 0;
  }

  .contents {
    margin-top: 30px;
    height: calc(100% - 46px);

    .radio-section {
      overflow: hidden;
      font-size: $fontSize6;

      span {
        display: block;

        &:last-of-type {
          margin-top: 8px;
        }
      }
    }

    .memo-title {
      margin-top: 40px;
    }

    .memo-contents {
      margin-top: 16px;
      position: relative;
      height: calc(100% - 196px);

      textarea {
        height: 100%;
      }
    }

    .secondary-type-btns {
      margin-top: 16px;
      display: flex;

      button {
        width: 114px;
        line-height: 38px;
        padding: 0;
        border-radius: 4px;
        border: solid 1px #d0d4dc;
        background-color: #ffffff;
        margin-left: auto;

        font-size: 14px;
        font-weight: 500;
        color: #606266;
      }

      &.disabled button {
        background: #ffffff;
        color: #d0d4dc;
        border-color: #ffffff;
        cursor: default;
      }
    }
  }
}

.MedicalRemote {
  height: 50%;
  position: relative;
  box-sizing: border-box;

  .tabs {
    background: $popupBgColor1;
    padding: 16px;
    padding-bottom: 0;
    border-bottom: 1px solid $borderColor1;

    &::after {
      content: "";
      display: block;
      clear: both;
    }

    .tab {
      position: relative;
      top: 1px;
      border: 1px solid $popupBgColor1;
      border-bottom: 0;
      width: 50%;
      float: left;
      line-height: 44px;

      &.active {
        border: 1px solid $borderColor1;
        border-bottom: 0;
        background: #ffffff;
        border-radius: 10px 10px 0 0;
      }
    }
  }

  .line {
    height: 16px;
    background: #f4f5f7;
  }

  .contents {
    margin-top: 29px;
    padding: 0 20px;
    height: calc(100% - 89px);
    overflow: scroll;

    .tree-view {
      li {
        a {
          cursor: pointer;
          display: block;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;

          &:hover {
            text-decoration: underline;
          }
        }

        .depth-box {
          padding: 5px 0;

          & > a {
            font-weight: bold;
            color: #606266;
          }
        }

        .sub-depth {
          li {
            padding-left: 25px;
            position: relative;

            &::before,
            &::after {
              content: "";
              display: block;
              position: absolute;
              left: 10px;
              top: 0;
              background: #d0d4dc;
            }

            &::before {
              width: 1px;
              height: 100%;
            }

            &::after {
              top: 13px;
              height: 1px;
              width: 6px;
            }

            &:last-child {
              &::before {
                top: -13px;
              }
            }
          }

          a {
            font-weight: 400;
            color: #626980;
          }
        }
      }
    }
  }
}

.game-remote-modal-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;

  .game-remote-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    min-height: 671px;
    border-radius: 12px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #d0d4dc;
    background-color: #ffffff;
    overflow: hidden;

    .modal-options {
      height: 50px;
      text-align: right;
      padding: 16px 16px 0;
      box-sizing: border-box;

      a {
        cursor: pointer;
      }
    }

    .modal-header {
      padding: 0 24px;

      h1 {
        margin-top: 0;
        line-height: 32px;
        font-size: 24px;
        font-weight: bold;
        color: #303133;
      }

      .game-info {
        margin-top: 26px;
        overflow: hidden;

        .game-thumbnail {
          float: left;
          width: 78px;

          .no-image {
            display: block;
            width: 100%;
            // line-height: 58px;
            text-align: center;
            font-size: 10px;
            color: gray;
          }
          img {
            width: 100%;
          }
        }

        .game-descriptions {
          float: right;
          width: 308px;
          height: 80px;
          overflow: auto;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.63;
          color: #606266;
        }
      }
    }

    .modal-contents {
      margin-top: 40px;
      padding: 0 24px;

      .options-wrap {
        height: 330px;
        border-radius: 8px;
        background-color: #f4f5f7;
        padding: 24px;
        box-sizing: border-box;

        .options-box {
          overflow: hidden;

          h4 {
            font-size: 12px;
            font-weight: bold;
            line-height: 16px;
            color: #606266;
            padding-bottom: 8px;
          }

          .level-box {
            width: 166px;
            float: left;
            overflow: hidden;
            h4 {
              padding-bottom: 20px;
            }
          }

          .detail-box {
            width: 188px;
            float: left;

            .detail-item {
              margin-top: 24px;

              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .modal-footer {
      margin-top: 24px;
      text-align: right;
      padding: 24px;
      background-color: #f4f5f7;

      .modal-button {
        line-height: 40px;
        font-size: 14px;
        font-weight: 600;
        color: #606266;
      }

      .play-game-button {
        text-align: center;
        margin-right: 12px;
        width: 122px;
        border-radius: 4px;
        background: #f0734d;
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
}
</style>
