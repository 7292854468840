<template>
  <div class="Advice" v-if="adviceList.length > 0" @scroll="handleScroll">
    <div class="contents">
      <div class="advice-new-btn secondary-type-btns">
        <button type="button" @click="handleCreateAdvice">{{ $t("page.patient.advice.newAdviceButton") }}</button>
      </div>
      <div class="advice-list">
        <ul>
          <li v-for="(advice, index) of adviceList" :key="index">
            <div class="advice-info">
              <span class="advice-title">
                {{
                `${ $t("page.patient.advice.list.title") } #${totalCount - index}`
                }}
              </span>
              <span class="advice-date">{{ $moment(advice.created).format("MMMM D, YYYY h:mm A") }}</span>
            </div>
            <div class="advice-contents">{{ advice.content }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="Advice no-written" v-else>
    <div class="contents">
      {{ $t("page.patient.advice.noAdviceYet") }}
      <div class="advice-new-btn secondary-type-btns">
        <button type="button" @click="handleCreateAdvice">{{ $t("page.patient.advice.newAdviceButton") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { fetchCreateAdvice, fetchGetAdvice } from "@/api/patient.js";
import CreateAdvice from "./components/CreateAdvice";
import { mapGetters } from "vuex";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
export default {
  name: "Advice",
  mixins: [ErrorMixin],
  data() {
    return {
      adviceList: [],
      totalCount: 0,
      size: 20,
      page: 1,
      totalPage: 1
    };
  },
  methods: {
    handleCreateAdvice() {
      return this.$modal.show(
        CreateAdvice,
        {
          patient: this.selectedPatient,
          title: `${this.$t("page.patient.advice.modal.title")} #${this.totalCount + 1}`,
          submit: this.createAdvice
        },
        {
          height: "auto",
          clickToClose: false
        }
      );
    },
    async createAdvice(patient, contents) {
      // 새로운 advice 생성
      try {
        const result = await fetchCreateAdvice(
          patient,
          contents,
          this.clinic.id
        );
        if (result) {
          // console.log(result);
          let newAdvice = [result, ...this.adviceList];
          if (newAdvice.length > this.page * this.size) {
            if (this.totalPage === 1) {
              this.totalPage += 1;
            }
            newAdvice.pop();
          }
          this.adviceList = newAdvice;
          this.totalCount += 1;
        }
      } catch (error) {
        this.handleError(error, "Advice > Create Advice API");
      }
    },
    async getAdviceList() {
      // advice list 가져오기
      try {
        const patientId = this.selectedPatient.id;
        const result = await fetchGetAdvice(
          patientId,
          this.size,
          this.page,
          this.clinic.id
        );
        if (result) {
          this.adviceList = this.adviceList.concat(result.datas);
          this.totalCount = result.total_count;
          this.totalPage = result.total_page;
        }
      } catch (error) {
        this.handleError(error, "Advice > Get Advice List API");
      }
    },
    handleScroll(e) {
      const {
        target: { scrollTop, clientHeight, scrollHeight }
      } = e;
      if (scrollTop + clientHeight >= scrollHeight) {
        // lazyload, scrollbottom 발생시 advice list가져오기
        // console.log("bottom", this.page);
        if (this.page === this.totalPage) return;
        this.page += 1;
        this.getAdviceList();
      }
    },
    initAdviceList() {
      this.adviceList = [];
      this.page = 1;
      this.totalCount = 0;
      this.totalPage = 1;
    }
  },
  watch: {
    selectedPatient: function(newVal, oldVal) {
      this.initAdviceList();
      if (this.selectedPatient) {
        this.getAdviceList();
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedPatient: "patient/selectedPatient",
      clinic: "user/clinic"
    })
  },
  created() {
    if (this.selectedPatient) {
      this.getAdviceList();
    }
  },
  updated() {}
};
</script>

<style lang="scss" scoped>
.Advice {
  padding: 40px 24px;
  font-size: $fontSize6;
  &.no-written {
    .contents {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $fontColor3;
      text-align: center;
      .advice-new-btn {
        margin-top: 24px;
      }
    }
  }
  .advice-list {
    width: 100%;
    max-width: 620px;
    margin-top: 24px;
    li {
      border-top: 1px solid $borderColor1;
      padding: 16px 0 40px 0;

      & + li {
        margin-top: 8px;
      }
      .advice-info {
        display: flex;
        .advice-title {
          color: $fontColor1;
        }
        .advice-date {
          color: $fontColor3;
          margin-left: auto;
        }
      }

      .advice-contents {
        margin-top: 14px;
        display: block;
        color: $fontColor2;
        line-height: 22px;
        white-space: pre-wrap;
        word-break: normal;
      }
    }
  }
}
</style>
