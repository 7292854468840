var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "ClinicMapping" }, [
    _c("div", { staticClass: "mapping-test" }, [
      _c(
        "button",
        {
          class: _vm.target === "Stroke coach" ? "on" : "",
          on: {
            click: function($event) {
              return _vm.convertTap("Stroke coach")
            }
          }
        },
        [
          _vm._v(
            _vm._s(
              _vm.$t("adminPage.management.mapping.tabTitle.providerPatient")
            )
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          class: _vm.target === "patient" ? "on" : "",
          on: {
            click: function($event) {
              return _vm.convertTap("patient")
            }
          }
        },
        [
          _vm._v(
            _vm._s(
              _vm.$t("adminPage.management.mapping.tabTitle.patientProvider")
            )
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mapping-view" },
      [
        _vm.target === "Stroke coach"
          ? _c("MappingTherapist")
          : _c("MappingPatient")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }