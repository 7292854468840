import settings from "@/settings";

const sendBirdProfile = settings.sendBirdProfile;

export function getPersonalChannelName(patientId) {
  return `personal_channel_${patientId}`;
}

export function getMessage(message) {
  const sender = message.sender !== undefined ? message.sender : message._sender;
  return { id: message.messageId, userId: sender.userId, nickname: sender.nickname, message: message.message, createdAt: message.createdAt };
}

export function getEnvProfile() {
  return sendBirdProfile;
}

