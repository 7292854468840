<template>
  <div class="EditName">
    <section>
      <div class="contents">
        <h1>{{ $t("page.profile.editName.editName") }}</h1>
        <div class="name">
          <label for="name">{{ $t("page.profile.editName.name") }}</label>
          <input
            id="name"
            v-model="name"
            :placeholder="inputPlaceholder"
            type="text"
          >
          <a v-if="this.name" class="delete-icon" @click="handleRemoveName" />
        </div>
      </div>
      <div class="popup-submit-btns" :class="submitDisable ? 'disabled' : ''">
        <button type="button" class="accept-btn" @click="handleSubmit">
          {{ $t("page.profile.editName.save") }}
        </button>
        <button type="button" class="cancel-btn" @click="handleClose">
          {{ $t("page.profile.editName.cancel") }}
        </button>
      </div>
      <div class="popup-btns">
        <!-- 팝업 컨트롤 버튼 -->
        <button type="button" class="close-btn icon-btn" @click="handleClose">
          <span class="icon-ic_delete">
            <span class="path1" />
            <span class="path2" />
          </span>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import { fetchUpdateTherapistName } from "@/api/user.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";

export default {
  name: "EditName",
  mixins: [ErrorMixin],
  props: {
    fullName: String,
    therapistId: Number
  },
  data() {
    return {
      name: "",
      id: ""
    };
  },
  computed: {
    submitDisable: function () {
      return isEmpty(this.name) || this.name === this.fullName;
    },
    inputPlaceholder: function () {
      return this.$t("page.profile.editName.placeholder");
    }
  },
  created() {
    this.name = this.fullName;
    this.id = this.therapistId;
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    async handleSubmit() {
      if (this.submitDisable) return;

      try {
        const therapistName = {
          name: this.name,
          clinic_req_id: this.id
        };
        await fetchUpdateTherapistName(therapistName);
        this.handleClose();
        window.location.reload();
      } catch (error) {
        this.handleError(error, "Profile > Edit User Name API");
      }
    },
    handleRemoveName() {
      this.name = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.EditName {
  font-family: Manrope;
  .contents {
    padding: 40px 24px 40px 24px;

    h1 {
      font-weight: bold;
      color: $fontColor1;
      font-size: $fontSize2;
      line-height: 32px;
    }

    .name {
      margin-top: 24px;
      position: relative;

      label {
        font-style: normal;
        font-weight: bold;
        font-size: $fontSize7;
        color: $fontColor2;
        line-height: 20px;
      }

      input {
        margin-top: 8px;
        height: 36px;
        border: 1px solid #d0d4dc;
        box-sizing: border-box;
        border-radius: 2px;
      }

      .delete-icon {
        cursor: pointer;
        display: inline-block;
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(-10px, -8px);
        width: 20px;
        height: 20px;
        background-color: #666666;
        border-radius: 100%;

        &:before,
        &:after {
          content: "";
          position: absolute;
          top: 9px;
          left: 4px;
          background-color: #fff;
          width: 12px;
          height: 2px;
          border-radius: 3px;
        }

        &:before {
          transform: rotate(-45deg);
        }

        &:after {
          transform: rotate(45deg);
        }
      }
    }
  }

  .popup-submit-btns {
    .accept-btn {
      min-width: 84px;
    }
  }
}
</style>
