<template>
    <section class="report-section glove-training-report">
      <div class="report-contents">
        <Loading v-if="loading" />
        <div v-if="!isNoData" class="content-grid">
          <div class="content-grid-row" v-for="item in reportList">
             <div class="content-grid-row-item-text"> {{item.text}} </div>
             <div class="content-grid-row-item-sum"> {{item.sum}} <span class="unit"> {{item.sumUnit}} </span> </div>
             <div class="content-grid-row-item-gap" v-if="isRangeCompare" v-bind:class="{minus: item.gap < 0, plus: item.gap > 0}">
               <span class="arrow" v-bind:class="{down: item.gap < 0, up: item.gap > 0}"></span>
               {{removeNumberMinusSign(item.gap)}} </div>
             <div class="content-grid-row-item-gap" v-else>
               -
             </div>
          </div>
        </div>
        <div v-if="isNoData" class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
        <div v-if="error" class="error">Error!</div>
      </div>
    </section>
</template>

<script>
  import { getTrainedSessionsSummary } from "@/api/report.js";
  import Loading from "@/components/Loading";
  import { mapGetters } from "vuex";
    export default {
      name: "GloveTrainingReport",
      components: {
          Loading
      },
      props: {
        isRangeCompare: Boolean,
        device: {
          type: Object
        },
        dateRange: {
          type: Object
        },
        toDateRange: {
          type: Object
        }
      },
      data () {
          return{
            reportList: [
              {text:this.$t("page.patient.neofectHome.summary.assignedSessions"), sum:0, sumUnit:'sessions', gap:0},
              {text:this.$t("page.patient.neofectHome.summary.completedSessions"), sum:0, sumUnit:'sessions', gap:0},
              {text:this.$t("page.patient.neofectHome.summary.daysTrained"), sum:0, sumUnit:'days', gap:0},
              {text:this.$t("page.patient.neofectHome.summary.averageTraining"), sum:0, sumUnit:'min/session', gap:0},
              {text:this.$t("page.patient.neofectHome.summary.totalTrainingTime"), sum:0, sumUnit:'min', gap:0},
              {text:this.$t("page.patient.neofectHome.summary.activityTolerance"), sum:0, sumUnit:'min', gap:0},
            ],
            loading: false,
            error: false,
            isNoData: false
          }
      },
      computed: {
        ...mapGetters({
          lang: "lang/language"
        }),
        patient: function () {
          return this.$store.getters.selectedPatient;
        }
      },
      watch: {
        lang(newVal) {
          this.reportList[0].text = this.$t("page.patient.neofectHome.summary.assignedSessions");
          this.reportList[1].text = this.$t("page.patient.neofectHome.summary.completedSessions");
          this.reportList[2].text = this.$t("page.patient.neofectHome.summary.daysTrained");
          this.reportList[3].text = this.$t("page.patient.neofectHome.summary.averageTraining");
          this.reportList[4].text = this.$t("page.patient.neofectHome.summary.totalTrainingTime");
          this.reportList[5].text = this.$t("page.patient.neofectHome.summary.activityTolerance");
        },
        device(newVal, oldVal) {
          this.loadReportList();
        },
        dateRange(newValue, oldValue) {
          this.loadReportList();
        },
        toDateRange(newValue, oldVAlue) {
          this.loadReportList();
        },
        isRangeCompare(newValue, oldValue) {
          this.loadReportList();
        }
      },
      mounted() {
        this.loadReportList();
      },
      methods: {
        async loadReportList() {
          if (!this.patient || !this.device) return;

          let trainingSessionsSummary;
          let toTrainingSessionsSummary;
          try {
            this.loading = true;
            trainingSessionsSummary = await getTrainedSessionsSummary(this.patient.id, this.device.id,this.$moment(this.dateRange.startDate).toISOString(),this.$moment(this.dateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString());
            if (this.isRangeCompare) {
              toTrainingSessionsSummary = await getTrainedSessionsSummary(this.patient.id, this.device.id, this.$moment(this.toDateRange.startDate).toISOString(), this.$moment(this.toDateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString());
            }
          } catch (error) {
            this.loading = false;
            this.error = true;
          }

          this.reportList[0].sum = trainingSessionsSummary.assignedSessions;
          this.reportList[1].sum = trainingSessionsSummary.completedSessions;
          this.reportList[2].sum = trainingSessionsSummary.daysTrained;
          this.reportList[3].sum = (trainingSessionsSummary.averageTrainingTimePerSession /60).toFixed(2);
          this.reportList[4].sum = (trainingSessionsSummary.TotalTrainingTime / 60).toFixed(2);
          this.reportList[5].sum = (trainingSessionsSummary.ActivityTolerance / 60).toFixed(2);

          if (this.isRangeCompare) {
            this.reportList[0].gap = this.reportList[0].sum - toTrainingSessionsSummary.assignedSessions;
            this.reportList[1].gap = this.reportList[1].sum - toTrainingSessionsSummary.completedSessions;
            this.reportList[2].gap = this.reportList[2].sum - toTrainingSessionsSummary.daysTrained;
            this.reportList[3].gap = this.reportList[3].sum - (toTrainingSessionsSummary.averageTrainingTimePerSession / 60).toFixed(2);
            this.reportList[4].gap = this.reportList[4].sum - (toTrainingSessionsSummary.TotalTrainingTime / 60).toFixed(2);
            this.reportList[5].gap = this.reportList[5].sum - (toTrainingSessionsSummary.ActivityTolerance / 60).toFixed(2);
          }

          this.loading = false;
        },
        removeNumberMinusSign: function (number) {
          if (number < 0) {
            return number * -1;
          } else if (number === 0) {
            return '-';
          }
          return number;
        }
      }
    }
</script>

<style lang="scss" scoped>
.glove-training-report {
  .report-contents {
    height: 535px;
    position: relative;
    overflow: hidden;
    .content-grid {
      display: grid;
      grid-template-rows: repeat(6,1fr);
      .content-grid-row {
        display: flex;
        width: 100%;
        padding: 35px 0;
        border-bottom: 1px solid #d0d4dc;
        .content-grid-row-item-text {
          width: 60%;
          font-size: 14px;
        }
        .content-grid-row-item-sum {
          text-align: left;
          width: 30%;
          .unit {
            font-size: 11px;
            color: gray;
          }
        }
        .content-grid-row-item-gap {
          text-align: center;
          width: 10%;
          font-size: 13px;
        }
        .content-grid-row-item-gap.plus {
          color: blue;
        }
        .content-grid-row-item-gap.minus {
          color: red;
        }

      }
    }
    .no-data,
    .error {
      width: 100%;
      height: 494px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      &.error {
        z-index: 2;
      }
    }
  }
}

</style>
