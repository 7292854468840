var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "VideoCall" }, [
    _c("div", { staticClass: "tokbox-sessions" }, [
      _c(
        "div",
        {
          staticClass: "Session",
          class: _vm.medicalRecords ? "menu-open" : "",
          on: { error: _vm.errorHandler }
        },
        [
          _c(
            "div",
            {
              staticClass: "screen-wrap",
              class: _vm.mode,
              on: {
                click: function(e) {
                  return _vm.screenWrapAction(e)
                }
              }
            },
            [
              _vm.sessionDie
                ? _c("div", { staticClass: "notice-message session-die" }, [
                    _vm._v(
                      "\n          Video call has been disconnected.\n          "
                    ),
                    _c("br"),
                    _vm._v(
                      "Please turn off and restart the video call.\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "screen-item subscribers camera-screen video-call",
                  class: _vm.publisherMain ? "publisher-main" : ""
                },
                [
                  _vm.videoCall
                    ? _c(
                        "div",
                        { staticClass: "sub-wrapper" },
                        [
                          _c("subscriber", {
                            attrs: {
                              stream: _vm.videoCall,
                              session: _vm.session
                            },
                            on: { error: _vm.errorHandler }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "push-again-btn" }, [
                    !_vm.videoDisconnected
                      ? _c("div", [
                          _c("span", { staticClass: "notice-message" }, [
                            _vm._v("No Participants")
                          ])
                        ])
                      : _c("div", [
                          _vm.videoDisconnected === "CASE_1"
                            ? _c("span", { staticClass: "notice-message" }, [
                                _vm._v("Patient doesn't respond")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.videoDisconnected === "CASE_2"
                            ? _c("span", { staticClass: "notice-message" }, [
                                _vm._v("Video Call has been disconnected")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "primary-type-btns" }, [
                            _c(
                              "button",
                              { on: { click: _vm.handleConnectPush } },
                              [_vm._v("Connect Again")]
                            )
                          ])
                        ])
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.screenSharing.view
                ? _c(
                    "div",
                    { staticClass: "screen-item subscribers screen-sharing" },
                    [
                      _vm.screenSharing.stream
                        ? _c(
                            "div",
                            { staticClass: "sub-wrapper" },
                            [
                              _c("subscriber", {
                                attrs: {
                                  stream: _vm.screenSharing.stream,
                                  session: _vm.session
                                },
                                on: { error: _vm.errorHandler }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "push-again-btn" }, [
                        !_vm.screenDisconnected
                          ? _c("div", [
                              _c("span", { staticClass: "notice-message" }, [
                                _vm._v("Connecting...")
                              ])
                            ])
                          : _c("div", [
                              _vm.screenDisconnected === "CASE_1"
                                ? _c(
                                    "span",
                                    { staticClass: "notice-message" },
                                    [_vm._v("Patient doesn't respond")]
                                  )
                                : _c(
                                    "span",
                                    { staticClass: "notice-message" },
                                    [
                                      _vm._v(
                                        "Screen Sharing has been disconnected"
                                      )
                                    ]
                                  ),
                              _vm._v(" "),
                              _c("div", { staticClass: "primary-type-btns" }, [
                                _c(
                                  "button",
                                  { on: { click: _vm.handleHomePush } },
                                  [_vm._v("Connect Again")]
                                )
                              ])
                            ])
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("publisher", {
                class:
                  "screen-item publisher camera-screen " +
                  (_vm.publisherMain ? "publisher-main" : ""),
                attrs: {
                  id: "publisher",
                  session: _vm.session,
                  "screen-sharing-on": _vm.screenSharing.view,
                  "screen-action": _vm.screenAction,
                  "change-mode": function() {
                    return _vm.changeMode(_vm.modes[1])
                  },
                  mode: _vm.mode,
                  "user-id": _vm.userId
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.publisherScreenSharing.view,
                      expression: "publisherScreenSharing.view"
                    }
                  ],
                  staticClass: "screen-item publisher screen-sharing"
                },
                [_c("div", { attrs: { id: "screen-preview" } })]
              ),
              _vm._v(" "),
              _c("RecordClock", { attrs: { "is-recording": _vm.isRecording } }),
              _vm._v(" "),
              _c("VideoCallClock"),
              _vm._v(" "),
              _vm._m(0)
            ],
            1
          ),
          _vm._v(" "),
          _vm.medicalRecords
            ? _c(
                "div",
                { staticClass: "medical-records" },
                [
                  _c("MedicalRecords", {
                    attrs: {
                      submit: _vm.saveMedicalRecords,
                      patient: _vm.patient,
                      "zone-offset": _vm.zoneOffset,
                      "game-categories": _vm.gameCategories,
                      "remote-request-game": _vm.remoteRequestGame
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "control-bar" }, [
      _c("div", { staticClass: "start-wrapper" }, [
        _c(
          "button",
          {
            staticClass: "presentation_button",
            attrs: { type: "button" },
            on: { click: _vm.togglePresentation }
          },
          [
            _vm._m(1),
            _vm._v(" "),
            !_vm.publisherScreenSharing.view
              ? _c("span", [_vm._v(" Presentation ")])
              : _vm._e(),
            _vm._v(" "),
            _vm.publisherScreenSharing.view
              ? _c("span", [_vm._v(" End Presentation ")])
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "button-wrapper" }, [
        _c(
          "button",
          {
            staticClass: "close-video-call",
            attrs: { type: "button", title: "Close Video Call" },
            on: { click: _vm.handleClose }
          },
          [_vm._m(2)]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class:
              "screen-share-btn " + (_vm.screenSharing.view ? "active" : ""),
            attrs: {
              type: "button",
              disabled: _vm.guestYN,
              title: "Screen Sharing"
            },
            on: { click: _vm.toggleScreenSharing }
          },
          [_vm._m(3)]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: "text-memo-btn " + (this.medicalRecords ? "active" : ""),
            attrs: {
              type: "button",
              disabled: _vm.guestYN,
              title: "Write Medical Record"
            },
            on: { click: _vm.toggleMedicalRecords }
          },
          [_vm._m(4)]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: "recording-btn " + (this.isRecording ? "stop" : ""),
            attrs: { disabled: _vm.guestYN, type: "button" },
            on: {
              click: function($event) {
                _vm.isRecording
                  ? _vm.handleRecordingEnd()
                  : _vm.handleRecordingStart()
              }
            }
          },
          [_c("span", { staticClass: "icon-ic_recording" })]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "end-wrapper" }, [
        _c(
          "button",
          {
            staticClass: "end_cal_button",
            attrs: { type: "button" },
            on: { click: _vm.handleEnd }
          },
          [_vm._m(5), _vm._v("\n        End Video Call\n      ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "toast-popup" } }, [
      _c("h1", [_vm._v("Video Call Recorded")]),
      _vm._v(" "),
      _c("p", [_vm._v("Video call record has been saved.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/svgs/ic_presentation.svg"),
          alt: "ic_presentation-image"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_call" }, [
      _c("span", { staticClass: "path1" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_screen-sharing" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" }),
      _vm._v(" "),
      _c("span", { staticClass: "path3" }),
      _vm._v(" "),
      _c("span", { staticClass: "path4" }),
      _vm._v(" "),
      _c("span", { staticClass: "path5" }),
      _vm._v(" "),
      _c("span", { staticClass: "path6" }),
      _vm._v(" "),
      _c("span", { staticClass: "path7" }),
      _vm._v(" "),
      _c("span", { staticClass: "path8" }),
      _vm._v(" "),
      _c("span", { staticClass: "path9" }),
      _vm._v(" "),
      _c("span", { staticClass: "path10" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_text" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" }),
      _vm._v(" "),
      _c("span", { staticClass: "path3" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/svgs/ic_termination.svg"),
          alt: "ic_termination-image"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }