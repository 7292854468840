<template>
  <section class="report-section session-log">
    <h1>{{ $t("page.patient.neofectHome.data.sessionLog") }}</h1>
    <div class="report-contents custom-scrollbar">
      <Loading v-if="loading" />
      <section v-for="(logs, index) in logsByDate" :key="index">
        <h2>{{ index }}</h2>
        <ul>
          <li v-for="(log, index) in logs" :key="index">
            <span class="title" :class="{target:log.target}">
              {{ log.sessionName }}
              {{ log.contentName }} ({{ log.successCount }}/{{
              log.targetSuccessCount
              }})
            </span>
            <span class="tooltip">
            <span class="icon-ic_info_circle">
              <span class="path1" />
              <span class="path2" />
              <div class="notice-information">{{log.motionTypeDisplayName}}</div>
            </span>
            </span>
            <span class="score">
              <span class="icon-ic_star_fill" :class="{ active: log.achieveLevel > 0 }">
                <span class="path1"></span>
              </span>
              <span class="icon-ic_star_fill" :class="{ active: log.achieveLevel > 1 }">
                <span class="path1"></span>
              </span>
              <span class="icon-ic_star_fill" :class="{ active: log.achieveLevel > 2 }">
                <span class="path1"></span>
              </span>
            </span>
          </li>
        </ul>
      </section>
      <div v-if="isNoData" class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
      <div v-if="error" class="no-data">Error!</div>
    </div>
  </section>
</template>

<script>
import { getMissionLogs } from "@/api/report.js";
import Loading from "@/components/Loading";

const displayMotion = Object.freeze({
  // Wrist Flex/Ext.
  SMART_GLOVE_WRIST_FLEX_EXT_AG : "Wrist Flex/Ext.",
  // Wrist Flex/Ext.(E.G.)
  SMART_GLOVE_WRIST_FLEX_EXT_EG : "Wrist Flex/Ext.(G.E.)",
  SMART_GLOVE_WRIST_FLEX_EXT_EG_FINGER_FLEX_EXT : "Wrist Flex/Ext.(G.E.), Finger Flex./Ext.",
  // Wrist R.D./U.D.
  SMART_GLOVE_WRIST_DEVIATION_AG : "Wrist R.D./U.D.",
  SMART_GLOVE_WRIST_DEVIATION_AG_FLEX_EXT_EG : "Wrist R.D./U.D.",
  // Wrist R.D./U.D.(E.G.)
  SMART_GLOVE_WRIST_DEVIATION_EG : "Wrist R.D./U.D.(G.E.)",
  // Forearm Pron./Sup.
  SMART_GLOVE_FOREARM_SUP_PRONATION : "Forearm Pron./Sup.",
  // Finger Flex./Ext.
  SMART_GLOVE_FINGER_FLEX_EXT : "Finger Flex./Ext.",

  SMART_BOARD_SHOULDER_VERTICAL : "Shoulder",
  SMART_BOARD_SHOULDER_HORIZONTAL : "Shoulder",
  SMART_BOARD_SHOULDER_VERTICAL_HORIZONTAL : "Shoulder",

})

export default {
  name: "MissionLogs",
  components: {
    Loading
  },
  data() {
    return {
      logs: [],
      logsByDate: {},
      loading: false,
      error: false,
      isNoData: false
    };
  },
  props: {
    device: {
      type: Object
    },
    dateRange: {
      type: Object
    },
    toDateRange: {
      type: Object
    },
    isRangeCompare: Boolean
  },
  computed: {
    patient: function() {
      return this.$store.getters.selectedPatient;
    }
  },
  watch: {
    device(newValue, oldValue) {
      this.logs = [];
      this.logsByDate = {};
      this.isNoData = false;
      this.error = false;
      this.loadLogs();
    },
    dateRange(newValue, oldValue) {
      this.loadLogs();
    },
    toDateRange(newValue, oldVAlue) {
      this.loadLogs();
    },
    isRangeCompare(newValue, oldValue) {
      this.loadLogs();
    }
  },
  mounted() {
    this.loadLogs();
  },
  methods: {
    async loadLogs() {

      if (this.device) {
        let logs;
        let targetLogs;
        try {
          this.loading = true;
          logs = await getMissionLogs(this.patient.id, this.device.id,this.$moment(this.dateRange.startDate).toISOString(),this.$moment(this.dateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString());
          if (this.isRangeCompare) {
            targetLogs = await getMissionLogs(this.patient.id, this.device.id,this.$moment(this.toDateRange.startDate).toISOString(),this.$moment(this.toDateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString());
          }
        } catch (error) {
          this.loading = false;
          this.error = true;
        }
        this.loading = false;
        this.logs = logs.map(item => {
          item.motionTypeDisplayName = displayMotion[item.motionTypeName];
          item.target = false;
          return item;
        });
        if (this.isRangeCompare) {
          this.logs = this.logs.concat(targetLogs.map(item=> {
            item.motionTypeDisplayName = displayMotion[item.motionTypeName];
            item.target = true;
            return item;
          }));
        }

        this.isNoData = this.logs.length < 1;
        this.logsByDate = this.logs.reduce((map, log) => {
          let date = log.trainedDate;
          if (!map[date]) {
            map[date] = [];
          }
          map[date].push(log);
          return map;
        }, {});
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.session-log {
  .report-contents {
    .no-data,
    .error {
      width: 100%;
      height: 314px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      z-index: 1;
      &.error {
        z-index: 2;
      }
    }
  }
  .tooltip {
    position: relative;
    .icon-ic_info_circle {
      position: absolute;
      top: -8px;
      left: 5px;
      color: $fontColor2;
      font-size: 16px;
      cursor: default;
      &:hover {
        color: $fontColor1;
        .notice-information {
          display: inline-block;
        }
      }
    }
    .notice-information {
      display: none;
      position: absolute;
      min-width: 200px;
      bottom: 25px;
      left: -90px;
      Word-break: break-all;
      color: $fontColor2;
      background: #ffffff;
      padding: 12px 16px;
      font-size: $fontSize7;
      text-align: center;
      line-height: 21px;
      z-index: 2;
      box-sizing: border-box;
      border-radius: 2px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px #dcdfe6;
    }
  }
}
</style>
