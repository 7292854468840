<template>
  <section class="report-section evaluation-score">
    <div class="evaluation">
      <h1>{{ $t("page.patient.neofectHome.data.evaluationRecord") }}</h1>
      <div class="report-contents">
        <Loading v-if="loading" />
        <div class="select">
          <select v-model="leftSelected" class="select-1 select-type-2 left">
            <option v-for="ev in evalList" v-bind:value="ev" :key="ev.id">
              [{{ ev.id }}] {{ ev.date }}
            </option>
          </select>
          <select v-model="rightSelected" class="select-2 select-type-2 left">
            <option v-for="ev in evalList" v-bind:value="ev" :key="ev.id">
              [{{ ev.id }}] {{ ev.date }}
            </option>
          </select>
        </div>
        <div class="contents">
          <div class="table" v-if="leftDetail && rightDetail">
            <table>
              <thead>
              <tr>
                <th colspan="2"></th>
                <th>[{{ leftSelected.id }}]</th>
                <th>[{{ rightSelected.id }}]</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>Forearm</th>
                <th>Pron.</th>
                <td>
                  {{
                    (
                      -1.0 * leftDetail.arom.FOREARM_SUP_PRONATION.min
                    ).toFixed(1)
                  }}°
                </td>
                <td>
                  {{
                    (
                      -1.0 * rightDetail.arom.FOREARM_SUP_PRONATION.min
                    ).toFixed(1)
                  }}°
                </td>
                <td>
                  {{
                    diffLeftRight(
                      (
                        -1.0 * leftDetail.arom.FOREARM_SUP_PRONATION.min
                      ).toFixed(1),
                      (
                        -1.0 * rightDetail.arom.FOREARM_SUP_PRONATION.min
                      ).toFixed(1)
                    )
                  }}
                  <img
                    :src="
                        '/img/icons/' +
                        upDownIcon(
                          (
                            -1.0 * leftDetail.arom.FOREARM_SUP_PRONATION.min
                          ).toFixed(1),
                          (
                            -1.0 * rightDetail.arom.FOREARM_SUP_PRONATION.min
                          ).toFixed(1)
                        )
                      "
                    width="10"
                    height="10"
                  />
                </td>
              </tr>
              <tr>
                <th></th>
                <th>Sup.</th>
                <td>
                  {{ leftDetail.arom.FOREARM_SUP_PRONATION.max.toFixed(1) }}°
                </td>
                <td>
                  {{ rightDetail.arom.FOREARM_SUP_PRONATION.max.toFixed(1) }}°
                </td>
                <td>
                  {{
                    diffLeftRight(
                      leftDetail.arom.FOREARM_SUP_PRONATION.max.toFixed(1),
                      rightDetail.arom.FOREARM_SUP_PRONATION.max.toFixed(1)
                    )
                  }}
                  <img
                    :src="
                        '/img/icons/' +
                        upDownIcon(
                          leftDetail.arom.FOREARM_SUP_PRONATION.max.toFixed(1),
                          rightDetail.arom.FOREARM_SUP_PRONATION.max.toFixed(1)
                        )
                      "
                    width="10"
                    height="10"
                  />
                </td>
              </tr>
              <template v-if="leftDetail.arom.FINGER_THUMB_FLEX_EXT">
                <tr>
                  <th>Finger</th>
                  <th>All Digit</th>
                  <td>
                    {{
                      (
                        (leftDetail.arom.FINGER_THUMB_FLEX_EXT.max -
                          leftDetail.arom.FINGER_THUMB_FLEX_EXT.min +
                          leftDetail.arom.FINGER_INDEX_FLEX_EXT.max -
                          leftDetail.arom.FINGER_INDEX_FLEX_EXT.min +
                          leftDetail.arom.FINGER_MIDDLE_FLEX_EXT.max -
                          leftDetail.arom.FINGER_MIDDLE_FLEX_EXT.min +
                          leftDetail.arom.FINGER_RING_FLEX_EXT.max -
                          leftDetail.arom.FINGER_RING_FLEX_EXT.min +
                          leftDetail.arom.FINGER_LITTLE_FLEX_EXT.max -
                          leftDetail.arom.FINGER_LITTLE_FLEX_EXT.min) *
                        100.0 / 5.0
                      ).toFixed(1)
                    }}%
                  </td>
                  <td>
                    {{
                      (
                        (rightDetail.arom.FINGER_THUMB_FLEX_EXT.max -
                          rightDetail.arom.FINGER_THUMB_FLEX_EXT.min +
                          rightDetail.arom.FINGER_INDEX_FLEX_EXT.max -
                          rightDetail.arom.FINGER_INDEX_FLEX_EXT.min +
                          rightDetail.arom.FINGER_MIDDLE_FLEX_EXT.max -
                          rightDetail.arom.FINGER_MIDDLE_FLEX_EXT.min +
                          rightDetail.arom.FINGER_RING_FLEX_EXT.max -
                          rightDetail.arom.FINGER_RING_FLEX_EXT.min +
                          rightDetail.arom.FINGER_LITTLE_FLEX_EXT.max -
                          rightDetail.arom.FINGER_LITTLE_FLEX_EXT.min) *
                        100.0 / 5.0
                      ).toFixed(1)
                    }}%
                  </td>
                  <td>
                    {{
                      diffLeftRight(
                        (
                          (leftDetail.arom.FINGER_THUMB_FLEX_EXT.max -
                            leftDetail.arom.FINGER_THUMB_FLEX_EXT.min +
                            leftDetail.arom.FINGER_INDEX_FLEX_EXT.max -
                            leftDetail.arom.FINGER_INDEX_FLEX_EXT.min +
                            leftDetail.arom.FINGER_MIDDLE_FLEX_EXT.max -
                            leftDetail.arom.FINGER_MIDDLE_FLEX_EXT.min +
                            leftDetail.arom.FINGER_RING_FLEX_EXT.max -
                            leftDetail.arom.FINGER_RING_FLEX_EXT.min +
                            leftDetail.arom.FINGER_LITTLE_FLEX_EXT.max -
                            leftDetail.arom.FINGER_LITTLE_FLEX_EXT.min) *
                          100.0 / 5.0
                        ).toFixed(1),
                        (
                          (rightDetail.arom.FINGER_THUMB_FLEX_EXT.max -
                            rightDetail.arom.FINGER_THUMB_FLEX_EXT.min +
                            rightDetail.arom.FINGER_INDEX_FLEX_EXT.max -
                            rightDetail.arom.FINGER_INDEX_FLEX_EXT.min +
                            rightDetail.arom.FINGER_MIDDLE_FLEX_EXT.max -
                            rightDetail.arom.FINGER_MIDDLE_FLEX_EXT.min +
                            rightDetail.arom.FINGER_RING_FLEX_EXT.max -
                            rightDetail.arom.FINGER_RING_FLEX_EXT.min +
                            rightDetail.arom.FINGER_LITTLE_FLEX_EXT.max -
                            rightDetail.arom.FINGER_LITTLE_FLEX_EXT.min) *
                          100.0 / 5.0
                        ).toFixed(1)
                      )
                    }}
                    <img
                      :src="
                          '/img/icons/' +
                          upDownIcon(
                            (
                              (leftDetail.arom.FINGER_THUMB_FLEX_EXT.max -
                                leftDetail.arom.FINGER_THUMB_FLEX_EXT.min +
                                leftDetail.arom.FINGER_INDEX_FLEX_EXT.max -
                                leftDetail.arom.FINGER_INDEX_FLEX_EXT.min +
                                leftDetail.arom.FINGER_MIDDLE_FLEX_EXT.max -
                                leftDetail.arom.FINGER_MIDDLE_FLEX_EXT.min +
                                leftDetail.arom.FINGER_RING_FLEX_EXT.max -
                                leftDetail.arom.FINGER_RING_FLEX_EXT.min +
                                leftDetail.arom.FINGER_LITTLE_FLEX_EXT.max -
                                leftDetail.arom.FINGER_LITTLE_FLEX_EXT.min) *
                              100.0 / 5.0
                            ).toFixed(1),
                            (
                              (rightDetail.arom.FINGER_THUMB_FLEX_EXT.max -
                                rightDetail.arom.FINGER_THUMB_FLEX_EXT.min +
                                rightDetail.arom.FINGER_INDEX_FLEX_EXT.max -
                                rightDetail.arom.FINGER_INDEX_FLEX_EXT.min +
                                rightDetail.arom.FINGER_MIDDLE_FLEX_EXT.max -
                                rightDetail.arom.FINGER_MIDDLE_FLEX_EXT.min +
                                rightDetail.arom.FINGER_RING_FLEX_EXT.max -
                                rightDetail.arom.FINGER_RING_FLEX_EXT.min +
                                rightDetail.arom.FINGER_LITTLE_FLEX_EXT.max -
                                rightDetail.arom.FINGER_LITTLE_FLEX_EXT.min) *
                              100.0 / 5.0
                            ).toFixed(1)
                          )
                        "
                      width="10"
                      height="10"
                    />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th>Thumb</th>
                  <td>
                    {{
                      (
                        (leftDetail.arom.FINGER_THUMB_FLEX_EXT.max -
                          leftDetail.arom.FINGER_THUMB_FLEX_EXT.min) *
                        100.0
                      ).toFixed(1)
                    }}%
                  </td>
                  <td>
                    {{
                      (
                        (rightDetail.arom.FINGER_THUMB_FLEX_EXT.max -
                          rightDetail.arom.FINGER_THUMB_FLEX_EXT.min) *
                        100.0
                      ).toFixed(1)
                    }}%
                  </td>
                  <td>
                    {{
                      diffLeftRight(
                        (
                          (leftDetail.arom.FINGER_THUMB_FLEX_EXT.max -
                            leftDetail.arom.FINGER_THUMB_FLEX_EXT.min) *
                          100.0
                        ).toFixed(1),
                        (
                          (rightDetail.arom.FINGER_THUMB_FLEX_EXT.max -
                            rightDetail.arom.FINGER_THUMB_FLEX_EXT.min) *
                          100.0
                        ).toFixed(1)
                      )
                    }}
                    <img
                      :src="
                          '/img/icons/' +
                          upDownIcon(
                            (
                              (leftDetail.arom.FINGER_THUMB_FLEX_EXT.max -
                                leftDetail.arom.FINGER_THUMB_FLEX_EXT.min) *
                              100.0
                            ).toFixed(1),
                            (
                              (rightDetail.arom.FINGER_THUMB_FLEX_EXT.max -
                                rightDetail.arom.FINGER_THUMB_FLEX_EXT.min) *
                              100.0
                            ).toFixed(1)
                          )
                        "
                      width="10"
                      height="10"
                    />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th>Index</th>
                  <td>
                    {{
                      (
                        (leftDetail.arom.FINGER_INDEX_FLEX_EXT.max -
                          leftDetail.arom.FINGER_INDEX_FLEX_EXT.min) *
                        100.0
                      ).toFixed(1)
                    }}%
                  </td>
                  <td>
                    {{
                      (
                        (rightDetail.arom.FINGER_INDEX_FLEX_EXT.max -
                          rightDetail.arom.FINGER_INDEX_FLEX_EXT.min) *
                        100.0
                      ).toFixed(1)
                    }}%
                  </td>
                  <td>
                    {{
                      diffLeftRight(
                        (
                          (leftDetail.arom.FINGER_INDEX_FLEX_EXT.max -
                            leftDetail.arom.FINGER_INDEX_FLEX_EXT.min) *
                          100.0
                        ).toFixed(1),
                        (
                          (rightDetail.arom.FINGER_INDEX_FLEX_EXT.max -
                            rightDetail.arom.FINGER_INDEX_FLEX_EXT.min) *
                          100.0
                        ).toFixed(1)
                      )
                    }}
                    <img
                      :src="
                          '/img/icons/' +
                          upDownIcon(
                            (
                              (leftDetail.arom.FINGER_INDEX_FLEX_EXT.max -
                                leftDetail.arom.FINGER_INDEX_FLEX_EXT.min) *
                              100.0
                            ).toFixed(1),
                            (
                              (rightDetail.arom.FINGER_INDEX_FLEX_EXT.max -
                                rightDetail.arom.FINGER_INDEX_FLEX_EXT.min) *
                              100.0
                            ).toFixed(1)
                          )
                        "
                      width="10"
                      height="10"
                    />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th>Middle</th>
                  <td>
                    {{
                      (
                        (leftDetail.arom.FINGER_MIDDLE_FLEX_EXT.max -
                          leftDetail.arom.FINGER_MIDDLE_FLEX_EXT.min) *
                        100.0
                      ).toFixed(1)
                    }}%
                  </td>
                  <td>
                    {{
                      (
                        (rightDetail.arom.FINGER_MIDDLE_FLEX_EXT.max -
                          rightDetail.arom.FINGER_MIDDLE_FLEX_EXT.min) *
                        100.0
                      ).toFixed(1)
                    }}%
                  </td>
                  <td>
                    {{
                      diffLeftRight(
                        (
                          (leftDetail.arom.FINGER_MIDDLE_FLEX_EXT.max -
                            leftDetail.arom.FINGER_MIDDLE_FLEX_EXT.min) *
                          100.0
                        ).toFixed(1),
                        (
                          (rightDetail.arom.FINGER_MIDDLE_FLEX_EXT.max -
                            rightDetail.arom.FINGER_MIDDLE_FLEX_EXT.min) *
                          100.0
                        ).toFixed(1)
                      )
                    }}
                    <img
                      :src="
                          '/img/icons/' +
                          upDownIcon(
                            (
                              (leftDetail.arom.FINGER_MIDDLE_FLEX_EXT.max -
                                leftDetail.arom.FINGER_MIDDLE_FLEX_EXT.min) *
                              100.0
                            ).toFixed(1),
                            (
                              (rightDetail.arom.FINGER_MIDDLE_FLEX_EXT.max -
                                rightDetail.arom.FINGER_MIDDLE_FLEX_EXT.min) *
                              100.0
                            ).toFixed(1)
                          )
                        "
                      width="10"
                      height="10"
                    />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th>Ring</th>
                  <td>
                    {{
                      (
                        (leftDetail.arom.FINGER_RING_FLEX_EXT.max -
                          leftDetail.arom.FINGER_RING_FLEX_EXT.min) *
                        100.0
                      ).toFixed(1)
                    }}%
                  </td>
                  <td>
                    {{
                      (
                        (rightDetail.arom.FINGER_RING_FLEX_EXT.max -
                          rightDetail.arom.FINGER_RING_FLEX_EXT.min) *
                        100.0
                      ).toFixed(1)
                    }}%
                  </td>
                  <td>
                    {{
                      diffLeftRight(
                        (
                          (leftDetail.arom.FINGER_RING_FLEX_EXT.max -
                            leftDetail.arom.FINGER_RING_FLEX_EXT.min) *
                          100.0
                        ).toFixed(1),
                        (
                          (rightDetail.arom.FINGER_RING_FLEX_EXT.max -
                            rightDetail.arom.FINGER_RING_FLEX_EXT.min) *
                          100.0
                        ).toFixed(1)
                      )
                    }}
                    <img
                      :src="
                          '/img/icons/' +
                          upDownIcon(
                            (
                              (leftDetail.arom.FINGER_RING_FLEX_EXT.max -
                                leftDetail.arom.FINGER_RING_FLEX_EXT.min) *
                              100.0
                            ).toFixed(1),
                            (
                              (rightDetail.arom.FINGER_RING_FLEX_EXT.max -
                                rightDetail.arom.FINGER_RING_FLEX_EXT.min) *
                              100.0
                            ).toFixed(1)
                          )
                        "
                      width="10"
                      height="10"
                    />
                  </td>
                </tr>
                <tr>
                  <th></th>
                  <th>Little</th>
                  <td>
                    {{
                      (
                        (leftDetail.arom.FINGER_LITTLE_FLEX_EXT.max -
                          leftDetail.arom.FINGER_LITTLE_FLEX_EXT.min) *
                        100.0
                      ).toFixed(1)
                    }}%
                  </td>
                  <td>
                    {{
                      (
                        (rightDetail.arom.FINGER_LITTLE_FLEX_EXT.max -
                          rightDetail.arom.FINGER_LITTLE_FLEX_EXT.min) *
                        100.0
                      ).toFixed(1)
                    }}%
                  </td>
                  <td>
                    {{
                      diffLeftRight(
                        (
                          (leftDetail.arom.FINGER_LITTLE_FLEX_EXT.max -
                            leftDetail.arom.FINGER_LITTLE_FLEX_EXT.min) *
                          100.0
                        ).toFixed(1),
                        (
                          (rightDetail.arom.FINGER_LITTLE_FLEX_EXT.max -
                            rightDetail.arom.FINGER_LITTLE_FLEX_EXT.min) *
                          100.0
                        ).toFixed(1)
                      )
                    }}
                    <img
                      :src="
                          '/img/icons/' +
                          upDownIcon(
                            (
                              (leftDetail.arom.FINGER_LITTLE_FLEX_EXT.max -
                                leftDetail.arom.FINGER_LITTLE_FLEX_EXT.min) *
                              100.0
                            ).toFixed(1),
                            (
                              (rightDetail.arom.FINGER_LITTLE_FLEX_EXT.max -
                                rightDetail.arom.FINGER_LITTLE_FLEX_EXT.min) *
                              100.0
                            ).toFixed(1)
                          )
                        "
                      width="10"
                      height="10"
                    />
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
          <div class="table" v-if="leftDetail && rightDetail">
            <table>
              <thead>
              <tr>
                <th colspan="2"></th>
                <th>[{{ leftSelected.id }}]</th>
                <th>[{{ rightSelected.id }}]</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <th>Wrist</th>
                <td>R.D.</td>
                <td>
                  {{ leftDetail.arom.WRIST_DEVIATION_AG.max.toFixed(1) }}°
                </td>
                <td>
                  {{ rightDetail.arom.WRIST_DEVIATION_AG.max.toFixed(1) }}°
                </td>
                <td>
                  {{
                    diffLeftRight(
                      leftDetail.arom.WRIST_DEVIATION_AG.max.toFixed(1),
                      rightDetail.arom.WRIST_DEVIATION_AG.max.toFixed(1)
                    )
                  }}
                  <img
                    :src="
                        '/img/icons/' +
                        upDownIcon(
                          leftDetail.arom.WRIST_DEVIATION_AG.max.toFixed(1),
                          rightDetail.arom.WRIST_DEVIATION_AG.max.toFixed(1)
                        )
                      "
                    width="10"
                    height="10"
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>U.D.</td>
                <td>
                  {{
                    (-1.0 * leftDetail.arom.WRIST_DEVIATION_AG.min).toFixed(
                      1
                    )
                  }}°
                </td>
                <td>
                  {{
                    (-1.0 * rightDetail.arom.WRIST_DEVIATION_AG.min).toFixed(
                      1
                    )
                  }}°
                </td>
                <td>
                  {{
                    diffLeftRight(
                      (-1.0 * leftDetail.arom.WRIST_DEVIATION_AG.min).toFixed(
                        1
                      ),
                      (
                        -1.0 * rightDetail.arom.WRIST_DEVIATION_AG.min
                      ).toFixed(1)
                    )
                  }}
                  <img
                    :src="
                        '/img/icons/' +
                        upDownIcon(
                          (
                            -1.0 * leftDetail.arom.WRIST_DEVIATION_AG.min
                          ).toFixed(1),
                          (
                            -1.0 * rightDetail.arom.WRIST_DEVIATION_AG.min
                          ).toFixed(1)
                        )
                      "
                    width="10"
                    height="10"
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>R.D.(G.E.)</td>
                <td>
                  {{ leftDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(1) }}°
                </td>
                <td>
                  {{ rightDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(1) }}°
                </td>
                <td>
                  {{
                    diffLeftRight(
                      leftDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(1),
                      rightDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(1)
                    )
                  }}
                  <img
                    :src="
                        '/img/icons/' +
                        upDownIcon(
                          leftDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(1),
                          rightDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(1)
                        )
                      "
                    width="10"
                    height="10"
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>U.D.(G.E.)</td>
                <td>
                  {{
                    (-1.0 * leftDetail.arom.WRIST_FLEX_EXT_EG.min).toFixed(1)
                  }}°
                </td>
                <td>
                  {{
                    (-1.0 * rightDetail.arom.WRIST_FLEX_EXT_EG.min).toFixed(
                      1
                    )
                  }}°
                </td>
                <td>
                  {{
                    diffLeftRight(
                      (-1.0 * leftDetail.arom.WRIST_FLEX_EXT_EG.min).toFixed(
                        1
                      ),
                      (-1.0 * rightDetail.arom.WRIST_FLEX_EXT_EG.min).toFixed(
                        1
                      )
                    )
                  }}
                  <img
                    :src="
                        '/img/icons/' +
                        upDownIcon(
                          (
                            -1.0 * leftDetail.arom.WRIST_FLEX_EXT_EG.min
                          ).toFixed(1),
                          (
                            -1.0 * rightDetail.arom.WRIST_FLEX_EXT_EG.min
                          ).toFixed(1)
                        )
                      "
                    width="10"
                    height="10"
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Flex.</td>
                <td>
                  {{ leftDetail.arom.WRIST_FLEX_EXT_AG.max.toFixed(1) }}°
                </td>
                <td>
                  {{ rightDetail.arom.WRIST_FLEX_EXT_AG.max.toFixed(1) }}°
                </td>
                <td>
                  {{
                    diffLeftRight(
                      leftDetail.arom.WRIST_FLEX_EXT_AG.max.toFixed(1),
                      rightDetail.arom.WRIST_FLEX_EXT_AG.max.toFixed(1)
                    )
                  }}
                  <img
                    :src="
                        '/img/icons/' +
                        upDownIcon(
                          leftDetail.arom.WRIST_FLEX_EXT_AG.max.toFixed(1),
                          rightDetail.arom.WRIST_FLEX_EXT_AG.max.toFixed(1)
                        )
                      "
                    width="10"
                    height="10"
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Ext.</td>
                <td>
                  {{
                    (-1.0 * leftDetail.arom.WRIST_FLEX_EXT_AG.min).toFixed(1)
                  }}°
                </td>
                <td>
                  {{
                    (-1.0 * rightDetail.arom.WRIST_FLEX_EXT_AG.min).toFixed(
                      1
                    )
                  }}°
                </td>
                <td>
                  {{
                    diffLeftRight(
                      (-1.0 * leftDetail.arom.WRIST_FLEX_EXT_AG.min).toFixed(
                        1
                      ),
                      (-1.0 * rightDetail.arom.WRIST_FLEX_EXT_AG.min).toFixed(
                        1
                      )
                    )
                  }}
                  <img
                    :src="
                        '/img/icons/' +
                        upDownIcon(
                          (
                            -1.0 * leftDetail.arom.WRIST_FLEX_EXT_AG.min
                          ).toFixed(1),
                          (
                            -1.0 * rightDetail.arom.WRIST_FLEX_EXT_AG.min
                          ).toFixed(1)
                        )
                      "
                    width="10"
                    height="10"
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Flex.(G.E.)</td>
                <td>
                  {{ leftDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(1) }}°
                </td>
                <td>
                  {{ rightDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(1) }}°
                </td>
                <td>
                  {{
                    diffLeftRight(
                      leftDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(1),
                      rightDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(1)
                    )
                  }}
                  <img
                    :src="
                        '/img/icons/' +
                        upDownIcon(
                          leftDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(1),
                          rightDetail.arom.WRIST_FLEX_EXT_EG.max.toFixed(1)
                        )
                      "
                    width="10"
                    height="10"
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Ext.(G.E.)</td>
                <td>
                  {{
                    (-1.0 * leftDetail.arom.WRIST_FLEX_EXT_EG.min).toFixed(1)
                  }}°
                </td>
                <td>
                  {{
                    (-1.0 * rightDetail.arom.WRIST_FLEX_EXT_EG.min).toFixed(
                      1
                    )
                  }}°
                </td>
                <td>
                  {{
                    diffLeftRight(
                      (-1.0 * leftDetail.arom.WRIST_FLEX_EXT_EG.min).toFixed(
                        1
                      ),
                      (-1.0 * rightDetail.arom.WRIST_FLEX_EXT_EG.min).toFixed(
                        1
                      )
                    )
                  }}
                  <img
                    :src="
                        '/img/icons/' +
                        upDownIcon(
                          (
                            -1.0 * leftDetail.arom.WRIST_FLEX_EXT_EG.min
                          ).toFixed(1),
                          (
                            -1.0 * rightDetail.arom.WRIST_FLEX_EXT_EG.min
                          ).toFixed(1)
                        )
                      "
                    width="10"
                    height="10"
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="isNoData" class="no-data">
          {{ $t("page.patient.neofectHome.data.empty") }}
        </div>
        <div v-if="error" class="error">Error!</div>
      </div>
    </div>
    <div class="range-of-motion">
      <h1>
        {{ $t("page.patient.neofectHome.data.rangeOfMotion") }}
      </h1>
      <div class="report-contents">
        <div class="graph">
          <Loading v-if="loading" />
          <v-chart
            ref="chartRef"
            v-if="!isNoData"
            :options="chartOptions"
            style="width: 100%; height: 500px"
            :auto-resize="true"
          ></v-chart>
          <div v-if="isNoData" class="no-data">
            {{ $t("page.patient.neofectHome.data.empty") }}
          </div>
          <div v-if="error" class="error">Error!</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { getEvaluations, getGloveEvaluationDetail } from "@/api/report.js";
import { chartDataCalculator } from "./GloveEvaluation";
import { get } from "lodash";
export default {
  name: "GloveEvaluation",
  components: {
    Loading,
    get,
  },
  data() {
    return {
      evalList: [],
      leftSelected: null,
      rightSelected: null,
      leftDetail: null,
      rightDetail: null,
      chartOptions: null,
      isNoData: false,
      loading: false,
      error: false,
      motionNameList: [
        { name: "Forearm Pron./Sup. (deg, °)", max: 180},
        { name: "Wrist Flex./Ext. (deg, °)", max: 160},
        { name: "Wrist U.D./R.D. (deg, °)", max: 80 ,},
        { name: "Wrist Flex./Ext. - G.E. (deg, °)", max: 160},
        { name: "Wrist U.D./R.D. - G.E. (deg, °)", max: 80},
        { name: "Finger Flex./Ext. (per, %)", max: 100},
      ],
      leftTitle: null,
      rightTitle: null,
    };
  },
  props: {
    device: {
      type: Object,
    },
  },
  computed: {
    patient: function () {
      return this.$store.getters.selectedPatient;
    },
  },
  watch: {
    device(newValue, oldValue) {
      this.leftSelected = null;
      this.leftDetail = null;
      this.rightSelected = null;
      this.rightDetail = null;
      this.chartOptions = null;
      this.evalList = [];
      this.isNoData = false;
      this.error = false;
      this.initChartData();
    },
    async leftSelected(newValue, oldValue) {
      if (newValue == null) this.leftDetail = null;
      else {
        let leftDetail;
        this.leftTitle = `[${newValue.id}] ${newValue.date}`;
        try {
          this.loading = true;
          leftDetail = await getGloveEvaluationDetail(newValue.id);
        } catch (error) {
          this.error = true;
          this.loading = false;
        }
        this.leftDetail = leftDetail;
        this.loading = false;
      }
    },
    async rightSelected(newValue, oldValue) {
      if (newValue == null) this.rightDetail = null;
      else {
        this.rightTitle = `[${newValue.id}] ${newValue.date}`;
        try {
          this.loading = true;
          const rightDetail = await getGloveEvaluationDetail(newValue.id);
          this.loading = false;
          this.rightDetail = rightDetail;
        } catch (error) {
          this.loading = false;
          this.error = true;
        }
      }
    },
    leftDetail(newVal, oldVal) {
      if (newVal) {
        this.drawChartDetail();
        if (newVal.arom.FINGER_THUMB_FLEX_EXT === undefined) {
          this.motionNameList = [
            { name: "Forearm Pron./Sup. (deg, °)", max: 180},
            { name: "Wrist Flex./Ext. (deg, °)", max: 160},
            { name: "Wrist U.D./R.D. (deg, °)", max: 80 ,},
            { name: "Wrist Flex./Ext. - G.E. (deg, °)", max: 160},
            { name: "Wrist U.D./R.D. - G.E. (deg, °)", max: 80}
          ];
        }
      }
    },
    rightDetail(newVal, oldVal) {
      if (newVal) {
        this.drawChartDetail();
      }
    },
  },
  methods: {
    diffLeftRight(left, right) {
      if (left === right) {
        return "-";
      } else {
        return Math.abs(right - left).toFixed(1);
      }
    },
    upDownIcon(left, right) {
      left = parseFloat(left);
      right = parseFloat(right);
      if (left === right) {
        return "blank.png";
      } else if (left > right) {
        return "arrow_red.png";
      } else {
        return "arrow_blue.png";
      }
    },
    async initChartData() {
      if (!this.patient || !this.device) return;
      try {
        this.loading = true;
        const evalList = await getEvaluations(this.patient.id, this.device.id);
        this.loading = false;
        if (evalList.length > 0) {
          this.evalList = evalList.map((item) => {
            if (item.date) {
              item.date = this.$moment(item.date).format("ll");
            }
            return item;
          });
          if (this.evalList.length > 0) {
            this.leftSelected = this.evalList[0];
            this.rightSelected = this.evalList[this.evalList.length - 1];
            // 날짜 데이터
            this.leftTitle = `[${this.evalList[0].id}] ${this.evalList[0].date}`;
            this.rightTitle = `[${
              this.evalList[this.evalList.length - 1].id
            }] ${this.evalList[this.evalList.length - 1].date}`;
          }
        } else {
          this.isNoData = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    drawChartDetail() {
      if (this.isNoData) return;
      let obj;
      if ((this.device.id == 1) || (this.device.id == 5)){
        obj = {
          indicator: this.motionNameList,
          data: [
            {
              value: [
                Number(
                  get(this.leftDetail, "arom.FOREARM_SUP_PRONATION.max") -
                  get(this.leftDetail, "arom.FOREARM_SUP_PRONATION.min")
                ).toFixed(1),
                Number(get(this.leftDetail, "arom.WRIST_FLEX_EXT_AG.max") -
                  get(this.leftDetail, "arom.WRIST_FLEX_EXT_AG.min")
                ).toFixed(1),
                Number(get(this.leftDetail, "arom.WRIST_DEVIATION_AG.max") -
                  get(this.leftDetail, "arom.WRIST_DEVIATION_AG.min")
                ).toFixed(1),
                Number(get(this.leftDetail, "arom.WRIST_FLEX_EXT_EG.max") -
                  get(this.leftDetail, "arom.WRIST_FLEX_EXT_EG.min")
                ).toFixed(1),
                Number(get(this.leftDetail, "arom.WRIST_DEVIATION_EG.max") -
                  get(this.leftDetail, "arom.WRIST_DEVIATION_EG.min")
                ).toFixed(1),
                Number((get(this.leftDetail, "arom.FINGER_THUMB_FLEX_EXT.max") -
                    get(this.leftDetail, "arom.FINGER_THUMB_FLEX_EXT.min") +
                    get(this.leftDetail, "arom.FINGER_INDEX_FLEX_EXT.max") -
                    get(this.leftDetail, "arom.FINGER_INDEX_FLEX_EXT.min") +
                    get(this.leftDetail, "arom.FINGER_MIDDLE_FLEX_EXT.max") -
                    get(this.leftDetail, "arom.FINGER_MIDDLE_FLEX_EXT.min") +
                    get(this.leftDetail, "arom.FINGER_RING_FLEX_EXT.max") -
                    get(this.leftDetail, "arom.FINGER_RING_FLEX_EXT.min") +
                    get(this.leftDetail, "arom.FINGER_LITTLE_FLEX_EXT.max") -
                    get(this.leftDetail, "arom.FINGER_LITTLE_FLEX_EXT.min")) * 100.0 / 5.0
                ).toFixed(1),
              ],
              name: this.leftTitle
            },
            {
              value: [
                Number(
                  get(this.rightDetail, "arom.FOREARM_SUP_PRONATION.max") -
                  get(this.rightDetail, "arom.FOREARM_SUP_PRONATION.min")
                ).toFixed(1),
                Number(get(this.rightDetail, "arom.WRIST_FLEX_EXT_AG.max") -
                  get(this.rightDetail, "arom.WRIST_FLEX_EXT_AG.min")
                ).toFixed(1),
                Number(get(this.rightDetail, "arom.WRIST_DEVIATION_AG.max") -
                  get(this.rightDetail, "arom.WRIST_DEVIATION_AG.min")
                ).toFixed(1),
                Number(get(this.rightDetail, "arom.WRIST_FLEX_EXT_EG.max") -
                  get(this.rightDetail, "arom.WRIST_FLEX_EXT_EG.min")
                ).toFixed(1),
                Number(get(this.rightDetail, "arom.WRIST_DEVIATION_EG.max") -
                  get(this.rightDetail, "arom.WRIST_FLEX_EXT_EG.min")
                ).toFixed(1),
                Number((get(this.rightDetail, "arom.FINGER_THUMB_FLEX_EXT.max") -
                    get(this.rightDetail, "arom.FINGER_THUMB_FLEX_EXT.min") +
                    get(this.rightDetail, "arom.FINGER_INDEX_FLEX_EXT.max") -
                    get(this.rightDetail, "arom.FINGER_INDEX_FLEX_EXT.min") +
                    get(this.rightDetail, "arom.FINGER_MIDDLE_FLEX_EXT.max") -
                    get(this.rightDetail, "arom.FINGER_MIDDLE_FLEX_EXT.min") +
                    get(this.rightDetail, "arom.FINGER_RING_FLEX_EXT.max") -
                    get(this.rightDetail, "arom.FINGER_RING_FLEX_EXT.min") +
                    get(this.rightDetail, "arom.FINGER_LITTLE_FLEX_EXT.max") -
                    get(this.rightDetail, "arom.FINGER_LITTLE_FLEX_EXT.min")) * 100.0 / 5.0
                ).toFixed(1),
              ],
              name: this.rightTitle
            }
          ]
        }
      } else {
        obj = {
          indicator: this.motionNameList.slice(0,5),
          data: [
            {
              value: [
                Number(
                  get(this.leftDetail, "arom.FOREARM_SUP_PRONATION.max") -
                  get(this.leftDetail, "arom.FOREARM_SUP_PRONATION.min")
                ).toFixed(1),
                Number(get(this.leftDetail, "arom.WRIST_FLEX_EXT_AG.max") -
                  get(this.leftDetail, "arom.WRIST_FLEX_EXT_AG.min")
                ).toFixed(1),
                Number(get(this.leftDetail, "arom.WRIST_DEVIATION_AG.max") -
                  get(this.leftDetail, "arom.WRIST_DEVIATION_AG.min")
                ).toFixed(1),
                Number(get(this.leftDetail, "arom.WRIST_FLEX_EXT_EG.max") -
                  get(this.leftDetail, "arom.WRIST_FLEX_EXT_EG.min")
                ).toFixed(1),
                Number(get(this.leftDetail, "arom.WRIST_DEVIATION_EG.max") -
                  get(this.leftDetail, "arom.WRIST_DEVIATION_EG.min")
                ).toFixed(1),
              ],
              name: this.leftTitle
            },
            {
              value: [
                Number(
                  get(this.rightDetail, "arom.FOREARM_SUP_PRONATION.max") -
                  get(this.rightDetail, "arom.FOREARM_SUP_PRONATION.min")
                ).toFixed(1),
                Number(get(this.rightDetail, "arom.WRIST_FLEX_EXT_AG.max") -
                  get(this.rightDetail, "arom.WRIST_FLEX_EXT_AG.min")
                ).toFixed(1),
                Number(get(this.rightDetail, "arom.WRIST_DEVIATION_AG.max") -
                  get(this.rightDetail, "arom.WRIST_DEVIATION_AG.min")
                ).toFixed(1),
                Number(get(this.rightDetail, "arom.WRIST_FLEX_EXT_EG.max") -
                  get(this.rightDetail, "arom.WRIST_FLEX_EXT_EG.min")
                ).toFixed(1),
                Number(get(this.rightDetail, "arom.WRIST_DEVIATION_EG.max") -
                  get(this.rightDetail, "arom.WRIST_FLEX_EXT_EG.min")
                ).toFixed(1),
              ],
              name: this.rightTitle
            }
          ]
        }
      }

      this.chartOptions = chartDataCalculator(obj);
    },
  },
  mounted() {
    this.initChartData();
  },
};
</script>
<style lang="scss" scoped>
.evaluation-score {
  .evaluation {
    .report-contents {
      height: 445px;
      position: relative;
      .no-data,
      .error {
        width: 100%;
        height: 494px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        background: #ffffff;
        &.error {
          z-index: 2;
        }
      }
    }
  }

  .range-of-motion {
    margin-top: 32px;

    .motion-btn-group {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding-top: 10px;
      .motion-btn {
        font-size: 14px;
        border: 1px solid #d0d4dc;
        padding: 10px 5px;
        text-align: center;
        background-color: white;
        color: gray;
        cursor: pointer;
        &.on {
          background-color: #f0734d;
          color: white;
        }
      }
    }
    .graph {
      position: relative;
      height: calc(550px - 48px);
      .no-data,
      .error {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        background: #ffffff;
        &.error {
          z-index: 2;
        }
      }
    }
  }
}
</style>

