<template>
  <section class="SettingTime" :class="show ? '' : 'invisible'">
    <div class="contents">
      <h1>{{ $t("page.calendar.setting.title") }}</h1>
      <div class="days-btns">
        <ul>
          <li v-for="(day, index) of days" :key="index">
            <input
              :id="'day' + index"
              v-model="checkedDays"
              type="checkbox"
              :value="day.toLowerCase()"
            >
            <label :for="'day' + index">{{ day }}</label>
          </li>
        </ul>
      </div>
      <h2>{{ $t("page.calendar.setting.workingHours") }}</h2>
      <div class="time-range">
        <Select
          v-model="workingStartTime"
          :options="times"
          label="label"
          placeholder="H:mm A"
          :components="{ OpenIndicator, Deselect }"
        >
          <div slot="no-options" class="vs__no-options">
            Sorry, no matching time.
          </div>
        </Select>
        <span class="center-hyphen">-</span>
        <Select
          v-model="workingEndTime"
          :options="times"
          label="label"
          placeholder="H:mm A"
          :components="{ OpenIndicator, Deselect }"
        >
          <div slot="no-options" class="vs__no-options">
            Sorry, no matching time.
          </div>
        </Select>
      </div>
      <hr>
      <h2>{{ $t("page.calendar.setting.lunchBreak") }}</h2>
      <div class="time-range">
        <Select
          v-model="lunchStartTime"
          :options="times"
          label="label"
          placeholder="H:mm A"
          :components="{ OpenIndicator, Deselect }"
        >
          <div slot="no-options" class="vs__no-options">
            Sorry, no matching time.
          </div>
        </Select>
        <span class="center-hyphen">-</span>
        <Select
          v-model="lunchEndTime"
          :options="times"
          label="label"
          placeholder="H:mm A"
          :components="{ OpenIndicator, Deselect }"
        >
          <div slot="no-options" class="vs__no-options">
            Sorry, no matching time.
          </div>
        </Select>
      </div>
    </div>
    <div class="popup-submit-btns">
      <button type="button" class="accept-btn" @click="handleSubmit">
        {{ $t("page.calendar.setting.save") }}
      </button>
    </div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button type="button" class="close-btn" @click="closeSettingTime">
        <span class="icon-ic_delete ic-icon">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
  </section>
</template>
<script>
/* eslint-disable */
import Timepicker from "vue2-timepicker";
import Select from "vue-select";
import Datepicker from "vuejs-datepicker";
import { fetchGetWorkinghours, fetchSetWorkinghours } from "@/api/calendar.js";
import times from "@/utils/timeList.js";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
export default {
  name: "SettingTime",
  components: {
    Timepicker,
    Select,
    Datepicker
  },
  mixins: [SelectMixin, ErrorMixin],
  props: {
    handleCloseSettingTime: Function
  },
  data() {
    return {
      workingStartTime: "",
      workingEndTime: "",
      lunchStartTime: "",
      lunchEndTime: "",
      days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      times: times,
      checkedDays: [],
      zoneOffset: 0,
      show: false
    };
  },
  methods: {
    handleSubmit() {
      const newSettings = {
        start_work_time: this.workingStartTime.value,
        end_work_time: this.workingEndTime.value,
        start_lunch_time: this.lunchStartTime.value,
        end_lunch_time: this.lunchEndTime.value,
        zone_offset: this.zoneOffset,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false
      };
      this.checkedDays.forEach(day => {
        if (day === "mon") {
          newSettings.mon = true;
        } else if (day === "tue") {
          newSettings.tue = true;
        } else if (day === "wed") {
          newSettings.wed = true;
        } else if (day === "thu") {
          newSettings.thu = true;
        } else if (day === "fri") {
          newSettings.fri = true;
        } else if (day === "sat") {
          newSettings.sat = true;
        } else if (day === "sun") {
          newSettings.sun = true;
        }
      });
      this.changeSettingTime(newSettings);
    },
    async changeSettingTime(settings) {
      try {
        await fetchSetWorkinghours(settings);
        this.closeSettingTime();
      } catch (error) {
        this.handleError(error, "SettingTime > Setting Working Hours API");
      }
    },
    closeSettingTime() {
      this.handleCloseSettingTime();
    },
    initSettingTime(settingTime) {
      /*
      //// 근무시간 초기셋팅 ////
      */
      this.zoneOffset = -new Date().getTimezoneOffset();
      this.workingStartTime = this.times.filter(time => {
        if (time.value === settingTime.start_work_time) {
          return time;
        }
      })[0];
      this.workingEndTime = this.times.filter(time => {
        if (time.value === settingTime.end_work_time) {
          return time;
        }
      })[0];
      this.lunchStartTime = this.times.filter(time => {
        if (time.value === settingTime.start_lunch_time) {
          return time;
        }
      })[0];
      this.lunchEndTime = this.times.filter(time => {
        if (time.value === settingTime.end_lunch_time) {
          return time;
        }
      })[0];
      let getChecked = [];
      if (settingTime.mon) {
        getChecked.push("mon");
      }
      if (settingTime.tue) {
        getChecked.push("tue");
      }
      if (settingTime.wed) {
        getChecked.push("wed");
      }
      if (settingTime.thu) {
        getChecked.push("thu");
      }
      if (settingTime.fri) {
        getChecked.push("fri");
      }
      if (settingTime.sat) {
        getChecked.push("sat");
      }
      if (settingTime.sun) {
        getChecked.push("sun");
      }
      this.checkedDays = getChecked;
      this.show = true;
    }
  },
  created() {
    fetchGetWorkinghours()
      .then(result => {
        // console.log(result);
        this.initSettingTime(result);
      })
      .catch(error => {
        this.handleError(error);
      });
  }
};
</script>

<style lang="scss" scoped>
.SettingTime {
  width: $modalWidth1;
  position: absolute;
  top: 56px;
  right: 300px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  z-index: 4;
  border-radius: 0.5rem;
  background: #ffffff;
  box-sizing: border-box;
  padding: 50px 0 0 0;
  color: $fontColor1;
  &.invisible {
    display: none;
  }
  .contents {
    padding: 0 24px 32px 24px;
    h1 {
      font-size: $fontSize2;
      color: $fontColor2;
    }
    h2 {
      font-size: $fontSize5;
      color: $fontColor2;
      margin-top: 32px;
    }
    hr {
      margin-top: 32px;
    }
    .days-btns {
      margin-top: 40px;

      ul {
        display: flex;
        li {
          & + li {
            margin-left: 8px;
          }
        }
        input[type="checkbox"] + label {
          position: relative;
          width: 48px;
          height: 48px;
          color: $fontColor2;
          border: 1px solid $borderColor1;
          border-radius: 50%;
          justify-content: center;
          &::before {
            display: none;
          }
        }
        input[type="checkbox"]:checked + label {
          border-color: $mainColor1;
          color: $mainColor1;
          background: rgba(240, 115, 77, 0.08);
        }
      }
    }
    .time-range {
      margin-top: 12px;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}
</style>
