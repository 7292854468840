<template>
  <div class="chat-room">
    <div class="room-title">{{ selectedInfo.name }}</div>
    <div
      id="messageLogScroll"
      class="message-log custom-scrollbar"
      @scroll="onScrollByThrottling"
    >
      <div v-for="(item, index) in selectedGroupChannel.messages" :key="index">
        <div v-if="item.userId == userId" class="me">
          <div class="message-wrapper">
            <div class="message">
              <p v-html="$options.filters.newlineToBr(item.message)" />
              <div class="time">{{ item.createdAt | dateFormat }}</div>
              <div v-if="item.createdAt == selectedGroupChannel.readMessageAt" class="read">Read</div>
            </div>
          </div>
        </div>
        <div v-if="item.userId != userId" class="other">
          <div class="message-wrapper">
            <div class="image">
              <span class="icon-ic_group_fill">
                <span class="path1" />
                <span class="path2" />
              </span>
              <img v-if="selectedInfo.image !== null" :src="selectedInfo.image">
            </div>
            <div class="name">{{ selectedInfo.name }}</div>
            <div class="message">
              <p v-html="$options.filters.newlineToBr(item.message)" />
              <div class="time">{{ item.createdAt | dateFormat }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-input" :class="isShowInputWarning ? 'warning' : ''">
      <textarea
        id="chatInput"
        ref="message"
        v-model="message"
        @focus="startTyping"
        @blur="endTyping"
        @keypress.enter="updateMessage"
      />
      <!-- button 비활성화 제거는 disabled 클래스 제거 -->
      <div class="send-btn primary-type-btns" :class="message.length < 1 ? 'disabled' : ''">
        <button ref="sendBtn" type="button" @click="send">{{ $t("common.chat.send") }}</button>
        <div class="message">
          <span>{{ chatInfo }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters, mapActions, mapMutations } from "vuex";
import lodash from "lodash";
import moment from "moment";

export default {
  name: "ChatRoom",
  data() {
    return {
      message: ""
    };
  },
  methods: {
    ...mapActions({
      sendMessage: "chat/sendMessage",
      prevMessages: "chat/prevMessages",
      resetWarning: "chat/resetWarning",
      startTyping: "chat/startTyping",
      endTyping: "chat/endTyping",
      setChatInfo: "chat/setChatInfo"
    }),
    onScrollByThrottling: lodash.throttle(function({
      target: { scrollTop, clientHeight, scrollHeight }
    }) {
      if (scrollTop === 0) this.prevMessages();
    },
    1000),
    updateMessage(e) {
      if (!e.shiftKey) {
        this.send();
      }
    },
    /**
     * mac에서 enter key를 누르면 이벤트가 2번 발생해 100ms 동안 send 이벤트가 여러번 발생시 한번만 동작하도록 처리
     */
    send: lodash.throttle(function() {
      if (this.message === "" || this.message.trim().length === 0) {
        return;
      }
      this.setChatInfo(this.$t("common.chat.sending"));
      this.sendMessage(this.message).then(result => {
        if (result) this.message = "";
      });
      this.$refs.message.focus();
    }, 100)
  },
  watch: {
    message: function(newVal, oldVal) {
      if (this.isShowInputWarning) {
        this.resetWarning();
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedGroupChannel: "chat/selectedGroupChannel",
      selectedInfo: "chat/selectedInfo",
      chatInfo: "chat/chatInfo",
      isShowInputWarning: "chat/isShowInputWarning",
      username: "name",
      userId: "id"
    })
  },
  filters: {
    newlineToBr: function(value) {
      if (!value) return "";
      return value.replace("\n", "<br/>");
    },
    dateFormat: function(timestamp) {
      const today = moment().startOf("day");
      const target = moment(timestamp);
      if (today.isBefore(target)) {
        return target.format("HH:mm");
      }
      return `${target.format("MMM DD")} at  ${target.format("HH:mm")}`;
    }
  }
};
</script>

<style lang="scss" scoped>
/* 오른쪽 영역 */
.chat-room {
  width: 530px;
  background: #f4f5f7;
  .room-title {
    padding: 24px;
    line-height: 21px;
    font-size: $fontSize5;
    color: $fontColor2;
    border-bottom: 1px solid $borderColor1;
  }
  .message-log {
    padding: 24px;
    padding-bottom: 0;
    box-sizing: border-box;
    width: 100%;
    height: 360px;
    overflow-y: auto;
    .message {
      padding: 16px;
      box-sizing: border-box;
      border-radius: 4px 25px 25px 25px;
      font-size: $fontSize6;
      line-height: 24px;
      letter-spacing: 0.5px;
      font-weight: 300;
      margin-bottom: 40px;
      word-break: break-all;
    }
    .me {
      clear: both;
      width: 100%;
      .message-wrapper {
        float: right;
        width: 320px;
        .name {
          font-size: $fontSize6;
          line-height: 20px;
          color: $fontColor2;
        }
        .message {
          margin-top: 8px;
          background: $mainColor2;
          color: #ffffff;
          position: relative;
          border-radius: 25px 4px 25px 25px;
          .time {
            font-size: 11px;
            line-height: $fontSize7;
            color: $fontColor3;
            position: absolute;
            left: 0px;
            bottom: -20px;
          }
          .read {
            font-size: 11px;
            line-height: $fontSize7;
            color: $fontColor3;
            position: absolute;
            left: 0;
            bottom: -35px;
          }
        }
      }
    }
    .other {
      clear: both;
      width: 100%;

      .message-wrapper {
        width: 320px;
        padding-left: 42px;
        position: relative;
        .name {
          font-size: $fontSize6;
          line-height: 20px;
          color: $fontColor2;
        }
        .image {
          width: 34px;
          height: 34px;
          display: inline-block;
          background: #d0d4dc;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          .icon-ic_group_fill {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: rgba(255, 255, 255, 0.8);
          }
          img {
            width: 34px;
            height: 34px;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
          }
        }
        .message {
          margin-top: 8px;
          background: #ffffff;
          position: relative;
        }
        .time {
          font-size: 11px;
          line-height: $fontSize7;
          color: $fontColor3;
          position: absolute;
          right: 0px;
          bottom: -20px;
        }
      }
    }
  }
  .chat-input {
    padding: 16px 16px 35px 16px;
    display: flex;
    border-top: 1px solid $borderColor1;
    background: #ffffff;
    position: relative;
    &.warning {
      textarea {
        border-color: $uiColor1;
        color: $uiColor2;
      }
      .message {
        color: $uiColor2;
      }
    }
    .send-btn {
      margin-left: 16px;
      flex-shrink: 0;
      button {
        padding: 20px 16px;
      }
    }
    .message {
      position: absolute;
      color: $fontColor3;
      font-size: $fontSize7;
      bottom: 13px;
      left: 24px;
    }
  }
}
</style>
