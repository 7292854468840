<template>
  <label class="switch">
    <input v-model="checkedLocal" type="checkbox" @change="toggle">
    <span class="slider round" />
  </label>
</template>

<script>
/* eslint-disable vue/require-prop-type-constructor */
/* eslint-disable vue/require-default-prop */

export default {
  name: "ToggleSliderButton",
  props: {
    checked: false
  },
  data() {
    return {
      checkedLocal: this.checked
    };
  },
  methods: {
    toggle: function () {
      this.$emit("toggle", this.checkedLocal);
    }
  }
};
</script>

<style scoped>

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 20px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #f0734d;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #f0734d;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(37px);
    -ms-transform: translateX(37px);
    transform: translateX(37px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>
