<template>
  <div id="patient">
    <GlobalMenu />
    <div class="main-view">
      <TopMenu
        :handle-toggle-notification="handleToggleNotification"
        :handle-toggle-setting-time="handleToggleSettingTime"
      />
      <div class="patient">
        <PatientList
          :selected-patient="selectedPatient"
          :window-height="windowHeight"
          :print-age="printAge"
          class="patient-sorting"
        />
        <div
          class="patient-right"
          :style="{ height: `${windowHeight - 60}px` }"
        >
          <div class="patient-quick-menus">
            <h1 v-if="selectedPatient">
              {{ selectedPatient.name ? selectedPatient.name : "-" }}
              <span v-if="selectedPatient.gender" class="sub-text capitalize">
                {{
                  `${genderFilter(selectedPatient.gender)} / ${printAge(
                    selectedPatient.birthday
                  )}`
                }}
              </span>
            </h1>
            <div class="control-btns default-type-btns">
              <button
                type="button"
                @click="handleCreateSessionPopup"
              >
                {{ $t("page.patient.layout.button.createSession") }}
              </button>
              <button v-if="this.userClinic.name.toLowerCase() === 'neofect'" type="button" @click="togglePatientChat">
                {{ $t("page.patient.layout.button.textChat") }}
              </button>
              <div class="default-type-btns disabled">
                <button type="button" @click="startScreenSharing">
                  {{ $t("page.patient.layout.button.screenSharing") }}
                </button>
              </div>
            </div>
          </div>
          <div class="patient-view">
            <div class="patient-link">
              <router-link to="/patient">{{ $t("page.patient.layout.menu.profile") }}</router-link>
              <router-link to="/patient/home">{{ $t("page.patient.layout.menu.neofectHome") }}</router-link>
              <router-link to="/patient/homework">{{ $t("page.patient.layout.menu.homework") }}</router-link>
              <router-link to="/patient/calendar">{{ $t("page.patient.layout.menu.calandar") }}</router-link>
              <router-link to="/patient/advice">{{ $t("page.patient.layout.menu.advice") }}</router-link>
              <router-link to="/patient/memo">{{ $t("page.patient.layout.menu.Documentation") }}</router-link>
            </div>
            <router-view ref="patientView" class="view custom-scrollbar" />
          </div>
        </div>
      </div>
    </div>
    <SettingTime
      v-if="settingTime.view"
      :handle-close-setting-time="handleCloseSettingTime"
    />
    <Notification
      v-if="notification.view"
      :handle-close-notification="handleCloseNotification"
    />
    <CreateSession
      v-if="createSession.view"
      :close-session-list-popup="handleCloseCreateSessionPopup"
    />
    <!-- TextChat -->
    <div v-if="this.userClinic.name.toLowerCase() === 'neofect'" class="textchat-btn">
      <button type="button" @click="toggleChat">
        <span class="icon-ic_message">
          <span class="path1" />
        </span>
        <span v-if="unreadMessageCount > 0" class="unread">{{
          unreadMessageCount
        }}</span>
      </button>
    </div>
    <TextChat v-if="this.userClinic.name.toLowerCase() === 'neofect'" />
    <modals-container />
  </div>
</template>
<script>
/* eslint-disable */

import SettingTime from "@/components/SettingTime";
import GlobalMenu from "@/components/GlobalMenu";
import TopMenu from "@/components/TopMenu";
import PatientList from "@/components/PatientList";
import CreateSession from "@/views/patient/components/CreateSession";
import ScreenSharing from "@/views/ScreenSharing";
import TextChat from "@/views/chat/components/Chat";
import Notification from "@/components/Notification";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import { mapGetters, mapActions } from "vuex";
// input library
import Timepicker from "vue2-timepicker";
import Select from "vue-select";
import Datepicker from "vuejs-datepicker";
// api
import {
  fetchGetWorkinghours,
  fetchSetWorkinghours,
  fetchScreenSharingStart
} from "@/api/calendar.js";

export default {
  components: {
    SettingTime,
    GlobalMenu,
    TopMenu,
    PatientList,
    Timepicker,
    Select,
    Datepicker,
    CreateSession,
    ScreenSharing,
    TextChat,
    Notification
  },
  mixins: [ErrorMixin],
  data() {
    return {
      settingTime: {
        view: false
      },
      notification: {
        view: false
      },
      windowHeight: 0,
      clientTime: {
        year: "",
        month: "",
        zone_offset: ""
      },
      createSession: {
        view: false
      }
    };
  },
  methods: {
    genderFilter(value) {
      if (value==='MALE') {
        return this.$t("page.patient.layout.gender.male")
      } else if(value==='FEMALE') {
        return this.$t("page.patient.layout.gender.female")
      }
    },
    togglePatientChat() {
      this.enterChannel({
        patientId: this.selectedPatient.id,
        name: this.selectedPatient.name
      });
    },
    handleToggleSettingTime() {
      this.handleCloseNotification();
      this.settingTime.view = !this.settingTime.view;
    },
    handleCloseSettingTime() {
      this.settingTime.view = false;
    },
    handleCloseNotification() {
      this.notification.view = false;
    },
    handleToggleNotification() {
      this.handleCloseSettingTime();
      this.notification.view = !this.notification.view;
    },
    async startScreenSharing() {
      /*
      //// Screen Sharing 시작하기 ////
      */
      return false;
    },
    handleResize: function() {
      // console.log("resize");
      const checkWindowHeight =
        window.innerHeight <= 790 ? 790 : window.innerHeight;
      this.windowHeight = checkWindowHeight;
    },
    handleCreateSessionPopup: function() {
      this.createSession.view = true;
    },
    handleCloseCreateSessionPopup: function() {
      this.createSession.view = false;
    },
    printAge: function(birthday) {
      return this.$moment().diff(this.$moment(birthday).format('YYYYMMDD'),'years');
    },
    ...mapActions({
      toggleChat: "chat/toggleTextChat",
      closeTextChat: "chat/closeTextChat",
      enterChannel: "chat/enterGroupChannel",
      getPatientsList: "user/getPatientsList"
    })
  },
  computed: {
    ...mapGetters({
      selectedPatient: "patient/selectedPatient",
      selectedPatientDevice: "patient/selectedPatientDevice",
      unreadMessageCount: "chat/unreadMessageCount",
      userClinic: "user/clinic"
    })
  },
  watch: {
    selectedPatient(newVal, oldVal) {
      this.closeTextChat();
    },
    selectedPatientDevice(newVal, oldVal) {
      this.handleCloseCreateSessionPopup();
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    const today = new Date();
    this.clientTime = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      zone_offset: -today.getTimezoneOffset()
    };
    this.getPatientsList();
  },
  mounted() {
    const checkWindowHeight =
      window.innerHeight <= 790 ? 790 : window.innerHeight;
    this.windowHeight = checkWindowHeight;
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
<style lang="scss">
#patient {
  display: flex;
  font-family: "manrope3";
  min-height: 790px;
  position: relative;
  .main-view {
    width: 100%;
    .patient {
      display: flex;
      .patient-sorting {
        flex-shrink: 0;
      }
      .patient-right {
        padding: 16px 24px 24px 24px;
        box-sizing: border-box;
        width: 100%;
        min-width: 840px;
        background: $popupBgColor1;
        .patient-quick-menus {
          display: flex;
          position: relative;
          &::before {
            content: "";
            display: inline-block;
            width: 0;
            height: 48px;
          }
          h1 {
            font-size: $fontSize3;
            font-weight: 500;
            line-height: 27px;
            color: $fontColor1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: normal;
            box-sizing: border-box;
            .sub-text {
              margin-top: 2px;
              font-size: $fontSize6;
              color: $fontColor2;
              display: block;
              line-height: normal;
            }
          }
          a {
            width: 100px;
            background: gray;
            font-weight: 500;
          }
          .control-btns {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            button {
              margin-left: 8px;
            }
            .default-type-btns {
              display: inline-block;
            }
          }
        }
        .patient-view {
          padding: 23px 0;
          margin-top: 12px;
          box-sizing: border-box;
          background: #ffffff;
          width: 100%;
          height: calc(100% - 60px);
          border-radius: 8px;
          border: 1px solid $borderColor1;
          overflow: hidden;
          .patient-link {
            border-bottom: 4px solid $fontColor5;
            padding-bottom: 16px;
            margin: 0;
            a {
              display: inline-block;
              padding: 0 35px;
              text-decoration: none;
              font-size: $fontSize6;
              color: $fontColor2;
              position: relative;
              &.router-link-exact-active {
                color: $fontColor1;
                &::after {
                  content: "";
                  display: block;
                  width: 100%;
                  height: 4px;
                  background: $mainColor1;
                  position: absolute;
                  left: 0;
                  bottom: -20px;
                }
              }
            }
          }

          .view {
            height: 100%;
            overflow-y: scroll;
            position: relative;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  .textchat-btn {
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: 50;
    button {
      width: 48px;
      height: 48px;
      background: $mainColor1;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
      position: relative;
      .icon-ic_message {
        color: #ffffff;
        font-size: 28px;
      }
      .unread {
        position: absolute;
        top: -8px;
        right: -10px;
        color: $fontColor2;
        font-size: $fontSize6;
        line-height: $fontSize6;
        padding: 6px 8px;
        background: $uiColor5;
        border-radius: 50%;
        min-width: 10px;
        display: inline-block;
        text-align: center;
      }
    }
  }
}
</style>
