import { render, staticRenderFns } from "./BoardSmoothness.vue?vue&type=template&id=ece8fa26&scoped=true&"
import script from "./BoardSmoothness.vue?vue&type=script&lang=js&"
export * from "./BoardSmoothness.vue?vue&type=script&lang=js&"
import style0 from "./BoardSmoothness.vue?vue&type=style&index=0&id=ece8fa26&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ece8fa26",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ece8fa26')) {
      api.createRecord('ece8fa26', component.options)
    } else {
      api.reload('ece8fa26', component.options)
    }
    module.hot.accept("./BoardSmoothness.vue?vue&type=template&id=ece8fa26&scoped=true&", function () {
      api.rerender('ece8fa26', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/patient/components/Home/components/BoardSmoothness.vue"
export default component.exports