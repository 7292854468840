/* eslint-disable no-throw-literal */
import { login, getInfo, fetchGetPatientsList } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import jsonwebtoken from "jsonwebtoken";

const state = {
  token: getToken(),
  id: "",
  name: "",
  avatar: "",
  clinic: {},
  level: "",
  patients: []
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_PATIENTS_LIST: (state, patients) => {
    state.patients = patients;
  },
  SET_LEVEL: (state, level) => {
    state.level = level;
  },
  SET_CLINIC: (state, clinic) => {
    state.clinic = clinic;
  },
  RESET_USER: state => {
    state.token = "";
    state.id = "";
    state.name = "";
    state.avatar = "";
    state.level = "";
    state.clinic = "";
    state.patients = [];
  }
};

const actions = {
  // therapist Login
  login({ commit }, userInfo) {
    return login(userInfo)
      .then(token => {
        try {
          const jwt = jsonwebtoken.decode(token.access_token);
          if (!jwt.authorities.includes("role_therapist")) {
            throw { code: "no.user" };
          }
        } catch (e) {
          if (e.code) throw e;
          throw { code: "unknown.error" };
        }

        commit("SET_TOKEN", token);
        // localstorage
        setToken(token);
        return token.name;
      })
      .catch(error => {
        if (error.code) throw error;
        if (error.data) throw error.data;
        throw error.data;
      });
  },

  getPatientsList({ commit }) {
    return fetchGetPatientsList()
      .then(result => {
        commit("SET_PATIENTS_LIST", result);
      })
      .catch(error => {
        throw error;
      });
  },

  // get user info
  getInfo({ commit, state }) {
    return getInfo(state.token)
      .then(data => {
        // console.log(data);
        if (!data) {
          return new Error("Verification failed, please Login again.");
        }
        commit("SET_NAME", data.name);
        commit("SET_ID", data.id);
        commit("SET_AVATAR", data.image);
        commit("SET_CLINIC", data.clinic);
        const level =
          data.roles.indexOf("role_clinic_system_owner") !== -1
            ? "Main Owner"
            : data.roles.indexOf("role_clinic_owner") !== -1
              ? "Owner"
              : data.roles.indexOf("role_clinic_admin") !== -1
                ? "Admin"
                : "Stroke coach";
        commit("SET_LEVEL", level);
        commit("SET_CLINIC", data.clinic);
      })
      .catch(error => {
        throw error;
      });
  },

  // user logout
  logout({ commit, dispatch }) {
    return new Promise(resolve => {
      removeToken();
      commit("RESET_USER");
      dispatch("notification/resetNotification", null, { root: true });
      resolve(true);
    });
  },

  // 탑 메뉴 바의 set force profile image
  // 현재 프로필에서 바꿔주는 경우 강제 캐쉬 덧씌우기용
  setAvatar({ commit }, image) {
    // console.log(image);
    commit("SET_AVATAR", image);
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken();
      commit("RESET_USER");
      resolve(true);
    });
  }
};
const getters = {
  token: state => {
    return state.token;
  },
  id: state => {
    return state.id;
  },
  name: state => {
    return state.name;
  },
  avatar: state => {
    return state.avatar;
  },
  level: state => {
    return state.level;
  },
  patients: state => {
    return state.patients;
  },
  clinic: state => {
    return state.clinic;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
