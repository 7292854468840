<template>
  <section class="Confirm">
    <!-- 이벤트 등록,수정 컨펌 -->
    <div v-if="!confirm" class="Confirm-contents">
      <h1>
        <span class="icon-ic_check_circle">
          <span class="path1" />
          <span class="path2" />
        </span>
        {{ $t("common.eventModal.title") }}
      </h1>
      <div class="sub-guidance">
        {{ $t("common.eventModal.message") }}
      </div>
      <div class="event-detail">
        <span>{{ $t("common.eventModal.boxLabel.typeOfEvent") }}: {{ printType(type) }}</span>
        <span v-if="eventInfo.type === 'THERAPISTLIVE'">Subject: {{ eventInfo.title }}</span>
        <span v-if="eventInfo.type === 'THERAPISTLIVE'">Description: {{ eventInfo.contents }}</span>
        <span class="detail-time">{{ $t("common.eventModal.boxLabel.time") }}:&nbsp;</span>
        <span v-html="printTime(eventInfo.start,eventInfo.end)" />
        <span
          v-if="eventInfo.type === 'COOPGAME'"
          class="detail-game"
        >Game: {{ eventInfo.game.print_title }}</span>
        <span
          v-if="eventInfo.type === 'COOPGAME' || eventInfo.type === 'SCREENSHARE' || eventInfo.type === 'VIDEOSESSION'"
        >{{ $t("common.eventModal.boxLabel.attendees") }}: {{ printPatients(eventInfo.patients) }}</span>
        <span v-if="eventInfo.type !== 'THERAPISTLIVE'">{{ $t("common.eventModal.boxLabel.message") }}: {{ eventInfo.contents }}</span>
        <span v-if="eventInfo.type === 'THERAPISTLIVE'">URL address: {{ eventInfo.contents_link }}</span>
      </div>
    </div>
    <!-- 경고성 컨펌 -->
    <div v-else-if="confirm" class="Confirm-contents" :class="confirm ? 'warning': ''">
      <h1 v-if="type !== 'change_save'">
        <span class="icon-ic_info_circle">
          <span class="path1" />
          <span class="path2" />
        </span>
        <span>{{ confirm.title }}</span>
      </h1>
      <h1 v-else>
        <span>{{ confirm.title }}</span>
      </h1>
      <div
        class="confirm-message custom-scrollbar"
        :class="type==='change_save' ? 'just-message': ''"
      >
        <span v-html="confirm.message" />
      </div>
    </div>
    <div v-if="!confirm" class="popup-submit-btns">
      <button type="button" class="accept-btn" @click="handleSubmit">{{ $t("common.modal.button.register") }}</button>
      <button type="button" @click="$emit('close')">{{ $t("common.modal.button.cancel") }}</button>
    </div>
    <div v-else-if="confirm" class="popup-submit-btns" :class="confirm ? 'warning': ''">
      <div v-if="type==='delete'">
        <button type="button" class="accept-btn error" @click="handleSubmit">{{ $t("common.modal.button.delete") }}</button>
        <button type="button" @click="$emit('close')">{{ $t("common.modal.button.cancel") }}</button>
      </div>
      <div v-else-if="type==='close'">
        <button type="button" class="accept-btn error" @click="handleSubmit">{{ $t("common.modal.button.close") }}</button>
        <button type="button" @click="$emit('close')">{{ $t("common.modal.button.cancel") }}</button>
      </div>
      <div v-else-if="type==='not-yet' || type==='validate' || type==='error'">
        <button type="button" class="accept-btn error" @click="$emit('close')">{{ $t("common.modal.button.close") }}</button>
      </div>
      <div v-else-if="type==='change_save'">
        <button type="button" class="accept-btn" @click="handleClose">{{ $t("common.modal.button.close") }}</button>
      </div>
      <div v-else-if="type==='cancel'">
        <button type="button" class="accept-btn error" @click="handleSubmit">Yes</button>
        <button type="button" @click="$emit('close')">No</button>
      </div>
      <div v-else-if="type==='remove'">
        <button type="button" class="accept-btn error" @click="handleSubmit">{{ $t("common.modal.button.remove") }}</button>
        <button type="button" @click="$emit('close')">{{ $t("common.modal.button.cancel") }}</button>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
/* eslint-disable */
export default {
  name: "Confirm",
  props: {
    eventInfo: Object,
    type: String,
    submit: Function,
    confirm: Object
    /*
      eventInfo : confirm으로 넘어오는 event 등록값정보
      type: 기본값은 이벤트의 type값이 넘어오며 alert값이 true인경우는 alert타입값이 넘어온다
            alert => false
            1. 'COOPGAME'
            2. 'SCREENSHARE'
            3. 'THERAPISTLIVE'
            4. 'PERSONAL'
            alert => true
            1. 'delete'
            2. 'close'
            3. 'not-yet'
            4. 'validate'
            5. 'error'
            6. 'change_save'
            7. 'cancel'
            8. 'remove'
      submit: submit실행시 실행되어져야할 함수
      alert: 이벤트 컨펌창인지 경고컨펌창인지 판단하는 값
             true => 이벤트 컨펌
             false => 경고창 컨펌
    */
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      lang: "lang/language"
    })
  },
  methods: {
    printTime(startDate, endDate) {
      let dateString = '';
      if (this.lang==='ko') {
        dateString = `${this.$moment(startDate).format(
          "dddd, MMMM D일, A h:mm"
        )} - ${this.$moment(endDate).format("A h:mm")}`;
      } else {
        dateString = `${this.$moment(startDate).format(
          "dddd, MMMM D, A h:mm"
        )} - ${this.$moment(endDate).format("A h:mm")}`;
      }

      if (
        this.eventInfo.allday_yn &&
        this.eventInfo.repeat_division === "NONE"
      ) {
        // Allday인 경우 날짜표기
        dateString = `${this.$moment(startDate).format("dddd, MMMM D")}`;
      } else if (
        this.eventInfo.allday_yn &&
        this.eventInfo.repeat_division === "DAILY"
      ) {
        // Allday이면서 Daily인 경우
        dateString = `${this.$moment(startDate).format(
          "dddd, MMMM D"
        )} - ${this.$moment(endDate).format("dddd, MMMM D")}`;
      } else if (
        !this.eventInfo.allday_yn &&
        this.eventInfo.repeat_division === "DAILY"
      ) {
        // Allday아니면서 Daily인 경우
        dateString = `<span>${this.$moment(startDate).format(
          "dddd, MMMM D"
        )} - ${this.$moment(endDate).format("dddd, MMMM D")}</span>
        <span class="detail-print-time">${this.$moment(startDate).format(
          "A h:mm"
        )} - ${this.$moment(endDate).format("A h:mm")}</span>`;
      }
      return dateString;
    },
    printType(type) {
      const print =
        type === "COOPGAME"
          ? "Co-op Game"
          : type === "SCREENSHARE"
          ? this.$t("common.eventModal.type.screenSharing")
          : type === "VIDEOSESSION"
          ? this.$t("common.eventModal.type.videoCall")
          : this.$t("common.eventModal.type.providerLive")
      return print;
    },
    printPatients(patients) {
      if (patients[0] === null) {
        return;
      }
      let stringValue = "";
      patients.forEach((patient, index) => {
        if (index === 0) {
          stringValue += `${patient.name}`;
        } else {
          stringValue += `, ${patient.name}`;
        }
      });
      return stringValue;
    },
    handleSubmit() {
      this.submit(this.eventInfo);
      this.$emit("close");
    },
    handleClose() {
      if (this.submit) {
        this.submit();
      }
      this.$emit("close");
    }
  }
};
</script>

<style lang='scss' scoped>
.Confirm {
  .Confirm-contents {
    padding: 50px 24px 40px 24px;
    color: $fontColor1;
    font-size: $fontSize5;
    h1 {
      font-size: $fontSize2;
      font-weight: 500;
      display: flex;
      align-items: center;
      .icon-ic_check_circle {
        font-size: 32px;
        margin-right: 16px;
        color: $uiColor3;
      }
    }
    &.warning {
      h1 {
        .icon-ic_info_circle {
          font-size: 32px;
          margin-right: 16px;
          color: $uiColor5;
        }
      }
    }
    .sub-guidance,
    .confirm-message {
      margin-top: 26px;
      color: $fontColor2;
      line-height: 24px;
    }
    .confirm-message {
      padding: 0 50px;
      &.just-message {
        padding-left: 0;
        max-height: 120px;
        width: calc(100% + 24px);
        overflow-y: auto;
      }
    }
    .event-detail {
      margin-top: 26px;
      max-height: 170px;
      padding: 16px 24px;
      box-sizing: border-box;
      border: 1px solid $borderColor1;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        box-shadow: none;
        width: 8px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $fontColor4;
        border-radius: 4px;
      }
      span {
        display: block;
        line-height: 26px;
        &.detail-time {
          float: left;
        }
        &.detail-print-time {
          padding-left: 47px;
        }
        &.detail-game {
          clear: both;
        }
      }
    }
  }
}
</style>
