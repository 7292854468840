var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.patientInfo
    ? _c("section", { staticClass: "Profile" }, [
        _c("div", { staticClass: "patient-avatar" }, [
          _c("div", { staticClass: "avatar" }, [
            !_vm.patientInfo.image
              ? _c("span", { staticClass: "icon-ic_group_fill" }, [
                  _c("span", { staticClass: "path1" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "path2" })
                ])
              : _c("span", { staticClass: "patient-image" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.patientInfo.image,
                      alt: _vm.patientInfo.name + "'s profile image'"
                    }
                  })
                ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "patient-info" }, [
          _c("section", { staticClass: "info-section" }, [
            _c("h1", [
              _vm._v(
                _vm._s(_vm.$t("page.patient.profile.basicInformation.title"))
              )
            ]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("page.patient.profile.basicInformation.age"))
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(_vm.patientInfo.age ? _vm.patientInfo.age : "-")
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "page.patient.profile.basicInformation.dateOfBirth"
                      )
                    )
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.patientInfo.birthday
                        ? _vm
                            .$moment(_vm.patientInfo.birthday)
                            .format("MMMM D, YYYY")
                        : "-"
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _c("li", [
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("page.patient.profile.basicInformation.gender")
                    )
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value capitalize" }, [
                  _vm._v(
                    _vm._s(
                      _vm.patientInfo.gender
                        ? _vm.patientInfo.gender.toLowerCase()
                        : ""
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.patientInfo.state_name
                ? _c("li", [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("page.patient.profile.basicInformation.state")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.patientInfo.state_name))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.patientInfo.email
                ? _c("li", [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("page.patient.profile.basicInformation.email")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.patientInfo.email))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.patientInfo.subscription
                ? _c("li", [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "page.patient.profile.basicInformation.subscriptionPlan"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(
                          _vm.patientInfo.subscription.planName
                            ? _vm.patientInfo.subscription.planName
                            : "-"
                        )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.patientInfo.subscription
                ? _c("li", [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "page.patient.profile.basicInformation.videoCall"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.patientInfo.subscription.video
                              ? "Used " +
                                  _vm.patientInfo.subscription.video.done +
                                  " times (" +
                                  _vm.patientInfo.subscription.video.remain +
                                  " left)"
                              : "-"
                          ) +
                          "\n          "
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _vm.patientInfo.disease
            ? _c("section", { staticClass: "info-section" }, [
                _c("h1", [
                  _vm._v(_vm._s(_vm.$t("page.patient.profile.diagnosis.title")))
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("page.patient.profile.diagnosis.nameOfDisease")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        _vm._s(
                          _vm.patientInfo.disease.disease
                            ? _vm.patientInfo.disease.disease
                            : "-"
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("page.patient.profile.diagnosis.affectedSide")
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value capitalize" }, [
                      _vm._v(
                        _vm._s(
                          _vm.patientInfo.disease.strokeSide
                            ? _vm.patientInfo.disease.strokeSide.toLowerCase()
                            : "-"
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", { staticClass: "label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "page.patient.profile.diagnosis.dateOfOccurance"
                          )
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.patientInfo.disease.onsetDate
                              ? _vm
                                  .$moment(_vm.patientInfo.disease.onsetDate)
                                  .format("ll")
                              : "-"
                          ) +
                          "\n          "
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.patientInfo.severity
            ? _c("section", { staticClass: "info-section" }, [
                _c("h1", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("page.patient.profile.upperExtremityLevel.title")
                    )
                  )
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.patientInfo.severity, function(serverity, index) {
                    return _c("li", { key: index }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(
                            serverity.date
                              ? _vm.$moment(serverity.date).format("ll")
                              : "-"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(
                          _vm._s(
                            serverity.level ? "Level " + serverity.level : "-"
                          )
                        )
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e()
        ])
      ])
    : _c("div", { staticClass: "loading" }, [_vm._v("No Result")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }