<template>
  <section class="Subscription">
    <div v-if="patient" class="patient-info">
      <section class="info-section">
        <h1>{{ $t("components.subscriptionInfo.title") }}</h1>
        <ul>
          <li>
            <span class="label">{{ $t("components.subscriptionInfo.name") }}</span>
            <span class="value">
              {{
                !patient.name ? "-" : patient.name.includes("null") ? "-" : patient.name
              }}
            </span>
          </li>
          <li>
            <span class="label">{{ $t("components.subscriptionInfo.email") }}</span>
            <span class="value">{{ patient.email }} </span>
          </li>
          <li>
            <span class="label">{{ $t("components.subscriptionInfo.plan") }}</span>
            <span class="value">{{ patient.subscription.planName }} </span>
          </li>
          <li>
            <span class="label">{{ $t("components.subscriptionInfo.start") }}</span>
            <span class="value">{{ customeDateTime(patient.subscription.startDate) }}</span>
          </li>
          <li>
            <span class="label">{{ $t("components.subscriptionInfo.expiration") }}</span>
            <span class="value">{{ customeDateTime(patient.subscription.expireDate) }}</span>
          </li>
          <li>
            <span class="label">{{ $t("components.subscriptionInfo.paymentMethod") }}</span>
            <span class="value">{{ marketPlace }}</span>
          </li>
          <li>
            <span class="label">{{ $t("components.subscriptionInfo.paymentHistory") }}</span>
            <span class="value">
              <textarea v-model="paymentList" readonly />
            </span>
          </li>
        </ul>
      </section>
    </div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button type="button" class="close-btn" @click="handleClose">
        <span class="icon-ic_delete ic-icon">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
  </section>
</template>

<script>
/* eslint-disable vue/require-default-prop */

import { fetchGetPaymentList } from "@/api/payment.js";

export default {
  name: "Subscription",
  props: {
    title: String,
    patient: Object
  },
  data() {
    return {
      history: []
    };
  },
  computed: {
    paymentList: function() {
      const $this = this;
      let paymentList = "N/A";
      if (this.history.length > 0) {
        paymentList = this.history.map(function (payment) {
          return $this.$moment(payment.expiryTimeMillis).format("YYYY-MM-DD HH:mm:ss");
        }).join("\n");
      }
      return paymentList;
    },
    marketPlace: function () {
      if (this.patient.subscription.marketPlace === "WELCOME_COUPON") {
        return "Free trial";
      }
      return this.patient.subscription.marketPlace;
    }
  },
  mounted() {
    console.log(this.patient);
    this.getPaymentList();
  },
  methods: {
    async getPaymentList() {
      try {
        const userId = this.patient.id;
        this.history = await fetchGetPaymentList(userId);
      } catch (error) {
        this.handleError(error, "Failed to get payment history");
      }
    },
    handleClose() {
      this.$emit("close");
    },
    customeDateTime(value) {
      return this.$moment(value).format("YYYY-MM-DD HH:mm:ss");
    }
  }
};
</script>

<style lang='scss' scoped>
  .Subscription {
    padding: 24px 24px 40px 24px;
    textarea {
      height: 200px;
      width: 30%;
    }
    height: calc(100% - 12px) !important;
    display: flex;

    .patient-info {
      margin-left: 40px;
      width: 100%;
      .info-section {
        width: 660px;
        padding-bottom: 30px;
        border-bottom: 1px solid $borderColor1;
        margin-bottom: 30px;
        &:last-of-type {
          border-bottom: 0 none;
          margin-bottom: 0;
          padding-bottom: 150px;
        }
        h1 {
          font-size: $fontSize4;
          line-height: 25px;
          font-weight: 500;
          color: $fontColor1;
        }
        ul {
          margin-top: 24px;
          li {
            font-size: $fontSize6;
            line-height: 19px;
            & + li {
              margin-top: 13px;
            }
            .label {
              color: $fontColor3;
              width: 170px;
              vertical-align: top;
              display: inline-block;
            }
            .value {
              color: $fontColor1;
              &.capitalize {
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }
</style>
