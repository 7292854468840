<template>
  <div class="international">
    <el-dropdown
      trigger="click"
      @command="handleSetLanguage"
    >
      <img :src="international" alt="international" class="international-icon">
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          :disabled="lang==='en'"
          command="en"
        >
          English
        </el-dropdown-item>
        <el-dropdown-item
          :disabled="lang==='ko'"
          command="ko"
        >
          한국어
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LangSelect",
  methods: {
    handleSetLanguage: function(lang) {
      this.$i18n.locale = lang;
      this.setLanguage(lang);
    },
    ...mapActions({
      setLanguage: "lang/setLanguage"
    })
  },
  computed: {
    ...mapGetters({
      lang: "lang/language"
    }),

    international: function () {
      return require("@/assets/svgs/language.svg");
    }
  }
};
</script>

<style  scoped>
  .international {
    padding-left: 28px;
    margin-right: -1em;
    width: 53px;
  }
  .international-icon {
    margin-right: 10px;
    margin-left: 3px;
    width: 24px;

  }
  .international-icon:hover {
    cursor: pointer;
  }
</style>
