<template>
  <section class="CreateSession">
    <h1>{{ title }}</h1>
    <div class="view-tab">
      <button
        v-show="this.selectedPatientDevice.length > 0"
        :class="
          target === $t('page.patient.session.tab.smartRehab.title') ? 'on' : ''
        "
        @click="convertTap($t('page.patient.session.tab.smartRehab.title'))"
      >
        {{ $t("page.patient.session.tab.smartRehab.title") }}
      </button>
      <button
        :class="
          target === $t('page.patient.session.tab.exercise.title') ? 'on' : ''
        "
        @click="convertTap($t('page.patient.session.tab.exercise.title'))"
      >
        {{ $t("page.patient.session.tab.exercise.title") }}
      </button>
    </div>
    <div
      v-show="
        target === $t('page.patient.session.tab.smartRehab.title') &&
          this.selectedPatientDevice.length > 0
      "
      class="contents"
    >
      <div class="session-date">
        <h2>{{ $t("page.patient.session.create.selectDate") }}</h2>
        <Datepicker
          ref="rehabCalendar"
          v-model="selectedDate"
          :calendar-class="{
            exercise: target === $t('page.patient.session.tab.exercise.title'),
          }"
          :inline="true"
          :disabled-dates="disabledDates"
          :highlighted="highlighted"
          @changedMonth="handleChangedMonth"
        />
      </div>
      <div class="session-list">
        <h2>{{ $t("page.patient.session.create.setSession") }}</h2>
        <div v-if="sessionList.length < 1" class="list no-list">
          <span>
            <div v-for="item in $t('page.patient.session.create.noSession')">
              {{ item }}
            </div>
          </span>
        </div>
        <div v-else class="list custom-scrollbar">
          <ul>
            <li v-for="(session, index) of sessionList" :key="index">
              <span class="list-date">{{ tabRightListTitle(session) }}</span>
              <span class="list-title">{{ session.title }}</span>
              <div class="list-control">
                <button
                  type="button"
                  class="icon-btn"
                  @click="sessionDelete(session)"
                >
                  <span class="icon-ic_bin">
                    <span class="path1" />
                    <span class="path2" />
                    <span class="path3" />
                    <span class="path4" />
                    <span class="path5" />
                  </span>
                </button>
                <button
                  type="button"
                  class="icon-btn"
                  @click="sessionEditPopup(session)"
                >
                  <span class="icon-ic_edit">
                    <span class="path1" />
                    <span class="path2" />
                    <span class="path3" />
                  </span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-show="target === $t('page.patient.session.tab.exercise.title')"
      class="contents"
    >
      <div class="session-date">
        <h2>{{ $t("page.patient.session.create.selectDate") }}</h2>
        <Datepicker
          ref="exerciseCalendar"
          v-model="exerciseSelectedDate"
          :calendar-class="{
            exercise: target === $t('page.patient.session.tab.exercise.title'),
          }"
          :inline="true"
          :disabled-dates="exerciseDisabledDates"
          :highlighted="exerciseHighlighted"
          @changedMonth="handleChangedMonth"
        />
      </div>
      <div class="session-list">
        <h2>{{ tabRightTitle }}</h2>
        <div v-if="exerciseSessionList.length < 1" class="list no-list">
          <span>
            <div v-for="item in tabRightNoItem">
              {{ item }}
            </div>
          </span>
        </div>
        <div v-else class="list custom-scrollbar">
          <ul>
            <li v-for="(session, index) of exerciseSessionList" :key="index">
              <span class="list-date">{{ tabRightListTitle(session) }}</span>
              <span class="list-title">{{ session.title }}</span>
              <div class="list-control">
                <button
                  type="button"
                  class="icon-btn"
                  @click="sessionDelete(session)"
                >
                  <span class="icon-ic_bin">
                    <span class="path1" />
                    <span class="path2" />
                    <span class="path3" />
                    <span class="path4" />
                    <span class="path5" />
                  </span>
                </button>
                <button
                  type="button"
                  class="icon-btn"
                  @click="sessionEditPopup(session)"
                >
                  <span class="icon-ic_edit">
                    <span class="path1" />
                    <span class="path2" />
                    <span class="path3" />
                  </span>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="popup-submit-btns"
      :class="
        sessionList.length > 0 || exerciseSessionList.length > 0
          ? ''
          : 'disabled'
      "
    >
      <button type="button" class="accept-btn" @click="handleSubmit">
        {{ $t("page.patient.session.saveButton") }}
      </button>
      <button type="button" @click="handleClose">
        {{ $t("page.patient.session.cancelButton") }}
      </button>
    </div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button type="button" class="close-btn icon-btn" @click="handleClose">
        <span class="icon-ic_delete">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
    <Session
      v-if="sessionPopup.view"
      :session-popup="sessionPopup"
      :close-session-popup="closeSessionPopup"
      :add-session="addSession"
      :edit-session="editSession"
    />
    <HomeworkSession
      v-if="homeworkSessionPopup.view"
      :session-popup="homeworkSessionPopup"
      :close-session-popup="closeHomeworkSessionPopup"
      :add-session="addHomeworkSession"
      :edit-session="editHomeworkSession"
      :repeat-disabled="false"
    />
  </section>
</template>

<script>
/* eslint-disable */
import Datepicker from "vuejs-datepicker";
import Session from "./components/Session";
import HomeworkSession from "./components/HomeworkSession";
import Confirm from "@/components/Confirm";
import { fetchGetSessions, fetchCreateSession } from "@/api/patient.js";
import {
  fetchGetMonthlyHomeworkAssingDate,
  fetchCreateHomeworkV2,
} from "@/api/connect.js";
import { mapGetters } from "vuex";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import { isArray } from "lodash";
export default {
  name: "CreateSession",
  mixins: [ErrorMixin],
  components: {
    Datepicker,
    Session,
    HomeworkSession,
  },
  props: {
    closeSessionListPopup: Function,
  },
  data() {
    return {
      target: this.$t("page.patient.session.tab.smartRehab.title"),
      disabledDates: {
        to: new Date(),
        dates: [],
      },
      exerciseDisabledDates: {
        to: new Date(`${this.$moment(new Date()).format("YYYY-MM-DD")}`), 
        dates: [],
      },
      highlighted: {
        dates: [],
      },
      exerciseHighlighted: {
        dates: [],
      },
      selectedDate: "",
      exerciseSelectedDate: "",
      sessionList: [],
      exerciseSessionList: [],
      settedSession: [],
      settedExerciseSession: [],
      sessionPopup: {
        view: false,
      },
      homeworkSessionPopup: {
        view: false,
      },
    };
  },
  methods: {
    // List Title
    tabRightListTitle(session) {
      if (
        this.target === this.$t("page.patient.session.tab.smartRehab.title")
      ) {
        if (this.lang === "ko") {
          return `${this.$moment(session.date).format("Do, MMMM")} ${this.$t(
            "page.patient.session.rightList.smartRehab.title"
          )}`;
        }
        return `${this.$t(
          "page.patient.session.rightList.smartRehab.title"
        )} ${this.$moment(session.date).format("Do, MMMM")}`;
      } else {
        // 운동 숙제 타이틀 Array 21.02.09 수정.
        if (!isArray(session.date)) return;
        if (this.lang === "ko") {
          let title = [];
          session.date.map((d) => {
            title.push(` ${this.$moment(d).format("MMMM Do")}`);
          });
          return `${title.toString()} ${this.$t(
            "page.patient.session.rightList.exercise.title"
          )}`;
        }
        let title = [];
        session.date.map((d) => {
          title.push(` ${this.$moment(d).format("Do, MMMM")}`);
        });
        return `${this.$t(
          "page.patient.session.rightList.exercise.title"
        )} ${title.toString()}`;
      }
    },
    convertTap(to) {
      this.target = to;
    },
    handleSubmit() {
      // 스마트 재활
      if (this.sessionList.length !== 0) {
        fetchCreateSession(this.sessionList)
          .then((result) => {
            if (this.$route.path === "/patient/calendar") {
              // 환자 캘린더가 열려진 경우 강제로 이벤트 리로딩
              this.$parent.$refs.patientView.forceGetWeekEvents();
            }
            const sessionListString = this.sessionList.reduce(
              (acc, cur, index) => {
                acc += `Session on ${this.$moment(cur.date).format(
                  "Do, MMMM"
                )}<br>`;
                return acc;
              },
              ""
            );
            this.$modal.show(
              Confirm,
              {
                type: "change_save",
                confirm: {
                  title: this.$t("page.patient.session.saveModal.title"),
                  message: sessionListString,
                },
              },
              {
                height: "auto",
              }
            );
            this.handleClose();
          })
          .catch((error) => {
            this.handleError(error, "CreateSession > Create Session API");
          });
      }
      // 운동 홈워크 생성.
      if (this.exerciseSessionList.length !== 0) {
        const reqData = [];
        this.exerciseSessionList.map((item) => {
          const list = item.date.map((date) => {
            return {
              date: date,
              messages: item.message,
              missionList: item.missions.map((missionItem) => ({
                id: missionItem.id,
                repeatCount: Number(missionItem.setsCount),
              })),
              therapistId: this.therapistId,
              userId: this.selectedPatient.id,
            };
          });

          reqData.push(...list);
        });

        // API 변경 21.02.04
        fetchCreateHomeworkV2(reqData)
          .then((result) => {
            console.log("homework create result : ", result);
            if (this.$route.path === "/patient/calendar") {
              // 환자 캘린더가 열려진 경우 강제로 이벤트 리로딩
              this.$parent.$refs.patientView.forceGetWeekEvents();
            }
            // 홈워크 스트링 변경
            const homeworkListString = this.exerciseSessionList.reduce(
              (acc, cur, index) => {
                cur.date.map((d) => {
                  acc += `Exercise on ${this.$moment(d).format(
                    "Do, MMMM"
                  )}<br/>`;
                });

                return acc;
              },
              ""
            );
            this.$modal.show(
              Confirm,
              {
                type: "change_save",
                confirm: {
                  title: this.$t("page.patient.session.saveModal.title"),
                  message: homeworkListString,
                },
              },
              {
                height: "auto",
              }
            );
            this.handleClose();
          })
          .catch((error) => {
            this.handleError(error, "CreateHomework > Create Homework API");
          });
      }
    },
    handleClose() {
      this.closeSessionListPopup();
    },
    handleChangedMonth(date) {
      this.getSessionList(date);
    },
    closeSessionPopup() {
      this.sessionPopup.view = false;
    },
    addSession(session) {
      this.sessionList = this.sessionList.concat({
        ...session,
        patient: this.selectedPatient,
      });
      this.highlighted.dates.push(new Date(session.date));
    },
    editSession(session) {
      // 세션 리스트의 세션을 수정
      this.sessionList = this.sessionList.map((list) => {
        if (list.date === session.date) {
          list = { ...session, patient: this.selectedPatient };
        }
        return list;
      });
    },

    closeHomeworkSessionPopup() {
      this.homeworkSessionPopup.view = false;
    },
    addHomeworkSession(homework) {
      // 홈워크 추가. Date를 Array로 받도록 수정. 21.02.09
      this.exerciseSessionList = this.exerciseSessionList.concat(homework);
      // 달력 날짜 표기
      homework.date.map((date) => {
        this.exerciseHighlighted.dates.push(new Date(date));
      });
    },
    editHomeworkSession(homework) {
      // 홈웍 리스트의 홈웍을 수정
      this.exerciseSessionList = this.exerciseSessionList.map((list) => {
        if (list.selectedDate === homework.selectedDate) {
          list = { ...homework };
        }
        return list;
      });
      // 달력 날짜 표기
      this.exerciseHighlighted.dates = [];
      this.exerciseSessionList.map((exercise) => {
        exercise.date.map((d) => {
          this.exerciseHighlighted.dates.push(new Date(d));
        });
      });
    },
    getSessionList(date) {
      if (this.selectedPatient) {
        if (
          this.target === this.$t("page.patient.session.tab.smartRehab.title")
        ) {
          fetchGetSessions(
            date.getFullYear(),
            date.getMonth() + 1,
            this.selectedPatient.id
          )
            .then((result) => {
              if (result) {
                const getList = result.map((list) => {
                  return new Date(this.$moment(list.date).toISOString());
                });
                this.settedSession = getList;
              }
            })
            .catch((error) => {
              this.handleError(error, "CreateSession > Get Session List API");
            });
        } else if (
          this.target === this.$t("page.patient.session.tab.exercise.title")
        ) {
          fetchGetMonthlyHomeworkAssingDate(
            date.getFullYear(),
            date.getMonth() + 1,
            this.selectedPatient.id
          ).then((result) => {
            if (result) {
              const getList = result.map((date) => {
                return new Date(this.$moment(date).toISOString());
              });
              this.settedExerciseSession = getList;
            }
          });
        }
      }
    },
    sessionDelete(session) {
      // 세션 리스트의 세션을 삭제
      if (
        this.target === this.$t("page.patient.session.tab.smartRehab.title")
      ) {
        this.sessionList = this.sessionList.filter((list) => {
          return list !== session;
        });
        this.highlighted.dates = this.highlighted.dates.filter((date) => {
          return date.getDate() !== new Date(session.date).getDate();
        });
      } else if (
        this.target === this.$t("page.patient.session.tab.exercise.title")
      ) {
        // 운동 홈워크 날짜 삭제 수정 21.02.09
        this.exerciseSessionList = this.exerciseSessionList.filter((list) => {
          return list !== session;
        });

        session.date.map((d) => {
          this.exerciseHighlighted.dates = this.exerciseHighlighted.dates.filter(
            (date) => {
              return date.getDate() !== new Date(d).getDate();
            }
          );
        });
      }
    },
    sessionEditPopup(session) {
      if (
        this.target === this.$t("page.patient.session.tab.smartRehab.title")
      ) {
        // 세션 리스트의 세션을 수정하기 위한 팝업열기
        this.sessionPopup = {
          ...session,
          selectedDate: session.date,
          view: true,
          edit: true,
        };
      } else if (
        this.target === this.$t("page.patient.session.tab.exercise.title")
      ) {
        // 운동 홈워크
        session.messages = session.message;
        this.homeworkSessionPopup = {
          ...session,
          view: true,
          edit: true,
        };
      }
    },
    sessionAddPopup(date) {
      if (
        this.target === this.$t("page.patient.session.tab.smartRehab.title")
      ) {
        // 세션 리스트에 세션을 추가하기 위한 팝업열기
        this.sessionPopup = {
          selectedDate: date,
          view: true,
        };
      } else if (
        this.target === this.$t("page.patient.session.tab.exercise.title")
      ) {
        // 세션 리스트에 세션을 추가하기 위한 팝업열기
        this.homeworkSessionPopup = {
          selectedDate: date,
          view: true,
        };
      }
    },
  },
  watch: {
    selectedDate: function (newVal, oldVal) {
      const find = this.highlighted.dates.findIndex(
        (date) => date.getDate() === newVal.getDate()
      );
      if (find === -1) {
        this.sessionAddPopup(newVal);
      }
    },
    exerciseSelectedDate: function (newVal, oldVal) {
      const find = this.exerciseHighlighted.dates.findIndex(
        (date) => date.getDate() === newVal.getDate()
      );
      if (find === -1) {
        this.sessionAddPopup(newVal);
      }
    },
    settedSession: function (newVal, oldVal) {
      this.disabledDates.dates = newVal;
    },
    settedExerciseSession: function (newVal, oldVal) {
      // this.exerciseDisabledDates.dates = newVal; 220513 - today도 훈련을 등록할 수 있도록 변경 
    },
    target: function (newVal) {
      let date = null;
      if (newVal === this.$t("page.patient.session.tab.smartRehab.title")) {
        date = this.$refs.rehabCalendar.pageDate;
      } else if (
        newVal === this.$t("page.patient.session.tab.exercise.title")
      ) {
        date = this.$refs.exerciseCalendar.pageDate;
      } else {
        date = new Date();
      }

      this.getSessionList(date);
    },
    lang: function () {
      this.convertTap(this.$t("page.patient.session.tab.smartRehab.title"));
    },
  },
  computed: {
    ...mapGetters({
      selectedPatient: "patient/selectedPatient",
      selectedPatientDevice: "patient/selectedPatientDevice",
      therapistId: "user/id",
      lang: "lang/language",
    }),
    title() {
      if (this.lang === "ko") {
        return `${this.selectedPatient.name} ${this.$t(
          "page.patient.session.create.title"
        )}`;
      }
      return `${this.$t("page.patient.session.create.title")} ${
        this.selectedPatient.name
      }`;
    },
    tabRightTitle() {
      if (
        this.target === this.$t("page.patient.session.tab.smartRehab.title")
      ) {
        return `${this.$t("page.patient.session.create.setSession")}`;
      }
      return `${this.$t("page.patient.session.create.setExerciseHomework")}`;
    },
    tabRightNoItem() {
      if (
        this.target === this.$t("page.patient.session.tab.smartRehab.title")
      ) {
        return this.$t("page.patient.session.create.noSession");
      }
      return this.$t("page.patient.session.create.noExerciseHomework");
    },
  },
  created() {
    const today = new Date();
    this.getSessionList(today);

    if (this.selectedPatientDevice.length > 0) {
      this.target = this.$t("page.patient.session.tab.smartRehab.title");
    } else {
      this.target = this.$t("page.patient.session.tab.exercise.title");
    }
  },
};
</script>

<style lang='scss'>
.CreateSession {
  position: fixed;
  width: 100%;
  max-width: 960px;
  background: #ffffff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $fontColor1;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  z-index: 3;
  h1 {
    padding: 50px 32px 23px 32px;
    font-size: $fontSize2;
    font-weight: 500;
  }
  h2 {
    font-size: $fontSize6;
    font-weight: 500;
    color: $fontColor2;
    line-height: 20px;
  }
  .view-tab {
    padding-left: 50px;
    padding-top: 23px;
    border-bottom: 1px solid $borderColor1;
    background-color: #f4f5f7;
    button {
      position: relative;
      padding: 14px 28px 10px 28px;
      top: 1px;
      background-color: #edeff3;
      font-size: $fontSize6;
      color: $fontColor3;
      border: 1px solid $borderColor1;
      border-bottom: 0 none;
      border-radius: 5px 5px 0 0;
      &.on {
        color: $fontColor1;
        background: #ffffff;
      }
    }
  }
  .contents {
    display: flex;
    .session-date {
      flex-shrink: 0;
      width: 400px;
      padding: 24px 32px;
      box-sizing: border-box;
      border-right: 1px solid $borderColor1;
      .vdp-datepicker__calendar {
        margin-top: 23px;
        border: 0 none;
        padding: 0;
        font-size: $fontSize6;
        &.exercise {
          .cell {
            &.today {
              background: #59886b;
              &:hover {
                background: #59886b;
              }
            }
          }
        }
        .day-header {
          font-size: 11px;
        }
        .day__month_btn {
          font-weight: 500;
          font-size: $fontSize3;
          color: $fontColor1;
        }
        .cell {
          &.day {
            height: 30px;
            width: 30px;
            line-height: 30px;
            margin: 8.9px;
            &:hover {
              background: $fontColor5;
              border-radius: 50%;
            }
          }
          &.selected {
            &:hover {
              background: $fontColor5;
            }
            background: $fontColor5;
            color: $fontColor2;
            border-radius: 50%;
            &::before {
              background: none;
            }
            &.highlighted {
              background: none;
              position: relative;
              &:hover {
                background: none;
              }
              &::before {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%, 0);
                content: "";
                display: block;
                width: 6px;
                height: 6px;
                background-color: $fontColor3;
                border-radius: 50%;
              }
            }
          }
          &.highlighted {
            background: none;
            position: relative;
            &:hover {
              background: none;
            }
            &::before {
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translate(-50%, 0);
              content: "";
              display: block;
              width: 6px;
              height: 6px;
              background-color: $fontColor3;
              border-radius: 50%;
            }
          }
          &.today {
            border-radius: 50%;
            color: #ffffff;
            background: #214252;
            &:hover {
              background: #214252;
            }
            &::before {
              background: none;
            }
            &.selected {
              &:hover {
                background: $fontColor5;
              }
              background: $fontColor5;
              color: $fontColor2;
              border-radius: 50%;
              &::before {
                background: none;
              }
            }
            &.highlighted {
              background: none;
              position: relative;
              color: $fontColor2;
              &:hover {
                background: none;
              }
              &::before {
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translate(-50%, 0);
                content: "";
                display: block;
                width: 6px;
                height: 6px;
                background-color: $fontColor3;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
    .session-list {
      padding: 24px 32px 40px 32px;
      width: 560px;
      box-sizing: border-box;
      .list {
        box-sizing: border-box;
        width: calc(100% + 32px);
        padding-right: 32px;
        margin-top: 23px;
        height: 310px;
        overflow-y: auto;
        border-radius: 8px;
        li {
          border: 1px solid $borderColor1;
          border-radius: 8px;
          background: rgba(244, 245, 247, 0.4);
          padding: 20px 24px;
          position: relative;
          & + li {
            margin-top: 16px;
          }
          span {
            display: block;
          }
          .list-date {
            width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: $fontColor2;
            font-size: $fontSize7;
          }
          .list-title {
            margin-top: 10px;
            color: $fontColor1;
            font-size: $fontSize6;
          }
          .list-control {
            position: absolute;
            top: 16px;
            right: 16px;
            button + button {
              margin-left: 9px;
            }
          }
        }
      }
      .no-list {
        margin-top: 23px;
        border: 1px solid $borderColor1;
        background-color: rgba(244, 245, 247, 0.4);
        color: $fontColor3;
        width: 100%;
        height: calc(100% - 20px - 23px);
        position: relative;
        box-sizing: border-box;
        font-size: $fontSize6;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
