import moment from "moment";

let option = {
  tooltip: {
    formatter: function(params) {
      let mins = " mins.";
      if (params.value === 1) {
        mins = " min.";
      }
      return params.marker + params.name + ": " + params.value + mins;
    }
  },
  legend: {
    show: true,
    data: ["min","origin","target"]
  },
  xAxis: {
    data: [],
    axisLine: {
      lineStyle: {
        color: "#f1f1f1"
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      formatter: function(value, index) {
        let date = moment(value, 'YYYY-MM-DD')
        if (index === 0) {
          date = date.format("YYYY.M.D");
        }else{
          date = date.format("M.D");
        }
        return date;
      },
      textStyle: {
        color: "rgba(68, 74, 88, 0.4)",
        fontSize: 11,
        fontFamily: "Poppins"
      }
    }
  }
  ,
  yAxis: {
    name: "min",
    nameTextStyle: {
      color: "rgba(68, 74, 88, 0.4)"
    },
    axisLine: {
      show: false,
      lineStyle: {
        color: "#f1f1f1"
      }
    },
    axisTick: {
      show: false
    },
    axisLabel: {
      textStyle: {
        color: "#444a58",
        fontSize: 11,
        fontFamily: "Poppins"
      }
    }
  },
  dataZoom: [
    {
      type: "slider",
      start: 0,
      end: 100
    },
    {
      type: "inside",
      start: 0,
      end: 100
    }
  ],
  series: [
    {
      name: 'origin',
      type: "bar",
      data: [],
      stack: false,
      barWidth: 6,
      itemStyle: {
        normal: {
          color: "#5aadf5",
          barBorderRadius: 10
        }
      }
    },
    {
      name: 'target',
      type: "bar",
      data: [],
      stack: false,
      barWidth: 6,
      itemStyle: {
        normal: {
          color: "#f0734d",
          barBorderRadius: 10
        }
      }
    }
  ]
};

export default option;
