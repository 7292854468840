var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Calendar" },
    [
      _c(
        "transition",
        { attrs: { name: "slide-fade" } },
        [
          _vm.sortingMenu
            ? _c("CalendarSorting", {
                ref: "sorting",
                attrs: {
                  "all-patients": _vm.allPatients,
                  "event-types": _vm.eventTypes,
                  "window-height": _vm.windowHeight + 60,
                  "handle-detail-popup-close": _vm.handleDetailPopupClose,
                  "sort-event-sources": _vm.sortEventSources,
                  "set-event-sources": _vm.setEventSources
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "calendar-area" },
        [
          _c(
            "button",
            {
              staticClass: "sorting-btn icon-btn",
              attrs: { type: "button" },
              on: { click: _vm.toggleSortingMenu }
            },
            [_vm._m(0)]
          ),
          _vm._v(" "),
          _c("FullCalendar", {
            ref: "fullCalendar",
            staticClass: "fullCalendar",
            attrs: {
              "time-zone": "local",
              plugins: _vm.calendarConfig.calendarPlugins,
              "default-view": "dayGridMonth",
              header: _vm.calendarConfig.header,
              "event-limit": _vm.calendarConfig.eventLimit,
              selectable: _vm.calendarConfig.selectable,
              "show-non-current-dates": _vm.calendarConfig.showNonCurrentDates,
              "fixed-week-count": _vm.calendarConfig.fixedWeekCount,
              "day-popover-format": _vm.calendarConfig.dayPopoverFormat,
              "title-format": _vm.calendarConfig.titleFormat,
              "all-day-text": _vm.calendarConfig.allDayText,
              "button-text": _vm.calendarConfig.buttonText,
              "first-day": _vm.calendarConfig.firstDay,
              "dates-render": _vm.handleDatesRender,
              "event-limit-text": _vm.calendarConfig.eventLimitText,
              height: _vm.windowHeight,
              now: _vm.now
            },
            on: {
              eventClick: _vm.handleEventClick,
              select: _vm.createEvent,
              windowResize: _vm.handleWindowResize
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.eventDetailPopup.view
        ? _c("EventDetail", {
            ref: "detailPopup",
            attrs: {
              "event-detail-info": _vm.eventDetailPopup,
              "start-screen-sharing": _vm.startScreenSharing,
              "start-video-call": _vm.startVideoCall,
              "handle-detail-popup-close": _vm.handleDetailPopupClose,
              "change-event-sources": _vm.changeEventSources,
              "delete-event": _vm.deleteEvent,
              "deny-event": _vm.denyEvent,
              "handle-error": _vm.handleError,
              "calendar-size": _vm.calendarSize,
              "edit-event": _vm.editEvent
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.createEventPopup.view
        ? _c("CreateEvent", {
            ref: "createPopup",
            attrs: {
              "create-event-info": _vm.createEventPopup,
              "change-event-sources": _vm.changeEventSources,
              "handle-error": _vm.handleError,
              "handle-create-event-popup-close": _vm.handleCreateEventPopupClose
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_sorting" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" }),
      _vm._v(" "),
      _c("span", { staticClass: "path3" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }