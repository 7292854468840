import Vue from "vue";
import Router from "vue-router";
import { fetchGetClinicReqStatus } from "@/api/clinic.js";
import { fetchGetTherapistInfo } from "@/api/user.js";
Vue.use(Router);

/* Layout */
import Layout_therapist from "@/layout/therapist.vue";
import Layout_account from "@/layout/account.vue";
import Layout_patient from "@/layout/patient.vue";
import Layout_Clinic from "@/layout/clinic.vue";

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

// router state check
const reqStatusCheck = () => async (to, from, next) => {
  const therapistInfo = await fetchGetTherapistInfo();
  if (therapistInfo.clinic.id !== -1) {
    const reqStatus = await fetchGetClinicReqStatus();
    if (reqStatus.status !== 1) {
      return next("/approvalStatus");
    }
  }
  next();
};

export const constantRoutes = [
  {
    path: "/",
    redirect: "/calendar"
  },
  {
    path: "/login",
    component: Layout_account,
    children: [
      {
        path: "",
        name: "Login",
        component: () => import("@/views/account/Login")
      }
    ]
  },
  {
    path: "/approvalStatus",
    component: Layout_account,
    children: [
      {
        path: "",
        name: "ApprovalStatus",
        component: () => import("@/views/account/ApprovalStatus")
      }
    ]
  },
  {
    path: "/resetPassword",
    component: Layout_account,
    children: [
      {
        path: "",
        name: "ResetPassword",
        component: () => import("@/views/account/ResetPasswordWithCode")
      }
    ]
  },
  {
    path: "/signup",
    component: Layout_account,
    children: [
      {
        path: "",
        name: "Signup_beforeEmail",
        component: () => import("@/views/account/Signup")
      }
    ]
  },
  {
    path: "/signup/:email",
    component: Layout_account,
    props: true,
    children: [
      {
        path: "",
        name: "Signup",
        component: () => import("@/views/account/Signup")
      }
    ]
  },
  {
    path: "/changeClinic",
    component: Layout_account,
    children: [
      {
        path: "",
        name: "ChangeClinic",
        component: () => import("@/views/account/Clinic")
      }
    ]
  },
  {
    path: "/profile",
    component: Layout_account,
    children: [
      {
        path: "",
        name: "TherapistProfile",
        component: () => import("@/views/account/Profile"),
        beforeEnter: reqStatusCheck()
      }
    ]
  },
  {
    path: "/account",
    component: Layout_account,
    children: [
      {
        path: "password",
        name: "Forgotpassword",
        component: () => import("@/views/account/ForgotPassword")
      }
      // {
      //   path: "email",
      //   name: "ForgotEmail",
      //   component: () => import("@/views/account/ForgotEmail.vue")
      // }
    ]
  },
  {
    path: "/calendar",
    component: Layout_therapist,
    children: [
      {
        path: "",
        name: "Calendar",
        component: () => import("@/views/calendar"),
        beforeEnter: reqStatusCheck()
      },
      {
        path: "/calendar/:eventId",
        name: "Calendar_withId",
        component: () => import("@/views/calendar")
      }
    ]
  },
  {
    path: "/patient",
    component: Layout_patient,
    beforeEnter: reqStatusCheck(),
    children: [
      {
        path: "",
        name: "Profile",
        component: () => import("@/views/patient/components/Profile")
      },
      {
        path: "home",
        name: "Home",
        component: () => import("@/views/patient/components/Home")
      },
      {
        path: "homework",
        name: "Homework",
        component: () => import("@/views/patient/components/Homework")
      },
      {
        path: "calendar",
        name: "PatientCalendar",
        component: () => import("@/views/patient/components/Calendar")
      },
      {
        path: "advice",
        name: "Advice",
        component: () => import("@/views/patient/components/Advice")
      },
      {
        path: "memo",
        name: "Memo",
        component: () => import("@/views/patient/components/Memo")
      }
    ]
  },
  {
    path: "/clinic",
    component: Layout_Clinic,
    beforeEnter: reqStatusCheck(),
    children: [
      {
        path: "",
        redirect: "/clinic/request/patient"
      },
      {
        path: "request",
        redirect: "/clinic/request/patient"
      },
      {
        path: "request/:target",
        name: "ClinicRequest",
        component: () => import("@/views/clinic/components/Request")
      },
      {
        path: "management/:target",
        name: "ClinicManagement",
        component: () => import("@/views/clinic/components/Management")
      },
      {
        path: "mapping",
        name: "ClinicMapping",
        component: () => import("@/views/clinic/components/Mapping")
      },
      {
        path: "roleassign",
        name: "RoleAssign",
        component: () => import("@/views/clinic/components/RoleAssign")
      }
    ]
  },
  // 현재는 404page가 필요없음
  // {
  //   path: "/404",
  //   component: () => import("@/views/404")
  // },
  { path: "*", redirect: "/" }
];

const createRouter = () =>
  new Router({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
// export function resetRouter() {
//   const newRouter = createRouter();
//   router.matcher = newRouter.matcher; // reset router
// }

export default router;
