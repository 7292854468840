<template>
  <div id="account">
    <div class="top">
      <a class="nav-home" @click="routerLink">
        <img :src="logo" alt="neofect logo">
      </a>
    </div>
    <router-view @status="loginStatus" />
    <footer class="footer">NEOFECT. ALL RIGHTS RESERVED</footer>
    <modals-container />
  </div>
</template>
<script>
/* eslint-disable */
import SettingTime from "@/components/SettingTime";
import Timepicker from "vue2-timepicker";
import Select from "vue-select";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    SettingTime,
    Timepicker,
    Select,
    Datepicker,
  },
  data() {
    return {
      settingTime: {
        view: false,
      },
      autentication: true,
      state: 1,
    };
  },
  methods: {
    loginStatus(state) {
      this.state = state;
    },
    routerLink() {
      if (this.state !== 1) {
        this.$store.dispatch("user/logout").then((result) => {
          location.href = "/";
        });
      } else {
        this.$router.push("/");
      }
    },
    toggleSettingTime() {
      const uri = `/therapist/workinghours`;
      this.$http.get(uri).then((result) => {
        console.log(result);
        if (result.status === 200) {
          const createNew = {
            view: true,
            ...result.data,
          };
          this.settingTime = createNew;
        }
      });
    },
    changeSettingTime(settings) {
      const uri = `/therapist/workinghours`;
      this.$http.post(uri, settings).then((result) => {
        console.log(result);
        if (result.status === 200) this.closeSettingTime();
      });
    },
    closeSettingTime() {
      this.settingTime.view = false;
    },
  },

  computed: {
    logo() {
      return require("@/assets/svgs/logo-neofect.svg");
    },
  },
  mounted() {},
};
</script>
<style lang='scss'>
#account {
  font-family: "manrope3";
  position: relative;
  display: block;
  .top {
    width: 100%;
    // border-bottom: 1px solid $borderColor1;
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    box-sizing: border-box;

    .nav-home {
      cursor: pointer;
    }
  }
  .view {
    width: 100%;
  }
  .footer {
    border-top: 1px solid $borderColor1;
    max-width: 350px;
    margin: 88px auto 120px;
    color: $fontColor3;
    font-size: $fontSize7;
    padding-top: 12px;
  }
}
</style>





