<template>
  <div v-if="isShowChat" class="TextChat">
    <!-- publishing -->
    <div class="popup-control">
      <button type="button">
        <span class="icon-ic_delete" @click="toggleChat">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
    <div class="chat-wrapper">
      <PatientList />
      <ChatRoom v-if="selectedInfo !== undefined" :key="selectedInfo.userId" />
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import PatientList from "./PatientList";
import ChatRoom from "./ChatRoom";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TextChat",
  components: {
    PatientList,
    ChatRoom
  },
  data() {
    return {
      patientId: 0
    };
  },
  computed: {
    ...mapGetters({
      selectedInfo: "chat/selectedInfo",
      isShowChat: "chat/isShowChat"
    })
  },
  methods: {
    ...mapActions({
      initSendBird: "chat/initSendBird",
      toggleChat: "chat/toggleTextChat"
    })
  },
  created() {
    // TODO initSendBird 최초 로그인한 후 위치로 변경
    this.initSendBird();
  }
};
</script>

<style lang="scss" scoped>
.TextChat {
  position: fixed;
  bottom: 80px;
  right: 118px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.4);
  border: solid 1px #d0d4dc;
  z-index: 100;
  .popup-control {
    width: 100%;
    height: 40px;
    background: #635042;
    display: flex;
    button {
      margin-left: auto;
      margin-right: 16px;
      color: #ffffff;
    }
  }
  .chat-wrapper {
    display: flex;
  }
}
</style>
