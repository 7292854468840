<template>
  <div class="ScreenSharing">
    <div class="Session" @error="errorHandler">
      <div v-if="sessionDie" class="notice-message session-die">
        Video call has been disconnected.
        <br>Please turn off and restart the video call.
      </div>
      <div class="push-again-btn">
        <div v-if="!disconnected">
          <span class="notice-message">Connecting...</span>
        </div>
        <div v-else>
          <span v-if="disconnected === 'CASE_1'" class="notice-message">Patient doesn't respond</span>
          <span v-else class="notice-message">Screen Sharing has been disconnected</span>
          <div class="primary-type-btns">
            <button @click="handleConnectPush">Connect Again</button>
          </div>
        </div>
      </div>
      <div v-for="stream in streams" id="subscribers" :key="stream.streamId">
        <subscriber v-if="stream" :stream="stream" :session="session" @error="errorHandler" />
      </div>
      <div class="control-bar">
        <button type="button" @click="handleClose">
          <span class="icon-ic_delete">
            <span class="path1" />
            <span class="path2" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import Confirm from "@/components/Confirm";
import OT from "@opentok/client";
import Publisher from "./components/Publisher.vue";
import Subscriber from "./components/Subscriber.vue";
import {
  fetchScreenSharingStart,
  fetchScreenSharingStartInSchedule
} from "@/api/calendar.js";
import { mapGetters } from "vuex";
export default {
  name: "ScreenSharing",
  components: { Publisher, Subscriber },
  props: {
    sessionId: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    },
    apiKey: {
      type: String,
      required: true
    },
    eventId: String
  },
  data() {
    return {
      streams: [],
      session: null,
      disconnected: false,
      home_cancel: undefined,
      sessionDie: false,
      pushSend: false
    };
  },
  watch: {
    disconnected: function(newVal, oldVal) {
      // console.log(newVal, oldVal);
    }
  },
  methods: {
    errorHandler(error) {
      console.log(error.message);
    },
    handleConnectPush() {
      this.disconnectSession();
      if (!this.selectedPatient) {
        return;
      }
      this.pushSend = true;
      if (this.eventId) {
        fetchScreenSharingStartInSchedule(this.eventId)
          .then(result => {
            this.disconnected = false;
            this.home_cancel = undefined;
            this.initSession(result.token, result.api_key, result.session_id);
          })
          .catch(error => {
            this.handleError(error);
          });
      } else {
        fetchScreenSharingStart(this.selectedPatient.id)
          .then(result => {
            this.disconnected = false;
            this.home_cancel = undefined;
            this.initSession(result.token, result.api_key, result.session_id);
          })
          .catch(error => {
            this.handleError(error);
          });
      }
    },
    disconnectSession() {
      this.session.disconnect(this.session.connection, error => {
        // console.log("disconnet session.");
        this.streams = [];
        if (error) {
          this.errorHandler(error);
        }
      });
    },
    async disconnect() {
      this.sendDisconnect();
      this.disconnectSession();
      try {
        if (this.eventId) {
          await this.$store.dispatch(
            "screensharing/closeInSchedule",
            this.eventId
          );
        } else {
          await this.$store.dispatch("screensharing/close");
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    sendDisconnect() {
      if (this.streams.length === 0) {
        return;
      }
      this.session.signal(
        {
          type: "disconnect_therapist",
          data: ""
        },
        error => {
          if (error) {
            console.log("signal error", error);
          }
        }
      );
    },
    handleClose() {
      if (this.sessionDie) {
        return this.$store.dispatch("screensharing/closeView");
      }
      this.$modal.show(
        Confirm,
        {
          type: "close",
          submit: this.disconnect,
          confirm: {
            title: "End Screen Sharing",
            message: "Are you sure you want to end the Screen Sharing?"
          }
        },
        {
          height: "auto"
        }
      );
    },
    handleError(error) {
      console.error("ScreenSharing", error);
      if (error) {
        this.$modal.show(
          Confirm,
          {
            type: "error",
            confirm: {
              title: "Error occurred",
              message: "Something is wrong with the server. Please try again."
            }
          },
          {
            height: "auto"
          }
        );
      }
    },
    initSession(token, apiKey, sessionId) {
      this.session = OT.initSession(apiKey, sessionId);
      this.session.connect(token, error => {
        // console.log("Session is connected");
        if (error) {
          return this.errorHandler(error);
        }
        this.sessionDie = false;
        this.pushSend = false;
        this.home_cancel = setTimeout(() => {
          this.disconnected = "CASE_1";
        }, 20000);
      });
      this.session.on("streamCreated", event => {
        console.log("stream", event);
        this.streams.push(event.stream);
        clearTimeout(this.home_cancel);
      });
      this.session.on("streamDestroyed", event => {
        const idx = this.streams.indexOf(event.stream);
        this.disconnected = "CASE_2";
        if (idx > -1) {
          this.streams.splice(idx, 1);
        }
      });
      this.session.on("sessionDisconnected", event => {
        // console.log(this.pushSend);
        if (!this.pushSend) {
          this.sessionDie = true;
        }
        console.log("You were disconnected from the session.", event.reason);
      });
      this.session.on("signal", function(event) {
        // console.log(event);
        if (event.type === "signal:disconnect_home") {
          // console.log(event);
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      selectedPatient: "patient/selectedPatient"
    })
  },
  created() {
    this.initSession(this.token, this.apiKey, this.sessionId);
  },
  beforeDestroy() {
    this.disconnectSession();
  }
};
</script>

<style lang="scss" scoped>
.ScreenSharing {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  z-index: 100;

  .Session {
    width: 100%;
    height: 100%;
    position: relative;
    .notice-message {
      font-size: $fontSize3;
      color: #ffffff;
      &.session-die {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
        transform: translate(-50%, -50%);
        width: 400px;
        height: 150px;
        background: black;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 28px;
      }
    }
    .push-again-btn {
      background: #000000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      .primary-type-btns {
        margin-top: 48px;
      }
    }
  }
  .OT_publisher {
    float: left;
    position: relative;
    z-index: 1;
  }
  #subscribers {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 70px);
  }
  .control-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 0;
    background: $fontColor1;
    button {
      margin: auto;
      background: $uiColor1;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-ic_delete {
        color: #ffffff;
      }
    }
  }
}
</style>
