<template>
  <div class="MemoDetail">
    <div class="memo-date">
      {{ $moment(memo.created).format("MMMM D, YYYY h:mmA") }}
    </div>
    <div class="memo-type">{{ printType(memo.division) }}</div>
    <div class="memo-title">{{ memo.title }}</div>
    <div class="memo-contents">{{ memo.content }}</div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button
        type="button"
        class="del-btn icon-btn"
        @click="handleDelete"
        v-if="memo.division !== 'VIDEOCALLRECORD'"
      >
        <span class="icon-ic_bin">
          <span class="path1" />
          <span class="path2" />
          <span class="path3" />
          <span class="path4" />
          <span class="path5" />
        </span>
      </button>
      <button
        type="button"
        class="edit-btn icon-btn"
        @click="handleEdit(memo)"
        v-if="memo.division !== 'VIDEOCALLRECORD'"
      >
        <span class="icon-ic_edit">
          <span class="path1" />
          <span class="path2" />
          <span class="path3" />
        </span>
      </button>
      <button type="button" class="close-btn icon-btn" @click="handleClose">
        <span class="icon-ic_delete">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Confirm from "@/components/Confirm";
export default {
  name: "MemoDetail",
  props: {
    memo: {
      division: String,
      id: String,
      title: String,
      content: String,
      created: String,
    },
    printType: Function,
    deleteMemo: Function,
    handleEditPopup: Function,
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleEdit(memo) {
      this.handleClose();
      this.handleEditPopup(memo);
    },
    handleDelete(memoId) {
      this.$modal.show(
        Confirm,
        {
          type: "delete",
          submit: this.deleteAfterConfirm,
          confirm: {
            title: this.$t("page.patient.documentation.deleteModal.title"),
            message: this.$t(
              "page.patient.documentation.deleteModal.description"
            ),
          },
        },
        {
          height: "auto",
        }
      );
    },
    deleteAfterConfirm() {
      this.deleteMemo(this.memo.id).then((result) => {
        this.handleClose();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.MemoDetail {
  padding: 50px 24px;
  .memo-date {
    color: $fontColor3;
    font-size: $fontSize7;
  }
  .memo-type {
    color: $mainColor1;
    font-size: $fontSize6;
    margin-top: 11px;
  }
  .memo-title {
    font-weight: 500;
    color: $fontColor1;
    font-size: $fontSize2;
    margin-top: 8px;
    line-height: 32px;
  }
  .memo-contents {
    margin-top: 30px;
    font-size: $fontSize6;
    color: $fontColor2;
    line-height: 18px;
    white-space: pre-wrap;
    word-break: normal;
  }
}
</style>
