var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "SettingTime", class: _vm.show ? "" : "invisible" },
    [
      _c("div", { staticClass: "contents" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("page.calendar.setting.title")))]),
        _vm._v(" "),
        _c("div", { staticClass: "days-btns" }, [
          _c(
            "ul",
            _vm._l(_vm.days, function(day, index) {
              return _c("li", { key: index }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.checkedDays,
                      expression: "checkedDays"
                    }
                  ],
                  attrs: { id: "day" + index, type: "checkbox" },
                  domProps: {
                    value: day.toLowerCase(),
                    checked: Array.isArray(_vm.checkedDays)
                      ? _vm._i(_vm.checkedDays, day.toLowerCase()) > -1
                      : _vm.checkedDays
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.checkedDays,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = day.toLowerCase(),
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.checkedDays = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.checkedDays = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.checkedDays = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("label", { attrs: { for: "day" + index } }, [
                  _vm._v(_vm._s(day))
                ])
              ])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("h2", [
          _vm._v(_vm._s(_vm.$t("page.calendar.setting.workingHours")))
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "time-range" },
          [
            _c(
              "Select",
              {
                attrs: {
                  options: _vm.times,
                  label: "label",
                  placeholder: "H:mm A",
                  components: {
                    OpenIndicator: _vm.OpenIndicator,
                    Deselect: _vm.Deselect
                  }
                },
                model: {
                  value: _vm.workingStartTime,
                  callback: function($$v) {
                    _vm.workingStartTime = $$v
                  },
                  expression: "workingStartTime"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vs__no-options",
                    attrs: { slot: "no-options" },
                    slot: "no-options"
                  },
                  [_vm._v("\n          Sorry, no matching time.\n        ")]
                )
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "center-hyphen" }, [_vm._v("-")]),
            _vm._v(" "),
            _c(
              "Select",
              {
                attrs: {
                  options: _vm.times,
                  label: "label",
                  placeholder: "H:mm A",
                  components: {
                    OpenIndicator: _vm.OpenIndicator,
                    Deselect: _vm.Deselect
                  }
                },
                model: {
                  value: _vm.workingEndTime,
                  callback: function($$v) {
                    _vm.workingEndTime = $$v
                  },
                  expression: "workingEndTime"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vs__no-options",
                    attrs: { slot: "no-options" },
                    slot: "no-options"
                  },
                  [_vm._v("\n          Sorry, no matching time.\n        ")]
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("h2", [_vm._v(_vm._s(_vm.$t("page.calendar.setting.lunchBreak")))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "time-range" },
          [
            _c(
              "Select",
              {
                attrs: {
                  options: _vm.times,
                  label: "label",
                  placeholder: "H:mm A",
                  components: {
                    OpenIndicator: _vm.OpenIndicator,
                    Deselect: _vm.Deselect
                  }
                },
                model: {
                  value: _vm.lunchStartTime,
                  callback: function($$v) {
                    _vm.lunchStartTime = $$v
                  },
                  expression: "lunchStartTime"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vs__no-options",
                    attrs: { slot: "no-options" },
                    slot: "no-options"
                  },
                  [_vm._v("\n          Sorry, no matching time.\n        ")]
                )
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "center-hyphen" }, [_vm._v("-")]),
            _vm._v(" "),
            _c(
              "Select",
              {
                attrs: {
                  options: _vm.times,
                  label: "label",
                  placeholder: "H:mm A",
                  components: {
                    OpenIndicator: _vm.OpenIndicator,
                    Deselect: _vm.Deselect
                  }
                },
                model: {
                  value: _vm.lunchEndTime,
                  callback: function($$v) {
                    _vm.lunchEndTime = $$v
                  },
                  expression: "lunchEndTime"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vs__no-options",
                    attrs: { slot: "no-options" },
                    slot: "no-options"
                  },
                  [_vm._v("\n          Sorry, no matching time.\n        ")]
                )
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "popup-submit-btns" }, [
        _c(
          "button",
          {
            staticClass: "accept-btn",
            attrs: { type: "button" },
            on: { click: _vm.handleSubmit }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("page.calendar.setting.save")) +
                "\n    "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "popup-btns" }, [
        _c(
          "button",
          {
            staticClass: "close-btn",
            attrs: { type: "button" },
            on: { click: _vm.closeSettingTime }
          },
          [_vm._m(0)]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete ic-icon" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }