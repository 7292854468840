var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "PatientList", style: { height: _vm.windowHeight + "px" } },
    [
      _c("div", { staticClass: "sort-patient" }, [
        _c("div", { staticClass: "search" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchText,
                expression: "searchText"
              }
            ],
            attrs: { type: "text", placeholder: _vm.searchPlaceholder },
            domProps: { value: _vm.searchText },
            on: {
              keyup: _vm.handleSearch,
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchText = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sort-type select-type2" }),
        _vm._v(" "),
        _c(
          "ul",
          {
            staticClass: "patients-list custom-scrollbar",
            style: { maxHeight: _vm.windowHeight - 108 + "px" }
          },
          _vm._l(_vm.searchedPatients, function(patient, index) {
            return _c(
              "li",
              {
                key: index,
                class: patient === _vm.selectedPatient ? "on" : ""
              },
              [
                _c(
                  "button",
                  {
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.handleSelect(index, patient)
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "patient-image" }, [
                      _vm._m(1, true),
                      _vm._v(" "),
                      patient.image
                        ? _c("img", {
                            attrs: {
                              src: patient.image,
                              alt: patient.name + "'s profile image'"
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "patient-info" }, [
                      _c("span", { staticClass: "patient-name" }, [
                        _vm._v(_vm._s(patient.name ? patient.name : "-"))
                      ]),
                      _vm._v(" "),
                      patient.gender
                        ? _c(
                            "span",
                            { staticClass: "patient-gender-age capitalize" },
                            [
                              _vm._v(
                                _vm._s(_vm.genderFilter(patient.gender)) +
                                  " / " +
                                  _vm._s(_vm.printAge(patient.birthday))
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_search" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_group_fill" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }