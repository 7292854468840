// right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"

// fullcalendar 기본셋팅
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import moment from "moment-timezone";
const CALENDAR_DEFAULT = {
  calendarPlugins: [
    dayGridPlugin,
    interactionPlugin,
    timeGridPlugin,
    listPlugin
  ],
  header: {
    left: "",
    center: "today prev title next",
    right: ""
  },
  titleFormat: function(dateData) {
    // 캘린더 타이틀날짜표기
    const currentMonth = new Date(dateData.date.marker);
    return moment.utc(currentMonth).format("MMMM YYYY");
  },
  selectable: true, // date select 가능여부
  eventLimit: true, // event 갯수 limit
  showNonCurrentDates: false, // 해당 달 이외의 날짜정보 hidden
  fixedWeekCount: false, // 항상 6줄의 위크카운트를 보여줄것인가
  dayPopoverFormat: function(dateData) {
    // 더보기 팝업 타이틀날짜표기
    const title = new Date(dateData.date.marker);
    return moment(title).format("D ddd");
  },
  buttonText: {
    today: "Today",
    month: "month",
    week: "week",
    day: "day",
    list: "list"
  },
  allDayText: "AllDay",
  firstDay: 1, // 시작요일 설정 0부터 일요일,
  eventLimitText: "",
  /* only patient calendar */
  columnHeaderHtml: function(date) {
    return `
      <span class='date'>${moment(date).format("D")}</span>
      <span class='day'>${moment(date).format("ddd")}</span>
    `;
  },
  minTime: "06:00:00",
  maxTime: "19:00:00",
  contentHeight: "auto",
  allDaySlot: false
};

export default CALENDAR_DEFAULT;
