import { fetchGetPushHistory } from "@/api/calendar";

const state = {
  hasNewNoti: false,
  notification: null
};

const mutations = {
  SET_NOTIFICATION: (state, noti) => {
    state.notification = noti;
    state.hasNewNoti = true;
  },
  MARK_NOTIFICATION: state => {
    state.hasNewNoti = true;
  },
  READ_NOTIFICATION: state => {
    state.hasNewNoti = false;
  },
  RESET_NOTIFICATION: state => {
    state.hasNewNoti = false;
    state.notification = null;
  }
};

const actions = {
  setNotification({ commit }, noti) {
    return new Promise(resolve => {
      commit("SET_NOTIFICATION", noti);
      resolve(noti);
    });
  },
  readNotification({ commit }) {
    commit("READ_NOTIFICATION");
  },
  checkNotification({ commit }) {
    const page = 1;
    const size = 50;
    fetchGetPushHistory(page, size).then(result => {
      const pushList = result.contents;
      if (pushList) {
        pushList.forEach(push => {
          if (!push.is_read) {
            return commit("MARK_NOTIFICATION");
          }
        });
      }
    });
  },
  resetNotification({ commit }) {
    commit("RESET_NOTIFICATION");
  }
};

const getters = {
  hasNewNoti: state => {
    return state.hasNewNoti;
  },
  notification: state => {
    return state.notification;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
