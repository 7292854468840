<template>
  <section class="report-section total-distance-moved">
    <h1>{{ $t("page.patient.neofectHome.data.totalDistanceMoved") }}</h1>
    <div class="report-contents">
      <Loading v-if="loading" />
      <div class="report-info" v-if="totalDistance && dateFrom">
        <div class="report-value">
          <span class="value">{{ (totalDistance / 100.0).toFixed(2) }} m</span>
          <div class="in-decrease" v-if="isRangeCompare" v-bind:class="{decrease: totalDistance - targetTotalDistance < 0, increase: totalDistance - targetTotalDistance > 0}">
            <span class="arrow" v-bind:class="{down: totalDistance - targetTotalDistance < 0, up: totalDistance - targetTotalDistance > 0}"></span>
            {{ removeNumberMinusSign((totalDistance / 100.0).toFixed(2) - (targetTotalDistance / 100.0).toFixed(2)) }}
          </div>
        </div>
        <span class="label">
          {{ $moment(new Date(dateFrom)).format("MMM DD,YYYY") }} ~
          {{ $moment(new Date(dateTo)).format("MMM DD,YYYY") }}
        </span>
      </div>
      <div class="graph">
        <v-chart
          v-if="!isNoData"
          ref="chartRef"
          :options="chartOptions"
          style="width:136px; height:85px;"
          :auto-resize="true"
        ></v-chart>
      </div>
      <div v-if="isNoData" class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
      <div v-if="error" class="no-data">Error!</div>
    </div>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { getBoardMovedDistance } from "@/api/report.js";
import option from "./BoardMovedDistance.js";
export default {
  name: "BoardMovedDistance",
  components: {
    Loading
  },
  data() {
    return {
      chartOptions: null,
      dateFrom: null,
      dateTo: null,
      totalDistance: null,
      targetTotalDistance: 0,
      isNoData: false,
      loading: false,
      error: false
    };
  },
  props: {
    device: {
      type: Object
    },
    dateRange: {
      type: Object
    },
    toDateRange: {
      type: Object
    },
    isRangeCompare: Boolean
  },
  computed: {
    patient: function() {
      return this.$store.getters.selectedPatient;
    }
  },
  watch: {
    device(newValue, oldValue) {
      this.error = false;
      this.isNoData = false;
      this.chartOptions = null;
      this.dateFrom = null;
      this.dateTo = null;
      this.totalDistance = null;
      this.drawChart();
    },
    dateRange(newValue, oldValue) {
      this.drawChart();
    },
    toDateRange(newValue, oldVAlue) {
      this.drawChart();
    },
    isRangeCompare(newValue, oldValue) {
      this.drawChart();
    }
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    removeNumberMinusSign: function (number) {
      if (number < 0) {
        return `${(number * -1).toFixed(2)}m`;
      } else if (number === 0) {
        return '-';
      }
      return `${number.toFixed(2)}m`;
    },
      async drawChart() {
      if (!this.patient || !this.device) return;

      try {
        this.loading = true;
        this.isNoData = true;
        const movements = await getBoardMovedDistance(
          this.patient.id,
          this.device.id,
          this.$moment(this.dateRange.startDate).toISOString(),
        this.$moment(this.dateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString()
        );
        let targetMovements = null;
        if (this.isRangeCompare) {
          targetMovements = await getBoardMovedDistance(
            this.patient.id,
            this.device.id,
            this.$moment(this.toDateRange.startDate).toISOString(),
            this.$moment(this.toDateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString()
          );
          if (!(targetMovements.movement.dates === null || targetMovements.movement.dates.length < 1)){
            this.targetTotalDistance = targetMovements.movement.distances.reduce((accumulator, currentValue)=>accumulator+currentValue)
          }
        }
        this.loading = false;
        const movement = movements.movement;

        this.isNoData = movement.dates === null || movement.dates.length < 1;

        if (!this.isNoData && movement.distances.reduce((accumulator, currentValue)=>accumulator+currentValue) === 0){
          this.isNoData = true
        }

        if (!this.isNoData) {
          if (movement.dates != null && movement.dates.length > 0) {
            this.dateFrom = movement.dates[0];
            this.dateTo = movement.dates[movement.dates.length - 1];
          }
          if (movement.distances != null) {
            this.totalDistance = movement.distances.reduce(
              (accumulator, currentValue, currentIndex, array) =>
                accumulator + currentValue
            );
          }
          this.chartOptions = option(movement);
        } else {
          this.chartOptions = null;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.total-distance-moved {
  .report-contents {
    position: relative;
    overflow: hidden;
    .no-data,
    .error {
      width: 100%;
      height: 135px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      &.error {
        z-index: 2;
      }
    }
  }
}
</style>
