/* eslint-disable no-undef */
import axios from "axios";
import store from "@/store";
import router from "@/router";
import { getToken } from "@/utils/auth";
NProgress.configure({ showSpinner: false });
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000 // request timeout,
});
// request interceptor
service.interceptors.request.use(
  config => {
    NProgress.start();
    if (store.getters.token) {
      config.headers["Authorization"] = "Bearer " + getToken().access_token;
    }
    return config;
  },
  error => {
    NProgress.done();
    return error.response;
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code && res.code !== 20000) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
      }
      NProgress.done();
      return new Error(res.message || "Something broken");
    } else {
      NProgress.done();
      return res;
    }
  },
  error => {
    NProgress.done();
    if (error.response.status === 403 || error.response.status === 401) {
      if (router.currentRoute.path.includes("clinic")) {
        store.dispatch("user/logout");
        router.push("/login");
      } else {
        throw error.response;
      }
    } else {
      throw error.response;
    }
  }
);

export default service;
