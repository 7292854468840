<template>
  <section class="report-section free-exploration">
    <h1>Free Exploration</h1>
    <div class="report-contents">
      <div class="table"  v-if="left && right">
        <table>
          <thead>
          <tr>
            <th width="170"></th>
            <th>[{{ leftId }}]</th>
            <th>[{{ rightId }}]</th>
            <th width="70"></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>Ratio of Area</th>
            <td>
              {{ left.areaRatioPercentage ? left.areaRatioPercentage.toFixed(0) :0 }} %
            </td>
            <td>
              {{ right.areaRatioPercentage ? right.areaRatioPercentage.toFixed(0) :0 }} %
            </td>
            <td>
              {{diffLeftRight(
                left.areaRatioPercentage ? left.areaRatioPercentage.toFixed(0) :0,
                right.areaRatioPercentage ? right.areaRatioPercentage.toFixed(0) :0
              )}}
              <img :src="'/img/icons/' + upDownIcon(
                      left.areaRatioPercentage ? left.areaRatioPercentage.toFixed(0) :0,
                      right.areaRatioPercentage ? right.areaRatioPercentage.toFixed(0) :0
                    )" width="10" height="10"/>
            </td>
          </tr>
          <tr>
            <th>Total Distance</th>
            <td>
              {{ left.meanRadialDistanceCenti? left.meanRadialDistanceCenti.toFixed(0):0 }} cm
            </td>
            <td>
              {{ right.meanRadialDistanceCenti? right.meanRadialDistanceCenti.toFixed(0):0 }} cm
            </td>
            <td>
              {{diffLeftRight(
                left.meanRadialDistanceCenti? left.meanRadialDistanceCenti.toFixed(0):0,
                right.meanRadialDistanceCenti? right.meanRadialDistanceCenti.toFixed(0):0
              )}}
              <img :src="'/img/icons/' + upDownIcon(
                      left.meanRadialDistanceCenti? left.meanRadialDistanceCenti.toFixed(0):0,
                      right.meanRadialDistanceCenti? right.meanRadialDistanceCenti.toFixed(0):0
                    )" width="10" height="10"/>
            </td>
          </tr>
          <tr>
            <th>Total Time</th>
            <td>
              {{ left.time ? left.time : 0 }} Secs
            </td>
            <td>
              {{ right.time ? right.time : 0 }} Secs
            </td>
            <td>
              {{diffLeftRight(
              left.time ? left.time : 0,
              right.time ? right.time : 0
              )}}
              <img :src="'/img/icons/' + upDownIcon(
                      left.time ? left.time : 0,
                      right.time ? right.time : 0
                    )" width="10" height="10"/>
            </td>
          </tr>
          <tr>
            <th>Total Score</th>
            <td>
              {{ left.standardScore ? left.standardScore : 0 }} pt.
            </td>
            <td>
              {{ right.standardScore ? right.standardScore : 0 }} pt.
            </td>
            <td>
              {{diffLeftRight(
              left.standardScore ? left.standardScore : 0,
              right.standardScore ? right.standardScore : 0
              )}}
              <img :src="'/img/icons/' + upDownIcon(
                      left.standardScore ? left.standardScore : 0,
                      right.standardScore ? right.standardScore : 0
                    )" width="10" height="10"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "EvaluationFreeExploration",
  props: {
    leftId: {
      type: Number
    },
    rightId: {
      type: Number
    },
    left: {
      type: Object
    },
    right: {
      type: Object
    }
  },
  watch: {
    evaluation(newValue, oldValue) {
      this.drawChart();
    }
  },
  mounted() {
    this.drawChart();
  },
  beforeDestroy() {},
  methods: {
    diffLeftRight(left, right) {
      if(left === right)
      {
        return '-'
      } else
      {
        return  Math.abs(right - left).toFixed(1)
      }
    },
    upDownIcon(left, right) {
      left = parseFloat(left);
      right = parseFloat(right);
      if(left === right)
      {
        return 'blank.png'
      } else if(left > right) {
        return 'arrow_red.png'
      } else {
        return 'arrow_blue.png'
      }
    },
    async drawChart() {}
  }
};
</script>
