var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "report-section area-arom" }, [
    _c("h1", [
      _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.areaAROM")))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "report-contents" },
      [
        _vm.loading ? _c("Loading") : _vm._e(),
        _vm._v(" "),
        _vm.isValid
          ? _c("div", { staticClass: "report-wrapper" }, [
              _c("div", { staticClass: "report-info" }, [
                _c("div", { staticClass: "report-item" }, [
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.aromArea.last.ratio.toFixed(0)) + "%")
                  ]),
                  _vm._v(" "),
                  _vm.isRangeCompare
                    ? _c(
                        "div",
                        {
                          staticClass: "in-decrease",
                          class: {
                            decrease:
                              _vm.aromArea.last.ratio -
                                _vm.targetAromAreaRatio <
                              0,
                            increase:
                              _vm.aromArea.last.ratio -
                                _vm.targetAromAreaRatio >
                              0
                          }
                        },
                        [
                          _c("span", {
                            staticClass: "arrow",
                            class: {
                              down:
                                _vm.aromArea.last.ratio -
                                  _vm.targetAromAreaRatio <
                                0,
                              up:
                                _vm.aromArea.last.ratio -
                                  _vm.targetAromAreaRatio >
                                0
                            }
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.removeNumberMinusSign(
                                  _vm.aromArea.last.ratio.toFixed(0) -
                                    _vm.targetAromAreaRatio.toFixed(0)
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "label" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm
                          .$moment(new Date(_vm.aromArea.last.date))
                          .format("MMM DD,YYYY")
                      ) +
                      "\n        "
                  )
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "value up" }, [
                  _vm._v("\n          " + _vm._s(_vm.diff) + "%\n          "),
                  _c("span", { class: _vm.upOrDown }, [
                    _c("span", { staticClass: "path1" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "path2" })
                  ])
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "label" }, [
                  _vm._v("\n          Compared to the first time\n          "),
                  _c("br"),
                  _vm._v(
                    "\n          (" +
                      _vm._s(
                        _vm
                          .$moment(new Date(_vm.aromArea.first.date))
                          .format("MMM DD,YYYY")
                      ) +
                      ")\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "graph" },
                [
                  _vm.dimensions
                    ? _c(
                        "v-stage",
                        {
                          attrs: {
                            height: isNaN(_vm.dimensions.stageHeight)
                              ? 0
                              : _vm.dimensions.stageHeight,
                            width: isNaN(_vm.dimensions.stageWidth)
                              ? 0
                              : _vm.dimensions.stageWidth
                          }
                        },
                        [
                          _c(
                            "v-layer",
                            [
                              _c("v-rect", {
                                attrs: {
                                  x: 0,
                                  y: 0,
                                  width: isNaN(_vm.dimensions.stageWidth)
                                    ? 0
                                    : _vm.dimensions.stageWidth,
                                  height: isNaN(_vm.dimensions.stageHeight)
                                    ? 0
                                    : _vm.dimensions.stageHeight,
                                  fill: "rgba(90,173, 244,0.1)"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layer",
                            [
                              _c("v-line", {
                                attrs: {
                                  points: _vm.dimensions.points,
                                  fill: "rgba(90,173, 244,0.2)",
                                  stroke: "#5aadf5",
                                  strokeWidth: 2,
                                  closed: true
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isNoData
          ? _c("div", { staticClass: "no-data" }, [
              _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.empty")))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "no-data" }, [_vm._v("Error!")])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }