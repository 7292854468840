var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "report-section glove-sensor-report" }, [
    _c(
      "div",
      { staticClass: "report-contents" },
      [
        _vm.loading ? _c("Loading") : _vm._e(),
        _vm._v(" "),
        !_vm.isNoData
          ? _c(
              "div",
              { staticClass: "content-grid" },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm._l(_vm.reportList, function(item) {
                  return _c("div", { staticClass: "content-grid-row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content-grid-row-item-category",
                        class: { under: item.underlineShow }
                      },
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.categoryShow,
                                expression: "item.categoryShow"
                              }
                            ]
                          },
                          [_vm._v(_vm._s(item.category))]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-grid-row-item-text" }, [
                      _c("div", [_vm._v(_vm._s(item.text) + " ")]),
                      _vm._v(" "),
                      item.gravityText
                        ? _c(
                            "div",
                            {
                              staticClass: "content-grid-row-item-text gravity"
                            },
                            [_vm._v("Gravity Eliminated")]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-grid-row-item-sum" }, [
                      _vm._v(" " + _vm._s(item.reps) + " ")
                    ]),
                    _vm._v(" "),
                    _vm.isRangeCompare
                      ? _c(
                          "div",
                          {
                            staticClass: "content-grid-row-item-gap",
                            class: {
                              minus: item.repsGap < 0,
                              plus: item.repsGap > 0
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "arrow",
                              class: {
                                down: item.repsGap < 0,
                                up: item.repsGap > 0
                              }
                            }),
                            _vm._v(
                              "\n             " +
                                _vm._s(
                                  _vm.removeNumberMinusSign(item.repsGap)
                                ) +
                                " "
                            )
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "content-grid-row-item-gap" },
                          [_vm._v("\n             -\n           ")]
                        ),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-grid-row-item-sum" }, [
                      _vm._v(" " + _vm._s(item.averageRom) + " ")
                    ]),
                    _vm._v(" "),
                    _vm.isRangeCompare
                      ? _c(
                          "div",
                          {
                            staticClass: "content-grid-row-item-gap",
                            class: {
                              minus: item.averageRomGap < 0,
                              plus: item.averageRomGap > 0
                            }
                          },
                          [
                            _c("span", {
                              staticClass: "arrow",
                              class: {
                                down: item.averageRomGap < 0,
                                up: item.averageRomGap > 0
                              }
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.removeNumberMinusSign(item.averageRomGap)
                                ) +
                                " "
                            )
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "content-grid-row-item-gap" },
                          [_vm._v("\n            -\n          ")]
                        )
                  ])
                })
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isNoData
          ? _c("div", { staticClass: "no-data" }, [
              _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.empty")))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "error" }, [_vm._v("Error!")])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content-grid-row" }, [
      _c("div", { staticClass: "content-grid-row-item-category" }),
      _vm._v(" "),
      _c("div", { staticClass: "content-grid-row-item-text" }),
      _vm._v(" "),
      _c("div", { staticClass: "content-grid-row-item-sum header" }, [
        _vm._v("Reps")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content-grid-row-item-sum header" }, [
        _vm._v("Deg")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }