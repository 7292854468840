var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "CreateMemo" }, [
    _c("div", { staticClass: "contents" }, [
      _c(
        "div",
        { staticClass: "radio-section" },
        _vm._l(_vm.memoTypes, function(type, index) {
          return _c("span", { key: index }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.pickedType,
                  expression: "pickedType"
                }
              ],
              attrs: { id: type.value, type: "radio" },
              domProps: {
                value: type.value,
                checked: _vm._q(_vm.pickedType, type.value)
              },
              on: {
                change: function($event) {
                  _vm.pickedType = type.value
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: type.value } }, [
              _vm._v(_vm._s(type.type))
            ])
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "memo-title" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.title,
              expression: "title"
            }
          ],
          attrs: { type: "text", placeholder: _vm.titlePlaceholder },
          domProps: { value: _vm.title },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.title = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "memo-contents" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.contents,
              expression: "contents"
            }
          ],
          attrs: { placeholder: _vm.memoPlaceholder },
          domProps: { value: _vm.contents },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.contents = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "popup-submit-btns",
        class: _vm.submitDisable ? "disabled" : ""
      },
      [
        _c(
          "button",
          {
            staticClass: "accept-btn",
            attrs: { type: "button", disabled: _vm.submitDisable },
            on: { click: _vm.handleSubmit }
          },
          [
            _vm._v(
              _vm._s(
                _vm.edit
                  ? _vm.$t("page.patient.documentation.modal.saveButton")
                  : _vm.$t("page.patient.documentation.modal.registerButton")
              )
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "popup-btns" }, [
      _c(
        "button",
        {
          staticClass: "close-btn icon-btn",
          attrs: { type: "button" },
          on: { click: _vm.handleClose }
        },
        [_vm._m(0)]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }