import { uuid } from "vue-uuid";

const FcmTokenKey = "neofect_fcm_token_key";
const UUIDKey = "neofect_uuid_token_key";

export function getUuid() {
  let uniqId = localStorage.getItem(UUIDKey);
  if (!uniqId) {
    uniqId = uuid.v1();
    localStorage.setItem(UUIDKey, uniqId);
  }

  return uniqId;
}

export function getFcmToken() {
  return JSON.parse(sessionStorage.getItem(FcmTokenKey));
}

export function setFcmToken(token) {
  return sessionStorage.setItem(FcmTokenKey, JSON.stringify(token));
}

export function removeFcmToken() {
  return sessionStorage.removeItem(FcmTokenKey);
}
