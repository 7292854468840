var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.adviceList.length > 0
    ? _c("div", { staticClass: "Advice", on: { scroll: _vm.handleScroll } }, [
        _c("div", { staticClass: "contents" }, [
          _c("div", { staticClass: "advice-new-btn secondary-type-btns" }, [
            _c(
              "button",
              {
                attrs: { type: "button" },
                on: { click: _vm.handleCreateAdvice }
              },
              [_vm._v(_vm._s(_vm.$t("page.patient.advice.newAdviceButton")))]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "advice-list" }, [
            _c(
              "ul",
              _vm._l(_vm.adviceList, function(advice, index) {
                return _c("li", { key: index }, [
                  _c("div", { staticClass: "advice-info" }, [
                    _c("span", { staticClass: "advice-title" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.$t("page.patient.advice.list.title") +
                              " #" +
                              (_vm.totalCount - index)
                          ) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "advice-date" }, [
                      _vm._v(
                        _vm._s(
                          _vm
                            .$moment(advice.created)
                            .format("MMMM D, YYYY h:mm A")
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "advice-contents" }, [
                    _vm._v(_vm._s(advice.content))
                  ])
                ])
              }),
              0
            )
          ])
        ])
      ])
    : _c("div", { staticClass: "Advice no-written" }, [
        _c("div", { staticClass: "contents" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("page.patient.advice.noAdviceYet")) +
              "\n    "
          ),
          _c("div", { staticClass: "advice-new-btn secondary-type-btns" }, [
            _c(
              "button",
              {
                attrs: { type: "button" },
                on: { click: _vm.handleCreateAdvice }
              },
              [_vm._v(_vm._s(_vm.$t("page.patient.advice.newAdviceButton")))]
            )
          ])
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }