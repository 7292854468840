var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "MappingPatientList" }, [
    _c("h1", [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("table", [
      _vm._m(0),
      _vm._v(" "),
      _c("thead", [
        _c("tr", [
          _c("th", { staticClass: "align-left" }, [
            _vm._v(
              _vm._s(_vm.$t("components.mappingPatientList.list.header.name"))
            )
          ]),
          _vm._v(" "),
          _c("th", { staticClass: "align-left" }, [
            _vm._v(
              _vm._s(_vm.$t("components.mappingPatientList.list.header.email"))
            )
          ]),
          _vm._v(" "),
          _c("th", [
            _vm._v(
              _vm._s(_vm.$t("components.mappingPatientList.list.header.age"))
            )
          ]),
          _vm._v(" "),
          _c("th", [
            _vm._v(
              _vm._s(_vm.$t("components.mappingPatientList.list.header.gender"))
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "table-scroll custom-scrollbar" }, [
      _c("table", [
        _vm._m(1),
        _vm._v(" "),
        _vm.patientList.length > 0
          ? _c(
              "tbody",
              _vm._l(_vm.patientList, function(item) {
                return _c("tr", { key: item.id }, [
                  _c("td", { staticClass: "align-left" }, [
                    _vm._v(
                      _vm._s(
                        !item.name
                          ? "-"
                          : item.name.includes("null")
                          ? "-"
                          : item.name
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "align-left" }, [
                    _vm._v(_vm._s(item.email))
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.age))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.gender ? item.gender[0] : "-"))])
                ])
              }),
              0
            )
          : _c("tbody", [_vm._m(2)])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "popup-btns" }, [
      _c(
        "button",
        {
          staticClass: "close-btn icon-btn",
          attrs: { type: "button" },
          on: { click: _vm.handleClose }
        },
        [_vm._m(3)]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { attrs: { width: "130px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "130px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "60px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "60px" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { attrs: { width: "130px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "160px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "60px" } }),
      _vm._v(" "),
      _c("col", { attrs: { width: "60px" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { attrs: { colspan: "4" } }, [_vm._v("Nothing to display")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }