import request from "@/utils/request";

/*
therapist
*/

// 월별 이벤트리스트 받아오기
export function fetchMonthEvents(year, month, zoneOffset, clinicId) {
  const organization_id = clinicId;
  const zone_offset = zoneOffset;
  return request({
    url: `/therapist/api/v1/portal/schedule/therapist/${year}/${month}`,
    method: "get",
    params: {
      zone_offset,
      organization_id
    }
  });
}

// 해당 이벤트의 자세한내용 받아오기
export function fetchDetailEventInfo(id) {
  return request({
    url: `/therapist/api/v1/portal/schedule/${id}`,
    method: "get"
  });
}

// 신규 이벤트 생성하기
export function fetchCreateEvent(schedule) {
  return request({
    url: `/therapist/api/v1/portal/schedule`,
    data: schedule,
    method: "post"
  });
}

// 해당 이벤트를 수정하기
export function fetchEditEvent(id, schedule) {
  return request({
    url: `/therapist/api/v1/portal/schedule/${id}`,
    data: schedule,
    method: "put"
  });
}

// 해당 이벤트를 삭제하기
export function fetchDeleteEvent(id) {
  return request({
    url: `/therapist/api/v1/portal/schedule/${id}`,
    method: "delete"
  });
}

// 해당 이벤트의 요청을 승낙하기
export function fetchConfirmEvent(id) {
  return request({
    url: `/therapist/api/v1/portal/schedule/${id}/confirm`,
    method: "put"
  });
}

// 해당 이벤트의 요청을 거부하기
export function fetchDenyEvent(id, contents) {
  return request({
    url: `/therapist/api/v1/portal/schedule/${id}/deny`,
    method: "put",
    data: contents
  });
}

// 근무시간 갖고오기
export function fetchGetWorkinghours() {
  return request({
    url: `/therapist/api/v1/portal/workinghours`,
    method: "get"
  });
}

// 근무시간 설정하기
export function fetchSetWorkinghours(settings) {
  return request({
    url: `/therapist/api/v1/portal/workinghours`,
    method: "post",
    data: settings
  });
}

// 환자의 스케쥴 상에서의 Screen Sharing
export function fetchScreenSharingStartInSchedule(eventId) {
  return request({
    url: `/therapist/api/v1/portal/schedule/${eventId}/startscreensharing`,
    method: "put"
  });
}

// 환자의 스케쥴 상에서의 Screen Sharing 종료
export function fetchScreenSharingStopInSchedule(eventId) {
  return request({
    url: `/therapist/api/v1/portal/schedule/${eventId}/stopscreensharing`,
    method: "put"
  });
}

// 환자화면에서 Screen Sharing
export function fetchScreenSharingStart(patientId) {
  return request({
    url: `/therapist/api/v1/portal/patient/${patientId}/startscreensharing`,
    method: "get"
  });
}

// 환자의 스케쥴 상에서의 Video Call
export function fetchVideoCallStartInSchedule(eventId) {
  return request({
    url: `/therapist/api/v1/portal/schedule/${eventId}/startvideocall`,
    method: "put"
  });
}

// 환자의 스케쥴 상에서의 Video Call 방 나가기
export function fetchVideoCallStopInSchedule(eventId) {
  return request({
    url: `/therapist/api/v1/portal/schedule/${eventId}/stopvideocall`,
    method: "put"
  });
}

// 환자의 스케쥴 상에서의 Video Call 종료
export function fetchVideoCallEndInSchedule(eventId) {
  return request({
    url: `/therapist/api/v1/portal/schedule/${eventId}/endvideocall`,
    method: "put"
  });
}

// Video Call Record Start
export function fetchVideoCallRecordStart(eventId, data) {
  return request({
    url: `/therapist/api/v1/portal/schedule/${eventId}/startrecord`,
    method: "put",
    data
  });
}

// Video Call Record end
export function fetchVideoCallRecordEnd(eventId, data) {
  return request({
    url: `/therapist/api/v1/portal/schedule/${eventId}/endrecord`,
    method: "put",
    data
  });
}

// push history 가져오기
export function fetchGetPushHistory(page, size) {
  const service_name = "therapist";
  return request({
    url: `/push/api/v1/auth/history`,
    method: "get",
    params: {
      service_name,
      // page 시작이 0 부터 시작
      // 다른 api와 시작이 달라서 -1해줌
      page: page - 1,
      size
    }
  });
}

// push history 가져오기
export function fetchReadPushHistory(historyId) {
  return request({
    url: `/push/api/v1/auth/history/${historyId}`,
    method: "put",
    data: {
      is_read: true
    }
  });
}

// push history 가져오기
export function fetchReadAllPushHistory(historyId) {
  return request({
    url: `/push/api/v1/auth/history/read_all`,
    method: "put"
  });
}
