const getters = {
  token: state => {
    return state.user.token;
  },
  fcmToken: state => {
    return state.firebase.fcmToken;
  },
  avatar: state => {
    return state.user.avatar;
  },
  userLevel: state => {
    return state.user.level;
  },
  id: state => {
    return state.user.id;
  },
  name: state => {
    return state.user.name;
  },
  patients: state => {
    return state.user.patients;
  },
  selectedPatient: state => {
    return state.patient.selectedPatient;
  },
  selectedPatientDevice: state => {
    return state.patient.selectedPatientDevice;
  },
  hasNotification: state => {
    return state.notification.hasNewNoti;
  }
};
export default getters;
