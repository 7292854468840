<template>
  <section class="report-section  evaluation-score">
    <h1>{{ $t("page.patient.neofectHome.data.evaluationRecord") }}</h1>
    <div class="report-contents">
      <Loading v-if="loading" />
      <div class="select">
        <select v-model="leftSelected" class="select-1 select-type-2 left">
          <option
            v-for="ev in evalList"
            v-bind:value="ev.id"
            :key="ev.id"
          >[{{ ev.id }}] {{ ev.date }}</option>
        </select>
        <select v-model="rightSelected" class="select-2 select-type-2 left">
          <option
            v-for="ev in evalList"
            v-bind:value="ev.id"
            :key="ev.id"
          >[{{ ev.id }}] {{ ev.date }}</option>
        </select>
      </div>
      <div v-if="leftDetail && rightDetail">
        <EvaluationFreeExploration
          :left-id="leftSelected"
          :right-id="rightSelected"
          :left="leftDetail.freeExplore"
          :right="rightDetail.freeExplore"/>
        <EvaluationPointToPoint
          :left-id="leftSelected"
          :right-id="rightSelected"
          :left="leftDetail.targetAchieve"
          :right="rightDetail.targetAchieve"/>
        <EvaluationShapeDrawingClockwise
          :left-id="leftSelected"
          :right-id="rightSelected"
          :left="leftDetail.traceDraw"
          :right="rightDetail.traceDraw"/>
        <EvaluationShapeDrawingCounterClockwise
          :left-id="leftSelected"
          :right-id="rightSelected"
          :left="leftDetail.traceDraw"
          :right="rightDetail.traceDraw"/>
      </div>
      <div v-if="isNoData" class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
      <div v-if="error" class="no-data">Error!</div>
    </div>
  </section>
</template>

<script>
import EvaluationFreeExploration from "./EvaluationFreeExploration.vue";
import EvaluationPointToPoint from "./EvaluationPointToPoint.vue";
import EvaluationShapeDrawingClockwise from "./EvaluationShapeDrawingClockwise.vue";
import EvaluationShapeDrawingCounterClockwise from "./EvaluationShapeDrawingCounterClockwise.vue";
import Loading from "@/components/Loading";
import { getEvaluations, getBoardEvaluationDetail } from "@/api/report.js";
export default {
  name: "BoardEvaluation",
  components: {
    EvaluationFreeExploration, //자유 탐색
    EvaluationPointToPoint, // 목표 도달
    EvaluationShapeDrawingClockwise, // 궤적 그리기
    EvaluationShapeDrawingCounterClockwise, // 궤적 그리기
    Loading
  },
  data() {
    return {
      evalList: [],
      leftSelected: null,
      rightSelected: null,
      leftDetail: null,
      rightDetail: null,
      isNoData: false,
      loading: false,
      error: false
    };
  },
  props: {
    device: {
      type: Object
    }
  },
  computed: {
    patient: function() {
      return this.$store.getters.selectedPatient;
    }
  },
  watch: {
    device(newValue, oldValue) {
      this.error = false;
      this.isNoData = false;
      this.evalList = [];
      this.evaluationId = null;
      this.evaluationDetail = null;
      this.loadEvalList();
    },
    async leftSelected(newValue, oldValue) {
      if (newValue == null) this.leftDetail = null;
      else {
        let leftDetail;
        try {
          this.loading = true;
          leftDetail = await getBoardEvaluationDetail(newValue);
        } catch (error) {
          this.error = true;
          this.loading = false;
        }
        this.leftDetail = leftDetail;
        this.loading = false;
      }
    },
    async rightSelected(newValue, oldValue) {
      if (newValue == null) this.rightDetail = null;
      else {
        try {
          this.loading = true;
          const rightDetail = await getBoardEvaluationDetail(newValue);
          this.loading = false;
          this.rightDetail = rightDetail;
        } catch (error) {
          this.loading = false;
          this.error = true;
        }
      }
    }
  },
  mounted() {
    this.loadEvalList();
  },
  methods: {
    async loadEvalList() {
      if (!this.patient || !this.device) return;
      try {
        this.loading = true;
        const evalList = await getEvaluations(this.patient.id, this.device.id);
        this.loading = false;
        if (evalList.length > 0) {
          this.evalList = evalList.map(item => {
            if (item.date) {
              item.date = this.$moment(item.date).format("ll");
            }
            return item;
          });
          if (this.evalList.length > 0) {
            this.leftSelected = this.evalList[0].id;
            this.rightSelected = this.evalList[this.evalList.length - 1].id;
          }
        } else {
          this.isNoData = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.evaluation-information {
  min-height: 400px;
  position: relative;
  .no-data,
  .error {
    border: 1px solid #d0d4dc;
    border-radius: 8px;
    width: 100%;
    height: calc(400px - 54px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40px;
    left: 0;
    background: #ffffff;
    &.error {
      z-index: 2;
    }
  }
}
</style>
