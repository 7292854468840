var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "ForgotPassword" }, [
    !_vm.send
      ? _c("div", [
          _c("h1", [_vm._v(_vm._s(_vm.$t("page.forgotPassword.title")))]),
          _vm._v(" "),
          _c("div", { staticClass: "notice" }, [
            _vm._v(_vm._s(_vm.$t("page.forgotPassword.description")))
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              ref: "loginForm",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c("div", { ref: "email", staticClass: "email" }, [
                _c("label", { attrs: { for: "email" } }, [
                  _vm._v(_vm._s(_vm.$t("page.forgotPassword.email.title")))
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  attrs: { id: "email", placeholder: "Email", type: "text" },
                  domProps: { value: _vm.email },
                  on: {
                    keypress: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleSubmit($event)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  { ref: "emailErrorMessage", staticClass: "message" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("page.forgotPassword.email.error.invalidEmail")
                      )
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  ref: "submitBtn",
                  staticClass: "submit-btns",
                  class: _vm.submitDisable ? "disabled" : ""
                },
                [
                  _c(
                    "button",
                    {
                      attrs: { type: "button" },
                      on: { click: _vm.handleSubmit }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("page.forgotPassword.resetPasswordButton")
                        )
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ])
      : _c("div", [
          !_vm.resend
            ? _c("h1", [
                _vm._v(_vm._s(_vm.$t("page.forgotPassword.reset.sent.title")))
              ])
            : _c("h1", [
                _vm._v(_vm._s(_vm.$t("page.forgotPassword.reset.reSent.title")))
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "notice" }, [
            !_vm.resend
              ? _c(
                  "div",
                  _vm._l(
                    _vm.$t("page.forgotPassword.reset.sent.description"),
                    function(item) {
                      return _c(
                        "p",
                        {
                          key:
                            item +
                            _vm.$t("page.forgotPassword.reset.sent.description")
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    }
                  ),
                  0
                )
              : _c(
                  "div",
                  _vm._l(
                    _vm.$t("page.forgotPassword.reset.reSent.description"),
                    function(item) {
                      return _c(
                        "p",
                        {
                          key:
                            item +
                            _vm.$t(
                              "page.forgotPassword.reset.reSent.description"
                            )
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    }
                  ),
                  0
                ),
            _vm._v(" "),
            _c(
              "button",
              { attrs: { type: "button" }, on: { click: _vm.handleSubmit } },
              [_vm._v(_vm._s(_vm.$t("page.forgotPassword.reset.link")))]
            ),
            _vm._v(".\n    ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "primary-type-btns big-btns login-btn" },
            [
              _c("router-link", { attrs: { to: "/login" } }, [
                _vm._v(_vm._s(_vm.$t("page.forgotPassword.reset.logIn")))
              ])
            ],
            1
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }