var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "SessionDetail",
      style: { top: _vm.coordinates.y + "px", left: _vm.coordinates.x + "px" }
    },
    [
      _c("h1", [
        _vm._v(
          _vm._s(
            _vm.$t("page.patient.calendar.head.detail.type.homework.title")
          )
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "session-detail-date" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$moment(_vm.homeworkDetailInfo.date).format("MMMM D, YYYY")
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "session-message" },
        _vm._l(_vm.homeworkDetailInfo.messages.split("\n"), function(item) {
          return _c("p", [_vm._v(_vm._s(item))])
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "session-games" }, [
        _c(
          "ul",
          _vm._l(_vm.homeworkDetailInfo.missions, function(mission, index) {
            return _c("li", { key: index }, [
              _c(
                "span",
                {
                  staticClass: "icon-ic_check_circle_fill",
                  class: mission.completed ? "complete" : ""
                },
                [
                  _c("span", { staticClass: "path1" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "path2" })
                ]
              ),
              _vm._v(" "),
              mission.lastTrainedDate
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$moment(mission.lastTrainedDate).format("h:mm A")
                      ) + " "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "title" }, [
                _vm._v("\n          " + _vm._s(mission.title))
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "popup-btns" }, [
        _c(
          "button",
          {
            staticClass: "del-btn icon-btn",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.handleDelete()
              }
            }
          },
          [
            !_vm.overTime
              ? _c("span", { staticClass: "icon-ic_bin" }, [
                  _c("span", { staticClass: "path1" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "path2" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "path3" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "path4" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "path5" })
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        !_vm.overTime
          ? _c(
              "button",
              {
                staticClass: "edit-btn icon-btn",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.handleEdit(_vm.homeworkDetailInfo)
                  }
                }
              },
              [_vm._m(0)]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close-btn icon-btn",
            attrs: { type: "button" },
            on: { click: _vm.handleClose }
          },
          [_vm._m(1)]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_edit" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" }),
      _vm._v(" "),
      _c("span", { staticClass: "path3" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }