<template>
  <section class="ClinicMapping">
    <div class="mapping-test">
      <button
        :class="target === 'Stroke coach' ? 'on' : ''"
        @click="convertTap('Stroke coach')"
      >{{ $t("adminPage.management.mapping.tabTitle.providerPatient") }}</button>
      <button
        :class="target === 'patient' ? 'on' : ''"
        @click="convertTap('patient')"
      >{{ $t("adminPage.management.mapping.tabTitle.patientProvider") }}</button>
    </div>
    <div class="mapping-view">
      <MappingTherapist v-if="target === 'Stroke coach'" />
      <MappingPatient v-else />
    </div>
  </section>
</template>

<script>
import MappingPatient from "./components/MappingPatient";
import MappingTherapist from "./components/MappingTherapist";
export default {
  name: "ClinicMapping",
  components: {
    MappingPatient,
    MappingTherapist
  },
  data() {
    return {
      target: "Stroke coach"
    };
  },
  watch: {
    target: function(newVal, oldVal) {}
  },
  methods: {
    convertTap(to) {
      this.target = to;
    }
  }
};
</script>

<style lang='scss' scoped>
.ClinicMapping {
  .mapping-test {
    button {
      padding: 14px 28px;
      background-color: #edeff3;
      font-size: $fontSize6;
      color: $fontColor3;
      border: 1px solid $borderColor1;
      border-bottom: 0 none;
      border-radius: 2px 2px 0 0;
      &.on {
        color: $fontColor1;
        background: #ffffff;
      }
    }
  }
  .mapping-view {
    margin-top: -1px;
  }
}
</style>
