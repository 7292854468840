import { fetchGetDevices } from "@/api/patient";

const state = {
  selectedPatient: {},
  selectedPatientDevice: []
};

const mutations = {
  SET_PATIENT: (state, patient) => {
    state.selectedPatient = patient;
  },
  SET_DEVICE: (state, device) => {
    state.selectedPatientDevice = device;
  }
};

const actions = {
  // select patient
  selectPatient({ commit }, patient) {
    return new Promise(resolve => {
      commit("SET_PATIENT", patient);
      resolve(patient);
    });
  },
  setPatientDevice({ commit }, patientId) {
    return fetchGetDevices(patientId)
      .then(result => {
        const devices = result.map(device => {
          let name = device.name.replace("_", " ").toLowerCase();
          name = name.split(" ");
          name[0] = name[0].charAt(0).toUpperCase() + name[0].slice(1);
          name[1] = name[1].charAt(0).toUpperCase() + name[1].slice(1);
          name = name.join(" ");
          let side = device.side.toLowerCase();
          side = side.charAt(0).toUpperCase() + side.slice(1);
          device.printName = `${name}(${side})`;
          return device;
        });
        commit("SET_DEVICE", devices);
      })
      .catch(error => {
        return error.response;
      });
  }
};
const getters = {
  selectedPatient: state => {
    return state.selectedPatient;
  },
  selectedPatientDevice: state => {
    return state.selectedPatientDevice;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
