import request from "@/utils/request";

// 환자별 주별 이벤트리스트(스케쥴리스트) 받아오기
export function fetchWeekEvents(
  year,
  month,
  monday,
  patientId,
  zoneOffset,
  clinicId
) {
  const id = patientId;
  const zone_offset = zoneOffset;
  const organization_id = clinicId;
  return request({
    url: `/therapist/api/v1/portal/schedule/patient/${year}/${month}/${monday}`,
    method: "get",
    params: {
      id,
      zone_offset,
      organization_id
    }
  });
}

// 환자별 주별 세션목록 가져오기 (환자 스케쥴에서 사용됨)
export function fetchGetWeeklySessions(year, month, monday, patientId) {
  return request({
    url: `/therapist/api/v1/portal/training/patient/${year}/${month}/${monday}?id=${patientId}`,
    method: "get"
  });
}

// 환자별 월별 세션목록 가져오기 (세션 생성화면에서 필요함)
export function fetchGetSessions(year, month, patientId) {
  return request({
    url: `/therapist/api/v1/portal/training/patient/${year}/${month}?id=${patientId}`,
    method: "get"
  });
}

// 환자별 테라피스트 세션 세부내용 가져오기
export function fetchGetSessionDetail(sessionId) {
  return request({
    url: `/therapist/api/v1/portal/training/${sessionId}`,
    method: "get"
  });
}

// 환자별 테라피스트 세션 생성하기
export function fetchCreateSession(sessionList) {
  return request({
    url: `/therapist/api/v1/portal/training`,
    method: "post",
    data: sessionList
  });
}

// 환자별 테라피스트 세션 수정하기
export function fetchEditSession(sessionId, title, games) {
  return request({
    url: `/therapist/api/v1/portal/training/${sessionId}`,
    method: "put",
    data: {
      title,
      games
    }
  });
}

// 환자별 테라피스트 세션 삭제하기
export function fetchDeleteSession(sessionId) {
  return request({
    url: `/therapist/api/v1/portal/training/${sessionId}`,
    method: "delete"
  });
}

// 환자별 월별 Execrise 세션목록 가져오기 (세션 생성화면에서 필요함)
export function fetchGetExerciseSessions(year, month, patientId) {
  return [
    {
      id: 1,
      date: "2020-10-28T00:00:00",
      count: 5
    },
    {
      id: 2,
      date: "2020-10-29T00:00:00",
      count: 3
    }
  ];
}

// Advice 목록가져오기
export function fetchGetAdvice(patientId, size, page, clinicId) {
  const id = patientId;
  const organization_id = clinicId;
  return request({
    url: `/therapist/api/v1/portal/advice`,
    method: "get",
    params: {
      size,
      page,
      id,
      organization_id
    }
  });
}

// Advice 작성하기
export function fetchCreateAdvice(patient, content, clinicId) {
  const organization_id = clinicId;
  return request({
    url: `/therapist/api/v1/portal/advice`,
    method: "post",
    data: {
      patient,
      content,
      organization_id
    }
  });
}

// Memo 목록 가져오기
export function fetchGetMemo(patientId, size, page, clinicId) {
  return request({
    url: `/therapist/api/v1/portal/memo?id=${patientId}&size=${size}&page=${page}&organization_id=${clinicId}`,
    method: "get"
  });
}

// Memo 상세보기
export function fetchGetMemoDetail(memoId) {
  return request({
    url: `/therapist/api/v1/portal/memo/${memoId}`,
    method: "get"
  });
}

// Memo 작성하기
export function fetchCreateMemo(memo) {
  return request({
    url: `/therapist/api/v1/portal/memo`,
    method: "post",
    data: memo
  });
}

// Memo 수정하기
export function fetchEditMemo(memoId, memo) {
  return request({
    url: `/therapist/api/v1/portal/memo/${memoId}`,
    method: "put",
    data: memo
  });
}

// Memo 삭제하기
export function fetchDeleteMemo(memoId) {
  return request({
    url: `/therapist/api/v1/portal/memo/${memoId}`,
    method: "delete"
  });
}

// VideoCall Record Download
export function fetchGetVideoCallDownload(memoId) {
  return request({
    url: `/therapist/api/v1/portal/memo/${memoId}/videoArchive`,
    method: "get"
  });
}
// VideoCall Record Download
export const getVideoCallDownload = (memoId) => {
  return new Promise((resolve, reject) => {
    request
      .get(`/therapist/api/v1/portal/memo/${memoId}/videoArchive`, {
        responseType: "blob"
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 환자별 profile 정보 가져오기
export function fetchGetPatientInformation(patientId) {
  return request({
    url: `/user/api/v1/patients/${patientId}`,
    method: "get"
  });
}

// 환자별 기기목록 가져오기
export function fetchGetDevices(patientId) {
  return request({
    url: `/user/api/v1/patients/devices?userId=${patientId}`,
    method: "get"
  });
}

// 환자별 기기목록 game list 가져오기
export function fetchGetGames(device, side) {
  return request({
    url: `/home/api/v1/games/${device}/${side}`,
    method: "get"
  });
}

// 환자별 기기목록 game의 모션타입 가져오기
export function fetchGetMotionTypes(device) {
  return request({
    url: `/home/api/v1/motionTypes/${device}`,
    method: "get"
  });
}

// 환자 상세 정보 수정
export function fetchPutPatientInformation(patientId, payload) {
  return request({
    url: `/user/api/v1/patients/${patientId}`,
    method: "put",
    data: payload
  });
}
