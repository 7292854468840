var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "report-section session-log" }, [
    _c("h1", [
      _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.sessionLog")))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "report-contents custom-scrollbar" },
      [
        _vm.loading ? _c("Loading") : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.logsByDate, function(logs, index) {
          return _c("section", { key: index }, [
            _c("h2", [_vm._v(_vm._s(index))]),
            _vm._v(" "),
            _c(
              "ul",
              _vm._l(logs, function(log, index) {
                return _c("li", { key: index }, [
                  _c(
                    "span",
                    { staticClass: "title", class: { target: log.target } },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(log.sessionName) +
                          "\n            " +
                          _vm._s(log.contentName) +
                          " (" +
                          _vm._s(log.successCount) +
                          "/" +
                          _vm._s(log.targetSuccessCount) +
                          ")\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "tooltip" }, [
                    _c("span", { staticClass: "icon-ic_info_circle" }, [
                      _c("span", { staticClass: "path1" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "path2" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "notice-information" }, [
                        _vm._v(_vm._s(log.motionTypeDisplayName))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "score" }, [
                    _c(
                      "span",
                      {
                        staticClass: "icon-ic_star_fill",
                        class: { active: log.achieveLevel > 0 }
                      },
                      [_c("span", { staticClass: "path1" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "icon-ic_star_fill",
                        class: { active: log.achieveLevel > 1 }
                      },
                      [_c("span", { staticClass: "path1" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "icon-ic_star_fill",
                        class: { active: log.achieveLevel > 2 }
                      },
                      [_c("span", { staticClass: "path1" })]
                    )
                  ])
                ])
              }),
              0
            )
          ])
        }),
        _vm._v(" "),
        _vm.isNoData
          ? _c("div", { staticClass: "no-data" }, [
              _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.empty")))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "no-data" }, [_vm._v("Error!")])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }