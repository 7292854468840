<template>
  <section class="ResetPassword">
    <form ref="signupForm" @submit.prevent>
      <div class="contents">
        <h1>{{ $t("page.profile.resetPassword.title") }}</h1>
        <div class="password" :class="passwordWrong ? 'wrong' : ''">
          <label for="password">{{ $t("page.profile.resetPassword.current") }}</label>
          <input
            id="password"
            v-model="password"
            :placeholder="inputCurrentPlaceholder"
            :type="passwordType"
            autocomplete="off"
          >
          <button
            v-if="passwordType === 'text'"
            type="button"
            class="password-show"
            @click="passwordHidden('default')"
          >
            <img src="@/assets/imgs/ic-eye-hide.png" alt="eye-hide-image">
          </button>
          <button v-else type="button" class="password-show" @click="passwordShow('default')">
            <img src="@/assets/imgs/ic-eye-show.png" alt="eye-show-image">
          </button>
          <span class="message">{{ $t("page.profile.resetPassword.error.incorrect") }}</span>
        </div>
        <div class="new-password" :class="passwordCheck ? '' : 'wrong'">
          <label for="newPassword">{{ $t("page.profile.resetPassword.new") }}</label>
          <input
            id="newPassword"
            v-model="newPassword"
            :placeholder="inputNewPlaceholder"
            autocomplete="off"
            :type="newPasswordType"
          >
          <button
            v-if="newPasswordType === 'text'"
            type="button"
            class="password-show"
            @click="passwordHidden('new')"
          >
            <img src="@/assets/imgs/ic-eye-hide.png" alt="eye-hide-image">
          </button>
          <button v-else type="button" class="password-show" @click="passwordShow('new')">
            <img src="@/assets/imgs/ic-eye-show.png" alt="eye-show-image">
          </button>
          <span class="message">{{ newPasswordWrongMessage }}</span>
        </div>
        <div class="confirm-password" :class="confirmPasswordCheck ? '' : 'wrong'">
          <label for="confirmPassword">{{ $t("page.profile.resetPassword.confirm") }}</label>
          <input
            id="confirmPassword"
            v-model="confirmPassword"
            :placeholder="inputConfirmPlaceholder"
            autocomplete="off"
            :type="confirmPasswordType"
          >
          <button
            v-if="confirmPasswordType === 'text'"
            type="button"
            class="password-show"
            @click="passwordHidden('confirm')"
          >
            <img src="@/assets/imgs/ic-eye-hide.png" alt="eye-hide-image">
          </button>
          <button v-else type="button" class="password-show" @click="passwordShow('confirm')">
            <img src="@/assets/imgs/ic-eye-show.png" alt="eye-show-image">
          </button>
          <span class="message">{{ $t("page.profile.resetPassword.error.notMatch") }}</span>
        </div>
      </div>
      <div class="popup-submit-btns" :class="submitDisable ? 'disabled' : ''">
        <button type="button" class="accept-btn" @click="handleSubmit">{{ $t("page.profile.resetPassword.button.reset") }}</button>
        <button type="button" @click="handleClose">{{ $t("page.profile.resetPassword.button.cancel") }}</button>
      </div>
      <div class="popup-btns">
        <!-- 팝업 컨트롤 버튼 -->
        <button type="button" class="close-btn icon-btn" @click="handleClose">
          <span class="icon-ic_delete">
            <span class="path1" />
            <span class="path2" />
          </span>
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import { fetchUpdateTherapistPassword } from "@/api/user";
import Confirm from "@/components/Confirm";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
export default {
  name: "ResetPassword",
  mixins: [ErrorMixin],
  data() {
    return {
      password: "",
      passwordType: "password",
      newPassword: "",
      newPasswordWrongMessage: "Must be at least 10 characters includes both letters and number",
      newPasswordType: "password",
      confirmPassword: "",
      confirmPasswordType: "password",
      passwordWrong: false
    };
  },
  computed: {
    inputCurrentPlaceholder() {
      return this.$t(`page.profile.resetPassword.placeholder.current`);
    },
    inputNewPlaceholder() {
      return this.$t(`page.profile.resetPassword.placeholder.new`);
    },
    inputConfirmPlaceholder() {
      return this.$t(`page.profile.resetPassword.placeholder.confirm`);
    },
    passwordCheck: function() {
      return this.validatePassword(this.newPassword);
    },
    confirmPasswordCheck: function() {
      return this.newPassword === this.confirmPassword;
    },
    submitDisable: function() {
      return (
        this.password.length < 1 ||
        this.newPassword.length < 1 ||
        this.confirmPassword.length < 1 ||
        !this.passwordCheck ||
        !this.confirmPasswordCheck
      );
    }
  },
  watch: {
    password: function(newVal, oldVal) {
      if (this.passwordWrong) {
        this.passwordWrong = false;
      }
    }
  },
  methods: {
    passwordHidden(what) {
      if (what === "default") {
        this.passwordType = "password";
      } else if (what === "confirm") {
        this.confirmPasswordType = "password";
      } else {
        this.newPasswordType = "password";
      }
    },
    passwordShow(what) {
      if (what === "default") {
        this.passwordType = "text";
      } else if (what === "confirm") {
        this.confirmPasswordType = "text";
      } else {
        this.newPasswordType = "text";
      }
    },
    validatePassword(password) {
      if (!password) {
        return true;
      }

      const regExpContinueWord = /(\w)\1\1/;
      const regExpIsDigit = /[0-9]/g;
      const regExpIsUpperCase = /[A-Z]/g;
      const regExpIslowerCase = /[a-z]/g;
      const regExpIsSpecial = /[!@#$%^&*?]/g;

      let chkWordKind = 0;

      if (regExpIsDigit.test(password)) chkWordKind++;
      if (regExpIsUpperCase.test(password)) chkWordKind++;
      if (regExpIslowerCase.test(password)) chkWordKind++;
      if (regExpIsSpecial.test(password)) chkWordKind++;

      let resBoolean = true;
      // 길이가 10개 이하이거나 20개 이일 때 false
      if (password.length < 10 || password.length > 20) {
        this.newPasswordWrongMessage = this.$t("page.profile.resetPassword.error.length");
        resBoolean = false;
        // 문자의 길이가 3개이상 연속되면 안됨!!!
      } else if (regExpContinueWord.test(password)) {
        this.newPasswordWrongMessage = this.$t("page.profile.resetPassword.error.continuous");
        resBoolean = false;
      } else if (chkWordKind < 2) {
        this.newPasswordWrongMessage = this.$t("page.profile.resetPassword.error.twoTypes");
        resBoolean = false;
      }

      return resBoolean;
    },
    handleClose() {
      this.$emit("close");
    },
    handleSubmit() {
      if (!this.submitDisable) {
        const password = {
          password: this.password,
          newPassword: this.newPassword
        };
        fetchUpdateTherapistPassword(password)
          .then(result => {
            // console.log(result);
            this.handleClose();
            this.$modal.show(
              Confirm,
              {
                type: "change_save",
                confirm: {
                  title: this.$t("page.profile.resetPassword.saveModal.title"),
                  message:
                    this.$t("page.profile.resetPassword.saveModal.message")
                }
              },
              {
                height: "auto"
              }
            );
          })
          .catch(error => {
            if (error.data.code === "password.not.match") {
              this.passwordWrong = true;
            } else {
              this.handleError(error, "Stroke coach Reset Password");
            }
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ResetPassword {
  color: $fontColor1;
  font-size: $fontSize5;
  h1 {
    font-weight: 800;
    font-size: $fontSize1;
    letter-spacing: -0.4px;
    line-height: 45px;
  }
  label {
    font-size: $fontSize7;
  }
  input[type="text"] {
    margin-top: 8px;
  }
  .notice {
    margin-top: 45px;
    font-size: $fontSize5;
    color: $fontColor2;
    line-height: 24px;
    button {
      text-decoration: underline;
      font-size: $fontSize5;
    }
  }
  .contents {
    padding: 50px 24px 40px 24px;
  }
  .message {
    margin-top: 4px;
    display: none;
    color: $uiColor1;
    background: rgba(244, 67, 54, 0.25);
    padding: 4px 16px;
    font-size: $fontSize6;
  }
  .password {
    margin-top: 24px;
    position: relative;
    label {
      font-size: $fontSize7;
    }
    input[type="password"],
    input[type="text"] {
      margin-top: 8px;
    }
    button {
      img {
        width: 24px;
      }
    }
    &.wrong {
      input[type="password"],
      input[type="text"] {
        border-color: $uiColor1;
      }
      .message {
        display: inline-block;
      }
    }
    .password-show {
      position: absolute;
      top: 29px;
      right: 12px;
    }
  }
  .new-password {
    margin-top: 24px;
    position: relative;
    label {
      font-size: $fontSize7;
    }
    input[type="password"],
    input[type="text"] {
      margin-top: 8px;
    }
    button {
      img {
        width: 24px;
      }
    }
    &.wrong {
      input[type="password"],
      input[type="text"] {
        border-color: $uiColor1;
      }
      .message {
        display: inline-block;
      }
    }
    .password-show {
      position: absolute;
      top: 29px;
      right: 12px;
    }
  }
  .confirm-password {
    margin-top: 24px;
    position: relative;
    label {
      font-size: $fontSize7;
    }
    input[type="password"],
    input[type="text"] {
      margin-top: 8px;
    }
    button {
      img {
        width: 24px;
      }
    }
    &.wrong {
      input[type="password"],
      input[type="text"] {
        border-color: $uiColor1;
      }
      .message {
        display: inline-block;
      }
    }
    .password-show {
      position: absolute;
      top: 29px;
      right: 12px;
    }
  }
}
</style>
