<template>
  <section class="report-section training-time">
    <h1>{{ $t("page.patient.neofectHome.data.trainingTime") }}</h1>
    <div class="report-contents">
      <div class="graph">
        <Loading v-if="loading" />
        <v-chart
          ref="chartRef"
          :options="chartOptions"
          style="width:950px;height:250px;"
          :auto-resize="true"
        ></v-chart>
        <div v-if="isNoData" class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
        <div v-if="error" class="error">Error!</div>
      </div>
    </div>
  </section>
</template>

<script>
import { getTrainingTimes } from "@/api/report.js";
import option from "./TrainingTime.js";
import Loading from "@/components/Loading";
import moment from "moment";
export default {
  name: "TrainingTime",
  data() {
    return {
      chartOptions: option,
      isNoData: false,
      loading: false,
      error: false
    };
  },
  components: {
    Loading
  },
  props: {
    device: {
      type: Object
    },
    dateRange: {
      type: Object
    },
    toDateRange: {
      type: Object
    },
    isRangeCompare: Boolean
  },
  computed: {
    patient: function() {
      return this.$store.getters.selectedPatient;
    }
  },
  watch: {
    device(newValue, oldValue) {
      this.chartOptions = option;
      this.isNoData = false;
      this.error = false;
      this.drawChart();
    },
    dateRange(newValue, oldValue) {
      this.drawChart();
    },
    toDateRange(newValue, oldValue) {
      this.drawChart();
    },
    isRangeCompare(newValue, oldValue) {
      this.drawChart();
    }
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    getFullDateArray(startDate, endDate, format="YYYY-MM-DD") {
      let tempDate = this.$moment(startDate).format(format);
      endDate = this.$moment(endDate).format(format);

      const resDateArray = [];
      while (this.$moment(tempDate) <= this.$moment(endDate)) {
        resDateArray.push(tempDate);
        tempDate = this.$moment(tempDate).add(1, 'd').format(format);
      }
      return resDateArray;
    },
    async drawChart() {
      if (!this.patient || !this.device) return;
      let trainingTimes;
      let toTrainingTimes;
      try {
        this.loading = true;
        trainingTimes = await getTrainingTimes(this.patient.id, this.device.id,this.$moment(this.dateRange.startDate).toISOString(),this.$moment(this.dateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString());
        if (this.isRangeCompare) {
          toTrainingTimes = await getTrainingTimes(this.patient.id, this.device.id, this.$moment(this.toDateRange.startDate).toISOString(), this.$moment(this.toDateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString());
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
      this.loading = false;
      this.chartOptions.xAxis.axisLabel =
        {
          formatter: function(value, index) {
            let date = moment(value, 'YYYY-MM-DD')
            if (index === 0) {
              date = date.format("YYYY.M.D");
            }else{
              date = date.format("M.D");
            }
            return date;
          },
          textStyle: {
            color: "rgba(68, 74, 88, 0.4)",
            fontSize: 11,
            fontFamily: "Poppins"
          },
          dataZoom: [
            {
              type: "slider",
              start: 0,
              end: 100
            },
            {
              type: "inside",
              start: 0,
              end: 100
            }
          ]
        }

      // 기간 비교와 기간 비교 아닐 때를 구분 한다.
      if (this.isRangeCompare) {

        const isStandardListEmpty = trainingTimes.times.length < 1;
        const isTargetListEmpty = toTrainingTimes.times.length < 1;

        this.isNoData = isStandardListEmpty && isTargetListEmpty;

        if (this.isNoData) {
          return;
        }

        this.chartOptions.legend.data = ["min",`${this.$moment(this.dateRange.startDate).format('YYYY.MM.DD')}~${this.$moment(this.dateRange.endDate).format('YYYY.MM.DD')}`,
          `${this.$moment(this.toDateRange.startDate).format('YYYY.MM.DD')}~${this.$moment(this.toDateRange.endDate).format('YYYY.MM.DD')}`];
        // 각 날짜 별로 x를 만든다.
        // 총 3개의 경우의 수가 있다.
        // 1. 기준, 타겟 모두 있는 경우
        // 2. 기준만 있는 경우
        // 3. 타겟만 있는 경우
        if (!isStandardListEmpty && !isTargetListEmpty) {
          this.chartOptions.xAxis.axisLabel =
           {
            formatter: function(value, index) {
              return value;
            },
            textStyle: {
              color: "rgba(68, 74, 88, 0.4)",
                fontSize: 11,
                fontFamily: "Poppins"
            }
          }

          // x축 기준 date
          const standardFullDateArray = this.getFullDateArray(this.dateRange.startDate,this.dateRange.endDate);
          const targetFullDateArray = this.getFullDateArray(this.toDateRange.startDate,this.toDateRange.endDate);

          this.chartOptions.series[0].name = [];
          this.chartOptions.series[1].name = [];

          this.chartOptions.series[0].name.push(`${this.$moment(this.dateRange.startDate).format('YYYY.MM.DD')}~${this.$moment(this.dateRange.endDate).format('YYYY.MM.DD')}`)
          this.chartOptions.series[0].name.push(`${this.$moment(this.toDateRange.startDate).format('YYYY.MM.DD')}~${this.$moment(this.toDateRange.endDate).format('YYYY.MM.DD')}`)


          const targetIndexObjectList = toTrainingTimes.times.map(item=>{
            const dateIndex = targetFullDateArray.findIndex(fItem=> fItem === item.date);
            item.originDate = item.date;
            item.date = standardFullDateArray[dateIndex];
            return item;
          });

          let realDateList = trainingTimes.times
                                .concat(targetIndexObjectList)
                                .map(item=>item.date)
                                .sort();
          realDateList = realDateList.filter((item,index) => realDateList.indexOf(item) === index);

          const reducer = (accumulator, curr) => Number(accumulator) + Number(curr);

          this.chartOptions.xAxis.data = [this.chartOptions.series[0].name[0], this.chartOptions.series[0].name[1]];

          let trainingTimesArray = realDateList.map(date => {
            const item = trainingTimes.times.find(item=>item.date===date);
            if (item===undefined){
              return 0;
            }
            return ((item.content + item.fun) / 60).toFixed(0);
          });
          this.chartOptions.series[0].data = []
          this.chartOptions.series[0].data.push(trainingTimesArray.reduce(reducer))


          let targetIndexArray = realDateList.map(date => {
            const item = targetIndexObjectList.find(item=>item.date===date);
            if (item===undefined){
              return 0;
            }
            return ((item.content + item.fun) / 60).toFixed(0);
          });
          this.chartOptions.series[0].data.push({value: targetIndexArray.reduce(reducer),itemStyle : {color: "#f0734d"}})
          this.chartOptions.axisLabel = [this.chartOptions.series[0].name[0], this.chartOptions.series[0].name[1]]

          console.log(this.chartOptions.axisLabel)

        } else if (!isStandardListEmpty) {
          this.chartOptions.series[0].name = `${this.$moment(this.dateRange.startDate).format('YYYY.MM.DD')}~${this.$moment(this.dateRange.endDate).format('YYYY.MM.DD')}`
          this.chartOptions.series[1].name = `${this.$moment(this.toDateRange.startDate).format('YYYY.MM.DD')}~${this.$moment(this.toDateRange.endDate).format('YYYY.MM.DD')}`


          this.chartOptions.xAxis.data = trainingTimes.times.map(t=>t.date);
          this.chartOptions.series[0].data = trainingTimes.times.map(item => {
            return ((item.content + item.fun) / 60).toFixed(0);
          });
          this.chartOptions.series[1].data = trainingTimes.times.map(t=>{
            return 0;
          });

        } else {
          this.chartOptions.series[0].name = `${this.$moment(this.dateRange.startDate).format('YYYY.MM.DD')}~${this.$moment(this.dateRange.endDate).format('YYYY.MM.DD')}`
          this.chartOptions.series[1].name = `${this.$moment(this.toDateRange.startDate).format('YYYY.MM.DD')}~${this.$moment(this.toDateRange.endDate).format('YYYY.MM.DD')}`

          this.chartOptions.xAxis.data = toTrainingTimes.times.map(t=>t.date);
          this.chartOptions.series[0].data = toTrainingTimes.times.map(t=>{
            return 0;
          });
          this.chartOptions.series[1].data = toTrainingTimes.times.map(item => {
            return ((item.content + item.fun) / 60).toFixed(0);
          });
        }
      } else {
        this.isNoData = trainingTimes.times.length < 1;
        if (this.isNoData) {
          return;
        }
        this.chartOptions.legend.data = ["min"];
        this.chartOptions.xAxis.data = trainingTimes.times.map(t => t.date);
        this.chartOptions.series[0].data = trainingTimes.times.map(t => {
          return ((t.content + t.fun) / 60).toFixed(0);
        });
        this.chartOptions.series[1].data = [];
      }
      console.log("chartoption : ", this.chartOptions)


    }
  }
};
</script>
<style lang="scss" scoped>
.training-time {
  .graph {
    position: relative;
    .no-data,
    .error {
      width: 100%;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      &.error {
        z-index: 2;
      }
    }
  }
}
</style>
