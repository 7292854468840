var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "report-section training-time" }, [
    _c("h1", [
      _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.trainingTime")))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "report-contents" }, [
      _c(
        "div",
        { staticClass: "graph" },
        [
          _vm.loading ? _c("Loading") : _vm._e(),
          _vm._v(" "),
          _c("v-chart", {
            ref: "chartRef",
            staticStyle: { width: "950px", height: "250px" },
            attrs: { options: _vm.chartOptions, "auto-resize": true }
          }),
          _vm._v(" "),
          _vm.isNoData
            ? _c("div", { staticClass: "no-data" }, [
                _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.empty")))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.error
            ? _c("div", { staticClass: "error" }, [_vm._v("Error!")])
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }