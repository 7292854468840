<template>
  <section class="report-section vertical-horizontal-arom">
    <h1>{{ $t("page.patient.neofectHome.data.verticalHorizontalAROM") }}</h1>
    <div class="report-contents">
      <Loading v-if="loading" />
      <div class="report-btns">
        <button
          type="button"
          v-on:click="show = 'vertical'"
          :class="{ on: show === 'vertical' }"
        >Vertical</button>
        <button
          type="button"
          v-on:click="show = 'horizontal'"
          :class="{ on: show === 'horizontal' }"
        >Horizontal</button>
      </div>
      <div class="report-wrapper" v-if="aromRange">
        <div class="report-info" v-if="vertical && horizontal">
          <div class="report-value">
            <span class="value">{{ selected.lastValueRange.toFixed(0) }}cm</span>
            <div class="in-decrease" v-if="isRangeCompare && show === 'horizontal'" v-bind:class="{decrease:  selected.lastValueRange.toFixed(0) - targetHorizontalRange < 0, increase:  selected.lastValueRange.toFixed(0) - targetHorizontalRange > 0}">
              <span class="arrow" v-bind:class="{down:  selected.lastValueRange.toFixed(0) - targetHorizontalRange < 0, up:  selected.lastValueRange.toFixed(0) - targetHorizontalRange > 0}"></span>
              <span class="arrow-text">{{ removeNumberMinusSign( selected.lastValueRange.toFixed(0) -  targetHorizontalRange.toFixed(0)) }}</span>
            </div>
            <div class="in-decrease" v-if="isRangeCompare && show === 'vertical'" v-bind:class="{decrease:  selected.lastValueRange.toFixed(0) - targetVerticalRange < 0, increase:  selected.lastValueRange.toFixed(0) - targetVerticalRange > 0}">
              <span class="arrow" v-bind:class="{down:  selected.lastValueRange.toFixed(0) - targetVerticalRange < 0, up:  selected.lastValueRange.toFixed(0) - targetVerticalRange > 0}"></span>
              <span class="arrow-text">{{ removeNumberMinusSign( selected.lastValueRange.toFixed(0) -  targetVerticalRange.toFixed(0)) }}</span>
            </div>
          </div>
          <span class="label">
            {{
            $moment(selected.rangeData.last.date, "YYYY-MM-DD").format("MMM DD,YYYY")
            }}
          </span>
          <span class="value up">
            {{ selected.diff }}
            <span :class="selected.upOrDown">
              <span class="path1"></span>
              <span class="path2"></span>
            </span>
          </span>
          <span class="label">
            Compared to the first time
            <br />
            ({{ $moment(selected.rangeData.first.date, "YYYY-MM-DD").format("MMM DD,YYYY") }})
          </span>
        </div>
        <div v-else class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
        <div class="graph" v-if="horizontal" v-show="show === 'horizontal'">
          <v-stage
            :height="horizontal.dimensions.stageHeight"
            :width="horizontal.dimensions.stageWidth"
          >
            <v-layer>
              <v-rect
                :x="isNaN(horizontal.dimensions.range.x) ? 0 : horizontal.dimensions.range.x"
                :y="isNaN(horizontal.dimensions.range.y) ? 0 : horizontal.dimensions.range.y"
                :width="isNaN(horizontal.dimensions.range.width) ? 0: horizontal.dimensions.range.width"
                :height="isNaN(horizontal.dimensions.range.height) ? 0 : horizontal.dimensions.range.height"
                fill="rgba(90,173, 244,0.7)"
              ></v-rect>
              <v-text
                :text="horizontal.valueRange[0].toFixed(0)"
                :x="horizontal.dimensions.labels.start.x"
                :y="horizontal.dimensions.labels.start.y"
              ></v-text>
              <v-text
                :text="horizontal.valueRange[1] + ' cm'"
                :x="horizontal.dimensions.labels.end.x"
                :y="horizontal.dimensions.labels.end.y"
              ></v-text>

              <v-rect
                :x="horizontal.dimensions.background.x"
                :y="horizontal.dimensions.background.y"
                :width="horizontal.dimensions.background.width"
                :height="horizontal.dimensions.background.height"
                fill="rgba(90,173, 244,0.1)"
                stroke="#5aadf5"
                :strokeWidth="1"
              ></v-rect>

              <v-line
                v-for="(tick, index) in horizontal.dimensions.ticks"
                :key="index"
                :points="tick"
                stroke="#5aadf5"
                :strokeWidth="1"
                closed="false"
              ></v-line>
            </v-layer>
          </v-stage>
        </div>
        <div class="graph" v-if="vertical" v-show="show === 'vertical'">
          <v-stage
            :height="vertical.dimensions.stageHeight"
            :width="vertical.dimensions.stageWidth"
          >
            <v-layer>
              <v-rect
                :x="isNaN(vertical.dimensions.range.x) ? 0 : vertical.dimensions.range.x"
                :y="isNaN(vertical.dimensions.range.y) ? 0 : vertical.dimensions.range.y"
                :width="isNaN(vertical.dimensions.range.width) ? 0 : vertical.dimensions.range.width"
                :height="isNaN(vertical.dimensions.range.height) ? 0 : vertical.dimensions.range.height"
                fill="rgba(90,173, 244,0.7)"
              ></v-rect>
              <v-text
                :text="vertical.valueRange[0].toFixed(0)"
                :x="vertical.dimensions.labels.start.x"
                :y="vertical.dimensions.labels.start.y"
              ></v-text>
              <v-text
                :text="vertical.valueRange[1] + ' cm'"
                :x="vertical.dimensions.labels.end.x"
                :y="vertical.dimensions.labels.end.y"
              ></v-text>

              <v-rect
                :x="vertical.dimensions.background.x"
                :y="vertical.dimensions.background.y"
                :width="vertical.dimensions.background.width"
                :height="vertical.dimensions.background.height"
                fill="rgba(90,173, 244,0.1)"
                stroke="#5aadf5"
                :strokeWidth="1"
              ></v-rect>

              <v-line
                v-for="(tick, index) in vertical.dimensions.ticks"
                :key="index"
                :points="tick"
                stroke="#5aadf5"
                :strokeWidth="1"
                closed="false"
              ></v-line>
            </v-layer>
          </v-stage>
        </div>
        <div v-if="isNoData" class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
        <div v-if="error" class="no-data">Error!</div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from "@/components/Loading";
import { getBoardAromRange } from "@/api/report.js";
const canvasHeight = 200;
export default {
  name: "BoardArom",
  components: {
    Loading
  },
  data() {
    return {
      aromRange: null,
      show: "vertical",
      // horizontal: {
      //   rangeData: null,
      //   diff: null,
      //   upOrDown: null,
      //   lastValueRange: null,
      //   firstValueRange: null,
      //   valueRange: null,
      //   dimensions: null
      // },
      // vertical: {
      //   rangeData: null,
      //   diff: null,
      //   upOrDown: null,
      //   lastValueRange: null,
      //   firstValueRange: null,
      //   valueRange: null,
      //   dimensions: null
      // }
      horizontal: null,
      vertical: null,
      targetHorizontalRange: 0,
      targetVerticalRange: 0,
      isNoData: false,
      loading: false,
      error: false
    };
  },
  props: {
    device: {
      type: Object
    },
    dateRange: {
      type: Object
    },
    toDateRange: {
      type: Object
    },
    isRangeCompare: Boolean
  },
  computed: {
    patient: function() {
      return this.$store.getters.selectedPatient;
    },
    isValid: function() {
      return this.rangeData && this.rangeData.first && this.rangeData.last;
    },
    selected: function() {
      return this.show === "vertical" ? this.vertical : this.horizontal;
    }
  },
  watch: {
    device(newValue, oldValue) {
      this.error = false;
      this.isNoData = false;
      this.aromRange = null;
      this.horizontal = null;
      this.vertical = null;
      this.loadInitialDate();
    },
    dateRange(newValue, oldValue) {
      this.loadInitialDate();
    },
    toDateRange(newValue, oldVAlue) {
      this.loadInitialDate();
    },
    isRangeCompare(newValue, oldValue) {
      this.loadInitialDate();
    }
  },
  mounted() {
    this.loadInitialDate();
  },
  methods: {
    removeNumberMinusSign: function (number) {
      if (number < 0) {
        return `${(number * -1).toFixed(0)}cm`;
      } else if (number === 0) {
        return '-';
      }
      return `${number.toFixed(0)}cm`;
    },
    async loadInitialDate() {
      if (!this.patient || !this.device) return;
      let aromRange;
      let targetAromRange;
      try {
        this.loading = true;
        aromRange = await getBoardAromRange(this.patient.id, this.device.id, this.$moment(this.dateRange.startDate).toISOString(), this.$moment(this.dateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString());
        if (this.isRangeCompare) {
          targetAromRange = await getBoardAromRange(this.patient.id, this.device.id, this.$moment(this.toDateRange.startDate).toISOString(), this.$moment(this.toDateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString());
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }

      this.aromRange = aromRange;

      if (aromRange.horizontal === null && aromRange.vertical === null) {
        this.isNoData = true;
        this.loading = false;
        return;
      }

      if (this.isRangeCompare) {
        if (!aromRange.horizontal === null) {
          this.targetHorizontalRange = (targetAromRange['horizontal'].last.max - targetAromRange['horizontal'].last.min) * targetAromRange['horizontal'].boardSize;
        }
        if (!aromRange.vertical === null) {
          this.targetVerticalRange = (targetAromRange['vertical'].last.max - targetAromRange['vertical'].last.min) * targetAromRange['vertical'].boardSize;
        }
      }

      let horizontal = this.drawChart("horizontal");
      let vertical = this.drawChart("vertical");
      this.horizontal = horizontal ? horizontal : this.horizontal;
      this.vertical = vertical ? vertical : this.vertical;
      this.loading = false;
    },
    drawChart(show) {
      if (!this.patient || !this.device) return;
      let data = this[show];
      let rangeData = this.aromRange[show];
      if (!rangeData) return;
      let boardSize = rangeData.boardSize;
      let firstDiff = rangeData.first.max - rangeData.first.min;
      let lastDiff = rangeData.last.max - rangeData.last.min;
      data = data === null ? {} : data;
      data.lastValueRange = lastDiff * boardSize;
      data.firstValueRange = firstDiff * boardSize;
      data.diff =
        Math.abs(data.lastValueRange - data.firstValueRange).toFixed() + " cm";
      if (data.lastValueRange > data.firstValueRange) {
        data.upOrDown = "icon-ic_ascending_sort";
      } else if (data.firstValueRange > data.lastValueRange) {
        data.upOrDown = "icon-ic_descending_sort";
      }
      data.valueRange = [0, boardSize];
      data.dimensions = this.calculateDimensions(
        canvasHeight,
        show,
        rangeData.last
      );
      data.rangeData = rangeData;
      return data;
    },
    calculateDimensions(height, mode, data) {
      let stageHeight = height * 0.5;
      let circleRadius = (stageHeight * (100.0 / 55.0)) / 2.0;

      let stageWidth = circleRadius * 2;
      stageHeight = circleRadius * 1.3;
      let defaultShortDimension = 18;

      let x = stageWidth / 2.0;
      let y = stageHeight / 2.0;
      let rectWidth = defaultShortDimension;
      let rectHeight = defaultShortDimension;

      let ticks = [];
      let range = {};
      let labels = {};

      if (mode == "horizontal") {
        x = 0;
        rectWidth = stageWidth - 10;
        let yStart = y + 3;
        let yEnd = y + (rectHeight / 2.0 - 3.0) * 2;

        for (let i = 1; i < 10; i++) {
          let tickX = x + (rectWidth / 10.0) * i;

          ticks.push([tickX, yStart, tickX, yEnd]);
        }
        let rangeX = x + data.min * rectWidth;
        let rangeWidth = (data.max - data.min) * rectWidth;

        range = {
          x: rangeX,
          y: y,
          width: rangeWidth,
          height: rectHeight
        };

        labels = {
          start: {
            x,
            y: y + rectHeight + 10
          },
          end: {
            x: x + rectWidth - 40,
            y: y + rectHeight + 10
          }
        };
      } else if (mode == "vertical") {
        y = 20;
        rectHeight = stageHeight - 20;

        let xStart = x + 3;
        let xEnd = x + (rectWidth / 2.0 - 3.0) * 2;

        for (let i = 1; i < 10; i++) {
          let tickY = y + (rectHeight / 10.0) * i;

          ticks.push([xStart, tickY, xEnd, tickY]);
        }

        let rangeY = y + data[0] * rectHeight;
        let rangeHeight = (data[1] - data[0]) * rectHeight;

        range = {
          x,
          y: rangeY,
          width: rectWidth,
          height: rangeHeight
        };

        labels = {
          start: {
            x: x + rectWidth + 10,
            y
          },
          end: {
            x: x + rectWidth + 10,
            y: y + rectHeight - 10
          }
        };
      }

      let background = {
        x,
        y,
        width: rectWidth,
        height: rectHeight
      };

      return {
        stageHeight,
        stageWidth,
        background,
        ticks,
        range,
        labels
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.vertical-horizontal-arom {
  .report-contents {
    position: relative;
    overflow: hidden;
    .report-wrapper {
      height: calc(100% - 57px);
    }
    .no-data,
    .error {
      width: 100%;
      height: 260px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      &.error {
        z-index: 2;
      }
    }
  }
}
</style>
