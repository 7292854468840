<template>
  <div class="ClinicMenu">
    <p class="home-btn">
      <span v-if="clinic.id === -1">
        <img src="@/assets/imgs/neofect.png" :alt="`${clinic.name} symbol`">
      </span>
      <span v-else>
        <img src="@/assets/imgs/rehabcare.png" :alt="`${clinic.name} symbol`">
      </span>
      <span>{{ clinic.name }}</span>
    </p>
    <dl class="clinic-menu">
      <dt>{{ $t("components.clinicMenu.request") }}</dt>
      <dd>
        <router-link to="/clinic/request/patient">{{ $t("components.clinicMenu.patient") }}</router-link>
      </dd>
      <dd>
        <router-link to="/clinic/request/therapist">{{ $t("components.clinicMenu.provider") }}</router-link>
      </dd>
      <dt>{{ $t("components.clinicMenu.management") }}</dt>
      <dd>
        <router-link to="/clinic/management/patient">{{ $t("components.clinicMenu.patientList") }}</router-link>
      </dd>
      <dd>
        <router-link
          to="/clinic/management/therapist"
        >{{ $t("components.clinicMenu.providerList") }}</router-link>
      </dd>
      <dd>
        <router-link to="/clinic/mapping">{{ $t("components.clinicMenu.mapping") }}</router-link>
      </dd>
      <dt v-if="level.includes('Owner')">{{ $t("components.clinicMenu.setting") }}</dt>
      <dd v-if="level.includes('Owner')">
        <router-link to="/clinic/roleassign">{{ $t("components.clinicMenu.roleAssign") }}</router-link>
      </dd>
    </dl>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ClinicMenu",
  computed: {
    symbol() {
      return require("@/assets/svgs/symbol-neofect-white.svg");
    },
    ...mapGetters({
      clinic: "user/clinic",
      level: "user/level"
    })
  }
};
</script>
<style lang="scss" scoped>
.ClinicMenu {
  width: 216px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid $borderColor1;
  background: #836855;
  z-index: 1;
  .home-btn {
    margin-top: 24px;
    padding: 0 24px;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #ffffff;
    font-size: $fontSize5;
    box-sizing: border-box;
    img {
      width: auto;
      height: 40px;
    }
    span:last-of-type {
      margin-left: 16px;
      line-height: 20px;
    }
  }
  .clinic-menu {
    margin-top: 32px;
    dt {
      padding: 12px 16px;
      margin-top: 16px;
      color: #cdc3bb;
      font-size: $fontSize7;
      line-height: 16px;
      &:first-of-type {
        margin-top: 0;
      }
    }
    dd {
      padding: 0 12px;
      a {
        display: block;
        padding: 10px 12px;
        text-decoration: none;
        color: #ffffff;
        font-size: $fontSize6;
        line-height: 24px;
        &.router-link-exact-active {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 4px;
        }
      }
    }
  }

  .nav-btn {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    text-decoration: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    &.router-link-active {
      background: rgba(0, 0, 0, 0.2);
      opacity: 1;
    }
    &.nav-calendar {
      margin-top: 40px;
    }
    &.nav-patients {
      margin-top: 30px;
    }
  }
}
</style>
