var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "MissionDetail",
      style: { top: _vm.coordinates.y + "px", left: _vm.coordinates.x + "px" }
    },
    [
      _c("h1", [
        _vm._v(
          _vm._s(
            _vm.$t("page.patient.calendar.head.detail.type.dailyMission.title")
          )
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mission-detail-date" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$moment(_vm.missionDetailInfo.date).format("MMMM D, YYYY")
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mission-games" }, [
        _c(
          "ul",
          _vm._l(_vm.missionDetailInfo.missions, function(mission, index) {
            return _c("li", { key: index }, [
              _c(
                "span",
                {
                  staticClass: "icon-ic_check_circle_fill",
                  class: mission.completed ? "complete" : ""
                },
                [
                  _c("span", { staticClass: "path1" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "path2" })
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "title" }, [
                _c("span", { staticStyle: { "font-weight": "600" } }, [
                  _vm._v(_vm._s(_vm._f("missionTypeFilter")(mission.type)))
                ]),
                _vm._v("\n          " + _vm._s(mission.title))
              ])
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "popup-btns" }, [
        _c(
          "button",
          {
            staticClass: "close-btn icon-btn",
            attrs: { type: "button" },
            on: { click: _vm.handleClose }
          },
          [_vm._m(0)]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }