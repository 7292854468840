var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slider-wrap" },
    [
      _c("vue-slider", {
        attrs: {
          direction: "btt",
          height: "230px",
          marks: false,
          max: _vm.max,
          min: _vm.min,
          tooltip: "none",
          "data-value": "value",
          "data-label": "label",
          data: _vm.options.data
        },
        model: {
          value: _vm.options.obj[_vm.key],
          callback: function($$v) {
            _vm.$set(_vm.options.obj, _vm.key, $$v)
          },
          expression: "options.obj[key]"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }