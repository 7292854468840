<template>
  <section class="ClinicManagement">
    <ManagementPatient v-if="target === 'patient'" />
    <ManagementTherapist v-else />
  </section>
</template>

<script>
import ManagementPatient from "./components/ManagementPatient";
import ManagementTherapist from "./components/ManagementTherapist";
export default {
  name: "ClinicManagement",
  components: {
    ManagementPatient,
    ManagementTherapist
  },
  computed: {
    target: function() {
      return this.$route.params.target;
    }
  }
};
</script>
