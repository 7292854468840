export default {
  common: {
    modal: {
      button: {
        close: "Close",
        cancel: "Cancel",
        delete: "Delete",
        remove: "Remove",
        register: "Register"
      }
    },
    role: {
      provider: "Provider",
      admin: "Admin",
      owner: "Owner",
      mainOwner: "Main Owner"
    },
    nation: {
      america: "United States of America",
      korea: "Republic of Korea"
    },
    license: {
      input: "Please select the States license you have."
    },
    chat: {
      searchPatientPlaceholder: "Search Patient",
      send: "Send",
      sending: "Sending..."
    },
    eventModal: {
      title: "Are you sure you want to register this event?",
      message:
        "Notification will be sent to patients with the information below. Please check all entered information before registering event.",
      boxLabel: {
        typeOfEvent: "Type of event",
        time: "Time",
        attendees: "Attendees",
        message: "Message"
      },
      type: {
        videoCall: "Video Call",
        screenSharing: "Screen Sharing",
        providerLive: "Provider Live"
      }
    },
    validation: {
      clinicCodeRequired: "Clinic Code is required.",
      clinicCodeWrong:
        "Clinic code must be entered in capital letters and digit.",
      clinicCodeLength: "Clinic Code length must be 6"
    }
  },
  page: {
    login: {
      logIn: "Log in",
      email: "Email",
      password: "Password",
      logInButton: "Log in",
      forgotPassword: "Forgot Password",
      willAccount: "Don't have an account?"
    },
    signUp: {
      title: "Sign up",
      description:
        "Email verification required. Only healthcare providers can sign up for Connect Portal.",
      emailLabel: "Email",
      emailError: {
        format: "Invalid email address",
        already: "This email is already in use"
      },
      sendButton: "Send Verification Link",
      logInLabel: "Log in",
      email: {
        title: "Verification Email Sent",
        description: [
          "Verification email has been sent to the email address you has submitted.",
          "Click the link in the email to verify your email address.",
          "If you didn't receive the verification email, please check you spam folder or"
        ],
        homeButton: "Go Home",
        reSend: "resend email"
      },
      reEmail: {
        title: "Verification Email Resent",
        description: [
          "Verification email resent.",
          "Click the link in the email to verify your email address.",
          "If you didn't receive the verification email, please check your spam folder or"
        ],
        reSend: "resend email"
      },
      inputInfo: {
        title: "Sign up",
        name: {
          title: "Full Name",
          placeholder: "Enter your full name"
        },
        country: {
          title: "Country",
          placeholder: "Select Country",
          errorMessage: "Sorry, no matching country."
        },
        states: {
          title: "States",
          tooltip: "Select the state in which you have obtained your license."
        },
        gender: {
          title: "Gender",
          male: "male",
          female: "female"
        },
        birth: {
          title: "Date of Birth"
        },
        mobilePhone: {
          title: "Mobile Phone",
          placeholder: "Enter numbers only"
        },
        providerType: {
          title: "Type of Provider",
          OT: "OT",
          PT: "PT",
          Others: "Others"
        },
        workPlace: {
          title: "Work Place",
          Neofect: "neofect",
          OtherClinic: "Other Clinic",
          placeholder: "Enter the Clinic Code"
        },
        email: {
          title: "Email"
        },
        password: {
          title: "Password",
          placeholder: "Enter the password",
          error: {
            length: "The password should be 10-20 characters.",
            continuous:
              "The password should be non-continuous characters or numbers.",
            twoTypes:
              "The password should contain at least two types of : Capital letters, Lower case letters, Numbers, and Special characters."
          }
        },
        rePassword: {
          title: "Confirm Password",
          placeholder: "Enter the password again",
          error: {
            notMatch: "Password doesn't match"
          }
        },
        signUpButton: "Sign up"
      },
      signUpDone: {
        title: "Hello",
        description: "Welcome to Neofect Portal.",
        logIn: "Log in"
      }
    },
    forgotPassword: {
      title: "Forgot Password",
      description:
        "Enter your ID (Email) to receive the link for password reset.",
      email: {
        title: "Email",
        error: {
          exist: "Account doesn't exist",
          invalidEmail: "Invalid email address"
        }
      },
      resetPasswordButton: "Reset Password",
      reset: {
        sent: {
          title: "Password Reset Email Sent",
          description: [
            "A password reset link has been sent to you email.",
            "Click the link in your email to reset your accont password.",
            "",
            "If you didn't receive the email, please check your spam folder of "
          ]
        },
        reSent: {
          title: "Password Reset Email Resent",
          description: [
            "A password reset link resent.",
            "Click the link in your email to reset your accont password.",
            "",
            "If you didn't receive the email, please check your spam folder of "
          ]
        },
        link: "resend email",
        logIn: "Log in"
      }
    },
    calendar: {
      setting: {
        title: "Setting Working Hours",
        workingHours: "Working hours",
        lunchBreak: "Lunch break",
        save: "Save"
      },
      notifications: {
        title: "Notifications",
        readAllButton: "Read all"
      },
      topMenu: {
        moveToAdmin: "Move to Admin",
        logOut: "Log out"
      },
      createEvent: {
        type: {
          videoCall: "Video Call",
          screenSharing: "Screen Sharing",
          providerLive: "Provider Live",
          general: {
            title: "General",
            allDay: "All day",
            daily: "Daily"
          }
        },
        placeholder: {
          patient: "Add Attendee",
          message: "Enter Message (Optional)",
          description: "Enter Description",
          memo: "Enter Memo (Optional)",
          topic: "Enter Topic",
          title: "Enter Title"
        },
        saveButton: "Save",
        validation: {
          requiredFields: "Fill in the required fields.",
          requiredFieldsLong:
            "Fill in all the required field to register this event.",
          choosePatient: "Please choose the patient.",
          noPatient: "Sorry, no matching patient.",
          inviteByEmail: "Would you like to invite by email?"
        },
        closeModal: {
          title: "Close Window",
          description:
            "Are you sure you want to close the window? Any changes will not be saved."
        }
      },
      eventDetail: {
        type: {
          videoCall: "Video Call",
          screenSharing: "Screen Sharing",
          providerLive: "Provider Live"
        },
        attendee: "Attendee",
        copyLink: "Copy Link",
        button: {
          videoCall: "Start Video Call",
          screenSharing: "Start Screen Sharing"
        },
        modal: {
          title: "Delete Event",
          description: "Are you sure you want to delete this event?"
        }
      },
      sideBar: {
        title: "Assigned Patients",
        search: "Search Patient",
        selectAll: "Select All",
        typeOfEvent: "Type of Event",
        type: {
          videoCall: "Video Call",
          videoCallRequest: "Video Call Request",
          screenSharing: "Screen Sharing",
          providerLive: "Provider Live",
          general: "General"
        }
      },
      index: {
        type: {
          videoCall: "Video Call",
          videoCallRequest: "Video Call Request",
          screenSharing: "Screen Sharing",
          providerLive: "Provider Live"
        }
      }
    },
    patient: {
      layout: {
        searchPlaceholder: "Search Patient",
        gender: {
          male: "Male",
          female: "Female"
        },
        button: {
          createSession: "Assign Homework",
          textChat: "Text Chat",
          screenSharing: "Screen Sharing"
        },
        menu: {
          profile: "Profile",
          neofectHome: "Home Device Results",
          homework: "Homework Record",
          calandar: "Patient Calendar",
          advice: "Advice",
          Documentation: "Documentation"
        }
      },
      profile: {
        basicInformation: {
          title: "Basic Information",
          age: "Age",
          dateOfBirth: "Date of Birth",
          gender: "Gender",
          state: "State",
          email: "Email",
          subscriptionPlan: "Subscription Plan",
          videoCall: "Video Call"
        },
        diagnosis: {
          title: "Diagnosis",
          nameOfDisease: "Diagnosis",
          affectedSide: "Affected Side",
          dateOfOccurance: "Date of Occurence"
        },
        upperExtremityLevel: {
          title: "Upper Extremity Level"
        }
      },
      neofectHome: {
        layout: {
          trainingReport: "Training Report",
          evaluation: "Evaluation",
          compare: "Compare",
          previousPeriodButton: "Previous period",
          target: "Target",
          allPeriod: "All Period"
        },
        summary: {
          assignedSessions: "Assigned Sessions",
          completedSessions: "Completed sessions",
          daysTrained: "Days trained",
          averageTraining: "Average training time per session",
          totalTrainingTime: "Total training time",
          activityTolerance: "Activity tolerance"
        },
        data: {
          trainingTime: "Training Time",
          sessionLog: "Session Log",
          movementTargeted: "Movement Targeted",
          trainingType: "Training Type",
          rangeOfMotion: "Available Range of Motion (ROM)",
          evaluationRecord: "Evaluation Record",
          totalDistanceMoved: "Total Distance Moved",
          areaAROM: "Area AROM",
          verticalHorizontalAROM: "Vertical / Horizontal AROM",
          smoothnessOfReach: "Smoothness of Reach",
          shapeSimilarity: "Shape Similarity",
          empty: "No Result"
        }
      },
      advice: {
        newAdviceButton: "New Advice",
        noAdviceYet: "No Advice yet.",
        list: {
          title: "Advice"
        },
        modal: {
          title: "Advice",
          contentsPlaceholer: "Enter Advice",
          RegisterButton: "Register"
        }
      },
      documentation: {
        newDocumentationButton: "New Documentation",
        noDocumentationYet: "No Documentation Yet.",
        modal: {
          type: {
            memo: "Memo",
            videoCall: "Video Call",
            coachingSession: "Coaching Session"
          },
          placeholder: {
            title: "Enter Title",
            memo: "Enter Memo"
          },
          registerButton: "Register",
          saveButton: "Save"
        },
        list: {
          type: {
            memo: "Memo",
            videoCall: "Video Call",
            coachingSession: "Coaching Session",
            videoCallRecord: "Video Call Record"
          }
        },
        deleteModal: {
          title: "Delete Documentation",
          description: "Are you sure you want to delete this documentation?"
        }
      },
      calendar: {
        head: {
          dailyMission: "Daily Mission",
          strokeCoachSession: "Smart Rehab Homework",
          trainings: "trainings",
          exerciseHomework: "Exercise Homework",
          exercises: "Exercises",
          detail: {
            type: {
              dailyMission: {
                title: "Daily Mission"
              },
              strokeCoachSession: {
                title: "Smart Rehab Homework",
                deleteModal: {
                  title: "Delete session",
                  message:
                    "Are you sure you want to delete this Smart Rehab Homework?"
                }
              },
              homework: {
                title: "Exercise Homework",
                deleteModal: {
                  title: "Delete homework",
                  message:
                    "Are you sure you want to delete this Exercise Homework?"
                }
              }
            }
          }
        }
      },
      session: {
        create: {
          title: "Homework for ",
          selectDate: "Select Date",
          setSession: "Set Session",
          noSession: [
            "No session created yet.",
            "Select date to create session."
          ],
          setExerciseHomework: "Set Exercise Homework",
          noExerciseHomework: [
            "No created homework yet.",
            "Select date to assign homework."
          ]
        },
        saveButton: "Save",
        cancelButton: "Cancel",
        saveModal: {
          title: "Homework Created"
        },
        tab: {
          smartRehab: {
            title: "Smart Rehab"
          },
          exercise: {
            title: "Exercise"
          }
        },
        detail: {
          title: "Session on",
          selectDevice: "Select Device",
          select: "Select",
          autoCreateButton: "Auto Create",
          defaultMotion: "All movement"
        },
        exerciseDetail: {
          title: "Exercise on",
          defaultLevel: "All level",
          message: "Message",
          messageDefault:
            "These exercises would be very helpful for your rehab. Try your best to accomplish the homework!",
          messageDescription:
            "Enter the message or explanation of the session.",
          select: "Select",
          detail: "Detail",
          recurrence: "Recurrence",
          doesnotrepeat: "Does not repeat",
          setting: "Setting",
          complete: "Complete",
          sets: "Sets",
          cancel: "Cancel"
        },
        rightList: {
          smartRehab: {
            title: "Session on",
            description: "Trainings"
          },
          exercise: {
            title: "Exercise on",
            description: "Exercises"
          }
        },
        recurrence: {
          homeworkRecurrence: "Homework Recurrence",
          doesNotRepeat: "Does not repeat",
          repeat: "Repeat",
          every: "Every",
          days: "Days",
          ends: "Ends",
          after: "After",
          occurences: "Occurences",
          save: "Save",
          week: "Week",
          repeatOn: "Repeat on",
          mon: "Mon",
          tue: "Tue",
          wed: "Wed",
          thu: "Thu",
          fri: "Fri",
          sat: "Sat",
          sun: "Sun"
        }
      },
      homework: {
        title: "Exercise",
        selectOption: {
          asc: "Oldest First",
          desc: "Newest First"
        },
        table: {
          header: {
            date: "Date",
            exercises: "Exercises",
            completion: "Completion",
            time: "Time"
          },
          contents: {
            empty: "No history"
          }
        }
      }
    },
    profile: {
      title: "Profile",
      editName: {
        edit: "Edit",
        editName: "Edit your name",
        name: "Full Name",
        save: "Save",
        cancel: "Cancel",
        placeholder: "Enter your full name"
      },
      country: "Country",
      states: {
        title: "States",
        tooltip: "Select the state in which you have obtained your license."
      },
      gender: {
        title: "Gender",
        male: "male",
        female: "female"
      },
      birth: {
        title: "Date of Birth"
      },
      mobilePhone: {
        title: "Mobile Phone",
        placeholder: "Enter numbers only"
      },
      providerType: {
        title: "Type of Provider",
        OT: "OT",
        PT: "PT",
        Others: "Others"
      },
      workPlace: {
        title: "Work Place",
        Neofect: "neofect",
        OtherClinic: "Other Clinic",
        placeholder: "Enter the Clinic Code"
      },
      introduce: {
        title: "introduce yourself",
        placeholder:
          "Text your introduction...(Within 100 characters, within 3 lines)"
      },
      resetPassword: {
        title: "Reset Password",
        codeTitle: "Please enter your new password.",
        current: "Current Password",
        new: "New Password",
        confirm: "Confirm Password",
        placeholder: {
          current: "Password",
          new: "Enter the password",
          confirm: "Enter the password again"
        },
        button: {
          reset: "Reset Password",
          cancel: "Cancel",
          save: "Save"
        },
        error: {
          incorrect: "Incorrect Password",
          notMatch: "Password doesn't match",
          length: "The password should be 10-20 characters.",
          continuous:
            "The password should be non-continuous characters or numbers.",
          twoTypes:
            "The password should contain at least two types of : Capital letters, Lower case letters, Numbers, and Special characters."
        },
        saveModal: {
          title: "Password Reset Completed",
          message:
            "Password has been reset successfully. Now you can login with the new password."
        },
        link: {
          logIn: "Log in"
        }
      },
      saveModal: {
        title: "Changes Saved",
        message: "Changes of your information have been saved."
      }
    },
    changeClinic: {
      title: "Choose your clinic",
      description: "Choose the clinic you are working for.",
      button: "Submit"
    },
    videoCall: {
      memo: {
        sessionrecords: "Session Records",
        videosession: "Video Session",
        noteforthepatient: "Note for the patient"
      },
      remoteTraining: {
        rangeofmotion: "Available Range of Motion (ROM)",
        playtime: "Play Time",
        holdtime: "Hold Time"
      }
    }
  },
  adminPage: {
    request: {
      patient: {
        button: {
          approve: "Approve Selected",
          deny: "Deny Selected"
        },
        selectOption: {
          all: "All",
          name: "Name",
          email: "Email"
        },
        list: {
          header: {
            name: "Name",
            email: "Email",
            age: "Age",
            gender: "Gender",
            requestedTime: "Requested Time",
            status: "Status"
          },
          statusType: {
            requested: "Requested",
            approved: "Approved",
            denied: "Denied",
            cancel: "Canceled"
          },
          button: {
            approve: "Approve",
            deny: "Deny"
          },
          empty: "Nothing to display"
        },
        deleteModal: {
          title: "Delete Request",
          message: "Are you sure you want to delete the request?"
        }
      },
      provider: {
        button: {
          approve: "Approve Selected",
          deny: "Deny Selected"
        },
        selectOption: {
          all: "All",
          name: "Name",
          email: "Email"
        },
        list: {
          header: {
            name: "Name",
            email: "Email",
            age: "Age",
            type: "Type",
            requestedTime: "Requested Time",
            status: "Status"
          },
          statusType: {
            requested: "Requested",
            approved: "Approved",
            denied: "Denied",
            cancel: "Canceled"
          },
          button: {
            approve: "Approve",
            deny: "Deny"
          },
          empty: "Nothing to display"
        },
        deleteModal: {
          title: "Delete Request",
          message: "Are you sure you want to delete the request?"
        }
      }
    },
    management: {
      patientList: {
        selectOption: {
          all: "All",
          name: "Name",
          email: "Email"
        },
        list: {
          header: {
            name: "Name",
            email: "Email",
            age: "Age",
            gender: "Gender",
            assignedTo: "Assigned to",
            subscription: "Subscription",
            created: "Created"
          }
        },
        basicInformation: {
          edit: "Edit",
          basicInformation: "Basic Information",
          fullName: "Nickname",
          firstName: "First Name",
          lastName: "Last Name",
          dateofBirth: "Date of Birth",
          gender: "Gender",
          state: "State",
          mobile: "Mobile",
          diagnosis: "Diagnosis",
          dateofOccurence: "Date of Occurence",
          affectedSide: "Affected Side",
          left: "Left",
          right: "Right",
          save: "Save",
          cancel: "Cancel"
        }
      },
      providerList: {
        selectOption: {
          all: "All",
          name: "Name",
          email: "Email"
        },
        button: {
          remove: "Remove Selected"
        },
        removeModal: {
          title: "Remove from the list",
          message: "Are you sure you want to remove the provider from the list?"
        },
        empty: "Nothing to display",
        list: {
          header: {
            name: "Name",
            email: "Email",
            age: "Age",
            type: "Type",
            patients: "Patients"
          },
          patients: "Patients",
          patientList: {
            title: "Patients of",
            list: {
              header: {
                name: "Name",
                email: "Email",
                age: "Age",
                gender: "Gender"
              }
            }
          }
        }
      },
      mapping: {
        tabTitle: {
          providerPatient: "Provider - Patient",
          patientProvider: "Patient - Provider"
        },
        providerPatient: {
          selectOption: {
            all: "All",
            name: "Name",
            email: "Email"
          },
          empty: "Nothing to display",
          button: {
            patientMapping: "Patient Mapping"
          },
          list: {
            header: {
              name: "Name",
              email: "Email",
              age: "Age",
              type: "Type",
              patients: "Patients"
            }
          },
          patients: "Patients"
        },
        patientProvider: {
          selectOption: {
            all: "All",
            name: "Name",
            email: "Email"
          },
          empty: "Nothing to display",
          selectPlaceholder: "Select",
          button: {
            save: "Save"
          },
          list: {
            header: {
              name: "Name",
              email: "Email",
              age: "Age",
              gender: "Gender",
              created: "Created",
              assignedTo: "Assigned To"
            }
          }
        },
        patientMapping: {
          title: "Patient Mapping",
          patientList: {
            title: "Patient List",
            empty: "Nothing to display",
            selectOption: {
              all: "All",
              name: "Name",
              range: "Range"
            },
            list: {
              header: {
                name: "Name",
                age: "Age",
                gender: "Gender",
                create: "Create"
              }
            },
            button: {
              add: "Add"
            }
          },
          patientOf: {
            title: "Patient of",
            empty: "Nothing to display",
            selectOption: {
              all: "All",
              name: "Name"
            },
            list: {
              header: {
                name: "Name",
                age: "Age",
                gender: "Gender"
              }
            }
          },
          button: {
            save: "Save",
            cancel: "Cancel"
          }
        }
      }
    },
    setting: {
      roleAssign: {
        mainOwner: "Main Owner",
        empty: "Nothing to display",
        selectOption: {
          all: "All",
          name: "Name",
          email: "Email"
        },
        roleOption: {
          admin: "Admin",
          owner: "Owner",
          provider: "Provider"
        },
        list: {
          header: {
            name: "Name",
            email: "Email",
            age: "Age",
            type: "Type",
            role: "Role"
          }
        },
        button: {
          save: "Save"
        }
      }
    }
  },
  components: {
    clinicMenu: {
      request: "Request",
      patient: "Patient",
      provider: "Provider",
      management: "Management",
      patientList: "Patient List",
      providerList: "Provider List",
      mapping: "Mapping",
      setting: "Setting",
      roleAssign: "Role Assign"
    },
    clinicTopMenu: {
      moveToPortal: "Move To Portal",
      logOut: "Log out"
    },
    mappingPatientList: {
      title: "Patients of",
      list: {
        header: {
          name: "Name",
          email: "Email",
          age: "Age",
          gender: "Gender"
        }
      }
    },
    subscriptionInfo: {
      title: "Subscription Information",
      name: "Name",
      email: "Email",
      plan: "Plan",
      start: "Start",
      expiration: "Expiration",
      paymentMethod: "Payment Method",
      paymentHistory: "Payment History"
    },
    approveStatus: {
      reviewing: "Reviewing Request",
      reviewingText:
        "Your sign up request is under review.<br/>Please wait for the approval from <span class='clinic-name'></span><br/><br/>if you want to change your clinic, click <a href='#' class='clickable'>here.</a>",
      denied: "Request Denied",
      deniedText:
        "Your sign up request has been denied by <span class='clinic-name'></span><br/>Please check if you have chosen right clinic and<br/><a href='#' class='clickable'>send a request again.</a><br/><br/>if you want to change your clinic, click <a href='#' class='clickable'>here.</a>",
      logout: "Log out"
    }
  }
};
