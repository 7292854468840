<template>
  <div class="MissionDetail">
    <section>
      <div class="contents">
        <div class="video-wrap">
          <iframe
            id="ytplayer"
            type="text/html"
            class="video-player"
            :src="findYoutubeSrc()"
            frameborder="0"
          >
          </iframe>
        </div>
        <div class="title">
          <h1>{{ mission.title }}</h1>
        </div>
        <div class="tag-box custom-scrollbar">
          <a v-for="(tag, index) of mission.tags" :key="index" class="tag">{{
            tag
          }}</a>
        </div>
        <div class="discription-wrap custom-scrollbar">
          <span>{{ mission.description }}</span>
        </div>
        <div class="set-info">
          <div class="set-label">
            {{ $t("page.patient.session.exerciseDetail.complete") }}
          </div>
          <div class="set-input-wrapper">
            <input
              type="number"
              v-model="setsCount"
              @input="updateValue"
              @keydown="onlyNumber"
              @blur="isCheckEmpty"
            />
            <span>{{ $t("page.patient.session.exerciseDetail.sets") }}</span>
          </div>
        </div>
      </div>
      <div
        class="popup-submit-btns"
        :class="selectedHomework.length > 7 ? 'disabled' : ''"
      >
        <button type="button" class="accept-btn" @click="addMission">
          {{ $t("page.patient.session.exerciseDetail.select") }}
        </button>
        <button type="button" class="cancel-btn" @click="handleClose">
          {{ $t("page.patient.session.exerciseDetail.cancel") }}
        </button>
      </div>
      <div class="popup-btns">
        <!-- 팝업 컨트롤 버튼 -->
        <button type="button" class="close-btn icon-btn" @click="handleClose">
          <span class="icon-ic_delete">
            <span class="path1" />
            <span class="path2" />
          </span>
        </button>
      </div>
    </section>
  </div>
</template>
<script>
import { isEmpty, get } from "lodash";

export default {
  name: "MissionDetail",
  props: {
    mission: Object,
    closePopup: Function,
    sets: Number,
    selectedHomework: Array,
  },
  data() {
    return {
      setsCount: this.sets,
    };
  },
  methods: {
    handleClose() {
      this.closePopup();
    },
    findYoutubeSrc() {
      // const link = this.mission.links[0];
      // let videoId = link.split("/")[3];
      // if (link.includes("v=")) {
      //   videoId = link.split("v=")[1];
      // }
      const youtubeUrl = get(this.mission, "links[0]");
      let youtubeId = youtubeUrl.split("/")[3];
      if (youtubeId.includes("watch?v=")) {
        youtubeId = youtubeId.slice(8);
      }
      return `https://www.youtube.com/embed/${youtubeId}?autoplay=1&start=0&mute=1`;
    },
    addMission() {
      if (this.selectedHomework.length > 7) {
        return;
      }

      const newSet = {
        id: this.mission.id,
        title: this.mission.title,
        image: this.mission.image,
        links: this.mission.links,
        setsCount: this.setsCount,
      };

      this.selectedHomework.push(newSet);
      this.handleClose();
    },
    updateValue(e) {
      const value = Number(e.target.value);
      if (value < 1) this.setsCount = "";
      if (value > 99) this.setsCount = 99;
      // this.$forceUpdate();
    },
    isCheckEmpty(e) {
      if (isEmpty(e.target.value)) this.setsCount = 3;
    },
    onlyNumber(event) {
      let re = new RegExp("[^0-9\\-]");
      if (event.keyCode === 8 || event.keyCode === 46) {
        return;
      } else {
        if (re.test(event.key)) {
          // event.stopPropagation();
          event.preventDefault();
          return;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.MissionDetail {
  position: fixed;
  width: 450px;
  height: 716px;
  background: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #d0d4dc;
  box-sizing: border-box;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  overflow: hidden;
  z-index: 3;
  font-family: Manrope;

  .contents {
    display: block;
    width: calc(100% - 48px);
    height: calc(627px - 56px - 23px);
    padding: 56px 24px 23px 24px;

    .video-wrap {
      width: 100%;
      height: 226px;
      border-radius: 10px;
      overflow: hidden;

      .video-player {
        width: 100%;
        height: 225px;
        .html5-video-container {
          background: #fff;
        }
      }
    }

    .title {
      margin-top: 16px;
      height: 66px;
      overflow: hidden;
      text-overflow: ellipsis;

      h1 {
        padding: 0;
        line-height: 33px;
      }
    }

    .tag-box.custom-scrollbar {
      width: 100%;
      box-sizing: border-box;
      margin-top: 12px;
      height: 64px;
      overflow-y: auto;

      .tag {
        display: inline-block;
        margin-bottom: 8px;
        font-size: $fontSize7;
        margin-right: 6px;
        padding: 5px 12px;
        border: 1px solid #d0d4dc;
        box-sizing: border-box;
        border-radius: 100px;
      }
    }

    .discription-wrap.custom-scrollbar {
      margin-top: 14px;
      height: 72px;
      box-sizing: border-box;
      line-height: 24px;
      overflow-y: auto;
      font-size: $fontSize7;
      font-weight: 500;
    }

    .set-info {
      margin-top: 16px;

      .set-label {
        font-size: $fontSize7;
        font-style: normal;
        font-weight: bold;
        line-height: 16px;
      }

      .set-input-wrapper {
        margin-top: 8px;

        input {
          width: 96px;
          height: 36px;
          background: #ffffff;
          border: 1px solid #d0d4dc;
          box-sizing: border-box;
          border-radius: 2px;
        }
        span {
          display: inline-block;
          margin-left: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: $fontSize7;
          line-height: 14px;
        }
      }
    }
  }

  .popup-submit-btns {
    height: 88px;

    .accept-btn {
      width: 122px;
      height: 40px;
    }
  }
}
</style>