<template>
  <div class="Calendar">
    <!-- 환자별 타입별 정렬 메뉴 -->
    <transition name="slide-fade">
      <CalendarSorting
        v-if="sortingMenu"
        ref="sorting"
        :all-patients="allPatients"
        :event-types="eventTypes"
        :window-height="windowHeight + 60"
        :handle-detail-popup-close="handleDetailPopupClose"
        :sort-event-sources="sortEventSources"
        :set-event-sources="setEventSources"
      />
    </transition>
    <!-- fullcalendar 섹션 -->
    <div class="calendar-area">
      <button type="button" class="sorting-btn icon-btn" @click="toggleSortingMenu">
        <span class="icon-ic_sorting">
          <span class="path1" />
          <span class="path2" />
          <span class="path3" />
        </span>
      </button>
      <FullCalendar
        ref="fullCalendar"
        time-zone="local"
        :plugins="calendarConfig.calendarPlugins"
        default-view="dayGridMonth"
        :header="calendarConfig.header"
        :event-limit="calendarConfig.eventLimit"
        :selectable="calendarConfig.selectable"
        :show-non-current-dates="calendarConfig.showNonCurrentDates"
        :fixed-week-count="calendarConfig.fixedWeekCount"
        :day-popover-format="calendarConfig.dayPopoverFormat"
        :title-format="calendarConfig.titleFormat"
        :all-day-text="calendarConfig.allDayText"
        :button-text="calendarConfig.buttonText"
        :first-day="calendarConfig.firstDay"
        :dates-render="handleDatesRender"
        :event-limit-text="calendarConfig.eventLimitText"
        :height="windowHeight"
        :now="now"
        class="fullCalendar"
        @eventClick="handleEventClick"
        @select="createEvent"
        @windowResize="handleWindowResize"
      />
    </div>
    <!-- 이벤트 상세팝업 -->
    <EventDetail
      v-if="eventDetailPopup.view"
      ref="detailPopup"
      :event-detail-info="eventDetailPopup"
      :start-screen-sharing="startScreenSharing"
      :start-video-call="startVideoCall"
      :handle-detail-popup-close="handleDetailPopupClose"
      :change-event-sources="changeEventSources"
      :delete-event="deleteEvent"
      :deny-event="denyEvent"
      :handle-error="handleError"
      :calendar-size="calendarSize"
      :edit-event="editEvent"
    />
    <!-- 이벤트 생성팝업 -->
    <CreateEvent
      v-if="createEventPopup.view"
      ref="createPopup"
      :create-event-info="createEventPopup"
      :change-event-sources="changeEventSources"
      :handle-error="handleError"
      :handle-create-event-popup-close="handleCreateEventPopupClose"
    />
  </div>
</template>
<script>
/* eslint-disable */
/* eslint-disable vue/attributes-order */

import CalendarSorting from "./components/CalendarSorting";
import EventDetail from "./components/EventDetail";
import CreateEvent from "./components/CreateEvent";
import ScreenSharing from "@/views/ScreenSharing";
import VideoCall from "@/views/VideoCall";
import Confirm from "@/components/Confirm";
/* full calendar library */
import FullCalendar from "@fullcalendar/vue";
/* full calendar default settings */
import CALENDAR_DEFAULT from "@/utils/calendar_default.js";
import {
  fetchMonthEvents,
  fetchDetailEventInfo,
  fetchDeleteEvent,
  fetchDenyEvent
} from "@/api/calendar.js";
import { mapGetters } from "vuex";
import { SelectMixin } from "@/mixins/SelectMixin.js";
export default {
  name: "Calendar",
  components: {
    FullCalendar,
    EventDetail,
    CalendarSorting,
    CreateEvent,
    ScreenSharing,
    VideoCall
  },
  mixins: [SelectMixin],
  data() {
    return {
      calendarConfig: CALENDAR_DEFAULT,
      calendarSize: {
        width: 0,
        height: 0
      },
      eventSources: [],
      /*
        eventTypes 설명

        1. Video Session === group1
        2. Co-op Game === group2
        3. Screen Sharing === group3
        4. Stroke coach Live === group4
        5. General === group5
        6. Video Session Request === group6
      */
      eventTypes: ["group1", "group3", "group4", "group5", "group6"], // co-op game hidden
      windowHeight: 0,
      clientTime: {
        year: "",
        month: "",
        zone_offset: ""
      },
      eventDetailPopup: {},
      createEventPopup: {},
      sortingMenu: false,
      now: new Date()
    };
  },
  methods: {
    handleEventClick(info) {
      /*
      //// Event 상세보기 ////
      */
      this.handleDetailPopupClose();
      const id = info.event.id;
      fetchDetailEventInfo(id)
        .then(result => {
          // console.log(coordinates);
          const createNew = {
            ...result,
            view: true,
            element: info.el
          };
          this.eventDetailPopup = createNew;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    handleEventDetailOpen(id) {
      /*
      //// Event 상세보기 (push에서 열어야하는 경우) ////
      */
      this.handleDetailPopupClose();
      fetchDetailEventInfo(id)
        .then(result => {
          // console.log(coordinates);
          const createNew = {
            clientTime: this.clientTime,
            ...result,
            view: true
          };
          this.eventDetailPopup = createNew;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    handleDetailPopupClose() {
      /*
      //// event 상세보기 닫기 ////
      */
      if (this.$route.params.eventId) {
        this.$router.push("/calendar");
      }
      this.eventDetailPopup.view = false;
    },
    handleCreateEventPopupClose() {
      /*
      //// event 생성팝업 닫기 ////
      */
      this.createEventPopup.view = false;
    },
    async startScreenSharing(eventId) {
      /*
      //// 스케쥴 일정에서 Screen Sharing 시작하기 ////
      */
      try {
        await this.$store.dispatch("screensharing/startInSchedule", eventId);
      } catch (error) {
        this.handleError(error);
      }
    },
    async startVideoCall(eventId) {
      /*
      //// 스케쥴 일정에서 Video Call 시작하기 ////
      */
      try {
        await this.$store.dispatch("videocall/startInSchedule", eventId);
      } catch (error) {
        this.handleError(error);
      }
    },
    handleWindowResize(info) {
      /*
      //// calendar resize 콜백 ////
      */
      this.calendarSize = {
        width: this.$el.offsetWidth,
        height: this.$el.offsetHeight
      };
      const checkWindowHeight =
        window.innerHeight <= 790 ? 730 : window.innerHeight - 60;
      this.windowHeight = checkWindowHeight;
      this.handleDetailPopupClose();
    },
    sortEventSources(types, patients) {
      /*
      //// type, patient별 정렬 ////
      */
      let newEventSources = eval("(" + JSON.stringify(this.eventSources) + ")");
      if (!newEventSources) {
        return [];
      }
      newEventSources = newEventSources.filter(source => {
        for (const type of types) {
          if (source.className === type) {
            return source;
          }
        }
      });
      newEventSources.map(source => {
        if (source.className === "group4" || source.className === "group5") {
          return source;
        }
        source.events = source.events.filter(event => {
          for (let group of event.patients) {
            for (let patient of patients) {
              if (group.id === patient) {
                return event;
              }
            }
          }
        });
      });
      return newEventSources;
    },
    createEvent(info) {
      /*
      //// Event 생성하기 ////
      */

      this.handleDetailPopupClose();
      const allPatients = this.allPatients.map(patient => {
        patient.attendance_state = "WAIT";
        return patient;
      });
      const createNew = {
        start: info.start,
        end: info.start,
        allPatients,
        zone_offset: this.clientTime.zone_offset,
        className: "group1",
        method: "ADD",
        view: true
      };
      this.createEventPopup = createNew;
    },
    editEvent(info) {
      /*
      //// Event 수정하기 ////
      */
      this.handleDetailPopupClose();
      const allPatients = this.allPatients.map(patient => {
        patient.attendance_state = "WAIT";
        return patient;
      });
      const patients = info.patients.map(patient => {
        allPatients.forEach(currentPatient => {
          if (patient.id === currentPatient.id) {
            patient.name = currentPatient.name;
            patient.zone_offset = currentPatient.zone_offset;
            return;
          }
        });
        return patient;
      });
      // console.log(patients);
      const editNew = {
        ...info,
        allPatients,
        patients,
        zone_offset: this.clientTime.zone_offset,
        method: "EDIT",
        view: true
      };
      // console.log(editNew);
      this.createEventPopup = editNew;
    },
    deleteEvent(type, id) {
      /*
      //// delete event ////
      */

      fetchDeleteEvent(id)
        .then(result => {
          this.handleDetailPopupClose();
          this.eventSources.forEach(source => {
            if (source.className === type) {
              source.events = source.events.filter(event => {
                return event.id !== id;
              });
            }
          });
          this.setEventSources(this.eventSources);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    denyEvent(type, id) {
      /*
      //// 스크린세션 요청거부 ////
      */
      const denyContents = {
        contents: ""
      };
      fetchDenyEvent(id, denyContents)
        .then(result => {
          // console.log(result);
          this.handleDetailPopupClose();
          this.eventSources.forEach(source => {
            if (source.className === type) {
              source.events = source.events.filter(event => {
                return event.id !== id;
              });
            }
          });
          this.setEventSources(this.eventSources);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    changeEventSources(method, newSources, id) {
      /*
      //// change eventSources ////
      */

      this.eventSources = this.eventSources || [];
      const find = this.eventSources.findIndex(source => {
        return source.className === newSources.className;
      });
      if (method === "ADD") {
        // event를 add

        if (find < 0) {
          this.eventSources.push(newSources);
        } else {
          this.eventSources.forEach(source => {
            if (source.className === newSources.className) {
              source.events = source.events.concat(newSources.events);
            }
          });
        }
      } else if (method === "EDIT") {
        // event를 edit
        this.eventSources[find].events = this.eventSources[find].events
          .filter(event => {
            return event.id !== id;
          })
          .concat(newSources.events);
      } else if (method === "CONFIRM") {
        // Request confirm (스크린세션 요청수락)
        if (find < 0) {
          this.eventSources.push(newSources);
        } else {
          this.eventSources.forEach(source => {
            if (source.className === newSources.className) {
              source.events = source.events.concat(newSources.events);
            }
          });
        }
        this.eventSources.forEach(source => {
          if (source.className === "group6") {
            source.events = source.events.filter(event => {
              return event.id !== id;
            });
          }
        });
      }
      this.setEventSources(this.eventSources);
    },
    setEventSources(sources) {
      /*
      //// calendar에 변경된 eventsource 프린트 ////
      */
      if (!this.$refs.fullCalendar) {
        return;
      }
      const calendarApi = this.$refs.fullCalendar.getApi();
      const getAllEventSource = calendarApi.getEventSources();
      for (let source of getAllEventSource) {
        source.remove();
      }
      // console.log(sources);
      for (let source of sources) {
        if (source.className === "group4") {
          // therapist live의 경우 제목표시는 해당 문자로 일괄처리
          source.events.forEach(item => {
            item.title = this.$t("page.calendar.sideBar.type.providerLive");
          });
        } else if(source.className === "group1") {
          source.events.forEach(item => {
            item.title = this.$t("page.calendar.sideBar.type.videoCall") + ' - ' + item.patients[0].name;
          });
        } else if(source.className === "group3") {
          source.events.forEach(item => {
            item.title = this.$t("page.calendar.sideBar.type.screenSharing");
          });
        } else if(source.className === "group6") {
          source.events.forEach(item => {
            item.title = this.$t("page.calendar.sideBar.type.videoCallRequest");
          });
        }
        calendarApi.addEventSource(source);
      }
    },
    handleDatesRender(info) {
      /*
      //// calendar 렌더링될 때 ////
      */
      this.checkCurrentMonth();
      this.handleDetailPopupClose();
    },
    toggleSortingMenu() {
      /*
      //// sorting Menu 토글 ////
      */
      this.sortingMenu = !this.sortingMenu;
    },
    checkCurrentMonth() {
      /*
      //// client timezone checking ////
      */
      const calendarApi = this.$refs.fullCalendar.getApi();
      const getCurrentDate = calendarApi.getDate();
      const currentYear = getCurrentDate.getFullYear();
      const currentMonth = getCurrentDate.getMonth() + 1;
      if (
        String(currentYear) !== String(this.clientTime.year) ||
        String(currentMonth) !== String(this.clientTime.month)
      ) {
        this.clientTime = {
          year: currentYear,
          month: currentMonth,
          zone_offset: -getCurrentDate.getTimezoneOffset()
        };
      }
    },
    getMonthEvents(year, month, zoneOffset) {
      /*
      //// 월별 eventSources Get ////
      */
      // this.activeTodayButtonInCalendar();
      fetchMonthEvents(year, month, zoneOffset, this.clinic.id)
        .then(result => {
          // console.log(result);
          this.eventSources = result;
          this.setEventSources(this.eventSources);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    forceGetMonthEvents() {
      // video call request 푸시울렸을때 사용함
      this.getMonthEvents(
        this.clientTime.year,
        this.clientTime.month,
        this.clientTime.zone_offset
      );
    },
    handleError(error) {
      console.error("Stroke coach Calendar", error);
      if (error.status === 404) {
        this.$modal.show(
          Confirm,
          {
            type: "error",
            confirm: {
              title: "Non-existent Event",
              message: "This event has been deleted or canceled."
            }
          },
          {
            height: "auto"
          }
        );
      } else {
        this.$modal.show(
          Confirm,
          {
            type: "error",
            confirm: {
              title: "Error occurred",
              message: "Something is wrong with the server. Please try again."
            }
          },
          {
            height: "auto"
          }
        );
      }
    },
    updateDateTime() {
      // fullcalendar today 업데이트
      const now = new Date();
      if (this.now.getDate() !== now.getDate()) {
        console.log("Start new Day.");
        this.now = now;
      }
    }
  },
  watch: {
    lang: function(newVal, oldVal) {
      this.forceGetMonthEvents();
    },
    clientTime: function(newVal, oldVal) {
      this.getMonthEvents(newVal.year, newVal.month, newVal.zone_offset);
    },
    $route: function(newVal, oldVal) {
      const eventId = newVal.params.eventId;
      if (eventId) {
        if (eventId === "forcerefresh") {
          this.forceGetMonthEvents();
          this.$router.push("/");
        } else {
          this.handleEventDetailOpen(eventId);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      allPatients: "user/patients",
      clinic: "user/clinic",
      lang: "lang/language"
    })
  },
  created() {
    const checkWindowHeight =
      window.innerHeight <= 790 ? 730 : window.innerHeight - 60;
    this.windowHeight = checkWindowHeight;
    const today = new Date();
    this.clientTime = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      zone_offset: -today.getTimezoneOffset()
    };
    // get patientsList
    this.$store.dispatch("user/getPatientsList");
    const eventId =
      this.$route.params.eventId || sessionStorage.getItem("eventId");
    if (eventId) {
      // console.log(eventId);
      if (eventId !== "forcerefresh") {
        this.handleEventDetailOpen(eventId);
      }
      if (sessionStorage.getItem("eventId")) {
        sessionStorage.removeItem("eventId");
      }
    }
    this.$options.interval = setInterval(this.updateDateTime, 1000);
  },
  mounted() {
    this.calendarSize = {
      width: this.$el.offsetWidth,
      height: this.$el.offsetHeight
    };
  },
  beforeDestroy() {
    const calendarApi = this.$refs.fullCalendar.getApi();
    calendarApi.destroy();
    clearInterval(this.$options.interval);
  }
};
</script>
<style lang="scss">
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
.Calendar {
  position: relative;
  display: flex;
  font-size: $fontSize5;
  /* sorting menu motions */
  .slide-fade-enter-active {
    transition: all 0.2s ease-in-out;
  }
  .slide-fade-leave-active {
    transition: all 0.2s ease-in-out;
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(-50px);
    opacity: 0;
  }
  .calendar-area {
    position: relative;
    .sorting-btn {
      position: absolute;
      top: 16px;
      left: 16px;
      z-index: 1;
    }
    .fullCalendar {
      position: relative;
      color: $fontColor1;
      .fc-head {
        .fc-day-header {
          font-size: $fontSize7;
          line-height: 16px;
          padding: 7px 0;
          color: $fontColor3;
        }
      }
      .fc-header-toolbar {
        padding: 16px 0;

        &.fc-toolbar {
          margin: 0;
        }
        .fc-center {
          display: flex;
          h2 {
            width: 150px;
            text-align: center;
            margin: 0 21.4px;
            line-height: 24px;
            font-size: $fontSize4;
          }
        }
      }

      &.fc-unthemed {
        th,
        td {
          border-width: 0.6px;
          border-color: $borderColor1;
          &:first-of-type {
            border-left: 0 none;
          }
          &:last-of-type {
            border-right: 0 none;
          }
        }
        .fc-popover {
          .fc-header {
            background: white;
          }
        }
        .fc-disabled-day {
          background: rgba(255, 255, 255, 0);
        }
      }
      .fc-day-header {
        position: relative;
      }
      .fc-day-top {
        position: relative;
        .fc-day-number {
          // float: left;
          position: relative;
          padding: 8px;
          top: 0;
          right: 0;
          font-size: $fontSize7;
          color: $fontColor2;
          // transform: translate(-50%, 0);
        }
      }
      .fc-event-container {
        padding: 0 8px;
      }
      .fc-event {
        cursor: pointer;
        margin: 0;
        font-size: $fontSize6;
        line-height: $fontSize6;
        background: rgba(255, 255, 255, 0);
        color: $fontColor1;
        padding-left: 16px;
        border: 0;

        &:hover {
          background: rgba(90, 90, 90, 0.1);
        }
        .fc-content {
          padding: 4px 0;
          font-size: $fontSize7;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: normal;
          box-sizing: border-box;
        }
        &::before {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          background: rgb(167, 155, 142);
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          border-radius: 50%;
        }
        &.group1 {
          &::before {
            background: $groupColor1;
          }
        }
        &.group2 {
          &::before {
            background: $groupColor2;
          }
        }
        &.group3 {
          &::before {
            background: $groupColor3;
          }
        }
        &.group4 {
          &::before {
            background: $groupColor4;
          }
        }
        &.group5 {
          &::before {
            background: $groupColor5;
          }
        }
        &.group6 {
          &::before {
            background: $groupColor6;
          }
        }
      }
      .fc-more-popover {
        width: 260px;
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
        overflow: hidden;
        border: none;
        box-sizing: border-box;
        .fc-header {
          .fc-title {
            margin: auto;
            font-weight: 500;
            font-size: $fontSize3;
            padding: 16px 0;
            color: $fontColor2;
          }
          .fc-close {
            position: absolute;
            top: 16px;
            right: 16px;
          }
          .fc-icon-x {
            font-size: $fontSize2;
            color: $fontColor1;
            &::before {
              content: "\e951";
            }
            &::after {
              content: "\e952";
              margin-left: -1em;
            }
          }
        }
        .fc-event-container {
          padding: 0 16px 24px 16px;
        }
      }
      .fc-today {
        background: rgba(255, 255, 255, 0);
        .fc-day-number {
          margin: 4px;
          padding: 0;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          background: $mainColor1;
          color: #ffffff;
          border-radius: 50%;
          box-sizing: border-box;
        }
      }
      .fc-button {
        padding: 0;
        border-radius: 6px;
        position: relative;
        box-sizing: border-box;
        &.fc-button-primary {
          background: rgba(255, 255, 255, 0);
          color: $fontColor1;
          border-color: $borderColor1;
        }
        &.fc-prev-button,
        &.fc-next-button {
          width: 24px;
          height: 24px;
          margin: 0;
          color: $fontColor2;
          background: rgba(244, 245, 247, 0.4);
        }

        &.fc-today-button {
          width: 67.1px;
          margin-right: 20.9px;
          color: $fontColor2;
          font-size: $fontSize7;
          background: rgba(244, 245, 247, 0.4);
        }
      }
      .fc-icon {
        font-family: "icomoon" !important;
        font-size: $fontSize6;
        color: $fontColor2;
        &.fc-icon-chevron-left {
          position: relative;
          top: -3px;
          left: -1px;
          &:before {
            content: "\e93a";
          }
        }
        &.fc-icon-chevron-right {
          position: relative;
          top: -2px;
          left: 1px;
          &:before {
            content: "\e93f";
          }
        }
      }
      .fc-popover.fc-more-popover {
        border-radius: 0.5rem;
      }
      .fc-more-cell {
        position: relative;
        .fc-more {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 40%);
          font-size: $fontSize7;
          color: $fontColor2;
        }
      }
    }
  }
}
</style>
