var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "createLicenseStatesPopup", staticClass: "LicenseStates" },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "Event-contents" }, [
          _c("div", { staticClass: "checkbox-section" }, [
            _c("div", { staticClass: "check-all" }, [
              _c(
                "button",
                {
                  class: _vm.checkAllStates,
                  on: { click: _vm.handleAllStates }
                },
                [_vm._v("Select All")]
              )
            ]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "check-list custom-scrollbar" },
              _vm._l(_vm.allStates, function(state, index) {
                return _c("li", { key: index }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkedStates,
                        expression: "checkedStates"
                      }
                    ],
                    attrs: { id: state.abbr, type: "checkbox" },
                    domProps: {
                      value: state,
                      checked: Array.isArray(_vm.checkedStates)
                        ? _vm._i(_vm.checkedStates, state) > -1
                        : _vm.checkedStates
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.checkedStates,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = state,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.checkedStates = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.checkedStates = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.checkedStates = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: state.abbr } }, [
                    _vm._v(_vm._s(state.state))
                  ])
                ])
              }),
              0
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "popup-submit-btns" }, [
          _c(
            "button",
            {
              staticClass: "accept-btn",
              attrs: { type: "button" },
              on: { click: _vm.handleClose }
            },
            [_vm._v("\n        Save\n      ")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "popup-btns" }, [
          _c(
            "button",
            {
              staticClass: "close-btn icon-btn",
              attrs: { type: "button" },
              on: { click: _vm.handleClose }
            },
            [_vm._m(0)]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }