<template>
  <div>
    <!-- 회원가입 1단계 -->
    <!-- 이메일 인증 -->
    <section v-if="stage === 1" class="Signup">
      <div v-if="!sentEmail">
        <h1>{{ $t("page.signUp.title") }}</h1>
        <div class="notice">
          {{ $t("page.signUp.description") }}
        </div>
        <form ref="signupForm" @submit.prevent>
          <div ref="email" class="email">
            <label for="email">{{ $t("page.signUp.emailLabel") }}</label>
            <input
              id="email"
              v-model="email"
              placeholder="Email"
              type="text"
              @keypress.enter="handleSubmit"
            >
            <span ref="emailErrorMessage" class="message">{{
              $t("page.signUp.emailError.format")
            }}</span>
          </div>
          <div
            ref="submitBtn"
            class="submit-btns"
            :class="submitDisable ? 'disabled' : ''"
          >
            <button type="button" @click="handleSubmit">
              {{ $t("page.signUp.sendButton") }}
            </button>
          </div>
          <div class="aside-btns">
            <router-link to="/login">{{
              $t("page.signUp.logInLabel")
            }}</router-link>
          </div>
        </form>
      </div>
      <div v-else>
        <h1 v-if="!resend">{{ $t("page.signUp.email.title") }}</h1>
        <h1 v-else>{{ $t("page.signUp.reEmail.title") }}</h1>
        <div class="verification-email">{{ email }}</div>
        <div class="notice">
          <span v-if="!resend">
            <p
              v-for="(item, index) in $t('page.signUp.email.description')"
              :key="index"
            >
              {{ item }}
            </p>
          </span>
          <span v-else>
            <p
              v-for="(item, index) in $t('page.signUp.reEmail.description')"
              :key="index"
            >
              {{ item }}
            </p>
          </span>
          <button type="button" @click="resendVerifyEmail">
            {{ $t("page.signUp.reEmail.reSend") }}</button>.
        </div>
        <div class="primary-type-btns big-btns login-btn">
          <router-link to="/login">{{
            $t("page.signUp.email.homeButton")
          }}</router-link>
        </div>
      </div>
    </section>
    <!-- 회원가입 2단계 -->
    <!-- 회원가입을 위한 정보입력 -->
    <section v-else-if="stage === 2" class="Signup">
      <h1>{{ $t("page.signUp.inputInfo.title") }}</h1>
      <form ref="signupForm" @submit.prevent>
        <div class="full-name">
          <label for="fullName">{{
            $t("page.signUp.inputInfo.name.title")
          }}</label>
          <input
            id="fullName"
            v-model="fullName"
            :placeholder="inputInfoNamePlaceholder"
            type="text"
            autocomplete="off"
          >
        </div>
        <div class="country">
          <label for="country">{{
            $t("page.signUp.inputInfo.country.title")
          }}</label>
          <Select
            id="country"
            v-model="selectedCountry"
            label="countryName"
            :placeholder="inputInfoCountryPlaceholder"
            :components="{ OpenIndicator, Deselect }"
            :options="countryList"
          >
            <div slot="no-options" class="vs__no-options">
              {{ $t("page.signUp.inputInfo.country.errorMessage") }}
            </div>
          </Select>
        </div>
        <div
          v-if="selectedCountry.countryName === nationAmerica"
          class="states"
        >
          <div class="states-information">
            <label for="states">{{
              $t("page.signUp.inputInfo.states.title")
            }}</label>
            <span class="icon-ic_info_circle">
              <span class="path1" />
              <span class="path2" />
            </span>
            <div class="notice-information">
              {{ $t("page.signUp.inputInfo.states.tooltip") }}
            </div>
          </div>
          <input
            v-model="showText"
            type="text"
            readonly
            class="inputClass"
            @focus="showPopup"
          >
          <LicenseStates
            v-show="licenseStatesPopup"
            ref="createLicenseStatesPopup"
            :all-states="statesList"
            :license-states-info="createLicenseStatesPopup"
            :handle-create-license-states-popup-close="
              handleCreateLicenseStatesPopupClose
            "
            :set-checked-states="setCheckedStates"
            :set-selected-states="setSelectedStates"
          />
        </div>
        <div class="gender">
          <label for="Male">{{
            $t("page.signUp.inputInfo.gender.title")
          }}</label>
          <div class="gender-radio">
            <span v-for="(type, index) of genderList" :key="index">
              <input
                :id="type"
                v-model="selectedGender"
                type="radio"
                :value="type"
              >
              <label :for="type">{{ inputInfoGenderOption(type) }}</label>
            </span>
          </div>
        </div>
        <div class="mobile">
          <label for="mobile">{{
            $t("page.signUp.inputInfo.mobilePhone.title")
          }}</label>
          <input
            id="mobile"
            v-model="mobileNumber"
            :placeholder="inputInfoMobilePhonePlaceholder"
            type="text"
            autocomplete="off"
            @keydown="onlyNumber"
          >
        </div>
        <div class="therapist-type">
          <label for="OT">{{
            $t("page.signUp.inputInfo.providerType.title")
          }}</label>
          <div class="therapist-type-radio">
            <span v-for="(type, index) of therapistTypeList" :key="index">
              <input
                :id="type"
                v-model="selectedTherapistType"
                type="radio"
                :value="type"
              >
              <label :for="type">{{ inputInfoProviderTypeOption(type) }}</label>
            </span>
          </div>
        </div>
        <div class="work-place" :class="clinicCodeCheck ? '' : 'wrong'">
          <label for="Neofect">{{
            $t("page.signUp.inputInfo.workPlace.title")
          }}</label>
          <div class="work-place-radio">
            <span v-for="(type, index) of workplaceList" :key="index">
              <input
                :id="type"
                v-model="selectedWorkplace"
                type="radio"
                :value="type"
              >
              <label :for="type">{{ inputInfoWorkPlaceOption(type) }}</label>
            </span>
          </div>
          <!-- <Select
            v-model="selectedClinic"
            label="name"
            :placeholder=inputInfoWorkPlacePlaceholder
            :components="{ OpenIndicator, Deselect }"
            :options="clinicList"
            id="clinic"
            :selectable="
              option => !option.name.toLowerCase().includes('neofect')
            "
            :disabled="selectedWorkplace === 'Neofect'"
          >
            <div slot="no-options" class="vs__no-options">Sorry, no matching states.</div>
          </Select> -->
          <!-- clinic code 입력 input 21.02.02 -->
          <input
            id="clinic"
            v-model="clinicCode"
            type="text"
            autocomplete="off"
            :placeholder="inputInfoWorkPlacePlaceholder"
            :disabled="selectedWorkplace === 'Neofect'"
          >
          <span class="message">
            {{ clinicCodeWrongMessage }}
          </span>
        </div>
        <div class="email">
          <label for="email">{{
            $t("page.signUp.inputInfo.email.title")
          }}</label>
          <input
            id="email"
            v-model="email"
            placeholder="Email"
            type="text"
            disabled
            autocomplete="off"
          >
        </div>
        <div class="password" :class="passwordCheck ? '' : 'wrong'">
          <label for="password">{{
            $t("page.signUp.inputInfo.password.title")
          }}</label>
          <input
            id="password"
            v-model="password"
            :placeholder="inputInfoPasswordPlaceholder"
            autocomplete="off"
            :type="passwordType"
          >
          <button
            v-if="passwordType === 'text'"
            type="button"
            class="password-show"
            @click="passwordHidden('default')"
          >
            <span class="icon-ic_eye_hide">
              <span class="path1" />
              <span class="path2" />
              <span class="path3" />
              <span class="path4" />
              <span class="path5" />
              <span class="path6" />
            </span>
          </button>
          <button
            v-else
            type="button"
            class="password-show"
            @click="passwordShow('default')"
          >
            <span class="icon-ic_eye_show">
              <span class="path1" />
            </span>
          </button>
          <span class="message">
            {{ passwordWrongMessage }}
          </span>
        </div>
        <div
          class="confirm-password"
          :class="confirmPasswordCheck ? '' : 'wrong'"
        >
          <label for="confirmpassword">{{
            $t("page.signUp.inputInfo.rePassword.title")
          }}</label>
          <input
            id="confirmpassword"
            v-model="confirmPassword"
            :placeholder="inputInfoRePasswordPlaceholder"
            autocomplete="off"
            :type="confirmPasswordType"
          >
          <button
            v-if="confirmPasswordType === 'text'"
            type="button"
            class="password-show"
            @click="passwordHidden('confirm')"
          >
            <span class="icon-ic_eye_hide">
              <span class="path1" />
              <span class="path2" />
              <span class="path3" />
              <span class="path4" />
              <span class="path5" />
              <span class="path6" />
            </span>
          </button>
          <button
            v-else
            type="button"
            class="password-show"
            @click="passwordShow('confirm')"
          >
            <span class="icon-ic_eye_show">
              <span class="path1" />
            </span>
          </button>
          <span class="message">{{
            $t("page.signUp.inputInfo.rePassword.error.notMatch")
          }}</span>
        </div>
        <div class="submit-btns" :class="submitDisable ? 'disabled' : ''">
          <button type="button" @click="handleSubmit">
            {{ $t("page.signUp.inputInfo.signUpButton") }}
          </button>
        </div>
      </form>
    </section>
    <!-- 회원가입 3단계 -->
    <!-- 회원가입 완료 -->
    <section v-else-if="stage === 3" class="Signup">
      <h1>{{ $t("page.signUp.signUpDone.title") }}, {{ `${fullName}!` }}</h1>
      <div class="notice">{{ $t("page.signUp.signUpDone.description") }}</div>
      <div class="primary-type-btns big-btns login-btn">
        <router-link to="/login">{{
          $t("page.signUp.signUpDone.logIn")
        }}</router-link>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable vue/return-in-computed-property */

import Select from "vue-select";
import LicenseStates from "./components/LicenseStates";

import { getUSAStatesList, fetchSignup, fetchVerifyEmail } from "@/api/user.js";
import {
  fetchGetClinicByClinicCode
} from "@/api/clinic.js";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";

export default {
  name: "Signup",
  components: {
    LicenseStates,
    Select
  },
  mixins: [SelectMixin, ErrorMixin],
  data() {
    return {
      countryList: [
        { countryName: this.$t("common.nation.america"), countryValue: "US" },
        { countryName: this.$t("common.nation.korea"), countryValue: "KR" }
      ],
      statesList: [],
      genderList: ["Male", "Female"],
      therapistTypeList: ["OT", "PT", "Others"],
      workplaceList: ["Neofect", "Other Clinic"],
      // clinicList: [],
      stage: 1,
      sentEmail: false,
      resend: false,
      passwordType: "password",
      confirmPasswordType: "password",
      // signup에 필요한 것들
      email: "",
      password: "",
      passwordWrongMessage:
        "Must be at least 10 characters includes both letters and number",
      confirmPassword: "",
      fullName: "",
      selectedGender: "",
      selectedCountry: "",
      checkedStates: [],
      selectedTherapistType: "",
      selectedWorkplace: "Other Clinic",
      selectedClinic: "",
      mobileNumber: "",
      token: "",
      createLicenseStatesPopup: {},
      licenseStatesPopup: false,
      showText: "",
      // 클리닉 코드 추가.
      clinicCode: "",
      clinicCodeWrongMessage: ""
    };
  },
  computed: {
    nationAmerica: function () {
      return this.$t("common.nation.america");
    },
    inputInfoNamePlaceholder: function () {
      return this.$t("page.signUp.inputInfo.name.placeholder");
    },
    inputInfoCountryPlaceholder: function () {
      return this.$t("page.signUp.inputInfo.country.placeholder");
    },
    inputInfoGenderOption() {
      return (value) => {
        return this.$t(`page.signUp.inputInfo.gender.${value.toLowerCase()}`);
      };
    },
    inputInfoMobilePhonePlaceholder: function () {
      return this.$t("page.signUp.inputInfo.mobilePhone.placeholder");
    },
    inputInfoProviderTypeOption() {
      return (value) => {
        return this.$t(`page.signUp.inputInfo.providerType.${value}`);
      };
    },
    inputInfoWorkPlaceOption() {
      return (value) => {
        return this.$t(
          `page.signUp.inputInfo.workPlace.${value.replace(" ", "")}`
        );
      };
    },
    inputInfoWorkPlacePlaceholder: function () {
      return this.$t("page.signUp.inputInfo.workPlace.placeholder");
    },
    inputInfoPasswordPlaceholder: function () {
      return this.$t("page.signUp.inputInfo.password.placeholder");
    },
    inputInfoRePasswordPlaceholder: function () {
      return this.$t("page.signUp.inputInfo.rePassword.placeholder");
    },
    passwordCheck: function () {
      return this.validatePassword(this.password);
    },
    confirmPasswordCheck: function () {
      return this.password === this.confirmPassword;
    },
    // 클리닉코드 체크 추가.
    clinicCodeCheck: function () {
      return this.validateClinicCode(this.clinicCode);
    },
    submitDisable: function () {
      if (this.stage === 1) {
        const re = new RegExp(
          "^[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*\\.[a-zA-Z]{2,4}$",
          "i"
        );
        return !re.test(this.email);
      }
      if (this.stage === 2) {
        return (
          this.fullName.length < 1 ||
          this.selectedGender.length < 1 ||
          this.selectedTherapistType.length < 1 ||
          this.mobileNumber.length < 1 ||
          this.selectedCountry.length < 1 ||
          (this.selectedCountry.countryValue === "US" &&
            this.checkedStates.length < 1) ||
          !this.password ||
          !this.validatePassword(this.password) ||
          this.password !== this.confirmPassword ||
          !this.validateClinicCode(this.clinicCode)
        );
      }
    }
  },
  watch: {
    email: function (newVal, oldVal) {
      if (this.stage === 1) {
        this.$refs.email.classList.remove("wrong");
        if (newVal.length < 1) {
          return;
        }
        const re = new RegExp(
          "^[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\\.]?[0-9a-zA-Z])*\\.[a-zA-Z]{2,4}$",
          "i"
        );
        if (!re.test(newVal)) {
          const refs = this.$refs;
          refs.emailErrorMessage.innerText = this.$t(
            "page.signUp.emailError.format"
          );
          refs.email.classList.add("wrong");
          return refs.email.children[1].focus();
        }
      }
    },
    mobileNumber: function (newVal, oldVal) {
      const re = new RegExp("[^0-9\\-]", "g");
      if (re.test(newVal)) {
        this.mobileNumber = newVal.replace(re, "");
      }
    },
    selectedWorkplace: function (newVal, oldVal) {
      if (newVal === "Neofect") {
        // this.selectedClinic = "";
        this.clinicCode = "";
      }
      // select -> input 수정
      // else {
      //   if (oldVal === "Neofect" && newVal === "Other Clinic") {
      //     this.selectedClinic = this.clinicList[1];
      //   }
      // }
    }
  },
  async created() {
    if (this.$route.params.email) {
      const token = this.$route.fullPath.split("?token=")[1];
      if (token === undefined) {
        return this.$router.push("/signup");
      }
      this.email = this.$route.params.email;
      this.stage = 2;
      this.token = token || "";
      try {
        const statesList = await getUSAStatesList();
        this.statesList = statesList;
      } catch (error) {
        this.handleError(error, "Signup > Get USA States List API");
      }
      // 클리닉리스트 삭제 21.02.02
      // try {
      //   const clinicList = await fetchGetClinicList();
      //   this.clinicList = clinicList;
      // } catch (error) {
      //   this.handleError(error, "Signup > Get Clinic List API");
      // }
    }
  },
  methods: {
    onlyNumber(event) {
      const re = new RegExp("[^0-9\\-]");
      if (event.keyCode === 8 || event.keyCode === 46) {
        return;
      } else {
        if (re.test(event.key)) {
          // event.stopPropagation();
          event.preventDefault();
          return;
        }
      }
    },
    passwordHidden(what) {
      if (what === "default") {
        this.passwordType = "password";
      } else {
        this.confirmPasswordType = "password";
      }
    },
    passwordShow(what) {
      if (what === "default") {
        this.passwordType = "text";
      } else {
        this.confirmPasswordType = "text";
      }
    },
    validatePassword(password) {
      if (!password) {
        return true;
      }

      const regExpContinueWord = /(\w)\1\1/;
      const regExpIsDigit = /[0-9]/g;
      const regExpIsUpperCase = /[A-Z]/g;
      const regExpIslowerCase = /[a-z]/g;
      const regExpIsSpecial = /[!@#$%^&*?]/g;

      let chkWordKind = 0;

      if (regExpIsDigit.test(password)) chkWordKind++;
      if (regExpIsUpperCase.test(password)) chkWordKind++;
      if (regExpIslowerCase.test(password)) chkWordKind++;
      if (regExpIsSpecial.test(password)) chkWordKind++;

      let resBoolean = true;
      // 길이가 10개 이하이거나 20개 이사일 때 false
      if (password.length < 10 || password.length > 20) {
        this.passwordWrongMessage = this.$t(
          "page.signUp.inputInfo.password.error.length"
        );
        resBoolean = false;
        // 문자의 길이가 3개이상 연속되면 안됨!!!
      } else if (regExpContinueWord.test(password)) {
        this.passwordWrongMessage = this.$t(
          "page.signUp.inputInfo.password.error.continuous"
        );
        resBoolean = false;
      } else if (chkWordKind < 2) {
        this.passwordWrongMessage = this.$t(
          "page.signUp.inputInfo.password.error.twoTypes"
        );
        resBoolean = false;
      }

      return resBoolean;
    },
    // validation ClinicCode 추가. 21.02.02
    validateClinicCode(code) {
      if (!code) return true;
      const regExpIsClincCode = /^(?=.*[A-Z])(?=.*\d)[A-Z\d]/g;
      let resBoolean = true;

      if (!regExpIsClincCode.test(code)) {
        this.clinicCodeWrongMessage = this.$t(
          "common.validation.clinicCodeWrong"
        );
        resBoolean = false;
      } else if (code.length !== 6) {
        this.clinicCodeWrongMessage = this.$t(
          "common.validation.clinicCodeLength"
        );
        resBoolean = false;
      }

      return resBoolean;
    },
    async resendVerifyEmail() {
      // 이메일 인증 이메일 다시 보내기
      try {
        const email = this.email;
        const uuid = `${Math.floor(Math.random() * 1000000 + 1000000)}`; // 7자리의 uid 랜덤생성
        const result = fetchVerifyEmail(email, uuid, "THERAPIST");
        if (result) {
          this.resend = true;
        }
      } catch (error) {
        this.handleError(error, "Signup > Resend Verify Email API");
      }
    },
    async handleSubmit() {
      if (this.submitDisable) {
        return;
      }
      if (this.stage === 1) {
        try {
          const email = this.email;
          if (email.length < 1) {
            return;
          }
          const uuid = `${Math.floor(Math.random() * 1000000 + 1000000)}`; // 7자리의 uid 랜덤생성
          const result = await fetchVerifyEmail(email, uuid, "THERAPIST");
          if (result) {
            this.sentEmail = true;
          }
        } catch (error) {
          this.sentEmail = false;
          if (error.data.code === "already.exist") {
            const refs = this.$refs;
            refs.emailErrorMessage.innerText = this.$t(
              "page.signUp.emailError.already"
            );
            refs.email.classList.add("wrong");
            return refs.email.children[1].focus();
          } else {
            this.handleError(error, "Signup > Send Verify Email API");
          }
        }
      } else if (this.stage === 2) {
        try {
          let clinicInfo;
          if (this.selectedWorkplace !== "Neofect") {
            clinicInfo = await fetchGetClinicByClinicCode(this.clinicCode);
          }
          const selectedClinic =
            this.selectedWorkplace === "Neofect" ? -1 : clinicInfo.id;
          const therapistInfo = {
            name: this.fullName,
            country: this.selectedCountry.countryValue,
            states: this.checkedStates,
            gender: this.selectedGender.toUpperCase(),
            mobile: this.mobileNumber,
            token: this.token,
            type: this.selectedTherapistType,
            email: this.email,
            password: this.password,
            zone_offset: -new Date().getTimezoneOffset(),
            clinic_req_id: selectedClinic
          };
          const result = await fetchSignup(therapistInfo);
          if (result) {
            this.stage = 3;
          }
        } catch (error) {
          this.handleError(error, "Signup > Sign up Request API");
        }
      }
    },
    handleCreateLicenseStatesPopupClose() {
      this.licenseStatesPopup = false;
    },
    showPopup() {
      this.handleCreateLicenseStatesPopupClose();
      this.licenseStatesPopup = true;
    },
    setCheckedStates(states) {
      this.checkedStates = states;
    },
    setSelectedStates(text) {
      this.showText = text;
    }
  }
};
</script>

<style lang="scss" scoped>
.Signup {
  max-width: 350px;
  width: 100%;
  margin: auto;
  margin-top: 130px;
  color: $fontColor1;
  h1 {
    font-weight: 800;
    font-size: $fontSize1;
    letter-spacing: -0.4px;
    line-height: 45px;
  }
  label {
    font-size: $fontSize7;
  }
  input[type="text"] {
    margin-top: 8px;
  }
  .notice {
    margin-top: 45px;
    font-size: $fontSize5;
    color: $fontColor2;
    line-height: 24px;
    button {
      text-decoration: underline;
      font-size: $fontSize5;
    }
  }
  .message {
    margin-top: 4px;
    display: none;
    color: $uiColor1;
    background: rgba(244, 67, 54, 0.25);
    padding: 4px 16px;
    font-size: $fontSize6;
  }
  .verification-email {
    margin-top: 56px;
    margin-bottom: -18px;
    font-size: $fontSize2;
    line-height: 34px;
    word-break: break-all;
  }
  .email {
    margin-top: 24px;
    &.wrong {
      input[type="password"],
      input[type="text"] {
        border-color: $uiColor1;
      }
      .message {
        display: inline-block;
      }
    }
  }
  .full-name {
    margin-top: 60px;
  }
  .country {
    margin-top: 24px;
    .v-select {
      margin-top: 8px;
    }
  }
  .states {
    margin-top: 24px;
    position: relative;

    .states-information {
      display: flex;
      align-items: center;
      .icon-ic_info_circle {
        padding-left: 3px;
        color: $fontColor2;
        font-size: 16px;
        cursor: pointer;
        &:hover {
          color: $fontColor1;
          & + .notice-information {
            display: block;
          }
        }
      }
      .notice-information {
        display: none;
        position: absolute;
        width: 236px;
        top: 2px;
        left: 71px;
        color: $fontColor2;
        background: #ffffff;
        padding: 12px 16px;
        font-size: $fontSize7;
        line-height: 21px;
        z-index: 2;
        box-sizing: border-box;
        border-radius: 2px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
        border: solid 1px #dcdfe6;
      }
    }
    .v-select {
      margin-top: 8px;
    }
  }
  .gender {
    margin-top: 24px;
    .gender-radio {
      display: flex;
      margin-top: 16px;
      span {
        margin-left: 32px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
  .mobile {
    margin-top: 24px;
  }
  .therapist-type {
    margin-top: 24px;
    .therapist-type-radio {
      display: flex;
      margin-top: 16px;
      span {
        margin-left: 32px;
        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
  .work-place {
    &.wrong {
      input[type="text"] {
        border-color: $uiColor1;
      }
      .message {
        display: inline-block;
      }
    }
    margin-top: 24px;
    .work-place-radio {
      margin-top: 16px;
      display: flex;
      span {
        margin-left: 32px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
    ::v-deep .v-select {
      margin-top: 16px;
    }
    &.request-sent {
      ::v-deep .v-select {
        &.vs--disabled {
          .vs__dropdown-toggle,
          .vs__clear,
          .vs__search,
          .vs__selected,
          .vs__open-indicator,
          .vs__dropdown-toggle {
            border-color: #fdeae4;
            background: #fdeae4;
            color: $fontColor2;
          }
        }
      }
      .request-sent-message {
        margin-top: 16px;
        font-size: $fontSize6;
        color: $fontColor2;
        button {
          text-decoration: underline;
        }
      }
    }
  }
  hr {
    margin: 48px 0;
  }
  .password {
    margin-top: 24px;
    position: relative;
    label {
      font-size: $fontSize7;
    }
    input[type="password"],
    input[type="text"] {
      margin-top: 8px;
    }
    &.wrong {
      input[type="password"],
      input[type="text"] {
        border-color: $uiColor1;
      }
      .message {
        display: inline-block;
      }
    }
    .password-show {
      position: absolute;
      top: 29px;
      right: 12px;
    }
  }
  .confirm-password {
    margin-top: 24px;
    position: relative;
    label {
      font-size: $fontSize7;
    }
    input[type="password"],
    input[type="text"] {
      margin-top: 8px;
    }
    &.wrong {
      input[type="password"],
      input[type="text"] {
        border-color: $uiColor1;
      }
      .message {
        display: inline-block;
      }
    }
    .password-show {
      position: absolute;
      top: 29px;
      right: 12px;
    }
  }
  .login-btn {
    margin-top: 48px;
  }
  .aside-btns {
    margin-top: 40px;
    display: flex;
    a {
      text-decoration: none;
      color: $fontColor2;
      font-size: $fontSize6;
    }
  }
}
</style>
