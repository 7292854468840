<template>
<div class="homework">
  <div class="homework-date">
    <span class="range">{{ startToTodayRange }}</span>
  </div>
  <div class="homework-top">
    <span class="title">{{ $t("page.patient.homework.title") }}</span>
    <Select
      class="sort-select"
      v-model="selectedSortType"
      :options="sortTypes"
      label="name"
      :components="{ OpenIndicator, Deselect }"
      id="sortTypeSelect"
    />
  </div>
  <div class="homework-list">
    <div class="header">
      <div class="list">
        <span class="left first">{{ $t("page.patient.homework.table.header.date") }}</span>
        <span class="left">{{ $t("page.patient.homework.table.header.exercises") }}</span>
        <span>{{ $t("page.patient.homework.table.header.completion") }}</span>
        <span class="left">{{ $t("page.patient.homework.table.header.time") }}</span>
      </div>
    </div>
    <div class="contents" v-if="homeworkList.length > 0">
      <div class="list" v-for="homework in homeworkList">
        <div class="item first vertical-left">
          <span>{{ showDate(homework.date) }}</span>
        </div>
        <div class="item vertical-left">
          <span v-for="mission in homework.missions">{{ mission.title }}</span>
        </div>
        <div class="item">
          <span v-for="mission in homework.missions">{{ mission.completed | trueAndFalse}}</span>
        </div>
        <div class="item vertical-left">
          <span v-for="mission in homework.missions">
            {{ mission.completed == true ? showTime(mission.updateTime) : "00:00" }}
          </span>
        </div>
      </div>
    </div>
    <div class="no-contents" v-else>
      <span>{{ $t("page.patient.homework.table.contents.empty") }}</span>
    </div>
  </div>
</div>
</template>

<script>
import Select from "vue-select";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import { fetchGetHistoryHomeworkList } from "@/api/connect";
import { mapGetters } from "vuex";

export default {
  name: "Homework",
  components: {
    Select
  },
  mixins: [SelectMixin, ErrorMixin],
  data() {
    return {
      selectedSortType: {name: this.$t("page.patient.homework.selectOption.asc"), value: "ASC"},
      sortTypes: [
        {name: this.$t("page.patient.homework.selectOption.asc"), value: "ASC"},
        {name: this.$t("page.patient.homework.selectOption.desc"), value: "DESC"}
      ],
      homeworkList: []
    }
  },
  computed: {
    ...mapGetters({
      selectedPatient: "patient/selectedPatient"
    }),
    startToTodayRange: function () {
      if (this.homeworkList.length === 0)
        return `${this.$moment().format("YYYY-MM-DD")} ~ ${this.$moment().format("YYYY-MM-DD")}`;
      const oldDate = this.homeworkList.reduce( (previous, current) => {
        return this.$moment(previous.date).isBefore(current.date) ? previous : current;
      })
      return `${this.$moment(oldDate.date).format("YYYY-MM-DD")} ~ ${this.$moment().format("YYYY-MM-DD")}`;
    }
  },
  watch: {
    selectedSortType: function () {
      this.loadHistory();
    },
    selectedPatient: function () {
      this.loadHistory();
    }
  },
  methods: {
    showDate: function (date) {
      return this.$moment(date).format("M.DD (ddd)");
    },
    showTime: function (date) {
      const localTime = this.$moment.utc(date).toDate();
      return this.$moment(localTime).format("A hh:mm");
    },
    loadHistory: function () {
      fetchGetHistoryHomeworkList(
        this.selectedPatient.id,
        this.selectedSortType.value,
        "2020-11-01", // 홈웍 기능이 만들어진 달 부터...
        this.$moment().format("YYYY-MM-DD")
      )
        .then(result=>{
          this.homeworkList = result
        });
    }
  },
  filters: {
    trueAndFalse: function (value) {
      if (value) return 'Y';
      return 'N';
    }
  },
  created() {
    if (this.selectedPatient) {
      this.loadHistory();
    }
  }
}
</script>

<style lang="scss" scoped>
  .homework {
    padding: 24px 24px 47px;
    display: grid;
    grid-template-rows: 36px 36px auto;
    .homework-date {
      display: flex;
      .range {
        background-color: #f4f5f7;
        width: 244px;
        font-size: 14px;
        color: #303133;
        text-align: center;
        line-height: 36px;
        border-radius: 21px;
      }
    }
    .homework-top {
      display: flex;
      .title {
        display: block;
        font-size: 16px;
        color: #303133;
        padding-top: 20px;
      }
      .sort-select {
        width: 166px;
        margin-left: auto;
      }
    }
    .homework-list {
      display: grid;
      margin-top: 16px;
      border: #d0d4dc 1px solid;
      border-radius: 8px;
      grid-template-rows: 56px;
      overflow: auto;
      .header {
        background-color: #f6f7fa;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        color: #606266;
        .list {
          display: grid;
          grid-template-rows: 56px;
          grid-template-columns: 206px auto 300px 200px;
          span {
            display: flex;
            align-items: center;
            justify-content: center;
            &.left {
              justify-content: left;
            }
            &.first {
              padding-left: 32px;
            }
          }
        }
      }
      .contents {
        overflow: auto;
        .list {
          display: grid;
          grid-template-columns: 206px auto 300px 200px;
          border: black 1px solid;
          border-color: #d0d4dc transparent transparent transparent;
          .item {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: left;
            font-size: 14px;
            line-height: 22px;
            color: #303133;
            &.horizontal-top {
              align-items: flex-start;
            }
            &.vertical-left {
              align-items: flex-start;
            }
            &.center {
              justify-content: center;
            }
            &.first {
              padding-left: 32px;
            }
            span {
              padding-top: 11px;
              padding-bottom: 11px;
            }
          }
        }
      }
      .no-contents {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>
