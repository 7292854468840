<template>
  <section
    class="MissionDetail"
    :style="{ top: `${coordinates.y}px`, left: `${coordinates.x}px` }"
  >
    <h1>{{ $t("page.patient.calendar.head.detail.type.dailyMission.title") }}</h1>
    <div class="mission-detail-date">
      {{ $moment(missionDetailInfo.date).format("MMMM D, YYYY") }}
    </div>
    <div class="mission-games">
      <ul>
        <li v-for="(mission, index) of missionDetailInfo.missions" :key="index">
          <span
            class="icon-ic_check_circle_fill"
            :class="mission.completed ? 'complete' : ''"
          >
            <span class="path1" />
            <span class="path2" />
          </span>
          <span class="title">
            <span style="font-weight: 600">{{ mission.type | missionTypeFilter}}</span>
            {{ mission.title }}</span>
        </li>
      </ul>
    </div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button type="button" class="close-btn icon-btn" @click="handleClose">
        <span class="icon-ic_delete">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "MissionDetail",
  props: {
    missionDetailInfo: {
      missions: Array,
      date: String,
      coordinates: Object,
      calendarSize: Object
    },
    detailPopupClose: Function
  },
  data() {
    return {
      coordinates: {
        x: 0,
        y: 0
      }
    };
  },
  filters: {
    missionTypeFilter: function(val) {
      if (val==='BASICS') {
        return '[B]'
      }
      if (val==='VIDEO') {
        return '[V]'
      }
      if (val==='READING') {
        return '[R]'
      }
      if (val==='ACTIVITY') {
        return '[A]'
      }
      if (val==='QUIZ') {
        return '[Q]'
      }
      return val;
    }
  },
  watch: {
    missionDetailInfo: function(newVal, oldVal) {
      if (
        newVal &&
        oldVal &&
        JSON.stringify(newVal.coordinates) !==
          JSON.stringify(oldVal.coordinates)
      ) {
        this.calculatePosition();
      }
    }
  },
  methods: {
    calculatePosition() {
      let calendarSize = this.missionDetailInfo.calendarSize;
      let thisPopupSize = {
        width: this.$el.offsetWidth,
        height: this.$el.offsetHeight
      };
      let calculateX =
        this.missionDetailInfo.coordinates.x +
          thisPopupSize.width +
          this.missionDetailInfo.coordinates.flexible +
          4 <
        calendarSize.width
          ? this.missionDetailInfo.coordinates.x +
            this.missionDetailInfo.coordinates.flexible +
            4
          : this.missionDetailInfo.coordinates.x - thisPopupSize.width - 4;
      let calculateY = this.missionDetailInfo.coordinates.y;
      this.coordinates = {
        x: calculateX,
        y: calculateY
      };
    },
    handleClose() {
      this.detailPopupClose();
    }
  },
  mounted() {
    this.calculatePosition();
  }
};
</script>

<style lang="scss" scoped>
.MissionDetail {
  width: $modalWidth1;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #d0d4dc;
  padding: 50px 24px 40px 24px;
  box-sizing: border-box;
  h1 {
    font-size: $fontSize2;
    color: $fontColor1;
    font-weight: 500;
  }
  .mission-detail-date {
    margin-top: 18px;
    font-size: $fontSize6;
    color: $fontColor2;
  }
  .mission-games {
    margin-top: 32px;
    font-size: $fontSize6;
    color: $fontColor2;
    line-height: 24px;
    li {
      display: flex;
      align-items: center;

      & + li {
        margin-top: 4px;
      }
      .icon-ic_check_circle_fill {
        font-size: 16px;
        color: $uiColor4;
        margin-right: 8px;
        &.complete {
          color: $uiColor3;
        }
      }
      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: normal;
        width: 100%;
      }
    }
  }
}
</style>
