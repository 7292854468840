var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ClinicMenu" }, [
    _c("p", { staticClass: "home-btn" }, [
      _vm.clinic.id === -1
        ? _c("span", [
            _c("img", {
              attrs: {
                src: require("@/assets/imgs/neofect.png"),
                alt: _vm.clinic.name + " symbol"
              }
            })
          ])
        : _c("span", [
            _c("img", {
              attrs: {
                src: require("@/assets/imgs/rehabcare.png"),
                alt: _vm.clinic.name + " symbol"
              }
            })
          ]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.clinic.name))])
    ]),
    _vm._v(" "),
    _c("dl", { staticClass: "clinic-menu" }, [
      _c("dt", [_vm._v(_vm._s(_vm.$t("components.clinicMenu.request")))]),
      _vm._v(" "),
      _c(
        "dd",
        [
          _c("router-link", { attrs: { to: "/clinic/request/patient" } }, [
            _vm._v(_vm._s(_vm.$t("components.clinicMenu.patient")))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "dd",
        [
          _c("router-link", { attrs: { to: "/clinic/request/therapist" } }, [
            _vm._v(_vm._s(_vm.$t("components.clinicMenu.provider")))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("dt", [_vm._v(_vm._s(_vm.$t("components.clinicMenu.management")))]),
      _vm._v(" "),
      _c(
        "dd",
        [
          _c("router-link", { attrs: { to: "/clinic/management/patient" } }, [
            _vm._v(_vm._s(_vm.$t("components.clinicMenu.patientList")))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "dd",
        [
          _c("router-link", { attrs: { to: "/clinic/management/therapist" } }, [
            _vm._v(_vm._s(_vm.$t("components.clinicMenu.providerList")))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "dd",
        [
          _c("router-link", { attrs: { to: "/clinic/mapping" } }, [
            _vm._v(_vm._s(_vm.$t("components.clinicMenu.mapping")))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.level.includes("Owner")
        ? _c("dt", [_vm._v(_vm._s(_vm.$t("components.clinicMenu.setting")))])
        : _vm._e(),
      _vm._v(" "),
      _vm.level.includes("Owner")
        ? _c(
            "dd",
            [
              _c("router-link", { attrs: { to: "/clinic/roleassign" } }, [
                _vm._v(_vm._s(_vm.$t("components.clinicMenu.roleAssign")))
              ])
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }