<template>
  <section class="Notification">
    <div class="contents">
      <div class="notification-header">
        <h1>{{ $t("page.calendar.notifications.title") }}</h1>
        <div class="secondary-type-btns small-btns read-all-btn">
          <button type="button" @click="handleReadAll">{{ $t("page.calendar.notifications.readAllButton") }}</button>
        </div>
      </div>
      <div class="notice-list">
        <ul v-if="noticeList" class="custom-scrollbar" @scroll="handleScroll">
          <li
            v-for="(notice, index) of noticeList"
            :key="index"
            :class="!notice.read ? 'unread' : ''"
          >
            <button @click="handleRead(notice.id, notice.eventId)">
              <span class="icon-ic_bell">
                <span class="path1" />
                <span class="path2" />
                <span class="path3" />
              </span>
              <div class="notice-info">
                <span class="title">{{ notice.title }}</span>
                <!-- <span class="time">{{ notice.date }}</span> -->
                <!--                <span class="time">{{ $moment(notice.date).fromNow() }}</span>-->
                <span class="time">{{ handleFromNow(notice.date) }}</span>
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button type="button" class="close-btn" @click="closeNotification">
        <span class="icon-ic_delete ic-icon">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
  </section>
</template>
<script>
/* eslint-disable */
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import {
  fetchGetPushHistory,
  fetchReadPushHistory,
  fetchReadAllPushHistory
} from "@/api/calendar";
import { mapGetters } from "vuex";

export default {
  name: "Notification",
  mixins: [ErrorMixin],
  props: {
    handleCloseNotification: Function
  },
  data() {
    return {
      page: 1,
      size: 5,
      totalPage: 1,
      totalCount: 0,
      noticeList: []
    };
  },
  computed: {
    ...mapGetters({
      lang: "lang/language"
    })
  },
  methods: {
    handleFromNow(date) {
      if (this.lang === 'ko') {
        require('moment/locale/ko.js');
        return this.$moment(date).locale('ko').fromNow();
      }
      require('moment/locale/en-gb.js');
      return this.$moment(date).local().fromNow();
    },
    closeNotification() {
      this.handleCloseNotification();
    },
    async getNotificationList() {
      // push history 가져오기
      try {
        const result = await fetchGetPushHistory(this.page, this.size);

        if (result) {
          const newList = result.contents.map(notice => {
            const oldNotice = JSON.parse(notice.data.json);
            const newNotice = {
              read: notice.is_read,
              title: oldNotice.title,
              date: notice.created,
              eventId: oldNotice.schedule_id,
              id: notice.data.history_id
            };
            // console.log(newNotice);
            return newNotice;
          });
          this.noticeList = this.noticeList.concat(newList);
          this.totalCount = result.page_info.total_count;
          this.totalPage = result.page_info.total_page;
        }
      } catch (error) {
        this.handleError(error, "Notification > Get Push History API");
      }
    },
    async handleReadAll() {
      try {
        await fetchReadAllPushHistory();
        this.noticeList = this.noticeList.map(notice => {
          notice.read = true;
          return notice;
        });
        this.$store.dispatch("notification/readNotification");
      } catch (error) {
        this.handleError(error, "Notification > Read All Push History API");
      }
    },
    async handleRead(pushId, eventId) {
      try {
        await fetchReadPushHistory(pushId);
        this.noticeList = this.noticeList.map(notice => {
          if (notice.id === pushId) {
            notice.read = true;
          }
          return notice;
        });
        let unMark = true;
        this.noticeList.forEach(notice => {
          if (notice.read === false) {
            return (unMark = false);
          }
        });
        if (unMark) this.$store.dispatch("notification/readNotification");
        if (eventId) {
          await this.$router.push(`/calendar/${eventId}`);
          this.closeNotification();
        }
      } catch (error) {
        this.handleError(error, "Notification > Read Single Push History API");
      }
    },
    handleScroll(e) {
      const {
        target: { scrollTop, clientHeight, scrollHeight }
      } = e;
      if (scrollTop + clientHeight >= scrollHeight) {
        // lazyload, scrollbottom 발생시 advice list가져오기
        // console.log("bottom", this.page);
        if (this.page === this.totalPage) return;
        this.page += 1;
        this.getNotificationList();
      }
    }
  },
  created() {
    this.getNotificationList();
  }
};
</script>

<style lang="scss" scoped>
.Notification {
  width: 300px;
  position: absolute;
  top: 56px;
  right: 245px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  z-index: 4;
  border-radius: 0.5rem;
  background: #ffffff;
  box-sizing: border-box;
  padding-top: 24px;
  color: $fontColor1;
  .contents {
    .notification-header {
      padding: 0 24px;
      h1 {
        font-size: $fontSize5;
        color: $fontColor1;
        font-weight: 500;
      }
      .read-all-btn {
        margin-top: 16px;
      }
    }
    .notice-list {
      margin-top: 16px;
      ul {
        max-height: 321px;
        overflow-y: auto;
        li {
          border-top: 1px solid $borderColor1;
          border-bottom: 1px solid $borderColor1;
          & + li {
            border-top: 0 none;
          }
          &.unread {
            button {
              background: rgba(240, 115, 77, 0.1);
              .icon-ic_bell {
                color: $mainColor1;
              }
            }
          }
          button {
            padding: 16px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            text-align: left;
            .icon-ic_bell {
              color: $mainColor1;
              position: relative;
              top: 6px;
            }
            .notice-info {
              margin-left: 12px;
              .title {
                color: $fontColor1;
                font-size: $fontSize6;
              }
              .time {
                display: block;
                font-size: $fontSize7;
                color: $fontColor3;
              }
            }
          }
        }
      }
    }
  }
}
</style>
