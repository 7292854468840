var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "ResetPassword" }, [
    _c(
      "form",
      {
        ref: "signupForm",
        on: {
          submit: function($event) {
            $event.preventDefault()
          }
        }
      },
      [
        _c("div", { staticClass: "contents" }, [
          _c("h1", [
            _vm._v(_vm._s(_vm.$t("page.profile.resetPassword.title")))
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "password",
              class: _vm.passwordWrong ? "wrong" : ""
            },
            [
              _c("label", { attrs: { for: "password" } }, [
                _vm._v(_vm._s(_vm.$t("page.profile.resetPassword.current")))
              ]),
              _vm._v(" "),
              _vm.passwordType === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password"
                      }
                    ],
                    attrs: {
                      id: "password",
                      placeholder: _vm.inputCurrentPlaceholder,
                      autocomplete: "off",
                      type: "checkbox"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.password)
                        ? _vm._i(_vm.password, null) > -1
                        : _vm.password
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.password,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.password = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.password = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.password = $$c
                        }
                      }
                    }
                  })
                : _vm.passwordType === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password"
                      }
                    ],
                    attrs: {
                      id: "password",
                      placeholder: _vm.inputCurrentPlaceholder,
                      autocomplete: "off",
                      type: "radio"
                    },
                    domProps: { checked: _vm._q(_vm.password, null) },
                    on: {
                      change: function($event) {
                        _vm.password = null
                      }
                    }
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password"
                      }
                    ],
                    attrs: {
                      id: "password",
                      placeholder: _vm.inputCurrentPlaceholder,
                      autocomplete: "off",
                      type: _vm.passwordType
                    },
                    domProps: { value: _vm.password },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.password = $event.target.value
                      }
                    }
                  }),
              _vm._v(" "),
              _vm.passwordType === "text"
                ? _c(
                    "button",
                    {
                      staticClass: "password-show",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.passwordHidden("default")
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/ic-eye-hide.png"),
                          alt: "eye-hide-image"
                        }
                      })
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "password-show",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.passwordShow("default")
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/ic-eye-show.png"),
                          alt: "eye-show-image"
                        }
                      })
                    ]
                  ),
              _vm._v(" "),
              _c("span", { staticClass: "message" }, [
                _vm._v(
                  _vm._s(_vm.$t("page.profile.resetPassword.error.incorrect"))
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "new-password",
              class: _vm.passwordCheck ? "" : "wrong"
            },
            [
              _c("label", { attrs: { for: "newPassword" } }, [
                _vm._v(_vm._s(_vm.$t("page.profile.resetPassword.new")))
              ]),
              _vm._v(" "),
              _vm.newPasswordType === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newPassword,
                        expression: "newPassword"
                      }
                    ],
                    attrs: {
                      id: "newPassword",
                      placeholder: _vm.inputNewPlaceholder,
                      autocomplete: "off",
                      type: "checkbox"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.newPassword)
                        ? _vm._i(_vm.newPassword, null) > -1
                        : _vm.newPassword
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.newPassword,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.newPassword = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.newPassword = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.newPassword = $$c
                        }
                      }
                    }
                  })
                : _vm.newPasswordType === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newPassword,
                        expression: "newPassword"
                      }
                    ],
                    attrs: {
                      id: "newPassword",
                      placeholder: _vm.inputNewPlaceholder,
                      autocomplete: "off",
                      type: "radio"
                    },
                    domProps: { checked: _vm._q(_vm.newPassword, null) },
                    on: {
                      change: function($event) {
                        _vm.newPassword = null
                      }
                    }
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newPassword,
                        expression: "newPassword"
                      }
                    ],
                    attrs: {
                      id: "newPassword",
                      placeholder: _vm.inputNewPlaceholder,
                      autocomplete: "off",
                      type: _vm.newPasswordType
                    },
                    domProps: { value: _vm.newPassword },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.newPassword = $event.target.value
                      }
                    }
                  }),
              _vm._v(" "),
              _vm.newPasswordType === "text"
                ? _c(
                    "button",
                    {
                      staticClass: "password-show",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.passwordHidden("new")
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/ic-eye-hide.png"),
                          alt: "eye-hide-image"
                        }
                      })
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "password-show",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.passwordShow("new")
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/ic-eye-show.png"),
                          alt: "eye-show-image"
                        }
                      })
                    ]
                  ),
              _vm._v(" "),
              _c("span", { staticClass: "message" }, [
                _vm._v(_vm._s(_vm.newPasswordWrongMessage))
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "confirm-password",
              class: _vm.confirmPasswordCheck ? "" : "wrong"
            },
            [
              _c("label", { attrs: { for: "confirmPassword" } }, [
                _vm._v(_vm._s(_vm.$t("page.profile.resetPassword.confirm")))
              ]),
              _vm._v(" "),
              _vm.confirmPasswordType === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.confirmPassword,
                        expression: "confirmPassword"
                      }
                    ],
                    attrs: {
                      id: "confirmPassword",
                      placeholder: _vm.inputConfirmPlaceholder,
                      autocomplete: "off",
                      type: "checkbox"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.confirmPassword)
                        ? _vm._i(_vm.confirmPassword, null) > -1
                        : _vm.confirmPassword
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.confirmPassword,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.confirmPassword = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.confirmPassword = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.confirmPassword = $$c
                        }
                      }
                    }
                  })
                : _vm.confirmPasswordType === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.confirmPassword,
                        expression: "confirmPassword"
                      }
                    ],
                    attrs: {
                      id: "confirmPassword",
                      placeholder: _vm.inputConfirmPlaceholder,
                      autocomplete: "off",
                      type: "radio"
                    },
                    domProps: { checked: _vm._q(_vm.confirmPassword, null) },
                    on: {
                      change: function($event) {
                        _vm.confirmPassword = null
                      }
                    }
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.confirmPassword,
                        expression: "confirmPassword"
                      }
                    ],
                    attrs: {
                      id: "confirmPassword",
                      placeholder: _vm.inputConfirmPlaceholder,
                      autocomplete: "off",
                      type: _vm.confirmPasswordType
                    },
                    domProps: { value: _vm.confirmPassword },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.confirmPassword = $event.target.value
                      }
                    }
                  }),
              _vm._v(" "),
              _vm.confirmPasswordType === "text"
                ? _c(
                    "button",
                    {
                      staticClass: "password-show",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.passwordHidden("confirm")
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/ic-eye-hide.png"),
                          alt: "eye-hide-image"
                        }
                      })
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "password-show",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.passwordShow("confirm")
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/imgs/ic-eye-show.png"),
                          alt: "eye-show-image"
                        }
                      })
                    ]
                  ),
              _vm._v(" "),
              _c("span", { staticClass: "message" }, [
                _vm._v(
                  _vm._s(_vm.$t("page.profile.resetPassword.error.notMatch"))
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "popup-submit-btns",
            class: _vm.submitDisable ? "disabled" : ""
          },
          [
            _c(
              "button",
              {
                staticClass: "accept-btn",
                attrs: { type: "button" },
                on: { click: _vm.handleSubmit }
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("page.profile.resetPassword.button.reset"))
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              { attrs: { type: "button" }, on: { click: _vm.handleClose } },
              [
                _vm._v(
                  _vm._s(_vm.$t("page.profile.resetPassword.button.cancel"))
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "popup-btns" }, [
          _c(
            "button",
            {
              staticClass: "close-btn icon-btn",
              attrs: { type: "button" },
              on: { click: _vm.handleClose }
            },
            [_vm._m(0)]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }