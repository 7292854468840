module.exports = {

  title: "Neofect Clinician portal",

  /**
   * @description Login Configuration
   */
  secretClientId: process.env.VUE_APP_SECRET_CLIENT_ID,
  secretResponseType: process.env.VUE_APP_SECRET_RESPONSE_TYPE,
  secretGrantType: process.env.VUE_APP_SECRET_GRANT_TYPE,
  secretScope: process.env.VUE_APP_SECRET_SCOPE,
  sendBirdAppId: process.env.VUE_APP_SENDBIRD_APP_ID,
  sendBirdProfile: process.env.VUE_APP_SENDBIRD_PROFILE,
  messagingSenderId: process.env.VUE_APP_FCM_SENDER_ID
};
