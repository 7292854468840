<template>
  <div class="RepeatSetting">
    <section>
      <div class="contents">
        <h1>{{ $t("page.patient.session.recurrence.homeworkRecurrence") }}</h1>
        <div class="checkbox-section">
          <input id="notRepeat" type="checkbox" v-model="isNotRepeat" />
          <label for="notRepeat">{{
            $t("page.patient.session.recurrence.doesNotRepeat")
          }}</label>
        </div>
        <div class="repeat-set-section">
          <label for="daysRepeat" class="sub-title">{{
            `${$t("page.patient.session.recurrence.repeat")}:`
          }}</label>
          <div class="select-wrap">
            <span>{{ $t("page.patient.session.recurrence.every") }}</span>
            <input
              id="daysRepeat"
              v-model="daysRepeat"
              name="daysRepeat"
              type="number"
              :disabled="isNotRepeat || selectedRepeatType === 'Week'"
              @keydown="onlyNumber"
              @input="updateValue"
            />
            <Select
              v-model="selectedRepeatType"
              class="date-select"
              :options="repeatTypes"
              label="name"
              :disabled="isNotRepeat"
            />
          </div>
        </div>
        <div v-if="selectedRepeatType === 'Week'" class="select-week-days-wrap">
          <label for="" class="sub-title">{{
            `${$t("page.patient.session.recurrence.repeatOn")}:`
          }}</label>
          <div class="days-btns">
            <ul>
              <li v-for="(day, index) of days" :key="index">
                <input
                  :id="'day' + index"
                  v-model="checkedDays"
                  type="checkbox"
                  :value="day.value"
                  :disabled="isNotRepeat"
                />
                <label :for="'day' + index">{{ day.label }}</label>
              </li>
            </ul>
          </div>
        </div>
        <div class="end-section">
          <label for="repeatCount" class="sub-title">{{
            `${$t("page.patient.session.recurrence.ends")}:`
          }}</label>
          <div class="input-wrap">
            <span>{{ $t("page.patient.session.recurrence.after") }}</span>
            <input
              id="repeatCount"
              v-model="repeatCount"
              name="repeatCount"
              type="number"
              :disabled="isNotRepeat"
              @keydown="onlyNumber"
              @input="updateValue"
            />
            <span class="last-span">{{
              $t("page.patient.session.recurrence.occurences")
            }}</span>
          </div>
        </div>
      </div>
      <div class="popup-submit-btns" :class="isDisabled ? 'disabled' : ''">
        <button
          type="button"
          class="accept-btn"
          @click="handleSave"
          :disabled="isDisabled"
        >
          {{ $t("page.patient.session.recurrence.save") }}
        </button>
      </div>
      <div class="popup-btns">
        <!-- 팝업 컨트롤 버튼 -->
        <button type="button" class="close-btn icon-btn" @click="handleClose">
          <span class="icon-ic_delete">
            <span class="path1" />
            <span class="path2" />
          </span>
        </button>
      </div>
    </section>
  </div>
</template>
<script>
import Select from "vue-select";
import { isEmpty } from "lodash";

export default {
  name: "RepeatSetting",
  components: {
    Select,
  },
  props: {
    lang: String,
    addHomeworkRepeat: Function,
    homeworkRepeat: Object,
  },
  data() {
    return {
      repeatTypes: ["Days", "Week"],
      days: [
        { label: this.$t("page.patient.session.recurrence.sun"), value: "sun" },
        { label: this.$t("page.patient.session.recurrence.mon"), value: "mon" },
        { label: this.$t("page.patient.session.recurrence.tue"), value: "tue" },
        { label: this.$t("page.patient.session.recurrence.wed"), value: "wed" },
        { label: this.$t("page.patient.session.recurrence.thu"), value: "thu" },
        { label: this.$t("page.patient.session.recurrence.fri"), value: "fri" },
        { label: this.$t("page.patient.session.recurrence.sat"), value: "sat" },
      ],
      message: "",
      isNotRepeat: true,
      selectedRepeatType: "Days",
      daysRepeat: "",
      checkedDays: [],
      repeatCount: "",
    };
  },
  methods: {
    updateValue(e) {
      const { name, value } = e.target;
      if (name === "daysRepeat") {
        if (value < 1) this.daysRepeat = "";
        if (value > 10) this.daysRepeat = 10;
      }
      if (name === "repeatCount") {
        if (value < 1) this.repeatCount = "";
        if (value > 10) this.repeatCount = 10;
      }
    },
    onlyNumber(event) {
      let re = new RegExp("[^0-9\\-]");
      if (event.keyCode === 8 || event.keyCode === 46) {
        return;
      } else {
        if (re.test(event.key)) {
          // event.stopPropagation();
          event.preventDefault();
          return;
        }
      }
    },
    handleClose() {
      this.$emit("close");
    },
    handleSave() {
      this.messageSetter();
      const req = {
        isNotRepeat: this.isNotRepeat,
        message: this.message,
        selectedRepeatType: this.selectedRepeatType,
        daysRepeat: Number(this.daysRepeat),
        checkedDays: this.checkedDays,
        repeatCount: Number(this.repeatCount),
      };

      this.addHomeworkRepeat(req);
      this.handleClose();
    },
    // 메시지 세팅...
    messageSetter() {
      let msg;

      if (this.isNotRepeat) {
        if (this.lang === "ko") msg = "반복 안 함";
        if (this.lang === "en") msg = "Does not repeat";
      } else {
        // 요일 배열 소팅 [월 => 일 순서로]
        const daySorter = {
          mon: 1,
          tue: 2,
          wed: 3,
          thu: 4,
          fri: 5,
          sat: 6,
          sun: 7,
        };

        this.checkedDays = this.checkedDays.sort((a, b) => {
          return daySorter[a] - daySorter[b];
        });
        // 요일 배열 번역
        const checkedDaysTranslation = this.checkedDays.map((day) => {
          let letter;
          if (this.lang === "ko") {
            letter = `${this.$t(`page.patient.session.recurrence.${day}`)}요일`;
          } else {
            letter = `${this.$t(`page.patient.session.recurrence.${day}`)}`;
          }
          return letter;
        });
        if (this.lang === "ko") {
          if (this.selectedRepeatType === "Days") {
            msg = `${this.daysRepeat}일 마다, ${this.repeatCount}회 반복`;
          } else {
            if (this.checkedDays.length === 7) {
              msg = `매일, ${this.repeatCount}회 반복`;
            } else {
              msg = `매주 ${checkedDaysTranslation.toString()}, ${
                this.repeatCount
              }회 반복`;
            }
          }
        } else {
          if (this.selectedRepeatType === "Days") {
            msg = `Every ${this.daysRepeat} days, ${this.repeatCount} times`;
          } else {
            if (this.checkedDays.length === 7) {
              msg = `Daily, ${this.repeatCount} times`;
            } else {
              msg = `Weekly on ${checkedDaysTranslation.toString()}, ${
                this.repeatCount
              } times`;
            }
          }
        }
      }

      this.message = msg;
    },
  },
  watch: {
    isNotRepeat(newVal, oldVal) {
      if (newVal) {
        this.selectedRepeatType = "Days";
        this.daysRepeat = "";
        this.repeatCount = "";
        this.checkedDays = [];
      } else {
        this.daysRepeat = 3;
        this.repeatCount = 3;
      }
    },
    selectedRepeatType(newVal, oldVal) {
      if (!this.isNotRepeat) {
        if (newVal === "Week") {
          this.daysRepeat = 1;
        }
        if (newVal === "Days") {
          this.daysRepeat = 3;
          this.checkedDays = [];
        }
      }
    },
  },
  computed: {
    isDisabled: function () {
      let error = false;
      // 반복 안함 체크 해제시
      if (!this.isNotRepeat) {
        // 일별 반복
        if (this.selectedRepeatType === "Days") {
          // 반복 횟수가 없을 경우 disabled
          if (!this.daysRepeat || !this.repeatCount) error = true;
        }
        // 주별 반복
        if (this.selectedRepeatType === "Week") {
          // 요일 선택 혹은 반복 횟수가 없을 경우 disabeld
          if (isEmpty(this.checkedDays) || !this.repeatCount) {
            error = true;
          }
        }
      }

      return error;
    },
  },
  created() {
    this.isNotRepeat = this.homeworkRepeat.isNotRepeat;
    this.selectedRepeatType = this.homeworkRepeat.selectedRepeatType;
    this.daysRepeat = this.homeworkRepeat.daysRepeat;
    this.checkedDays = this.homeworkRepeat.checkedDays;
    this.repeatCount = this.homeworkRepeat.repeatCount;
  },
};
</script>
<style lang="scss" scoped>
.RepeatSetting {
  .contents {
    padding: 50px 24px 44px;
    font-family: Manrope;

    h1 {
      font-weight: bold;
      font-size: $fontSize2;
      line-height: 32px;
      color: $fontColor1;
    }

    .checkbox-section {
      margin-top: 40px;
      width: 100%;

      label {
        width: auto;
        font-weight: 600;
        font-size: $fontSize6;
        line-height: 20px;
        color: $fontColor1;
      }
    }

    .repeat-set-section {
      margin-top: 40px;

      .sub-title {
        display: block;
        color: $fontColor2;
        font-size: $fontSize7;
        line-height: 20px;
      }

      .select-wrap {
        margin-top: 12px;
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          color: $fontColor1;
          font-size: $fontSize6;
          width: 44px;
        }

        input {
          display: inline-block;
          text-align: center;
          margin-left: 12px;
          width: 64px;
          height: 36px;
          border: 1px solid #d0d4dc;
          box-sizing: border-box;
          border-radius: 2px;
        }

        .date-select {
          margin-left: 9px;
          width: 120px;
          height: 36px;

          .vs__dropdown-toggle {
            height: 100%;
          }
        }
      }
    }

    .select-week-days-wrap {
      margin-top: 40px;

      .sub-title {
        display: block;
        color: $fontColor2;
        font-size: $fontSize7;
        line-height: 20px;
      }

      .days-btns {
        margin-top: 12px;

        ul {
          display: flex;
          li {
            & + li {
              margin-left: 8px;
            }
          }
          input[type="checkbox"] + label {
            position: relative;
            width: 48px;
            height: 48px;
            color: $fontColor2;
            font-size: $fontSize7;
            border: 1px dashed $borderColor1;
            border-radius: 50%;
            justify-content: center;
            &::before {
              display: none;
            }
          }
          input[type="checkbox"]:checked + label {
            color: $mainColor1;
            border: 1px solid $mainColor1;
            background: rgba(240, 115, 77, 0.08);
          }
        }
      }
    }

    .end-section {
      margin-top: 44px;

      .sub-title {
        display: block;
        color: $fontColor2;
        font-size: $fontSize7;
        line-height: 20px;
      }

      .input-wrap {
        display: flex;
        align-items: center;

        span:nth-child(2) {
          margin-left: 17px;
        }

        span {
          display: inline-block;
          font-family: Manrope;
          font-size: $fontSize6;
          line-height: 20px;
          color: #303133;
          width: 44px;
        }

        .last-span {
          width: auto;
          margin-left: 17px;
        }

        input {
          display: inline-block;
          text-align: center;
          margin-left: 12px;
          width: 64px;
          height: 36px;
          border: 1px solid #d0d4dc;
          box-sizing: border-box;
          border-radius: 2px;
        }
      }
    }
  }

  .popup-submit-btns {
    height: 88px;
  }
}
</style>