var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "Session" }, [
    _c("h1", [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "device-select" },
      [
        _c("label", { attrs: { for: "#deviceSelect" } }, [
          _vm._v(_vm._s(_vm.$t("page.patient.session.detail.selectDevice")))
        ]),
        _vm._v(" "),
        _c("Select", {
          attrs: {
            options: _vm.selectedPatientDevice,
            label: "printName",
            components: {
              OpenIndicator: _vm.OpenIndicator,
              Deselect: _vm.Deselect
            },
            disabled: _vm.sessionPopup.edit,
            id: "deviceSelect"
          },
          model: {
            value: _vm.selectedDevice,
            callback: function($$v) {
              _vm.selectedDevice = $$v
            },
            expression: "selectedDevice"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "contents" }, [
      _c(
        "div",
        { staticClass: "game-list" },
        [
          _c("Select", {
            attrs: {
              options: _vm.motionTypes,
              label: "name",
              components: {
                OpenIndicator: _vm.OpenIndicator,
                Deselect: _vm.Deselect
              },
              id: "deviceSelect"
            },
            model: {
              value: _vm.selectedMotionType,
              callback: function($$v) {
                _vm.selectedMotionType = $$v
              },
              expression: "selectedMotionType"
            }
          }),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "custom-scrollbar" },
            _vm._l(_vm.sortedGames, function(game, index) {
              return _c("li", { key: index }, [
                _c("div", { staticClass: "game-image" }, [
                  _c("img", { attrs: { src: game.thumbnail, alt: game.title } })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "game-info" }, [
                  _c("div", [
                    _c(
                      "span",
                      { staticClass: "game-feature" },
                      _vm._l(game.features, function(feature, index) {
                        return _c("span", { key: index }, [
                          _vm._v(_vm._s(feature))
                        ])
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "game-title" }, [
                      _vm._v(_vm._s(game.title))
                    ]),
                    _vm._v(" "),
                    _vm.selectedDevice.name !== "SMART_BOARD"
                      ? _c("span", { staticClass: "game-motion" }, [
                          _vm._v(_vm._s(_vm.displayMotionFunc(game.motions)))
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "game-select secondary-type-btns small-btns",
                    class: _vm.selectedGames.length > 7 ? "disabled" : ""
                  },
                  [
                    _c(
                      "button",
                      {
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.selectGame(game)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("page.patient.session.detail.select"))
                        )
                      ]
                    )
                  ]
                )
              ])
            }),
            0
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "selected-games-list custom-scrollbar" }, [
        _c("div", { staticClass: "auto-select primary-type-btns small-btns" }, [
          _c(
            "button",
            { attrs: { type: "button" }, on: { click: _vm.autoCreateGames } },
            [
              _vm._v(
                _vm._s(_vm.$t("page.patient.session.detail.autoCreateButton"))
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "game-list-wrapper" }, [
          _c(
            "ul",
            { staticClass: "selected-games" },
            _vm._l(_vm.selectedGames, function(game, index) {
              return _c("li", { key: index }, [
                _c("div", { staticClass: "game-info" }, [
                  _c("span", { staticClass: "game-image" }, [
                    _c("img", {
                      attrs: { src: game.thumbnail, alt: game.title }
                    })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "game-title" }, [
                    _vm._v(_vm._s(game.title))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "game-delete" }, [
                    _c("button", { attrs: { type: "button" } }, [
                      _c(
                        "span",
                        {
                          staticClass: "icon-ic_delete_circle",
                          on: {
                            click: function($event) {
                              return _vm.deleteGame(game)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "path1" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "path2" }),
                          _vm._v(" "),
                          _c("span", { staticClass: "path3" })
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "game-level" }, [
                  _c(
                    "button",
                    {
                      class: game.level === game.min_level ? "disabled" : "",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.minusLevel(index, game.min_level)
                        }
                      }
                    },
                    [_vm._m(0, true)]
                  ),
                  _vm._v(
                    "\n              " +
                      _vm._s("Level " + game.level) +
                      "\n              "
                  ),
                  _c(
                    "button",
                    {
                      class: game.level === game.max_level ? "disabled" : "",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.plusLevel(index, game.max_level)
                        }
                      }
                    },
                    [_vm._m(1, true)]
                  )
                ])
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "empty-list" },
            _vm._l(_vm.emptyList, function(empty, index) {
              return _c("li", { key: index }, [_vm._m(2, true)])
            }),
            0
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "popup-submit-btns",
        class: _vm.selectedGames.length < 1 ? "disabled" : "selectedGames"
      },
      [
        _c(
          "button",
          {
            staticClass: "accept-btn",
            attrs: { type: "button" },
            on: {
              "~click": function($event) {
                return _vm.handleSubmit($event)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("page.patient.session.saveButton")))]
        ),
        _vm._v(" "),
        _c(
          "button",
          { attrs: { type: "button" }, on: { click: _vm.handleClose } },
          [_vm._v(_vm._s(_vm.$t("page.patient.session.cancelButton")))]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "popup-btns" }, [
      _c(
        "button",
        {
          staticClass: "close-btn icon-btn",
          attrs: { type: "button" },
          on: { click: _vm.handleClose }
        },
        [_vm._m(3)]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_minus ic-icon" }, [
      _c("span", { staticClass: "path1" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_add ic-icon" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_add" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }