<template>
  <section class="RequestTherapist">
    <div class="request-top">
      <div class="request-search">
        <div class="search-select">
          <Select
            v-model="selectedSortingType"
            :options="sortingOption"
            label="label"
            placeholder
            :components="{ OpenIndicator, Deselect }"
          />
        </div>
        <div class="search">
          <span class="icon-ic_search" @click="searchTherapist">
            <span class="path1" />
            <span class="path2" />
          </span>
          <input v-model="searchText" type="text" @keypress.enter="searchTherapist">
        </div>
      </div>
      <div
        class="control-btns default-type-btns small-btns"
        :class="checkedList.length < 1 ? 'disabled' : ''"
      >
        <button type="button" @click="multipleManipulation(1)">{{ $t("adminPage.request.provider.button.approve") }}</button>
        <button type="button" @click="multipleManipulation(2)">{{ $t("adminPage.request.provider.button.deny") }}</button>
      </div>
    </div>
    <table>
      <colgroup>
        <col width="50px">
        <col width="60px">
        <col width="140px">
        <col width="240px">
        <col width="60px">
        <col width="160px">
        <col width="130px">
        <col width="200px">
      </colgroup>
      <thead>
        <tr>
          <th>
            <div class="check-all">
              <button :class="checkAll" @click="handleAllCheck" />
            </div>
          </th>
          <th>No.</th>
          <th class="align-left">{{ $t("adminPage.request.provider.list.header.name") }}</th>
          <th class="align-left">{{ $t("adminPage.request.provider.list.header.email") }}</th>
          <th>{{ $t("adminPage.request.provider.list.header.type") }}</th>
          <th>{{ $t("adminPage.request.provider.list.header.requestedTime") }}</th>
          <th>{{ $t("adminPage.request.provider.list.header.status") }}</th>
          <th />
        </tr>
      </thead>
    </table>
    <div class="custom-scrollbar table-scroll">
      <table>
        <colgroup>
          <col width="50px">
          <col width="60px">
          <col width="140px">
          <col width="240px">
          <col width="60px">
          <col width="160px">
          <col width="130px">
          <col width="200px">
        </colgroup>
        <tbody v-if="list.length > 0">
          <tr
            v-for="(item) of list"
            :key="item.index"
            :class="checkedList.indexOf(item.index) > -1 ? 'checked' : ''"
          >
            <th>
              <span v-if="item.status < 1">
                <input :id="item.index" v-model="checkedList" type="checkbox" :value="item.index">
                <label :for="item.index" />
              </span>
              <span v-else>
                <input
                  :id="item.id"
                  v-model="checkedList"
                  type="checkbox"
                  :value="item.id"
                  disabled
                >
                <label :for="item.id" />
              </span>
            </th>
            <td>{{ item.index }}</td>
            <td class="align-left">
              {{
                !item.name ? "-" : item.name.includes("null") ? "-" : item.name
              }}
            </td>
            <td class="align-left">{{ item.email }}</td>
            <td>{{ item.therapist_type }}</td>
            <td>{{ $moment(item.requested_time).format("YYYY-MM-DD HH:mm") }}</td>
            <td
              :class="
                item.status === 2
                  ? 'denied'
                  : item.status === 1
                    ? 'approved'
                    : ''
              "
            >
              {{
                item.status === 0
                  ? $t("adminPage.request.provider.list.statusType.requested")
                  : item.status === 1
                    ? $t("adminPage.request.provider.list.statusType.approved")
                    : item.status === 2
                      ? $t("adminPage.request.provider.list.statusType.denied")
                      : item.status === 3
                        ? $t("adminPage.request.provider.list.statusType.cancel")
                        : ""
              }}
            </td>
            <td class="align-right">
              <span v-if="item.status < 1" class="default-type-btns small-btns">
                <button type="button" @click="singleManipulation(item, 1)">{{ $t("adminPage.request.provider.list.button.approve") }}</button>
                <button type="button" @click="singleManipulation(item, 2)">{{ $t("adminPage.request.provider.list.button.deny") }}</button>
              </span>
              <span v-else class="delete-btn icon-btn">
                <button type="button" @click="handleDelete(item)">
                  <span class="icon-ic_bin">
                    <span class="path1" />
                    <span class="path2" />
                    <span class="path3" />
                    <span class="path4" />
                    <span class="path5" />
                  </span>
                </button>
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="9">{{ $t("adminPage.request.provider.list.empty") }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <v-pagination v-model="currentPage" :page-count="totalPage" />
  </section>
</template>
<script>
import Confirm from "@/components/Confirm";
import vPagination from "vue-plain-pagination";
import Select from "vue-select";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import {
  fetchGetTherapistRequest,
  fetchApproveTherapistRequest,
  fetchDenyTherapistRequest,
  fetchDeleteTherapistRequest
} from "@/api/clinic.js";
import { mapGetters } from "vuex";
export default {
  name: "RequestTherapist",
  components: {
    vPagination,
    Select
  },
  mixins: [SelectMixin, ErrorMixin],
  data() {
    return {
      currentPage: 1,
      size: 15,
      totalCount: 1,
      totalPage: 1,
      checkedList: [],
      searchText: "",
      sortingOption: [
        {
          label: this.$t("adminPage.request.provider.selectOption.all"),
          value: "ALL"
        },
        {
          label: this.$t("adminPage.request.provider.selectOption.name"),
          value: "NAME"
        },
        {
          label: this.$t("adminPage.request.provider.selectOption.email"),
          value: "EMAIL"
        }
      ],
      selectedSortingType: {
        label: this.$t("adminPage.request.provider.selectOption.all"),
        value: "ALL"
      },
      list: [],
      deleteTarget: ""
    };
  },
  computed: {
    availableCheckList: function() {
      return this.list.reduce((acc, cur) => {
        if (cur.status < 1) {
          acc.push(cur);
        }
        return acc;
      }, []);
    },
    checkAll: function() {
      const allCounts = this.availableCheckList.length;
      const checkedCounts = this.checkedList.length;
      return checkedCounts === allCounts
        ? "all all-checkbox"
        : checkedCounts > 0
          ? "checked all-checkbox"
          : "all-checkbox";
    },
    ...mapGetters({
      clinic: "user/clinic"
    })
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchTherapist();
      }
    }
  },
  created() {
    this.searchTherapist();
  },
  methods: {
    async searchTherapist() {
      try {
        const search = {
          page: this.currentPage,
          size: this.size,
          keyword: this.searchText,
          search_type: this.selectedSortingType.value,
          sorts: [{ field: "time", order: "desc" }]
        };
        const result = await fetchGetTherapistRequest(this.clinic.id, search);
        this.totalCount = result.total_count < 1 ? 1 : result.total_count;
        this.totalPage = result.total_page < 1 ? 1 : result.total_page;
        const addIndex = result.list.map((item, index) => {
          item.index =
            result.total_count - index - (this.currentPage - 1) * this.size;
          return item;
        });

        this.list = addIndex;
      } catch (error) {
        this.handleError(error, "RequestTherapist > Get Therapist Request API");
      }
    },
    handleAllCheck() {
      const newChecked = [];
      if (
        this.checkedList.length === 0 ||
        this.checkedList.length !== this.availableCheckList.length
      ) {
        this.availableCheckList.forEach((item, index) => {
          newChecked.push(item.index);
        });
      }
      this.checkedList = newChecked;
    },
    handleDelete(target) {
      this.deleteTarget = target;
      this.$modal.show(
        Confirm,
        {
          type: "delete",
          submit: this.deleteSubmit,
          confirm: {
            title: this.$t("adminPage.request.provider.deleteModal.title"),
            message: this.$t("adminPage.request.provider.deleteModal.message")
          }
        },
        {
          height: "auto"
        }
      );
    },
    async deleteSubmit() {
      try {
        const clinicId = this.clinic.id;
        const deleteTarget = this.deleteTarget;
        const result = await fetchDeleteTherapistRequest(
          clinicId,
          deleteTarget.req_id,
          deleteTarget.therapist_id
        );
        if (result) {
          this.checkedList = [];
          this.searchTherapist();
        }
      } catch (error) {
        this.handleError(
          error,
          "RequestTherapist > Delete Therapist Request API"
        );
      }
    },
    async singleManipulation(request, order) {
      try {
        const clinicId = this.clinic.id;
        const target = {
          user_id: request.id,
          target_id: request.therapist_id,
          req_id: request.req_id
        };
        let result;
        if (order === 1) {
          result = await fetchApproveTherapistRequest(clinicId, [target]);
        } else if (order === 2) {
          result = await fetchDenyTherapistRequest(clinicId, [target]);
        }
        if (result) {
          const afterList = this.list.map(item => {
            if (item.index === request.index) {
              item.status = order;
            }
            return item;
          });
          this.list = afterList;
          this.checkedList = this.checkedList.filter(checked => {
            return checked !== request.index;
          });
        }
      } catch (error) {
        this.handleError(
          error,
          "RequestTherapist > Single Therapist Manipulation API"
        );
      }
    },
    async multipleManipulation(order) {
      if (this.checkedList.length < 1) {
        return;
      }
      try {
        const clinicId = this.clinic.id;
        const target = [];
        this.list.forEach(item => {
          if (this.checkedList.includes(item.index)) {
            const newOne = {
              user_id: item.id,
              target_id: item.therapist_id,
              req_id: item.req_id
            };
            target.push(newOne);
          }
        });
        let result;
        if (order === 1) {
          result = await fetchApproveTherapistRequest(clinicId, target);
        } else if (order === 2) {
          result = await fetchDenyTherapistRequest(clinicId, target);
        }
        if (result) {
          const afterList = this.list.map(item => {
            if (this.checkedList.includes(item.index)) {
              item.status = order;
            }
            return item;
          });
          this.list = afterList;
          this.checkedList = [];
        }
      } catch (error) {
        this.handleError(
          error,
          "RequestTherapist > Multi Therapist Manipulation Request API"
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.RequestTherapist {
  background: #ffffff;
  border: 1px solid $borderColor1;
  border-radius: 3px;
  .request-top {
    padding: 16px;
    display: flex;
    .request-search {
      display: flex;
      align-content: center;
      .search {
        padding: 0 16px;
        position: relative;
        .icon-ic_search {
          position: absolute;
          top: 50%;
          right: 32px;
          transform: translate(0, -50%);
          font-size: $fontSize2;
          color: $fontColor2;
        }
        input[type="text"] {
          padding-right: 55px;
          height: 35px;
          border-radius: 2px;
        }
      }
      .search-select {
        width: 110px;
        ::v-deep .v-select {
          background: #ffffff;
          .vs__dropdown-toggle {
            border-radius: 2px;
          }
          .vs__dropdown-menu {
            min-width: 110px;
          }
        }
      }
    }
    .control-btns {
      margin-left: auto;
      display: flex;
      justify-content: center;

      button {
        &:last-of-type {
          margin-left: 8px;
        }
      }
    }
  }
  table {
    tbody {
      tr:first-of-type {
        th,
        td {
          border-top: 0 none;
        }
      }
    }
  }
}
</style>
