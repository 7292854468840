<template>
  <div class="Calendar">
    <div class="calendar-area">
      <FullCalendar
        ref="fullCalendarPatient"
        :plugins="calendarConfig.calendarPlugins"
        default-view="timeGridWeek"
        :header="calendarConfig.header"
        :show-non-current-dates="calendarConfig.showNonCurrentDates"
        :fixed-week-count="calendarConfig.fixedWeekCount"
        :day-popover-format="calendarConfig.dayPopoverFormat"
        :title-format="calendarConfig.titleFormat"
        :first-day="calendarConfig.firstDay"
        :all-day-text="calendarConfig.allDayText"
        :button-text="calendarConfig.buttonText"
        :column-header-html="calendarConfig.columnHeaderHtml"
        :content-height="calendarConfig.contentHeight"
        :all-day-slot="calendarConfig.allDaySlot"
        :dates-render="handleDatesRender"
        :min-time="calendarConfig.minTime"
        :now="now"
        class="fullCalendar-patient"
        @eventClick="handleEventClick"
        @windowResize="handleWindowResize"
      />
    </div>
    <!-- 이벤트 상세팝업 -->
    <EventDetail
      v-if="eventDetailPopup.view"
      ref="detailPopup"
      :event-detail-info="eventDetailPopup"
      :deny-event="denyEvent"
      :start-screen-sharing="startScreenSharing"
      :start-video-call="startVideoCall"
      :edit-event="editEvent"
      :delete-event="deleteEvent"
      :handle-detail-popup-close="handleDetailPopupClose"
      :calendar-size="calendarSize"
    />
    <!-- 이벤트 수정팝업 -->
    <CreateEvent
      v-if="createEventPopup.view"
      :create-event-info="createEventPopup"
      :handle-error="handleError"
      :handle-create-event-popup-close="handleCreateEventPopupClose"
      :change-event-sources="changeEventSources"
    />
    <!-- 주별 미션리스트 -->
    <div class="mission-week-list">
      <WeeklyMission
        :mission-list="missionList"
        :handle-mission-detail-popup="handleMissionDetailPopup"
      />
    </div>
    <!-- 미션 상세팝업 -->
    <MissionDetail
      v-if="missionDetailPopup.view"
      :mission-detail-info="missionDetailPopup"
      :detail-popup-close="handleMissionDetailPopupClose"
    />
    <!-- 주별 홈웍 리스트 -->
    <div class="homework-week-list">
      <WeeklyHomework
        :homework-list="homeworkList"
        :handle-homework-detail="handleHomeworkClick"
      />
    </div>
    <!-- 주별 홈웍 상세팝업 -->
    <HomeworkDetail
      v-if="homeworkDetailPopup.view"
      :homework-detail-info="homeworkDetailPopup"
      :detail-popup-close="handleHomeworkDetailPopupClose"
      :delete-homework="deleteHomework"
      :edit-homework="handleEditHomeworkPopup"
    />
    <!-- 홈웍 수정팝업 -->
    <HomeworkSession
      v-if="homeworkPopup.view"
      :session-popup="homeworkPopup"
      :close-session-popup="handleCloseHomeworkPopup"
      :edit-session="editHomework"
      :repeat-disabled="true"
    />
    <!-- 주별 세션리스트 -->
    <div class="session-week-list">
      <WeeklySession
        :session-list="sessionList"
        :handle-session-type1click="handleSessionType1Click"
        :handle-session-type2click="handleSessionType2Click"
      />
    </div>
    <!-- 세션 상세팝업 -->
    <SessionDetail
      v-if="sessionDetailPopup.view"
      :session-detail-info="sessionDetailPopup"
      :detail-popup-close="handleSessionDetailPopupClose"
      :delete-session="deleteSession"
      :edit-session="handleEditSessionPopup"
    />
    <!-- 세션 수정팝업 -->
    <Session
      v-if="sessionPopup.view"
      :session-popup="sessionPopup"
      :close-session-popup="handleCloseSessionPopup"
      :edit-session="editSession"
    />
  </div>
</template>
<script>
import EventDetail from "@/views/calendar/components/EventDetail";
import CreateEvent from "@/views/calendar/components/CreateEvent";
import WeeklySession from "./components/WeeklySession";
import SessionDetail from "./components/SessionDetail";
import MissionDetail from "./components/MissionDetail";
import HomeworkDetail from "./components/HomeworkDetail";
import WeeklyMission from "./components/WeeklyMission";
import WeeklyHomework from "./components/WeeklyHomework";
import Session from "@/views/patient/components/CreateSession/components/Session";
import HomeworkSession from "../CreateSession/components/HomeworkSession";
import Confirm from "@/components/Confirm";
/* full calendar library */
import FullCalendar from "@fullcalendar/vue";
/* full calendar default settings */
import CALENDAR_DEFAULT from "@/utils/calendar_default";
import {
  fetchWeekEvents,
  fetchGetWeeklySessions,
  fetchGetSessionDetail,
  fetchDeleteSession,
  fetchEditSession
} from "@/api/patient";
import {
  fetchDetailEventInfo,
  fetchDeleteEvent,
  fetchDenyEvent
} from "@/api/calendar.js";
import {
  fetchGetWeeklyMissions,
  fetchGetWeeklyHomeworkAssingInfo,
  fetchGetHomework,
  fetchDeleteHomework,
  fetchUpdateHomeworkV2
} from "@/api/connect";
import { fetchGetSessionDetailAfterBatch } from "@/api/home";
import { mapGetters } from "vuex";
export default {
  name: "Calendar",
  components: {
    FullCalendar,
    EventDetail,
    CreateEvent,
    WeeklySession,
    SessionDetail,
    HomeworkDetail,
    Session,
    HomeworkSession,
    WeeklyMission,
    WeeklyHomework,
    MissionDetail
  },
  data() {
    return {
      calendarConfig: CALENDAR_DEFAULT,
      eventDetailPopup: {
        view: false
      },
      createEventPopup: {
        view: false
      },
      sessionDetailPopup: {
        view: false
      },
      homeworkDetailPopup: {
        view: false
      },
      missionDetailPopup: {
        view: false
      },
      sessionPopup: {
        view: false
      },
      homeworkPopup: {
        view: false
      },
      calendarSize: {
        width: 0,
        height: 0
      },
      currentWeek: {},
      patientInfo: {},
      sessionList: ["", "", "", "", "", "", ""],
      missionList: ["", "", "", "", "", "", ""],
      homeworkList: ["", "", "", "", "", "", ""],
      now: new Date()
    };
  },
  watch: {
    currentWeek: function (newVal, oldVal) {
      if (this.patientInfo === undefined) return;
      this.getWeekEvents(
        newVal.year,
        newVal.month,
        newVal.monday,
        this.patientInfo.id,
        newVal.zoneOffset
      );
    },
    selectedPatient: function (newVal, oldVal) {
      this.patientInfo = newVal;
      this.getWeekEvents(
        this.currentWeek.year,
        this.currentWeek.month,
        this.currentWeek.monday,
        newVal.id,
        this.currentWeek.zoneOffset
      );
    }
  },
  created() {
    this.patientInfo = this.selectedPatient;
    this.$options.interval = setInterval(this.updateDateTime, 1000);
  },
  mounted() {
    this.calendarSize = {
      width: this.$refs.fullCalendarPatient.$el.offsetWidth,
      height: this.$refs.fullCalendarPatient.$el.offsetHeight
    };
  },
  beforeDestroy() {
    const calendarApi = this.$refs.fullCalendarPatient.getApi();
    calendarApi.destroy();
    clearInterval(this.$options.interval);
  },
  methods: {
    async startScreenSharing(eventId) {
      /*
      //// 스케쥴 일정에서 Screen Sharing 시작하기 ////
      */
      try {
        await this.$store.dispatch("screensharing/startInSchedule", eventId);
      } catch (error) {
        this.handleError(error);
      }
    },
    async startVideoCall(eventId) {
      /*
      //// 스케쥴 일정에서 Video Call 시작하기 ////
      */
      try {
        await this.$store.dispatch("videocall/startInSchedule", eventId);
      } catch (error) {
        this.handleError(error);
      }
    },
    handleSessionClick() {
      this.handleSessionDetailPopupClose();
      this.handleMissionDetailPopupClose();
      this.handleHomeworkDetailPopupClose();
      const targetParentWidth = event.target.offsetParent.offsetWidth;
      const coordinates = {
        x: event.target.offsetParent.offsetLeft,
        y: 240,
        flexible: targetParentWidth
      };
      // console.log(coordinates);
      return coordinates;
    },
    handleSessionType1Click(sessionId) {
      /*
      //// therapist 세션 Type1 상세보기 (배치 이전) ////
      */
      const coordinates = this.handleSessionClick();
      fetchGetSessionDetail(sessionId)
        .then((result) => {
          // console.log(result);
          const createNew = {
            view: true,
            ...result,
            coordinates,
            calendarSize: this.calendarSize,
            batch: false
          };
          this.sessionDetailPopup = createNew;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleSessionType2Click(sessionId, date) {
      /*
      //// therapist 세션 Type2 상세보기 (배치 이후) ////
      */
      const coordinates = this.handleSessionClick();
      fetchGetSessionDetailAfterBatch(sessionId)
        .then((result) => {
          // console.log(result);
          const games = result.missions.map((game) => {
            // component에 알맞은 형태로 init
            game.sessionNumber = sessionId;
            game.title = game.title ? game.title : "Game Title";
            let starCount = [false, false, false];
            game.successCount = game.successCount ? game.successCount : 0;
            game.starCount = game.starCount ? game.starCount : 0;
            starCount = starCount.map((item, index) => {
              if (index < game.starCount) item = true;
              return item;
            });
            game.starCount = starCount;
            return game;
          });
          const createNew = {
            view: true,
            coordinates,
            calendarSize: this.calendarSize,
            date,
            games,
            batch: true
          };
          this.sessionDetailPopup = createNew;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleSessionDetailPopupClose() {
      /*
      //// therapist 세션 상세보기 닫기 ////
      */
      this.sessionDetailPopup.view = false;
    },
    handleEditSessionPopup(session) {
      /*
      //// therapist 세션 수정팝업 열기 ////
      */
      this.sessionPopup = {
        ...session,
        view: true,
        edit: true
      };
    },
    handleCloseSessionPopup() {
      /*
      //// therapist 세션 수정팝업 닫기 ////
      */
      this.sessionPopup.view = false;
    },
    editSession(session) {
      /*
      //// therapist 세션 delete ////
      */
      const sessionId = this.sessionDetailPopup.id;
      const title = session.title;
      const games = session.games;
      fetchEditSession(sessionId, title, games)
        .then((result) => {
          // console.log(result);
          this.forceGetWeekEvents();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    deleteSession(sessionId) {
      /*
      //// therapist 세션 delete ////
      */
      fetchDeleteSession(sessionId)
        .then((result) => {
          this.forceGetWeekEvents();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    handleHomeworkDetailPopupClose() {
      this.homeworkDetailPopup.view = false;
    },
    // 홈워크 수정
    editHomework(homework, id) {
      const reqData = {
        // missionList: homework.missions.map((item) => {
        //   if (item.missionId === undefined) return item.id;
        //   return item.missionId;
        // }),
        missionList: homework.missions.map((item) => ({
          id: item.missionId || item.id,
          repeatCount: Number(item.setsCount)
        })),
        messages: homework.message
      };

      fetchUpdateHomeworkV2(id, reqData)
        .then((result) => {
          this.forceGetWeekEvents();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    deleteHomework(homeworkId) {
      fetchDeleteHomework(homeworkId)
        .then((result) => {
          console.log("homework delete result : ", result);
          this.forceGetWeekEvents();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleHomeworkClick(homeworkId) {
      this.handleSessionDetailPopupClose();
      this.handleMissionDetailPopupClose();
      this.handleHomeworkDetailPopupClose();
      const targetParentWidth = event.target.offsetParent.offsetWidth;
      const coordinates = {
        x: event.target.offsetParent.offsetLeft,
        y: 180,
        flexible: targetParentWidth
      };

      fetchGetHomework(homeworkId)
        .then((result) => {
          const createNew = {
            view: true,
            ...result,
            coordinates,
            calendarSize: this.calendarSize,
            batch: false
          };
          this.homeworkDetailPopup = createNew;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleCloseHomeworkPopup() {
      this.homeworkPopup.view = false;
    },
    handleEditHomeworkPopup(homework) {
      /*
      //// therapist 홈웍 수정팝업 열기 //// TODO : 210217
      */
      const _missions = homework.missions.map((mission) => ({
        id: mission.missionId,
        setsCount: mission.repeatCount,
        title: mission.title,
        image: mission.image || mission.thumbnail
      }));

      const _homework = {
        id: homework.id,
        messages: homework.messages,
        date: [homework.date],
        selectedDate: homework.date,
        missions: _missions
      };

      this.homeworkPopup = {
        ..._homework,
        view: true,
        edit: true
      };
    },
    handleEventClick(info) {
      /*
      //// Event 상세보기 ////
      */
      if (this.eventDetailPopup.view) {
        this.handleDetailPopupClose();
      }
      const id = info.event.id;
      const zoneOffset = this.currentWeek.zoneOffset;
      fetchDetailEventInfo(id, zoneOffset)
        .then((result) => {
          const createNew = {
            ...result,
            view: true,
            currentWeek: this.currentWeek,
            element: info.el,
            isPatient: true
          };
          this.eventDetailPopup = createNew;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleDetailPopupClose() {
      /*
      //// event 상세보기 닫기 ////
      */
      this.eventDetailPopup.view = false;
    },
    handleCreateEventPopupClose() {
      /*
      //// event 생성팝업 닫기 ////
      */
      this.createEventPopup.view = false;
    },
    handleWindowResize(info) {
      /*
      //// calendar resize 콜백 ////
      */
      this.handleMissionDetailPopupClose();
      this.handleSessionDetailPopupClose();
      this.handleDetailPopupClose();
      this.calendarSize = {
        width: this.$refs.fullCalendarPatient.$el.offsetWidth,
        height: this.$refs.fullCalendarPatient.$el.offsetHeight
      };
    },
    editEvent(info) {
      /*
      //// Event 수정하기 ////
      */
      this.handleDetailPopupClose();
      const allPatients = this.allPatients.map((patient) => {
        patient.attendance_state = "WAIT";
        return patient;
      });
      const patients = info.patients.map((patient) => {
        allPatients.forEach((currentPatient) => {
          if (patient.id === currentPatient.id) {
            patient.name = currentPatient.name;
            patient.zone_offset = currentPatient.zone_offset;
            return;
          }
        });
        return patient;
      });
      // console.log(patients);
      const editNew = {
        ...info,
        allPatients,
        patients,
        zone_offset: this.currentWeek.zoneOffset,
        method: "EDIT",
        view: true
      };
      this.createEventPopup = editNew;
    },
    deleteEvent(type, id) {
      /*
      //// delete event ////
      */
      fetchDeleteEvent(id)
        .then((result) => {
          this.handleDetailPopupClose();
          this.eventSources.forEach((source) => {
            if (source.className === type) {
              source.events = source.events.filter((event) => {
                return event.id !== id;
              });
            }
          });
          this.setEventSources(this.eventSources);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    denyEvent(type, id) {
      /*
      //// 스크린세션 요청거부 ////
      */
      const denyContents = {
        contents: ""
      };
      fetchDenyEvent(id, denyContents)
        .then((result) => {
          // console.log(result);
          this.handleDetailPopupClose();
          this.eventSources.forEach((source) => {
            if (source.className === type) {
              source.events = source.events.filter((event) => {
                return event.id !== id;
              });
            }
          });

          this.setEventSources(this.eventSources);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    changeEventSources(method, newSources, id) {
      /*
      //// change eventSources ////
      */

      this.eventSources = this.eventSources || [];
      const find = this.eventSources.findIndex((source) => {
        return source.className === newSources.className;
      });
      if (method === "EDIT") {
        // event를 edit
        this.eventSources[find].events = this.eventSources[find].events
          .filter((event) => {
            return event.id !== id;
          })
          .concat(newSources.events);
      } else if (method === "CONFIRM") {
        // Request confirm (스크린세션 요청수락)
        if (find < 0) {
          this.eventSources.push(newSources);
        } else {
          this.eventSources.forEach((source) => {
            if (source.className === newSources.className) {
              source.events = source.events.concat(newSources.events);
            }
          });
        }
        this.eventSources.forEach((source) => {
          if (source.className === "group6") {
            source.events = source.events.filter((event) => {
              return event.id !== id;
            });
          }
        });
      }
      this.setEventSources(this.eventSources);
    },
    setEventSources(sources) {
      /*
      //// calendar에 변경된 eventsource 프린트 ////
      */
      if (!this.$refs.fullCalendarPatient) {
        return;
      }
      const calendarApi = this.$refs.fullCalendarPatient.getApi();
      const getAllEventSource = calendarApi.getEventSources();
      for (const source of getAllEventSource) {
        source.remove();
      }
      for (const source of sources) {
        calendarApi.addEventSource(source);
      }
    },
    handleDatesRender(info) {
      /*
      //// calendar 렌더링될 때 ////
      */
      const getCurrentStart = new Date(info.view.currentStart);
      const year = getCurrentStart.getFullYear();
      const month = getCurrentStart.getMonth() + 1;
      const monday = getCurrentStart.getDate();
      const zoneOffset = -getCurrentStart.getTimezoneOffset();
      this.currentWeek = {
        year,
        month,
        monday,
        zoneOffset,
        weekStart: this.$moment(info.view.currentStart).format("YYYY-MM-DD"),
        weekEnd: this.$moment(info.view.currentEnd).format("YYYY-MM-DD")
      };
    },
    getWeekEvents(year, month, monday, patientId, zoneOffset) {
      /*
      //// 주별 eventSources Get ////
      */
      fetchWeekEvents(
        year,
        month,
        monday,
        patientId,
        zoneOffset,
        this.clinic.id
      )
        .then(
          // event 일정 가져오기
          (result) => {
            console.log("인치 : " + result);
            this.handleCreateEventPopupClose();
            this.handleDetailPopupClose();
            this.handleHomeworkDetailPopupClose();
            this.eventSources = result;
            this.setEventSources(this.eventSources);
          }
        )
        .catch((error) => {
          this.handleError(error);
        });
      fetchGetWeeklySessions(year, month, monday, patientId)
        .then((result) => {
          // session 일정 가져오기
          console.log("인치 : " + result);
          // console.log("sessionList", result);
          this.handleSessionDetailPopupClose();
          const newSessionList = ["", "", "", "", "", "", ""];
          if (result && Array.isArray(result)) {
            result.forEach((session) => {
              let index = new Date(session.date).getUTCDay() - 1;
              index = index === -1 ? 6 : index;
              if (newSessionList[index] === "") {
                newSessionList[index] = session;
              }
            });
          }
          this.sessionList = newSessionList;
        })
        .catch((error) => {
          this.handleError(error);
        });

      fetchGetWeeklyHomeworkAssingInfo(
        patientId,
        this.currentWeek.weekStart,
        this.currentWeek.weekEnd
      )
        .then((result) => {
          console.log("인치 : " + result);
          // homework 일정 가져오기
          // TODO : 홈웍 디테일이 모달이 떠있다면 닫아주자
          // this.handleSessionDetailPopupClose();
          const newHomeworkList = ["", "", "", "", "", "", ""];
          if (result && Array.isArray(result)) {
            result.forEach((homework) => {
              let index = new Date(homework.date).getUTCDay() - 1;
              index = index === -1 ? 6 : index;
              if (newHomeworkList[index] === "") {
                newHomeworkList[index] = homework;
              }
            });
          }
          this.homeworkList = newHomeworkList;
        })
        .catch((error) => {
          this.handleError(error);
        });
      fetchGetWeeklyMissions(
        patientId,
        this.currentWeek.weekStart,
        this.currentWeek.weekEnd
      )
        .then((result) => {
          console.log("인치 : " + result);
          // mission 일정 가져오기
          this.handleMissionDetailPopupClose();
          const newMissionList = ["", "", "", "", "", "", ""];
          if (result && result.length > 0) {
            // console.log(result);
            result.forEach((mission) => {
              let index = new Date(mission.date).getUTCDay() - 1;
              index = index === -1 ? 6 : index;
              mission.missionCount = mission.missions.length;
              mission.completedCount = mission.missions.filter(
                (item) => item.completed
              ).length;
              if (newMissionList[index] === "") {
                newMissionList[index] = mission;
              }
            });
          }
          this.missionList = newMissionList;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    handleMissionDetailPopup(date, missions) {
      /*
      //// therapist 미션 상세보기 ////
      */
      this.handleSessionDetailPopupClose();
      const targetParentWidth = event.target.offsetParent.offsetWidth;
      const coordinates = {
        x: event.target.offsetParent.offsetLeft,
        y: 135,
        flexible: targetParentWidth
      };
      this.missionDetailPopup = {
        view: true,
        missions,
        coordinates,
        date,
        calendarSize: this.calendarSize
      };
    },
    handleMissionDetailPopupClose() {
      /*
      //// therapist 미션 상세보기 닫기 ////
      */
      this.missionDetailPopup.view = false;
    },
    forceGetWeekEvents() {
      // createSession popup에서 강제로 호출하기 위한 함수
      this.getWeekEvents(
        this.currentWeek.year,
        this.currentWeek.month,
        this.currentWeek.monday,
        this.patientInfo.id,
        this.currentWeek.zoneOffset
      );
    },
    updateDateTime() {
      // fullcalendar today 업데이트
      const now = new Date();
      if (this.now.getDate() !== now.getDate()) {
        console.log("Start new Day.");
        this.now = now;
      }
    },
    handleError(error) {
      console.error("Patient Calendar", error);
      if (error) {
        this.$modal.show(
          Confirm,
          {
            type: "error",
            confirm: {
              title: "Error occurred",
              message: "Something is wrong with the server. Please try again."
            }
          },
          {
            height: "auto"
          }
        );
      }
    }
  },
  computed: {
    ...mapGetters({
      selectedPatient: "patient/selectedPatient",
      allPatients: "user/patients",
      clinic: "user/clinic"
    })
  }
};
</script>

<style lang="scss">
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";
.Calendar {
  font-size: $fontSize5;
  min-width: 1000px;
  .calendar-area {
    .fullCalendar-patient {
      position: relative;
      padding-bottom: 8px;
      color: $fontColor1;
      .fc-head {
        .fc-day-header {
          padding: 6px 0 190px 0;
          color: $fontColor3;
        }
      }
      .fc-axis {
        padding: 6px 12.5px;
        text-align: right;
        color: $fontColor2;
        font-size: $fontSize6;
        box-sizing: border-box;
        width: 65px !important;
        border: 0 none !important;
        &.fc-widget-header {
          position: relative;
          &::before {
            content: "";
            width: 100%;
            display: block;
            border-bottom: 1px solid #ffffff;
            position: absolute;
            bottom: -1px;
            left: 0;
          }
        }
        &::before {
          content: "";
          display: inline-block;
        }
      }
      .fc-widget-content {
        border-top: none;
      }
      /* 불필요하다고 생각해서 none처리함
      .fc-divider {
        // display: none;
      }
      */
      .fc-content-skeleton {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        td {
          border-color: rgba(255, 255, 255, 0) !important;
        }
      }

      .fc-scroller.fc-time-grid-container {
        overflow: visible !important;
      }
      .fc-header-toolbar {
        padding: 16px 0;
        background: #ffffff;
        &.fc-toolbar {
          margin: 0;
        }
        .fc-center {
          display: flex;
          h2 {
            width: 150px;
            margin: 0 21.4px;
            line-height: 24px;
            font-size: $fontSize4;
            text-align: center;
          }
        }
      }
      .fc-slats {
        tr:nth-of-type(odd) {
          td {
            border-bottom-style: dashed;
          }
        }
      }
      &.fc-unthemed {
        th,
        td {
          border-width: 0.6px;
          border-color: $borderColor1;
          &:first-of-type {
            border-left: 0 none;
          }
          &:last-of-type {
            border-right: 0 none;
          }
        }
        .fc-popover {
          .fc-header {
            background: white;
          }
        }
        .fc-disabled-day {
          background: rgba(255, 255, 255, 0);
        }
      }
      .fc-day-header {
        position: relative;
        &.fc-today {
          .day {
            color: $mainColor1;
          }
          .date {
            background: $mainColor1;
            color: #ffffff;
          }
        }
        .date {
          display: inline-block;
          font-size: $fontSize4;
          color: $fontColor1;
          width: 32px;
          height: 32px;
          line-height: 32px;
          border-radius: 50%;
        }
        .day {
          margin-top: 4px;
          display: block;
          font-size: $fontSize7;
          color: $fontColor2;
        }
      }
      .fc-day-top {
        position: relative;
        .fc-day-number {
          float: left;
          position: relative;
          padding: 20.5px 0 13.7px 0;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
      .fc-event-container {
        position: relative;
      }
      .fc-event {
        cursor: pointer;
        font-size: $fontSize6;
        margin: 0 4px;
        line-height: 24px;
        background: rgba(255, 255, 255, 0);
        color: $fontColor1;
        padding: 5px 8px;
        border: 0;
        color: #ffffff;
        position: absolute;
        &:hover {
          background: rgba(90, 90, 90, 0.1);
        }
        &.group1 {
          background: $groupColor1;
        }
        &.group2 {
          background: $groupColor2;
        }
        &.group3 {
          background: $groupColor3;
        }
        &.group4 {
          background: $groupColor4;
        }
        &.group5 {
          background: $groupColor5;
        }
        &.group6 {
          background: $groupColor6;
        }
        .fc-content {
          padding: 0;
          line-height: 20px;
          .fc-time {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: normal;
            box-sizing: border-box;
          }
          .fc-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: normal;
            box-sizing: border-box;
          }
        }
      }
      .fc-more-popover {
        width: 260px;
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
        overflow: hidden;
        border: none;
        box-sizing: border-box;
        .fc-header {
          .fc-title {
            margin: auto;
            font-weight: 500;
            font-size: $fontSize3;
            padding: 16px 0;
            color: $fontColor2;
          }
          .fc-close {
            position: absolute;
            top: 16px;
            right: 16px;
          }
          .fc-icon-x {
            font-size: $fontSize2;
            color: $fontColor1;
            &::before {
              content: "\e951";
            }
            &::after {
              content: "\e952";
              margin-left: -1em;
            }
          }
        }
        .fc-event-container {
          padding: 0 16px 24px 16px;
        }
      }
      .fc-today {
        background: rgba(255, 255, 255, 0);
        .fc-day-number {
          margin: 0.5rem 0;
          padding: 0;
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: $mainColor1;
          color: #ffffff;
          border-radius: 50%;
          box-sizing: border-box;
        }
      }
      .fc-button {
        padding: 0;
        border-radius: 6px;
        position: relative;
        box-sizing: border-box;
        &.fc-button-primary {
          background: rgba(255, 255, 255, 0);
          color: $fontColor1;
          border-color: $borderColor1;
        }
        &.fc-prev-button,
        &.fc-next-button {
          width: 24px;
          height: 24px;
          margin: 0;
          color: $fontColor2;
          background: rgba(244, 245, 247, 0.4);
        }
        .fc-icon {
          font-family: "icomoon" !important;
          font-size: $fontSize6;
          color: $fontColor2;
          &.fc-icon-chevron-left {
            position: relative;
            top: -3px;
            left: -1px;
            &:before {
              content: "\e93a";
            }
          }
          &.fc-icon-chevron-right {
            position: relative;
            top: -2px;
            left: 1px;
            &:before {
              content: "\e93f";
            }
          }
        }
        &.fc-today-button {
          width: 67.1px;
          margin-right: 20.9px;
          color: $fontColor2;
          font-size: $fontSize7;
          background: rgba(244, 245, 247, 0.4);
        }
      }

      .fc-popover.fc-more-popover {
        border-radius: 0.5rem;
      }
      .fc-more {
        position: absolute;
        transform: translate(50%, 40%);
        font-size: $fontSize6;
        color: $fontColor2;
      }
    }
  }
  .mission-week-list {
    position: absolute;
    top: 130px;

    width: 100%;
    z-index: 1;
    box-sizing: border-box;
  }
  .homework-week-list {
    position: absolute;
    top: 175px;

    width: 100%;
    z-index: 1;
    box-sizing: border-box;
  }
  .session-week-list {
    position: absolute;
    top: 235px;

    width: 100%;
    z-index: 1;
    box-sizing: border-box;
  }
}
</style>
