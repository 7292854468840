<template>
  <section class="MappingPatientList">
    <h1>{{ title }}</h1>
    <table>
      <colgroup>
        <col width="130px">
        <col width="130px">
        <col width="60px">
        <col width="60px">
      </colgroup>
      <thead>
        <tr>
          <th class="align-left">{{ $t("components.mappingPatientList.list.header.name") }}</th>
          <th class="align-left">{{ $t("components.mappingPatientList.list.header.email") }}</th>
          <th>{{ $t("components.mappingPatientList.list.header.age") }}</th>
          <th>{{ $t("components.mappingPatientList.list.header.gender") }}</th>
        </tr>
      </thead>
    </table>
    <div class="table-scroll custom-scrollbar">
      <table>
        <colgroup>
          <col width="130px">
          <col width="160px">
          <col width="60px">
          <col width="60px">
        </colgroup>
        <tbody v-if="patientList.length > 0">
          <tr v-for="(item) of patientList" :key="item.id">
            <td
              class="align-left"
            >{{ !item.name ? '-' : item.name.includes('null') ? '-' : item.name }}</td>
            <td class="align-left">{{ item.email }}</td>
            <td>{{ item.age }}</td>
            <td>{{ item.gender ? item.gender[0] : '-' }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4">Nothing to display</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button type="button" class="close-btn icon-btn" @click="handleClose">
        <span class="icon-ic_delete">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
  </section>
</template>
<script>
/* eslint-disable vue/require-default-prop */

import { fetchGetClinicTherapistPatientList } from "@/api/clinic.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import { mapGetters } from "vuex";
export default {
  name: "MappingPatientList",
  mixins: [ErrorMixin],
  props: {
    clinicId: Number,
    therapistName: String,
    therapistId: Number
  },
  data() {
    return {
      patientList: []
    };
  },
  computed: {
    ...mapGetters({
      lang: "lang/language"
    }),
    title() {
      if (this.lang === "ko") {
        return `${this.therapistName} ${this.$t("components.mappingPatientList.title")}`;
      }
      return `${this.$t("components.mappingPatientList.title")} ${this.therapistName}`;
    }
  },
  created() {
    fetchGetClinicTherapistPatientList(this.clinicId, this.therapistId)
      .then(result => {
        this.patientList = result.list;
      })
      .catch(error => {
        this.handleError(
          error,
          "MappingPatientList > Get Clinic Therapist PatientList API"
        );
      });
  },
  methods: {
    handleClose() {
      this.$emit("close");
    }
  }
};
</script>

<style lang='scss' scoped>
.MappingPatientList {
  padding: 50px 24px 24px 24px;
  h1 {
    font-size: $fontSize2;
    font-weight: 500;
    color: $fontColor1;
  }
  table {
    width: 100%;
    margin-top: 32px;

    .align-left {
      text-align: left;
      padding-left: 24px;
    }

    .align-right {
      text-align: right;
    }
    thead {
      th,
      td {
        padding: 18px 0;
        background: #f6f7fa;
        font-size: $fontSize7;
        color: $fontColor2;
        border-top: 1px solid $borderColor1;
        border-bottom: 1px solid $borderColor1;
        text-align: center;
      }
    }
    tbody {
      tr:first-of-type {
        th,
        td {
          border-top: 0 none;
        }
      }
      th,
      td {
        padding: 13px 0;
        color: $fontColor1;
        font-size: $fontSize7;
        border-top: 1px solid $borderColor1;
        border-bottom: 1px solid $borderColor1;
        text-align: center;
      }
    }
  }
  .table-scroll {
    max-height: 430px;
    overflow-y: auto;
    table {
      margin-top: 0;
    }
  }
}
</style>
