var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "patients-list" }, [
    _c("div", { staticClass: "search" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.keyword,
            expression: "keyword"
          }
        ],
        attrs: { type: "text", placeholder: _vm.searchPatientPlaceholder },
        domProps: { value: _vm.keyword },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.keyword = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "list" }, [
      _c(
        "ul",
        { staticClass: "custom-scrollbar" },
        _vm._l(_vm.filteredPatients, function(item) {
          return _c(
            "li",
            {
              key: item.user_id,
              class:
                item.user_id ===
                (!!_vm.selectedInfo && _vm.selectedInfo.patientId)
                  ? "on"
                  : "",
              on: {
                click: function($event) {
                  return _vm.enterGroupChannel({
                    patientId: item.user_id,
                    name: item.name,
                    image: item.image
                  })
                }
              }
            },
            [
              _c("button", { attrs: { type: "button" } }, [
                _c("div", { staticClass: "patient-image" }, [
                  _vm._m(1, true),
                  _vm._v(" "),
                  item.image !== null
                    ? _c("img", { attrs: { src: item.image } })
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "message-info" }, [
                  _c("span", { staticClass: "patient-name" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.name))
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(item.lastMessageCreatedAt))
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "message" }, [
                    _vm._v(_vm._s(item.lastMessage))
                  ])
                ]),
                _vm._v(" "),
                item.unreadMessageCount > 0
                  ? _c("div", { staticClass: "unread" }, [
                      _vm._v(_vm._s(item.unreadMessageCount))
                    ])
                  : _vm._e()
              ])
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_search" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_group_fill" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }