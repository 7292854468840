<template>
    <section class="report-section glove-sensor-report">
      <div class="report-contents">
        <Loading v-if="loading" />
        <div v-if="!isNoData" class="content-grid">
          <div class="content-grid-row">
            <div class="content-grid-row-item-category"/>
            <div class="content-grid-row-item-text"/>
            <div class="content-grid-row-item-sum header">Reps</div>
            <div class="content-grid-row-item-sum header">Deg</div>

          </div>
          <div class="content-grid-row" v-for="item in reportList">
              <div class="content-grid-row-item-category" :class="{under:item.underlineShow}"> <span v-show="item.categoryShow">{{item.category}}</span></div>
               <div class="content-grid-row-item-text">
                 <div>{{item.text}} </div>
                 <div class="content-grid-row-item-text gravity" v-if="item.gravityText">Gravity Eliminated</div>
               </div>
               <div class="content-grid-row-item-sum"> {{item.reps}} </div>
               <div class="content-grid-row-item-gap" v-if="isRangeCompare" v-bind:class="{minus: item.repsGap < 0, plus: item.repsGap > 0}">
                 <span class="arrow" v-bind:class="{down: item.repsGap < 0, up: item.repsGap > 0}"></span>
                 {{removeNumberMinusSign(item.repsGap)}} </div>
               <div class="content-grid-row-item-gap" v-else>
                 -
               </div>
              <div class="content-grid-row-item-sum"> {{item.averageRom}} </div>
              <div class="content-grid-row-item-gap" v-if="isRangeCompare" v-bind:class="{minus: item.averageRomGap < 0, plus: item.averageRomGap > 0}">
                <span class="arrow" v-bind:class="{down: item.averageRomGap < 0, up: item.averageRomGap > 0}"></span>
                {{removeNumberMinusSign(item.averageRomGap)}} </div>
              <div class="content-grid-row-item-gap" v-else>
                -
              </div>
          </div>
        </div>
        <div v-if="isNoData" class="no-data">{{ $t("page.patient.neofectHome.data.empty") }}</div>
        <div v-if="error" class="error">Error!</div>
      </div>
    </section>
</template>

<script>
  import { getTrainedSensorSummary } from "@/api/report.js";
  import Loading from "@/components/Loading";
    export default {
      name: "GloveTrainingReport",
      components: {
          Loading
      },
      props: {
        isRangeCompare: Boolean,
        device: {
          type: Object
        },
        dateRange: {
          type: Object
        },
        toDateRange: {
          type: Object
        }
      },
      data () {
          return{
            reportList: [
              {category:"Forearm",categoryShow:true,underlineShow:false,text:"Pronation", gravityText: false, reps:0, repsGap:0, averageRom:0, averageRomGap:0},
              {category:"Forearm",categoryShow:false,underlineShow:true,text:"Supination", gravityText: false, reps:0, repsGap:0, averageRom:0, averageRomGap:0},
              {category:"Wrist",categoryShow:true,underlineShow:false,text:"Radial Deviation", gravityText: false, reps:0, repsGap:0, averageRom:0, averageRomGap:0},
              {category:"Wrist",categoryShow:false,underlineShow:false,text:"Ulnar Deviation", gravityText: false, reps:0, repsGap:0, averageRom:0, averageRomGap:0},
              {category:"Wrist",categoryShow:false,underlineShow:false,text:"Radial Deviation", gravityText: true, reps:0, repsGap:0, averageRom:0, averageRomGap:0},
              {category:"Wrist",categoryShow:false,underlineShow:false,text:"Ulnar Deviation", gravityText: true, reps:0, repsGap:0, averageRom:0, averageRomGap:0},

              {category:"Wrist",categoryShow:false,underlineShow:false,text:"Flexion", reps:0, gravityText: false, repsGap:0, averageRom:0, averageRomGap:0},
              {category:"Wrist",categoryShow:false,underlineShow:false,text:"Extension", reps:0, gravityText: false, repsGap:0, averageRom:0, averageRomGap:0},
              {category:"Wrist",categoryShow:false,underlineShow:false,text:"Flexion", reps:0, gravityText: true, repsGap:0, averageRom:0, averageRomGap:0},
              {category:"Wrist",categoryShow:false,underlineShow:false,text:"Extension", reps:0, gravityText: true, repsGap:0, averageRom:0, averageRomGap:0},

              {category:"Finger",categoryShow:true,underlineShow:false,text:"Finger", reps:0, gravityText: false, repsGap:0, averageRom:0, averageRomGap:0},
            ],
            loading: false,
            error: false,
            isNoData: false
          }
      },
      computed: {
        patient: function () {
          return this.$store.getters.selectedPatient;
        }
      },
      watch: {
        device(newVal, oldVal) {
          this.loadReportList();
        },
        dateRange(newValue, oldValue) {
          this.loadReportList();
        },
        toDateRange(newValue, oldVAlue) {
          this.loadReportList();
        },
        isRangeCompare(newValue, oldValue) {
          this.loadReportList();
        }
      },
      mounted() {
        this.loadReportList();
      },
      methods: {
        async loadReportList() {
          if (!this.patient || !this.device) return;

          let trainingSensorSummary;
          let toTrainingSensorSummary;
          try {
            this.loading = true;
            trainingSensorSummary = await getTrainedSensorSummary(this.patient.id, this.device.id,this.$moment(this.dateRange.startDate).toISOString(),this.$moment(this.dateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString());
            if (this.isRangeCompare) {
              toTrainingSensorSummary = await getTrainedSensorSummary(this.patient.id, this.device.id, this.$moment(this.toDateRange.startDate).toISOString(), this.$moment(this.toDateRange.endDate).add(23,'hours').add(59,'minutes').add(59,'seconds').toISOString());
            }
          } catch (error) {
            this.loading = false;
            this.error = true;
          }

          this.reportList[0].reps = trainingSensorSummary.forearm.pronationReps;
          this.reportList[0].averageRom = trainingSensorSummary.forearm.pronationAverageRom;
          this.reportList[1].reps = trainingSensorSummary.forearm.supinaionReps;
          this.reportList[1].averageRom = trainingSensorSummary.forearm.supinaionAverageRom;

          this.reportList[2].reps = trainingSensorSummary.whist.radialDeviationReps;
          this.reportList[2].averageRom = trainingSensorSummary.whist.radialDeviationAverageRom;
          this.reportList[3].reps = trainingSensorSummary.whist.ulnarDeviationReps;
          this.reportList[3].averageRom = trainingSensorSummary.whist.ulnarDeviationAverageRom;
          this.reportList[4].reps = trainingSensorSummary.whist.radialDeviationGravityEliminatedReps;
          this.reportList[4].averageRom = trainingSensorSummary.whist.radialDeviationGravityEliminatedAverageRom;
          this.reportList[5].reps = trainingSensorSummary.whist.ulnarDeviationGravityEliminatedReps;
          this.reportList[5].averageRom = trainingSensorSummary.whist.ulnarDeviationGravityEliminatedAverageRom;
          this.reportList[6].reps = trainingSensorSummary.whist.flexionReps;
          this.reportList[6].averageRom = trainingSensorSummary.whist.flexionAverageRom;
          this.reportList[7].reps = trainingSensorSummary.whist.extensionReps;
          this.reportList[7].averageRom = trainingSensorSummary.whist.extensionAverageRom;
          this.reportList[8].reps = trainingSensorSummary.whist.flexionGravityEliminatedReps;
          this.reportList[8].averageRom = trainingSensorSummary.whist.flexionGravityEliminatedAverageRom;
          this.reportList[9].reps = trainingSensorSummary.whist.extensionGravityEliminatedReps;
          this.reportList[9].averageRom = trainingSensorSummary.whist.extensionGravityEliminatedAverageRom;

          this.reportList[10].reps = trainingSensorSummary.finger.reps;
          this.reportList[10].averageRom = trainingSensorSummary.finger.averageRom;

          if (this.isRangeCompare) {
            this.reportList[0].repsGap = this.reportList[0].reps - toTrainingSensorSummary.forearm.pronationReps;
            this.reportList[0].averageRomGap = this.reportList[0].averageRom - toTrainingSensorSummary.forearm.pronationAverageRom;
            this.reportList[1].repsGap = this.reportList[1].reps - toTrainingSensorSummary.forearm.supinaionReps;
            this.reportList[1].averageRomGap = this.reportList[1].averageRom - toTrainingSensorSummary.forearm.supinaionAverageRom;

            this.reportList[2].repsGap = this.reportList[2].reps - toTrainingSensorSummary.whist.radialDeviationReps;
            this.reportList[2].averageRomGap = this.reportList[2].averageRom - toTrainingSensorSummary.whist.radialDeviationAverageRom;
            this.reportList[3].repsGap = this.reportList[3].reps - toTrainingSensorSummary.whist.ulnarDeviationReps;
            this.reportList[3].averageRomGap = this.reportList[3].averageRom - toTrainingSensorSummary.whist.ulnarDeviationAverageRom;
            this.reportList[4].repsGap = this.reportList[4].reps - toTrainingSensorSummary.whist.radialDeviationGravityEliminatedReps;
            this.reportList[4].averageRomGap = this.reportList[4].averageRom - toTrainingSensorSummary.whist.radialDeviationGravityEliminatedAverageRom;
            this.reportList[5].repsGap = this.reportList[5].reps - toTrainingSensorSummary.whist.ulnarDeviationGravityEliminatedReps;
            this.reportList[5].averageRomGap = this.reportList[5].averageRom - toTrainingSensorSummary.whist.ulnarDeviationGravityEliminatedAverageRom;
            this.reportList[6].repsGap = this.reportList[6].reps - toTrainingSensorSummary.whist.flexionReps;
            this.reportList[6].averageRomGap = this.reportList[6].averageRom - toTrainingSensorSummary.whist.flexionAverageRom;
            this.reportList[7].repsGap = this.reportList[7].reps - toTrainingSensorSummary.whist.extensionReps;
            this.reportList[7].averageRomGap = this.reportList[7].averageRom - toTrainingSensorSummary.whist.extensionAverageRom;
            this.reportList[8].repsGap = this.reportList[8].reps - toTrainingSensorSummary.whist.flexionGravityEliminatedReps;
            this.reportList[8].averageRomGap = this.reportList[8].averageRom - toTrainingSensorSummary.whist.flexionGravityEliminatedAverageRom;
            this.reportList[9].repsGap = this.reportList[9].reps - toTrainingSensorSummary.whist.extensionGravityEliminatedReps;
            this.reportList[9].averageRomGap = this.reportList[9].averageRom - toTrainingSensorSummary.whist.extensionGravityEliminatedAverageRom;

            this.reportList[10].repsGap = this.reportList[10].reps - toTrainingSensorSummary.finger.reps;
            this.reportList[10].averageRomGap = this.reportList[10].averageRom - toTrainingSensorSummary.finger.averageRom;
          }

          this.loading = false;
        },
        removeNumberMinusSign: function (number) {
          if (number < 0) {
            return number * -1;
          } else if (number === 0) {
            return '-';
          }
          return number;
        }
      }
    }
</script>

<style lang="scss" scoped>
.glove-sensor-report {
  .report-contents {
    height: 535px;
    position: relative;
    overflow: hidden;
    .content-grid {
      display: grid;
      grid-template-rows: repeat(6,1fr);
      .content-grid-row {
        display: flex;
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #d0d4dc;
        align-items: center;
        .content-grid-row-item-category {
          width: 30%;
          &.under {
          }
        }
        .content-grid-row-item-text {
          width: 50%;
          &.gravity {
            font-size: 10px;
            color: gray;
          }
        }
        .content-grid-row-item-sum {
          text-align: center;
          width: 10%;
          &.header {
            font-size: 11px;
            width: 20%
          }
        }
        .content-grid-row-item-gap {
          text-align: center;
          width: 10%;
          font-size: 13px;
        }
        .content-grid-row-item-gap.plus {
          color: blue;
        }
        .content-grid-row-item-gap.minus {
          color: red;
        }

      }
    }
    .no-data,
    .error {
      width: 100%;
      height: 494px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      &.error {
        z-index: 2;
      }
    }
  }
}

</style>
