import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import user from "./modules/user";
import patient from "./modules/patient";
import chat from "./modules/chat";
import firebase from "./modules/firebase";
import notification from "./modules/notification";
import screensharing from "./modules/screensharing";
import videocall from "./modules/videocall";
import lang from "./modules/lang";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    patient,
    chat,
    firebase,
    notification,
    screensharing,
    videocall,
    lang
  },
  getters
});

export default store;
