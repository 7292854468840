/* eslint-disable no-undef */
import router from "./router";
import store from "@/store";
// import { Message } from 'element-ui'
import { getToken } from "@/utils/auth"; // get token from cookie
// import { fetchGetClinicReqStatus } from "@/api/clinic.js";
// import getPageTitle from "@/utils/get-page-title";

// Nprogress의 오른쪽 작은 동그라미 옵션끄기
NProgress.configure({ showSpinner: false });

const whiteList = ["/login", "/resetPassword", "/signup", "/account"]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  // set page title
  // document.title = getPageTitle(to.meta.title)
  // determine whether the user has logged in
  // const hasToken = getToken()

  const hasToken = await getToken();
  if (hasToken) {
    if (to.path === "/login" || to.path === "/signup") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
    } else {
      try {
        // get user info
        await store.dispatch("user/getInfo");
        if (
          to.path.includes("clinic") &&
          store.getters.userLevel.includes("Therapist")
        ) {
          next({ path: "/" });
        } else if (
          to.path.includes("roleassign") &&
          !store.getters.userLevel.includes("Owner")
        ) {
          next({ path: "/" });
        } else {
          if (!store.getters.hasNotification) {
            await store.dispatch("notification/checkNotification");
          }
          next();
        }
      } catch (error) {
        // remove token and go to login page to re-login
        await store.dispatch("user/resetToken");
        // Message.error(error || 'Has Error')
        // next(`/auth/login?redirect=${to.path}`);
        next();
      }
    }
    NProgress.done();
  } else {
    /* has no token*/
    const find = whiteList.findIndex(item => {
      const re = new RegExp(item);
      return re.test(to.path);
    });
    if (find !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      if (to.path.includes("calendar")) {
        const eventId = to.path.slice(1).split("/")[1];
        if (eventId) {
          sessionStorage.setItem("eventId", eventId);
        }
      }
      console.log("you have no permission");
      next(`/login`);
    }
    NProgress.done();
  }
});

router.afterEach(() => {
  // router 목적지 도착후 callback
  NProgress.done();
});
