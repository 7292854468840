var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "report-section training-ratio" }, [
    _c("h1", [
      _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.trainingType")))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "report-contents" },
      [
        _vm.loading ? _c("Loading") : _vm._e(),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(_vm.featureRatio, function(fr, index) {
            return _c("li", { key: index, staticClass: "report-list" }, [
              _c("span", { staticClass: "label" }, [_vm._v(_vm._s(fr.name))]),
              _vm._v(" "),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm._f("numFormat")(fr.ratio, "0.0")) + "%")
              ]),
              _vm._v(" "),
              _vm.isRangeCompare
                ? _c(
                    "div",
                    {
                      staticClass: "in-decrease",
                      class: {
                        decrease: fr.genRatio < 0,
                        increase: fr.genRatio > 0
                      }
                    },
                    [
                      _c("span", {
                        staticClass: "arrow",
                        class: { down: fr.genRatio < 0, up: fr.genRatio > 0 }
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("numFormat")(
                              _vm.removeNumberMinusSign(fr.genRatio),
                              "0.0"
                            )
                          ) +
                          "%\n        "
                      )
                    ]
                  )
                : _vm._e()
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm.isNoData
          ? _c("div", { staticClass: "no-data" }, [
              _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.empty")))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "error" }, [_vm._v("Error!")])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }