var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "NoticeConfirm" }, [
    _c("div", { staticClass: "Confirm-contents" }, [
      _c("h1", [_c("span", [_vm._v(_vm._s(_vm.confirm.title))])]),
      _vm._v(" "),
      _c("div", { staticClass: "confirm-message" }, [
        _c("span", [_vm._v(_vm._s(_vm.confirm.message))])
      ])
    ]),
    _vm._v(" "),
    _vm.confirm.type === "VIDEOCALL"
      ? _c("div", { staticClass: "popup-submit-btns" }, [
          _c(
            "button",
            {
              staticClass: "accept-btn",
              attrs: { type: "button" },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("Start Video Call")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("Close")]
          )
        ])
      : _vm.confirm.type === "SCREENSHARING"
      ? _c("div", { staticClass: "popup-submit-btns" }, [
          _c(
            "button",
            {
              staticClass: "accept-btn",
              attrs: { type: "button" },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("Start Screen Sharing")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("Close")]
          )
        ])
      : _c("div", { staticClass: "popup-submit-btns" }, [
          _c(
            "button",
            {
              staticClass: "accept-btn",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_vm._v("Close")]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }