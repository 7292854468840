var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MemoDetail" }, [
    _c("div", { staticClass: "memo-date" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.$moment(_vm.memo.created).format("MMMM D, YYYY h:mmA")) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "memo-type" }, [
      _vm._v(_vm._s(_vm.printType(_vm.memo.division)))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "memo-title" }, [_vm._v(_vm._s(_vm.memo.title))]),
    _vm._v(" "),
    _c("div", { staticClass: "memo-contents" }, [
      _vm._v(_vm._s(_vm.memo.content))
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "popup-btns" }, [
      _vm.memo.division !== "VIDEOCALLRECORD"
        ? _c(
            "button",
            {
              staticClass: "del-btn icon-btn",
              attrs: { type: "button" },
              on: { click: _vm.handleDelete }
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.memo.division !== "VIDEOCALLRECORD"
        ? _c(
            "button",
            {
              staticClass: "edit-btn icon-btn",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.handleEdit(_vm.memo)
                }
              }
            },
            [_vm._m(1)]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close-btn icon-btn",
          attrs: { type: "button" },
          on: { click: _vm.handleClose }
        },
        [_vm._m(2)]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_bin" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" }),
      _vm._v(" "),
      _c("span", { staticClass: "path3" }),
      _vm._v(" "),
      _c("span", { staticClass: "path4" }),
      _vm._v(" "),
      _c("span", { staticClass: "path5" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_edit" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" }),
      _vm._v(" "),
      _c("span", { staticClass: "path3" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }