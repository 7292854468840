<template>
  <section class="Login">
    <h1>{{ $t("page.login.logIn") }}</h1>
    <form ref="loginForm" @submit.prevent>
      <div ref="email" class="email">
        <label for="email">{{ $t("page.login.email") }}</label>
        <input
          id="email"
          v-model="email"
          placeholder="Email"
          type="text"
          autocomplete="email"
        >
        <span class="message">Account doesn't exist</span>
      </div>
      <div ref="password" class="password">
        <label for="password">{{ $t("page.login.password") }}</label>
        <input
          id="password"
          v-model="password"
          :type="passwordType"
          placeholder="Password"
          autocomplete="off"
          @keyup.enter="handleSubmit"
        >
        <button
          v-if="passwordType === 'text'"
          type="button"
          class="password-show"
          @click="passwordHidden"
        >
          <span class="icon-ic_eye_hide">
            <span class="path1" />
            <span class="path2" />
            <span class="path3" />
            <span class="path4" />
            <span class="path5" />
            <span class="path6" />
          </span>
        </button>
        <button
          v-else
          type="button"
          class="password-show"
          @click="passwordShow"
        >
          <span class="icon-ic_eye_show">
            <span class="path1" />
          </span>
        </button>
        <span class="message">Incorrect password</span>
      </div>
      <div
        ref="submitBtn"
        class="submit-btns"
        :class="submitDisable ? 'disabled' : ''"
      >
        <button type="button" @click="handleSubmit">
          {{ $t("page.login.logInButton") }}
        </button>
      </div>
      <div class="aside-btns">
        <router-link to="/account/password">{{
          $t("page.login.forgotPassword")
        }}</router-link>
        <router-link to="/signup">{{
          $t("page.login.willAccount")
        }}</router-link>
      </div>
    </form>
  </section>
</template>
<script>
/* eslint-disable no-undef */

import firebase from "firebase/app";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import "firebase/messaging";
import { fetchGetClinicReqStatus } from "@/api/clinic.js";
import { fetchGetTherapistInfo } from "@/api/user.js";
// import ApproveStatus from "./components/ApproveStatus";
export default {
  name: "Login",
  mixins: [ErrorMixin],
  data() {
    return {
      email: "",
      password: "",
      passwordType: "password"
    };
  },
  computed: {
    submitDisable: function () {
      return this.email.length < 1 || this.password.length < 1;
    }
  },
  watch: {
    email: function (newVal, oldVal) {
      this.setErrorMessage();
    },
    password: function (newVal, oldVal) {
      this.setErrorMessage();
    }
  },
  created() {
    if (this.email && this.password) {
      this.$refs.submitBtn.classList.remove("disabled");
    }
  },
  methods: {
    async handleSubmit() {
      if (this.submitDisable) {
        return;
      }
      const loginForm = {
        username: this.email,
        password: this.password
      };
      try {
        const result = await this.$store.dispatch("user/login", loginForm);
        if (result) {
          this.$store.dispatch("user/getInfo");
          this.$store.dispatch("notification/checkNotification");
        }
      } catch (error) {
        // console.log("Unable to retrieve token. ", error);
        /*
            error type
            1. Wrong Password
            2. Account does not exist
          */
        NProgress.done();
        if (error.code === "wrong.password") {
          this.setErrorMessage("password");
        } else if (error.code === "no.user") {
          this.setErrorMessage("email");
        } else {
          this.handleError(error, "Login");
        }
      }
      try {
        const messaging = firebase.messaging();
        const fcmToken = await messaging.getToken();
        if (fcmToken) {
          this.$store.dispatch("firebase/initFcmToken", fcmToken);
        }
      } catch (e) {
        console.log("Unable to retrieve fcm token.", e);
      }
      try {
        const therapistInfo = await fetchGetTherapistInfo();
        if (therapistInfo.clinic.id === -1) {
          this.$router.push("/");
        } else {
          if (therapistInfo.clinic_req) {
            // 모달에서 페이지로 수정.
            // status는 (0 : 요청, 1 : 승인, 2 : 거절, 3: 취소) 입니다.
            if (therapistInfo.clinic_req.status === 1) {
              this.$router.push("/");
            } else {
              this.$router.push("/approvalStatus");
            }
          } else {
            const clinicReq = await fetchGetClinicReqStatus();
            // 모달에서 페이지로 수정.
            // status는 (0 : 요청, 1 : 승인, 2 : 거절, 3: 취소) 입니다.
            if (clinicReq.status === 1) {
              this.$router.push("/");
            } else {
              this.$router.push("/approvalStatus");
            }
          }
        }
      } catch (e) {
        this.handleError(e, "Clinic Approval Status");
      }
    },
    setErrorMessage(target) {
      // input의 에러메시지 표시 혹은 삭제
      const refs = this.$refs;
      refs.email.classList.remove("wrong");
      refs.password.classList.remove("wrong");
      if (target === "email") {
        refs.email.classList.add("wrong");
        refs.email.children[1].focus();
      } else if (target === "password") {
        refs.password.classList.add("wrong");
        refs.password.children[1].focus();
      }
    },
    passwordShow() {
      // password 보이기
      this.passwordType = "text";
    },
    passwordHidden() {
      // password 숨기기
      this.passwordType = "password";
    }
  }
};
</script>
<style lang="scss" scoped>
.Login {
  max-width: 350px;
  width: 100%;
  margin: auto;
  margin-top: 130px;
  color: $fontColor1;
  h1 {
    font-weight: 800;
    font-size: $fontSize1;
    letter-spacing: -0.4px;
  }
  .email {
    margin-top: 60px;
    label {
      font-size: $fontSize7;
    }
    input[type="text"] {
      margin-top: 8px;
    }
    &.wrong {
      input[type="password"],
      input[type="text"] {
        border-color: $uiColor1;
      }
      .message {
        display: inline-block;
      }
    }
  }
  .password {
    margin-top: 24px;
    position: relative;
    label {
      font-size: $fontSize7;
    }
    input[type="password"],
    input[type="text"] {
      margin-top: 8px;
    }
    &.wrong {
      input[type="password"],
      input[type="text"] {
        border-color: $uiColor1;
      }
      .message {
        display: inline-block;
      }
    }
    .password-show {
      position: absolute;
      top: 29px;
      right: 12px;
    }
  }
  .message {
    margin-top: 4px;
    display: none;
    color: $uiColor1;
    background: rgba(244, 67, 54, 0.25);
    padding: 4px 16px;
    font-size: $fontSize6;
  }
  .aside-btns {
    margin-top: 40px;
    display: flex;
    a {
      text-decoration: none;
      color: $fontColor2;
      font-size: $fontSize6;
      &:last-of-type {
        margin-left: auto;
      }
    }
  }
}
</style>
