<template>
  <section class="PatientMapping">
    <h1>
      {{ $t("adminPage.management.mapping.patientMapping.title") }}
      <span class="therapist-name">{{ therapistName }}</span>
    </h1>
    <div class="mapping-list">
      <div class="mapping-left">
        <h2>{{ $t("adminPage.management.mapping.patientMapping.patientList.title") }}</h2>
        <div class="mapping-search">
          <div class="search-select">
            <Select
              v-model="selectedSortingLeft"
              :options="sortingOptionLeft"
              label="label"
              :components="{ OpenIndicator, Deselect }"
            />
          </div>
          <div v-if="selectedSortingLeft.value !== 'RANGE'" class="search">
            <span class="icon-ic_search" @click="searchClinicPatientName">
              <span class="path1" />
              <span class="path2" />
            </span>
            <input
              v-model="searchLeft"
              type="text"
              @keypress.enter="searchClinicPatientName"
            >
          </div>
          <div v-else class="search">
            <el-date-picker
              v-model="rangeData"
              type="daterange"
              align="right"
              start-placeholder="Start Date"
              end-placeholder="End Date"
            />
          </div>
        </div>
        <table>
          <colgroup>
            <col width="100px">
            <col width="40px">
            <col width="40px">
            <col width="110px">
            <col width="80px">
          </colgroup>
          <thead>
            <tr>
              <th class="align-left">{{ $t("adminPage.management.mapping.patientMapping.patientList.list.header.name") }}</th>
              <th>{{ $t("adminPage.management.mapping.patientMapping.patientList.list.header.age") }}</th>
              <th>{{ $t("adminPage.management.mapping.patientMapping.patientList.list.header.gender") }}</th>
              <th>{{ $t("adminPage.management.mapping.patientMapping.patientList.list.header.create") }}</th>
              <th />
            </tr>
          </thead>
        </table>
        <div class="table-scroll custom-scrollbar">
          <table>
            <colgroup>
              <col width="100px">
              <col width="40px">
              <col width="40px">
              <col width="100px">
              <col width="80px">
            </colgroup>
            <tbody v-if="leftList.length > 0">
              <tr v-for="(item, index) of leftList" :key="index">
                <td class="align-left">
                  {{
                    !item.name
                      ? "-"
                      : item.name.includes("null")
                        ? "-"
                        : item.name
                  }}
                </td>
                <td>{{ item.age }}</td>
                <td>{{ item.gender ? item.gender[0] : "-" }}</td>
                <td>{{ $moment(item.created_time).format("YYYY-M-D H:mm") }}</td>
                <td class="secondary-type-btns small-btns align-right">
                  <button type="button" @click="handleAddPatient(item)">
                    {{ $t("adminPage.management.mapping.patientMapping.patientList.button.add") }}
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">{{ $t("adminPage.management.mapping.patientMapping.patientList.empty") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mapping-right">
        <h2>{{ title }}</h2>
        <div class="mapping-search">
          <div class="search-select">
            <Select
              v-model="selectedSortingRight"
              :options="sortingOptionRigth"
              label="label"
              :components="{ OpenIndicator, Deselect }"
            />
          </div>
          <div class="search">
            <span class="icon-ic_search" @click="searchTherapistPatientName">
              <span class="path1" />
              <span class="path2" />
            </span>
            <input
              v-model="searchRight"
              type="text"
              @keypress.enter="searchTherapistPatientName"
            >
          </div>
        </div>
        <table>
          <colgroup>
            <col width="150px">
            <col width="60px">
            <col width="60px">
          </colgroup>
          <thead>
            <tr>
              <th class="align-left">{{ $t("adminPage.management.mapping.patientMapping.patientOf.list.header.name") }}</th>
              <th>{{ $t("adminPage.management.mapping.patientMapping.patientOf.list.header.age") }}</th>
              <th>{{ $t("adminPage.management.mapping.patientMapping.patientOf.list.header.gender") }}</th>
              <th />
            </tr>
          </thead>
        </table>
        <div class="table-scroll custom-scrollbar">
          <table>
            <colgroup>
              <col width="150px">
              <col width="60px">
              <col width="60px">
            </colgroup>
            <tbody v-if="rightList.length > 0">
              <tr v-for="(item, index) of rightList" :key="index">
                <td class="align-left">
                  {{
                    !item.name
                      ? "-"
                      : item.name.includes("null")
                        ? "-"
                        : item.name
                  }}
                </td>
                <td>{{ item.age }}</td>
                <td>{{ item.gender ? item.gender[0] : "-" }}</td>
                <td class="align-right">
                  <button
                    type="button"
                    class="icon-btn"
                    @click="handleDelPatient(item)"
                  >
                    <span class="icon-ic_bin">
                      <span class="path1" />
                      <span class="path2" />
                      <span class="path3" />
                      <span class="path4" />
                      <span class="path5" />
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4">{{ $t("adminPage.management.mapping.patientMapping.patientOf.empty") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 서밋버튼 -->
    <div :class="{'popup-submit-btns':true,'disabled':!isSaved}">
      <button type="button" class="accept-btn" @click="handleSubmit">
        {{ $t("adminPage.management.mapping.patientMapping.button.save") }}
      </button>
      <button type="button" @click="handleClose">{{ $t("adminPage.management.mapping.patientMapping.button.cancel") }}</button>
    </div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button type="button" class="close-btn icon-btn" @click="handleClose">
        <span class="icon-ic_delete">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
  </section>
</template>

<script>
/* eslint-disable vue/require-default-prop */

import Select from "vue-select";
import { SelectMixin } from "@/mixins/SelectMixin.js";
import { ErrorMixin } from "@/mixins/ErrorMixin.js";
import {
  fetchGetClinicTherapistPatientList,
  fetchGetClinicPatientsNoAssigned,
  fetchMappingClinicTherapistToPatient
} from "@/api/clinic.js";
import { mapGetters } from "vuex";
export default {
  name: "PatientMapping",
  components: {
    Select
  },
  mixins: [SelectMixin, ErrorMixin],
  props: {
    clinicId: Number,
    therapistName: String,
    therapistId: Number,
    searchTherapist: Function
  },
  data() {
    return {
      clinicList: [],
      therapistList: [],
      rangeData: null,
      sortingOptionLeft: [
        {
          label: this.$t("adminPage.management.mapping.patientMapping.patientList.selectOption.all"),
          value: "ALL"
        },
        {
          label: this.$t("adminPage.management.mapping.patientMapping.patientList.selectOption.name"),
          value: "NAME"
        },
        {
          label: this.$t("adminPage.management.mapping.patientMapping.patientList.selectOption.range"),
          value: "RANGE"
        }
      ],
      sortingOptionRigth: [
        {
          label: this.$t("adminPage.management.mapping.patientMapping.patientList.selectOption.all"),
          value: "ALL"
        },
        {
          label: this.$t("adminPage.management.mapping.patientMapping.patientList.selectOption.name"),
          value: "NAME"
        }
      ],
      selectedSortingLeft: {
        label: this.$t("adminPage.management.mapping.patientMapping.patientList.selectOption.all"),
        value: "ALL"
      },
      selectedSortingRight: {
        label: this.$t("adminPage.management.mapping.patientMapping.patientList.selectOption.all"),
        value: "ALL"
      },
      searchLeft: "",
      searchLeftOn: "",
      searchRight: "",
      searchRightOn: "",
      isSaved: false
    };
  },
  computed: {
    ...mapGetters({
      lang: "lang/language"
    }),
    title() {
      if (this.lang === "ko") {
        return `${this.therapistName} ${this.$t("components.mappingPatientList.title")}`;
      }
      return `${this.$t("components.mappingPatientList.title")} ${this.therapistName}`;
    },
    leftList: function() {
      if (this.selectedSortingLeft.value === "RANGE" && this.rangeData !== null) {
        return this.clinicList.filter(item => {
          if (
            this.$moment(item.created_time).isBetween(
              this.$moment(this.rangeData[0]).format("YYYY-M-D 00:00:00"),
              this.$moment(this.rangeData[1]).format("YYYY-M-D 23:59:59")) &&
            this.therapistList.find(fItem => fItem.patient_id === item.patient_id) === undefined

          // (!this.searchLeftOn && !item.created_time) )
          ) {
            return item;
          }
        });
      } else {
        return this.clinicList.filter(item => {
          if (
            (!this.searchLeftOn && !item.name) ||
            (item.name && item.name.includes(this.searchLeftOn))
          ) {
            return item;
          }
        });
      }
    },
    rightList: function() {
      return this.therapistList.filter(item => {
        if (
          (!this.searchLeftOn && !item.name) ||
          (item.name && item.name.includes(this.searchRightOn))
        ) {
          return item;
        }
      });
    }
  },
  async created() {
    try {
      this.isSaved = false;
      const getTherapistPatients = await fetchGetClinicTherapistPatientList(
        this.clinicId,
        this.therapistId
      );
      const getClinicPatients = await fetchGetClinicPatientsNoAssigned(
        this.clinicId
      );
      this.therapistList = getTherapistPatients.list;
      this.clinicList = getClinicPatients.list.sort(function (a, b) {
        return new Date(b.created_time) - new Date(a.created_time);
      });
      this.isSaved = true;
    } catch (error) {
      this.handleError(error, "PatientMapping");
    }
  },
  methods: {
    async handleSubmit() {
      if (this.isSaved === false) return;
      const therapistId = this.therapistId;
      const newPatientList = this.therapistList.map(item => {
        return {
          user_id: item.id,
          target_id: item.patient_id
        };
      });
      try {
        this.isSaved = false;
        const result = await fetchMappingClinicTherapistToPatient(
          this.clinicId,
          therapistId,
          newPatientList
        );
        if (result) {
          this.handleClose();
          this.searchTherapist();
        }
      } catch (error) {
        this.isSaved = true;
        this.handleError(
          error,
          "PatientMapping > Clinic Therapist to Patient Mapping API"
        );
      }
      this.isSaved = true;
    },
    handleClose() {
      this.$emit("close");
    },
    searchTherapistPatientName() {
      this.searchRightOn = this.searchRight;
    },
    searchClinicPatientName() {
      this.searchLeftOn = this.searchLeft;
    },
    handleAddPatient(patient) {
      const clinicList = this.clinicList.filter(item => {
        return item.id !== patient.id;
      });
      const therapistList = this.therapistList;
      therapistList.unshift(patient);
      this.clinicList = clinicList;
      this.therapistList = therapistList;
    },
    handleDelPatient(patient) {
      const therapistList = this.therapistList.filter(item => {
        return item.id !== patient.id;
      });
      const clinicList = this.clinicList;
      clinicList.unshift(patient);
      this.clinicList = clinicList;
      this.therapistList = therapistList;
    }
  }
};
</script>

<style lang="scss" scoped>
.PatientMapping {
  h1 {
    padding: 24px;
    font-size: $fontSize2;
    font-weight: 500;
    border-bottom: 1px solid $borderColor1;
    color: $fontColor1;
    .therapist-name {
      margin-left: 16px;
      font-size: $fontSize5;
      color: $fontColor2;
    }
  }
  h2 {
    padding: 16px;
    padding-bottom: 0;
    font-weight: 500;
    color: $fontColor1;
    font-size: $fontSize5;
    line-height: 21px;
  }
  .mapping-list {
    display: flex;

    .mapping-left,
    .mapping-right {
      width: 50%;
      padding-bottom: 24px;
    }
    .mapping-left {
      background: #f9fafb;
      border-right: 1px solid $borderColor1;
    }
    .mapping-search {
      display: flex;
      align-content: center;
      margin-top: 24px;
      padding: 0 16px;
      .search {
        padding: 0 16px;
        position: relative;
        width: 100%;
        .el-date-editor {
          width: 100%;
        }
        .icon-ic_search {
          position: absolute;
          top: 50%;
          right: 32px;
          transform: translate(0, -50%);
          font-size: $fontSize2;
          color: $fontColor2;
        }
        input[type="text"] {
          padding-right: 55px;
          height: 35px;
          border-radius: 2px;
        }
      }
      .search-select {
        flex-shrink: 0;
        width: 110px;
        ::v-deep .v-select {
          background: #ffffff;
          .vs__dropdown-toggle {
            border-radius: 2px;
          }
          .vs__dropdown-menu {
            min-width: 110px;
          }
        }
      }
    }
    .table-scroll {
      max-height: 300px;
      overflow-y: auto;
      table {
        margin-top: 0;
      }
    }
    table {
      width: calc(100% - 48px);
      margin: 0 24px;
      margin-top: 16px;
      .align-left {
        text-align: left;
      }

      .align-right {
        text-align: right;
      }
      th,
      td {
        text-align: center;
        vertical-align: middle;
        background: none;
      }
      thead {
        th,
        td {
          padding: 13px 0;
          border-bottom: 0 none;
          color: $fontColor2;
          font-size: $fontSize7;
        }
      }
      tbody {
        th,
        td {
          padding: 13px 0;
          color: $fontColor1;
          font-size: $fontSize7;
        }
      }
    }
  }
}
</style>
