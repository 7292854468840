<template>
  <div class="counter-wrap">
    <h4>{{ title }}</h4>
    <div class="counter" :class="obj[`${key}_disabled`] ? 'disabled' : ''">
      <button class="counter-controller minus" @click="_minus">
        <img src="@/assets/svgs/ic_minus.svg" alt="ic_minus-image">
      </button>
      <input v-model="obj[key]" class="counter-value" type="text">
      <button class="counter-controller plus" @click="_plus">
        <img src="@/assets/svgs/ic_add.svg" alt="ic_add-image">
      </button>
    </div>
    <span class="counter-range"> {{ range }} </span>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */

export default {
  name: "Counter",
  props: {
    // title: String, // 제목
    // value: Number, // 값
    // min: Number, // 최소
    // max: Number, // 최대
    // step: Number, // 상승 하락 단위
    // range: String, // 범위
    options: Object
    // minus: Function,
    // plus: Function,
  },
  data() {
    return {
      ...this.options
    };
  },
  methods: {
    _minus() {
      if (this.options.obj[`${this.key}_disabled`]) return;
      if (this.obj[this.key] > this.min) {
        const val = (this.obj[this.key] * 10 - this.step * 10) / 10;
        this.$set(this.obj, this.key, val);
        this.$set(this.options.obj, this.key, val);
        this.$forceUpdate();
      }
    },
    _plus() {
      if (this.options.obj[`${this.key}_disabled`]) return;
      if (this.obj[this.key] < this.max) {
        const val = (this.obj[this.key] * 10 + this.step * 10) / 10;
        this.$set(this.obj, this.key, val);
        this.$set(this.options.obj, this.key, val);
        this.$forceUpdate();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.counter-wrap {
  h4 {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    color: #606266;
    padding-bottom: 8px;
  }
  .counter {
    height: 36px;
    padding: 0 36px;
    border-radius: 2px;
    border: solid 1px #d0d4dc;
    background-color: #ffffff;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    &.disabled {
      opacity: 0.5;
    }
    .counter-value {
      width: 100%;
      border: none;
      text-align: center;
      line-height: 34px;
      padding: 0;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 600;
      color: #303133;
    }

    .counter-controller {
      width: 34px;
      height: 34px;
      position: absolute;
      top: 0;
      box-sizing: border-box;

      img {
        vertical-align: middle;
      }

      &.minus {
        left: 0;
        border-right: solid 1px #d0d4dc;
      }

      &.plus {
        right: 0;
        border-left: solid 1px #d0d4dc;
      }
    }
  }

  .counter-range {
    display: block;
    text-align: center;
    line-height: 14px;
    font-size: 11px;
    font-weight: normal;
    color: #909399;
    margin-top: 4px;
    color: #909399;
  }
}
</style>
