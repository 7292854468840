var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "medical-options" }, [
    _c("section", { staticClass: "MedicalRecords" }, [
      _c("h1", [_vm._v(_vm._s(_vm.$t("page.videoCall.memo.sessionrecords")))]),
      _vm._v(" "),
      _c("div", { staticClass: "contents" }, [
        _c(
          "div",
          { staticClass: "radio-section" },
          _vm._l(_vm.memoTypes, function(type, index) {
            return _c("span", { key: index }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.pickedType,
                    expression: "pickedType"
                  }
                ],
                attrs: { id: type.value, type: "radio" },
                domProps: {
                  value: type.value,
                  checked: _vm._q(_vm.pickedType, type.value)
                },
                on: {
                  change: function($event) {
                    _vm.pickedType = type.value
                  }
                }
              }),
              _vm._v(" "),
              _c("label", { attrs: { for: type.value } }, [
                _vm._v(_vm._s(type.type))
              ])
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "memo-title" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.title,
                expression: "title"
              }
            ],
            attrs: { type: "text", placeholder: "Enter the title" },
            domProps: { value: _vm.title },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.title = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "memo-contents" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.contents,
                expression: "contents"
              }
            ],
            attrs: { placeholder: "Enter Memo" },
            domProps: { value: _vm.contents },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.contents = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "secondary-type-btns",
            class: _vm.submitDisable ? "disabled" : ""
          },
          [
            _c(
              "button",
              {
                staticClass: "accept-btn",
                attrs: { type: "button", disabled: _vm.submitDisable },
                on: { click: _vm.handleSubmit }
              },
              [_vm._v("\n          Save\n        ")]
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "MedicalRemote" }, [
      _c("div", { staticClass: "line" }),
      _vm._v(" "),
      _c("h1", [_vm._v("Smart rehab")]),
      _vm._v(" "),
      _c("div", { staticClass: "contents" }, [
        _c("div", { staticClass: "start-rehab-content" }, [
          !_vm.gameCategories[0]
            ? _c("p", [_vm._v("실행 가능한 게임이 없습니다.")])
            : _vm._e(),
          _vm._v(" "),
          _vm.gameCategories[0]
            ? _c(
                "ul",
                { staticClass: "tree-view" },
                _vm._l(_vm.gameCategories, function(category, idx) {
                  return _c("li", { key: idx, staticClass: "depth1" }, [
                    _c("div", { staticClass: "depth-box" }, [
                      _c(
                        "a",
                        {
                          staticClass: "depth1-button",
                          on: {
                            click: function($event) {
                              category.isOpen = !category.isOpen
                            }
                          }
                        },
                        [_vm._v(_vm._s(category.deviceName))]
                      )
                    ]),
                    _vm._v(" "),
                    category.isOpen && !_vm.isEmpty(category.middleCategories)
                      ? _c(
                          "ul",
                          { staticClass: "sub-depth" },
                          [
                            _vm._l(category.middleCategories, function(
                              middelCategory,
                              idx
                            ) {
                              return [
                                !_vm.isEmpty(middelCategory.subClasses)
                                  ? _c(
                                      "li",
                                      { key: idx, staticClass: "depth2" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "depth-box" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "depth2-button",
                                                on: {
                                                  click: function($event) {
                                                    middelCategory.isOpen = !middelCategory.isOpen
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    middelCategory.motionType
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        middelCategory.isOpen
                                          ? _c(
                                              "ul",
                                              { staticClass: "sub-depth" },
                                              _vm._l(
                                                middelCategory.subClasses,
                                                function(subClass, idx) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: idx,
                                                      staticClass: "depth3"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "depth-box"
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "depth3-button",
                                                              on: {
                                                                click: function() {
                                                                  return _vm.setGame(
                                                                    subClass
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  subClass.title
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                }),
                0
              )
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.selectedGame
      ? _c("div", { staticClass: "game-remote-modal-wrap" }, [
          _c("div", { staticClass: "game-remote-modal" }, [
            _c("div", { staticClass: "modal-options" }, [
              _c(
                "a",
                {
                  staticClass: "close-button",
                  on: {
                    click: function($event) {
                      _vm.selectedGame = null
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/svgs/ic_delete.svg"),
                      alt: "ic_delete-image"
                    }
                  })
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-header" }, [
              _c("h1", [
                _vm._v(_vm._s(_vm.selectedGame.title || "Game Setting"))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "game-info" }, [
                _c("div", { staticClass: "game-thumbnail" }, [
                  !_vm.selectedGame.thumbnailUrl
                    ? _c("span", { staticClass: "no-image" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "78",
                              height: "58",
                              viewBox: "0 0 78 58",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("rect", {
                              attrs: {
                                width: "78",
                                height: "58",
                                fill: "#EBECF0"
                              }
                            }),
                            _vm._v(" "),
                            _c("g", { attrs: { opacity: "0.55" } }, [
                              _c("path", {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "clip-rule": "evenodd",
                                  d:
                                    "M30 36.2821C30 41.2527 34.0295 45.2822 39.0001 45.2822C43.9705 45.2822 48 41.2527 48 36.2821C48 31.3118 43.9705 27.2822 39.0001 27.2822C34.0295 27.2822 30 31.3118 30 36.2821Z",
                                  fill: "url(#paint0_linear)"
                                }
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "clip-rule": "evenodd",
                                  d:
                                    "M39.0001 37.4795C43.9705 37.4795 48 33.4503 48 28.4796C48 23.509 43.9705 19.4795 39.0001 19.4795C34.0295 19.4795 30 23.509 30 28.4796C30 33.4503 34.0295 37.4795 39.0001 37.4795Z",
                                  fill: "url(#paint1_linear)"
                                }
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "clip-rule": "evenodd",
                                  d:
                                    "M38.9999 30.0003C34.0295 30.0003 30 25.9708 30 21.0001C30 16.0295 34.0295 12 38.9999 12C43.9705 12 48 16.0295 48 21.0001C48 25.9708 43.9705 30.0003 38.9999 30.0003Z",
                                  fill: "#909399"
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "defs",
                              [
                                _c(
                                  "linearGradient",
                                  {
                                    attrs: {
                                      id: "paint0_linear",
                                      x1: "47.849",
                                      y1: "45.193",
                                      x2: "47.849",
                                      y2: "27.4951",
                                      gradientUnits: "userSpaceOnUse"
                                    }
                                  },
                                  [
                                    _c("stop", {
                                      attrs: {
                                        "stop-color": "#909399",
                                        "stop-opacity": "0.5"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("stop", {
                                      attrs: {
                                        offset: "1",
                                        "stop-color": "#909399",
                                        "stop-opacity": "0.3"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "linearGradient",
                                  {
                                    attrs: {
                                      id: "paint1_linear",
                                      x1: "30",
                                      y1: "19.4797",
                                      x2: "30",
                                      y2: "37.4797",
                                      gradientUnits: "userSpaceOnUse"
                                    }
                                  },
                                  [
                                    _c("stop", {
                                      attrs: {
                                        "stop-color": "#909399",
                                        "stop-opacity": "0.5"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("stop", {
                                      attrs: {
                                        offset: "1",
                                        "stop-color": "#909399",
                                        "stop-opacity": "0.7"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.selectedGame.thumbnailUrl
                    ? _c("img", {
                        attrs: {
                          src: _vm.selectedGame.thumbnailUrl,
                          alt: _vm.selectedGame.title
                        }
                      })
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "game-descriptions" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.selectedGame.contentDescription) +
                      "\n          "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-contents" }, [
              _c("div", { staticClass: "options-wrap" }, [
                _c("div", { staticClass: "options-box" }, [
                  _vm.selectedGame.levels.length > 1
                    ? _c(
                        "div",
                        { staticClass: "level-box" },
                        [
                          _c("h4", [_vm._v("Difficulty")]),
                          _vm._v(" "),
                          _c("Slider", {
                            attrs: {
                              options: {
                                min: 1,
                                max: _vm.selectedGame.levels.length,
                                obj: _vm.selectedGame,
                                key: "level",
                                data: _vm.selectedGame.levels.map(function(
                                  item,
                                  idx
                                ) {
                                  return {
                                    label: "LEVEL " + (idx + 1),
                                    value: idx + 1
                                  }
                                })
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail-box" }, [
                    _c(
                      "div",
                      { staticClass: "detail-item" },
                      [
                        _c("Counter", {
                          attrs: {
                            options: {
                              title: _vm.rangeOfMotionTitle,
                              min: 10,
                              max: 90,
                              step: 10,
                              range: " 10% ~ 90% ",
                              obj: _vm.selectedGame,
                              key: "rangeOfMotion"
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "detail-item" },
                      [
                        _c("Counter", {
                          attrs: {
                            options: {
                              title: _vm.playTime,
                              min: 1,
                              max: 7,
                              step: 1,
                              range: " 1min ~ 7min ",
                              obj: _vm.selectedGame,
                              key: "playTime"
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "detail-item" },
                      [
                        _c("Counter", {
                          attrs: {
                            options: {
                              title: _vm.holdTime,
                              min: 1,
                              max: 10,
                              step: 1,
                              range: " 1sec ~ 10sec ",
                              obj: _vm.selectedGame,
                              key: "holddingTime"
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "modal-button play-game-button",
                  on: {
                    click: function() {
                      // selectedGame 정보 필요
                      _vm.remoteRequestGame(_vm.selectedGame)
                      _vm.selectedGame = null
                    }
                  }
                },
                [_vm._v("\n          Play game\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "modal-button",
                  on: {
                    click: function($event) {
                      _vm.selectedGame = null
                    }
                  }
                },
                [_vm._v("\n          Cancel\n        ")]
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }