<template>
  <section
    class="SessionDetail"
    :style="{ top: `${coordinates.y}px`, left: `${coordinates.x}px` }"
  >
    <h1>{{ $t("page.patient.calendar.head.detail.type.homework.title") }}</h1>
    <div class="session-detail-date">
      {{ $moment(homeworkDetailInfo.date).format("MMMM D, YYYY") }}
    </div>
    <div class="session-message">
      <p v-for="item in homeworkDetailInfo.messages.split('\n')">{{ item }}</p>
    </div>
    <div class="session-games">
      <ul>
        <li
          v-for="(mission, index) of homeworkDetailInfo.missions"
          :key="index"
        >
          <span
            class="icon-ic_check_circle_fill"
            :class="mission.completed ? 'complete' : ''"
          >
            <span class="path1" />
            <span class="path2" />
          </span>
          <span v-if="mission.lastTrainedDate"
            >{{ $moment(mission.lastTrainedDate).format("h:mm A") }}&nbsp;</span
          >
          <span class="title">
            {{ mission.title }}</span>
        </li>
      </ul>
    </div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button type="button" class="del-btn icon-btn" @click="handleDelete()">
        <span class="icon-ic_bin" v-if="!overTime">
          <span class="path1" />
          <span class="path2" />
          <span class="path3" />
          <span class="path4" />
          <span class="path5" />
        </span>
      </button>
      <button
        type="button"
        class="edit-btn icon-btn"
        @click="handleEdit(homeworkDetailInfo)"
        v-if="!overTime"
      >
        <span class="icon-ic_edit">
          <span class="path1" />
          <span class="path2" />
          <span class="path3" />
        </span>
      </button>
      <button type="button" class="close-btn icon-btn" @click="handleClose">
        <span class="icon-ic_delete">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
  </section>
</template>

<script>
import Confirm from "@/components/Confirm";
export default {
  name: "HomeworkDetail",
  props: {
    homeworkDetailInfo: Object,
    detailPopupClose: Function,
    deleteHomework: Function,
    editHomework: Function,
  },
  data() {
    return {
      coordinates: {
        x: 0,
        y: 0,
      },
      overTime: false,
    };
  },
  methods: {
    calculatePosition() {
      let calendarSize = this.homeworkDetailInfo.calendarSize;
      let thisPopupSize = {
        width: this.$el.offsetWidth,
        height: this.$el.offsetHeight,
      };
      let calculateX =
        this.homeworkDetailInfo.coordinates.x +
          thisPopupSize.width +
          this.homeworkDetailInfo.coordinates.flexible +
          4 <
        calendarSize.width
          ? this.homeworkDetailInfo.coordinates.x +
            this.homeworkDetailInfo.coordinates.flexible +
            4
          : this.homeworkDetailInfo.coordinates.x - thisPopupSize.width - 4;
      let calculateY = this.homeworkDetailInfo.coordinates.y;
      this.coordinates = {
        x: calculateX,
        y: calculateY,
      };
    },
    handleClose() {
      this.detailPopupClose();
    },
    handleEdit(homework) {
      this.editHomework(homework);
    },
    handleDelete(sessionId) {
      this.$modal.show(
        Confirm,
        {
          type: "delete",
          submit: this.confirm_delete_ok,
          confirm: {
            title: this.$t(
              "page.patient.calendar.head.detail.type.homework.deleteModal.title"
            ),
            message: this.$t(
              "page.patient.calendar.head.detail.type.homework.deleteModal.message"
            ),
          },
        },
        {
          height: "auto",
        }
      );
    },
    confirm_delete_ok() {
      this.deleteHomework(this.homeworkDetailInfo.id);
    },
  },
  created() {
    // console.log(this.sessionDetailInfo);
    const now = new Date();
    const eventStart = new Date(this.homeworkDetailInfo.date);
    const startDisable = (eventStart - now) / 1000 / 60 <= 0;
    if (startDisable) {
      this.overTime = true;
    }
  },
  mounted() {
    this.calculatePosition();
  },
};
</script>

<style lang="scss" scoped>
.SessionDetail {
  width: $modalWidth1;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #d0d4dc;
  padding: 50px 24px 40px 24px;
  box-sizing: border-box;
  h1 {
    font-size: $fontSize2;
    color: $fontColor1;
    font-weight: 500;
  }
  .session-detail-date {
    margin-top: 18px;
    font-size: $fontSize6;
    color: $fontColor2;
  }
  .session-message {
    margin-top: 18px;
    font-size: $fontSize6;
    color: $fontColor2;
  }
  .session-games {
    margin-top: 32px;
    font-size: $fontSize6;
    color: $fontColor2;
    line-height: 24px;
    li {
      display: flex;
      align-items: center;
      word-wrap: break-word;
      & + li {
        margin-top: 4px;
      }
      .icon-ic_check_circle_fill {
        font-size: 16px;
        color: $uiColor4;
        margin-right: 8px;
        &.complete {
          color: $uiColor3;
        }
      }
      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: normal;
        width: 100%;
      }
    }
  }
}
</style>
