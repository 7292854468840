<template>
  <div ref="clock" class="clock">
    <span class="time">{{ recordingTime }}</span>
  </div>
</template>
<script>
export default {
  props: {
    // isStart: Boolean,
  },
  data() {
    return {
      recordingTime: "00:00:00",
      startTime: null,
      started: null
    };
  },
  watch: {
    // isStart(newVal, oldVal) {
    //   newVal ? this.start() : this.reset();
    // },
  },
  created() {
    this.start();
  },
  destroyed() {
    this.reset();
  },
  methods: {
    start() {
      this.startTime = new Date();
      this.started = setInterval(this.clockRunning, 10);
    },
    reset() {
      clearInterval(this.started);
      this.recordingTime = "00:00:00";
    },
    clockRunning() {
      const currentTime = new Date();
      const timeElapsed = new Date(currentTime - this.startTime);
      const hour = timeElapsed.getUTCHours();
      const min = timeElapsed.getUTCMinutes();
      const sec = timeElapsed.getUTCSeconds();

      this.recordingTime =
        this.zeroPrefix(hour, 2) +
        ":" +
        this.zeroPrefix(min, 2) +
        ":" +
        this.zeroPrefix(sec, 2);
    },
    zeroPrefix(num, digit) {
      let zero = "";
      for (let i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    }
  }
};
</script>
<style lang="scss" scoped>
.clock {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -18px);
  width: 83px;
  height: auto;
  padding: 7px 0;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  z-index: 10;
  text-align: center;

  .time {
    font-size: $fontSize5;
    color: #fff;
  }
}
</style>
