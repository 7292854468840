var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "SessionDetail",
      style: { top: _vm.coordinates.y + "px", left: _vm.coordinates.x + "px" }
    },
    [
      _c("h1", [
        _vm._v(
          _vm._s(
            _vm.$t(
              "page.patient.calendar.head.detail.type.strokeCoachSession.title"
            )
          )
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "session-detail-date" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$moment(_vm.sessionDetailInfo.date).format("MMMM D, YYYY")
            ) +
            "\n  "
        )
      ]),
      _vm._v(" "),
      !_vm.sessionDetailInfo.batch
        ? _c("div", { staticClass: "session-games" }, [
            _c(
              "ul",
              _vm._l(_vm.sessionDetailInfo.games, function(game, index) {
                return _c("li", { key: index }, [
                  _c("span", [_vm._v(_vm._s(game.title))]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s("(Level" + game.level + ")"))])
                ])
              }),
              0
            )
          ])
        : _c("div", { staticClass: "session-games" }, [
            _c(
              "ul",
              _vm._l(_vm.sessionDetailInfo.games, function(game, index) {
                return _c("li", { key: index }, [
                  game.lastTrainedDate
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$moment(game.lastTrainedDate).format("h:mm A")
                          ) + " "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s("S" + game.sessionNumber) + " ")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s("M" + (index + 1)) + " ")]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(game.title) + " ")]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        "(" + game.successCount + "/" + game.targetCount + ")"
                      )
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "game-star" },
                    _vm._l(game.starCount, function(star, index) {
                      return _c(
                        "span",
                        {
                          key: index,
                          staticClass: "icon-ic_star_fill",
                          class: star ? "active" : ""
                        },
                        [_c("span", { staticClass: "path1" })]
                      )
                    }),
                    0
                  )
                ])
              }),
              0
            )
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "popup-btns" }, [
        _c(
          "button",
          {
            staticClass: "del-btn icon-btn",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.handleDelete()
              }
            }
          },
          [
            !_vm.sessionDetailInfo.batch && !_vm.overTime
              ? _c("span", { staticClass: "icon-ic_bin" }, [
                  _c("span", { staticClass: "path1" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "path2" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "path3" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "path4" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "path5" })
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        !_vm.sessionDetailInfo.batch && !_vm.overTime
          ? _c(
              "button",
              {
                staticClass: "edit-btn icon-btn",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.handleEdit(_vm.sessionDetailInfo)
                  }
                }
              },
              [_vm._m(0)]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close-btn icon-btn",
            attrs: { type: "button" },
            on: { click: _vm.handleClose }
          },
          [_vm._m(1)]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_edit" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" }),
      _vm._v(" "),
      _c("span", { staticClass: "path3" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }