var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "CreateAdvice" }, [
    _c("section", [
      _c("div", { staticClass: "contents" }, [
        _c("h1", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.contents,
              expression: "contents"
            }
          ],
          attrs: { placeholder: _vm.contentsPlaceholder },
          domProps: { value: _vm.contents },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.contents = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "popup-submit-btns" }, [
        _c(
          "button",
          {
            staticClass: "accept-btn",
            attrs: { type: "button" },
            on: { click: _vm.handleSubmit }
          },
          [_vm._v(_vm._s(_vm.$t("page.patient.advice.modal.RegisterButton")))]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "popup-btns" }, [
        _c(
          "button",
          {
            staticClass: "close-btn",
            attrs: { type: "button" },
            on: { click: _vm.handleClose }
          },
          [_vm._m(0)]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_delete ic-icon" }, [
      _c("span", { staticClass: "path1" }),
      _vm._v(" "),
      _c("span", { staticClass: "path2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }