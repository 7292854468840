<template>
  <section class="CreateMemo">
    <!-- 메모 타입 라디오 -->
    <div class="contents">
      <div class="radio-section">
        <span v-for="(type, index) of memoTypes" :key="index">
          <input :id="type.value" v-model="pickedType" type="radio" :value="type.value" />
          <label :for="type.value">{{ type.type }}</label>
        </span>
      </div>
      <div class="memo-title">
        <input v-model="title" type="text" :placeholder="titlePlaceholder" />
      </div>
      <div class="memo-contents">
        <textarea v-model="contents" :placeholder="memoPlaceholder" />
      </div>
    </div>

    <!-- 서밋버튼 -->
    <div class="popup-submit-btns" :class="submitDisable ? 'disabled' : ''">
      <button
        type="button"
        class="accept-btn"
        @click="handleSubmit"
        :disabled="submitDisable"
      >{{ edit ? $t("page.patient.documentation.modal.saveButton") : $t("page.patient.documentation.modal.registerButton") }}</button>
    </div>
    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button type="button" class="close-btn icon-btn" @click="handleClose">
        <span class="icon-ic_delete">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
export default {
  name: "CreateMemo",
  props: {
    patient: Object,
    submit: Function,
    memo: Object,
    edit: Boolean
  },
  data() {
    return {
      memoTypes: [
        {
          type: this.$t("page.patient.documentation.modal.type.memo"),
          value: "MEMO"
        },
        {
          type: this.$t("page.patient.documentation.modal.type.videoCall"),
          value: "VIDEOSESSION"
        }
      ],
      pickedType: "MEMO",
      title: "",
      contents: ""
    };
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleSubmit() {
      const newMemo = {
        division: this.pickedType,
        title: this.title,
        content: this.contents,
        patient: this.patient,
        id: this.memo ? this.memo.id : "",
        organization_id: this.clinic.id
      };
      // console.log(newMemo);
      this.submit(newMemo);
      this.handleClose();
    }
  },
  watch: {
    pickedType: function(newVal, oldVal) {
      if (!this.edit && newVal !== oldVal) {
        this.title = "";
        this.contents = "";
      }
    }
  },
  computed: {
    ...mapGetters({
      clinic: "user/clinic"
    }),
    submitDisable: function() {
      return this.title.length < 1 || this.contents.length < 1;
    },
    titlePlaceholder() {
      return this.$t("page.patient.documentation.modal.placeholder.title");
    },
    memoPlaceholder() {
      return this.$t("page.patient.documentation.modal.placeholder.memo");
    }
  },
  created() {
    if (this.edit) {
      this.title = this.memo.title;
      this.contents = this.memo.content;
      this.pickedType = this.memo.division;
    }
  }
};
</script>

<style lang="scss" scoped>
.CreateMemo {
  .contents {
    padding: 50px 24px 40px 24px;
    .radio-section {
      overflow: hidden;
      font-size: $fontSize6;
      span {
        float: left;
        margin-right: 32px;
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
    .memo-title {
      margin-top: 40px;
    }
    .memo-contents {
      margin-top: 16px;
      textarea {
        height: 170px;
      }
    }
  }
}
</style>
