import request from "@/utils/request";

export function fetchGetGamesList(device, side) {
  // device
  // 1. SMART_GLOVE
  // 2. SMART_KiDS
  // 3. SMART_BOARD
  // side
  // 1. LEFT
  // 2. RIGHT
  return request({
    url: `/home/api/v1/games/${device}/${side}`,
    method: "get"
  });
}

// 세션 게임 알고리즘 생성
export function fetchCreateGamesListByAlgorithm(
  device,
  side,
  patientId,
  games
) {
  const contentIds = games.reduce((acc, cur, i) => {
    const contentId = i === 0 ? `${cur.contentId}` : `,${cur.contentId}`;
    return acc + contentId;
  }, "");
  // console.log(contentIds);
  return request({
    url: `/home/api/v1/games/algorithm/${device}/${side}`,
    method: "get",
    params: {
      userId: patientId,
      contentIds
    }
  });
}

// 배치 이후의 세션내용 상세보기
export function fetchGetSessionDetailAfterBatch(sessionId) {
  return request({
    url: `/home/api/v1/session/${sessionId}`,
    method: "get"
  });
}
