import Confirm from "@/components/Confirm";
export const ErrorMixin = {
  methods: {
    handleError(error, from) {
      console.error(from, error);
      if (error) {
        this.$modal.show(
          Confirm,
          {
            type: "error",
            confirm: {
              title: "Error occurred",
              message: "Something is wrong with the server. Please try again."
            }
          },
          {
            height: "auto"
          }
        );
      }
    }
  }
};
