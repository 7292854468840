<template>
  <div class="TopMenu">
    <lang-select />
    <button type="button" class="user-profile" @click="linkProfile">
      <span class="icon-ic_group_fill">
        <span class="path1" />
        <span class="path2" />
      </span>
      <span v-if="avatar" class="user-image">
        <img :src="avatar" :alt="`${name}'s photo`">
      </span>
      <span />
      <span class="user-name">
        {{ name }}
        <span class="user-grade">{{ i18nLevel(level) }}</span>
      </span>
    </button>
    <button type="button" class="user-menu" @click="toggleAccountMenu">
      <span v-if="!accountMenu" class="icon-ic_chevron_down">
        <span class="path1" />
      </span>
      <span v-else class="icon-ic_chevron_up">
        <span class="path1" />
      </span>
    </button>
    <div v-if="accountMenu" class="account-menu" @click="toPortal">
      <router-link to="/" class="to-clinic">
        <span class="icon-ic_change">
          <span class="path1" />
          <span class="path2" />
          <span class="path3" />
          <span class="path4" />
          <span class="path5" />
          <span class="path6" />
        </span>
        <span>{{ $t("components.clinicTopMenu.moveToPortal") }}</span>
      </router-link>
      <button type="button" @click="handleLogout">
        <span class="icon-ic_logout">
          <span class="path1" />
        </span>
        <span>{{ $t("components.clinicTopMenu.logOut") }}</span>
      </button>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex";
import LangSelect from "./LangSelect";
export default {
  name: "TopMenu",
  components: {LangSelect},
  data() {
    return {
      accountMenu: false
    };
  },
  computed: {
    ...mapGetters({
      avatar: "user/avatar",
      name: "user/name",
      level: "user/level"
    })
  },
  methods: {
    ...mapActions({
      initForceSendBird: "chat/initForceSendBird"
    }),
    toPortal() {
      this.initForceSendBird();
    },
    i18nLevel(val) {
      if (val==="Main Owner") {
        return this.$t("common.role.mainOwner");
      } else if (val==="Owner") {
        return this.$t("common.role.owner");
      } else if (val==="Admin") {
        return this.$t("common.role.admin");
      }

      return this.$t("common.role.provider");
    },
    toggleAccountMenu() {
      this.accountMenu = !this.accountMenu;
    },
    handleLogout() {
      this.$store.dispatch("user/logout").then(result => {
        location.href = "/";
      });
    },
    linkProfile() {
      this.$router.push("/profile");
    }
  }
};
</script>

<style lang="scss" scoped>
.TopMenu {
  width: 100%;
  border-bottom: 1px solid $borderColor1;
  height: 60px;
  background: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 40px;
  .icon-btn {
    position: relative;
    & + .icon-btn {
      margin-left: 32px;
    }
    &.unread {
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        background: $uiColor1;
        border-radius: 50%;
      }
    }
  }
  .user-profile {
    display: flex;
    align-items: center;
    margin-left: 32px;
    position: relative;
    .icon-ic_group_fill {
      color: rgba(255, 255, 255, 0.8);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      background: $borderColor1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .user-image {
      width: 32px;
      height: 32px;
      overflow: hidden;
      border-radius: 50%;
      box-sizing: border-box;
      position: relative;
      background: $fontColor3;
      display: inline-block;
      position: absolute;

      left: 0;
      img {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .user-name {
      margin-left: 12px;
      white-space: nowrap;
      word-wrap: normal;
      text-align: left;
      .user-grade {
        display: block;
        width: 100%;
        font-size: $fontSize7;
        color: $fontColor2;
      }
    }
  }
  .user-menu {
    padding: 4px;
    margin-left: 12px;
    .icon-ic_chevron_down,
    .icon-ic_chevron_up {
      font-size: $fontSize6;
    }
  }
  .icon-ic_setting {
    color: $fontColor2;
  }
  .icon-ic_bell {
    color: $fontColor2;
  }
  .icon-ic_user {
    color: $fontColor2;
  }
  .account-menu {
    position: absolute;
    top: 55px;
    right: 30px;
    background: #ffffff;
    padding: 12px 0;
    overflow: hidden;
    border-radius: 12px;

    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #dcdfe6;
    z-index: 1;
    .to-clinic {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $fontColor2;
      font-size: $fontSize6;
      .icon-ic_change {
        margin-right: 8px;
      }
    }
    button {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      .icon-ic_logout {
        margin-right: 8px;
      }
    }
  }
}
</style>
