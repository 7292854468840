import moment from "moment";

let option = function option(aroms) {
  let legends = Object.keys(aroms);
  let xAxisValues = aroms.dates;
  let seriesData = [];
  let lineColor = ["#5aadf5", "#f0734d"];
  let dataSymbol = "(deg, °)";
  let yRangeMax = aroms.yRangeMax;

  // Finger Flex/Ext : 0-100 (%)
  // Forearm Pron/Sup : 0-180 (각도)
  // Wrist Flex/Ext : 0-160 (각도)
  // Wrist R.D./ U.D. : 0-80 (각도)

  if (aroms.dataSymbol !== undefined && aroms.dataSymbol !== null) {
    dataSymbol = aroms.dataSymbol;
  }

  if (aroms.lineColorMode === "singleTarget") {
    lineColor = ["#f0734d"];
  }

  for (let motion in aroms.motionMap) {
    seriesData.push({
      name: motion,
      connectNulls: true,
      stack: false,
      type: "line",
      data: aroms.motionMap[motion]
    });
  }
  return {
    tooltip: {
      formatter: function(params, ticket, callback) {
        let tooltip = "";

        let seriesName = params.seriesName;
        let value = params.value;
        let date = xAxisValues[params.dataIndex];

        tooltip = seriesName + "<br/>";
        tooltip += date + ": " + value.toFixed(1) + dataSymbol;

        return tooltip;
      }
    },
    color: lineColor,
    xAxis: {
      nameTextStyle: {
        color: "rgba(68, 74, 88, 0.4)"
      },
      axisLine: {
        lineStyle: {
          color: "rgba(68, 74, 88, 0.15)"
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        inside: false,
        formatter: function(value, index) {
          let date = moment(value, "YYYY-MM-DD");
          if (index === 0) {
            date = date.format("Y.M.D");
          } else {
            date = date.format("M.D");
          }
          return date;
        },
        textStyle: {
          color: "#444a58",
          fontSize: 11,
          fontFamily: "Poppins"
        }
      },
      data: xAxisValues,
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.02
        }
      },
      splitArea: {
        show: true,
        areaStyle: {
          color: ["rgba(255, 255, 255, 0)", "rgba(68, 74, 88, 0.05)"]
        }
      }
    },
    yAxis: {
      name: dataSymbol,
      nameTextStyle: {
        color: "rgba(68, 74, 88, 0.4)"
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        textStyle: {
          color: "#444a58",
          fontSize: 11,
          fontFamily: "Poppins"
        }
      },
      min: 0,
      max: yRangeMax
    },
    dataZoom: [
      {
        type: "slider",
        start: 0,
        end: 100
      },
      {
        type: "inside",
        start: 0,
        end: 100
      }
    ],
    legend: {
      data: legends
    },
    series: seriesData
  };
};

export default option;
