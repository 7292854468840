<template>
  <div class="loading">
    <Circle2 class="chart-spinner" />Loading...
  </div>
</template>
<script>
import { Circle2 } from "vue-loading-spinner";
export default {
  name: "Loading",
  components: {
    Circle2
  }
};
</script>
<style lang="scss" scoped>
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $fontColor1;
  font-size: $fontSize6;
  background: #ffffff;
  .chart-spinner {
    margin-bottom: 16px;
    border-color: #f0734d #d0d4dc #d0d4dc !important;
  }
}
</style>
