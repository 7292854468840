let option  = function option(movements) {
  return  {
    legend: {
      show: false,
    },
    /*
    grid: {
      top: 20,
      bottom: 20
    },

     */
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      data: movements.dates,
      axisLine: {
        show: false,
        lineStyle: {
          color: '#f1f1f1'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        textStyle: {
          color: 'rgba(68, 74, 88, 0.4)',
          fontSize: 11,
          fontFamily: 'Poppins'
        },
        formatter: function (value, index) {
          if (index == 0 || index == 6) {
            let date = new Date(value);
            let texts = [(date.getMonth() + 1), date.getDate()];
            return texts.join('.');
          }
          else {
            return '';
          }
        }
      }
    },
    yAxis: {
      name: '',
      nameTextStyle: {
        color: 'rgba(68, 74, 88, 0.4)'
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: '#f1f1f1'
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false,
        textStyle: {
          color: '#444a58',
          fontSize: 11,
          fontFamily: 'Poppins'
        },
        formatter: function (value, index) {
          return (value/100.0).toFixed(2)
        }
      },
      boundaryGap: ['0%', '0%'],
      splitLine: {
        show: false
      }
    },
    series: [{
      name: 'distance',
      type: 'line',
      data: movements.distances,
      showSymbol: false,
      itemStyle: {
        normal: {
          color: '#5aadf5'
        }
      },
      areaStyle: {
        normal: {
          color: 'rgba(90,173,244,0.1)'
        }
      }
    }]
  }
}

export default option
