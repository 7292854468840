<template>
  <section class="report-section free-exploration">
    <h1>Shape Drawing(Clockwise)</h1>
    <div class="report-contents">
      <div class="table"  v-if="left && right">
        <table>
          <thead>
          <tr>
            <th width="127"></th>
            <th width="40"></th>
            <th width="55">[{{ leftId }}]</th>
            <th width="55">[{{ rightId }}]</th>
            <th width="45"></th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <th>Shape Similarity</th>
              <th>Inner</th>
              <td>
                {{ leftData.innerDataCW ? leftData.innerDataCW.pathFollowIndex : 0 }} %
              </td>
              <td>
                {{ rightData.innerDataCW ? rightData.innerDataCW.pathFollowIndex : 0 }} %
              </td>
              <td>
                {{diffLeftRight(
                leftData.innerDataCW ? leftData.innerDataCW.pathFollowIndex : 0,
                rightData.innerDataCW ? rightData.innerDataCW.pathFollowIndex : 0
                )}}
                <img :src="'/img/icons/' + upDownIcon(
                        leftData.innerDataCW ? leftData.innerDataCW.pathFollowIndex : 0,
                            rightData.innerDataCW ? rightData.innerDataCW.pathFollowIndex : 0
                      )" width="10" height="10"/>
              </td>
            </tr>
            <tr>
              <th></th>
              <th>Outer</th>
              <td>
                {{ leftData.outerDataCW ? leftData.outerDataCW.pathFollowIndex : 0 }} %
              </td>
              <td>
                {{ rightData.outerDataCW ? rightData.outerDataCW.pathFollowIndex : 0 }} %
              </td>
              <td>
                {{diffLeftRight(
                leftData.outerDataCW ? leftData.outerDataCW.pathFollowIndex : 0,
                rightData.outerDataCW ? rightData.outerDataCW.pathFollowIndex : 0
                )}}
                <img :src="'/img/icons/' + upDownIcon(
                        leftData.outerDataCW ? leftData.outerDataCW.pathFollowIndex : 0,
                            rightData.outerDataCW ? rightData.outerDataCW.pathFollowIndex : 0
                      )" width="10" height="10"/>
              </td>
            </tr>
            <tr>
              <th>Circular Symmetry</th>
              <th>Inner</th>
              <td>
                {{ leftData.innerDataCW ? leftData.innerDataCW.circleIndex : 0 }} %
              </td>
              <td>
                {{ rightData.innerDataCW ? rightData.innerDataCW.circleIndex : 0 }} %
              </td>
              <td>
                {{diffLeftRight(
                  leftData.innerDataCW ? leftData.innerDataCW.circleIndex : 0 ,
                  rightData.innerDataCW ? rightData.innerDataCW.circleIndex : 0
                )}}
                <img :src="'/img/icons/' + upDownIcon(
                    leftData.innerDataCW ? leftData.innerDataCW.circleIndex : 0 ,
                    rightData.innerDataCW ? rightData.innerDataCW.circleIndex : 0
                      )" width="10" height="10"/>
              </td>
            </tr>
            <tr>
              <th></th>
              <th>Outer</th>
              <td>
                {{ leftData.outerDataCW ? leftData.outerDataCW.circleIndex : 0 }} %
              </td>
              <td>
                {{ rightData.outerDataCW ? rightData.outerDataCW.circleIndex : 0 }} %
              </td>
              <td>
                {{diffLeftRight(
                leftData.outerDataCW ? leftData.outerDataCW.circleIndex : 0 ,
                rightData.outerDataCW ? rightData.outerDataCW.circleIndex : 0
                )}}
                <img :src="'/img/icons/' + upDownIcon(
                    leftData.outerDataCW ? leftData.outerDataCW.circleIndex : 0 ,
                    rightData.outerDataCW ? rightData.outerDataCW.circleIndex : 0
                      )" width="10" height="10"/>
              </td>
            </tr>
            <tr>
              <th>Duration</th>
              <th>Inner</th>
              <td>
                {{ leftData.innerDataCW ? leftData.innerDataCW.time : 0 }} secs
              </td>
              <td>
                {{ rightData.innerDataCW ? rightData.innerDataCW.time : 0 }} secs
              </td>
              <td>
                {{diffLeftRight(
                leftData.innerDataCW ? leftData.innerDataCW.time : 0 ,
                rightData.innerDataCW ? rightData.innerDataCW.time : 0
                )}}
                <img :src="'/img/icons/' + upDownIcon(
                    leftData.innerDataCW ? leftData.innerDataCW.time : 0 ,
                rightData.innerDataCW ? rightData.innerDataCW.time : 0
                      )" width="10" height="10"/>
              </td>
            </tr>
            <tr>
              <th></th>
              <th>Outer</th>
              <td>
                {{ leftData.outerDataCW ? leftData.outerDataCW.time : 0 }} secs
              </td>
              <td>
                {{ rightData.outerDataCW ? rightData.outerDataCW.time : 0 }} secs
              </td>
              <td>
                {{diffLeftRight(
                leftData.outerDataCW ? leftData.outerDataCW.time : 0 ,
                rightData.outerDataCW ? rightData.outerDataCW.time : 0
                )}}
                <img :src="'/img/icons/' + upDownIcon(
                    leftData.outerDataCW ? leftData.outerDataCW.time : 0 ,
                rightData.outerDataCW ? rightData.outerDataCW.time : 0
                      )" width="10" height="10"/>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
    </div>
  </section>
</template>

<script>
import jsonQuery from "json-query";
export default {
  name: "EvaluationShapeDrawingClockwise",
  data() {
    return {
      leftData: {
        innerDataCW: null,
        outerDataCW: null,
        innerDataCCW: null,
        outerDataCCW: null,
      },
      rightData: {
        innerDataCW: null,
        outerDataCW: null,
        innerDataCCW: null,
        outerDataCCW: null,
      },
    };
  },
  props: {
    leftId: {
      type: Number
    },
    rightId: {
      type: Number
    },
    left: {
      type: Object
    },
    right: {
      type: Object
    }
  },
  watch: {
    left(newValue, oldValue) {
      this.setDisplayData(this.left, this.leftData);
    },
    right(newValue, oldValue) {
      this.setDisplayData(this.right, this.rightData);
    }
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    diffLeftRight(left, right) {
      if(left === right)
      {
        return '-'
      } else
      {
        return  Math.abs(right - left).toFixed(1)
      }
    },
    upDownIcon(left, right) {
      left = parseFloat(left);
      right = parseFloat(right);
      if(left === right)
      {
        return 'blank.png'
      } else if(left > right) {
        return 'arrow_red.png'
      } else {
        return 'arrow_blue.png'
      }
    },
    async drawChart() {
      this.setDisplayData(this.left, this.leftData);
      this.setDisplayData(this.right, this.rightData);
    },
    async setDisplayData(src, target) {
      let innerDataCW, outerDataCW, innerDataCCW, outerDataCCW;
      let queryString = "[circleSize=SMALL & tracingDirection=CW]";
      let innerDataCWJson = jsonQuery(queryString, {
        data: src.traceDrawResultModelList
      }).value;

      if (innerDataCWJson) {
        innerDataCW = {};
        innerDataCW.pathFollowIndex = innerDataCWJson.pathFollowIndexPercentage.toFixed(
          0
        );
        innerDataCW.time = innerDataCWJson.time.toFixed(0);
        innerDataCW.circleIndex = innerDataCWJson.circleIndexPercentage.toFixed(
          0
        );
      }

      queryString = "[circleSize=LARGE & tracingDirection=CW]";
      let outerDataCWJson = jsonQuery(queryString, {
        data: src.traceDrawResultModelList
      }).value;

      // console.log(outerDataCWJson);
      if (outerDataCWJson) {
        outerDataCW = {};
        outerDataCW.pathFollowIndex = outerDataCWJson.pathFollowIndexPercentage.toFixed(
          0
        );
        outerDataCW.time = outerDataCWJson.time.toFixed(0);
        outerDataCW.circleIndex = outerDataCWJson.circleIndexPercentage.toFixed(
          0
        );
      }

      queryString = "[circleSize=LARGE & tracingDirection=CCW]";
      // homedata의 문제로 값이 바뀌어서 오기때문에 inner지만 large로 바꿨음
      let innerDataCCWJson = jsonQuery(queryString, {
        data: src.traceDrawResultModelList
      }).value;

      // console.log(innerDataCCWJson);
      if (innerDataCCWJson) {
        innerDataCCW = {};
        innerDataCCW.pathFollowIndex = innerDataCCWJson.pathFollowIndexPercentage.toFixed(
          0
        );
        innerDataCCW.time = innerDataCCWJson.time.toFixed(0);
        innerDataCCW.circleIndex = innerDataCCWJson.circleIndexPercentage.toFixed(
          0
        );
      }
      queryString = "[circleSize=SMALL & tracingDirection=CCW]";
      // homedata의 문제로 값이 바뀌어서 오기때문에 outer지만 small로 바꿨음
      let outerDataCCWJson = jsonQuery(queryString, {
        data: src.traceDrawResultModelList
      }).value;

      // console.log(outerDataCCWJson);
      if (outerDataCCWJson) {
        outerDataCCW = {};
        outerDataCCW.pathFollowIndex = outerDataCCWJson.pathFollowIndexPercentage.toFixed(
          0
        );
        outerDataCCW.time = outerDataCCWJson.time.toFixed(0);
        outerDataCCW.circleIndex = outerDataCCWJson.circleIndexPercentage.toFixed(
          0
        );
      }
      target.innerDataCW = innerDataCW ? innerDataCW : null;
      target.outerDataCW = outerDataCW ? outerDataCW : null;
      target.innerDataCCW = innerDataCCW ? innerDataCCW : null;
      target.outerDataCCW = outerDataCCW ? outerDataCCW : null;
    }
  }
};
</script>
