<template>
  <section class="clinic">
    <div class="main-view">
      <TopMenu />
      <div class="clinic">
        <ClinicMenu :style="{ marginTop: '-60px' }" />
        <div class="clinic-contents" :style="{ height: `${windowHeight}px` }">
          <router-view ref="clinicView" class="view custom-scrollbar" />
        </div>
      </div>
    </div>
    <modals-container />
  </section>
</template>
<script>
/* eslint-disable */

import TopMenu from "@/components/ClinicTopMenu";
import ClinicMenu from "@/components/ClinicMenu";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    TopMenu,
    ClinicMenu
  },
  data() {
    return {
      windowHeight: 0
    };
  },
  methods: {
    handleResize: function() {
      // console.log("resize");
      const checkWindowHeight =
        window.innerHeight <= 790 ? 790 : window.innerHeight;
      this.windowHeight = checkWindowHeight;
    }
  },
  computed: {
    ...mapGetters({
      level: "user/level",
      clinic: "user/clinic"
    })
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    const checkWindowHeight =
      window.innerHeight <= 790 ? 790 : window.innerHeight;
    this.windowHeight = checkWindowHeight;
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>
<style lang="scss" scoped>
.clinic {
  display: flex;
  font-family: "manrope3";
  min-height: 790px;
  position: relative;
  .main-view {
    width: 100%;
    min-width: 1440px;
    .clinic {
      display: flex;
      .clinic-contents {
        padding: 16px 24px 24px 24px;
        box-sizing: border-box;
        width: 100%;
        background: $popupBgColor1;
      }
      .view {
        height: 100%;
        width: 1170px;
        overflow-y: auto;
        position: relative;
        box-sizing: border-box;
      }
    }
  }
}
</style>
