import Vue from "vue";
import VueI18n from "vue-i18n";

import { getLanguage } from "@/utils/cookies.js";

// element-ui built-in lang
import elementEnLocale from "element-ui/lib/locale/lang/en";
import elementKoLocale from "element-ui/lib/locale/lang/ko";

// User defined lang
import enLocale from "./en";
import koLocale from "./ko";

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  ko: {
    ...koLocale,
    ...elementKoLocale
  }
};

export const getLocale = () => {
  const cookieLanguage = getLanguage();
  if (cookieLanguage) {
    return cookieLanguage;
  }

  // const language = navigator.language.toLowerCase()
  // const locales = Object.keys(messages)
  // for (const locale of locales) {
  //   if (language.indexOf(locale) > -1) {
  //     return locale
  //   }
  // }

  // 한국 시간대임. 원래 +9인데 date는 거꾸로나온다...
  if (new Date().getTimezoneOffset() / 60 === -9) {
    return "ko";
  }

  return "en";
};

const i18n = new VueI18n({
  locale: getLocale(),
  messages
});

export default i18n;
