<template>
  <div class="Publisher" />
</template>

<script>
/* eslint-disable vue/require-default-prop */

import OT from "@opentok/client";
export default {
  name: "Publisher",
  props: {
    session: {
      type: OT.Session,
      required: false
    },
    opts: {
      type: Object,
      required: false
    }
  },
  mounted() {
    const publisher = OT.initPublisher(this.$el, this.opts, error => {
      if (error) {
        this.$emit("error", error);
      } else {
        this.$emit("publisherCompleted");
      }
    });
    this.$emit("publisherCreated", publisher);
    const publish = () => {
      this.session.publish(publisher, error => {
        if (error) {
          this.$emit("error", error);
        } else {
          this.$emit("publisherConnected", publisher);
        }
      });
    };
    if (this.session && this.session.isConnected()) {
      publish();
    }
    if (this.session) {
      this.session.on("sessionConnected", publish);
    }
  }
};
</script>
