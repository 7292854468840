var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "ApprovalStatus" }, [
    _vm.status === 0
      ? _c("div", { staticClass: "contents" }, [
          _c("h1", [
            _vm._v(_vm._s(_vm.$t("components.approveStatus.reviewing")))
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "discription",
              attrs: { id: "requested-discription" }
            },
            [
              _vm.lang === "ko"
                ? _c("div", [
                    _vm._v("\n        가입 요청을 검토중입니다."),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "clinic-name" }, [
                      _vm._v(_vm._s(_vm.clinic.name))
                    ]),
                    _vm._v("의 승인을 기다려주세요."),
                    _c("br"),
                    _c("br"),
                    _vm._v("\n        클리닉을 변경하려면\n        "),
                    _c(
                      "a",
                      {
                        staticClass: "clickable",
                        on: { click: _vm.handleClickHere }
                      },
                      [_vm._v("여기")]
                    ),
                    _vm._v("를 클릭하세요.\n      ")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.lang === "en"
                ? _c("div", [
                    _vm._v("\n        Your sign up request is under review."),
                    _c("br"),
                    _vm._v(
                      "\n        Please wait for the approval from\n        "
                    ),
                    _c("span", { staticClass: "clinic-name" }, [
                      _vm._v(_vm._s(_vm.clinic.name))
                    ]),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "\n        if you want to change your clinic, click\n        "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "clickable",
                        on: { click: _vm.handleClickHere }
                      },
                      [_vm._v("here.")]
                    )
                  ])
                : _vm._e()
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.status === 2
      ? _c("div", { staticClass: "contents" }, [
          _c("h1", [_vm._v(_vm._s(_vm.$t("components.approveStatus.denied")))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "discription", attrs: { id: "denied-discription" } },
            [
              _vm.lang === "ko"
                ? _c("div", [
                    _c("span", { staticClass: "clinic-name" }, [
                      _vm._v(_vm._s(_vm.clinic.name))
                    ]),
                    _vm._v("\n        으로부터 가입 요청이 거절되었습니다."),
                    _c("br"),
                    _vm._v("\n        올바른 클리닉을 선택했는지 확인 후"),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "clickable",
                        on: { click: _vm.handleRequestReSent }
                      },
                      [_vm._v("다시 요청해주세요.")]
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v("\n        클리닉을 변경하려면\n        "),
                    _c(
                      "a",
                      {
                        staticClass: "clickable",
                        on: { click: _vm.handleClickHere }
                      },
                      [_vm._v("여기")]
                    ),
                    _vm._v("를 클릭하세요.\n      ")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.lang === "en"
                ? _c("div", [
                    _vm._v(
                      "\n        Your sign up request has been denied by\n        "
                    ),
                    _c("span", { staticClass: "clinic-name" }, [
                      _vm._v(_vm._s(_vm.clinic.name))
                    ]),
                    _c("br"),
                    _vm._v(
                      "\n        Please check if you have chosen right clinic and\n        "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "clickable",
                        on: { click: _vm.handleRequestReSent }
                      },
                      [_vm._v("send a request again.")]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "\n        if you want to change your clinic, click\n        "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "clickable",
                        on: { click: _vm.handleClickHere }
                      },
                      [_vm._v("here.")]
                    )
                  ])
                : _vm._e()
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "submit-btns" }, [
      _c(
        "button",
        { attrs: { type: "button" }, on: { click: _vm.handleClickLogout } },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("components.approveStatus.logout")) +
              "\n    "
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }