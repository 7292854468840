var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "GlobalMenu" },
    [
      _c("router-link", { staticClass: "nav-home", attrs: { to: "/" } }, [
        _c("img", { attrs: { src: _vm.symbol, alt: "neofect symbol" } })
      ]),
      _vm._v(" \n  "),
      _c(
        "router-link",
        {
          ref: "calendar",
          staticClass: "nav-calendar nav-btn",
          attrs: { to: "/calendar" }
        },
        [
          _c("span", { staticClass: "icon-ic_date" }, [
            _c("span", { staticClass: "path1" }),
            _vm._v(" "),
            _c("span", { staticClass: "path2" }),
            _vm._v(" "),
            _c("span", { staticClass: "path3" }),
            _vm._v(" "),
            _c("span", { staticClass: "path4" }),
            _vm._v(" "),
            _c("span", { staticClass: "path5" }),
            _vm._v(" "),
            _c("span", { staticClass: "path6" }),
            _vm._v(" "),
            _c("span", { staticClass: "path7" }),
            _vm._v(" "),
            _c("span", { staticClass: "path8" }),
            _vm._v(" "),
            _c("span", { staticClass: "path9" }),
            _vm._v(" "),
            _c("span", { staticClass: "path10" })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          ref: "patient",
          staticClass: "nav-patients nav-btn",
          attrs: { to: "/patient" }
        },
        [
          _c("span", { staticClass: "icon-ic_group" }, [
            _c("span", { staticClass: "path1" }),
            _vm._v(" "),
            _c("span", { staticClass: "path2" }),
            _vm._v(" "),
            _c("span", { staticClass: "path3" }),
            _vm._v(" "),
            _c("span", { staticClass: "path4" })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }