<template>
  <div
    ref="eventDetail"
    class="EventDetail"
    :class="eventDetailInfo.element ? '' : 'center'"
    :style="{ top: `${coordinates.y}px`, left: `${coordinates.x}px` }"
  >
    <!-- Video Session -->
    <div class="Event-contents">
      <div :class="'Event-title ' + eventDetailInfo.className">
        {{
          eventDetailInfo.className === "group4"
            ? $t("page.calendar.eventDetail.type.providerLive")
            : typeFilter(eventDetailInfo.title)
        }}
      </div>
      <div class="Event-date">
        <span class="icon-ic_time">
          <span class="path1" />
          <span class="path2" />
        </span>
        <div
          class="date-string"
          v-html="convertTimeString(eventDetailInfo.start, eventDetailInfo.end)"
        />
      </div>
      <div v-if="eventDetailInfo.className === 'group2'" class="Event-game">
        <span class="icon-ic_star">
          <span class="path1" />
        </span>
        {{
          eventDetailInfo.game === undefined
            ? "Nothing chosen"
            : eventDetailInfo.game.game_title
        }}
      </div>
      <div v-else-if="eventDetailInfo.className === 'group4'">
        <div class="Event-live-contents">
          <div class="subject">
            <span>Topic</span>
            {{ eventDetailInfo.title }}
          </div>
          <div class="summary">{{ eventDetailInfo.contents }}</div>
        </div>
        <div class="Event-live-link">
          <span class="icon-ic_connecting">
            <span class="path1" />
            <span class="path2" />
          </span>
          <a
            :href="eventDetailInfo.contents_link"
            target="_blank"
          >{{ eventDetailInfo.contents_link }}</a>
        </div>
      </div>
      <div
        v-else-if="eventDetailInfo.className === 'group5'"
        class="Event-general-contents"
      >{{ eventDetailInfo.contents }}</div>

      <div
        v-if="
          eventDetailInfo.className === 'group1' ||
            eventDetailInfo.className === 'group2' ||
            eventDetailInfo.className === 'group3' ||
            eventDetailInfo.className === 'group6'
        "
        class="Event-patients"
      >
        <div class="patients-heading">
          <span class="icon-ic_group">
            <span class="path1" />
            <span class="path2" />
            <span class="path3" />
            <span class="path4" />
            <span class="path5" />
          </span>
          {{ attendeeFilter(eventDetailInfo.patients.length) }}
        </div>
        <div class="patients-list">
          <ul>
            <li v-for="(patient, index) of eventDetailInfo.patients" :key="index">
              <div class="patient-name">
                <span class="icon-ic_check_circle_fill accept status-icon">
                  <span class="path1" />
                  <span class="path2" />
                </span>
                <!-- <span
                  v-if="patient.attendance_state === 'WAIT'"
                  class="icon-ic_check_circle_fill wait status-icon"
                >
                  <span class="path1" />
                  <span class="path2" />
                </span>
                <span
                  v-if="patient.attendance_state === 'ACCEPT'"
                  class="icon-ic_check_circle_fill accept status-icon"
                >
                  <span class="path1" />
                  <span class="path2" />
                </span>
                <span
                  v-if="patient.attendance_state === 'REFUSE'"
                  class="icon-ic_delete_circle_fill refuse status-icon"
                >
                  <span class="path1" />
                  <span class="path2" />
                  <span class="path3" />
                </span>-->
                <span>{{ patient.name }}</span>
              </div>
              <div class="patient-time">{{ convertTimeString(patient.start, patient.end) }}</div>
            </li>
          </ul>
        </div>
        <div if="eventDetailInfo.className === 'group1'" class="Event-videocall">
          <div class="Event-live-link">
            <span class="icon-ic_connecting">
              <span class="path1" />
              <span class="path2" />
            </span>
            <!-- <button
              type="button"
              v-clipboard="`${baseURL}/${eventDetailInfo.id}`"
              v-clipboard:success="copySucess"
              v-clipboard:error="copyError"
              class="url-btn"
            >
              <abbr
                :title="`${baseURL}/${eventDetailInfo.id}`"
              >{{ `${baseURL}/${eventDetailInfo.id}` }}</abbr>
            </button>-->
            <button
              v-clipboard="`${baseURL}/${eventDetailInfo.id}`"
              v-clipboard:success="copySucess"
              v-clipboard:error="copyError"
              type="button"
              class="copy-btn"
            >{{ $t("page.calendar.eventDetail.copyLink") }}</button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="eventDetailInfo.className === 'group1'"
      class="popup-submit-btns"
      :class="overTime ? 'disabled' : ''"
    >
      <button type="button" class="accept-btn" @click="handleVideoCall">{{ $t("page.calendar.eventDetail.button.videoCall") }}</button>
    </div>
    <div
      v-else-if="eventDetailInfo.className === 'group3'"
      class="popup-submit-btns"
      :class="overTime ? 'disabled' : ''"
    >
      <button
        type="button"
        class="accept-btn"
        @click="handleStartScreenSharing"
      >{{ $t("page.calendar.eventDetail.button.screenSharing") }}</button>
    </div>
    <div v-else-if="eventDetailInfo.className === 'group6'" class="popup-submit-btns">
      <div class="primary-type-btns">
        <button type="button" class="accept-btn" @click="confirmRequest">Confirm</button>
      </div>
      <div class="secondary-type-btns decline">
        <button type="button" @click="denyRequest">Decline</button>
      </div>
    </div>

    <div class="popup-btns">
      <!-- 팝업 컨트롤 버튼 -->
      <button
        v-if="eventDetailInfo.className !== 'group6'"
        type="button"
        class="del-btn icon-btn"
        @click="handleDeleteEvent"
      >
        <span class="icon-ic_bin">
          <span class="path1" />
          <span class="path2" />
          <span class="path3" />
          <span class="path4" />
          <span class="path5" />
        </span>
      </button>
      <button
        v-if="
          eventDetailInfo.className !== 'group1' &&
            eventDetailInfo.className !== 'group6'
        "
        type="button"
        class="edit-btn icon-btn"
        @click.once="handleEditEvent"
      >
        <span class="icon-ic_edit">
          <span class="path1" />
          <span class="path2" />
          <span class="path3" />
        </span>
      </button>
      <button type="button" class="close-btn icon-btn" @click="handleClose">
        <span class="icon-ic_delete">
          <span class="path1" />
          <span class="path2" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Confirm from "@/components/Confirm";
import { fetchConfirmEvent } from "@/api/calendar";
import { mapGetters } from "vuex";
export default {
  name: "EventDetail",
  props: {
    eventDetailInfo: Object,
    startScreenSharing: Function,
    startVideoCall: Function,
    handleDetailPopupClose: Function,
    changeEventSources: Function,
    deleteEvent: Function,
    denyEvent: Function,
    calendarSize: Object,
    handleError: Function,
    editEvent: Function
  },
  data() {
    return {
      coordinates: {
        x: 0,
        y: 0
      },
      overTime: false,
      baseURL: window.location.href
    };
  },
  computed: {
    ...mapGetters({
      lang: "lang/language"
    })
  },
  methods: {
    typeFilter(value) {
      if (value==='Video Call') {
        return this.$t("page.calendar.eventDetail.type.videoCall");
      } else if (value==='Screen Sharing') {
        return this.$t("page.calendar.eventDetail.type.screenSharing");
      }
      return value;
    },
    attendeeFilter(value) {
      if (this.lang==='ko') {
        return `${this.$t("page.calendar.eventDetail.attendee")} ${value}명`
      } else {
        let endChar = "";
        if (value>1) {
          endChar = "s";
        }
        return `${value} ${this.$t("page.calendar.eventDetail.attendee")}${endChar}`
      }
    },
    calculatePosition() {
      let calendarSize = this.calendarSize;
      let thisPopup = this.$el;
      const from = this.eventDetailInfo.element;
      if (!from) {
        return (this.coordinates = {
          x: 0,
          y: 0
        });
      }
      const x = from.getBoundingClientRect().left - 70;
      const y = from.getBoundingClientRect().top - 60;
      let calculateX =
        x + thisPopup.offsetWidth + from.offsetWidth < calendarSize.width
          ? x + from.offsetWidth + 8
          : x - thisPopup.offsetWidth - 9;
      let calculateY =
        y + thisPopup.offsetHeight < calendarSize.height
          ? y
          : y - thisPopup.offsetHeight + from.offsetHeight;
      if (this.eventDetailInfo.isPatient) {
        const p_x = from.getBoundingClientRect().left - 346;
        const p_y =
          from.getBoundingClientRect().top - 196 + this.$parent.$el.scrollTop;
        calculateX =
          p_x + thisPopup.offsetWidth + from.offsetWidth < calendarSize.width
            ? p_x + from.offsetWidth + 5
            : p_x - thisPopup.offsetWidth - 5;
        calculateY =
          p_y + thisPopup.offsetHeight < calendarSize.height
            ? p_y
            : p_y - thisPopup.offsetHeight + from.offsetHeight;
      }
      this.coordinates = {
        x: calculateX,
        y: calculateY
      };
    },
    handleStartScreenSharing() {
      if (this.overTime) return;
      const eventId = this.eventDetailInfo.id;
      this.startScreenSharing(eventId);
    },
    handleVideoCall() {
      if (this.overTime) return;
      const now = new Date();
      const eventStart = new Date(this.eventDetailInfo.start);
      const startDisable = (eventStart - now) / 1000 / 60 > 5;
      if (startDisable) {
        // 테스트를 위해 disable 주석처리
        // return this.$modal.show(
        //   Confirm,
        //   {
        //     type: "not-yet",
        //     confirm: {
        //       title: "Try again later",
        //       message:
        //         "You can start Video Call 5mins before the appointed time."
        //     }
        //   },
        //   {
        //     height: "auto"
        //   }
        // );
      }

      const eventId = this.eventDetailInfo.id;
      const patient = this.eventDetailInfo.patients[0];

      this.startVideoCall(eventId, patient);
    },
    handleClose() {
      this.handleDetailPopupClose();
    },
    convertTimeString(start, end) {
      const startDate = this.$moment(start);
      const endDate = this.$moment(end);
      const endDateTime =
        endDate.format("A h:mm") === "PM 11:59"
          ? "AM 12:00"
          : endDate.format("A h:mm");
      let dateString = `${startDate.format(
        "dddd, MMMM D, A h:mm"
      )} - ${endDateTime}`;
      if (
        this.eventDetailInfo.allday_yn &&
        this.eventDetailInfo.repeat_division === "NONE"
      ) {
        // Allday인 경우 날짜표기
        dateString = `${startDate.format("dddd, MMMM D")}`;
      } else if (
        this.eventDetailInfo.allday_yn &&
        this.eventDetailInfo.repeat_division === "DAILY"
      ) {
        // Allday이면서 Daily인 경우
        dateString = `${startDate.format("dddd, MMMM D")} - ${endDate.format(
          "dddd, MMMM D"
        )}`;
      } else if (
        !this.eventDetailInfo.allday_yn &&
        this.eventDetailInfo.repeat_division === "DAILY"
      ) {
        // Allday아니면서 Daily인 경우
        dateString = `<span>${startDate.format(
          "dddd, MMMM D"
        )} - ${endDate.format("dddd, MMMM D")}</span>
        <span>${startDate.format("A h:mm")} - ${endDateTime}</span>`;
      }

      return dateString;
    },
    handleDeleteEvent() {
      this.$modal.show(
        Confirm,
        {
          type: "delete",
          submit: this.confirm_delete_ok,
          confirm: {
            title: this.$t("page.calendar.eventDetail.modal.title"),
            message: this.$t("page.calendar.eventDetail.modal.description")
          }
        },
        {
          height: "auto"
        }
      );
    },
    confirm_delete_ok() {
      this.deleteEvent(this.eventDetailInfo.className, this.eventDetailInfo.id);
    },
    handleEditEvent() {
      this.editEvent(this.eventDetailInfo);
    },
    denyRequest() {
      //screen session request deny
      this.denyEvent(this.eventDetailInfo.className, this.eventDetailInfo.id);
    },
    confirmRequest() {
      //screen session request confirm
      fetchConfirmEvent(this.eventDetailInfo.id)
        .then(result => {
          const newSources = {
            className: "group1",
            events: result
          };
          this.changeEventSources(
            "CONFIRM",
            newSources,
            this.eventDetailInfo.id
          );
          this.handleClose();
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    copySucess: function(e) {
      this.$toast.open({
        message: `Link copied`,
        type: "default",
        duration: 2000
      });
    },
    copyError: function(e) {
      this.$toast.open({
        message: `Link copy failed`,
        type: "error",
        duration: 2000
      });
    }
  },
  created() {
    const now = new Date();
    const eventStart = new Date(this.eventDetailInfo.start);
    const eventEnd = new Date(this.eventDetailInfo.end);
    if ((eventStart - now) / 1000 / 60 > 5) {
      // 테스트를 위해 disable 주석처리
      // this.overTime = true;
    } else if (
      (eventStart - now) / 1000 / 60 < -5 &&
      (eventEnd - now) / 1000 / 60 < -10
    ) {
      this.overTime = true;
    }
  },
  mounted() {
    this.calculatePosition();
  }
};
</script>

<style lang="scss" scoped>
.EventDetail {
  z-index: 2;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: $modalWidth1;
  border-radius: 0.5rem;
  background: #ffffff;
  padding: 0.5rem;
  box-sizing: border-box;
  position: absolute;
  padding: 50px 0 0 0;
  color: $fontColor1;
  &.center {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
  }
  .Event-contents {
    padding: 0 24px 40px 24px;
    .Event-title {
      font-size: $fontSize2;
      font-weight: 500;
      padding-left: 32px;
      line-height: 40px;
      position: relative;
      &::before {
        content: "";
        width: 16px;
        height: 16px;
        display: inline-block;
        position: absolute;
        top: 20px;
        left: 0;
        border-radius: 50%;
        transform: translate(0, -50%);
      }
      &.group1 {
        &::before {
          background: $groupColor1;
        }
      }
      &.group2 {
        &::before {
          background: $groupColor2;
        }
      }
      &.group3 {
        &::before {
          background: $groupColor3;
        }
      }
      &.group4 {
        &::before {
          background: $groupColor4;
        }
      }
      &.group5 {
        &::before {
          background: $groupColor5;
        }
      }
      &.group6 {
        &::before {
          background: $groupColor6;
        }
      }
    }
    .Event-date {
      padding-top: 26px;
      font-size: $fontSize5;
      color: $fontColor2;
      line-height: 28px;
      display: flex;
      .icon-ic_time {
        margin-right: 16px;
        margin-top: 2px;
      }
      .date-string {
        span {
          display: block;
        }
      }
    }
    .Event-game {
      padding-top: 12px;
      font-size: $fontSize5;
      display: flex;
      align-items: center;
      color: $fontColor2;
      .icon-ic_star {
        margin-right: 16px;
      }
    }
    .Event-patients {
      padding-top: 10px;
      font-size: $fontSize5;
      .patients-heading {
        display: flex;
        align-items: center;
        color: $fontColor2;

        .icon-ic_group {
          margin-right: 16px;
        }
      }
      .patients-list {
        padding-top: 16px;
        padding-left: 40px;
        font-size: $fontSize6;
        color: $fontColor1;
        li {
          & + li {
            margin-top: 12px;
          }
        }
        .status-icon {
          font-size: $fontSize5;
          margin-right: 8px;
          &.accept {
            color: $uiColor3;
          }
          &.wait {
            color: $uiColor4;
          }
          &.refuse {
            color: $uiColor1;
          }
        }
      }
      .patient-name {
        display: flex;
        align-items: center;
        line-height: 20px;
      }
      .patient-time {
        font-size: $fontSize7;
        padding-top: 8px;
        color: $fontColor3;
        padding-left: 24px;
      }
    }
    .Event-general-contents {
      margin: 16px 30px 0 30px;
      border-top: 1px solid $borderColor1;
      padding-top: 16px;
      font-size: $fontSize6;
      line-height: 24px;
      color: $fontColor2;
    }
    .Event-live-contents {
      margin: 16px 30px;
      padding: 16px 0;
      border-top: 1px solid $borderColor1;
      border-bottom: 1px solid $borderColor1;

      .subject {
        font-family: $fontSize5;
        color: $fontColor1;
        line-height: 24px;
        font-weight: 500;
        span {
          display: block;
          margin-bottom: 6px;
          color: $fontColor2;
          font-size: $fontSize7;
          font-weight: 300;
        }
      }
      .summary {
        padding-top: 16px;
        font-size: $fontSize6;
        line-height: 24px;
        color: $fontColor2;
      }
    }
    .Event-live-link {
      font-size: $fontSize5;
      color: $fontColor2;
      line-height: 20px;
      display: flex;
      align-items: center;
      .icon-ic_connecting {
        margin-right: 16px;
      }
      a {
        text-decoration: none;
        color: $fontColor2;
      }
    }
  }
  .Event-videocall {
    position: absolute;
    bottom: 32px;
    left: 24px;

    .Event-live-link {
      color: #538fff;
      .icon-ic_connecting {
        margin-right: 6x;
      }
    }
    button {
      text-decoration: underline;
      color: #538fff;
      &.copy-btn {
        text-decoration: none;
        margin-left: auto;
      }
      &.url-btn {
        width: 65%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .submit-btns {
    width: 100%;
    margin-top: -16px;
    padding: 24px;
    box-sizing: border-box;
    background: $popupBgColor1;
    display: flex;
    justify-content: flex-end;
    .accept-btn {
      background: $mainColor1;
      color: #ffffff;
    }
  }
  .popup-submit-btns {
    .decline {
      margin-left: 8px;
    }
  }
}
</style>
