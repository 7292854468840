var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "clinic" },
    [
      _c(
        "div",
        { staticClass: "main-view" },
        [
          _c("TopMenu"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "clinic" },
            [
              _c("ClinicMenu", { style: { marginTop: "-60px" } }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "clinic-contents",
                  style: { height: _vm.windowHeight + "px" }
                },
                [
                  _c("router-view", {
                    ref: "clinicView",
                    staticClass: "view custom-scrollbar"
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("modals-container")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }