<template>
  <section class="PatientList" :style="{height:`${windowHeight}px`}">
    <div class="sort-patient">
      <div class="search">
        <span class="icon-ic_search">
          <span class="path1" />
          <span class="path2" />
        </span>
        <input v-model="searchText" type="text" :placeholder="searchPlaceholder" @keyup="handleSearch">
      </div>
      <div class="sort-type select-type2">
        <!-- <Select
          v-model="selectedSortingType"
          :options="sortingOption"
          label="label"
          placeholder="Add Attendee"
          :components="{OpenIndicator,Deselect}"
        >
          <div slot="no-options" class="vs__no-options">Sorry, no matching patient.</div>
        </Select>-->
      </div>
      <ul class="patients-list custom-scrollbar" :style="{maxHeight:`${windowHeight-108}px`}">
        <li
          v-for="(patient,index) of searchedPatients"
          :key="index"
          :class="patient === selectedPatient? 'on': '' "
        >
          <button type="button" @click="handleSelect(index,patient)">
            <span class="patient-image">
              <span class="icon-ic_group_fill">
                <span class="path1" />
                <span class="path2" />
              </span>
              <img
                v-if="patient.image"
                :src="patient.image"
                :alt="`${patient.name}'s profile image'`"
              >
            </span>
            <div class="patient-info">
              <span class="patient-name">{{ patient.name ? patient.name : '-' }}</span>
              <span
                v-if="patient.gender"
                class="patient-gender-age capitalize"
              >{{ genderFilter(patient.gender) }} / {{ printAge(patient.birthday) }}</span>
            </div>
          </button>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex";
import Select from "vue-select";
import { SelectMixin } from "@/mixins/SelectMixin.js";
export default {
  name: "PatientList",
  components: {
    Select
  },
  mixins: [SelectMixin],
  props: {
    windowHeight: Number,
    printAge: Function
  },
  data() {
    return {
      sortingOption: [
        { label: "All Patients", value: "ALL_PATIENT" },
        { label: "Active Patients", value: "ACTIVE_PATIENT" },
        { label: "Inactive Patients", value: "INACTIVE_PATIENT" }
      ],
      searchedPatients: [],
      searchText: "",
      selectedSortingType: { label: "All Patients", value: "ALL_PATIENT" }
    };
  },
  methods: {
    genderFilter(value) {
      if (value==='MALE') {
        return this.$t("page.patient.layout.gender.male")
      } else if(value==='FEMALE') {
        return this.$t("page.patient.layout.gender.female")
      }
    },
    handleSearch: function(e) {
      const text = this.searchText;
      if (text.length < 1) {
        this.searchedPatients = this.allPatients;
        return;
      }
      const re = new RegExp(`${text}`, "i");
      let SearchedList = [];
      this.allPatients.forEach((patient, index) => {
        if (re.test(patient.name)) {
          SearchedList.push(patient);
        }
      });
      if (
        JSON.stringify(this.searchedPatients) === JSON.stringify(SearchedList)
      ) {
        return;
      }
      this.searchedPatients = SearchedList;
    },
    handleSelect: function(index, patient) {
      this.$store.dispatch("patient/selectPatient", patient).then(result => {
        // console.log(result);
      });
      this.$store
        .dispatch("patient/setPatientDevice", patient.id)
        .then(result => {
          // console.log(result);
        });
    },
    ...mapActions({
      setSelectPatient: "patient/selectPatient"
    })
  },
  watch: {
    allPatients: function(newVal, oldVal) {
      // 새로고침시 created를 거치지 않고 값이 셋팅되어야하는 checkedPatient값을 init함
      this.searchedPatients = newVal;
      if (this.searchedPatients.length > 0) {
        this.$store.dispatch("patient/selectPatient", this.searchedPatients[0]);
        this.$store.dispatch(
          "patient/setPatientDevice",
          this.searchedPatients[0].id
        );
      }
    },
    selectedSortingType: function(newVal, oldVal) {
      // console.log(newVal);
    }
  },
  computed: {
    ...mapGetters({
      allPatients: "user/patients",
      selectedPatient: "patient/selectedPatient"
    }),
    searchPlaceholder() {
      return this.$t("page.patient.layout.searchPlaceholder");
    }
  },
  created() {
    this.searchedPatients = this.allPatients;
    this.setSelectPatient(this.searchedPatients[0]);
  }
};
</script>

<style lang="scss" scoped>
.PatientList {
  position: relative;
  margin-top: -60px;
  min-height: 730px;
  width: 250px;
  flex-shrink: 0;
  box-sizing: border-box;
  border-right: 1px solid $borderColor1;
  color: $fontColor1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: #ffffff;
  h2 {
    margin: 16px 8px 0 8px;
    font-weight: 500;
    &.sort-heading {
      margin-top: 32px;
    }
  }
  .search {
    input[type="text"] {
      line-height: 24px;
      border-radius: 24.5px;
    }
  }
  .sort-patient {
    height: calc(100% - 170px);
    .search {
      margin-top: 24px;
      padding: 0 16px;
      position: relative;
      .icon-ic_search {
        position: absolute;
        top: 50%;
        left: 32px;
        transform: translate(0, -50%);
        font-size: $fontSize2;
        color: $fontColor2;
      }
      input[type="text"] {
        padding-left: 55px;
      }
    }
    .sort-type {
      display: flex;
      padding: 4px 0;
      min-height: 42px;
      box-sizing: border-box;
      .v-select {
        margin-left: auto;
        width: 150px;
      }
    }
  }
  .patients-list {
    overflow-y: auto;
    li {
      width: 100%;
      overflow: hidden;
      &:nth-of-type(odd) {
        background: rgba(244, 245, 247, 0.6);
      }

      button {
        padding: 8px 24px;
        text-align: left;
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 0;
        box-sizing: border-box;
        .patient-image {
          width: 34px;
          height: 34px;
          display: inline-block;
          background: #d0d4dc;
          border-radius: 50%;
          position: relative;
          flex-shrink: 0;
          overflow: hidden;
          .icon-ic_group_fill {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: rgba(255, 255, 255, 0.8);
          }
          img {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .patient-info {
          padding: 0 32px 0 16px;
          width: 100%;
          box-sizing: border-box;
          .patient-name {
            display: block;
            width: 100%;
            color: $fontColor1;
            font-size: $fontSize6;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: normal;
            box-sizing: border-box;
          }
          .patient-gender-age {
            margin-top: 4px;
            display: block;
            color: $fontColor3;
            font-size: $fontSize7;
          }
        }
      }
      &.on {
        button {
          background-color: rgba(131, 104, 85, 0.2);
          .patient-image {
            background: #ffffff;
            .icon-ic_group_fill {
              color: $borderColor1;
            }
          }
        }
      }
    }
  }
}
</style>
