<template>
  <div id="App">
    <router-view ref="appView" />
    <!-- ScreenSharing -->
    <ScreenSharing
      v-if="screen_view"
      :session-id="screen_sessionId"
      :api-key="screen_apiKey"
      :token="screen_token"
      :event-id="screen_eventId"
    />
    <!-- VideoCall -->
    <VideoCall
      v-if="video_view"
      :session-id="video_sessionId"
      :api-key="video_apiKey"
      :token="video_token"
      :event-id="video_eventId"
    />
    <modals-container />
  </div>
</template>
<script>
// import firebase from "firebase/app";
// import "firebase/messaging";
import NoticeConfirm from "@/components/NoticeConfirm";
import ScreenSharing from "@/views/ScreenSharing";
import VideoCall from "@/views/VideoCall";
import { mapGetters } from "vuex";
import SingletonFirebase from "@/firebase";
export default {
  name: "App",
  components: {
    ScreenSharing,
    VideoCall
  },
  data() {
    return {
      messaging: null
    };
  },
  computed: {
    ...mapGetters({
      screen_view: "screensharing/view",
      screen_sessionId: "screensharing/sessionId",
      screen_apiKey: "screensharing/apiKey",
      screen_token: "screensharing/token",
      screen_eventId: "screensharing/eventId",
      video_view: "videocall/view",
      video_sessionId: "videocall/sessionId",
      video_apiKey: "videocall/apiKey",
      video_token: "videocall/token",
      video_eventId: "videocall/eventId",
      login_token: "user/token",
      lang: "lang/language"
    })
  },
  watch: {
    lang: function () {
      if (this.lang === "ko") {
        require("moment/locale/ko.js");
        this.$moment.locale("ko");
      } else {
        require("moment/locale/en-gb.js");
        this.$moment.locale("en-gb");
      }
    }
  },
  async mounted() {
    if (this.lang === "ko") {
      require("moment/locale/ko.js");
      this.$moment.locale("ko");
    } else {
      require("moment/locale/en-gb.js");
      this.$moment.locale("en-gb");
    }
    this.messaging = await SingletonFirebase.getInstance();
    this.messaging.onMessage((payload) => {
      if (this.login_token === "") {
        return;
      }
      console.log(`Push Received`, payload);
      const data = JSON.parse(payload.data.json);
      const { title, contents, type, schedule_id } = data;
      this.$store.dispatch("notification/setNotification", payload);
      this.$toast.open({
        message: `${title} :\n${contents}`,
        type: "default",
        duration: 5000
      });
      // 5분전 팝업노티
      if (
        type === "portal_videocall_bef_min_5" ||
        type === "portal_screenshare_bef_min_5" ||
        type === "portal_live_bef_min_5"
      ) {
        const sendType =
          type === "portal_videocall_bef_min_5"
            ? "VIDEOCALL"
            : type === "portal_screenshare_bef_min_5"
              ? "SCREENSHARING"
              : "THERAPISTLIVE";
        this.$modal.show(
          NoticeConfirm,
          {
            confirm: {
              sendType,
              title,
              message: contents
            },
            eventId: schedule_id
          },
          {
            height: "auto"
          }
        );
      }
      if (
        type === "portal_videocall_request" ||
        type === "connect_videocall_sched_cancel_f-patient" ||
        this.$route.fullPath === "/"
      ) {
        this.$router.push("/calendar/forcerefresh");
      }
    });
  },
  methods: {
    // async getToken() {
    //   const fcmToken = await this.messaging.getToken();
    //   if (fcmToken) {
    //     this.fcmToken = fcmToken;
    //     await this.$store.dispatch("firebase/initFcmToken", fcmToken);
    //   }
    // }
  }
};
</script>
<style lang="scss">
@import "@/styles/default.scss"; /* 기본태그 스타일 */
@import "@/styles/custom_icons.scss"; /* 사용되는 아이콘 */
@import "~vue2-timepicker/dist/VueTimepicker.css"; /* timepicker 컴포넌트 스타일 */
@import "vue-select/src/scss/vue-select.scss"; /* vueselect 컴포넌트 스타일 */
</style>
