var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "patient" } },
    [
      _c("GlobalMenu"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-view" },
        [
          _c("TopMenu", {
            attrs: {
              "handle-toggle-notification": _vm.handleToggleNotification,
              "handle-toggle-setting-time": _vm.handleToggleSettingTime
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "patient" },
            [
              _c("PatientList", {
                staticClass: "patient-sorting",
                attrs: {
                  "selected-patient": _vm.selectedPatient,
                  "window-height": _vm.windowHeight,
                  "print-age": _vm.printAge
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "patient-right",
                  style: { height: _vm.windowHeight - 60 + "px" }
                },
                [
                  _c("div", { staticClass: "patient-quick-menus" }, [
                    _vm.selectedPatient
                      ? _c("h1", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.selectedPatient.name
                                  ? _vm.selectedPatient.name
                                  : "-"
                              ) +
                              "\n            "
                          ),
                          _vm.selectedPatient.gender
                            ? _c(
                                "span",
                                { staticClass: "sub-text capitalize" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.genderFilter(
                                          _vm.selectedPatient.gender
                                        ) +
                                          " / " +
                                          _vm.printAge(
                                            _vm.selectedPatient.birthday
                                          )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "control-btns default-type-btns" },
                      [
                        _c(
                          "button",
                          {
                            attrs: { type: "button" },
                            on: { click: _vm.handleCreateSessionPopup }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "page.patient.layout.button.createSession"
                                  )
                                ) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        this.userClinic.name.toLowerCase() === "neofect"
                          ? _c(
                              "button",
                              {
                                attrs: { type: "button" },
                                on: { click: _vm.togglePatientChat }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "page.patient.layout.button.textChat"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "default-type-btns disabled" },
                          [
                            _c(
                              "button",
                              {
                                attrs: { type: "button" },
                                on: { click: _vm.startScreenSharing }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$t(
                                        "page.patient.layout.button.screenSharing"
                                      )
                                    ) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "patient-view" },
                    [
                      _c(
                        "div",
                        { staticClass: "patient-link" },
                        [
                          _c("router-link", { attrs: { to: "/patient" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("page.patient.layout.menu.profile"))
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            { attrs: { to: "/patient/home" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("page.patient.layout.menu.neofectHome")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            { attrs: { to: "/patient/homework" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("page.patient.layout.menu.homework")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            { attrs: { to: "/patient/calendar" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("page.patient.layout.menu.calandar")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            { attrs: { to: "/patient/advice" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("page.patient.layout.menu.advice")
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            { attrs: { to: "/patient/memo" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "page.patient.layout.menu.Documentation"
                                  )
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("router-view", {
                        ref: "patientView",
                        staticClass: "view custom-scrollbar"
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.settingTime.view
        ? _c("SettingTime", {
            attrs: { "handle-close-setting-time": _vm.handleCloseSettingTime }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.notification.view
        ? _c("Notification", {
            attrs: { "handle-close-notification": _vm.handleCloseNotification }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.createSession.view
        ? _c("CreateSession", {
            attrs: {
              "close-session-list-popup": _vm.handleCloseCreateSessionPopup
            }
          })
        : _vm._e(),
      _vm._v(" "),
      this.userClinic.name.toLowerCase() === "neofect"
        ? _c("div", { staticClass: "textchat-btn" }, [
            _c(
              "button",
              { attrs: { type: "button" }, on: { click: _vm.toggleChat } },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm.unreadMessageCount > 0
                  ? _c("span", { staticClass: "unread" }, [
                      _vm._v(_vm._s(_vm.unreadMessageCount))
                    ])
                  : _vm._e()
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      this.userClinic.name.toLowerCase() === "neofect"
        ? _c("TextChat")
        : _vm._e(),
      _vm._v(" "),
      _c("modals-container")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon-ic_message" }, [
      _c("span", { staticClass: "path1" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }