var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Memo", on: { scroll: _vm.handleScroll } }, [
    _vm.memoList.length > 0
      ? _c("div", { staticClass: "contents" }, [
          _c("div", { staticClass: "memo-new-btn secondary-type-btns" }, [
            _c(
              "button",
              {
                attrs: { type: "button" },
                on: { click: _vm.handleCreateMemo }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "page.patient.documentation.newDocumentationButton"
                      )
                    ) +
                    "\n      "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "memo-list" }, [
            _c(
              "ul",
              _vm._l(_vm.memoList, function(memo, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    on: {
                      click: function($event) {
                        return _vm.handleMemoDetail(memo.id, $event)
                      }
                    }
                  },
                  [
                    _c("div", [
                      _c("div", { staticClass: "memo-type-date" }, [
                        _c("span", { staticClass: "type" }, [
                          _vm._v(_vm._s(_vm.printType(memo.division)))
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "date" }, [
                          _vm._v(
                            _vm._s(
                              _vm
                                .$moment(memo.created)
                                .format("MMMM D, YYYY h:mm A")
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "memo-title" }, [
                        _vm._v(_vm._s(memo.title))
                      ])
                    ]),
                    _vm._v(" "),
                    memo.division === "VIDEOCALLRECORD"
                      ? _c(
                          "a",
                          {
                            staticClass: "video-download",
                            on: {
                              click: function($event) {
                                return _vm.handleClickVideoDownload(
                                  memo,
                                  _vm.selectedPatient
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.selectedPatient.name +
                                    "_videocall_record_" +
                                    _vm.$moment(memo.created).format("YYMMDD") +
                                    ".mp4"
                                ) +
                                "\n            "
                            ),
                            _vm._m(0, true)
                          ]
                        )
                      : _vm._e()
                  ]
                )
              }),
              0
            )
          ])
        ])
      : _c("div", { staticClass: "contents no-written" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("page.patient.documentation.noDocumentationYet")) +
              "\n    "
          ),
          _c("div", { staticClass: "memo-new-btn secondary-type-btns" }, [
            _c(
              "button",
              {
                attrs: { type: "button" },
                on: { click: _vm.handleCreateMemo }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t(
                        "page.patient.documentation.newDocumentationButton"
                      )
                    ) +
                    "\n      "
                )
              ]
            )
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "download-icon" }, [
      _c("span", { staticClass: "download-1" }),
      _vm._v(" "),
      _c("span", { staticClass: "download-2" }),
      _vm._v(" "),
      _c("span", { staticClass: "download-3" }),
      _vm._v(" "),
      _c("span", { staticClass: "download-4" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }