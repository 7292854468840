import {
  fetchScreenSharingStart,
  fetchScreenSharingStartInSchedule,
  fetchScreenSharingStopInSchedule
} from "@/api/calendar.js";

const state = {
  view: false,
  token: "",
  sessionId: "",
  apiKey: "",
  eventId: ""
};

const mutations = {
  SET_SESSION: (state, value) => {
    state.view = true;
    state.token = value.token;
    state.sessionId = value.session_id;
    state.apiKey = value.api_key;
  },
  SET_EVENTID: (state, id) => {
    state.eventId = id;
  },
  CLOSE_SESSION: state => {
    state.view = false;
    state.token = "";
    state.sessionId = "";
    state.apiKey = "";
    state.eventId = "";
  },
  CLOSE_VIEW: state => {
    state.view = false;
  }
};

const actions = {
  async start({ commit }, patientId) {
    /*
        //// Screen Sharing 시작하기 ////
        */
    await fetchScreenSharingStart(patientId)
      .then(result => {
        // console.log(result);
        commit("SET_SESSION", result);
      })
      .catch(error => {
        throw error;
      });
  },
  close({ commit }) {
    /*
        //// Screen Sharing 종료하기 ////
        */
    commit("CLOSE_SESSION");
  },
  closeView({ commit }) {
    commit("CLOSE_VIEW");
  },
  async startInSchedule({ commit }, eventId) {
    /*
    //// 스케쥴 일정에서 Screen Sharing 시작하기 ////
    */
    await fetchScreenSharingStartInSchedule(eventId)
      .then(result => {
        // console.log(result);
        commit("SET_SESSION", result);
        commit("SET_EVENTID", eventId);
      })
      .catch(error => {
        throw error;
      });
  },
  async closeInSchedule({ commit }, eventId) {
    /*
    //// Screen Sharing 종료하기 ////
    */
    await fetchScreenSharingStopInSchedule(eventId)
      .then(result => {
        commit("CLOSE_SESSION");
      })
      .catch(error => {
        throw error;
      });
  }
};

const getters = {
  view: state => {
    return state.view;
  },
  token: state => {
    return state.token;
  },
  sessionId: state => {
    return state.sessionId;
  },
  apiKey: state => {
    return state.apiKey;
  },
  eventId: state => {
    return state.eventId;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
