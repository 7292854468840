import request from "@/utils/request";

export function getDeviceList(userId) {
  return request({
    url: "/user/api/v1/patients/devices",
    method: "get",
    params: {
      userId
    }
  });
}

export function getTrainedSensorSummary(userId, deviceId, startDate, endDate) {
  return request({
    url: "/home/api/v1/report/trainedSensorSummary",
    method: "get",
    params: {
      userId,
      deviceId,
      startDate,
      endDate
    }
  });
}

export function getTrainedSessionsSummary(
  userId,
  deviceId,
  startDate,
  endDate
) {
  return request({
    url: "/home/api/v1/report/trainedSessionsSummary",
    method: "get",
    params: {
      userId,
      deviceId,
      startDate,
      endDate
    }
  });
}

export function getTrainingTimes(userId, deviceId, startDate, endDate) {
  return request({
    url: "/home/api/v1/report/trainedTimes",
    method: "get",
    params: {
      userId,
      deviceId,
      startDate,
      endDate
    }
  });
}

export function getMissionLogs(userId, deviceId, startDate, endDate) {
  return request({
    url: "/home/api/v1/report/missionLogs",
    method: "get",
    params: {
      userId,
      deviceId,
      startDate,
      endDate
    }
  });
}

export function getGloveMotionTypeRatio(userId, deviceId, startDate, endDate) {
  return request({
    url: "/home/api/v1/report/glove/trainedMotionTypes",
    method: "get",
    params: {
      userId,
      deviceId,
      startDate,
      endDate
    }
  });
}

export function getMotionFeatureRatio(userId, deviceId, startDate, endDate) {
  return request({
    url: "/home/api/v1/report/trainedFeatures",
    method: "get",
    params: {
      userId,
      deviceId,
      startDate,
      endDate
    }
  });
}

export function getEvaluations(userId, deviceId) {
  return request({
    url: "/home/api/v1/report/evaluations",
    method: "get",
    params: {
      userId,
      deviceId
    }
  });
}

export function getGloveTrainedAroms(userId, deviceId, startDate, endDate) {
  return request({
    url: "/home/api/v1/report/glove/trainedAroms",
    method: "get",
    params: {
      userId,
      deviceId,
      startDate,
      endDate
    }
  });
}

export function getGloveEvaluationDetail(evaluationId) {
  return request({
    url: `/home/api/v1/report/glove/evaluations/${evaluationId}`,
    method: "get"
  });
}

export function getBoardMovedDistance(userId, deviceId, startDate, endDate) {
  return request({
    url: "/home/api/v1/report/board/movements",
    method: "get",
    params: {
      userId,
      deviceId,
      startDate,
      endDate
    }
  });
}

export function getBoardAromArea(userId, deviceId, startDate, endDate) {
  return request({
    url: "/home/api/v1/report/board/aromArea",
    method: "get",
    params: {
      userId,
      deviceId,
      startDate,
      endDate
    }
  });
}

export function getBoardAromRange(userId, deviceId, startDate, endDate) {
  return request({
    url: "/home/api/v1/report/board/aromRange",
    method: "get",
    params: {
      userId,
      deviceId,
      startDate,
      endDate
    }
  });
}

export function getBoardAromSmoothness(userId, deviceId, startDate, endDate) {
  return request({
    url: "/home/api/v1/report/board/smoothness",
    method: "get",
    params: {
      userId,
      deviceId,
      startDate,
      endDate
    }
  });
}

export function getBoardAromSimilarity(userId, deviceId, startDate, endDate) {
  return request({
    url: "/home/api/v1/report/board/similarity",
    method: "get",
    params: {
      userId,
      deviceId,
      startDate,
      endDate
    }
  });
}

export function getBoardEvaluationDetail(evaluationId) {
  return request({
    url: `/home/api/v1/report/board/evaluations/${evaluationId}`,
    method: "get"
  });
}

// 훈련 전체 날짜 get patient's Trained whole Dates
export function getPatientTrainedWholeDates(userId, deviceId) {
  return request({
    url: `/home/api/v1/report/trainedDates?userId=${userId}&deviceId=${deviceId}`,
    method: "get"
  });
}
