<template>
  <div class="Subscriber" />
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: "Subscriber",
  props: {
    stream: {
      type: OT.Stream,
      required: true
    },
    session: {
      type: OT.Session,
      required: true
    }
  },
  data() {
    return {
      options: {
        fitMode: "contain",
        width: "100%",
        height: "100%"
      }
    };
  },
  mounted: function() {
    const subscriber = this.session.subscribe(
      this.stream,
      this.$el,
      this.options,
      error => {
        if (error) {
          this.$emit("error", error);
        } else {
          this.$emit("subscriberConnected", subscriber);
        }
      }
    );

    subscriber.setStyle("nameDisplayMode", "off");
    this.$emit("subscriberCreated", subscriber);
  }
};
</script>
<style lang="scss" scoped>
.Subscriber {
  width: 100%;
  height: 100%;
}
</style>
