var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Calendar" },
    [
      _c(
        "div",
        { staticClass: "calendar-area" },
        [
          _c("FullCalendar", {
            ref: "fullCalendarPatient",
            staticClass: "fullCalendar-patient",
            attrs: {
              plugins: _vm.calendarConfig.calendarPlugins,
              "default-view": "timeGridWeek",
              header: _vm.calendarConfig.header,
              "show-non-current-dates": _vm.calendarConfig.showNonCurrentDates,
              "fixed-week-count": _vm.calendarConfig.fixedWeekCount,
              "day-popover-format": _vm.calendarConfig.dayPopoverFormat,
              "title-format": _vm.calendarConfig.titleFormat,
              "first-day": _vm.calendarConfig.firstDay,
              "all-day-text": _vm.calendarConfig.allDayText,
              "button-text": _vm.calendarConfig.buttonText,
              "column-header-html": _vm.calendarConfig.columnHeaderHtml,
              "content-height": _vm.calendarConfig.contentHeight,
              "all-day-slot": _vm.calendarConfig.allDaySlot,
              "dates-render": _vm.handleDatesRender,
              "min-time": _vm.calendarConfig.minTime,
              now: _vm.now
            },
            on: {
              eventClick: _vm.handleEventClick,
              windowResize: _vm.handleWindowResize
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.eventDetailPopup.view
        ? _c("EventDetail", {
            ref: "detailPopup",
            attrs: {
              "event-detail-info": _vm.eventDetailPopup,
              "deny-event": _vm.denyEvent,
              "start-screen-sharing": _vm.startScreenSharing,
              "start-video-call": _vm.startVideoCall,
              "edit-event": _vm.editEvent,
              "delete-event": _vm.deleteEvent,
              "handle-detail-popup-close": _vm.handleDetailPopupClose,
              "calendar-size": _vm.calendarSize
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.createEventPopup.view
        ? _c("CreateEvent", {
            attrs: {
              "create-event-info": _vm.createEventPopup,
              "handle-error": _vm.handleError,
              "handle-create-event-popup-close":
                _vm.handleCreateEventPopupClose,
              "change-event-sources": _vm.changeEventSources
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mission-week-list" },
        [
          _c("WeeklyMission", {
            attrs: {
              "mission-list": _vm.missionList,
              "handle-mission-detail-popup": _vm.handleMissionDetailPopup
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.missionDetailPopup.view
        ? _c("MissionDetail", {
            attrs: {
              "mission-detail-info": _vm.missionDetailPopup,
              "detail-popup-close": _vm.handleMissionDetailPopupClose
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "homework-week-list" },
        [
          _c("WeeklyHomework", {
            attrs: {
              "homework-list": _vm.homeworkList,
              "handle-homework-detail": _vm.handleHomeworkClick
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.homeworkDetailPopup.view
        ? _c("HomeworkDetail", {
            attrs: {
              "homework-detail-info": _vm.homeworkDetailPopup,
              "detail-popup-close": _vm.handleHomeworkDetailPopupClose,
              "delete-homework": _vm.deleteHomework,
              "edit-homework": _vm.handleEditHomeworkPopup
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.homeworkPopup.view
        ? _c("HomeworkSession", {
            attrs: {
              "session-popup": _vm.homeworkPopup,
              "close-session-popup": _vm.handleCloseHomeworkPopup,
              "edit-session": _vm.editHomework,
              "repeat-disabled": true
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "session-week-list" },
        [
          _c("WeeklySession", {
            attrs: {
              "session-list": _vm.sessionList,
              "handle-session-type1click": _vm.handleSessionType1Click,
              "handle-session-type2click": _vm.handleSessionType2Click
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.sessionDetailPopup.view
        ? _c("SessionDetail", {
            attrs: {
              "session-detail-info": _vm.sessionDetailPopup,
              "detail-popup-close": _vm.handleSessionDetailPopupClose,
              "delete-session": _vm.deleteSession,
              "edit-session": _vm.handleEditSessionPopup
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.sessionPopup.view
        ? _c("Session", {
            attrs: {
              "session-popup": _vm.sessionPopup,
              "close-session-popup": _vm.handleCloseSessionPopup,
              "edit-session": _vm.editSession
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }