import request from "@/utils/request";

// Therapist clinic 조직정보
export function fetchGetClinicList() {
  return request({
    url: `/user/api/v1/clinic/list`,
    method: "get"
  });
}

// 프로필 - 자신의 클리닉 가입 요청 신청 - 치료사
export function fetchAddMyClinicTherapistRequest(clinic_req_id) {
  return request({
    url: `/user/api/v1/clinic/therapist/req/${clinic_req_id}`,
    method: "post"
  });
}

// Therapist clinic 변경신청 취소
export function fetchCancelChangeClinic(clinic_req_id) {
  return request({
    url: `/user/api/v1/clinic/therapist/req/${clinic_req_id}`,
    method: "delete"
  });
}

// Therapist request 치료사목록 가져오기
export function fetchGetTherapistRequest(id, data) {
  return request({
    url: `/user/api/v1/clinic/${id}/therapist/req/list`,
    method: "post",
    data
  });
}

// Therapist request 환자목록 가져오기
export function fetchGetPatientRequest(id, data) {
  return request({
    url: `/user/api/v1/clinic/${id}/patient/req/list`,
    method: "post",
    data
  });
}

// clinic 치료사 request 승인
export function fetchApproveTherapistRequest(id, list) {
  return request({
    url: `/user/api/v1/clinic/${id}/therapist/req/modify`,
    method: "post",
    data: {
      mode: 1,
      list
    }
  });
}

// clinic 치료사 request 거절
export function fetchDenyTherapistRequest(id, list) {
  return request({
    url: `/user/api/v1/clinic/${id}/therapist/req/modify`,
    method: "post",
    data: {
      mode: 2,
      list
    }
  });
}

// clinic 환자 request 승인
export function fetchApprovePatientRequest(id, list) {
  return request({
    url: `/user/api/v1/clinic/${id}/patient/req/modify`,
    method: "post",
    data: {
      mode: 1,
      list
    }
  });
}

// clinic 환자 request 거절
export function fetchDenyPatientRequest(id, list) {
  return request({
    url: `/user/api/v1/clinic/${id}/patient/req/modify`,
    method: "post",
    data: {
      mode: 2,
      list
    }
  });
}

// clinic 치료사 request 삭제
export function fetchDeleteTherapistRequest(id, req_id, therapist_id) {
  return request({
    url: `/user/api/v1/clinic/${id}/therapist/req/${req_id}/${therapist_id}`,
    method: "delete"
  });
}

// clinic 환자 request 삭제
export function fetchDeletePatientRequest(id, req_id, patient_id) {
  return request({
    url: `/user/api/v1/clinic/${id}/patient/req/${req_id}/${patient_id}`,
    method: "delete"
  });
}

// Therapist management 환자목록 가져오기
export function fetchGetClinicPatient(id, data) {
  return request({
    url: `/user/api/v1/clinic/${id}/patient/list`,
    method: "post",
    data
  });
}

// Therapist management 치료사목록 가져오기
export function fetchGetClinicTherapist(id, data) {
  return request({
    url: `/user/api/v1/clinic/${id}/therapist/list`,
    method: "post",
    data
  });
}

// Therapist management 치료사 삭제
export function fetchDeleteClinicTherapistList(id, data) {
  return request({
    url: `/user/api/v1/clinic/${id}/therapist/list`,
    method: "delete",
    data
  });
}

// Therapist management 치료사별 환자목록 가져오기
export function fetchGetClinicTherapistPatientList(id, therapistId) {
  return request({
    url: `/user/api/v1/clinic/${id}/therapist/patients`,
    method: "get",
    params: {
      therapist_id: therapistId
    }
  });
}

// Therapist mapping 치료사 맵핑안된 환자목록 가져오기
export function fetchGetClinicPatientsNoAssigned(id) {
  return request({
    url: `/user/api/v1/clinic/${id}/patient/unassigned`,
    method: "get"
  });
}

// Therapist management 치료사1vs환자N mapping
export function fetchMappingClinicTherapistToPatient(id, therapistId, list) {
  return request({
    url: `/user/api/v1/clinic/${id}/mapping/list`,
    method: "put",
    data: {
      therapist_id: therapistId,
      list
    }
  });
}

// Therapist management 치료사1vs환자1 mapping
export function fetchMappingClinicPatientToTherapist(
  id,
  patient_id,
  therapist_id
) {
  return request({
    url: `/user/api/v1/clinic/${id}/mapping/single`,
    method: "post",
    data: {
      therapist_id,
      patient_id
    }
  });
}

// Therapist clinic 소속 치료사정보 (owner)
export function fetchGetClinicTherapistList(id, data) {
  return request({
    url: `/user/api/v1/clinic/${id}/roles/list`,
    method: "post",
    data
  });
}

// Therapist clinic 소속 치료사정보 (owner)
export function fetchSingleMappingClinicTherapist(id, data) {
  return request({
    url: `/user/api/v1/clinic/${id}/roles/single`,
    method: "post",
    data
  });
}

// 가입/프로필 - 엔드유저용 클리닉코드 조회
export function fetchGetClinicByClinicCode(clinicCode) {
  return request({
    url: `/user/api/v1/clinic/clinicCode/${clinicCode}`,
    method: "get"
  });
}

// 클리닉 승인상태 체크
export function fetchGetClinicReqStatus() {
  return request({
    url: "/user/api/v1/clinic/therapist/req/status",
    method: "get"
  });
}
