/* eslint-disable prefer-const */
import firebase from "firebase/app";
import "firebase/messaging";
import store from "@/store";

const config = {
  apiKey: process.env.VUE_APP_FCM_API_KEY,
  authDomain: process.env.VUE_APP_FCM_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FCM_DATABASE_URL,
  projectId: process.env.VUE_APP_FCM_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FCM_SENDER_ID,
  appId: process.env.VUE_APP_FCM_APP_ID,
  measurementId: process.env.VUE_APP_FCM_MEASUREMENT_ID
};

const SingletonFirebase = (function() {
  let instanceFirebase;
  async function init() {
    await firebase.initializeApp(config);
    let messaging;
    messaging = firebase.messaging();
    messaging.usePublicVapidKey(process.env.VUE_APP_FCM_CERT_KEY_PAIR);
    messaging.requestPermission();
    messaging.onTokenRefresh(() => {
      this.getToken();
    });
    const fcmToken = await messaging.getToken();
    await setToken(fcmToken);
    return messaging;
  }
  async function setToken(fcmToken) {
    if (fcmToken) {
      await store.dispatch("firebase/initFcmToken", fcmToken);
    }
  }
  return {
    getInstance: function() {
      if (!instanceFirebase) {
        instanceFirebase = init();
      }
      return instanceFirebase;
    }
  };
})();

export default SingletonFirebase;
