var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "report-section glove-training-report" },
    [
      _c(
        "div",
        { staticClass: "report-contents" },
        [
          _vm.loading ? _c("Loading") : _vm._e(),
          _vm._v(" "),
          !_vm.isNoData
            ? _c(
                "div",
                { staticClass: "content-grid" },
                _vm._l(_vm.reportList, function(item) {
                  return _c("div", { staticClass: "content-grid-row" }, [
                    _c("div", { staticClass: "content-grid-row-item-text" }, [
                      _vm._v(" " + _vm._s(item.text) + " ")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content-grid-row-item-sum" }, [
                      _vm._v(" " + _vm._s(item.sum) + " "),
                      _c("span", { staticClass: "unit" }, [
                        _vm._v(" " + _vm._s(item.sumUnit) + " ")
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.isRangeCompare
                      ? _c(
                          "div",
                          {
                            staticClass: "content-grid-row-item-gap",
                            class: { minus: item.gap < 0, plus: item.gap > 0 }
                          },
                          [
                            _c("span", {
                              staticClass: "arrow",
                              class: { down: item.gap < 0, up: item.gap > 0 }
                            }),
                            _vm._v(
                              "\n           " +
                                _vm._s(_vm.removeNumberMinusSign(item.gap)) +
                                " "
                            )
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "content-grid-row-item-gap" },
                          [_vm._v("\n           -\n         ")]
                        )
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isNoData
            ? _c("div", { staticClass: "no-data" }, [
                _vm._v(_vm._s(_vm.$t("page.patient.neofectHome.data.empty")))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.error
            ? _c("div", { staticClass: "error" }, [_vm._v("Error!")])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }