var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Publisher",
      class: [_vm.ScreenSharingOn ? "ScringSharingOn" : "ScringSharingOff"],
      on: { click: _vm.screenAction }
    },
    [
      _vm.mode === "main-mode"
        ? _c("div", { staticClass: "controller-button-wrap" }, [
            _vm.changeMode
              ? _c(
                  "button",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.changeMode($event)
                      }
                    }
                  },
                  [_vm._m(0)]
                )
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/svgs/ic_division.svg"),
          alt: "ic_division-image"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }